import React, { useState,useEffect} from 'react'
import './Reporting.scss'
import {Table,Popover,Checkbox} from 'antd';
import { CSVLink} from "react-csv";
import {holding_report_put} from './ReportsApi'
import ClientWise from '../../Admin/Reports/ClientWise';

const HoldingTable = (props) => {
    const [Tabdata, setTabData] = useState([]);
    const [exdata, setExData] = useState([]);
    const [selectedschemes,setSelectedSchemes] = useState({})
    const [check,setCheck] = useState('Y')
  
    const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        minimumFractionDigits:0
    }).format(value);
  

    const content = (
      <p>Missing Transaction</p>
    );


    const checkChange=(record)=>{
            const input={
                access_token:localStorage.token,
                family_member_id: record.family_member_id,
                tradingsymbol:record.tradingsymbol,
                id:props.id,
                flag:props.xirrstatus
              
            }
            console.log("input",input)
            holding_report_put(input).then(res => {
              console.log("res",res)
          try{
          if (res.status==='successfull') {
            
            }
            else{}
            }catch{}
        })
        }
        
    const ExpandedRowRender = (props) => {
      const columns = [
        ...(props.data3 == 'clientwise' ? [{
        title: 'Select',
        dataIndex: 'checkbox',
        key: 'checkbox',
         render: (_, record) => (
          <Checkbox onClick={() => checkChange(record)} style={{ marginRight: 8 }}></Checkbox>
          ),
          }] : []),
        { title: 'Fund/Trading Symbol',width: '40%', dataIndex: 'fund'},
        { title: 'Average Price',align:'right', dataIndex: 'average_price',render: total => { return numberFormat(total)}},
        { title: 'Last Price',align:'right', dataIndex: 'last_price',render: total => { return total?(numberFormat(total)):0}},
        { title: 'Quantity',align:'right', dataIndex: 'quantity' },
        { title: 'Invested Value',align:'right', dataIndex: 'invested_value',render: total => { return total?(numberFormat(Math.trunc(total))):0}},
        { title: 'Current Value',align:'right',width: '10%', dataIndex: 'current_value',render: total => { return total?(numberFormat(Math.trunc(total))):0}},
        { title: 'XIRR ',align:'right',width: '7.5%', dataIndex: 'irr',render: total => { 
          return total==='-0.00'?  <Popover content={content}><p style={{cursor:'pointer'}}>0</p></Popover> : `${total} %`} },
        { title: 'Allocation',align:'right',width: '7%', dataIndex: 'Allocation',render: total => { return `${total} %`} },
      ];
      
      const dataEx = [];
      if(props.data || props.data2){
        Object.keys(props.data).map((val)=>{
          if(val!=='total' && val!=='invested_total' && val!=='class_wise_allocation' && val!=='class_wise_xirr'){
            // console.log(props.data2);
            dataEx.push({
              key: val,
              fund: val,
              average_price:props.data[val].average_price,
              current_value: props.data[val].current_value,
              quantity: props.data[val].quantity,
              irr: props.data[val].xirr,
              last_price: props.data[val].last_price,
              Allocation:props.data[val].Allocation,
              invested_value:props.data[val].invested_value,
              family_member_id:props.data2,
              tradingsymbol:props.data[val].tradingsymbol
            })
          }
      })
      }
      return <Table
        columns={columns} dataSource={dataEx}
        pagination={false}   size='small'  
        rowClassName="editable-row"
        bordered/>;
    };
    useEffect(()=>{
        let newdata = []
        Object.keys(props.dataMain).map((val)=>{
          if(val!=='total_xirr' && val!=='family_member_id'){
          newdata.push({ 
            key: val,
            name:val,
            current_value:props.dataMain[val].total,
            invested_total:props.dataMain[val].invested_total,
            class_wise_allocation:props.dataMain[val].class_wise_allocation,
            class_wise_xirr:props.dataMain[val].class_wise_xirr,
            description: <ExpandedRowRender data={props.dataMain[val]} data3={props.xirrstatus} data2={props.dataMain.family_member_id}/>
            })
          }
        })
        setTabData(newdata)
      
    },[props.dataMain])

    const columns = [
      
      {title: 'Asset Class',dataIndex: 'name',className:'colm'},
      {title: 'Invested Value' ,align:'right',dataIndex: 'invested_total',render: total => { return total?(numberFormat(Math.trunc(total))):0}},
      {title: 'Current Value' ,align:'right',width: '10%',dataIndex: 'current_value',render: total => { return total?(numberFormat(Math.trunc(total))):0}},
      {title: 'XIRR' ,align:'right',width: '7%',dataIndex: 'class_wise_xirr',render: total => { return `${(total)} %`}},
      {title: 'Allocation' ,align:'right',width: '7%',dataIndex: 'class_wise_allocation',render: total => { return `${total} %`}},
    ];

 

 
 
  return (
    <div>
      <Table
        columns={columns}
        pagination={false}
        expandable={{ expandRowByClick:true,
          expandedRowRender: record =><>{record.description}</> }}
        rowClassName="antTable"
        dataSource={Tabdata}
        size='small'
        bordered
        summary={(pageData) => {
          let ITotal = 0;
          let CTotal = 0;
          let ATotal = 0;
          pageData.forEach(({invested_total,current_value,class_wise_allocation}) => {
            ITotal = ITotal+parseInt(invested_total);
            CTotal = CTotal+parseInt(current_value);
            ATotal = ATotal+class_wise_allocation;            
          });

          return (
            <>
              <Table.Summary.Row className='totalRow'>
              <Table.Summary.Cell ></Table.Summary.Cell> 
                <Table.Summary.Cell className='tableTotal'>Total</Table.Summary.Cell>            
                   <Table.Summary.Cell className='tableTotal'><div style={{textAlign:'right'}}>{numberFormat(ITotal)}</div></Table.Summary.Cell>
                   <Table.Summary.Cell className='tableTotal'><div style={{textAlign:'right'}}>{numberFormat(CTotal)}</div></Table.Summary.Cell>
                   <Table.Summary.Cell className='tableTotal'><div style={{textAlign:'right'}}>{`${props.dataMain.total_xirr} %`}</div></Table.Summary.Cell>
                   <Table.Summary.Cell className='tableTotal'><div style={{textAlign:'right'}}>{(`${Math.round(ATotal)} %`)}</div></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
    />
    </div>
  )
}

export default HoldingTable
// import React, { useState,useEffect} from 'react'
// import './Cheatsheet.scss'
// import Book from '../../../Images/homePage/book.png'
// import Bhuv from '../../../Images/homePage/Bhuvna.jpg'
// import {MDBIcon} from 'mdbreact'
// import{power_of_passive_income_form} from '../Api'
// import {useNavigate} from 'react-router-dom'

// const Cheatsheet = () => {
//     const[name,setName] = useState('')
//     const[email,setEmail] = useState('')
//     const navigate = useNavigate()

//    const onchangeName=(e)=>{setName(e.target.value)}
//    const onchangeEmail=(e)=>{setEmail(e.target.value)}
//    const Download = (e)=>{
//         e.preventDefault()
//         const input = {
//          name:name,
//          email:email
//         }
//         power_of_passive_income_form(input).then(res => {
//           console.log(res)
//             try{
//                 if (res.status==='successfull'){navigate('/powerofpassiveincome/thank_uou')}
//                 else{}
//             }catch{}
//         })
//    }
//   return (
//     <div className='chtPage'>
//         <div className='chtBanner'>
//             <div className='row'>
//             <div className='col-md-1'></div>
//                 <div className='col-md-6'>
//                     <div className='chtBanHead'>
//                         Download the Ultimate Cheat<br/>Sheet on Passive Income Secrets<br/>for Business Owners</div>
//                         <p className='chtBanText'>
//                         Discover the magic formula for Building Enough Wealth<br/>and Passive Income so you can Retire comfortably<br/>within 10 years
//                         </p>
//                         <div className='chtBtn'><a href="#download"><button>DOWNLOAD NOW</button></a></div>
//                 </div>
//                 <div className='col-md-5'>
//                     <div className='bookImg'><img alt='book' src={Book}/></div>
//                 </div>
//             </div>
//         </div>

//         <section >
//             <div className='chts2Head'>Discover the Power of Passive Income</div>
//             <div className='chts2Text'>
//                 Every successful business owner dreams of the day they can step back and reap the rewards<br/>
//                 of their hard work. Our exclusive cheat sheet unveils the secrets to achieving that dream<br/>
//                 faster than you imagined.
//             </div>
//         </section>
//         <section>
//             <div className='chts3Head'>Top 3 Benefits of the Cheat Sheet</div>
//             <div className='row'>
//                 <div className='col-1'></div>
//                 <div className='col-10'>
//                     <div className='chts3Box'>
//                         <p className='chts3IH'> <MDBIcon fas icon="check-circle"/>&nbsp; &nbsp;Strategize for Success</p>
//                         <p className='chts3Text'>Edive deep into tailored strategies that guide mature business owners towards a secure financial future</p>
//                     </div>
//                     <div className='chts3Box'>
//                         <p className='chts3IH'><MDBIcon fas icon="check-circle"/>&nbsp; &nbsp;Quick and Efficient</p>
//                         <p className='chts3Text'>Quick and Efficient:* Designed for busy business owners, get actionable insights that can be implemented swiftly</p>
//                     </div>
//                     <div className='chts3Box'>
//                         <p className='chts3IH'><MDBIcon fas icon="check-circle"/>&nbsp; &nbsp;Expert Insights</p>
//                         <p className='chts3Text'>Benefit from proven methods and expert advice, ensuring your retirement is not just comfortable, but luxurious</p>
//                     </div>
//                 </div>
//                 <div className='col-1'></div>
//             </div>
//         </section>
//         <section>
//             <div className='sec4cht' id="download">
//                 <div className='sec4Head'>Download Now <MDBIcon fas icon="arrow-right" /></div>
//                 <form onSubmit={Download}>
//                     <div className='row'>
//                         <div className='col-4'><input type="text" maxLength={100} name="name" placeholder="Name"className='chtInput'onChange={onchangeName} required/></div>
//                         <div className='col-4'><input type="text" maxLength={100} name="email"placeholder="Email" className='chtInput'onChange={onchangeEmail} required/></div>
//                         <div className='col-4'>
//                             <div className='chtBtn2' ><button>DOWNLOAD NOW</button></div>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         </section>
//         <section className='chtsec5'>
//             <div className='container row'>
//             {/* <div className='col-1'></div> */}
//                 <div className='col-5'>
//                     <div className='chtsec5Img'><img src={Bhuv} alt='Bhuvna'/></div>
//                 </div>
//                 <div className='col-7'>
//                     <p className='sec5Text'>“ isn't just a Certified Financial Planner and Life Coach; she's
//                         your gateway to Lifestyle Financial Planning. With expertise
//                         spanning decades, she's helped countless mature business
//                         owners pave their paths to early and affluent retirements.
//                         House of Alpha stands as a beacon in the financial world - a
//                         SEBI-registered, fee-only firm dedicated to offering unbiased
//                         personal finance and wealth management advisory. Here,
//                         your dreams and aspirations are always at the forefront ”</p>
//                         <p className='chts5Text'>Bhuvanaa Shreeram</p>
//                         <p className='chts5D'>Certified Financial Planner - Hpuse of Alpha</p>
//                 </div>
//             </div>
//         </section>

//         <footer className='chtFoot'>
//             <div className='chtFHead'>
//             Don't Wait for Tomorrow. Start Your Journey<br/>to a <span style={{color:'#ffec00'}}>Luxurious Retirement</span> Today
//             </div>
//             <div className='chtFText'>Download the "Ultimate Cheat Sheet on Passive Income Secrets for Business Owners"<br/>and take the first step towards a future filled with leisure and affluence</div>
//             <div className='chtFBtn'><a href="#download"><button>DOWNLOAD NOW</button></a></div>
//             <div className='chtFBG'> 
//              {/* <p className='chtCC'>Copyright @ 2023</p> */}
//              </div>
          
          
//         </footer>
//     </div>
//   )
// }

// export default Cheatsheet
import React from 'react'

const Cheatsheet = () => {
  return (
    <div></div>
  )
}

export default Cheatsheet
import React,{useState,useEffect} from 'react';
import '../../UserManagement.scss'
import {Row,Col,Tabs} from 'antd';
import Margins from './Margins';
import Order from './Order';
import Positions from './Positions';
import Holdings from './Holdings';

const Equity = (props) => {
  const { TabPane } = Tabs;
  const{cusName,customer_id,cusEmail, close, tabName} = props
  const [tabs, setTabs] = useState('Holdings');
  const defaultTab = 'tab1';

  const onChange = (key) => {setTabs(key)};

  return (
    <>
      <div className='modalHeadBar'>
        <Row align='middle'>
          <Col span={6}>
            <div className='modalHeadBarTitle'>{tabName}</div>
          </Col>
          <Col span={12}>
            <div className='modalHeadBarTab'>
              <Tabs defaultActiveKey={defaultTab} onChange={onChange} size='small' centered={true}>
                <TabPane tab='Holdings' key='Holdings'></TabPane>
                <TabPane tab='Orders' key='Orders'></TabPane>
                <TabPane tab='Positions' key='Positions'></TabPane>
                <TabPane tab='Margins' key='Margins'></TabPane>
              </Tabs>
            </div>
          </Col>
          <Col span={5}>
            <div className='modalHeadBarTitle'>{cusName}</div>
            <p className='modalHeadBarP'>{cusEmail}</p>
          </Col>
          <Col span={1}>
            <div className='TableActionButtonClose' onClick={close}>Close</div>
          </Col>
        </Row>
      </div>

      <div className='modalBodyBar'>

        {tabs==='Margins'?<Margins customer_id={customer_id} cusName={cusName} cusEmail={cusEmail}/>:null}
        {tabs==='Orders'?<Order customer_id={customer_id} cusName={cusName} cusEmail={cusEmail}/>:null}
        {tabs==='Positions'?<Positions customer_id={customer_id} cusName={cusName} cusEmail={cusEmail}/>:null}
        {tabs==='Holdings'?<Holdings customer_id={customer_id} cusName={cusName} cusEmail={cusEmail}/>:null}

      </div>
    </>
  )
}

export default Equity
import React,{useState,useEffect} from 'react';
import { Table } from "ant-table-extensions";
import {customer_mf_details} from '../../Api'
const Sips = (props) => {

    const [data, setData] = useState([]);

    useEffect(()=>{
        let newdata = [...data]
        const input = {
            access_token:localStorage.token,
            type:'sips',
            id:props.customer_id
            // id:475
        }
        customer_mf_details(input).then(res => {
            console.log('sips',res);
            Object.keys(res.result).map((val)=>{
                newdata.push({ 
                key: val,
                completed_instalments:res.result[val].completed_instalments,
                created: res.result[val].created,
                customer_id: res.result[val].customer_id,
                date: res.result[val].date,
                dividend_type: res.result[val].dividend_type,
                frequency: res.result[val].frequency,
                fund: res.result[val].fund,
                instalment_amount: res.result[val].instalment_amount,
                instalment_day: res.result[val].instalment_day,
                instalments: res.result[val].instalments,
                last_instalment: res.result[val].last_instalment,
                next_instalment: res.result[val].next_instalment,
                pending_instalments: res.result[val].pending_instalments,
                sip_id: res.result[val].sip_id,
                sip_reg_num: res.result[val].sip_reg_num,
                sip_type: res.result[val].sip_type,
                status: res.result[val].status,
                step_up: res.result[val].step_up,
                tag: res.result[val].tag,
                tradingsymbol: res.result[val].tradingsymbol,
                transaction_type: res.result[val].transaction_type,
                trigger_price: res.result[val].trigger_price,
                name:res.result[val].name,
                })
            })
            setData(newdata)
          })
      },[])
      console.log(data);
      const columns = [
        {title: 'Name',dataIndex: 'name',width: 70},
        {title: 'Updated Date',dataIndex: 'date',width: 90},
        {title: 'Customer Id',dataIndex: 'customer_id',width: 70},
        {title: 'SIP Id',dataIndex: 'sip_id',width: 90},
        {title: 'Completed Instalments',dataIndex: 'completed_instalments',width: 80},
        {title: 'Created',dataIndex: 'created',width: 70},
        {title: 'Fund',dataIndex: 'fund',width: 90},
        {title: 'Frequency',dataIndex: 'frequency',width: 70},
        {title: 'Instalment Amount',dataIndex: 'instalment_amount',width: 80},
        {title: 'Instalment Day',dataIndex: 'instalment_day',width: 100},
        {title: 'Instalments',dataIndex: 'instalments',width: 70},
        {title: 'Last Instalment',dataIndex: 'last_instalment',width: 70},
        {title: 'Next Instalment',dataIndex: 'next_instalment',width: 70},
        {title: 'Pending Instalments',dataIndex: 'pending_instalments',width: 85},
        {title: 'Dividend Type',dataIndex: 'dividend_type',width: 70},
        {title: 'SIP Reg Num',dataIndex: 'sip_reg_num',width: 90},
        {title: 'SIP Type',dataIndex: 'sip_type',width: 90},
        {title: 'Status',dataIndex: 'status',width: 90},
        {title: 'Step Up',dataIndex: 'step_up',width: 70},
        {title: 'Tag',dataIndex: 'tag',width: 70},
        {title: 'Trading Symbol',dataIndex: 'tradingsymbol',width: 90},
        {title: 'Transaction Type',dataIndex: 'transaction_type',width: 70},
        {title: 'Trigger Price',dataIndex: 'trigger_price',width: 70},
      ]
  return(
      <>
        <Table
              rowClassName="editable-row"
              columns={columns}
              dataSource={data}
              size='small'
              scroll={{ x: 1150, y: 400 }}
              pagination={{
                pageSize: 10,
                position:['bottomCenter']
              }}
              sticky={true}
              exportable
          />

      </>
  );
};

export default Sips;

import React,{useState,useEffect} from 'react'
import { Table,Row,Col,Drawer,Select,message,Typography,Popconfirm,Form,Input} from 'antd';
import {EyeOutlined,EditFilled, CloseCircleFilled,DeleteFilled,SaveOutlined } from '@ant-design/icons';
import { newsletter_get,newsletter_put,newsletter_delete } from '../Api';
import Moment from 'moment';
import Loader from '../../../shared/Loader';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Newsletter = (props) => {
    const [data, setData] = useState([]);
    const [newsletter,setNewsletter] = useState('')
    const [status,setStatus] = useState('')
    const [date,setDate] = useState(new Date())
    const [loading,setLoading] = useState(false)
    const [detailData,setDetailData] = useState({})
    const [modalType,setModalType] = useState('')
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    const toastMsg =(id,msg,type)=>{
      toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    }

    const {Option} = Select;
    
    const EditableCell = ({
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      disable,
      ...restProps
    }) => {


      const inputNode = title === 'Status'?
                                  <Select>
                                    <Option value="Y">Y</Option>
                                    <Option value="N">N</Option>
                                  </Select>:
                                  inputType === 'remarks' ? <Input/>:<Input/>
                                    
    return (
        <td {...restProps}>
    {editing? (
      <Form.Item
        name={dataIndex}
        rules={[{required: true,message: `Please Input ${title}!`}]}>
        {inputNode}
      </Form.Item>
      ) : (
      children
    )}
  </td>
  );
 };

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      status: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const getNewsletterList= async () =>{
    setLoading(true)
      let newdata = []
      const input = {access_token:localStorage.token}
      const res = await newsletter_get(input)
      try{
        if (res && res.status==='successfull'){ 
          setLoading(false)
          Object.keys(res.result).map((val)=>{
            newdata.push({ 
              key: res.result[val].newsletter,
              newsletter:res.result[val].newsletter,
              date:res.result[val].date,
              data:res.result[val].data,
              status:res.result[val].status 
            })
            })
            setData(newdata)
            setLoading(false)
          }
          else{setLoading(false); toastMsg(false,res.message,'error');}
        }catch{setLoading(false); toastMsg(false,'Something went wrong','error');}
      }
    useEffect(()=>{
      getNewsletterList()
    },[])


    const sumbitNewsLetter = (e) => {
          const id = toast.loading("Please wait...",{toastId:'load'})
          e.preventDefault();
          let data = new FormData();
          data.append('newsletter', newsletter);
          data.append('date', date);
          data.append('status', status);
        fetch('/alpha_admin/newsletter_upload', {
          method: 'POST',
          body: data,
        })
          .then(res => res.json())
          .then((data) => {
            console.log(data);
            if(data.status==='successfull'){
              toastMsg(id,data.message,'success')
              setVisible(false)
              setNewsletter('')
              setDate('')
              getNewsletterList();
            }
                else{toastMsg(id,data.message,'error')}
          })
          .catch(() => {toastMsg(id,'Something went wrong','error')})
        };

    const save = async (key) => {
      const id = toast.loading("Please wait...",{toastId:'load'})
        try {
    const row = await form.validateFields();
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
            setData(newData);
            setEditingKey('');
          const input = {
            access_token:localStorage.token,
            row:newData[index],
            filename:newsletter
          }
            newsletter_put(input).then(res => {
              console.log(res);
              try{
                if (res && res.status==='successfull'){
                  toastMsg(id,res.message,'success')
                  getNewsletterList();
                  }
                else{toastMsg(id,res.message,'error')}
              }catch{toastMsg(id,'Something went wrong','error')}
            })
          } else {
            newData.push(row);
            setData(newData);
            setEditingKey('');
            toastMsg(id,data.message,'error')
          }
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
          toastMsg(id,'Something went wrong','error')
        }
      };

  const handleDelete=(record)=>{
    const id = toast.loading("Please wait...",{toastId:'load'})
    const input={
      access_token:localStorage.token,
      row:record.date
  }
    newsletter_delete(input).then(res => {
    try{
    if (res.status==='successfull') {
      toastMsg(id,res.message,'success')
      getNewsletterList();
    }
    else{toastMsg(id,res.message,'error')}
    }
    catch{toastMsg(id,'Something went wrong','error')}
  })  
  }
    
  const showDrawerAction=(type,record)=>{
    setVisible(true);
    setModalType(type)
    setDetailData(record)
  }  
  const onClose = () => {
    setVisible(false);
    setModalType('')
    setDetailData({})
  };

  const onChangeDate = (e,val) => {
    setDate(e.target.value)
  }

  let today=Moment(new Date()).format('YYYY-MM')

  const handleImage = (e) => {
    setNewsletter(e.target.files[0])
  }

const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 80,
    },
    {
        title: 'Newsletter',
        dataIndex: 'newsletter',
        width: 300,
        
      },
      {
        title: 'Status',
      dataIndex: 'status',
      width: 100,
      editable: true,
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        width: 200,
        render: (_, record) => {
          const editable = isEditing(record);
        return editable ? (
              <>
                <Typography.Link onClick={() => save(record.key)} style={{marginRight: 8,}}>
                  <span className='outlineSaveButton'>
                  <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
                  </span>
                </Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
                </Popconfirm>
              </>):(
                <>
                <span style={{marginRight:'10px'}}>
                <Typography.Link  onClick={() => showDrawerAction('Newsletter Details',record)}>
                    <span className='outlineEditButton'> <EyeOutlined style={{ fontSize: '13px', color: '#F7DC6F', marginRight:'3px' }} />View</span>
                </Typography.Link>
                </span>
                
                <Typography.Link disabled={editingKey !== ''}
                  onClick={() => edit(record)}>
                  <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
                </Typography.Link>
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                  <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'10px' }} />
                </Popconfirm>
                </>
                
              )
            },
         }, 
       ];

       const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            }),
          };
        });

  return (
        <>
          <ToastContainer theme='colored'/>
          {loading?<Loader/>:null}
          <Row>
          <Form form={form} component={false}>
            <Col span={24}>
                  <Table
                    components={{
                    body: {
                        cell: EditableCell,
                      },
                    }}
                    rowClassName="editable-row"
                    columns={mergedColumns}
                    dataSource={data}
                    size='small'
                    scroll={{y: 400 }}
                    sticky={true}
                  />
            </Col>
          </Form>
          </Row>
                 
          <div className='addBtn'><button onClick={()=>showDrawerAction('Add Newsletter')}>Add Newsletter</button></div>

          <Drawer title={modalType} placement="top" height={'600'} onClose={onClose} visible={visible}>
            {modalType==='Add Newsletter'?
              <div>
                  <span>Date</span>
                  <span className='addNewsletter'>
                  <input type="month" placeholder='' name="date" value={date} 
                    onChange={onChangeDate}  
                    required max={today}
                  />
                  </span>
                            
                  <span className='newsletterFile'>File</span>
                  <span className='addNewsletter'>
                  <input type="file" accept="application/pdf"  onChange={handleImage} /></span>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary" onClick={sumbitNewsLetter}data-dismiss="modal">Save Newsletter</button>
                </div>
              </div>:null}
            
            {modalType==='Newsletter Details'?
            <>
              {detailData?
                <div className='adminLinkedinVideo'>
                  <iframe src={`data:application/pdf;base64,${detailData.data} #view=fitH`} title="testPdf" height="100%" width="100%" />
                </div>
              :null}
            </>:null}
          </Drawer>
        </>
      )
    }

export default Newsletter
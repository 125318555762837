import React,{useState,useEffect} from 'react';
import './Update.scss'
import {Button,Row,Col,message,Select,Upload ,Tooltip} from 'antd';
import {DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined,UploadOutlined,DownloadOutlined} from '@ant-design/icons';
import {index_fund_file} from '../Api'
import IndexMFSample from '../../../../Files/Index MF classification Sample.xlsx'

const Index_fund_doc = () => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList,setFileList] = useState([])
    const [holdingUploading,setHoldingUploading] = useState(false)

    let pdf1=null



    const download=()=>{

        const input = {
            access_token:localStorage.token,
            // flag:name,
            // id:id,
            // filename: 'filename'
        }
        console.log(input);
        index_fund_file(input).then(res => {
            console.log(input);
            console.log("resss",res)
            try {
              if (res.status==='successfull') {
                pdf1=res.result
                const linkSource = `data:application/vnd.ms-excel;base64,${pdf1}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = "index_fund.xlsx";
                downloadLink.click();
            }
                else{}
            } catch (error) {}
            })
    }
    const handleUploadHoldings = () => {
        const formData = new FormData();
        fileList.forEach(file => {formData.append('csv_file', file)});
        // formData.append('flag', 'holdings')
        setHoldingUploading(true)
        fetch('/alpha_admin/index_fund_file', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': localStorage.token
          },
        })
          .then(res => res.json())
          .then((data) => {
            console.log(data);
            if(data.status==='successfull'){
              
              message.success(data.message)}
              setFileList([])
            if(data.status==='failed'){
            message.error(data.message)}
            setHoldingUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setHoldingUploading(false);
          })
          .finally(() => {});
      };

      const props = {
        maxCount:1,
        accept:'.csv, .xlsx',
        beforeUpload: file => {
          setFileList( [...fileList, file])
          return false;
        },
        fileList,
      };

  return (
    <div>
    
        <div className='equityDocsMainDiv'>
            <span className='equityDocshead'>Index Fund</span>
            <span className='equityDocsSampletext'><span className='equityDocsSampletext2'>Download a sample design to upload an Index Fund Excel.</span><Button><a  href={IndexMFSample} download='Index MF classification Sample'> Download</a></Button></span>
        </div>
        <Row>
        <Col span={12}>
        <div className='equityTitleDiv'>
        <div className='equityTitleheadUpload'>Upload Here</div>
        <div className='equityDocsMainDiv'>
           <Upload
            {...props}
            ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
            <Button
              type="primary"
              onClick={handleUploadHoldings}
              disabled={fileList.length === 0}
              loading={holdingUploading}
              className='startUploading'
              onRemove={true}
            >
              {holdingUploading ? 'Uploading' : 'Start Upload'}
            </Button>
          </div>
          </div>

        </Col>
        <Col span={12}>
        <div className='equityTitleDiv'>
          <div className='equityTitlehead'>Download Here</div>
          <Button onClick={()=>download()}>Download</Button>
        </div>

        </Col>
    </Row>

    </div>
  )
}

export default Index_fund_doc
import React, { useState,useEffect} from 'react'
import { Table } from "ant-table-extensions";
import Loader from '../../../shared/Loader';
import {DatePicker,Input,Space,Button} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {all_clients_orders_and_holdings} from './Api'
import Highlighter from 'react-highlight-words';

const Hoqreport = (props) => {
    const [data, setData] = useState([]);
    const [cDate, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading,setLoading] = useState(false)
    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')


    const onChange = (date, dateString) => {
      fetchClassWiseData(dateString)
    };

    let searchInput= ''
  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
      // style: 'currency',
      // currency: 'INR',
      minimumFractionDigits:0
  }).format(value);


  const fetchClassWiseData = (date)=>{
    setLoading(true)
    let newdata = []
    const input = {
      access_token:localStorage.token,
      // type:'class_name',
      date:date,
      download:'N'
  }
  console.log(input);
  all_clients_orders_and_holdings(input).then(res => {
    console.log('year',res);
    setLoading(false)
    try{
      if (res.status==='successfull'){
    Object.keys(res.result).map((val)=>{
        Object.keys(res.result[val]).map((val1)=>{
            newdata.push({ 
                zerodha_id: val,
                name:res.result[val][val1].name,
                holdings_quantity:res.result[val][val1].holdings_quantity,
                orders_quantity:res.result[val][val1].orders_quantity,
                tradingsymbol:res.result[val][val1].tradingsymbol,
                user_id:res.result[val][val1].user_id,
                family_member_id:res.result[val][val1].family_member_id,

                })
        })
        
    })
    setData(newdata)
  }else{
    setLoading(false)
  }
}catch{
  setLoading(false)
}
  }) 
  }

  useEffect(()=>{
    fetchClassWiseData(cDate)
  },[])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };
  
  const  handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };


  let getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => {
            handleReset(clearFilters)
            confirm({ closeDropdown: false });
            setSearchedColumn(dataIndex)
            }} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

//   console.log(data);
  const column = [   
    {title: 'zerodha Id',dataIndex: 'zerodha_id',...getColumnSearchProps('zerodha_id')},
    {title: 'Customer Id',dataIndex: 'user_id',...getColumnSearchProps('user_id')},
    {title: 'Name',dataIndex: 'name',...getColumnSearchProps('name')},
    {title: 'Family Member Id',dataIndex: 'family_member_id',...getColumnSearchProps('family_member_id')},
    {title: 'Trading Symbol',dataIndex: 'tradingsymbol',...getColumnSearchProps('tradingsymbol')},
    {title: 'Holdings Quantity',dataIndex: 'holdings_quantity'},
    {title: 'Order Quantity',dataIndex: 'orders_quantity'},
  ]

  return (

     <div> 
     {loading?<Loader/>:null}
         <>
         <div className='reportRangebox'>
             <DatePicker onChange={onChange} placeholder={cDate}
             disabledDate={(current) => {
             return current && current.valueOf() > Date.now();
             }} />
         </div>
          <Table rowClassName="antTable" columns={column} dataSource={data} size='small'
              scroll={{y: 350}}
              pagination={true} 
              sticky={true}
              exportableProps={{ fileName: `holding_order_quantity_report_${cDate}`}}
          />   
         </>
     </div>
  )
}

export default Hoqreport
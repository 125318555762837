import axios from 'axios'

export const class_and_scheme_assets = input => {
    return axios
        .get('/alpha_admin/class_and_scheme_assets',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
                id:input.id,
                type: input.type,
                date:input.date,
                download:input.download
                // start_date:input.start_date,
                // end_date:input.end_date
              }

          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const all_clients_orders_and_holdings = input => {
    return axios
        .get('/alpha_admin/all_clients_orders_and_holdings',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
                id:input.id,
                date:input.date,
                download:input.download,
                // type:input.type,
                // start_date:input.start_date,
                // end_date:input.end_date
              }

          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const MoM_reports = input => {
    return axios
        .get('/alpha_admin/MoM_reports',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
                id:input.id,
                type: input.type,
                start_date:input.start_date,
                end_date:input.end_date,
                download:input.download
              }

          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const uplaods_to_azure_get = input => {
    return axios
        .get('/alpha_admin/uplaods_to_azure',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {               
              document_name:input.document_name
              
              }

          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  
  export const uplaods_to_azure = input => { 
    return axios
        .post('/alpha_admin/uplaods_to_azure', {  
            docs:input.docs
        },
        {
          headers: {
            'Authorization': input.access_token
          }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const html_content = input => {
    return axios
        .get('/alpha_admin/html_content',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {               
              preview:input.preview,
              email:input.email,
              id:input.cus_id,
              email_list:input.email_list
              }

          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
import axios from 'axios'

export const logout = () => {
    return axios
        .post('/alpha_user/logout')
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const email_setting = input => {
return axios
    .post('/alpha_user_db/email_setting', {
            email:input.email,
            password :input.password,
    },
    {
        headers: {
            'Authorization': input.access_token
        }
        })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const mobile_setting = input => {
    return axios
        .post('/alpha_user/mobile_setting', {
                mobile_no:input.mobile_no,
                password :input.password,
        },
        {
            headers: {
                'Authorization': input.access_token
            }
            })
        .then(response => {
            return response.data
        })
        .catch(err => {})
    }

  export const account_setting = input => {
    return axios
        .post('/alpha_user/account_setting', {
               flag:input.flag,
               email:input.email,
               mobile_no:input.mobile_no,
               password :input.password,
               newPassword:input.newPassword
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const recommendation = input => {
    return axios
        .get('/alpha_user/recommendation',
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }

 
export const calculated_funds = input => {
    return axios
    .get('/alpha_user_db/calculated_funds',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          id:input.id
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const calculated_funds_post = input => {
    return axios
    .post('/alpha_user_db/calculated_funds', {
           data:input.data,
           growth_rates:input.growth_rates
    },
    {
        headers: {
          'Authorization': input.access_token
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const alpha_plans = input => {
    return axios
    .get('/alpha_user/alpha_plans',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          id:input.id
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const asset_information = input => {
    return axios
    .get('/alpha_user/asset_information',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          id:input.id
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const liability_information = input => {
    return axios
    .get('/alpha_user/liability_information',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          id:input.id
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const insurance_information = input => {
    return axios
    .get('/alpha_user/insurance_information',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          id:input.id
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const goal_information = input => {
    return axios
    .get('/alpha_user/goal_information',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          id:input.id
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const sips = input => {
    return axios
    .get('/alpha_user/sips',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          id:input.id
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const cas_upload = input => {
    return axios
    .get('/alpha_user/cas_upload',
    {
        headers: {
          'Authorization': input.access_token
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const dashboard_data = input => {
  return axios
  .get('/alpha_user/dashboard_data',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}

export const other_assets = input => {
  return axios
  .get('/alpha_user/other_assets',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const other_assets_post = input => {
  return axios
  .post('/alpha_user/other_assets',
  {
    id:input.asset_id,
    asset_type:input.asset_type,
    amount:input.amount
  },
  {
      headers: {
        'Authorization': input.access_token
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const other_assets_put = input => {
  return axios
  .put('/alpha_user/other_assets',
  {
    id : input.asset_id,
    editamount:input.editamount
  },
  {
      headers: {
        'Authorization': input.access_token
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const other_assets_delete = input => {
  return axios
      .delete('/alpha_user/other_assets',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            asset_id:input.asset_id,
          }
        },)
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const execute_recom = input => {
  return axios
  .put('/alpha_user/execute_recommendation',
  {
    date:input.date,
    instruments_type:input.instruments_type,
  },
  {
      headers: {
        'Authorization': input.access_token
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const dashboard_information = input => {
  return axios
  .get('/alpha_user_db/dashboard_information',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const asset_card = input => {
  return axios
  .get('/alpha_user_db/asset_card',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const liability_card = input => {
  return axios
  .get('/alpha_user_db/liability_card',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const insurance_card = input => {
  return axios
  .get('/alpha_user_db/insurance_card',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const budget_card = input => {
  return axios
  .get('/alpha_user_db/budget_card',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const goal_card = input => {
  return axios
  .get('/alpha_user_db/goal_card',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const user_name = input => {
  return axios
  .get('/alpha_user_db/user_name',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}


import React,{useState,useEffect} from 'react'
import {customer_review} from '../Api'
import { MoM_reports } from './Api';
import { Select,DatePicker,Table} from 'antd';
import Loader from '../../../shared/Loader';
import Momequityreporttable from './Momequityreporttable';
import moment from 'moment'

const Momequityreport = (props) => {
    
    const [adata, setaData] = useState([]);
    const [cdata,setcData] =useState([])
    const [clientId,setClientId] = useState('')
    const [pickerValue, setPickerValue] = useState(moment(new Date()));
    const [loading,setLoading] = useState(false)
    const [dateSend, setdateSend] = useState([])
    const [cDate, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [dd, setDD] = useState(new Date().toISOString().split('T')[0]);

    const { Option } = Select;
    let pdf1=null
    
    const { RangePicker } = DatePicker;

    const numberFormat=(value)=>
    new Intl.NumberFormat('en-IN', {
      // style: 'currency',
      // currency: 'INR',
      minimumFractionDigits:0
  }).format(value);

    const updateclientID=(value)=>{     
      setClientId(value)
  }
  const onChangeDate = (date, dateString) => {
    setdateSend(dateString)
    setDD(dateString)
  };
  
  
    
    const submitData =()=>{
      setLoading(true)
      const input = {
          access_token:localStorage.token,
          type:'equity',
          id:clientId,
          download:'N',
          start_date:dateSend[0],
          end_date:dateSend[1],
      }
      console.log(input);
      MoM_reports(input).then(res => {
        console.log('holding',res);
        try{
          if (res.status==='successfull'){
              setLoading(false)
              setaData(res.result)
          }else{
            setLoading(false)
          }
        }catch{
          setLoading(false)
        }
        })
      
    }

   
    

    const fetchClient = ()=>{
        const input = {
            access_token:localStorage.token
        }
        let newdata = [...cdata]
        customer_review(input).then(res => {
            console.log(res,'ressCLI')
        try{
            if (res.status==='successfull'){
                Object.keys(res.result).map((val)=>{
                    newdata.push({
                    key: res.result[val].customer_id,
                    customer_fullname:res.result[val].customer_fullname,
                    id: res.result[val].customer_id,
                    email_address:res.result[val].email_address,
                    zerodha:res.result[val].zerodha
                })
                })
                setcData(newdata)
            }else{}
        }catch{}
        })
    }
    

    
    useEffect(()=>{
        fetchClient()
      },[])    
      

      const exportData=(name)=>{
        const input = {
            access_token:localStorage.token,
            id:clientId,
            type:'equity',
            download:'Y',
            start_date:dateSend[0],
            end_date:dateSend[1],
        }
        console.log(input);
        MoM_reports(input).then(res => {
          console.log('report',res);
          try{
            if (res.status==='successfull'){
              pdf1=res.result
              const linkSource = `data:application/vnd.ms-excel;base64,${pdf1}`;
              const downloadLink = document.createElement("a");
              downloadLink.href = linkSource;
              downloadLink.download = `${name}_Equity_MoM_report_${cDate}.xlsx`;
              downloadLink.click();
            }else{}
          }catch{}
          })
      }
      console.log(adata);
  return (
    <div>
      {loading?<Loader/>:null}
      <Select
        style={{width:'40%'}}
        showSearch
        bordered={true}
        title='Client Mail ID'
        className='selectborder'
        placeholder="Select Client Mail ID"
        optionFilterProp="children"
        onChange={updateclientID}
        filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        // size='large'
        >
        {Object.keys(cdata).map((val)=>{
            return <Option value={cdata[val].id}>{cdata[val].email_address}, <b>{cdata[val].customer_fullname}</b></Option>
            })}
      </Select>
      <span style={{marginLeft:'10px'}}>
          <RangePicker 
              onChange={onChangeDate} allowClear={true}
              defaultValue={[pickerValue, pickerValue]} 
          />
          <button className='reportBtn' onClick={submitData}>Submit</button>
          <div className='PagesCommentIconDiv'>
            <span className='valueInRs'>value in (₹)</span>
          </div>
      </span>

          {Object.keys(adata).length>0?(
        <>
          {Object.keys(adata).map((val)=>{
            console.log(adata[val].data)
            if(adata[val]===null){
              return(<div style={{textAlign:'center', padding:'10px'}}>No Data</div>)
            }else{
              return(
                <div >
                  
                  <div className='holFamCard'>
                    <div className='famName'>{val}</div>
                    <div className='csvDwn'><button onClick={(name)=>exportData(val)}>Export</button></div>          
                    <Momequityreporttable id={props.id} dataMain={adata[val].data}/>
                  </div>
                  </div>
              )
            }
          })}
        </>
      ):null}


        
    </div>
  )
}

export default Momequityreport

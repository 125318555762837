import React, { useState,useEffect} from 'react'
import '../Tabs.scss'
import DonutChart from './DonutChart';
import {expense_age_meter} from '../../Api'

import { Row, Col,InputNumber,Slider} from 'antd';

const CashflowPie = (props) => {
  const [cashFlowData, setCashFlowData] = useState({});
  const [age, setAge] = useState(0);
  const [inputValue, setInputValue] = useState(0);

  const onChange = (newValue) => {
    setInputValue(newValue);
};

  const fetchAgeData = async () =>{
    const input = {access_token:localStorage.token,id:props.id}
    const res = await expense_age_meter(input)
    .catch((err) => {console.log("Err: ", err)});
    console.log('age',res);
    try{
      if (res.status==='successfull'){
          setCashFlowData(res.result.expense_breakup)
          setAge(res.result.age)
          setInputValue(res.result.age)
      }else{}
    }catch{}
  }
  useEffect(()=>{
    fetchAgeData()
  },[])

  let arr =[]
  let total=0
  if(Object.keys(cashFlowData).length>0 && age>0){
    Object.keys(cashFlowData[inputValue].breakup).map((val)=>{
      arr.push({type: val,value:cashFlowData[inputValue].breakup[val]})
    })
    total =  cashFlowData[inputValue].total
  }

  return (
    <>
      <Row gutter={[16, 16]}>
            <Col span={13}>
              <div className='casflowPieCard'>
                <div className='PageComponentHead'>EXPENSE CHART</div>
                <div className='cashflowChartArea'>
                  {Object.keys(cashFlowData).length>0?
                    <DonutChart cashFlowData={arr} total={total}/>:null}
                  </div>
              </div>
            </Col>
            <Col span={11}>
              <div className='casflowPieCard'>
                <div className='PageComponentHead'>AGE METER</div>
                <div className='cashflowChartArea'> 
                  <div>
                      <Slider
                          min={age}
                          max={100}
                          onChange={onChange}
                          value={typeof inputValue === 'number' ? inputValue : 0}
                          // autoFocus={true}
                          trackStyle={{background:'#e87c1b',height:'6px'}}
                          handleStyle={{background:'#fff',border:'3px solid #e87c1b'}}
                      />
                  </div>
                  <div>
                      <InputNumber
                          min={age}
                          max={100}
                          style={{
                              margin: '0 16px',
                          }}
                          value={inputValue}
                          disabled
                          // onChange={onChange}
                      />
                  </div>
                </div>
              </div>
            </Col>
        </Row>
    </>
  )
}

export default React.memo(CashflowPie)
import React,{useState,useEffect} from 'react';
import './CrmStatus.scss'
import {Row,Col,Checkbox,Form,Select,} from 'antd';

const SetCrm = (props) => {
    const{cusName,customer_id,cusEmail, close, tabName} = props
    const { Option } = Select;
   
    const dataDict = [
        {'Zerodha Account Opening':{check:'Y', resp:''}},
        {'Lumpsum Investment':{check:'Y', resp:''}},
        {'Monthly Investment SIP':{check:'Y', resp:''}},
        {'Currency Strategy':{check:'Y', resp:''}},
        {'CCO strategy ':{check:'Y', resp:''}},
        {'Consolidation':{check:'Y', resp:''}},
    ]
   
  return (
    <>
      <Row gutter={10}>
        <Col span={8}>
            <div className='modalBodyBar'>
                <div className='modalBodyBarTitle'>Plan Implementation</div> 
                {Object.keys(dataDict).map((val)=>{
                    console.log(val);
                    return(
                        <div className='setCrmDiv'> 
                            <Checkbox/>
                            <span className='setCrmspan'>Zerodha Account Opening</span>
                            <span className='setCrmspanSelect'>
                                <Select  placeholder="Select" size='small' style={{fontSize:'11px',width:'40%',padding:'1px'}}>
                                    <Option value='sdfsd'>sdfszfjhbdsfjsbvjbvkdbdjsdjfjfjfd</Option>
                                    <Option value='sdfsd'>sdfsd</Option>
                                    <Option value='sdfsd'>sdfsd</Option>
                                    <Option value='sdfsd'>sdfsd</Option>
                                    <Option value='sdfsd'>sdfsd</Option>
                                    <Option value='sdfsd'>sdfsd</Option>
                                </Select>
                            </span>
                        </div>
                    )
                })}
                <div style={{textAlign:'center',marginTop:'10px'}}>
                    <button className='dateSubmitButton'>Submit</button>
                </div>
                    
            </div>
        </Col>
      </Row>
    </>
  )
}

export default SetCrm
import React, { useState,useEffect} from 'react'
import './Cover.scss'
import {Table,Input,Space,Button} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {MDBIcon} from 'mdbreact'
import AddComment from '../comment/AddComment';

const Tables = (props) => {
    const [tab,setTab] = useState({life:true,health:false,others:false})
    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')
    const [lifeData, setLifeData] = useState([]);
    const [healthData, setHealthData] = useState([]);
    const [otherData, setOtherData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    let searchInput = ''

    const numberFormat=(value)=>{
      var val = Math.abs(value)
      if (val >= 10000000) {
        val = (val / 10000000).toFixed(2) + ' Cr';
      } else if (val >= 100000) {
        val = (val / 100000).toFixed(2) + ' L';
      }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
      return val;
    }

    useEffect(()=>{
      let arr = []
      let arr2 = []
      let arr3 = []
        Object.entries(props.tableData.LIFE).map((val)=>arr.push(val[1]))
        Object.entries(props.tableData.HEALTH).map((val)=>arr2.push(val[1]))
        Object.entries(props.tableData.OTHERS).map((val)=>arr3.push(val[1]))
        setLifeData(arr)
        setHealthData(arr2)
        setOtherData(arr3)
    },[])

    console.log(props.tableData);

    const handleTab=(flag)=>{
        let dict = {...tab}
        Object.keys(dict).map(item => {
            if(item===flag){dict[item] = true
            }else{dict[item] = false}
            });
            setTab(dict)
    }
    let getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => {
                handleReset(clearFilters)
                confirm({ closeDropdown: false });
                // setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                }} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#e87c1b' : '#000' }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
      };
      const  handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };
      const handleModal = () => {setIsModalVisible(!isModalVisible);};

      const columns = [
        {title: 'SR. NO',dataIndex: 's_no',width: 60, ...getColumnSearchProps('s_no')},
        {title: 'NAME OF INSURED',dataIndex: 'Name of insured',width: 150,...getColumnSearchProps('Name of insured')},
        {title: 'POLICY NAME',dataIndex: 'policy name',width: 120,...getColumnSearchProps('policy name')},
        {title: 'SUM INSURED',dataIndex: 'sum_insured',width: 120,...getColumnSearchProps('sum_insured'),
        render: total => { return numberFormat(total)}},
        {title: 'ANNUAL PREMIUM',dataIndex: 'annual_premium',width: 120,...getColumnSearchProps('annual_premium'),  render: total => { return numberFormat(total)}},
        {title: 'MATURITY',dataIndex: 'maturity_date',width: 120,...getColumnSearchProps('maturity_date')}
      ];
    const healthColumns = [
      {title: 'SR. NO',dataIndex: 's_no',width: 60, ...getColumnSearchProps('s_no')},
      {title: 'NAME OF POLICY',dataIndex: 'Name_of_policy',width: 150,...getColumnSearchProps('Name_of_policy')},
      {title: 'Cover',dataIndex: 'Cover',width: 120,...getColumnSearchProps('Cover')},
      {title: 'Amount Of Cover',dataIndex: 'amount_of_cover',width: 120,...getColumnSearchProps('amount_of_cover'),  render: total => { return numberFormat(total)}},
      {title: 'ANNUAL PREMIUM',dataIndex: 'annual_premium',width: 120,...getColumnSearchProps('annual_premium'),  render: total => { return numberFormat(total)}},
      {title: 'RENEWAL DATE',dataIndex: 'renewal_date',width: 120,...getColumnSearchProps('maturity_date')}
      ];
      const otherColumns = [
        {title: 'SR. NO',dataIndex: 's_no',width: 60, ...getColumnSearchProps('s_no')},
        {title: 'NAME OF POLICY',dataIndex: 'name_of_policy',width: 150,...getColumnSearchProps('name_of_policy')},
        {title: 'Asset Cover',dataIndex: 'assets_cover',width: 120,...getColumnSearchProps('assets_cover')},
        {title: 'Amount Of Cover',dataIndex: 'amount_of_cover',width: 120,...getColumnSearchProps('amount_of_cover'),  render: total => { return numberFormat(total)}},
        {title: 'ANNUAL PREMIUM',dataIndex: 'annual_premium',width: 120,...getColumnSearchProps('annual_premium'),  render: total => { return numberFormat(total)}},
        {title: 'RENEWAL DATE',dataIndex: 'renewal_date',width: 120,...getColumnSearchProps('maturity_date')}
        ];
  return (
    <div className='tableContainer'>
        <div className='tableTabContainer'>
            <div className={tab.life?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('life')}>Life Insurance</div>
            <div className={tab.health?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('health')}>Health Insurance</div>
            <div className={tab.others?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('others')}>Other Insurance</div>
               <div className='PagesCommentIconDiv'>
              <MDBIcon className='PagesCommentIcon' icon="comment-dots" onClick={handleModal}/>
            </div>
        </div>

        <div>
          {tab.life?
            <Table rowClassName="antTable" columns={columns} dataSource={lifeData} size='small'
              // pagination={{pageSize: 5,position:['bottomCenter']}} sticky={true}
              pagination={false}/>:
          null}

          {tab.health?
            <Table rowClassName="antTable" columns={healthColumns} dataSource={healthData} size='small'
              // pagination={{pageSize: 5,position:['bottomCenter']}}
              pagination={false} 
              sticky={true}/>:
          null}

          {tab.others?
            <Table rowClassName="antTable" columns={otherColumns} dataSource={otherData} size='small'
              // pagination={{pageSize: 5,position:['bottomCenter']}} 
              scroll={{y: 220}}
              pagination={false} 
              sticky={true}/>:
          null}
        </div>
        {isModalVisible? <AddComment handleModal={handleModal}/>:null}


    </div>
  )
}

export default React.memo(Tables)
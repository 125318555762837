import React,{useEffect,useState} from 'react'
import { Row, Col,Table,Drawer,Popconfirm, Typography,Modal,Button,message} from 'antd';
import {video_links_post,video_links_get,video_links_put,video_links_delete} from '../Api'
import Loader from '../../../shared/Loader';
import {DeleteFilled,EditFilled,EyeOutlined} from '@ant-design/icons';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [detailData,setDetailData] = useState({}) 
    const [modalType,setModalType] = useState('')
    const [title, setTitle] = useState('')
    
    const [link, setLink] = useState('')
    const [loading,setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    const toastMsg =(id,msg,type)=>{
      toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    }

        const getBlogVideo= async () =>{
          setLoading(true)
          let newdata = []
            const input = {access_token:localStorage.token}
            const res = await video_links_get(input)
            console.log('blogvideo',res);
            try{
              if (res && res.status==='successfull'){ 
                setLoading(false)
                Object.keys(res.result).map((val)=>{
                  newdata.push({ 
                    key: res.result[val].title,
                    title:res.result[val].title,
                    date:res.result[val].date,
                    link:res.result[val].link, 
                })
                })
                setData(newdata)
                setLoading(false)
              }
              else{setLoading(false); toast.error(res.message);}
            }catch{setLoading(false); toast.error('Something went wrong');}
          
          }
          useEffect(()=>{
            getBlogVideo()
          },[])

  const handleTitle = (e) => {
        setTitle(e.target.value)
    }
  const handleLink = (e) => {
        setLink(e.target.value)
    }
  const handleBlogChange = (e) =>{
      let aa = {...detailData}
      aa[e.target.name] = e.target.value
      setDetailData(aa)
  }
  const handleSave=()=>{
      const id = toast.loading("Please wait...",{toastId:'load'})
      const input={
          access_token:localStorage.token,
          row:detailData
      }
      console.log("input",input)
      video_links_put(input).then(res => {
        console.log("res",res)
    try{
    if (res.status==='successfull') {
      toastMsg(id,res.message,'success')
      setVisible(false)
      getBlogVideo();
      }
      else{toastMsg(id,res.message,'error')}
      }catch{setLoading(false); toastMsg(id,'Something went wrong','error');}
  })
  }

  const showDrawerAction=(type,record)=>{
    setVisible(true);
    setModalType(type)
    setDetailData(record)
  }   
  const onClose = () => {
      setVisible(false);
      setModalType('')
      setDetailData({})
    };
    
    const submitData = () =>{
        const id = toast.loading("Please wait...",{toastId:'load'})
        const input ={
            access_token:localStorage.token,
            title:title,
            link:link
        }
        video_links_post(input).then(res => {
            console.log("saveVideo",res)
            try{
            if (res.status==='successfull'){
                toastMsg(id,res.message,'success')
                setVisible(false)
                getBlogVideo();
                setIsModalVisible(false)
                setTitle('')
                setLink('')
            }
            else{toastMsg(id,res.message,'error')}
            }catch{toastMsg(id,'Something went wrong','error')} 
            })
    }
      const handleDelete=(record)=>{
        const id = toast.loading("Please wait...",{toastId:'load'})
        const input={
            access_token:localStorage.token,
            row:record.title
        }
        console.log("input",input)
        video_links_delete(input).then(res => {
          console.log("res",res)
      try{
      if (res.status==='successfull') {
        toastMsg(id,res.message,'success')
        getBlogVideo();
    }
    else{toastMsg(id,res.message,'error')}
    }catch{toastMsg(id,'Something went wrong','error')}
    })  
    }

    const showModal = () => {
      setIsModalVisible(true);
    };
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            width: 260,
        },
        {
          title: 'Date',
          dataIndex: 'date',
          width: 80,
        },
        {
            title: 'Link',
            dataIndex: 'link',
            width: 400,
          },
          {
            title: 'Operation',
            dataIndex: 'operation',
            fixed: 'right',
            width: 200,
            render: (_, record) => {
              return(
                <>
                <span style={{marginRight:'10px'}}>
                <Typography.Link
                    onClick={() => showDrawerAction('Video Details',record)}>
                    <span className='outlineEditButton'> <EyeOutlined style={{ fontSize: '13px', color: '#F7DC6F', marginRight:'3px' }} />View</span>
                </Typography.Link>
                </span>
                <Typography.Link
                    onClick={() => showDrawerAction('Edit Video',record)}>
                    <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
                </Typography.Link>
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                  <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'10px' }} />
                </Popconfirm>
                 </>
              )
            },
          }, 
      ];
   
    return (
        <div>
        <ToastContainer theme='colored'/>
        {loading?<Loader/>:null}
            <Row>
                <Col span={24}>
                    <Table
                        rowClassName="editable-row"
                        columns={columns}
                        dataSource={data}
                        size='small'
                        scroll={{y: 400 }}
                        sticky={true}
                    />
                </Col>
            </Row>

            <div className='addBtn'><button onClick={()=>showDrawerAction('Add Video')}>Add New Video</button></div>

            <Drawer title={modalType} placement="top" height={'600'} onClose={onClose} visible={visible}>
                {modalType==='Add Video'?
                <div>
                  <div>
                      <p className='testimonialModalHead'>Title</p>
                      <textarea  rows="2" cols="50" onChange={handleTitle} value={title}></textarea>
                  </div>
                  <div>
                      <p className='testimonialModalHead'>Link</p>
                      <textarea className='newLin' rows="4" cols="50" onChange={handleLink} value={link}></textarea>
                  </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary" onClick={showModal}
                    data-dismiss="modal"
                  >Save Video</button>
              </div>
                </div>:null}

                <Modal visible={isModalVisible} onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                      Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={submitData}>
                      Save & Publish
                    </Button>,
                    ]}
                >
                  <h5>you want to save the video.</h5>
                </Modal>
                {modalType==='Edit Video'?
                <>
                  {detailData?<>
                  <div className='BlogModalCode'>
                      <div className='blogModal'><input value={detailData.title} name="title" disabled onChange={handleBlogChange}/></div>
                      <div className='blogModal'><textarea value={detailData.link} rows='10' name="link" onChange={handleBlogChange}></textarea></div>
                      
                      <div className='blogSubmitBtn'><button  className='' onClick={handleSave}>Save</button></div>
                  </div>
                  </>:null}
                </>:null}
                {modalType==='Video Details'?<>
                {detailData?
                <div className='adminvideoView'>
                    <div className='videoHead'>{detailData.title}</div>
                    <div className='adminLinkedinVideo'>
                        <iframe src={detailData.link} title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" controls allowfullscreen='true'></iframe>
                    </div>
                </div>
                :null}
                </>:null}
                
            </Drawer>

        </div>
    )
}

export default Home



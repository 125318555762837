import React,{useState,useEffect} from 'react';
import { Table } from "ant-table-extensions";
import {customer_margin_details} from '../../Api'
import { DatePicker, Space } from 'antd';
import moment from 'moment'

const Positions = (props) => {
  const [pickerValue, setPickerValue] = useState(moment(new Date()));
  const [data, setData] = useState([]);
  const date = new Date().toISOString().split('T')[0]
  const [dateSend, setdateSend] = useState([])

  const { RangePicker } = DatePicker;

    const fetchData = ()=>{
    let newdata = [...data]
    const input = {
        access_token:localStorage.token,
        type:'positions',
        id:props.customer_id,
        start_date:dateSend[0],
        end_date:dateSend[1]
    }
    customer_margin_details(input).then(res => {
        console.log('position',res);
        Object.keys(res.result).map((val)=>{
            newdata.push({ 
            key: val,
            date:res.result[val].date,
            average_price:res.result[val].average_price,
            buy_m2m: res.result[val].buy_m2m,
            buy_price: res.result[val].buy_price,
            buy_quantity: res.result[val].buy_quantity,
            buy_value: res.result[val].buy_value,
            close_price: res.result[val].close_price,
            day_buy_price: res.result[val].day_buy_price,
            day_buy_quantity: res.result[val].day_buy_quantity,
            day_buy_value: res.result[val].day_buy_value,
            day_sell_price: res.result[val].day_sell_price,
            day_sell_quantity: res.result[val].day_sell_quantity,
            day_sell_value: res.result[val].day_sell_value,
            exchange: res.result[val].exchange,
            instrument_token: res.result[val].instrument_token,
            last_price: res.result[val].last_price,
            m2m: res.result[val].m2m,
            multiplier: res.result[val].multiplier,
            overnight_quantity: res.result[val].overnight_quantity,
            pnl: res.result[val].pnl,
            product: res.result[val].product,
            quantity: res.result[val].quantity,
            realised: res.result[val].realised,
            sell_m2m: res.result[val].sell_m2m,
            sell_price: res.result[val].sell_price,
            sell_quantity: res.result[val].sell_quantity,
            sell_value: res.result[val].sell_value,
            tradingsymbol: res.result[val].tradingsymbol,
            type: res.result[val].type,
            unrealised: res.result[val].unrealised,
            value: res.result[val].value,
            account_type: res.result[val].account_type,
            name: res.result[val].name,
            })
        })
        setData(newdata)
      })
    }

    useEffect(()=>{
      fetchData()
    },[])
  
    const submitData =()=>{
      fetchData()
    }
  
    const onChangeDate = (date, dateString) => {
      setdateSend(dateString)
    };

  const columns = [
    {title: 'Updated Date',dataIndex: 'date',width: 70},
    {title: 'Type',dataIndex: 'type',width: 70},
    {title: 'Name',dataIndex: 'name',width: 70},
    {title: 'Average Price',dataIndex: 'average_price',width: 70},
    {title: 'Buy Price',dataIndex: 'buy_price',width: 70},
    {title: 'Buy Quantity',dataIndex: 'buy_quantity',width: 70}, 
    {title: 'Buy Value',dataIndex: 'buy_value',width: 70},
    {title: 'Close Price',dataIndex: 'close_price',width: 70},
    {title: 'Day Buy Price',dataIndex: 'day_buy_price',width: 70},
    {title: 'Day Buy Quantity',dataIndex: 'day_buy_quantity',width: 70},
    {title: 'Day Buy Value',dataIndex: 'day_buy_value',width: 70},
    {title: 'Day Sell Price',dataIndex: 'day_sell_price',width: 70},
    {title: 'Day Sell Quantity',dataIndex: 'day_sell_quantity',width: 70},
    {title: 'Day Sell Value',dataIndex: 'day_sell_value',width: 70},
    {title: 'Exchange',dataIndex: 'exchange',width: 70},
    {title: 'Instrument Token',dataIndex: 'instrument_token',width: 70},
    {title: 'Last Price',dataIndex: 'last_price',width: 70},
    {title: 'M2M',dataIndex: 'm2m',width: 70},
    {title: 'Multiplier',dataIndex: 'multiplier',width: 70},
    {title: 'Overnight Quantity',dataIndex: 'overnight_quantity',width: 70},
    {title: 'PnL',dataIndex: 'pnl',width: 70},
    {title: 'Product',dataIndex: 'product',width: 70},
    {title: 'Quantity',dataIndex: 'quantity',width: 70},
    {title: 'Buy M2M',dataIndex: 'buy_m2m',width: 70},
    {title: 'Realised',dataIndex: 'realised',width: 70},
    {title: 'Sell M2M',dataIndex: 'sell_m2m',width: 70},
    {title: 'Sell Price',dataIndex: 'sell_price',width: 70},
    {title: 'Sell Value',dataIndex: 'sell_value',width: 70},
    {title: 'Sell Quantity',dataIndex: 'sell_quantity',width: 70},
    {title: 'Trading Symbol',dataIndex: 'tradingsymbol',width: 70},
    {title: 'Unrealised',dataIndex: 'unrealised',width: 70},
    {title: 'Value',dataIndex: 'value',width: 70},
  ]
  return (
      <>
      <div className='modalDateBox'>
        <RangePicker 
          onChange={onChangeDate} allowClear={true}
          defaultValue={[pickerValue, pickerValue]} />
        <button className='dateSubmitButton' onClick={submitData}>Submit</button>
      </div>
       <Table
              rowClassName="editable-row"
              columns={columns}
              dataSource={data}
              size='small'
              scroll={{ x: 1150, y: 400 }}
              pagination={{
                pageSize: 10,
                position:['bottomCenter']
              }}
              sticky={true}
              exportableProps={{ fileName: `Positions_${props.cusEmail}_${date}`}}
          />
      </>
  );
};

export default Positions;

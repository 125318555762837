// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formLbl {
  font-weight: 600;
  margin-bottom: 10px;
}

.InnerFormInput {
  padding: 8px !important;
  border-radius: 5px !important;
  color: #000 !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/Dashboard/billing/Billing.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;AACJ;;AACA;EACI,uBAAA;EACA,6BAAA;EACA,sBAAA;AAEJ","sourcesContent":[".formLbl{\n    font-weight: 600;\n    margin-bottom: 10px;\n}\n.InnerFormInput{\n    padding: 8px !important;\n    border-radius: 5px !important;\n    color: #000 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React,{useState,useEffect} from 'react'
import { get_faqs } from '../Api'
import parse from 'html-react-parser';

const MyAccordian = (props) => {
    const{question,answer}=props
    const[show,setShow] = useState(false)

    return (
        <div className='questionBox'>
            <div onClick={() => setShow(!show)}>
                <span className='questionarrow' >{show?"-":"+"}</span>
                <span className='questionText'>{question}</span>
            </div>
            <div>{show && <p className='answerText'>{parse(answer)}</p>}</div>
        </div>
    )
}

const Accordian = (props) => {
    const[data,setData] = useState({})
    const fetchFaqData = async () => {
        const input = {access_token:localStorage.token}
        const res = await get_faqs(input)
        try{
            if (res.status==='successfull'){setData(res.result)}
            else{}
        }catch{}
    }
    useEffect(()=>{
       fetchFaqData() 
    },[]) 
    return (
        <div>
        {data?
            <>
            {Object.keys(data).map((curElem)=>{
                return(
                <MyAccordian key={data[curElem].id} question={data[curElem].question}
                answer={data[curElem].answer} />)
            })}
            </>:null}
        </div>
    )
}
export default Accordian

import React,{useState,useEffect} from 'react';
import {Table, Form, Input, Button,Typography,Popconfirm,Row,Col,message,Select } from 'antd';
import {DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined} from '@ant-design/icons';
import {admin_zerodha_key_post,admin_zerodha_key_get,admin_zerodha_key_delete,admin_zerodha_key_put} from '../Api'
import '../UserManagement.scss'
import Loader from '../../../../shared/Loader';
import {family_members} from '../../../FinancialProfile/Api'

const EditableCell=({editing,dataIndex,title,record,index,children,...restProps})=>{
  return (
    <td {...restProps}>
      {editing?
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}>
            <Input/>
        </Form.Item>
      :children}
    </td>
  );
};

const Zerodhakey = (props) => {
  const{cusName,customer_id,cusEmail, close, tabName} = props
  const [data1,setData1] = useState([]) 
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false)
  const [familyDropDown, setFamilyDropDown] = useState('');

  const { Option } = Select;
  
  const getZerodhaDetail = async () =>{
    setLoading(true)
    const input = {
      access_token:localStorage.token,
      customer_id:customer_id
    }
    let newdata = []
    console.log(cusName,cusEmail);
    admin_zerodha_key_get(input).then(res => {
      console.log("resZerodha",res)
      try{
        if (res.status==='successfull'){
          Object.keys(res.result).map((val)=>{
            newdata.push({
              key: res.result[val],
              account_type:res.result[val].account_type,
              publisher_api_key: res.result[val].publisher_api_key,
              zerodha_id:res.result[val].zerodha_id,
              email:res.result[val].email,
              family_member_id:res.result[val].family_member_id
            })
          })
          setData1(newdata)
           setLoading(false)
        }else{ setLoading(false)}
      }catch{ setLoading(false)}
    }) 
   
  }
  const getFamilyMember=()=>{
    const input = {
      access_token:localStorage.token,
      id:customer_id
    }
    family_members(input).then(res => {
      console.log('rrelation',res)
      try{
      if (res.status==='successfull'){setFamilyDropDown(res.result)}
      else{console.log(res)}
      }catch{}
      })
  }

  useEffect(()=>{
    getZerodhaDetail()
    getFamilyMember()
  },[])

  const onFinish = (values) => {
    setLoading(true)
    const input = {
        access_token:localStorage.token,
        customer_id:customer_id,
        zerodha_id:values.zerodha_id,
        publisher_api_key:values.publisher_api_key,
        family_member_id:values.owner,
        account_type:values.account_type,
        email:values.email
      }
      console.log(input);
      admin_zerodha_key_post(input).then(res => {
        console.log(res);
        try{
          if (res.status==='successfull') {
            getZerodhaDetail()
            setLoading(false)
            message.success(res.message);
          }
          else{ 
            setLoading(false)
            message.error(res.message);
          }
            
        }catch{
          setLoading(false)
          message.error('Something went Wrong');
        }
        
        })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({name: '',age: '',address: '',...record});
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    setLoading(true)
    try {
      const row = await form.validateFields();
      const newData = [...data1];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData1(newData);
        setEditingKey('');
        const input = {
          access_token:localStorage.token,
          row:newData[index],
        }
        console.log(input)
        admin_zerodha_key_put(input).then(res => {
          console.log(res);
          try{
            if (res && res.status==='successfull'){
              getZerodhaDetail()
              getFamilyMember()
              setLoading(false);message.success(res.message);
            }
            else{ 
              setLoading(false);message.error(res.message);}
          }catch{setLoading(false);message.error('Something went Wrong');}
        })
      }else {
        newData.push(row);
        setData1(newData);
        setEditingKey('');

      }
    } catch (errInfo) {
      setLoading(false)
      console.log('Validate Failed:', errInfo);
    }
  };

  const onDelete = async(zerodha_id)=>{
    setLoading(true)
    const input = {
      access_token:localStorage.token,
      zerodha_id:zerodha_id,
      id:customer_id

    }
    console.log(input)
    admin_zerodha_key_delete(input).then(res => {
    console.log(res);
      try{
        if (res && res.status==='successfull'){
          getZerodhaDetail()
          getFamilyMember();
          setLoading(false);message.success(res.message)}
        else{setLoading(false); message.error(res.message)}
      }catch{setLoading(false); message.error('Something went Wrong')}
    }) 
}
  
const columns = [
    {title: 'Owner',dataIndex: 'family_member_id',width: 100},
    {title: 'Account Type',dataIndex: 'account_type',width: 100},
    {title: 'Email',dataIndex: 'email',width: 100,editable: true},
    {title: 'Zerodha Id',dataIndex: 'zerodha_id',width: 100,editable: true},
    {title: 'Publisher Api Key',dataIndex: 'publisher_api_key',width: 80,editable: true},
    
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: 120,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() =>save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <span className='outlineSaveButton'>
                <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
              </span>
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
            </Popconfirm>
          </span>
        ) : (
          <span>
          <Typography.Link disabled={editingKey !== ''}
            onClick={() => edit(record)}>
            <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
          </Typography.Link>
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record.zerodha_id)}>
           <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
          </Popconfirm>
          </span>
        );
      },
    },
  ];


  const mergedColumns = columns.map((col) => {
    if (!col.editable) {return col;}
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 14,
    },
  };
  return (
    <>
      {loading?<Loader/>:null}
      <div className='modalHeadBar'>
        <Row align='middle'>
          <Col span={6}>
            <div className='modalHeadBarTitle'>{tabName}</div>
          </Col>
          <Col span={12}>
            <div></div>
          </Col>
          <Col span={5}>
            <div className='modalHeadBarTitle'>{cusName}</div>
            <p className='modalHeadBarP'>{cusEmail}</p>
          </Col>
          <Col span={1}>
            <div className='TableActionButtonClose' onClick={close}>Close</div>
          </Col>
        </Row>
      </div>

      <Row gutter={10}>
        <Col span={18}>
          <div className='modalBodyBar'>
            <Form form={form} component={false}>
              <Table
                components={{body: {cell: EditableCell}}}
                dataSource={data1}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
                size='small'
                />
            </Form>
          </div>
        </Col>

        <Col span={6}>
        <div className='modalBodyBar'>
          <div className='modalBodyBarTitle'>Add Zerodha Credential</div>
          <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}
            style={{marginTop:'10px'}} size='small'  {...layout}>
              <Form.Item
              label="Owner"
              name="owner"
              rules={[{required: true,message: 'Please input owner'}]}>
                <Select placeholder='select owner'>
                  {familyDropDown?
                    <> 
                      {Object.keys(familyDropDown).map((member)=>{
                            return <Option value={member}>{familyDropDown[member]}</Option>
                            })}
                    </>:null}
                </Select>
              </Form.Item>
               
              <Form.Item
              label="Account Type"
              name="account_type"
              rules={[{required: true,message: 'Please input Account Type'}]}>
                <Select placeholder='select account type'>
                  {/* {familyDropDown?
                    <> 
                      {Object.keys(familyDropDown).map((member)=>{
                            return <Option value={member}>{familyDropDown[member]}</Option>
                            })}
                    </>:null} */}
                    <Option value='Individual'>Individual</Option>
                    <Option value='HuF'>HuF</Option>
                </Select>
              </Form.Item>
               
              <Form.Item
                label="Email"
                name="email"
                rules={[{required: true,message: 'Please input Email!'}]}>
                <Input />
              </Form.Item>
              
              <Form.Item
              label="Zerodha Id"
              name="zerodha_id"
              rules={[{required: true,message: 'Please input Zerodha Id!'}]}>
              <Input />
              </Form.Item>
               
              <Form.Item
              label="Publisher Api Key"
              name="publisher_api_key"
              rules={[{required: true, message: 'Please input Publisher Api Key!'}]}>
              <Input/>
              </Form.Item>
            
              <Form.Item style={{textAlign:'end'}}>
              <Button type="primary" htmlType="submit" className="submitButtonsmall">Submit</Button>
              </Form.Item>
          </Form>
        </div>
        </Col>
      </Row>
    </>
  )
};

export default Zerodhakey;

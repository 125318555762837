import React,{useState,useEffect} from 'react'
import {Form,Input,Select,Row,Col,Button,message} from 'antd';
import {customer_review,admin_billing_history,billing_history_put} from '../Api'
import { dashboard_billing_history } from '../Api';
import Loader from '../../../shared/Loader';
import BillingHistoryTable from './BillingHistoryTable';

const BillingHistory = (props) => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [clientEmail,setClientEmail] = useState('')
    const [loading,setLoading] = useState(false)

    const fetchUser = async () =>{
        const input = {access_token:localStorage.token}
        const res = await customer_review(input)
        console.log(input,"resss",res)
        if(res.status==='successfull'){
            setClientEmail(res.result)
        }
      }

      const getBillingHistory = async()=>{
        setLoading(true)
        let newdata = []
        const input = {access_token:localStorage.token,id:props.id}
        const res = await dashboard_billing_history(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.entries(res.result.billing_history).map((val)=>newdata.push(val[1]))
            setData(newdata)
            setLoading(false)
          }
          else{setLoading(false); message.error(res.message)}
        }catch{setLoading(false); message.error('Something went wrong')}
      }

      const onFinish = async (values) => {
        setLoading(true)
        const input = {access_token:localStorage.token,values:values}
        const res = await admin_billing_history(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('status',res,input);
        try{
            if (res.status==='successfull') {
              getBillingHistory();
              message.success(res.message);
              setLoading(false);
              form.resetFields();
            }
            else{ 
                message.error(res.message)
                setLoading(false)}
        }catch{ 
            message.error('Something went wrong')
            setLoading(false)
        }
      };

      useEffect(() =>{
        fetchUser();
        getBillingHistory();
      },[])

    const { Option } = Select;

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24}
      };

  return (
    <div>
        {loading?<Loader/>:null}
      <div className='formContainer'>
        <Form
        {...formItemLayout}
        form={form}
        name="billing"
        onFinish={onFinish}
        scrollToFirstError
        >
        <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                    name="id"
                    label="User Email"
                    rules={[{required: true}]}>
                        <Select
                            placeholder="Select Email">
                              {clientEmail?   
                                Object.keys(clientEmail).map((val)=>{
                                    return(<Option value={clientEmail[val].customer_id}>{clientEmail[val].email_address}</Option>)
                                }):null}
                        </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                    name="amount_paid"
                    label="Amount Paid"
                    rules={[{required: true, message: 'please fill!', whitespace: true,}]}
                >
                <Input prefix={<b>₹</b>}/>
                </Form.Item>
              </Col>
            </Row>
            <Col span={6}>
              <Form.Item>
                <Button className='SolidButton' htmlType="submit" >Submit</Button>
             </Form.Item>
            </Col>
        </Form>
        <BillingHistoryTable/>
    </div>
    </div>
  )
}

export default BillingHistory
import axios from "axios";

export const dashboard_assets = input => {
    return axios
        .get('/alpha_user_db/dashboard_assets',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const current_market_value = input => {
    return axios
        .get('/alpha_user_db/current_market_value',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const user_details = input => {
    return axios
        .get('/alpha_user_db/user_details',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  
import axios from "axios";

export const networth_as_it_is = input => {
    return axios
        .get('/alpha_user_db/networth_as_it_is',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const networth_scenario = input => {
    return axios
        .get('/alpha_user_db/networth_scenario',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id,
              scenario_id:input.scenario_id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const networth_scenario_case = input => {
    return axios
        .post('/alpha_user_db/networth_scenario_case', {
            access_token:input.access_token,
            values:input.values,
            scenario_id:input.scenario_id,
            id:input.id,
            },
        {
            headers: {
              'Authorization': input.access_token
            },
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const networth_scenario_case_get = input => {
    return axios
        .get('/alpha_user_db/networth_scenario_case',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              scenario_id:input.scenario_id,
              id:input.id,
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  
  export const networth_compare = input => {
    return axios
        .get('/alpha_user_db/networth_compare',
        {
            headers: {
              'Authorization': input.access_token,
              
            },
            params: {
              from_date:input.from_date,
              id:input.id,
              
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
import React,{useEffect,useState} from 'react'

import Dashboard from '../../Dashboard/Dashboard'
import Edit from '../../EditScreens/EditScreens'
import RiaStatus from './Update/Ria_Status'
import Zerodhakey from './zerodha/Zerodhakey';
import SendLink from './zerodha/SendLink';
import Equity from './zerodha/equity/Equity';
import MutualFunds from './zerodha/mutualFunds/MutualFunds';
import ClientData from './zerodha/ClientData';
import CcoReporting from './zerodha/CcoReporting'
import Notes from './Update/Notes';
import zerodhaicon from '../../../Images/zerodha.png'
import Implementation from './crm/Implementation';
import {customer_review,customer_admin_delete,get_uploaded_docs_admin,view_compliants_docs} from '../Api'
import Loader from '../../../shared/Loader';
import {Table,Tag,Drawer, Button,Dropdown,Menu,Popconfirm,Input,Space,message,Tooltip,Avatar,Modal,Row,Col} from 'antd';
import {UserOutlined,SearchOutlined,DownloadOutlined,MenuFoldOutlined,BranchesOutlined,} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { MDBIcon } from 'mdbreact';

const Review = (props) => {
    const {testClient} = props
    const { SubMenu } = Menu;

    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const[cusId,setCusID] = useState('')
    const[cusEmail,setCusEmail] = useState('')
    const[cusName,setCusName] = useState('')
    const[modalType,setModalType] = useState('')
    const [docList,setDocList] = useState({})
    const [loading,setLoading] = useState(false)

    // const MyIcon = createFromIconfontCN({
    //   scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
    // });
    
    let searchInput = ''

    const getAllClient= async()=>{
      setLoading(true)
      let newdata = []
      let newdataTest = []
        const input = {access_token:localStorage.token}
        const res = await customer_review(input)
        console.log('home',res);
        try{
          if (res && res.status==='successfull'){ 
            setLoading(false)
            Object.keys(res.result).map((val)=>{
              let add = res.result[val].customer_fullname.includes('Test')
              if(!add){
                newdata.push({ 
                key: res.result[val].customer_id,
                customer_id:res.result[val].customer_id,
                customer_fullname: res.result[val].customer_fullname,
                email_address: res.result[val].email_address,
                address: res.result[val].address_details,
                mobile_number: res.result[val].mobile_number,
                status: res.result[val].compliance_status,
                onboarding_date:res.result[val].onboarding_date,
                })
              }else{
                  newdataTest.push({ 
                  key: res.result[val].customer_id,
                  customer_id:res.result[val].customer_id,
                  customer_fullname: res.result[val].customer_fullname,
                  email_address: res.result[val].email_address,
                  address: res.result[val].address_details,
                  mobile_number: res.result[val].mobile_number,
                  status: res.result[val].compliance_status,
                  onboarding_date:res.result[val].onboarding_date,
                  })
                }
            })
            if(testClient===0){setData(newdata)}
            else{setData(newdataTest)}
           
          }
          else{setLoading(false)}
        }catch{setLoading(false)}
      }

      useEffect(()=>{
        getAllClient();
      },[])

      const showDrawer = (type) => {
        setVisible(true);
        setModalType(type)
    };
      
      const onClose = () => {
        setVisible(false);
        setCusID('')
        setCusEmail('')
        setCusName('')
        setModalType('')
      };
      const onVisibleChange=(record)=>{
        setCusID(record.key)
        setCusEmail(record.email_address)
        setCusName(record.customer_fullname)
      }
      const handledelete=()=>{
        const input = {
          access_token:localStorage.token,
          id:cusId
        }
        console.log("input",input)
        customer_admin_delete(input).then(res => {
            console.log(res);
            try{
                if (res.status==='successfull') {
                  message.success(res.message)
                  getAllClient();
                }
                else{ message.error(res.message)}
            }catch{ message.error('Something went wrong')}
        })
      }


      const getDocList=()=>{
        const input = {
            access_token:localStorage.token,
            id:cusId
        }
        get_uploaded_docs_admin(input).then(res => {
            console.log("resssdocs",res)
            try {
                if (res.status==='successfull') {setDocList(res.result)}
                else{console.log("error")}
            } catch (error) {}
            })
      }
      const download=(dirt,document_name)=>{
        let docs = ''
        const input = {
            access_token:localStorage.token,
            flag:document_name,
            id:cusId,
            dir: dirt
        }
        console.log(input)
        // console.log(document_name)
        let splitType = document_name.split('.').pop();
        view_compliants_docs(input).then(res => {
            console.log("resss",res)
            try {
                if (res.status==='successfull') {
                    docs=res.result
                    let linkSource = ''
                    if(splitType==='pdf'){
                      linkSource = `data:application/pdf;base64,${docs}`;
                    }
                    else{
                      linkSource = `data:image/png;base64,${docs}`;
                    }
                    const downloadLink = document.createElement("a");
                    const fileName = document_name;
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
                else{}
            } catch (error) {}
            })
      }
      
      let getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => {
                handleReset(clearFilters)
                confirm({ closeDropdown: false });
                setSearchedColumn(dataIndex)
                }} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
      
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
      };
      
      const  handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };

      let admin=1

      const setting = (
        <Menu onOpenChange={getDocList}>
            {/* <Menu.Item key='Email' onClick={()=>showDrawer('Send Email')}>Email</Menu.Item> */}
            <Menu.Item key='View' onClick={()=>showDrawer('View Client Dashboard')}>View</Menu.Item>
            <Menu.Item key='notes' onClick={()=>showDrawer('Notes')}>Notes</Menu.Item>
            <Menu.Item key='Edit' onClick={()=>showDrawer('Edit Client Data')}>Edit</Menu.Item>
            <Menu.Item key='Delete'>
                <Popconfirm title="Sure to cancel?" onConfirm={handledelete}>
                    Delete
                </Popconfirm>
            </Menu.Item>
            {/* <Menu.Item key='Payment Info' onClick={()=>showDrawer('Payment')}>Payment Info</Menu.Item> */}
            {/* <Menu.Item key='upload' onClick={()=>showDrawer('Upload')}>Upload</Menu.Item> */}
            <Menu.Item key='riaStatus' onClick={()=>showDrawer('Change Ria Status')}>Ria Status</Menu.Item>
            
            
            <SubMenu key="download"  title="Download" >
                {docList?<>
                    {Object.keys(docList).map((val)=>{
                        let dir = ''
                        console.log(docList[val].document_id)
                        if(docList[val].document_id===17 ||docList[val].document_id===18 ){
                          dir = 'agreement'
                        }
                        else if(docList[val].document_id===12){
                          dir = 'insurance'
                        }
                        else if(docList[val].document_id===13){
                          dir = 'liability'
                        }
                        else if(docList[val].document_id===14){
                          dir = 'assets'
                        }
                        else{
                          dir = 'kyc'
                        }
                        return(
                            <Menu.Item key={docList[val].document_name}>{docList[val].document_name}
                                <DownloadOutlined onClick={(dirt,document_name)=>download(dir,docList[val].document_name)}/>
                            </Menu.Item>
                          )
                      })}
                </>:null}
            </SubMenu>

        </Menu>
      );

      const zerodha2 = (
        <Menu key="Zerodha"  title="Zerodha" >
        {/* <SubMenu key="Zerodha"  title="Zerodha"> */}
              <Menu.Item key='add_zerodha_key' onClick={()=>showDrawer('Add Zerodha Key')}>Add Zerodha Key</Menu.Item>
              <Menu.Item key='send_acco_reportinguth_zerodha' onClick={()=>showDrawer('Send Auth Zerodha')}>Send Auth Zerodha</Menu.Item>
              <Menu.Item key='' onClick={()=>showDrawer('CCO Reporting')}>CCO Reporting</Menu.Item>
              <Menu.Item key='view_client_data' onClick={()=>showDrawer('View Client Data')}>View Client Data</Menu.Item>
              <Menu.Item key='equity' onClick={()=>showDrawer('Equity')}>Equity</Menu.Item>
              <Menu.Item key='mutual_fund' onClick={()=>showDrawer('Mutual Fund')}>Mutual Fund</Menu.Item>
            </Menu>

      );

      const crmDrp = (
        <Menu key="CRM"  title="CRM" >
          <Menu.Item key='plan_implementation' onClick={()=>showDrawer('Plan Implementation')}>Plan Implementation</Menu.Item>
        </Menu>

      );
    const columns = [
        {title: 'Name',dataIndex: 'customer_fullname',width: 100,...getColumnSearchProps('customer_fullname'),render: (text, record) => (
          <Row >
            <Col span={6}>
              <Avatar size="small" style={{ backgroundColor: '#FF862C', verticalAlign: 'middle' }}>
                {record.customer_fullname.substring(0,1)}
              </Avatar>
            </Col>
            <Col span={18}>
              <p>{record.customer_fullname}</p>
              <p className='reviewTableId'>{record.customer_id}</p>
            </Col>
          </Row>
        )},
        {title: 'Email',dataIndex: 'email_address',width: 110,...getColumnSearchProps('email_address')},
        {title: 'Address',dataIndex: 'address',width: 55,...getColumnSearchProps('address'),
        render: (text, record) => (
          <Tooltip placement='top' title={record.address}><div style={{cursor:'pointer'}}>View</div></Tooltip>
      ),},
        {title: 'Mobile Number',dataIndex: 'mobile_number',width: 80,...getColumnSearchProps('mobile_number')},
        {title: 'Compliance Status',dataIndex: 'status',width: 80,
          filters: [
            {
              text: 'Y',
              value: 'Y',
            },
            {
              text: 'N',
              value: 'N',
            },
          ],
          onFilter: (value, record) => record.status.startsWith(value),
          filterSearch: false,
            render: status => (
              <Tag color={status==='Y'?'green':'red'} key={status}>
                  <div style={{fontSize:'10px'}}>{status}</div>
              </Tag>
            ),
          },
        {title: 'Onboard Date', dataIndex: 'onboarding_date',width:65,...getColumnSearchProps('onboarding_date')},
        {title: 'Action',key: 'action',width: 100,
            render: (text, record) => (<>
                <Dropdown overlay={setting}  placement="bottomLeft" onVisibleChange={()=>onVisibleChange(record)}>
                  <button className='TableActionButton'><MenuFoldOutlined className='Tic'/></button> 
                </Dropdown>
                <Dropdown overlay={zerodha2}  placement="bottomLeft" onVisibleChange={()=>onVisibleChange(record)}>
                  <button className='TableActionButton'><img src={zerodhaicon} alt=''/></button>
                </Dropdown>
                <Dropdown overlay={crmDrp}  placement="bottomLeft" onVisibleChange={()=>onVisibleChange(record)}>
                  <button className='TableActionButton'>CRM</button>
                </Dropdown>
                </>
            ),
          },
      ];
     
    return (
        <div className='adminBg'>
          {loading?<Loader/>:null}
          <Table
              rowClassName="editable-row"
              columns={columns}
              dataSource={data}
              size='small'
              scroll={{y:'68vh' }}
              sticky={true}
          />
            <Drawer title={modalType} placement="top" height={'100%'} visible={visible} headerStyle={{display:'none'}}>
              {modalType==='View Client Dashboard'?
              <div className='adminViewDas'><Dashboard id={cusId} email_address={cusEmail} admin={admin} close={onClose} tabName={modalType}/></div>:null}
              {modalType==='Edit Client Data'?<Edit cusEmail={cusEmail} admin={admin} id={cusId}close={onClose} tabName={modalType}/>:null}
              {modalType==='Notes'?<Notes cusName={cusName} cusEmail={cusEmail} admin={admin} customer_id={cusId} close={onClose} tabName={modalType}/>:null}
              {modalType==='Change Ria Status'?<RiaStatus cusName={cusName} customer_id={cusId} cusEmail={cusEmail} close={onClose} tabName={modalType}/>:null}
              {modalType==='Add Zerodha Key'?<Zerodhakey cusName={cusName} customer_id={cusId} cusEmail={cusEmail} close={onClose} tabName={modalType}/>:null}
              {modalType==='Send Auth Zerodha'?<SendLink cusName={cusName} customer_id={cusId} cusEmail={cusEmail} close={onClose} tabName={modalType}/>:null}
              {modalType==='Cco Reporting'?<CcoReporting cusName={cusName} customer_id={cusId} cusEmail={cusEmail} close={onClose} tabName={modalType}/>:null}
              {modalType==='Equity'?<Equity cusName={cusName} customer_id={cusId} cusEmail={cusEmail} close={onClose} tabName={modalType}/>:null}
              {modalType==='Mutual Fund'?<MutualFunds cusName={cusName} customer_id={cusId} cusEmail={cusEmail} close={onClose} tabName={modalType}/>:null}
              {modalType==='View Client Data'?<ClientData cusName={cusName} customer_id={cusId} cusEmail={cusEmail} close={onClose} tabName={modalType}/>:null}
              {modalType==='Plan Implementation'?<Implementation cusName={cusName} customer_id={cusId} cusEmail={cusEmail} close={onClose} tabName={modalType}/>:null}
            </Drawer>
        </div>
        
    )
}

export default Review



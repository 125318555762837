import React,{useState,useEffect} from 'react';
import {Row,Col,Avatar} from 'antd';
import './CrmStatus.scss'
import {MDBIcon} from 'mdbreact'
const CrmStatus = () => {

    const CardC = ()=>{
    return(
        <div className='crmCard'>
            <Row className='crmCardRow'>
                <Col span={3}>
                    <Avatar size='default' style={{ backgroundColor: '#FF862C'}}>S</Avatar>
                </Col>
                <Col span={13}>
                    <p className='crmCardName'>Sakib Khan</p>
                    <p className='crmCardmail'>sk0574006@gmail.com</p>
                </Col>
                <Col span={8}>
                    <div className='crmCardDate'>13/01/23 | 2:30 pm</div>
                </Col>
            </Row>
            <div className='crmCardpoint'>
                <MDBIcon fas icon="circle" className='crmCardicon'/>
                <span>Zerodha Account Opening</span>
            </div>
            <div className='crmCardpoint'>
                <MDBIcon fas icon="circle" className='crmCardicon'/>
                <span>Zerodha Account Opening</span>
            </div>
        </div> 
       
    )
    }
  return (
    <div className='modalBodyBar'>
        <Row gutter={20}>
            <Col span={8}>
                <div className='crmCardPlate'>
                    <div className='modalBodyBarTitle'>New</div>
                    <CardC/>
                    <CardC/>
                </div>
            </Col>
            <Col span={8}>
                <div className='crmCardPlate'>
                    <div className='modalBodyBarTitle'>Open</div>
                    <CardC/>
                    <CardC/>
                </div>
            </Col>
        </Row>

    </div>
  )
}

export default CrmStatus
import React,{useState,useEffect} from 'react'
import {Form,Input,Table,message,Typography,InputNumber,Popconfirm} from 'antd';
import { EditFilled,CloseCircleFilled,SaveOutlined,DeleteFilled} from '@ant-design/icons';
import { dashboard_billing_history,billing_history_put } from '../Api';

const BillingHistoryTable = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading,setLoading] = useState(false)
    const [editingKey, setEditingKey] = useState('');

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
      }) => {
        const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
        return (
          <td {...restProps}>
            {editing ? (
              <Form.Item
                name={dataIndex}
                style={{
                  margin: 0,
                }}
                rules={[
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ]}
              >
                {inputNode}
              </Form.Item>
            ) : (
              children
            )}
          </td>
        );
      };

    const getBillingHistory = async()=>{
        setLoading(true)
        let newdata = []
        const input = {access_token:localStorage.token}
        const res = await dashboard_billing_history(input)
        console.log('resbilling',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.entries(res.result.billing_history).map((val)=>newdata.push(val[1]))
            setLoading(false)
            setData(newdata)
          }
          else{setLoading(false); message.error(res.message)}
        }catch{setLoading(false); message.error('Something went wrong')}
      }
    
      useEffect(() =>{
        getBillingHistory();
      },[])

      const save = async (key) => {
        setLoading(true)
          try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
              setData(newData);
              setEditingKey('');
            const input = {
              access_token:localStorage.token,
              row:newData[index],
            }
            billing_history_put(input).then(res => {
                console.log(res,'abc',input);
                
                try{
                  if (res && res.status==='successfull'){
                    getBillingHistory();
                    setLoading(false);
                    message.success("Saved");
                    }
                  else{
                    setLoading(false)
                    message.error(res.message);
                  }
                }catch{
                  setLoading(false);
                }
              })
            } else {
              newData.push(row);
              setData(newData);
              setEditingKey('');
              setLoading(false)
            }
          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
            setLoading(false)
          }
        };
        const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
    form.setFieldsValue({
      agreementDate: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

    const columns = [
        {title: 'Email',dataIndex: 'email_addres',width: 60},
        {title: 'Amount paid',dataIndex: 'amount_paid',width: 80,editable: true},
        {title: 'Payment Date',dataIndex: 'payment_date',width: 100},
    //     {
    //         title: 'Action',
    //         dataIndex: 'action',
    //         fixed: 'right',
    //         width: 120,
    //         render: (_, record) => {
    //             const editable = isEditing(record);
           
    //   return editable ? (
    //             <span>
    //                 <Typography.Link onClick={() => save(record.key)} style={{marginRight: 8,}}>
    //                     <span className='outlineSaveButton'>
    //                     <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
    //                     </span>
    //                 </Typography.Link>
    //                 <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //                     <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
    //                 </Popconfirm>
    //             </span>
    //             ) : (
    //             <span>
    //                 <Typography.Link disabled={editingKey !== ''}
    //                     onClick={() => edit(record)}
    //                     >
    //                     <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
    //                 </Typography.Link>
    //                 {/* <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
    //                     <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
    //                 </Popconfirm> */}
    //             </span>
    //         )
    //          },
    //       },
        ];
      
        const mergedColumns = columns.map((col) => {
          if (!col.editable) {
            return col;
          }
          return {
            ...col,
            onCell: (record) => ({
              record,
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
              }),
            };
          });

  return (
    <div>
        <Form form={form}>
        <Table rowClassName="antTable" components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns} dataSource={data} size='small'
        pagination={{pageSize: 8,position:['bottomCenter']}} sticky={true} 
        />
        </Form>
    </div>
  )
}

export default BillingHistoryTable
import axios from 'axios'
export const save_income_expence_details = input => {
    return axios
        .post('/alpha_user_financial_profile_setup/income', {
            access_token:input.access_token,
            income_data:input.income_data,
            id:input.id
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const delete_income_details = input => {
  return axios
      .delete('/alpha_user_financial_profile_setup/income',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
            delete_row:input.delete_row,
            row_index:input.row_index,
            type:input.type
          }
        },)
      .then(response => {
          return response.data
      })
      .catch(err => {})
  }
export const save_expense_details = input => {
  return axios
      .post('/alpha_user_financial_profile_setup/expense',
      {
        access_token:input.access_token,
        id:input.id,
        expense_details:input.expense_details
    },
      {
          headers: {
            'Authorization': input.access_token
          },
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const update_income_expence_details = input => {
  return axios
      .put('/alpha_user_financial_profile_setup/income', {
          access_token:input.access_token,
          income_data:input.income_data,
          id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const update_expense_details = input => {
  return axios
      .put('/alpha_user_financial_profile_setup/expense',
      {
        access_token:input.access_token,
        id:input.id,
        expense_details:input.expense_details
    },
      {
          headers: {
            'Authorization': input.access_token
          },
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}


export const save_insurance_policy_details = input => {
    return axios
        .post('/alpha_user_financial_profile_setup/insurance_policy', {
            access_token:input.access_token,
            policy_data:input.policy_data,
            id:input.id,
            skip:input.skip
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}

export const update_insurance_policy_details = input => {
  return axios
      .put('/alpha_user_financial_profile_setup/insurance_policy', {
          access_token:input.access_token,
          id:input.id,
          cellName:input.cellName,
          cellValue:input.cellValue,
          row_id:input.row_id,
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const delete_insurance_policy_details = input => {
  return axios
      .delete('/alpha_user_financial_profile_setup/insurance_policy',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
            delete_row:input.delete_row,
          }

        },)
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const save_asset_details = input => {
    return axios
        .post('/alpha_user_financial_profile_setup/asset', {
            access_token:input.access_token,
            asset_result:input.asset_result,
            id:input.id
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const update_asset_details = input => {
  return axios
      .put('/alpha_user_financial_profile_setup/asset', {
          access_token:input.access_token,
          edit_row:input.edit_row,
          id:input.id,
          group:input.group,
          asset:input.asset,
          asset_row_id:input.asset_row_id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const save_liability_details = input => {
  return axios
      .post('/alpha_user_financial_profile_setup/liabilities', {
          access_token:input.access_token,
          liability_data:input.liability_data,
          id:input.id,
          skip:input.skip
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const update_liability_details = input => {
return axios
    .put('/alpha_user_financial_profile_setup/liabilities', {
        access_token:input.access_token,
        id:input.id,
        cellName:input.cellName,
        cellValue:input.cellValue,
        row_id:input.row_id,
    },
    {
        headers: {
          'Authorization': input.access_token
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const delete_liability_details = input => {
return axios
    .delete('/alpha_user_financial_profile_setup/liabilities',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          id:input.id,
          delete_row:input.delete_row,
        }
      },)
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const save_goals_details = input => {
    return axios
        .post('/alpha_user_financial_profile_setup/goals', {
            access_token:input.access_token,
            goals_data:input.goals_data,
            userLifeEvent:input.userLifeEvent,
            id:input.id,
            skip:input.skip
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}

export const update_goals_details = input => {
  return axios
      .put('/alpha_user_financial_profile_setup/goals', {
          access_token:input.access_token,
          id:input.id,
          cellName:input.cellName,
          cellValue:input.cellValue,
          row_id:input.row_id,
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const delete_goals_details = input => {
  return axios
      .delete('/alpha_user_financial_profile_setup/goals',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
            delete_row:input.delete_row,
          }
        },)
      .then(response => {
          return response.data
      })
      .catch(err => {})
}

export const get_income_expense_details = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/income',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_expense_details = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/expense',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_insurance_details = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/insurance_policy',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_asset_details = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/asset',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const delete_asset_details = input => {
  return axios
      .delete('/alpha_user_financial_profile_setup/asset',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
            row:input.row,
            row_id:input.row_id,
            group:input.group,
            asset:input.asset,
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_liability_details = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/liabilities',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_goals_details = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/goals',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_last_index = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/last_index',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            flag:input.flag,
             id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const next_click = input => {
  return axios
      .put('/alpha_user_financial_profile_setup/next', {
          access_token:input.access_token,
          flag:input.flag,
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const dropdown_data = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/dropdown_data',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            flag:input.flag,
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const family_members = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/family_members',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const post_srfo = input => {
  return axios
      .post('/alpha_user_financial_profile_setup/asset_details',
      {
          access_token:input.access_token,
          values: input.values,
          srfoValues:input.srfoValues,
          fields:input.fields,
          id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_srfo_details = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/asset_details',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            asset_row_id:input.asset_row_id,
            asset_type:input.asset_type,
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const delete_srfo_details = input => {
  return axios
      .delete('/alpha_user_financial_profile_setup/asset_details',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
            row:input.row,
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const asset_irr_get = input => {
  return axios
      .get('/alpha_user_financial_profile_setup/asset_irr',
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const asset_irr_update = input => {
  return axios
      .put('/alpha_user_financial_profile_setup/asset_irr', {
          access_token:input.access_token,
          row:input.row,
          id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const expense_scenario = input => {
  return axios
      .post('/alpha_user_db/expense_scenario', {
        access_token:input.access_token,
        id:input.id,
        expense_details:input.expense_details,
        scenario_id:input.scenario_id

      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_expense_scenario = input => {
  return axios
      .get('/alpha_user_db/expense_scenario',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
            scenario_id:input.scenario_id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const income_scenario = input => {
  return axios
      .post('/alpha_user_db/income_scenario', {
          access_token:input.access_token,
          income_data:input.income_data,
          id:input.id,
          scenario_id:input.scenario_id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const income_scenario_get = input => {
  return axios
      .get('/alpha_user_db/income_scenario',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
            scenario_id:input.scenario_id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}



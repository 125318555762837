import React, { useEffect, useState } from 'react';
import './Recommandation.scss'
import 'reactjs-popup/dist/index.css';
import {customer_review,recommendation} from '../Api'
import Loader from '../../../shared/Loader'
import { Select, Table, Drawer,Button,Space,Input,message,Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined} from '@ant-design/icons';

const SendRecommendation = (props) => {
    const {instrument} = props

    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')
    const [data,setData] = useState([])
    const [derivatives,setDerivatives] = useState([{id:1}])
    const [details,setDetails] = useState({})
    const [checkedClient,setCheckedClient] = useState([])
    const [checkedClientFam,setCheckedClientFam] = useState([])
    const [visible,setVisible] = useState(false)
    const [childrenDrawer,setChildrenDrawer] = useState(false)
    const [loading,setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [rejectData,setRejectData] = useState({})
    let searchInput = ''

    const updateDrvtType=(e,key)=>{
        let aa  = [...derivatives]
        aa[e.target.id]={'id':key,exchange:''}
        aa[e.target.id][e.target.name] = e.target.value
        setDerivatives(aa)
    }
    const updateDrvtEx=(e)=>{
        let aa  = [...derivatives]
        delete aa[e.target.id]['trading_symbol']
        aa[e.target.id][e.target.name] = e.target.value
        setDerivatives(aa)
    }
    const updateDrvt=(e)=>{
        let aa  = [...derivatives]
        aa[e.target.id][e.target.name] = e.target.value
        setDerivatives(aa)
    }
    const updateDrvtSerch=(value,val,symbolName)=>{
        let aa  = [...derivatives]
        if(value){
             aa[val][symbolName] = value}
        else{
             aa[val][symbolName] = ''}
        aa[val]['name'] = ''
        aa[val]['lot_size'] =''
        aa[val]['expiry'] =''
        aa[val]['strike'] =0

        if(value){
        if(instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']][value].name){
            aa[val]['name'] = instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']][value].name
        }
    
        if(instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']][value].lot_size){
            aa[val]['lot_size'] = instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']][value].lot_size
        }
        if(instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']][value].expiry){
            aa[val]['expiry'] = instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']][value].expiry
        }
        if(instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']][value].strike){
            aa[val]['strike'] = instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']][value].strike
        }
    }
        setDerivatives(aa)
    }
    const addRow2 = () =>{
        let aa = [...derivatives]
        var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
          var product = {id: id,}
          aa.push(product);
          setDerivatives(aa)
    }
    const handleRowDel2=(val)=>{
        let aa = [...derivatives]
        aa.splice(val,1);
        setDerivatives(aa)
    };
  
    const submit_recom=()=>{
        setLoading(true)
        const mergearray = checkedClient.concat(checkedClientFam);

        const input = {
            access_token:localStorage.token,
            client_list:mergearray,
            derivatives_list:derivatives
        }
        console.log(input);
        recommendation(input).then(res => {
            console.log('ressubmit',res)
        try{
        if (res.status==='successfull') {
            if(Object.keys(res.result).length>0){
                setLoading(false)
                setRejectData(res.result)
                setModal(true)
                setVisible(false)
                setDerivatives([{id:1}])
            }else{
                setLoading(false)
                message.success(res.message)
                setVisible(false)
                setDerivatives([{id:1}])
            } 
        }
        else{
            setLoading(false)
            setVisible(false)
        }
        }catch{
            setLoading(false)
            message.error('Something went wrong')
        }
        })
    }
    const showDrawer = () => {setVisible(true)};
    
    const onClose = () => {setVisible(false)};
    
    const showChildrenDrawer = (record) => {
        setChildrenDrawer(true)
        setDetails(record)
    };
    
    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false)
        setDetails({})
    };
    
    useEffect(()=>{
        const input = {
            access_token:localStorage.token
        }
        let newdata = [...data]
        customer_review(input).then(res => {
            console.log(res,'ress')
        try{
            if (res.status==='successfull'){
                Object.keys(res.result).map((val)=>{
                    newdata.push({ 
                    key: res.result[val].customer_id,
                    customer_id: res.result[val].customer_id,
                    name:res.result[val].customer_fullname,
                    email_address:res.result[val].email_address,
                    mobile_number:res.result[val].mobile_number,
                    onboarding_date:res.result[val].onboarding_date,
                    compliance_status:res.result[val].compliance_status,
                    address_details:res.result[val].address_details,
                    zerodha:res.result[val].zerodha,
                    family_member_id:res.result[val].family_member_id,
                    description: <ExpandedRowRender family_list={res.result[val].family_list}/>
                })
                })
                setData(newdata)
            }else{}
        }catch{}
        })
    },[])

    const ExpandedRowRender = (props) => {
        const columns = [
          { title: 'Name', dataIndex: 'name',width:'45%' },
          { title: 'Zerodha Id', dataIndex: 'zerodha_id',width:'45%' },
        ];
      
        const data = [];
      
        if(props.family_list){
          Object.keys(props.family_list).map((val)=>{
            data.push({
              key: val,
              name: props.family_list[val].name,
              family_member_id: props.family_list[val].family_member_id,
              zerodha_id:props.family_list[val].zerodha_id,
              customer_id:props.family_list[val].customer_id   
            })
          })
        }
       
        return <Table
          columns={columns} dataSource={data}
          pagination={false}   size='small'  
          rowSelection={{    type: 'checkbox',...rowSelectionFam}} 
          rowClassName="editable-row"
          bordered/>;
      };

    const handleOk = () => {
        setModal(false);
      };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {setCheckedClient(selectedRows)},
        getCheckboxProps: (record) => ({disabled: record.zerodha === 'N'}),
    };
    const rowSelectionFam = {
        onChange: (selectedRowKeys, selectedRows) => {setCheckedClientFam(selectedRows)}
    };
    let getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>

            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setSearchText(selectedKeys[0])
                  setSearchedColumn(dataIndex)
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
      
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
      };
      
      const  handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };

    const { Option } = Select;
    const columns = [ 
        {
          title: 'Id',
          dataIndex: 'customer_id',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
        },
        {
          title: 'Details',
          dataIndex: 'details',
          render: (text, record) => (
                <Button className='DashedButton' size='small' onClick={()=>showChildrenDrawer(record)}>Details</Button>
        ),
        },
      ];
    if (loading){
        return(
          <div style={{textAlign:'center',marginTop:100}}>
          <Loader
              type="ThreeDots"
              color="#f2c377"
              height={100}
              width={100}/>
          </div>
          );
      }

      console.log(checkedClient);
      console.log(checkedClientFam);
  return (
    <div style={{overflow:'hidden'}}>
    <div>
        <div className='tab-scroll'>
            <table class="table table-condensed table-sm">
                    <th className='tab-th td-lf-border'>Instrument Type</th>
                    <th className='tab-th td-lf-border'>Exchange</th>
                    <th className='tab-th td-lf-border'>Trading Symbol</th>
                    <th className='tab-th td-lf-border'>Name</th>
                    <th className='tab-th td-lf-border'>Lot Size</th>
                    <th className='tab-th td-lf-border '>Order Type</th>
                    <th className='tab-th td-lf-border '>Limit Price</th>
                    <th className='tab-th td-lf-border '>Quantity</th>
                    <th className='tab-th td-lf-border '>Buy/Sell</th>
                    <th className='tab-th td-lf-border td-rh-border'></th>
                <tbody>
                {Object.keys(derivatives).map((val) =>{
                    return(
                        <tr className='tab-tr'> 
                            <td className='tab-td td-lf-border'style={{minWidth:'100px',maxWidth:'100px'}}>
                                <select name='instrument_type' id={val} 
                                onChange={(e,key)=>updateDrvtType(e,derivatives[val].id)} value={derivatives[val].instrument_type} className='select-width'>
                                <option value=''>Select</option>
                                {instrument?
                                <>
                                {Object.keys(instrument).map((insType)=>{
                                    return(<option value={insType}>{insType}</option>)
                                })}
                                </>:null}
                                </select>
                            </td>
                            <td className='tab-td td-lf-border' style={{minWidth:'110px',maxWidth:'110px'}}>
                                <select name='exchange' id={val} onChange={updateDrvtEx} className='select-width' value={derivatives[val].exchange}>
                                <option value=''>Select</option>
                                {derivatives[val]['instrument_type']?
                                <>
                                {Object.keys(instrument[derivatives[val]['instrument_type']]).map((exchange)=>{
                                    return(<option value={exchange}>{exchange}</option>)
                                })}
                                </>:null}
                                </select>
                            </td>
                            <td className='tab-td td-lf-border' style={{minWidth:'120px',maxWidth:'120px'}}>

                            <Select
                                showSearch
                                bordered={false}
                                className='selectborder'
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                onChange={(value) => updateDrvtSerch(value,val,'trading_symbol')}
                                value={derivatives[val].trading_symbol}
                                size='small'
                            >
                                {derivatives[val]['exchange']?
                                <> 
                                    {Object.keys(instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']]).map((trSymbl)=>{
                                        return <Option value={trSymbl}>{trSymbl}</Option>
                                        })}
                                </>:null}
                            </Select>
                            </td>
                            {derivatives[val]['trading_symbol']?
                            <>
                            <td className='tab-td td-lf-border' style={{minWidth:'120px',maxWidth:'120px'}}>
                                {instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']][derivatives[val]['trading_symbol']].name}
                            </td>
                            <td className='tab-td td-lf-border'>
                                {instrument[derivatives[val]['instrument_type']][derivatives[val]['exchange']][derivatives[val]['trading_symbol']].lot_size}
                            </td>
                            </>:
                            <>
                            <td className='tab-td td-lf-border'></td>
                            <td className='tab-td td-lf-border'></td>
                            </>}

                            <td className='tab-td td-lf-border' style={{minWidth:'80px',maxWidth:'80px'}}><select name='order_type' onChange={updateDrvt} id = {val} className='select-width'>
                                <option>Select</option>
                                <option>LIMIT</option>
                                <option>MARKET</option>
                                </select>
                            </td>

                            {derivatives[val]['order_type']?<>
                            {derivatives[val]['order_type']==='MARKET'?(
                            <td className='td-lf-border not-applicable' style={{minWidth:'80px',maxWidth:'80px'}}>Not Applicable</td>
                            ):(
                            <td className='tab-td td-lf-border' style={{minWidth:'80px',maxWidth:'80px'}}>
                                <input name='limit_price' type='number' id = {val} placeholder='0' onChange={updateDrvt} className='select-width'/>
                            </td>)}
                            </>:<td className='td-lf-border not-applicable' style={{minWidth:'80px',maxWidth:'80px'}}>Not Applicable</td>}

                            <td className='tab-td td-lf-border' style={{minWidth:'80px',maxWidth:'80px'}}><input name='quantity' type='number' placeholder='0' id = {val} onChange={updateDrvt} className='select-width'/></td>
                          

                            <td className='tab-td td-lf-border'><select name='buy_or_sell' onChange={updateDrvt} id = {val} className='select-width'>
                                <option>Select</option>
                                <option>BUY</option>
                                <option>SELL</option>
                                </select>
                            </td>
                          
                            <td className='cross-btn td-lf-border td-rh-border'><button onClick={()=>handleRowDel2(val)}><i class="fas fa-trash-alt"></i></button></td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
        </div>
    </div>
    <div className='addBtn'><button onClick={addRow2}>Add Row</button></div>
    
    <div className='clientListBtn'><button onClick={showDrawer}>Client List</button></div>
           
    <Drawer
        title="Client List"
        width={400}
        onClose={onClose}
        visible={visible}>
            <Table
                rowSelection={{type: 'checkbox',...rowSelection}}
                expandable={{ expandedRowRender: record =><>{record.description}</> }}
                columns={columns}
                dataSource={data}
                size='small'
                sticky={true}
                rowClassName="editable-row"
                scroll={{y: 350 }}
                pagination={{pageSize: 10,position:['bottomCenter']}}
            />
              <Button className='DashedButton' size='small' onClick={submit_recom}>Submit</Button>
          <Drawer
            title="Client Details"
            width={320}
            onClose={onChildrenDrawerClose}
            visible={childrenDrawer}>
               {details?
                <>
                    <p className='RecomDetailHead'>Client Name : {details.name}</p>
                    <p className='RecomDetailHead'>Client Email : {details.email_address}</p>
                    <p className='RecomDetailHead'>Mobile Number : {details.mobile_number}</p>
                    <p className='RecomDetailHead'>Onboarding Date : {details.onboarding_date}</p>
                    <p className='RecomDetailHead'>Compliance Status : {details.compliance_status}</p>
                    <p className='RecomDetailHead'>Address Details : {details.address_details}</p>
                </>
                :null}
          </Drawer>
        </Drawer>

        <Modal onOk={handleOk} onCancel={handleOk} visible={modal} closable={false}>
           <>
           {rejectData?<>
            <p>Following clients do not have enough stocks for sell"</p>
            <table className='table table-condensed table-sm'>
            <tr>
                <th>Customer Id</th>
                <th>Customer Name</th>
                <th>Email Address</th>
                <th>Stock</th>
            </tr>
            {Object.keys(rejectData).map((val)=>{
                return(
                    <>
                        <tr>
                            <td>{rejectData[val].customer_id}</td>
                            <td>{rejectData[val].customer_name}</td>
                            <td>{rejectData[val].email_address}</td>
                            <td>{rejectData[val].stock}</td>
                        </tr>
                    </>
                )
            })}
            </table>
           </>:null}
           </>
        </Modal>
    </div>
    );
};

export default SendRecommendation;
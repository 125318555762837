import React,{useState,useEffect} from 'react';
import { Table } from "ant-table-extensions";
import {Row,Col} from 'antd'
import {covered_call_options_reporting} from '../Api'


const CcoReporting = (props) => {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);

    useEffect(()=>{
        let newdata = [...data]
        let newdata2 = [...data2]
        const input = {
            access_token:localStorage.token,
            type:'cco_reporting',
            id:props.customer_id
        }
        covered_call_options_reporting(input).then(res => {
            // console.log('reporting',res);
            Object.keys(res.result).map((val)=>
                Object.keys(res.result[val]).map((val2)=>{
                    // console.log(val);
              data.push({ 
              // key: EQ,
              date:res.result.EQ[val2].date,
              MTM:res.result.EQ[val2].MTM,
              current_price:res.result.EQ[val2].current_price,
              percentage:res.result.EQ[val2].percentage,
              purchased_price:res.result.EQ[val2].purchased_price,
              quantity:res.result.EQ[val2].quantity,
              stock:res.result.EQ[val2].stock,

              date2:res.result.CE[val2].date,
              MTM2:res.result.CE[val2].MTM,
              current_price2:res.result.CE[val2].current_price,
              Option_Sold_rate:res.result.CE[val2].Option_Sold_rate,
              Option_strike_sold:res.result.CE[val2].Option_strike_sold,
              stock2:res.result.CE[val2].stock,
              })
          })
        )
            setData(newdata)
            // })
            // covered_call_options_reporting(input).then(res => {
            //     console.log('reporting',res);
            //     Object.keys(res.result).map((CE)=>
            //         Object.keys(res.result.CE).map((val2)=>{
            //             // console.log(val2);
            //       newdata2.push({ 
            //         key: CE,
            //         date:res.result.CE[val2].date,
            //         MTM:res.result.CE[val2].MTM,
            //         current_price:res.result.CE[val2].current_price,
            //         Option_Sold_rate:res.result.CE[val2].Option_Sold_rate,
            //         Option_strike_sold:res.result.CE[val2].Option_strike_sold,
            //         stock:res.result.CE[val2].stock,
            //         })
            //   })
            // )
            //     setData2(newdata2)
                })
                console.log(newdata2);
      },[])

      const columns = [
        {title: 'Date',dataIndex: 'date', width: '7%'},
        {title: 'Stock',dataIndex: 'stock', width: '11%'},
        {title: 'Quantities',dataIndex: 'quantity', width: '7%'},
        {title: 'Current Price',dataIndex: 'current_price', width: '8%'},
        {title: 'Purchased Price',dataIndex: 'purchased_price', width: '9%'},
        {title: 'MTM',dataIndex: 'MTM', width: '6%'},
        {title: 'Percentage',dataIndex: 'percentage' },
      ]
    
      const columns2 = [
        {title: 'Date',dataIndex: 'date2', width: '7%'},
        {title: 'Stock',dataIndex: 'stock2', width: '12%'},
        {title: 'Current Price',dataIndex: 'current_price2', width: '8%'},
        {title: 'Option Sold Rate',dataIndex: 'Option_Sold_rate', width: '11%'},
        {title: 'Option Strike Sold',dataIndex: 'Option_strike_sold', width: '11%'},
        {title: 'MTM',dataIndex: 'MTM2',},   
      ]
      
  return (
    <>
      <span>
      <div className='mfCustomerEmail'>{props.email_address}</div>
      <Row>
          <Col span={12}>
            <Table
            rowClassName="editable-row"
            columns={columns}
            dataSource={data}
            size='small'
            scroll={{ x: 1150, y: 400 }}
            pagination={{
                pageSize: 10,
                position:['bottomCenter']
            }}
            sticky={true}
            exportable
            />
          </Col>
          <Col span={12}>
            <Table
            rowClassName="editable-row"
            columns={columns2}
            dataSource={data}
            size='small'
            // align='right'
            scroll={{ x: 1150, y: 400 }}
            pagination={{
                pageSize: 10,
                position:['bottomCenter']
            }}
            sticky={true}
            exportable={{
                position:['topright']
            }}
            />
          </Col>
      </Row>
        
          
          
          </span>
      </>
  )
}

export default CcoReporting
import React,{useState,useEffect} from 'react'
import {MDBIcon} from 'mdbreact'
import {save_asset_details,update_asset_details,get_asset_details,family_members,delete_asset_details} from './Api'
import {get_uploaded_docs} from '../Profile-setup/Api'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../shared/Loader';
import './FinancialProfile.scss'
import './FinancialProfileMediaQuery.scss'
import CurrencyInput from 'react-currency-input-field';
import AddDocument from '../Profile-setup/AddDocument';
import {view_compliants_docs} from '../Compliance/Api'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal,message, Popconfirm  } from 'antd';
import Srfo from './AssetSrfo';
import { data } from 'jquery';


const Asset2 = (props) => {
    const {id,asset,insurance,step3,step5,update} = props

    const[dataDict,setDataDict] = useState({})
    const[familyDropDown,setFamilyDropDown] = useState({})
    const[disabled,setDisabled] = useState(false)
    const[loading,setLoading] = useState(false)
    const[documentModal,setDocumentModal] = useState(false)
    const[refrence,setRefrence] = useState('')
    const[uploadedDocs,setUploadedDocs] = useState({})
    const[viewModal,setViewModal] = useState(false)
    const[view,setView] = useState(null)
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [warning,setWarning] = useState(false)
    const [successmsg,setSuccessmsg] = useState('')
    const [errormsg,setErrormsg] = useState('')
    const [visible, setVisible] = useState(false);
    const [srfoData,setSrfoData] = useState({})
    const [isSingleEdit,setIsSingleEdit] = useState(false)
    const [editRowIndex,setEditRowIndex] = useState('')
    const [showSave,setShowSave] = useState(disabled)
    

    const toastMsg =(ids,msg,type)=>{
        toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
      } 

    useEffect(()=>{
        if(asset===1){setDisabled(true)}
        const input = {
            access_token:localStorage.token,
            id:id,
            dir:'assets'
        }
        get_asset_details(input).then(res => {
            console.log("resasset",res)
            try{
                if (res.status==='successfull') {
                    setDataDict(res.result)
                }
                else{console.log(res)}
            }catch{
                toast.error("Something went wrong")
                setLoading(false)
            }
        })
        get_uploaded_docs(input).then(res => {
            console.log('docs-res',res)
            try{
                if (res.status==='successfull'){setUploadedDocs(res.result)
                }else{}
            }catch{
                toast.error("Something went wrong")
                setLoading(false)
            }
        })
        family_members(input).then(res => {
            console.log('rrelation',res)
            try{
            if (res.status==='successfull'){setFamilyDropDown(res.result)}
            else{console.log(res)}
            }catch{toast.error("Something went wrong")}
            })
    },[])

    const addFunc=(group,asset)=>{
        let newRow = parseInt(Object.keys(dataDict[group][asset])[Object.keys(dataDict[group][asset]).length - 1])
        console.log(Object.keys(dataDict[group][asset])[Object.keys(dataDict[group][asset]).length - 1]);
        const newRowItem={name: "",account_managed_by:"",asset_class:dataDict[group][asset][Object.keys(dataDict[group][asset])[0]].asset_class,contribution_end_date:"NULL",current_value:0,description:"",family_member_id:"",interest_rate:0,invested_date:"NULL",invested_value: "",liquidable_and_non_liquidable:dataDict[group][asset][Object.keys(dataDict[group][asset])[0]].liquidable_and_non_liquidable,maturity_date: "NULL",jointly_held:"N", no_of_units_and_shares: 0,regular_savings: 0,relation_id: "",remarks: "",nominee_id:"",srfo:"",war_calculation:"Y",irr:dataDict[group][asset][Object.keys(dataDict[group][asset])[0]].irr,start_date:dataDict[group][asset][Object.keys(dataDict[group][asset])[0]].start_date}

        console.log(newRowItem);
        let aa = {...dataDict}
        // Object.values(dataDict[group][asset]).map(item => {
            newRow = newRow + 1
            console.log(newRow);
            if(!aa[group][asset][newRow]){
                aa[group][asset][newRow] = newRowItem
                setDataDict(aa);
            }
          
    //    }
    //    );
    }
    const removeFunc=(group,asset)=>{
        let aa = {...dataDict}
        // let length = Object.keys(aa[group][asset]).length
        let length = parseInt(Object.keys(dataDict[group][asset])[Object.keys(dataDict[group][asset]).length - 1])
        console.log(aa[group][asset][length].current_value);
        if(length>1){
           if(aa[group][asset][length].current_value===0){
        delete aa[group][asset][length];
        setDataDict(aa);
           }
        }
    }

    const handleChangeValue=(e,group,asset,row)=>{
        const aa = {...dataDict};
        aa[group][asset][row][e.target.name] = e.target.value;
        setDataDict(aa);
    }
    const handleChangeAmount=(value,name,group,asset,row)=>{
        const aa = {...dataDict};
        aa[group][asset][row][name] = parseInt(value);
        setDataDict(aa);
    }
    const handleChangeCheckbox=(e,group,asset,row)=>{
        const aa = {...dataDict};
        if(aa[group][asset][row].war_calculation === 'Y'){
            aa[group][asset][row].war_calculation = 'N'
        }else{
            aa[group][asset][row].war_calculation = 'Y'
        }
        setDataDict(aa);
    }
    
    const submitAsset=(e)=>{
        const ids = toast.loading("Please wait...",{toastId:'load'})
        e.preventDefault();
        const input = {
            access_token:localStorage.token,
            id:id,
            asset_result:dataDict
        }
        console.log("inputres",input)
        save_asset_details(input).then(res => {
            console.log("resdropassety",res)
            try{
                if (res.status==='successfull') {
                    if(asset===0){
                        update();
                        step5();
                    }
                    else{
                        toastMsg(ids,res.message,'success')
                        setDisabled(true)
                    }
                }
                else{toastMsg(ids,res.message,'error')}
            }catch{toastMsg(ids,'Something went wrong','error')}
        })   
    }

    const edit=()=>{setDisabled(false)}

    const deleteSingle=(group,asset,row)=>{
        const aa = {...dataDict};
        console.log(group,asset,row);
        const input = {
            access_token:localStorage.token,
            row:aa[group][asset][row],
            row_id:row,
            group:group,
            asset:asset,
            id:id,
        }
        console.log(input);
        delete_asset_details(input).then(res => {
            console.log("delete",res)
            try{
                if (res.status==='successfull') {
                    toast.success(res.message)
                    get_asset_details(input).then(res => {
                        console.log("resasset",res)
                        try{
                            if (res.status==='successfull') {
                                setDataDict(res.result)
                            }
                            else{console.log(res)}
                        }catch{
                            toast.error("Something went wrong")
                            setLoading(false)
                        }
                    })
                }
                else{toast.error(res.message)}
            }catch{
                toast.error("Something went wrong")
                setLoading(false)
            }
        })
    }
    const editSingle=(group,asset,row)=>{
        setIsSingleEdit(true)
        setDisabled(false)
        setShowSave(true)
        setEditRowIndex(asset+row)
    }
    const saveSingle=(group,asset,row)=>{
        let aa = {}
        aa = dataDict[group][asset][row]
        setIsSingleEdit(false)
        const input = {
            access_token:localStorage.token,
            id:id,
            edit_row:aa,
            group:group,
            asset:asset,
            asset_row_id:row
        }
        console.log("inputupdate",input)
        if(dataDict[group][asset][row].current_value===0){toast.error('Please fill the current value')}
        else{
        update_asset_details(input).then(res => {
            console.log("assetupdate",res)
            try{
                if (res.status==='successfull') {
                    setShowSave(false)
                    toast.success(res.message)
                    setDisabled(true)
                }
                else{toast.error(res.message)}
            }catch{}
        })   
    }
    }
    const cancel=()=>{
            setError(false)
            setSuccess(false)
            setDisabled(true)
        }
   
    const openModal=(refrence)=>{
        setDocumentModal(true)
        setRefrence(refrence)   
    }
    const closeModaldocument=()=>{
        setDocumentModal(false)
        setViewModal(false)
        setView(null)
      }
    const onAfterUpload=()=>{
        const input = {
          access_token:localStorage.token,
          id:id,
          dir:'assets'
      }
        get_uploaded_docs(input).then(res => {
          console.log('docs-res',res)
          try{
          if (res.status==='successfull') {setUploadedDocs(res.result)}
        else{toast.error(res.message)}
        }catch{}
        })
        closeModaldocument()
    }
    const pleaseSave=(e)=>{
        e.preventDefault()
        setWarning(true)
    }
    const onleave=()=>{
        setWarning(false)
      }
    const handlesave=()=>{
        step5();
    }

    const handleCancel=()=>{
        setVisible(false)
    }
    const openSrfo=(group,asset,row)=>{
        console.log(group,asset,row);
        let dict = {...srfoData}
        dict['asset_row_id'] = row
        dict['asset_type'] = asset
        dict['family_member_id'] = dataDict[group][asset][row].family_member_id
        dict['relation_id'] = dataDict[group][asset][row].relation_id
        setSrfoData(dict)
        setVisible(true)       
    }
    
    
    const download=(name)=>{
        const input = {
            access_token:localStorage.token,
            flag:name,
            id:id,
            dir: 'assets'
        }
        console.log(input)
        let splitType = name.split('.').pop();
        view_compliants_docs(input).then(res => {
            console.log("resss",res)
            try {
                if (res.status==='successfull') {
                    let docs=res.result
                    let linkSource = ''
                    if(splitType==='pdf'){
                      linkSource = `data:application/pdf;base64,${docs}`;
                    }
                    else{
                      linkSource = `data:image/png;base64,${docs}`;
                    }
                    const downloadLink = document.createElement("a");
                    const fileName = name;
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
                else{}
            } catch (error) {}
            })
      }

    const viewDocs=(name)=>{
        setViewModal(true)
        const input = {
          access_token:localStorage.token,
          flag:name,
          id:id,
          dir: 'assets'
        }
        let splitType = name.split('.').pop();
        view_compliants_docs(input).then(res => {
          console.log("viewRes",res)
          try {
              if (res.status==='successfull') {
                  if(splitType==='pdf'){setView(`data:application/pdf;base64,${res.result}`)}
                  else{setView(`data:image/png;base64,${res.result}`)}
              }else{}
          } catch (error) {}
        })
      }
    let amount_arr = []
    let t_amount = 0
    let sumAmount =0
    if (loading){
        return(
            <div style={{textAlign:'center',marginTop:100}}>
            <Loader/>
            </div>
        );
    }
    const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits:0
    }).format(value);
 
    const retiralAsset=["Ownership*","Description","Contribution End Date","Maturity Date","Current value","Regular Savings(Yearly)","Liquidable/Non Liquidable*","Asset Class","Remarks"]

    const investmentAsset=["Ownership*","Description","Account Managed By","No. of Units/ Shares","Investment Date","Investment value","Current value","Regular Savings(Yearly)","Liquidable/Non Liquidable*","Asset Class","Remarks"]

    const bankBasedAsset=["Ownership*","Description","Investment Date","Investment value","Interest Rate","Maturity Date","Current value","Regular Savings(Yearly)","Liquidable/Non Liquidable*","Asset Class","Remarks"]

    const realState=["Ownership*","Name","Investment Date","Investment value","Current value","Liquidable/Non Liquidable*","Asset Class","Remarks"]


    // console.log(dataDict);
    return (
        <div>
        <ToastContainer theme='colored' limit={1}/>
            {error?
                    <SweetAlert
                        error
                        onConfirm={cancel}>
                    {errormsg}
                    </SweetAlert>:null}

                {success?
                    <SweetAlert
                        success
                        onConfirm={cancel}>
                    {successmsg}
                    </SweetAlert>:null}

                {warning?
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnText="No"
                        cancelBtnBsStyle="danger"
                        onConfirm={handlesave}
                        onCancel={onleave}
                        focusCancelBtn>Do you want to proceed without save your changes
                    </SweetAlert>:null}

            <div className='form-heading'>Asset Details
                {/* {asset===1?(
                <span className='formButton'>
                    {disabled?
                    <button type="button" onClick={edit}><MDBIcon far icon="edit" className='form-edt-icon'/>Edit</button>:
                    <>
                    <button onClick={submitAsset}><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>
                    </>}
                </span>):null} */}
            </div>

            <div className='formHeightSmall scrollB'>  
                {dataDict?(
                <>
                <div className='row tb_header-top'>
                    <div className='col-3'>Asset Type</div>
                    <div className='col-6'></div>
                    <div className='col-2 lf-border'>Total Amount</div>
                </div>
            {Object.keys(dataDict).map((group,index)=>{
                t_amount = 0
                Object.keys(dataDict[group]).map((asset)=>{
                Object.keys(dataDict[group][asset]).map((row)=>{
                    t_amount = t_amount + parseInt(dataDict[group][asset][row].current_value)
                })
                })
                amount_arr.push(t_amount)
                return(<>
                    <div className='row colapse-form-row' data-toggle="" data-target={'#'+group} aria-expanded="true" aria-controls={group}>
                        <div className='col-4'>{group}</div>
                        <div className='col-5'></div>
                        <div className='col-2'>{numberFormat(amount_arr[index])}</div>
                        <div className='col-1'><MDBIcon icon="angle-down" /></div>
                    </div>

                    <div id={group} class='' aria-labelledby="headingOne">
                        <div className='tb-height'>
                        <table className="table table-condensed table-sm table-bordered">   
                            <tr className='tb_header'>
                                
                                <td style={{minWidth:'160px'}}>Type</td>
                                <td>WAR</td>
                               {group==='BANK & POST OFFICE BASED ASSETS'?
                                <>{bankBasedAsset.map((type)=> <td>{type}</td>)}</>:
                                group==='INVESTMENT ASSETS'?
                                <>{investmentAsset.map((type)=> <td>{type}</td>)}</>:
                                group==='REAL ESTATE'?
                                <>{realState.map((type)=> <td>{type}</td>)}</>:
                                group==='RETIRAL ASSETS'?
                                <>{retiralAsset.map((type)=> <td>{type}</td>)}</>:
                                <>{retiralAsset.map((type)=><td>{type}</td>)}</>
                                }
                                <td style={{minWidth:'60px'}} >IRR (%)</td>
                                <td style={{minWidth:'150px'}}>Document</td>
                                {/* <td style={{minWidth:'100px'}}>Nominee</td> */}
                                <td>Succession Details</td>
                                {asset===1?
                                <td>Operations</td>:null}
                            </tr>
                {Object.keys(dataDict[group]).map((asset)=>{
                return(
                    <tbody>
                    {Object.keys(dataDict[group][asset]).map((row,index1)=>{  
                        let ref = asset + '-'+ row
                        let view = false
                        let fileName = ''
                        if(uploadedDocs){
                            Object.keys(uploadedDocs).map((upldDocument)=>{
                                if (upldDocument===ref){
                                    view=true
                                    fileName =uploadedDocs[upldDocument].document_extension
                                }
                            })
                        }
                        
                        return(
                            <tr className='tb_body_asset'>
                               {index1===0?(
                                <>
                                
                                <td rowspan={index1}>{asset}
                                <MDBIcon far icon="plus-square" className="assetAddIcon" onClick={()=>addFunc(group,asset)} />

                                <MDBIcon far icon="minus-square" className="assetAddIcon" onClick={()=>removeFunc(group,asset)} />
                                </td>
                                </>):null}
                                <td>
                                    <input type='checkbox' onChange={(e)=>handleChangeCheckbox(e,group,asset,row)} name="war_calculation" checked={dataDict[group][asset][row].war_calculation==='Y'}disabled={props.asset===1?asset+row!==editRowIndex:false} />
                                    </td>

                                {/* {group==='BANK & POST OFFICE BASED ASSETS'? */}
                                <td>
                                    <select value={dataDict[group][asset][row].family_member_id} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="family_member_id"  disabled={props.asset===1?asset+row!==editRowIndex:false}>
                                        <option value="">Select</option>
                                        {Object.entries(familyDropDown).map((member)=> 

                                            <option value={member[0]}>{member[1]}</option>
                                        )}
                                    </select>
                                </td>
                                {/* :null} */}

                                {group==='REAL ESTATE'?
                                 <td>
                                    <input type="text" placeholder={dataDict[group][asset][row].name} 
                                    onChange={(e)=>handleChangeValue(e,group,asset,row)} name="name"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>:
                                <td>
                                    <input type="text" placeholder={dataDict[group][asset][row].description} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="description"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>}

                                {group==='INVESTMENT ASSETS'?
                                <>
                                <td>
                                    <input type="text" placeholder={dataDict[group][asset][row].account_managed_by} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="account_managed_by"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>
                                 <td>
                                    <input type="number" placeholder={dataDict[group][asset][row].no_of_units_shares} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="no_of_units_shares"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>
                                </>
                                :null}

                                {group==='RETIRAL ASSETS' || group==='OTHER ASSETS'?
                                <>
                                <td>
                                    <input type="date" value={dataDict[group][asset][row].contribution_end_date} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="contribution_end_date"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>
                                <td>
                                    <input type="date" value={dataDict[group][asset][row].maturity_date} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="maturity_date"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>
                                </>:
                                <>
                                <td>
                                    <input type="date" value={dataDict[group][asset][row].invested_date} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="invested_date"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>
                                <td style={{minWidth:'80px'}}>
                                <CurrencyInput
                                    name="invested_value"
                                    placeholder={numberFormat(dataDict[group][asset][row].invested_value)}
                                     disabled={props.asset===1?asset+row!==editRowIndex:false}
                                    onValueChange={(value,name)=>handleChangeAmount(value,name,group,asset,row)}
                                    intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                    prefix="₹"/>
                                </td>
                                </>}
                                {group==='BANK & POST OFFICE BASED ASSETS'?
                                <>
                                <td>
                                    <input type="number" placeholder={dataDict[group][asset][row].intrest_rate} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="intrest_rate"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>
                                <td>
                                    <input type="date" value={dataDict[group][asset][row].maturity_date} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="maturity_date"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>
                                
                                </>:null}
                                <td style={{minWidth:'80px'}}>
                                <CurrencyInput
                                    name="current_value"
                                    placeholder={numberFormat(dataDict[group][asset][row].current_value)}
                                     disabled={props.asset===1?asset+row!==editRowIndex:false}
                                    onValueChange={(value,name)=>handleChangeAmount(value,name,group,asset,row)}
                                    intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                    prefix="₹"/>
                                </td>

                                {group==='REAL ESTATE'?null:
                                 <td>
                                 <CurrencyInput
                                     name="regular_savings"
                                     placeholder={numberFormat(dataDict[group][asset][row].regular_savings)}
                                      disabled={props.asset===1?asset+row!==editRowIndex:false}
                                     onValueChange={(value,name)=>handleChangeAmount(value,name,group,asset,row)}
                                     intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                     prefix="₹"/>
                                </td>}
                                
                                <td>
                                    <select value={dataDict[group][asset][row].liquidable_and_non_liquidable}  onChange={(e)=>handleChangeValue(e,group,asset,row)} name="liquidable_and_non_liquidable"  disabled={props.asset===1?asset+row!==editRowIndex:false} >
                                        <option value="">Select</option>
                                        <option value="Liquidable">Liquidable</option>
                                        <option value="Non Liquidable">Non Liquidable</option>
                                    </select>
                                </td>
                                <td>
                                    <input type="text" placeholder={dataDict[group][asset][row].asset_class} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="asset_class" disabled={true}/>
                                </td>
                                <td>
                                    <input type="text" placeholder={dataDict[group][asset][row].remarks} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="remarks"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>
                                <td>
                                    <input type="number" placeholder={dataDict[group][asset][row].irr} onChange={(e)=>handleChangeValue(e,group,asset,row)} name="irr"  disabled={props.asset===1?asset+row!==editRowIndex:false}/>
                                </td>
                                <td className='l-r-br bt-border'>
                                <div>
                                    {view?(
                                        <>
                                        <div>{fileName} 
                                        <div>
                                            <MDBIcon icon="eye" className='mdbicon' onClick={(name)=>viewDocs(fileName)}/>
                                            <MDBIcon icon="download" className='mdbicon' onClick={(name)=>download(fileName)}/>
                                            <MDBIcon icon="edit" className='mdbicon' onClick={(refrence)=>openModal(ref)}/>
                                        </div>
                                        </div>
                                        </>
                                        
                                    ):(
                                        <MDBIcon icon="upload" onClick={(refrence)=>openModal(ref)}/>
                                    )}
                                    </div>
                                </td>
                                <td>
                                    <MDBIcon far icon="plus-square" className="assetAddIcon" onClick={()=>openSrfo(group,asset,row)} />
                                </td>
                                {props.asset===1?
                                <td>
                                    <MDBIcon far icon="edit" className="assetEditIcon" onClick={()=>editSingle(group,asset,row)} />&nbsp; &nbsp;
                                    {showSave?<>
                                    <MDBIcon far icon="save" className="assetSaveIcon" onClick={()=>saveSingle(group,asset,row)} />&nbsp;&nbsp;</>:null}
                                    <Popconfirm
                                        title="Sure to Delete?"
                                        description="Are you sure to delete this task?"
                                        onConfirm={()=>deleteSingle(group,asset,row)} 
                                        onCancel={cancel}
                                        okText="OK"
                                        cancelText="Cancel"
                                    >
                                        <MDBIcon far icon="trash-alt" className="assetDelIcon" />
                                    </Popconfirm>
                                </td>:null}
                            </tr>   
                        )                        
                    })}
                    </tbody>
                )
            })}
            </table>
            </div>
            </div>
            </>
            )
            })}
            </>):null}
            </div>

            <div className='grandTotalArea'>
                <div className='row tb_header-top'>
                    <div className='col-3'></div>
                    <div className='col-6'></div>
                    <div className='col-2 lf-border'>
                        {amount_arr.map((val)=>{sumAmount = parseInt(sumAmount) + parseInt(val)})}
                            {numberFormat(sumAmount)}
                    </div>
                </div>   
            </div>

            {documentModal?(
            <div className='upload-modal-sec'>
                <div className='row'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <div className='upload-modal-sec-card'>
                            <div className='modal-close-icon' onClick={closeModaldocument}><MDBIcon icon="times" /></div>
                            <div className='upload-component'>
                                <AddDocument document_type='investment_certificate' dir='assets' refName='Type' refrence={refrence} onAfterUpload={onAfterUpload} id={id}/>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'></div>
                </div>
        </div>):null}

        {viewModal?(
            <div className='upload-modal-sec'>
                <div className='row'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                    <div className='viewDocs-modal-sec-card'>
                        <div className='modal-close-icon' onClick={closeModaldocument}><MDBIcon icon="times" />
                        </div>    
                        {view?<iframe src={`${view}#view=fitH`} title="testPdf" height="80%" width="100%" />:null}
                    </div>
                    </div>
                    <div className='col-md-4'></div>
                </div>
            </div>):null}

            {asset===0?
            <div className='agree-checkbox'><input type='checkbox'/>  Skip this section</div>:null}

            {insurance===1?null:<div className='previous-msg'>Please fill the previous form first.</div>}

            {insurance===1 && asset===1?(
                 <>
                 {disabled?(
                     <div className='submit-Btn'><button onClick={step3}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button onClick={step5}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>
                 ):(
                     <div className='submit-Btn'><button onClick={step3}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button type='button' onClick={pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>
                 )}
                 </>
            ):(<div></div>)}

            {insurance===1 && asset===0 ?(
            <div className='submit-Btn'><button onClick={step3}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button onClick={submitAsset}>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>):(<div></div>)}

            <Modal visible={visible} onCancel={handleCancel} footer={null} width='60%'>
                <Srfo srfoValues={srfoData} id={id} asset_row_id={srfoData.asset_row_id}/>
            </Modal>
        </div>
    )
}
export default Asset2
import React,{useState,useEffect} from 'react'
import { InputNumber,Form,Input,Select,Row,Col,Button,message } from 'antd'
import {customer_review,admin_succession} from '../Api'

const SuccessionReadiness = () => {
    const [clientEmail,setClientEmail] = useState('')
    const { Option } = Select;
    const [form] = Form.useForm();

    const fetchUser = async () =>{
        const input = {access_token:localStorage.token}
        const res = await customer_review(input)
        if(res.status==='successfull'){
            setClientEmail(res.result)
        }
      }

      useEffect(() =>{
        fetchUser();
      },[])

      const onFinish = async (values) => {
        // setLoading(true)
        const input = {access_token:localStorage.token,values:values}
        console.log(input);
        const res = await admin_succession(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('status',res,input);
        try{
            if (res.status==='successfull') {
            //   getBillingDetails();
              message.success(res.message);
            //   setLoading(false);
              form.resetFields();
            }
            else{ 
                message.error(res.message)
                // setLoading(false)
            }
        }catch{ 
            message.error('Something went wrong')
            // setLoading(false)
        }
      };

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24}
      };
  return (
    <>
    <div className='formContainer'>
      <Form
      {...formItemLayout}
      form={form}
      name="successionRead"
      onFinish={onFinish}
      scrollToFirstError
      >
      <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="id"
              label="User Email"
              rules={[{required: true}]}>
                  <Select
                      placeholder="Select Email">
                        {clientEmail?   
                          Object.keys(clientEmail).map((val)=>{
                              return(<Option value={clientEmail[val].customer_id}>{clientEmail[val].email_address}</Option>)
                          }):null}
                  </Select>
            </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                  name="succession_readiness"
                  label="Succession Readiness"
                  rules={[{required: true, message: 'Please fill!'}]}
              >
                  <InputNumber style={{width:'50%'}}/>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                  name="value_at_risk"
                  label="Value At Risk"
                  rules={[{required: true, message: 'Please fill!'}]}
              >
                  <InputNumber style={{width:'50%'}}/>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                    name="risk_cover"
                    label="Risk Cover"
                    rules={[{required: true, message: 'Please fill!'}]}
                >
                    <InputNumber style={{width:'50%'}}/>
                </Form.Item>
            </Col>
           
      </Row>
      <Form.Item>
          <Button className='SolidButton' htmlType="submit" >Submit</Button>
      </Form.Item>
  </Form>
  </div>
  </>
  )
}

export default SuccessionReadiness

import React,{useEffect,useState} from 'react'
import {uplaods_to_azure,html_content} from './Api'
import {customer_review} from '../Api'
import { Select, Table, Row,Col,Button,Space,Input,Modal,Drawer,message} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined,EyeOutlined,UploadOutlined,MailOutlined} from '@ant-design/icons';
import './Report.scss'
import Loader from '../../../shared/Loader';
import ReactHtmlParser from 'react-html-parser';

const EmailReportUpload = () => {
  const [htmlValue, setHtmlValue] =  useState("");
  const[searchText,setSearchText] = useState('')
  const[searchedColumn,setSearchedColumn] = useState('')
  const [data,setData] = useState([])
  const [checkedClient,setCheckedClient] = useState([])
  const[viewModal,setViewModal] = useState(false)
  const[modlType,setModlType] = useState(false)
  const[resData,setResData] = useState('')
  const [loading,setLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  let searchInput = ''

  const success = (msg) => {
    messageApi.open({
      type: 'success',
      content: msg,
    });
  };

  const error = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };
  const load = (msg) => {
    messageApi.open({
      type: 'loading',
      content: 'loading',
    });
  };

  const viewModl=(name)=>{
    setViewModal(true)
    setModlType('HTML')
  }
  const closeModal=()=>{setViewModal(false)}

  const { TextArea } = Input;

  console.log(checkedClient);
  useEffect(()=>{
    const input = {
        access_token:localStorage.token
    }
    let newdata = [...data]
    customer_review(input).then(res => {
        console.log(res,'ress')
    try{
        if (res.status==='successfull'){
            Object.keys(res.result).map((val)=>{
                newdata.push({ 
                key: res.result[val].customer_id,
                customer_id: res.result[val].customer_id,
                name:res.result[val].customer_fullname,
                email_address:res.result[val].email_address,
                mobile_number:res.result[val].mobile_number,
                onboarding_date:res.result[val].onboarding_date,
                compliance_status:res.result[val].compliance_status,
                address_details:res.result[val].address_details,
                zerodha:res.result[val].zerodha,
                family_member_id:res.result[val].family_member_id,
               
            })
            })
            setData(newdata)
        }else{}
    }catch{}
    })
},[])

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {setCheckedClient(selectedRows)},
  // getCheckboxProps: (record) => ({disabled: record.zerodha === 'N'}),
  // getCheckboxProps: (record) => ({disabled: checkedClient.length !== 'N'})
}

let getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>

      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
          }}
        >
          Filter
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
  render: text =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0])
  setSearchedColumn(dataIndex)
};

const  handleReset = clearFilters => {
  clearFilters();
  setSearchText('')
};

 const changeHtmlText = (e) =>{
    setHtmlValue(e.target.value)
 }

  const TextFile = () => {
      load()
      // let newVal = htmlValue.replace(/(\n)/gm, "") 
      const input = {
          access_token:localStorage.token,
          docs:htmlValue
      }
      console.log(input);
      uplaods_to_azure(input).then(res => {
        console.log('emm',res);
        try{
          if (res.status==='Succesfull'){
            success(res.message)
          }
          else{  error(res.message)}
        }catch{error('Something went wrong')}
      })
  }

  const PreviewData = (record) => {
    setLoading(true)
    setModlType('allData')
    setViewModal(true)
    const input = {
        access_token:localStorage.token,
        preview:'Y',
        email:'N',
        cus_id:record.customer_id
    }
    console.log(input);
    html_content(input).then(res => {
      console.log('preview',res);
      try{
        if (res.status==='successfull'){
          setResData(res.result)
          setLoading(false)
        }
        else{
          setLoading(false)
        }
      }catch{
        setLoading(false)
      }
    })
}

    const EmailData = () => {
      load()
      let dd = {...checkedClient}
      const input = {
          access_token:localStorage.token,
          preview:'N',
          email:'Y',
          email_list:dd
      }
      console.log(input);
      html_content(input).then(res => {
        console.log('emmjj',res);
        try{
          if (res.status==='Succesfull'){
            success(res.message)
          }
          else{ error(res.message)}
        }catch{ error('Something went wrong')}
      })
}

const columns = [ 
  {
    title: 'Id',
    dataIndex: 'customer_id',
    width:60
  },
  {
    title: 'Name',
    dataIndex: 'name',
    ...getColumnSearchProps('name'),
    width:140
  },
  {
    title: 'Email',
    dataIndex: 'email_address',
    ...getColumnSearchProps('email_address'),
  },
  {title: 'Preview',key: 'action',width: 70,
  render: (text, record) => (
      <button className='TableActionButton' onClick={()=>PreviewData(record)}><EyeOutlined/></button> 
  ),
},
];

  return (
    <div className='row'>
        {contextHolder}
      <Row>
        <Col span={12}>
          <div className='textAreaBox'>
          <TextArea 
            rows={18} 
            placeholder="Paste HTML here..." 
            onChange={changeHtmlText}
            allowClear={true}
          />
          </div>
          <div className='buttonC'>
            <button onClick={viewModl}><EyeOutlined />&nbsp;Preview</button>

            {Object.keys(checkedClient).length>0?(
               <button style={{background:'#E74C3C'}} onClick={EmailData}><MailOutlined />&nbsp;Email</button>
            ):(
              <button style={{background:'#F5B7B1'}} disabled><MailOutlined />&nbsp;Email</button>
            )}
           

          </div>
          
        </Col>
        <Col span={12}>
          <div>
            <Table
                rowSelection={{type: 'checkbox',...rowSelection}}
                columns={columns}
                dataSource={data}
                size='small'
                sticky={true}
                rowClassName="editable-row"
                scroll={{y: 350 }}
                pagination={{pageSize: 10,position:['bottomCenter']}}
            />
          </div>
        </Col>
      </Row>


      {/* <Modal title="Preview" visible={viewModal} onCancel={closeModal} footer={null} style={{ top:10}}>
      {modlType==='allData'?(
        <>
         <div>{parse(resData)}
      </div>
        </>
      ):null}
     
      {modlType==='HTML'?(
        <>
         <div>
            {parse(htmlValue)}
        </div>
        <div className='buttonC'><button onClick={TextFile}><UploadOutlined />&nbsp;Upload</button></div>
        </>
      ):null}
       
      </Modal> */}



      
      <Drawer title="Preview" placement="top" height={'100%'} open={viewModal} onClose={closeModal}>
      {loading?<Loader/>:null}
        <Row>
          <Col span={2}></Col>
          <Col span={20} style={{padding:'10px'}}>
          {modlType==='allData'?(
              <>
              <div>
                {Object.keys(resData).length>0?(<>{ReactHtmlParser(resData)}</>):null}
               
                </div>
              </>
            ):null}
          
            {modlType==='HTML'?(
              <>
              <div>
                  {ReactHtmlParser(htmlValue)}
              </div>
              <div className='buttonC'><button onClick={TextFile}><UploadOutlined />&nbsp;Upload</button></div>
              </>
            ):null}
          </Col>
          <Col span={2}></Col>
        </Row>
     
      </Drawer>
   
  </div>
  ) 
}

export default EmailReportUpload
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crmCardPlate {
  padding: 10px 10px;
  background: #F4F6F6;
}

.crmCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  background: white;
  margin-top: 10px;
  cursor: pointer;
}

.crmCardRow {
  border-bottom: 1px solid lightgray;
}

.crmCardName {
  font-weight: 600;
}

.crmCardmail {
  font-size: 9px;
  margin-top: -16px;
  font-weight: 500;
  color: grey;
}

.crmCardDate {
  color: grey;
  font-weight: 400;
  font-size: 10px;
  margin-top: 10px;
}

.crmCardpoint {
  font-size: 12px;
  margin-top: 5px;
}

.crmCardicon {
  font-size: 9px;
  margin-right: 10px;
  color: #E87C1B;
}

.setCrmDiv {
  padding: 5px 5px;
  border-bottom: 1px dashed lightgray;
}

.setCrmspan {
  font-size: 12px;
  margin-left: 10px;
}

.setCrmspanSelect {
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Admin/crmStatus/CrmStatus.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;EACA,mBAAA;AAAJ;;AAEA;EACI,0CAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AACJ;;AACA;EACI,kCAAA;AAEJ;;AAAA;EACI,gBAAA;AAGJ;;AADA;EACI,cAAA;EAEA,iBAAA;EACA,gBAAA;EACA,WAAA;AAIJ;;AAFA;EACI,WAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AAKJ;;AAHA;EACI,eAAA;EACA,eAAA;AAMJ;;AAJA;EACI,cAAA;EACA,kBAAA;EACA,cAtCQ;AA6CZ;;AAJA;EACI,gBAAA;EACA,mCAAA;AAOJ;;AALE;EACE,eAAA;EACA,iBAAA;AAQJ;;AANE;EACE,iBAAA;AASJ","sourcesContent":["$mainColor: #E87C1B;\n.crmCardPlate{\n    padding: 10px 10px;\n    background: #F4F6F6;\n}\n.crmCard{\n    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);\n    padding: 10px 10px;\n    background: white;\n    margin-top: 10px;\n    cursor: pointer;\n}\n.crmCardRow{\n    border-bottom: 1px solid lightgray;\n}\n.crmCardName{\n    font-weight: 600;\n}\n.crmCardmail{\n    font-size: 9px;\n    font-weight: 100;\n    margin-top: -16px;\n    font-weight: 500;\n    color: grey;\n}\n.crmCardDate{\n    color: grey;\n    font-weight: 400;\n    font-size: 10px;\n    margin-top: 10px;\n}\n.crmCardpoint{\n    font-size: 12px;\n    margin-top: 5px;\n}\n.crmCardicon{\n    font-size: 9px;\n    margin-right: 10px;\n    color: $mainColor;\n}\n// ----------------config-------------------\n.setCrmDiv{\n    padding: 5px 5px;\n    border-bottom: 1px dashed lightgray;\n  }\n  .setCrmspan{\n    font-size: 12px;\n    margin-left: 10px;\n  }\n  .setCrmspanSelect{\n    margin-left: 20px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React,{useEffect,useState} from 'react'
import {Input,Button,Form,Modal,Row,Col,Divider, message} from 'antd';
import {handleReply} from './Api'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {comments_chat} from './Api'

const ReplyModal = (props) => {
  const [data, setData] = useState([]);
  const[success,setSuccess] = useState(false)
  const[successMsg,setSuccessMsg] = useState('')
  const [form] = Form.useForm();

  const { TextArea } = Input;
  const onSubmitLogin = async (values) =>{
    // setLoading(true)
    const input = {
      access_token:localStorage.token,
      comment: values.comment,
      comment_id:props.commentId,
      customer_id:props.customerId
    }
    console.log(input);
    const res = await handleReply(input)
    console.log('replyres',input,res);
    try{
        if (res.status==='successfull') {
          setSuccess(true)
          message.success(res.message)
          form.resetFields();
          fetchComment();
          // setLoading(false)
        }
        else{
          toast.error(res.message)
          // setLoading(false)
        }
    }catch{
      toast.error('Something went wrong')
      // setLoading(false)
    }
  }
  
  const fetchComment = async ()=>{
    let newdata = []
    const input = {
      access_token:localStorage.token,
      comment_id:props.commentId,
      customer_id:props.customerId
    }
    console.log(input);
    const res = await comments_chat(input)
    console.log('resReply',res);
    try{
        if (res.status==='successfull') {
          Object.keys(res.result).map((val)=>{
            newdata.push({ 
            key: res.result[val].comment_id,
            comment_id:res.result[val].comment_id,
            customer_id:res.result[val].customer_id,
            comment: res.result[val].comment,
            date: res.result[val].date,
            name: res.result[val].name,
            })
        })
        setData(newdata)
        }
        else{
         
        }
    }catch{
      toast.error('Something went wrong')
    }
  }
  useEffect (()=>{
    fetchComment()
  },[])

  return (
    <Modal  footer={null} visible={true} onCancel={props.handleReplyModal} width={'70%'}>
      <div className='commentBoxHead'>Comments <span>{props.status}</span></div>

      <Row gutter={16}>
        <Col span={14}>
        <div className='commentListBox scrollB'>
          {Object.keys(data).map((val)=>{
            return(
              <>
                {data[val].name!=='Admin'?
                <div className='commentBoxTagLine'>
                  Showing archive of {data[val].date} - Created By {data[val].name}
                </div>:
                 <div className='commentBoxTagLine'>
                  Responded by wealth support user on {data[val].date}
               </div>}
                <div className='commentListText'>{data[val].comment}</div>
                <Divider/>
              </>
            )
          })}
        </div>
        </Col>
        <Col span={10}>
          <Form onFinish={onSubmitLogin} form={form}>
            <Form.Item
              name="comment"
              rules={[{ required: true, message: 'Please input comment!' }]}>
              <TextArea rows={7} maxLength={1000} allowClear={true} showCount={true}/>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" className="submitButton" >Reply</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

    </Modal>
  )
}

export default ReplyModal
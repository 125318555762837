import React,{useState,useEffect} from 'react'
import './NewMainPage.scss'
import Loader from '../../../shared/Loader';

import {instrument_list,screen_management_user} from '../Api'
import {useNavigate} from 'react-router-dom'
import { Menu,Row, Col,Breadcrumb,Avatar, Dropdown,Tabs} from 'antd';
import { MDBIcon } from 'mdbreact';
import {CaretRightFilled} from '@ant-design/icons'
import logo from "../../../Images/logoBlack.png"
import logoSingle from "../../../Images/hoa_logo_single.png"
import User from "../../../Images/user.png"

import ClientReveiw from '../userManagement/AllClient'
import ReviewTest from '../userManagement/AllTestClient';
import OthersUserReview from '../userManagement/InternalUser'

import RecommendationDashboard from '../Recommandation/Home' 
import SendRecommendation from '../Recommandation/SendRecommendation' 
import ReviewRecommendation from '../Recommandation/Review'

import MonthEndReport from '../monthendreport/MonthEndReport'
import FullMonthEndReport from '../monthendreport/MonthEndReport1'
import Blog from '../socilaActivity/AdminBlog1'
import AssetIrr from '../socilaActivity/AssetIrr'
import Incomerate from '../socilaActivity/Incomerate'
import Vedio from '../socilaActivity/AdminVideo1'
import Newsletter from '../socilaActivity/NewsLetter2'
import NewsletterSubscription from '../socilaActivity/NewsletterSubscription'
import AssetClassList from '../userManagement/Update/Asset_class_list';
import ReviewDownload from '../userManagement/Update/Review_download'
import Gains from '../userManagement/Update/Gains';
import MasterStockList from '../userManagement/Update/Master_stock_list';
import MasterTran from '../Reports/Master_tran';
import Allocation from '../Reports/Allocation';
import RiaPercentage from '../userManagement/Update/RiaPercentage4'
import Regulatory from '../userManagement/Update/Complain'
import AdminTestimonial from '../socilaActivity/AdminTestimonial1'
import WebsiteLead from '../userManagement/leadManagement/WebsiteLead'
import Comment from '../socilaActivity/Comment'
import UserCreation from '../userCreation/UserCreation'
import ScreenManagement from '../userCreation/ScreenManagement'
import DocsUpload from '../docsUpload/DocsUpload'
import NetworthUpload from '../docsUpload/NetworthUpload';
import FileUploads from '../docsUpload/FilesUploads';
import FilesDownload from '../docsUpload/FilesDownload';
import DocsDownload from '../docsUpload/DocsDownload'
import Setup from '../ccoStrategy/Setup'
import Review from '../ccoStrategy/Review'
import BillingDetails from '../billingSection/BillingDetails'
import BillingHistory from '../billingSection/BillingHistory';
import SuccessionReadiness from '../socilaActivity/SuccessionReadiness';
import Allclientwise from '../Reports/Allclientwise';
import Schemewise from '../Reports/Schemewise';
import Classwise from '../Reports/Classwise';
import ClientWise from '../Reports/ClientWise';
import Hoqreport from '../Reports/Hoqreport';
import Monthonmonthreport from '../Reports/Monthonmonthreport';
import Momequityreport from '../Reports/Momequityreport';
import Mommfreport from '../Reports/Mommfreport';

import CrmStatus from '../crmStatus/CrmStatus';
import CrmConfig from '../crmStatus/CrmConfig'
import EmailReportUpload from '../Reports/EmailReportUpload';
import Master_stock_list from '../userManagement/Update/Master_stock_list';

const { SubMenu } = Menu;

const NewMainPage = (props) => {
  const { TabPane } = Tabs;
  
    const[sideTab,setSideTab] =useState({
                                       'Client Review':true,'Client Test Review':false,otherReview:false,  'Lead Management':false,recomDashboard:false,recomSend:false,recomReview:false,totalmonthend:false,hoqreport:false,monthonmonthreport:false,equityreport:false,mfreport:false,clienttotalmonthend:false,
                                        universal:false,
                                        ria:false,reviewdownload:false,gains:false,masterstocklist:false,regulatory:false,'Asset class':false,mastertrans:false,allocation:false,emailReportUpload:false,
                                        allclientwise:false,schemewise:false,classwise:false,client_wise:false,
                                        blog:false,vedio:false,testimonial:false,newsletter:false,newsletterSubscription:false,comment:false,successionReadiness:false,
                                        userCreation:false,screenManagement:false,docsUpload:false,fileUpload:false,fileDownload:false,networthUpload:false,docsDownload:false,
                                        ccoSetup:false,ccoReview:false,billingDetails:false,billingHistory:false,assetIrr:false,incomeRate:false,crmConfig:false,crmStatus:false
                                    })
  const [subMenuKey,setSubMenuKey] = useState('Users Management')
  const [menuKey,setMenuKey] = useState('Client Review')
  const[collapsed,setCollapsed] =  useState(false)
  const[modal,setModal] = useState({emailModal:false,passwordModal:false})
  const[instrument,setinstrument] = useState({})
  const[screens,setScreens] = useState({})
  const [tabs, setTabs] = useState('All Leads');
  const [tavs, setTavs] = useState('stock_list');
  const defaultTab = 'tab1';

  const navigate = useNavigate()

  const onChange = (key) => {
    setTabs(key)
  };
  const onChangeTavs =(key)=>{
    setTavs(key)
  }

  const handleTab=(name)=>{
    console.log(name);
    Object.keys(sideTab).map(item => {
        if(item===name){setSideTab(prevState => ({...prevState,[item]: true}))
        }else{setSideTab(prevState => ({...prevState,[item]: false}))}
   });
  }
  console.log(tabs);
  const handleModal=(name)=>{
    Object.keys(modal).map(item => {
      if(item===name){setModal(prevState => ({...prevState,[item]: true}))
      }else{setModal(prevState => ({...prevState,[item]: false}))}
    }); 
  }
  const  toggleCollapsed = () => {
    setCollapsed(!collapsed)
  };
  const menuItemSelect =(item)=>{
    console.log(item);
    setMenuKey(item.keyPath[0])
    setSubMenuKey(item.keyPath[1])
  }
  const logOut=()=>{
    localStorage.clear();
    navigate('/')
  }
  const getInstrument = async() =>{
    const input = {access_token:localStorage.token}
    const res = await instrument_list(input)
    try{
        if (res.status==='successfull') {setinstrument(res.result)}
        else{}
    }catch{}
}
const getScreen = async() =>{
    const input = {access_token:localStorage.token}
    const res = await screen_management_user(input)
    console.log(res);
    try{
        if (res.status==='successfull') {setScreens(res.result)}
        else{}
    }catch{}
}
  useEffect(()=>{
    getInstrument()
    getScreen()
  },[])
  const setting = (
    <Menu>
      <Menu.Item onClick={()=>handleModal('emailModal')}>Change Email</Menu.Item>
      <Menu.Item onClick={()=>handleModal('passwordModal')}>Change Password</Menu.Item>
      <Menu.Item onClick={logOut}>Log Out</Menu.Item>
    </Menu>
  );

  return (
    <div className='parentDivBg'>
      <div className={collapsed?'sideBarClose':'sideBarOpen'}>
        <div className='sidebarLogo'>
          {collapsed?<img src={logoSingle} alt=""/>:<img src={logo} alt=""/>}
        </div>
        <div className='menuScrollDiv scrollB'>
        <Menu
            defaultSelectedKeys={['Client Review']}
            defaultOpenKeys={['Client Review']}
            mode='inline'
            // inlineCollapsed={collapsed}
            inlineIndent={12}
            style={{backgroundColor:'#fff',height:'100%',color:'#000'}}
            onSelect={menuItemSelect} 
          >
        <SubMenu key="Users Management" icon={<MDBIcon fas icon="users" />} title="Users Management" >
          <Menu.Item key="Client Review"  icon={<CaretRightFilled />} onClick={()=>handleTab('Client Review')}><span className='sidebarMenuTitle'>All Clients</span>
          </Menu.Item>
          <Menu.Item key="Client Test Review"  icon={<CaretRightFilled />} onClick={()=>handleTab('Client Test Review')}><span className='sidebarMenuTitle'>Test Clients</span>
          </Menu.Item>
          <Menu.Item key="otherReview"  icon={<CaretRightFilled />} onClick={()=>handleTab('otherReview')}><span className='sidebarMenuTitle'>Internal Users</span>
          </Menu.Item>
          <Menu.Item key="Lead Management"  icon={<CaretRightFilled />} onClick={()=>handleTab('Lead Management')}><span className='sidebarMenuTitle'>Lead Management</span>
            </Menu.Item>
        </SubMenu>

        <SubMenu key="Recommendation" icon={<MDBIcon fas icon="thumbs-up" />} title="Recommendation">
          <Menu.Item key="recomDashboard"  icon={<CaretRightFilled />} onClick={()=>handleTab('recomDashboard')}><span className='sidebarMenuTitle'>Dashboard</span>
          </Menu.Item>
          <Menu.Item key="recomSend"  icon={<CaretRightFilled />} onClick={()=>handleTab('recomSend')}><span className='sidebarMenuTitle'>Send</span>
          </Menu.Item>
          <Menu.Item key="recomReview"  icon={<CaretRightFilled />} onClick={()=>handleTab('recomReview')}><span className='sidebarMenuTitle'>Review & Edit</span>
          </Menu.Item>

          <SubMenu key="cco" icon={<MDBIcon fas icon="braille" />}title="CCO Strategy">
            <Menu.Item key="ccoSetup"  icon={<CaretRightFilled />} onClick={()=>handleTab('ccoSetup')}><span className='sidebarMenuTitle'>Setup</span>
            </Menu.Item>
            <Menu.Item key="ccoReview"  icon={<CaretRightFilled />} onClick={()=>handleTab('ccoReview')}><span className='sidebarMenuTitle'>Review & Edit</span>
            </Menu.Item>
          </SubMenu>
        </SubMenu>

        <SubMenu key="Reports" icon={<MDBIcon fas icon="file-alt" />} title="Reports">
          <Menu.Item key="mastertrans"  icon={<CaretRightFilled />} onClick={()=>handleTab('mastertrans')}><span className='sidebarMenuTitle'>Master Transaction</span>
          </Menu.Item>
          <SubMenu key="monthonmonthreport"  icon={<CaretRightFilled />} title='Month on Month Report'>
          <Menu.Item key="equityreport"  icon={<CaretRightFilled />} onClick={()=>handleTab('equityreport')}><span className='sidebarMenuTitle'>Equity report</span>
            </Menu.Item>
            <Menu.Item key="mfreport"  icon={<CaretRightFilled />} onClick={()=>handleTab('mfreport')}><span className='sidebarMenuTitle'>MF Report</span>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="allocation"  icon={<CaretRightFilled />} title='Allocation'>
          <Menu.Item key="allclientwise"  icon={<CaretRightFilled />} onClick={()=>handleTab('allclientwise')}><span className='sidebarMenuTitle'>Allclientwise</span>
            </Menu.Item>
            <Menu.Item key="schemewise"  icon={<CaretRightFilled />} onClick={()=>handleTab('schemewise')}><span className='sidebarMenuTitle'>Schemewise</span>
            </Menu.Item>
            <Menu.Item key="classwise"  icon={<CaretRightFilled />} onClick={()=>handleTab('classwise')}><span className='sidebarMenuTitle'>Classwise</span>
            </Menu.Item>
            <Menu.Item key="client_wise"  icon={<CaretRightFilled />} onClick={()=>handleTab('client_wise')}><span className='sidebarMenuTitle'>ClientWise</span>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="totalmonthend"  icon={<CaretRightFilled />} onClick={()=>handleTab('totalmonthend')}><span className='sidebarMenuTitle'>Monthend Report</span>
          </Menu.Item>
          
          <Menu.Item key="emailReportUpload"  icon={<CaretRightFilled />} onClick={()=>handleTab('emailReportUpload')}><span className='sidebarMenuTitle'>Email Report</span>
          </Menu.Item>
          <Menu.Item key="hoqreport"  icon={<CaretRightFilled />} onClick={()=>handleTab('hoqreport')}><span className='sidebarMenuTitle'>Holdings and Order Quantity Report</span>
          </Menu.Item>
        </SubMenu>

        <SubMenu key='Mastertransaction' icon={<MDBIcon fas icon="exchange-alt" />} title='Admin functions'>
          <SubMenu key="Docs Upload" icon={<MDBIcon fas icon="file-upload" />} title="Docs Upload"> 
            <Menu.Item key="Docs Upload" icon={<CaretRightFilled />} onClick={()=>handleTab('docsUpload')}><span className='sidebarMenuTitle'>zerodha Upload</span>
            </Menu.Item>
            <Menu.Item key="Networth Upload" icon={<CaretRightFilled />} onClick={()=>handleTab('networthUpload')}><span className='sidebarMenuTitle'>Networth Upload</span>
            </Menu.Item>
            <Menu.Item key="Upload" icon={<CaretRightFilled />} onClick={()=>handleTab('fileUpload')}><span className='sidebarMenuTitle'>Upload</span>
            </Menu.Item>
            <Menu.Item key="Download" icon={<CaretRightFilled />} onClick={()=>handleTab('fileDownload')}><span className='sidebarMenuTitle'>Download</span>
            </Menu.Item> 
          </SubMenu> 
          <SubMenu key="Control" icon={<MDBIcon fas icon="desktop" />} title="Control Panel"> 
            <Menu.Item key="Master Stock List"  icon={<CaretRightFilled />} onClick={()=>handleTab('masterstocklist')}><span className='sidebarMenuTitle'>Master Stock List</span>
            </Menu.Item>
            <Menu.Item key="Ria"  icon={<CaretRightFilled />} onClick={()=>handleTab('ria')}><span className='sidebarMenuTitle'>Ria Percentage</span>
            </Menu.Item>
            <Menu.Item key="Review Download"  icon={<CaretRightFilled />} onClick={()=>handleTab('reviewdownload')}><span className='sidebarMenuTitle'>Review Download</span>
            </Menu.Item>
            <Menu.Item key="Gains"  icon={<CaretRightFilled />} onClick={()=>handleTab('gains')}><span className='sidebarMenuTitle'>Gains</span>
            </Menu.Item>
            <Menu.Item key="Regulatory"  icon={<CaretRightFilled />} onClick={()=>handleTab('regulatory')}><span className='sidebarMenuTitle'>Regulatory Disclosure</span>
            </Menu.Item>
            <Menu.Item key="assetIrr"  icon={<CaretRightFilled />} onClick={()=>handleTab('assetIrr')}><span className='sidebarMenuTitle'>Asset Growth Rate</span>
            </Menu.Item>
            <Menu.Item key="incomeRate"  icon={<CaretRightFilled />} onClick={()=>handleTab('incomeRate')}><span className='sidebarMenuTitle'>Income Rate</span>
            </Menu.Item>
            <Menu.Item key="Asset class"  icon={<CaretRightFilled />} onClick={()=>handleTab('Asset class')}><span className='sidebarMenuTitle'>Asset class List</span>
            </Menu.Item>
            <Menu.Item key="successionReadiness"  icon={<CaretRightFilled />} onClick={()=>handleTab('successionReadiness')}><span className='sidebarMenuTitle'>Succession Readiness</span>
            </Menu.Item>
            <Menu.Item key="comment"  icon={<CaretRightFilled />} onClick={()=>handleTab('comment')}><span className='sidebarMenuTitle'>Comment</span>
            </Menu.Item>
          </SubMenu>
        </SubMenu>

        <SubMenu key="UserCreation" icon={<MDBIcon fas icon="user-plus" />}title="User Creation">
          <Menu.Item key="Create Admin"  icon={<CaretRightFilled />} onClick={()=>handleTab('userCreation')}><span className='sidebarMenuTitle'>Create Users</span>
          </Menu.Item>
          <Menu.Item key="Screen Management"  icon={<CaretRightFilled />} onClick={()=>handleTab('screenManagement')}><span className='sidebarMenuTitle'>Screen Management</span>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="Social" icon={<MDBIcon fas icon="share-alt-square" />} title="Social Activity">
          <Menu.Item key="blog"  icon={<CaretRightFilled />} onClick={()=>handleTab('blog')}><span className='sidebarMenuTitle'>Blog</span>
          </Menu.Item>
          <Menu.Item key="vedio"  icon={<CaretRightFilled />} onClick={()=>handleTab('vedio')}><span className='sidebarMenuTitle'>Video</span>
          </Menu.Item>
          <Menu.Item key="testimonial"  icon={<CaretRightFilled />} onClick={()=>handleTab('testimonial')}><span className='sidebarMenuTitle'>Testimonial</span>
          </Menu.Item>
          <Menu.Item key="newsletter"  icon={<CaretRightFilled />} onClick={()=>handleTab('newsletter')}><span className='sidebarMenuTitle'>Newsletter</span>
          </Menu.Item>
          <Menu.Item key="newsletterSubscription"  icon={<CaretRightFilled />} onClick={()=>handleTab('newsletterSubscription')}><span className='sidebarMenuTitle'>Newsletter Subscription</span>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="Billing" icon={<MDBIcon fas icon="file-invoice-dollar" />} title="Billing Section">
        <Menu.Item key="Billing Details" icon={<CaretRightFilled />} onClick={()=>handleTab('billingDetails')}><span className='sidebarMenuTitle'>Billing Details</span>
        </Menu.Item>
        <Menu.Item key="Billing History" icon={<CaretRightFilled />} onClick={()=>handleTab('billingHistory')}><span className='sidebarMenuTitle'>Billing History</span>
        </Menu.Item>
        </SubMenu>

        <SubMenu key="CRM" icon={<MDBIcon fas icon="users-cog" />} title="CRM">
        <Menu.Item key="crmConfig"  icon={<CaretRightFilled />} onClick={()=>handleTab('crmConfig')}><span className='sidebarMenuTitle'>CRM Configuration</span>
          </Menu.Item>
          <Menu.Item key="crmStatus"  icon={<CaretRightFilled />} onClick={()=>handleTab('crmStatus')}><span className='sidebarMenuTitle'>CRM Status</span>
          </Menu.Item>
        </SubMenu>
        </Menu>
        </div>

         <div className='sidebarToggleButton'>
            {collapsed?
          <MDBIcon icon="chevron-circle-right" className='sideToggBtnI'  onClick={toggleCollapsed}/>:
            <><MDBIcon icon="chevron-circle-left" className='sideToggBtnI' onClick={toggleCollapsed}/> 
            <span>Collapse</span></>}
          </div>
      </div>

      <div class={collapsed?'dashboardContentAreaClose':'dashboardContentAreaOpen'}>
        {/* ------------------------Top Bar--------------------------- */}
        <div className='modalHeadBar' style={{padding:'7px'}}>
          <Row align='middle'>
            <Col span={8}>
            <h5 className='dashboardPageTitle'>{menuKey}</h5>
                <Breadcrumb separator=">">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>{(subMenuKey.substring(0,10))}...</Breadcrumb.Item>
                <Breadcrumb.Item>{menuKey}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={10}>
              {sideTab['Lead Management']?
              <div className='modalHeadBarTab'>
                <Tabs defaultActiveKey={defaultTab} onChange={onChange} size='small' centered={false}>
                  <TabPane tab='All Leads' key='All Leads'></TabPane>
                  <TabPane tab='Prospect' key='Prospect'></TabPane>
                  <TabPane tab='Dead Lead' key='Dead Lead'></TabPane>
                </Tabs>
              </div>:null}
              {sideTab['Asset class']?
              <div className='modalHeadBarTab'>
                <Tabs defaultActiveKey={defaultTab} onChange={onChangeTavs} size='small' centered={false}>
                <TabPane tab='Stock List' key='stock_list'></TabPane>
                <TabPane tab='Equity' key='equity'></TabPane>
                <TabPane tab='Index Fund' key='index_fund'></TabPane>
                <TabPane tab='ISIN for Equity' key='isin_equity'></TabPane>
                <TabPane tab='NGEN' key='ngen'></TabPane>
                </Tabs>
              </div>:null}
            </Col>
            <Col span={6}>
              <Dropdown overlay={setting} placement="bottomCenter" trigger={['click']}>
                <div style={{cursor:'pointer'}}>
                  <Avatar src={User} />
                  <span className='salutationName'>{localStorage.email}</span>
                  <MDBIcon fas icon="angle-down" className='salutationIcon'/>
                </div>
              </Dropdown>
            </Col>
          </Row>
        </div>
  {/* ------------------------Top Bar end--------------------------- */}
        <div className='PageArea scrollB modalBodyBar'>
                {sideTab['Client Review']?<ClientReveiw testClient={0}/>:null}
                {sideTab['Client Test Review']?<ClientReveiw testClient={1}/>:null}
                {sideTab.otherReview?<OthersUserReview/>:null}
                {sideTab['Lead Management']?<WebsiteLead tabs={tabs}/>:null}
                {sideTab.recomDashboard?<RecommendationDashboard/>:null}
                {sideTab.recomSend?<div>
                    {Object.keys(instrument).length>0?<SendRecommendation instrument={instrument}/>:
                        <div className='loader-div' style={{textAlign:'center'}}>
                            <Loader/>
                        </div>
                    }  
                </div>:null}
                {sideTab.recomReview?<ReviewRecommendation/>:null}
                {sideTab.totalmonthend?<MonthEndReport/>:null}
                {sideTab.emailReportUpload?<EmailReportUpload/>:null}
                {sideTab.hoqreport?<Hoqreport/>:null}
                {sideTab.monthonmonthreport?<Monthonmonthreport/>:null}
                {sideTab.clienttotalmonthend?<FullMonthEndReport/>:null}
                {sideTab.blog?<Blog/>:null}
                {sideTab.vedio?<Vedio/>:null}
                {sideTab.newsletter?<Newsletter/>:null}
                {sideTab.newsletterSubscription?<NewsletterSubscription/>:null}
                {sideTab.comment?<Comment/>:null}
                {sideTab.successionReadiness?<SuccessionReadiness/>:null}
                {sideTab.ria?<RiaPercentage/>:null}
                {sideTab.reviewdownload?<ReviewDownload/>:null}
                {sideTab.gains?<Gains/>:null}
                {sideTab.masterstocklist?<MasterStockList/>:null}
                {sideTab['Asset class']?<AssetClassList tavs={tavs}/>:null}
                {sideTab.mastertrans?<MasterTran/>:null}
                {sideTab.allocation?<Allocation/>:null}
                {sideTab.regulatory?<Regulatory/>:null}
                {sideTab.allclientwise?<Allclientwise/>:null}
                {sideTab.schemewise?<Schemewise/>:null}
                {sideTab.classwise?<Classwise/>:null}
                {sideTab.client_wise?<ClientWise/>:null}
                {sideTab.equityreport?<Momequityreport/>:null}
                {sideTab.mfreport?<Mommfreport/>:null}
                {sideTab.testimonial?<AdminTestimonial/>:null}
                {sideTab.assetIrr?<AssetIrr/>:null}
                {sideTab.incomeRate?<Incomerate/>:null}
                {sideTab.userCreation?<UserCreation/>:null}
                {sideTab.screenManagement?<ScreenManagement/>:null}
                {sideTab.docsUpload?<DocsUpload/>:null}
                {sideTab.networthUpload?<NetworthUpload/>:null}
                {sideTab.fileUpload?<FileUploads/>:null}
                {sideTab.fileDownload?<FilesDownload/>:null}
                {sideTab.ccoSetup?<Setup/>:null}
                {sideTab.ccoReview?<Review/>:null}
                {sideTab.billingDetails?<BillingDetails/>:null}
                {sideTab.billingHistory?<BillingHistory/>:null}
                {sideTab.crmConfig?<CrmConfig/>:null}
                {sideTab.crmStatus?<CrmStatus/>:null}
                

                
        </div>
    </div> 
</div>
  )
}

export default React.memo(NewMainPage)
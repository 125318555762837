import React,{useState,useEffect} from 'react'
import AllLeads from './AllLeads'
import DeadLeads from './DeaLeads'
import Prospect from './Prospect'

const WebsiteLead = (props) => {
    return(
        <>
          {props.tabs==='All Leads'?<AllLeads/>:null}
          {props.tabs==='Prospect'?<Prospect/>:null}
          {props.tabs==='Dead Lead'?<DeadLeads/>:null}
        </>
  )
}

export default WebsiteLead
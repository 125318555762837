// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cashflowCard {
  width: 100%;
  background: transparent;
  border: none;
  overflow: hidden;
  padding: 5px 10px;
}

.cashflowGraphHead {
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
}

.tableTitle {
  font-weight: bold;
  font-size: 15px;
  margin: 20px 0px 8px 10px;
}

.casflowPieCard {
  width: 100%;
  height: 50vh;
  background: white;
  border: 1px solid #D7DBDD;
  border-radius: 5px;
  overflow: hidden;
}

.cashflowChartArea {
  height: 44vh;
  padding: 5px;
}

.ant-slider-rail {
  background-color: #ffecdb !important;
  height: 6px !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/Dashboard/cashflow/tabs/Tabs.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AACA;EACI,eAAA;EACA,gBAAA;EACA,aAAA;AAEJ;;AAAA;EACI,iBAAA;EACA,eAAA;EACA,yBAAA;AAGJ;;AAAA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AAGJ;;AADA;EACI,YAAA;EACA,YAAA;AAIJ;;AAFA;EACI,oCAAA;EACA,sBAAA;AAKJ","sourcesContent":[".cashflowCard{\n    width: 100%;\n    background: transparent;\n    border: none;\n    overflow: hidden;\n    padding: 5px 10px;\n}\n.cashflowGraphHead{\n    font-size: 15px;\n    font-weight: 500;\n    padding: 10px;\n}\n.tableTitle{\n    font-weight: bold;\n    font-size: 15px;\n    margin: 20px 0px 8px 10px;\n}\n\n.casflowPieCard{\n    width: 100%;\n    height: 50vh;\n    background: white;\n    border: 1px solid #D7DBDD;\n    border-radius: 5px;\n    overflow: hidden;\n}\n.cashflowChartArea{\n    height: 44vh;\n    padding: 5px;\n}\n.ant-slider-rail{\n    background-color: #ffecdb !important;\n    height: 6px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState,useEffect} from 'react'
// import './Reporting.scss'
import {Table,Popover} from 'antd';
import { CSVLink} from "react-csv";
// import {holding_report} from './ReportsApi'

const Momequityreporttable = (props) => {
    const [Tabdata, setTabData] = useState([]);
    const [exdata, setExData] = useState([]);
  
    const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        minimumFractionDigits:0
    }).format(value);
  

    // const content = (
    //   <p>Missing Transaction</p>
    // );
    
 

    const ExpandedRowRender = (props) => {
        const columns = [
          { title: 'Month', dataIndex: 'month',width:'30%' },
          { title: 'Invested Value',width:'35%',dataIndex: 'invested_amount',render: total => { return numberFormat(total)}},
          { title: 'Current value',width:'35%',dataIndex: 'current_amount',render: total => { return numberFormat(total)}},
    
        ];
      
        const dataEx = [];
  
        if(props.years){
          Object.keys(props.years).map((val)=>{
            // console.log(val);
            dataEx.push({
              key: val,
              month: val,
              invested_amount:props.years[val].invested_amount,
              current_amount: props.years[val].current_amount,
              description2: <ExpandedRowRender2 month={props.years[val].funds}/>
            })
          })
        }    
      
      return <Table
        columns={columns} dataSource={dataEx}
        expandable={{ expandRowByClick:true,
          expandedRowRender: record =><>{record.description2}</>

           }}
        pagination={false}   size='small'  
        rowClassName="editable-row"
        bordered/>;
    };

    const ExpandedRowRender2 = (props) => {
      const columns = [
        { title: 'Name', dataIndex: 'scheme',width:'30%' },
        { title: 'Invested Value',width:'35%',dataIndex: 'invested_amount',render: total => { return numberFormat(total)}},
        { title: 'Current value',width:'35%',dataIndex: 'current_amount',render: total => { return numberFormat(total)}},
  
      ];
    
      const dataEx2 = [];

      if(props.month){
        Object.keys(props.month).map((val)=>{
          console.log(props.month);
          dataEx2.push({
            key2: val,
            scheme: val,
            invested_amount:props.month[val].fund_invested_amount,
            current_amount: props.month[val].fund_current_amount, 
          })
        })
      }    
    
    return <Table
      columns={columns} dataSource={dataEx2}
      pagination={false}   size='small'  
      rowClassName="editable-row"
      bordered/>;
  };


    useEffect(()=>{
        let newdata = []
        Object.keys(props.dataMain).map((val)=>{
            // console.log(val);
          newdata.push({ 
            key: val,
            years:val,
            description: <ExpandedRowRender years={props.dataMain[val]}/>,
            })
        })
        setTabData(newdata)
      
    },[props.dataMain])
    // console.log(props.dataMain);

    const columns = [
      {title: 'Years',dataIndex: 'years'},
      
    ];

 

 
 
  return (
    <div>
      <Table
        columns={columns}
        pagination={false}
        expandable={{ expandRowByClick:true,
          expandedRowRender: record =><>{record.description}</>

           }}
        rowClassName="antTable"
        dataSource={Tabdata}
        size='small'
        bordered
    />
    </div>
  )
}

export default Momequityreporttable
import React,{useState,useEffect} from 'react';
import { Table,Input,InputNumber,Popconfirm,Form,Typography,Button,Space,message,Select} from 'antd';
import {covered_call_options_get,covered_call_options_put,covered_call_options_delete} from '../Api'
import Highlighter from 'react-highlight-words';
import { SearchOutlined,DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined} from '@ant-design/icons';
import Loader from '../../../shared/Loader';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  disable,
  ...restProps
}) => {
  

  const inputNode = <InputNumber/>
                                              
  return (
    <td {...restProps}>
      {editing? (
        <>
        {dataIndex==='limit_price'?
        <Form.Item name={dataIndex}>{inputNode}</Form.Item>:

        <Form.Item
          name={dataIndex}
          rules={[{required: true,message: `Please Input ${title}!`}]}
        >
            {inputNode}
        </Form.Item>}
        </>
      ) : (
        children
      )}
    </td>
  );
};

const Review = () => {
  const[searchText,setSearchText] = useState('')
  const[searchedColumn,setSearchedColumn] = useState('')
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [editRecommendation,setEditRecommendation] = useState({}) 
  const [loading,setLoading] = useState(false)

  let searchInput = ''
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const getData = async()=>{
  setLoading(true)
  let newdata = []
  const input = {access_token:localStorage.token}
  const res = await covered_call_options_get(input)
  console.log('res',res);
  try{
    if (res && res.status==='successfull'){ 
      Object.keys(res.result).map((val)=>{
        newdata.push({ 
        key: val,
        date: res.result[val].date,
        customer_id:res.result[val].customer_id,
        equity_trading_symbol: res.result[val].equity_trading_symbol,
        equity_quantity: res.result[val].equity_quantity,
        call_options_tradingsymbol:res.result[val].call_options_tradingsymbol,
        call_options_quantity:res.result[val].call_options_quantity,
        total_invested_value:res.result[val].total_invested_value
      })
      })
      setData(newdata)
      setLoading(false)
    }
    else{setLoading(false)}
  }catch{setLoading(false)}
}
useEffect(()=>{
  getData()
},[])

let getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
          }}
        >
          Filter
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
  render: text =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0])
  setSearchedColumn(dataIndex)
};

const  handleReset = clearFilters => {
  clearFilters();
  setSearchText('')
};

  const save = async (key) => {
    setLoading(true)
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        setEditRecommendation(item)
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
        const input = {
          access_token:localStorage.token,
          editCco:newData[index],
        }
        console.log(input)
        covered_call_options_put(input).then(res => {
          console.log(res);
          try{
            if (res && res.status==='successfull'){
              setLoading(false)
              message.success(res.message)
              covered_call_options_get();
            }
            else{
              setLoading(false)
              message.error(res.message)
            }
          }catch{
            setLoading(false)
            message.error('Something went Wrong')
          }
        })
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
        setLoading(false)
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      setLoading(false)
    }
  };

  const onDelete = async(record)=>{
    setLoading(true)
  const input = {
    access_token:localStorage.token,
    ccoRow:record
  }
  console.log(input)
  covered_call_options_delete(input).then(res => {
  console.log(res);
  try{
    if (res && res.status==='successfull'){
      setLoading(false)
      message.success(res.message)
      covered_call_options_get();
    }
    else{
      setLoading(false)
      message.error(res.message)
    }
  }catch{
    setLoading(false)
    message.error('Something went Wrong')
  }
}) 
}


  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      ...getColumnSearchProps('date'),
    },
    {
      title: 'Client ID',
      dataIndex: 'customer_id',
      width: 80,
      ...getColumnSearchProps('customer_id'),
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      width: 150,
      ...getColumnSearchProps('clientName'),
    },
    {
      title: 'EQ Trading Symbol',
      dataIndex: 'equity_trading_symbol',
      width: 130,
      ...getColumnSearchProps('equity_trading_symbol'),
    },
    {
      title: 'EQ Quantity',
      dataIndex: 'equity_quantity',
      width: 150,
      editable: true,
      ...getColumnSearchProps('equity_quantity'),
    },
    {
      title: 'CE Symbol',
      dataIndex: 'call_options_tradingsymbol',
      width: 200,
      ...getColumnSearchProps('call_options_tradingsymbol'),
    },
    {
      title: 'CE Quantity',
      dataIndex: 'call_options_quantity',
      width: 120,
      editable: true,
      ...getColumnSearchProps('call_options_quantity'),
    },
    {
      title: 'Total Invested Value',
      dataIndex: 'total_invested_value',
      width: 120,
      ...getColumnSearchProps('total_invested_value'),
    },
    
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: 120,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() =>save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <span className='outlineSaveButton'>
                <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
              </span>
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
            </Popconfirm>
          </span>
        ) : (
          <span>
          <Typography.Link disabled={editingKey !== ''}
            onClick={() =>edit(record)}>
            <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
          </Typography.Link>
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
           <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
          </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'quantity' || col.dataIndex === 'limit_price' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        disable: record.order_type === 'MARKET'? true:false
      }),
    };
  });

  return (
    <div style={{fontSize:'10px'}}>
      {loading?<Loader/>:null}
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          // bordered={true}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            pageSize: 10,
            onChange: cancel,
            position:['bottomCenter']
          }}
          size='small'
          scroll={{y: 420 }}
          sticky={true}
        />
      </Form>
    </div>
  );
};

export default Review
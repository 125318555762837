import React, { useState,useEffect} from 'react'
import './Tabs.scss'
import {MDBIcon} from 'mdbreact'
import IncomeBreakup from './incomeBreakup/IncomeBreakup'
import ExpenseBreakup from './expenseBreakup/ExpenseBreakup'
import RegularBreakup from './regular/RegularBreakup'
import CashflowPie from './cashflowPie/CashflowPie'
import CashFlowProjection from './cashFlowProjection/CashFlowProjection'
import {cashflow_growth_chart} from '../Api'
import AddComment from '../../comment/AddComment'

const Tabs = (props) => {
  const [cashFlowData, setCashFlowData] = useState({});
  const [tab,setTab] = useState({incomeBreakup:true,expenseBreakup:false,regularBreakup:false,
                                    lifetime:false,cashflowPie:false})
  const [isModalVisible, setIsModalVisible] = useState(false);

    const fetchCashFlow = async () =>{
      const input = {access_token:localStorage.token,id:props.id}
      const res = await cashflow_growth_chart(input)
      .catch((err) => {console.log("Err: ", err)});
      console.log('cashflow',res,input);
      try{
        if (res.status==='successfull'){
          setCashFlowData(res.result)
        }else{}
      }catch{}
    }
    useEffect(()=>{
      fetchCashFlow()
    },[])

    const handleTab=(flag)=>{
        let dict = {...tab}
        Object.keys(dict).map(item => {
            if(item===flag){dict[item] = true
            }else{dict[item] = false}
            });
            setTab(dict)
    }
    const handleModal = () => {setIsModalVisible(!isModalVisible);};
  return (
    <div className='tableContainer'>
         <div className='tableTabContainer'>
            <div className={tab.incomeBreakup?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('incomeBreakup')}>Income Breakup</div>
            <div className={tab.expenseBreakup?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('expenseBreakup')}>Expense Breakup</div>
            <div className={tab.regularBreakup?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('regularBreakup')}>Regular Investment Breakup</div>
            <div className={tab.lifetime?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('lifetime')}>Lifetime Cashflow Projection</div>
            <div className={tab.cashflowPie?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('cashflowPie')}>Cashflow Pie With Age Meter</div>
            <div className='PagesCommentIconDiv'>
              <MDBIcon className='PagesCommentIcon' icon="comment-dots" onClick={handleModal}/>
            </div>
        </div>
        {tab.incomeBreakup?<IncomeBreakup tableData={props.tableData} id={props.id}/>:null}
        {tab.expenseBreakup?<ExpenseBreakup id={props.id}/>:null}
        {tab.regularBreakup?<RegularBreakup id={props.id}/>:null}
        {tab.lifetime?<CashFlowProjection cashFlowData={cashFlowData} id={props.id}/>:null}
        {tab.cashflowPie?<CashflowPie id={props.id}/>:null}

        {isModalVisible? <AddComment handleModal={handleModal}/>:null}
    </div>
  )
}

export default React.memo(Tabs)
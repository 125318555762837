import React, { useState } from 'react';
import {zerodha_loginurl} from '../Api'
import {MailFilled, WhatsAppOutlined} from '@ant-design/icons';
import {Row,Col,Tabs} from 'antd';

const SendLink = (props) => {
    const[link,setLink] = useState()
    const{cusName,customer_id,cusEmail, close, tabName} = props
    const[share,setShare] = useState(false)

    const generateLink=()=>{
        const input = {
          access_token:localStorage.token,
          email:props.cusEmail
        }
        console.log(input);
        zerodha_loginurl(input).then(res => {
            console.log(res);
            try{
                if (res.status==='successfull') {
                   setLink(res.result)
                }
                else{}
            }catch{}
        })
      }
      console.log(props.cusEmail)

      // const SendAuthLink=()=>{
      //   const input = {
      //     access_token:localStorage.token,
      //     email:props.cusEmail
      //   }
      //   console.log(input);
      //   zerodha_loginurl(input).then(res => {
      //       console.log(res);
      //       try{
      //           if (res.status==='successfull') {
                   
      //           }
      //           else{}
      //       }catch{}
      //   })
      // }
      console.log(props.cusEmail)

      const shareButton = () => {
        setShare(true)
      }

      

  return (
      <div>
        <div className='modalHeadBar'>
        <Row align='middle'>
          <Col span={6}>
            <div className='modalHeadBarTitle'>{tabName}</div>
          </Col>
          <Col span={12}>
            <div className='modalHeadBarTab'>
              
            </div>
          </Col>
          <Col span={5}>
            <div className='modalHeadBarTitle'>{cusName}</div>
            <p className='modalHeadBarP'>{cusEmail}</p>
          </Col>
          <Col span={1}>
            <div className='TableActionButtonClose' onClick={close}>Close</div>
          </Col>
        </Row>
      </div>

      <div className='modalBodyBar'>
      <div>
            <button type="button" class="btn btn-secondary" onClick={generateLink}>Generate Link</button> 
            <span className='authZeroLink'>{link}</span>
        </div>
            
      </div>
        
      </div>
  );
};

export default SendLink;

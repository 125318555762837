import axios from 'axios'

export const zerodha_request_token_get = input => {
  return axios
  .get('/alpha_user_db/zerodha_request_token',
  {
     params :{
      token: input.token,
     }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const mf_recommendation_list = input => {
  return axios
      .post('/alpha_user_db/mf_recommendation_list', {
          token: input.token,
          zerodha_id:input.zerodha_id
      })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const zerodha_request_token_post = input => {
  return axios
  .get('/alpha_admin/zerodha_authentication',
  {
    params :{
      request_token:input.request_token,
      id:input.id
    }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
import React, { useState,useEffect} from 'react'
import './Reporting.scss'
import {Table} from 'antd';
import {customer_mf_details} from './ReportsApi'
import Loader from '../../../shared/Loader';

const SipReport = (props) => {
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState(false)

  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
      minimumFractionDigits:0
  }).format(value);

  useEffect(()=>{
    setLoading(true)
    let newdata = []
    const input = {
        access_token:localStorage.token,
        type:'sips',
        id:props.id
    }
    customer_mf_details(input).then(res => {
        console.log('sips',res);
        Object.keys(res.result).map((val)=>{
            newdata.push({ 
            key: val,
            completed_instalments:res.result[val].completed_instalments,
            created: res.result[val].created,
            frequency: res.result[val].frequency,
            fund: res.result[val].fund,
            instalment_amount: res.result[val].instalment_amount,
            instalment_day: res.result[val].instalment_day,
            last_instalment: res.result[val].last_instalment,
            next_instalment: res.result[val].next_instalment,
            pending_instalments: res.result[val].pending_instalments,
            status: res.result[val].status,
            name:res.result[val].name
            })
        })
        setLoading(false)
        setData(newdata)
      })
  },[])

  const column = [   
    {title: 'Name',dataIndex: 'name',width: 60},
    {title: 'Created Date',dataIndex: 'created',width: 80},
    {title: 'Fund',dataIndex: 'fund',width: 100,ellipsis:true},
    {title: 'Instalment Amount',align:'right',dataIndex: 'instalment_amount',width: 90},
    {title: 'Instalment Day',dataIndex: 'instalment_day',width: 100},
    {title: 'Completed Instalments',dataIndex: 'completed_instalments',width: 80},
    {title: 'Last Instalment',dataIndex: 'last_instalment',width: 70},
    {title: 'Next Instalment',dataIndex: 'next_instalment',width: 70},
    {title: 'Pending Instalments',align:'right',dataIndex: 'pending_instalments',width: 85},
    {title: 'Status',dataIndex: 'status',width: 70},
  ]

  return (
    <>   
    {loading?<Loader/>:null}
      <Table rowClassName="antTable" columns={column} dataSource={data} size='small'
          // pagination={{pageSize: 5,position:['bottomCenter']}} 
          scroll={{y: 300}}
          pagination={false} 
          sticky={true}
          exportableProps={{ fileName: `sip_report_${localStorage.email}`}}

          summary={(pageData) => {
            let Total = 0;
            pageData.forEach(({instalment_amount}) => {
              Total = Total+instalment_amount;
            });

            return (
              <>
                <Table.Summary.Row className='totalRow'>
                  <Table.Summary.Cell className='tableTotal'>Total</Table.Summary.Cell>
                  <Table.Summary.Cell ></Table.Summary.Cell>
                  <Table.Summary.Cell ></Table.Summary.Cell>
                 
                     <Table.Summary.Cell className='tableTotal'><div style={{textAlign:'right'}}>{numberFormat(Total)}</div></Table.Summary.Cell>
                     <Table.Summary.Cell ></Table.Summary.Cell>
                     <Table.Summary.Cell ></Table.Summary.Cell>
                     <Table.Summary.Cell ></Table.Summary.Cell>
                     <Table.Summary.Cell ></Table.Summary.Cell>
                     <Table.Summary.Cell ></Table.Summary.Cell>
                     <Table.Summary.Cell ></Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
      />
</>
  )
}

export default SipReport
import React,{useState,useEffect} from 'react'
import {Table,Input,Space,Button} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {expense_breakup} from '../../Api'

const ExpenseBreakup = (props) => {

  const[searchText,setSearchText] = useState('')
  const[searchedColumn,setSearchedColumn] = useState('')
  const [data, setData] = useState([])

  const numberFormat=(value)=>{
    var val = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' L';
    }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
    return val;
  }
    
  const fetchData = async()=>{
    const arr = []
    const input = {access_token:localStorage.token,id:props.id}
    const res = await expense_breakup(input)
    .catch((err) => {console.log("Err: ", err)});
    console.log('expense',res,input);
    try{
      if (res.status==='successfull'){
         Object.entries(res.result).map((val)=>arr.push(val[1]))
      }else{}
    }catch{}
    setData(arr)
  }
  
  useEffect(()=>{
    fetchData()
  },[])

  let searchInput = ''

  let getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => {
              handleReset(clearFilters)
              confirm({ closeDropdown: false });
              // setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
              }} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    };
  const  handleReset = clearFilters => {
      clearFilters();
      setSearchText('')
    };

  const regularColumns = [
    {title: 'SR. NO',dataIndex: 's_no',width: 60, ...getColumnSearchProps('s_no')},
    {title: 'Expense Name',dataIndex: 'Expense_name',width: 150,...getColumnSearchProps('Expense_name')},
    // {title: 'AMOUNT',dataIndex: 'amount',width: 120,...getColumnSearchProps('amount')},
    {title: 'AMOUNT',dataIndex: 'amount',width: 120,...getColumnSearchProps('amount'),
        render: total => { return numberFormat(total)}},
    // {title: 'AMOUNT at Retirement',dataIndex: 'amount_at_retirment',width: 120,...getColumnSearchProps('amount_at_retirment')},
    // {title: 'AMOUNT at Post Age',dataIndex: 'amount_at_post_age',width: 120,...getColumnSearchProps('amount_at_post_age')},
    {title: 'AMOUNT at Retirement',dataIndex: 'amount_at_retirment',width: 120,...getColumnSearchProps('amount_at_retirment'),
        render: total => { return numberFormat(total)}},
    {title: 'AMOUNT at Post Age',dataIndex: 'amount_at_post_age',width: 120,...getColumnSearchProps('amount_at_post_age'),
        render: total => { return numberFormat(total)}},
    // {title: 'Continues Till',dataIndex: 'continues_till',width: 120,...getColumnSearchProps('continues_till')}
  ];
  return (
    <div>
        <p className='tableTitle'>Expense Breakup Summary</p>
        <div className='tableContainer'>
            <Table rowClassName="antTable" columns={regularColumns} dataSource={data} size='small'
              // pagination={{pageSize: 5,position:['bottomCenter']}} 
              scroll={{y: 200}}
              pagination={false} 
              sticky={false}
              summary={(pageData) => {
                console.log(pageData);
                let Total = 0;
                let Total1 = 0;
                let Total2= 0;
                pageData.forEach(({ amount,amount_at_retirment,amount_at_post_age}) => {
                  Total = Total+amount;
                  Total1 = Total1+parseInt(amount_at_retirment);
                  Total2 = Total2+parseInt(amount_at_post_age);
                });
  
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell ></Table.Summary.Cell>
                      <Table.Summary.Cell className='tableTotal'>Total</Table.Summary.Cell>
                      <Table.Summary.Cell className='tableTotal'>{numberFormat(Total)}</Table.Summary.Cell>
                      <Table.Summary.Cell className='tableTotal'>{numberFormat(Total1)}</Table.Summary.Cell>
                      <Table.Summary.Cell className='tableTotal'>{numberFormat(Total2)}</Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
              />
        </div>
    </div>
  )
}

export default React.memo(ExpenseBreakup)
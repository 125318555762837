import axios from 'axios'

export const send_email_admin = input => {
  return axios
      .post('/alpha_admin/send_email_admin', {
        customer_email:input.customer_email,
        subject:input.subject,
        body:input.body
      },
      {
        headers: {
          'Authorization': input.access_token
        }
      })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}

export const admin_zerodha_key_get = input => {
  return axios
      .get('/alpha_admin/admin_zerodha_details',
      {
          headers: {
            'Authorization': input.access_token
          },
          params:{
            id:input.customer_id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}

export const admin_zerodha_key_post = input => {
  return axios
      .post('/alpha_admin/admin_zerodha_details', {
          access_token:input.access_token,
          id:input.customer_id,
          zerodha_id:input.zerodha_id,
          publisher_api_key:input.publisher_api_key,
          family_member_id:input.family_member_id,
          account_type:input.account_type,
          email:input.email
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}

export const admin_zerodha_key_put = input => {
  return axios
      .put('/alpha_admin/admin_zerodha_details', {
          access_token:input.access_token,
          row:input.row
      },
      {
        headers: {
          'Authorization': input.access_token
        }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const admin_zerodha_key_delete = input => {
  return axios
      .delete('/alpha_admin/admin_zerodha_details',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            zerodha_id:input.zerodha_id,
            id:input.id
          }

        },)
      .then(response => {
          return response.data
      })
      .catch(err => {})
}

export const customer_margin_details = input => {
    return axios
        .get('/alpha_admin/customer_margin_details',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
                id:input.id,
                type: input.type,
                date:input.date,
                start_date:input.start_date,
                end_date:input.end_date
              }

          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  
export const zerodha_loginurl = input => {
  return axios
      .post('/alpha_admin/zerodha_loginurl', {
          access_token:input.access_token,
          email:input.email
      },
      {
        headers: {
          'Authorization': input.access_token
        }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const customer_mf_details = input => {
  return axios
      .get('/alpha_admin/customer_mf_details',
      {
          headers: {'Authorization': input.access_token},
          params: {
            id:input.id,
            type: input.type,
            date:input.date,
            start_date:input.start_date,
            end_date:input.end_date
          }
      })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const month_end_zerodha = input => {
  return axios
      .get('/alpha_admin/month_end_zerodha',
      {
          headers: {'Authorization': input.access_token},
          params: {
            id:input.id,
            type: input.type,
          }
      })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
  export const admin_review = input => {
    return axios
        .get('/alpha_admin/admin_review',
        {
            headers: {'Authorization': input.access_token}
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const admin_review_put = input => {
    return axios
        .put('/alpha_admin/admin_review', {
            access_token:input.access_token,
            editadmin:input.editadmin
        },
        {
          headers: {
            'Authorization': input.access_token
          }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_review_delete = input => {
    return axios
        .delete('/alpha_admin/admin_review',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id,
            }
  
          },)
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const date_post = input => {
    return axios
        .post('/alpha_admin/month_end_zerodha', {
            access_token:input.access_token,
            id:input.id,
            datess:input.datess,
            
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const covered_call_options_reporting = input => {
    return axios
        .get('/alpha_admin/covered_call_options_reporting',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
                id:input.id,
                type: input.type
              }

          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_stocks_list = input => {
    return axios
        .get('/alpha_admin/admin_stocks_list',
        {
            headers: {'Authorization': input.access_token}
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_stocks_list_post = input => {
    return axios
        .post('/alpha_admin/admin_stocks_list', {
            access_token:input.access_token,
            id:input.id,
            Asset_category:input.Asset_category,
            Asset_type:input.Asset_type,
            Name:input.Name,
            exchange:input.exchange,
            instrumentsymbol:input.instrumentsymbol
          },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_stocks_list_delete = input => {
    return axios
        .delete('/alpha_admin/admin_stocks_list',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              instrumentsymbol:input.instrumentsymbol,
            }

          },)
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_stocks_list_put = input => {
    return axios
        .put('/alpha_admin/ria_percentage', {
            access_token:input.access_token,
            row:input.row,
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const master_transcation = input => { 
    return axios
        .get('/alpha_admin/master_transcation',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
                start_date:input.start_date,
                end_date:input.end_date,
                customer_id:input.customer_id,
                flag:input.flag
              }

          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const add_admin_notes = input => { 
    return axios
        .post('/alpha_admin/add_admin_notes', {
            comment:input.comment,
            id:input.id,
            email:input.email,
            notes_reference:input.notes_reference
        },
        {
          headers: {
            'Authorization': input.access_token
          }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const getNotes = input => {
    return axios
        .get('/alpha_admin/add_admin_notes',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id,
              notes_reference:input.notes_reference
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const notes_data_delete = input => {
    return axios
        .delete('/alpha_admin/add_admin_notes',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              key:input.key,
            }

          },)
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const notes_data_update = input => {
    return axios
        .put('/alpha_admin/add_admin_notes', {
            access_token:input.access_token,
            comment:input.comment,
            key:input.key,
            id:input.id
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const email_admin_notes = input => {
    return axios
        .post('/alpha_admin/email_admin_notes',{
            comment:input.comment,
            key:input.key,
            id:input.id,
            send_to:input.email,
            datetime:input.datetime,
            responding_datetime:input.responding_datetime,
            // frequency:input.frequency,
            // intervals:input.intervals
        },
        {
            headers: {
              'Authorization': input.access_token
            },
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const get_email_admin_notes = input => {
    return axios
        .get('/alpha_admin/email_admin_notes',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              notes_id:input.notes_id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const delete_email_admin_notes = input => {
    return axios
        .delete('/alpha_admin/email_admin_notes',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              key:input.key,
            }

          },)
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const directEqFile = input => {
    return axios
    .get('/alpha_admin/directEqFile',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
        
          // filename:input.filename
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
    }
    export const index_fund_file = input => {
      return axios
      .get('/alpha_admin/index_fund_file',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
          
            // filename:input.filename
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
      }
      export const isin_file = input => {
        return axios
        .get('/alpha_admin/isin_file',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
            
              // filename:input.filename
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
        }
     
        export const ngenFile = input => {
          return axios
          .get('/alpha_admin/ngenFile',
          {
              headers: {
                'Authorization': input.access_token
              },
              params: {
                extension:input.extension,
                flag:input.flag
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
          }
          export const review_sheet = input => {
            return axios
            .get('/alpha_admin/review_sheet',
            {
                headers: {
                  'Authorization': input.access_token
                },
                params: {
                id:input.id,
                start_date:input.start_date
                  // filename:input.filename
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
            }
            export const realized_and_unrealized_returns_sheet = input => {
              return axios
              .get('/alpha_admin/realized_and_unrealized_returns_sheet',
              {
                  headers: {
                    'Authorization': input.access_token
                  },
                  params: {
                  id:input.id,
                  start_date:input.start_date
                    // filename:input.filename
                  }
                })
              .then(response => {
                  return response.data
              })
              .catch(err => {})
              }
              export const masters_stocks_detail_get = input => {
                return axios
                    .get('/alpha_admin/masters_stocks_detail',
                    {
                        headers: {'Authorization': input.access_token}
                    })
                    .then(response => {
                        return response.data
                    })
                    .catch(err => {})
              }
              export const masters_stocks_detail_delete = input => {
                return axios
                    .delete('/alpha_admin/masters_stocks_detail',
                    {
                        headers: {
                          'Authorization': input.access_token
                        },
                        params: {
                          key:input.row,
                          scheme_isin:input.scheme_isin
                        }
            
                      },)
                    .then(response => {
                        return response.data
                    })
                    .catch(err => {})
              }
              export const masters_stocks_detail_post = input => {
                return axios
                    .post('/alpha_admin/masters_stocks_detail', {
                        access_token:input.access_token,
                        // id:input.id,
                        image:FormData.image,
                        scheme_name:input.scheme_name,
                        scheme_sector:input.scheme_sector,
                        scheme_isin:input.scheme_isin,
                        scheme_short_name:input.scheme_short_name
                      },
                    {
                        headers: {
                          'Authorization': input.access_token
                        }
                      })
                    .then(response => {
                        return response.data
                    })
                    .catch(err => {})
              }
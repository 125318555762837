import React,{useState,useEffect} from 'react'
import './Cashflow.scss'
import Income from '../../../Images/income.svg'
import Expense from '../../../Images/expense.svg'
import Surplus from '../../../Images/surplus.svg'
import Tabs from './tabs/Tabs'
import {income_breakup} from './Api'
import { Row, Col} from 'antd';

const Cashflow = (props) => {
    const [cardData, setCardData] = useState([]);
    const [tableData, setTableData] = useState({});

    const numberFormat=(value)=>{
        var val = Math.abs(value)
        if (val >= 10000000) {
          val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
          val = (val / 100000).toFixed(2) + ' L';
        }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
        return val;
      }

    const fetchCashFlow = async () =>{
        const input = {access_token:localStorage.token,id:props.id}
        console.log(input);
        const res = await income_breakup(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('cashflowTab',res);
        try{
          if (res.status==='successfull'){
            setCardData(res.result.Budget)
            setTableData(res.result.income_data)
          }else{}
        }catch{}
      }
      useEffect(()=>{
        fetchCashFlow()
      },[])

    const StatsCard = (props)=>{
        const {name,value} = props
        return(
        <>
            <div className='statisticCard'>
                <Row>
                    <Col span={10}>
                        <img src={name==='INCOME'?Income:name==='EXPENSE'?Expense:name===
                      'SURPLUS'?Surplus:Income} alt="" className='statisticCardImg'/>
                    </Col>
                    <Col span={14}>
                        <div className='statisticCardValue'>
                            <small>{name}</small>
                            <div> <b>{numberFormat(value)}</b> </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
        )
    }

  return (
    <>
        <div className='pageSubhead_1'>Monthly Cashflow Explorer</div>
        <Row gutter={[15, 16]}>
            {Object.keys(cardData).map((val)=>{
                return(
                    <Col span={5}>
                        <StatsCard name={val} value={cardData[val]}/>
                    </Col>
                )
            })}
        </Row>
        {Object.keys(tableData).length>0?<Tabs tableData={tableData} id={props.id}/>:null}

    </>
  )
}

export default React.memo(Cashflow)
import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import './Wg.scss'

const AreaGraph = (props) => {
  const [line1,setLine1] = useState([])
  const [line2,setLine2] = useState([])

  const numberFormat=(value)=>{
    var val = Math.abs(value)
    if (val >= 10000000) {
      val = Math.sign(value)*(val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = Math.sign(value)*(val / 100000).toFixed(2) + ' L';
    }else if(val >= 1000) val = Math.sign(value)*(val/1000).toFixed(2) + ' K';
    return   val;
  }

  useEffect(()=>{
    let arr = []
    let arr2 = []
    Object.keys(props.graphData).map((val)=>{
        arr.push({x:props.graphData[val].age,y:props.graphData[val].networth})
        arr2.push ({x:props.graphData[val].age,y:props.graphData[val].withdrawal})
    })
    setLine1(arr)
    setLine2(arr2)
  },[])

  const series= [
    {name: 'Net worth at the end of the year',data: line1}, 
    {name: 'Total Withdrawals',data: line2}
  ]
  const options= {
    chart: {
      type: 'area',
      toolbar: {show: false,}
  },
    dataLabels: {enabled: false},
    colors: ['#ade4ff','#fb963b'],
    fill:{
      type:'solid',
      opacity:1
    },
    xaxis: {
      type: 'category',
      tooltip: {
        enabled: false
      },
      tickAmount: 30,
      labels: {
        show: true,
        rotate: -0,
      }
    },
    yaxis: {
      // tickAmount: 4,
      floating: false,
      labels: {
        style: {colors: '#292929'},
        offsetY: -7,
        offsetX: 0,
        formatter: (value) => { return numberFormat(value) }
      },
    },
    plotOptions: {
      area: {
          fillTo: 'origin',
      }
  },
  stroke: {
    show: true,
    curve: 'straight',
    // lineCap: 'butt',
    width: 1,
    dashArray: 0,      
},
tooltip: {
  enabled: true,
  theme: false,
  
  style: {
    fontSize: '12px',
  }
}

  }

  return (
    <ReactApexChart options={options} series={series} type="area" height={'100%'}/>

  )
}

export default React.memo(AreaGraph)
import React, { PureComponent } from 'react'
import {save_address,get_address_details,update_address,country} from './Api'
import {MDBIcon} from 'mdbreact'
import './Compliance.scss'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../shared/Loader';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastMsg =(ids,msg,type)=>{
    toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
} 

export default class AddressDetails extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            current_line1:'',
            current_line2:'',
            current_line3:'',
            current_city:'',
            current_state:'',
            current_country:'India',
            current_pincode:'',
            permanent_line1:'',
            permanent_line2:'',
            permanent_line3:'',
            permanent_city:'',
            permanent_state:'',
            permanent_country:'India',
            permanent_pincode:'',
            loading:false,
            disabled:false,
            pin_valid:true,
            pin_valid2:true,
            error:false,
            success:false,
            warning:false,
            country_list:{},
            Permanent_add_flag:null,
            ria_edit:false,
            Ncheck:false,
            Ycheck:false
        }
    }
    componentWillMount(){
        const input = {
            access_token:localStorage.token,
            id:this.props.id
        }
        if(this.props.ria_form===1){
            this.setState({ria_edit:true})
        }
        if(this.props.address===1){
        get_address_details(input).then(res => {
            console.log("getres",res)
        try{
        if (res.status==='successfull') {
          this.setState(() => ({
            current_line1:res.result.house_no,
            current_line2:res.result.apartment,
            current_line3:res.result.area_locality,
            current_city:res.result.current_city,
            current_state:res.result.current_state,
            current_country:res.result.current_country,
            current_pincode:res.result.current_pincode,
            permanent_line1:res.result.permanent_house_no,
            permanent_line2:res.result.permanent_apartment,
            permanent_line3:res.result.permanent_area_locality,
            permanent_city:res.result.permanent_city,
            permanent_state:res.result.permanent_state,
            permanent_country:res.result.permanent_country,
            permanent_pincode:res.result.permanent_pincode,
            Permanent_add_flag:res.result.Permanent_add_flag,
            disabled:true,
            ria_edit:true
          }))
          if(res.result.Permanent_add_flag==='Y'){
              this.setState({Ycheck:true})
          }
          else{
            this.setState({Ncheck:true})
          }
      }
      else{toast.error(res.message)}
      }catch{
        this.setState({loading:false})
        toast.error('Something went wrong')
      }
      })
    }
    country(input).then(res => {
    console.log("get",res)
    try{
    if (res.status==='successfull') {
      this.setState(() => ({
         country_list:res.result
      }))
  }
  else{console.log(res)}
  }catch{
    this.setState({
        error:true,
        errormsg:"Something went wrong",
    })
  }
  })
    }
onChange=(e)=>{
    this.setState({ [e.target.name]: e.target.value })
    }
onChangePerm=(e)=>{
this.setState({ [e.target.name]: e.target.value })
}
onSubmitaddress=(e)=>{
    e.preventDefault();
    const ids = toast.loading("Please wait...",{toastId:'load'})
      const input = {
        access_token:localStorage.token,
        house_no:this.state.current_line1,
        apartment:this.state.current_line2,
        area_locality:this.state.current_line3,
        current_city:this.state.current_city,
        current_state:this.state.current_state,
        current_country:this.state.current_country,
        current_pincode:this.state.current_pincode,
        permanent_house_no:this.state.permanent_line1,
        permanent_apartment:this.state.permanent_line2,
        permanent_area_locality:this.state.permanent_line3,
        permanent_city:this.state.permanent_city,
        permanent_state:this.state.permanent_state,
        permanent_country:this.state.permanent_country,
        permanent_pincode:this.state.permanent_pincode,
        Permanent_add_flag:this.state.Permanent_add_flag,
        id:this.props.id
      }
      console.log("input",input)
    if(this.props.address===0){
      save_address(input).then(res => {
          console.log("res",res)
      try{
      if (res.status==='successfull') {
        toastMsg(ids,res.message,'success')
        this.props.update();
        this.props.step3();
    }
    else{toastMsg(ids,res.message,'error')}
    }catch{toastMsg(ids,'Something went wrong','error')}
    })
}
else{
    update_address(input).then(res => {
        console.log("res",res)
    try{
    if (res.status==='successfull') {
        toastMsg(ids,res.message,'success')
        this.setState(() => ({disabled:true}
      ))
  }
  else{toastMsg(ids,res.message,'error')}
  }catch{toastMsg(ids,'Something went wrong','error')}
  })
}
}

edit=(e)=>{
    e.preventDefault();
    this.setState({
        disabled:false
    })
    if(this.props.ria_form===0){
        this.setState({ria_edit:false})
    }
}
onChangePin=(e)=>{
    const validate=RegExp(/^[1-9][0-9]{5}$/)
    let pin= validate.test(e.target.value)
    if(pin===true){
        this.setState({
            [e.target.name]: e.target.value,
            pin_valid:true
        })
    }
    else{
        this.setState({
            pin_valid:false,
            [e.target.name]: e.target.value
        })
    }
}
onChangePin2=(e)=>{
    const validate = RegExp(/^[1-9][0-9]{5}$/)
    let pin= validate.test(e.target.value)
    if(pin===true){
        this.setState({
            [e.target.name]: e.target.value,
            pin_valid2:true,
        })
    }
    else{
          this.setState({
            pin_valid2:false,
            [e.target.name]: e.target.value,
        })
    }
}
cancel=()=>{
   this.setState({
       error:false,
       success:false,
       warning:false
   })
}
pleaseSave=(e)=>{
    e.preventDefault()
    this.setState({
        warning:true,
    })
}
handlesave=()=>{
    this.props.step3();
}
onleave=()=>{
    this.setState({
        warning:false,
    })
  }
Permanent_add_flag=(e)=>{
    this.setState({
        Permanent_add_flag:e.target.value,
        Ycheck:!this.state.Ycheck,
        Ncheck:!this.state.Ncheck
    })
    if(this.state.Permanent_add_flag==='Y'){
        this.setState({
            permanent_line1:'',
            permanent_line2:'',
            permanent_line3:'',
            permanent_city:'',
            permanent_state:'',
            permanent_country:'India',
            permanent_pincode:'',
        })
    }
    
    // console.log(this.state.Ycheck);
}

    render() {
        const {disabled} = this.state
        const stateArray = ["Andhra Pradesh","Arunachal Pradesh","Assam","Bihar","Chhattisgarh","Goa","Gujarat","Haryana","Himachal Pradesh","Jammu and Kashmir","Jharkhand","Karnataka","Kerala","Ladakh","Madhya Pradesh","Maharashtra","Manipur","Meghalaya","Mizoram","Nagaland","Odisha","Punjab","Rajasthan","Sikkim","Tamil Nadu","Telangana","Tripura","Uttarakhand","Uttar Pradesh","West Bengal","Andaman and Nicobar Islands","Chandigarh","Dadra and Nagar Haveli","Daman and Diu","Delhi","Lakshadweep","Puducherry"]
            if (this.state.loading){
                return(
                <div style={{textAlign:'center',marginTop:100}}>
                <Loader/>
                </div>
                );
            }
            return (
            <div>
            <ToastContainer theme='colored' limit={1}/>
            {this.state.error?(
                <SweetAlert
                    error
                    onConfirm={this.cancel}>
                {this.state.errormsg}
                </SweetAlert>):(<span></span>)}

                {this.state.warning?(
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="success"
                    cancelBtnText="No"
                    cancelBtnBsStyle="danger"
                    onConfirm={this.handlesave}
                  onCancel={this.onleave}
                  focusCancelBtn>Do you want to proceed without save your changes</SweetAlert>):(<span></span>)}

        {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancel}>
              {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

          <div className='row'>
            <div className='col-md-12'>
                <form onSubmit={this.onSubmitaddress} onKeyPress={(e)=>{e.target.keyCode === 13 && e.preventDefault();}}>
                <div className='form-heading'>Correspondence Address
                    {this.props.address===1?(
                    <span className='formButton'>
                        {disabled?
                        <button type="button" onClick={this.edit}><MDBIcon far icon="edit" className='form-edt-icon'/>Edit</button>:
                        <>
                            {this.state.pin_valid && this.state.pin_valid2 ?
                        <button type='submit'><MDBIcon far icon="save" className='form-edt-icon'/>Save</button>:
                        <button disabled><MDBIcon far icon="save" className='form-edt-icon'/>Save</button>}
                        </>}
                    </span>):null}
                </div>
                <div className='formHeight scrollB'>
                    <div class="row">
                        <div class="col-md-3 inner-form-gap">
                            <div class="input-field-u">
                                <input id="line1" type="text" name="current_line1" value={this.state.current_line1} onChange={this.onChange} maxLength={40} disabled={this.state.ria_edit} required/>
                                <label for="line1">House No*</label>
                            </div>
                        </div>
                        <div class="col-md-3 inner-form-gap">
                            <div class="input-field-nr-u">
                                <input id="line2" type="text" name="current_line2" value={this.state.current_line2} onChange={this.onChange}maxLength={40} disabled={this.state.ria_edit} placeholder=' '/>
                                <label for="line2">Apartment</label>
                            </div>
                        </div>
                        <div class="col-md-3 inner-form-gap">
                            <div class="input-field-u">
                                <input id="line33" type="text" name="current_line3" value={this.state.current_line3} onChange={this.onChange} maxLength={40} disabled={this.state.ria_edit} required/>
                                <label for="line33">Area/Locality*</label>
                            </div>
                        </div>
                        <div class="col-md-3 inner-form-gap">
                            <div class="input-field-u">
                                <input id="pincode" type="number" name="current_pincode" value={this.state.current_pincode} disabled={this.state.ria_edit} onChange={this.onChangePin}  required/>
                                <label for="pincode">Pin Code*</label>
                                {this.state.pin_valid?(<span></span>):(<div className='invalid-msg'>Invalid Pin code</div>)}
                            </div>
                        </div>
                        
                    </div>

                    <div class="row">
                        <div class="col-md-3 inner-form-gap">
                                <div class="input-field-u">
                                    <input id="city" type="text" name="current_city" value={this.state.current_city} disabled={this.state.ria_edit} maxLength={30} onChange={this.onChange} required/>
                                    <label for="city">City*</label>
                                </div>
                        </div>

                        {/* <div class="col-md-3 inner-form-gap">
                                <div class="input-field-u">
                                    <input id="country" type="text" name="current_country" value={this.state.current_country} disabled={true} onChange={this.onChange} required/>
                                    <label for="country">Country*</label>
                                </div>
                        </div> */}

                        <div class="col-md-3">
                            <div class="input-field-u">
                            <div className='select-label'>Country*</div>
                                <div className='profile-form-input'>
                                <select name="current_country" value={this.state.current_country} disabled={this.state.ria_edit} onChange={this.onChange} required>
                                <option value="" style={{background:'#999'}}>Select</option>
                                {Object.keys(this.state.country_list).map((val)=>{
                                    return<option value={val}>{val}</option>
                                })}
                                </select>
                                </div>
                            </div>
                        </div>

                        {/* <div class="col-md-3">
                        <div class="input-field-u">
                            <div className='select-label'>State*</div>
                            <div className='profile-form-input'>
                                <select name="current_state" value={this.state.current_state} disabled={this.state.ria_edit} onChange={this.onChange} required>
                                    <option value="" style={{background:'#999'}}>Select</option>
                                     {stateArray.map((val)=>{
                                       return <option value={val}>{val}</option>
                                    })}
                                </select>
                                </div>
                            </div>
                            </div> */}

                            <div class="col-md-3 inner-form-gap">
                                <div class="input-field-u">
                                    <input id="state" type="text" name="current_state" value={this.state.current_state} disabled={this.state.ria_edit} maxLength={30} onChange={this.onChange} required/>
                                    <label for="state">State*</label>
                                </div>
                            </div>
                        
                        
                        
                    </div>

                <div className='perm-add-box'>
                    <div className='form-heading'>
                        <div className='row'>
                            <div className='col-md-6 perm-address'>Permanent Address</div>
                            <div className='col-md-6'>
                                {this.props.address===1?(
                                <div>
                                    {this.state.disabled?(
                                        <div className='row'>
                                            <div className='col-6 same-address-head'>Same as correspondence address</div>
                                            <div className='col-6 radio-button'>
                                            <span><input type='radio' name='one' value='Y' disabled checked={this.state.Ycheck}/>Yes</span>
                                            <span><input type='radio' name='one' value='N' disabled checked={this.state.Ncheck}/>No</span>
                                            </div>
                                        </div>
                                    ):(
                                    <div className='row'>
                                    <div className='col-6 same-address-head'>Same as correspondence address</div>
                                    <div className='col-6 radio-button'>
                                       <span><input type='radio' name='one' value='Y'disabled={this.state.ria_edit} onChange={this.Permanent_add_flag} checked={this.state.Ycheck}/>Yes</span>
                                       <span><input type='radio' name='one' value='N'disabled={this.state.ria_edit} onChange={this.Permanent_add_flag} checked={this.state.Ncheck}/>No</span>
                                    </div>
                                    </div>)}
                                </div>):(
                                <div className='row'>
                                    <div className='col-6 same-address-head'>Same as correspondence address</div>
                                    <div className='col-6 radio-button'>
                                        <span><input type='radio' name='one' value='Y' onChange={this.Permanent_add_flag}/>Yes</span>
                                        <span><input type='radio' name='one' value='N' onChange={this.Permanent_add_flag}/>No</span>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                    {this.state.Permanent_add_flag==='N'?(
                    <>
                    <div class="row">
                        <div class="col-md-3 inner-form-gap">
                            <div class="input-field-u">
                                <input id="p_line1" type="text" name="permanent_line1" value={this.state.permanent_line1} maxLength={40} disabled={this.state.ria_edit} onChange={this.onChangePerm} required/>
                                <label for="p_line1">House No*</label>
                            </div>
                        </div>
                        <div class="col-md-3 inner-form-gap">
                            <div class="input-field-nr-u">
                                <input id="p_line2" type="text" name="permanent_line2" value={this.state.permanent_line2} maxLength={40} disabled={this.state.ria_edit} onChange={this.onChangePerm} placeholder=' '/>
                                <label for="p_line2">Apartment</label>
                            </div>
                        </div>
                        <div class="col-md-3 inner-form-gap">
                            <div class="input-field-u">
                                <input id="p_line33" type="text" name="permanent_line3" value={this.state.permanent_line3} maxLength={40} disabled={this.state.ria_edit} onChange={this.onChangePerm} required/>
                                <label for="p_line33">Area/Locality*</label>
                            </div>
                        </div>
                        <div className='col-md-3 inner-form-gap'>
                            <div class="input-field-u">
                                <input id="p_pincode" type="number" name="permanent_pincode" value={this.state.permanent_pincode} disabled={this.state.ria_edit} onChange={this.onChangePin2} required/>
                                <label for="p_pincode">Pin Code*</label>
                                {this.state.pin_valid2?(<span></span>):(<div className='invalid-msg'>Invalid Pin code</div>)}
                            </div>
                        </div>
                        
                    </div>

                    <div class="row">
                        <div class="col-md-3 inner-form-gap">
                            <div class="input-field-u">
                                <input id="p_city" type="text" name="permanent_city" value={this.state.permanent_city} disabled={this.state.ria_edit} maxLength={30} onChange={this.onChange} required/>
                                <label for="p_city">City*</label>
                            </div>
                        </div>
                        <div class="col-md-3">

                        <div class="input-field-u">
                            <div className='select-label'>Country*</div>
                                <div className='profile-form-input'>
                                <select name="permanent_country" value={this.state.permanent_country} onChange={this.onChangePerm} required>
                                <option value="" style={{background:'#999'}}>Select</option>
                                {Object.keys(this.state.country_list).map((val)=>{
                                    return<option value={val}>{val}</option>
                                })}
                                </select>
                                </div>
                            </div>

                            {/* <div class="input-field-u">
                                <input id="perm_country" type="text" name="permanent_country" value={this.state.permanent_country} disabled={true} onChange={this.onChangePerm} required/>
                                <label for="perm_country">Country*</label>
                            </div> */}
                        </div>
                        {/* <div class="col-md-3">
                            <div class="input-field-u">
                            <div className='select-label'>State*</div>
                            <div className='profile-form-input'>
                                <select name="permanent_state" value={this.state.permanent_state} disabled={this.state.ria_edit} onChange={this.onChangePerm} required>
                                    <option value=""style={{background:'#999'}}>Select</option>
                                    {stateArray.map((val)=>{
                                        return <option value={val}>{val}</option>
                                        })}
                                </select>
                                </div>
                            </div>
                        </div> */}

                        <div class="col-md-3 inner-form-gap">
                            <div class="input-field-u">
                                <input id="p_state" type="text" name="permanent_state" value={this.state.permanent_state} disabled={this.state.ria_edit} maxLength={30} onChange={this.onChange} required/>
                                <label for="p_state">State*</label>
                            </div>
                        </div>
                    </div>
                    </>):(null)}
                </div>
            </div>

    {this.props.userInfo===1 && this.props.address===0?(
        <div className='submit-Btn'><button type="button" onClick={this.props.step1}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
        {this.state.pin_valid && this.state.pin_valid2?(
        <button type='submit'>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>):(
        <button disabled={true} style={{background:'#E5E7E9'}}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
        )}
        </div>):(<div></div>)}

    {this.props.userInfo===0?(<div className='previous-msg'>Please fill the previous form first.</div>):(<div></div>)}

    {this.props.address===1 && this.props.userInfo===1?(<div className='submit-Btn'>
        <button type='button' onClick={this.props.step1}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
        {this.state.disabled?(
        <button onClick={this.props.step3}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
        ):(
        <button type='button' onClick={this.pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
        )}
        </div>):(<div></div>)}
    </form>
    </div>
    </div>
   <div>
  </div>
</div>
        )
    }
}

import React,{useState} from 'react'
import {Input,Button,Form,Modal} from 'antd';
import {handleComments} from './Api'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddComment = (props) => {
  const[success,setSuccess] = useState(false)
  const[successMsg,setSuccessMsg] = useState('')
  const[loading,setLoading] = useState(false)

  const date = new Date().toISOString().split('T')[0] 
  const { TextArea } = Input;

  const onSubmitComment = async (values) =>{
    setLoading(true)
    const input = {
      access_token:localStorage.token,
      comment: values.comment,
      comment_id:values.comment_id
    }
    const res = await handleComments(input)
    try{
        if (res.status==='successfull') {
          setSuccess(true)
          setSuccessMsg(res.message)
          setLoading(false)
        }
        else{
          toast.error(res.message)
          setLoading(false)
        }
    }catch{
      toast.error('Something went wrong')
      setLoading(false)
    }
  }

  return (
    <>
       <ToastContainer theme='colored' limit={1}/>
      
      <Modal  footer={null} visible={true} onCancel={props.handleModal}>
        <div className='commentBoxHead'>Comments</div>
        {loading?
        <>
          <div class="cubes">
              <div class="sk-cube sk-cube1"></div>
              <div class="sk-cube sk-cube2"></div>
              <div class="sk-cube sk-cube3"></div>
              <div class="sk-cube sk-cube4"></div>
              <div class="sk-cube sk-cube5"></div>
              <div class="sk-cube sk-cube6"></div>
              <div class="sk-cube sk-cube7"></div>
              <div class="sk-cube sk-cube8"></div>
              <div class="sk-cube sk-cube9"></div>
          </div>
          <p className='loaderWait'>Loading...</p>
        </>:
        success?
        <div className='commentThank'>
          {successMsg}
          <div><Button onClick={props.handleModal} className="submitButton">Close</Button></div>
        </div>
        :
        <>
        <div className='commentBoxTagLine'>Write your comments to inform / query as regarding your Wealth</div>
        <Form onFinish={onSubmitComment}>
          <Form.Item
            name="comment"
            rules={[{ required: true, message: 'Please input comment!' }]}>
            <TextArea rows={7} maxLength={1000} allowClear={true} showCount={true}/>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="submitButton">Submit</Button>
            {/* {date} */}
          </Form.Item>
        </Form>
        </>}
      </Modal>
    </>
  )
}

export default AddComment
import React from 'react'
import Logo from '../../../Images/navabar_footer/logoBlack.png'
import Tick from '../../../Images/tick.png'
import {MDBIcon} from 'mdbreact'
import {useNavigate} from 'react-router-dom'

const ThankYou = () => {
  const navigate = useNavigate()

  const Home = (e)=>{navigate('/home')}
  return (
    <>
      <div className='ThLogo'><img src={Logo} alt='logo'/></div>

      <div className='ThLogo'><img src={Tick} alt='logo'/></div>
      <div className='chts2Head'>Thank you for downloading<br/>cheat sheet</div>
      <div className='ThText'>it has been mailed to you...</div>
      <div className='threturnB'onClick={Home}>Return to Home&nbsp;&nbsp;<MDBIcon fas icon="long-arrow-alt-right" /></div>
      <div className='thF'></div>
    </>
  )
}

export default ThankYou
import React,{useState,useEffect} from 'react';
import { Table } from "ant-table-extensions";
import {customer_margin_details} from '../../Api'
import { DatePicker, Space } from 'antd';
import moment from 'moment'

const Margins = (props) => {

    const [pickerValue, setPickerValue] = useState(moment(new Date()));
    const [data, setData] = useState([]);
    const date = new Date().toISOString().split('T')[0]
    const [dateSend, setdateSend] = useState([])

    const { RangePicker } = DatePicker;

      const fetchData = ()=>{
        let newdata = []
        const input = {
            access_token:localStorage.token,
            type:'margins',
            id:props.customer_id,
            start_date:dateSend[0],
            end_date:dateSend[1]
        }
        customer_margin_details(input).then(res => {
            console.log('margin',res);
            Object.keys(res.result).map((val)=>{
                newdata.push({ 
                key: val,
                date:res.result[val].date,
                type:res.result[val].type,
                net: res.result[val].net,
                enabled: res.result[val].enabled,
                available_adhoc_margin: res.result[val].available_adhoc_margin,
                available_cash: res.result[val].available_cash,
                available_collateral: res.result[val].available_collateral,
                available_intraday_payin: res.result[val].available_intraday_payin,
                available_live_balance: res.result[val].available_live_balance,
                available_opening_balance: res.result[val].available_opening_balance,
                utilised_debits: res.result[val].utilised_debits,
                utilised_delivery: res.result[val].utilised_delivery,
                utilised_exposure: res.result[val].utilised_exposure,
                utilised_holding_sales: res.result[val].utilised_holding_sales,
                Utilised_liquid_collateral: res.result[val].Utilised_liquid_collateral,
                utilised_m2m_realised: res.result[val].utilised_m2m_realised,
                utilised_m2m_unrealised: res.result[val].utilised_m2m_unrealised,
                utilised_option_premium: res.result[val].utilised_option_premium,
                utilised_payout: res.result[val].utilised_payout,
                utilised_span: res.result[val].utilised_span,
                utilised_stock_collateral: res.result[val].utilised_stock_collateral,
                utilised_turnover: res.result[val].utilised_turnover,
                name: res.result[val].name,
                })
            })
            setData(newdata)
          })
        }

      useEffect(()=>{
        fetchData()
      },[])

      const submitData =()=>{
        fetchData()
      }
    
      const onChangeDate = (date, dateString) => {
        setdateSend(dateString)
      };

      const columns = [
        {title: 'Updated Date',dataIndex: 'date',width: 70},
        {title: 'Type',dataIndex: 'type',width: 70},
        {title: 'Name',dataIndex: 'name',width: 70},
        {title: 'Net',dataIndex: 'net',width: 70},
        {title: 'Enabled',dataIndex: 'enabled',width: 70},
        {title: 'Available adhoc margin',dataIndex: 'available_adhoc_margin',width: 90}, 
        {title: 'Available cash',dataIndex: 'available_cash',width: 70},
        {title: 'Available collateral',dataIndex: 'available_collateral',width: 70},
        {title: 'Available intraday payin',dataIndex: 'available_intraday_payin',width: 90},
        {title: 'Available live balance',dataIndex: 'available_live_balance',width: 80},
        {title: 'Available opening balance',dataIndex: 'available_opening_balance',width: 100},
        {title: 'Utilised debits',dataIndex: 'utilised_debits',width: 70},
        {title: 'Utilised delivery',dataIndex: 'utilised_delivery',width: 70},
        {title: 'Utilised exposure',dataIndex: 'utilised_exposure',width: 70},
        {title: 'Utilised holding sales',dataIndex: 'utilised_holding_sales',width: 85},
        {title: 'Utilised Liquid  collateral',dataIndex: 'Utilised_liquid_collateral',width: 90},
        {title: 'Utilised M2M realised',dataIndex: 'utilised_m2m_realised',width: 90},
        {title: 'Utilised M2M unrealised',dataIndex: 'utilised_m2m_unrealised',width: 90},
        {title: 'Utilised option premium',dataIndex: 'utilised_option_premium',width: 90},
        {title: 'Utilised payout',dataIndex: 'utilised_payout',width: 70},
        {title: 'Utilised Span',dataIndex: 'utilised_span',width: 70},
        {title: 'Utilised stock collateral',dataIndex: 'utilised_stock_collateral',width: 90},
        {title: 'Utilised turnover',dataIndex: 'utilised_turnover',width: 70},
      ]
  return(
      <>
      <div className='modalDateBox'>
        <RangePicker 
          onChange={onChangeDate} allowClear={true}
          defaultValue={[pickerValue, pickerValue]} />
        <button className='dateSubmitButton' onClick={submitData}>Submit</button>
      </div>
        <Table
              rowClassName="editable-row"
              columns={columns}
              dataSource={data}
              size='small'
              scroll={{ x: 1150, y: 400 }}
              pagination={{
                pageSize: 10,
                position:['bottomCenter']
              }}
              sticky={true}
              exportableProps={{ fileName: `Margins_${props.cusEmail}_${date}`}}
          />

      </>
  );
};

export default Margins;

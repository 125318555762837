// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wealthBg {
  background: white;
  height: 100%;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.animate {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 0.5s;
  animation-name: fadeIn;
}
@keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/Component/Dashboard/wealth360/Wealth360.scss"],"names":[],"mappings":"AAIA;EACI,iBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,4CAAA;AAFJ;;AAMA;EAEI,sBAAA;EAEA,yBAAA;EAGA,qBAAA;EAEA,sBAAA;AAHJ;AAeE;EACE;IACE,YAAA;EAJJ;EAOE;IACE,UAAA;EALJ;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Montserrat');\n$mainColor: #E87C1B;\n$darkGrey:#525252;\n\n.wealthBg{\n    background: white;\n    height: 100%;\n    border-radius: 10px;\n    padding: 10px;\n    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);\n}\n\n\n.animate {\n    -webkit-animation-duration: 1s;\n    animation-duration: 1s;\n    -webkit-animation-fill-mode: both;\n    animation-fill-mode: both;\n    -webkit-animation-delay: .5s;\n    -moz-animation-delay: .5s;\n    animation-delay: .5s;\n    -webkit-animation-name: fadeIn;\n    animation-name: fadeIn;\n  }\n  \n  @-webkit-keyframes fadeIn {\n    from {\n      opacity: 0;\n    }\n  \n    to {\n      opacity: 1;\n    }\n  }\n  @keyframes fadeIn {\n    from {\n      opacity: .2;\n    }\n  \n    to {\n      opacity: 1;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React,{useState,useEffect} from 'react'
import { Row,Col,Table,Drawer,Popconfirm, Typography,Select,Form,Input,Avatar,Button} from 'antd';
import { EditFilled,CloseCircleFilled,SaveOutlined,SnippetsOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import {hoa_contact_get,hoa_contact_put,hoa_contact_dropdown_get} from '../../Api'
import Loader from '../../../../shared/Loader';
import AddLeads from './AddLeads';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LeadNotes from './LeadNotes';

const WebsiteLead = () => {
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false)
    const [detailData,setDetailData] = useState({})
    const [loading,setLoading] = useState(false)
    const [dropdown,setDropdown] = useState({})
    const[modalType,setModalType] = useState('')
    const[cusId,setCusID] = useState('')
    const[cusEmail,setCusEmail] = useState('')
    const[cusName,setCusName] = useState('')
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    const toastMsg =(id,msg,type)=>{
      toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    }
   
    const {Option} = Select;
    

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        disable,
        ...restProps
      }) => {

        const inputNode = title === 'Action' ? 
        <Select placeholder="Select Action"> 
          { Object.values (dropdown).map((val)=>{
            return(
            <Option value={val}>{val}</Option>
            )
          })}
        </Select>:
        title === 'Status' ?
        <Select>
          <Option value="Open">Open</Option>
          <Option value="Meeting Fix">Meeting Fix</Option>
          <Option value="Prospect">Prospect</Option>
        </Select>:
        inputType === 'remarks' ? <Input/>:<Input/>
                                          
        return (
          <td {...restProps}>
      {editing? (
        <Form.Item
          name={dataIndex}
          rules={[{required: true,message: `Please Input ${title}!`}]}
        >
            {inputNode}
        </Form.Item>
        
      ) : (
        children
      )}
    </td>
  );
};


  const isEditing = (record) => record.lead_id === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      action: '',
      remarks: '',
      status: '',
      ...record,
    });
    setEditingKey(record.lead_id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const getContactUsList = async()=>{
        setLoading(true)
        let newdata = []
        const input = {access_token:localStorage.token,flag:'all_lead'}
        const res = await hoa_contact_get(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.keys(res.result).map((val)=>{
              newdata.push({ 
              key: res.result[val].lead_id,
              name:res.result[val].name,
              lead_id:res.result[val].lead_id,
              surname:res.result[val].surname,
              date:res.result[val].date,
              email:res.result[val].email,
              mobile:res.result[val].mobile,
              message:res.result[val].message,
              reference:res.result[val].reference,
              remarks:res.result[val].remarks,
              updated_date:res.result[val].updated_date,
              status:res.result[val].status,
              })
              
            })
            setData(newdata)
            setLoading(false)
          }
          else{setLoading(false); toast.error(res.message)}
        }catch{setLoading(false); toast.error('Something went wrong')}
      }
      
  const getDropdownList = async()=>{
  const input = {access_token:localStorage.token}
  const res = await hoa_contact_dropdown_get(input)
        try{
          if (res && res.status==='successfull'){ setDropdown(res.result)}
          else{setLoading(false)}
        }catch{setLoading(false)}
      }
    useEffect(()=>{
        getContactUsList()
        getDropdownList()
    },[])

    const showDrawer = (type) => {
      setVisible(true);
      setModalType(type)
  };

  const onClose = () => {
    getContactUsList()
    setVisible(false);
    setModalType('')
    setDetailData({})
  };

  const onVisibleChange=(record)=>{
    setCusID(record.lead_id)
    setCusEmail(record.email)
  }
      
  const save = async (key) => {
   
        try {
    const row = await form.validateFields();
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
            setData(newData);
            setEditingKey('');
          const input = {
            access_token:localStorage.token,
            row:newData[index],
          }
          console.log(input);
           const id = toast.loading("Please wait...",{toastId:'load'})
            hoa_contact_put(input).then(res => {
              console.log(res);
              try{
                if (res && res.status==='successfull'){
                  getContactUsList();
                  toastMsg(id,res.message,'success')
                  }
                else{toastMsg(id,res.message,'error');}
              }catch{toastMsg(id,'Something went wrong','error')}
            })
          } else {
            newData.push(row);
            setData(newData);
            setEditingKey('');
            setLoading(false)
          }
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
          setLoading(false)
        }
      };

    const openDrw=()=>{
      setVisible(true)
    }
  
      

    const columns = [
        {title: 'Name',dataIndex: 'name',width: 160,render: (text, record) => (
          <Row >
            <Col span={6}>
              <Avatar size="small" style={{ backgroundColor: '#FF862C', verticalAlign: 'middle' }}>
                {record.name.substring(0,1)}
              </Avatar>
            </Col>
            <Col span={18}>
              <p>{record.name} {record.surname}</p>
            </Col>
          </Row>
        )},
        {title: 'Email/Phone',dataIndex: 'email',width: 170,render: (text, record) => (<>
              <p>{record.email}</p>
              <p className='reviewTableId'>{record.mobile}</p>
              </>)},
          {
            title: 'Lead ID',
            dataIndex: 'lead_id',
            width: 80,
          },
          {
            title: 'Date',
            dataIndex: 'date',
            width: 80,
          },
          {
            title: 'Reference',
            dataIndex: 'reference',
            width: 80,
          },
          {
            title: 'Remarks',
            dataIndex: 'remarks',
            width: 130,
            editable: true,
          },
          {
            title: 'Updated Date',
            dataIndex: 'updated_date',
            width: 80,
          },
          {
            title: 'Status',
            dataIndex: 'status',
            width: 100,
            editable: true,
            
          },
          {
            title: 'Operation',
            dataIndex: 'operation',
            // fixed: 'right',
            width: 100,
            render: (_, record) => {
                const editable = isEditing(record);
           
  return editable ? (
    <span>
    <Typography.Link
      onClick={() => save(record.key)}
      style={{
        marginRight: 2,
      }}
    >
      <span className='outlineSaveButton'>
        <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
      </span>
    </Typography.Link>
    <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
        <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
    </Popconfirm>
  </span>
) : (
  <span>
  <Typography.Link disabled={editingKey !== ''}
    onClick={() => edit(record)}>
    <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
  </Typography.Link>
  </span>
        )
             },
          },
          {title: 'Action',key: 'action',width: 100,
            render: (text, record) => (<>
                <span  placement="bottomLeft" onClick={()=>onVisibleChange(record)}>
                  <button className='TableActionButton'><SnippetsOutlined onClick={()=>showDrawer('Notes')} className='Tic'/></button> 
                </span>
                </>
            ),
          }, 
        ];


        const mergedColumns = columns.map((col) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: (record) => ({
                record,
                // inputType: col.dataIndex === 'status' || col.dataIndex === 'action' || col.dataIndex === 'remarks',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
              }),
            };
          });
        
       
    return(
        <div>
            <ToastContainer theme='colored'/>
            {loading?<Loader/>:null}
            <Form form={form} component={false}>
              <Table
                components={{body: {cell: EditableCell}}}
                rowClassName="editable-row"
                columns={mergedColumns}
                dataSource={data}
                size='small'
                scroll={{y: 370 }}
                sticky={true}
                pagination={{position:['bottomCenter']}}
              />
            </Form>

            <div className='addBtnDrw'><button onClick={()=>showDrawer('addleads')}>Add New Lead + </button></div>
            <Drawer placement="top" height={'100%'} width={'30%'} visible={visible} headerStyle={{display:'none'}}>
            {/* <div className='formDrawrDiv'><AddLeads/></div> */}
            {modalType==='addleads'?<AddLeads close={onClose}  tabName={modalType}/>:null}
            {modalType==='Notes'?<LeadNotes  customer_id={cusId} cusEmail={cusEmail} notes_reference='leads_client' close={onClose}  tabName={modalType}/>:null}
            </Drawer>  
                  
            
        </div>
  )
}

export default WebsiteLead
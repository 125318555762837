import React, { Component } from 'react'
import {save_nominee_details,get_nominee_details,delete_nominee_details} from './Api'
import {MDBIcon} from 'mdbreact'
import Loader from '../../shared/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastMsg =(ids,msg,type)=>{
  toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
}

export default class NomineeExcel extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        resData:{},
        order : [],
        del_product:{},
        confirm:false,
        success:false,
        error:false,
        successmsg:'',
        errormsg:''
      };
    }
    componentDidMount(){
      let arr = []
        const input = {
            access_token:localStorage.token,
            id:this.props.id
        }
        get_nominee_details(input).then(res => {
        console.log("res",res)
        try{
        if (res.status==='successfull') {
        Object.keys(res.result).map((val)=>{
            arr.push(res.result[val])
        })
        this.setState(() => ({
            resData:res.result,
            order:arr
        }))
    }
    else{console.log(res)}
    }catch{
      this.setState({loading:false})
      toast.error('Something went wrong')
    }
    })
    }
    handleRowDel(product) {
        if(product.nominee_name && product.relationship && product.nominee_percentage && product.nominee_type){
            this.setState({del_product:product,confirm:true})
        }
        else{
            var index = this.state.order.indexOf(product);
            this.state.order.splice(index, 1);
            this.setState(this.state.order);
        }
       
    };
    handledelete=()=>{
        const input = {
            access_token:localStorage.token,
            id:this.props.id,
            delete_row:this.state.del_product
        }
        console.log("input",input)
        delete_nominee_details(input).then(res => {
            console.log("resff",res)
            try{
            if (res.status==='successfull') {
                this.setState({confirm:false})
                toast.success(res.message)
                var index = this.state.order.indexOf(this.state.del_product);
                this.state.order.splice(index, 1);
                this.setState(this.state.order);
        }
        else{
          this.setState({confirm:false})
          toast.error(res.message)}
        }catch{
          this.setState({confirm:false})
          toast.error('Something went wrong')}
        }) 
      }
      
    handleAddEvent(evt) {
      var id = Math.floor(1000 + Math.random() * 9000);
      var product = {
        id: id,
        nominee_name: '',
        relationship: '',
        nominee_percentage:'',
        nominee_type:'',
        pan_number:'',
        nominee_dob:'',
        guardian_name:'',
        gurdian_pan_number:'',
      }
      this.state.order.push(product);
      this.setState(this.state.order);
    }
  
    handleProductTable(evt) {
      var item = {
        id: evt.target.id,
        name: evt.target.name,
        value: evt.target.value
      };
  var order = this.state.order.slice();
    var neworder = order.map(function(product) {
      for (var key in product) {
        if (key === item.name && product.id === parseInt(item.id)) {
          product[key] = item.value;
        }
      }
      return product;
    });
      this.setState({order:neworder});
    };
    onCancel=()=>{
        this.setState({
          confirm:false,
          success:false,
          error:false,
        })
      }
    render() {
        console.log(this.state.order)
      return (
        <div>
        {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.onCancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

        {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.onCancel}>
              {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

        {this.state.confirm?(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={this.handledelete}
                onCancel={this.onCancel}
                focusCancelBtn></SweetAlert>
            ):(<span></span>)}

          <ProductTable onProductTableUpdate={this.handleProductTable.bind(this)} onRowAdd={this.handleAddEvent.bind(this)} onRowDel={this.handleRowDel.bind(this)} order={this.state.order} update={this.props.update} nomination={this.props.nomination} step4={this.props.step4} finance={this.props.finance} fatca={this.props.fatca} id={this.props.id}/>
        </div>
      );
  
    }
  }
  
 

  class ProductTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        success:false,
        error:false,
        successmsg:'',
        errormsg:'',
        disabled:false,
        skip:false,
        warning:false,
        loading:false,
      };
    }
    componentDidMount(){
      if(this.props.nomination===1){
        this.setState({disabled:true})
      }
     }
    enableNext=()=>{
      this.setState({skip:!this.state.skip})
     }
    onSubmitRecord=(e) =>{
      e.preventDefault();
      const ids = toast.loading("Please wait...",{toastId:'load'})
        const input = {
          access_token:localStorage.token,
          nominee_data:this.props.order,
          id:this.props.id,
          skip:this.state.skip
        }
        console.log("input",input)
        save_nominee_details(input).then(res => {
            console.log("resgoa",res)
        try{
        if (res.status==='successfull') {
          if(this.props.nomination===1){
            toastMsg(ids,res.message,'success')
            this.setState(() => ({disabled:true})) 
          }
          else{
            this.props.update();
            this.props.finance();
        }
        }
      else{toastMsg(ids,res.message,'error')}
      }catch{toastMsg(ids,'Something went wrong','error')}
      })
      }
      onCancel=()=>{
        this.setState({
          success:false,
          error:false,
          warning:false,
          disabled:true
        })
      }
      edit=(e)=>{
        e.preventDefault();
        this.setState({
            disabled:false
        })
    }
    pleaseSave=(e)=>{
      e.preventDefault()
      this.setState({
          warning:true,
      })
  }
  onleave=()=>{
    this.setState({
        warning:false,
    })
  }
  handlesave=()=>{
    this.props.finance();
}
    render() {
      var onProductTableUpdate = this.props.onProductTableUpdate;
      var rowDel = this.props.onRowDel;
      let disabled = this.state.disabled
      let perm_disable = true
      let nomination = this.props.nomination
      var product = this.props.order.map(function(product) {
        if(product.id>1000 || nomination===0){
          perm_disable = false
        }
        else{
          perm_disable=true
        }
        return (<ProductRow onProductTableUpdate={onProductTableUpdate} product={product} onDelEvent={rowDel.bind(this)} key={product.id} disabled={disabled} perm_disable={perm_disable}/>)
      }); 
      
      if (this.state.loading){
        return(
          <div style={{textAlign:'center',marginTop:100}}>
          <Loader/>
          </div>
          );
      }
      return (
        <div> 
            <ToastContainer theme='colored' limit={1}/>
             {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.onCancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

        {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.onCancel}>
              {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

            {this.state.warning?(
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="success"
                    cancelBtnText="No"
                    cancelBtnBsStyle="danger"
                    onConfirm={this.handlesave}
                  onCancel={this.onleave}
                  focusCancelBtn>Do you want to proceed without save your changes</SweetAlert>):(<span></span>)}
    
        <form onSubmit={this.onSubmitRecord}>

        <div className='form-heading'>Nominee Details
            {this.props.nomination===1?(
            <span className='formButton'>
            {disabled?
            <button type="button" onClick={this.edit}><MDBIcon far icon="edit" className='form-edt-icon' />Edit</button>:
            <button type='submit'><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>}
            </span>):null}
            <span className={disabled?"formButtonDisable":"formButton"}>
              <button disabled={disabled} type="button" onClick={this.props.onRowAdd}>
              <MDBIcon icon="plus"className='form-edt-icon' />Add Row </button>
            </span>
        </div>
        
        <div className='formHeight'>
        <table class="table table-condensed table-sm" cellspacing="0" cellpadding="0" border="0">
        <thead>
        <tr className='tb_header' >
                <td>Nominee Name</td>
                <td className='lf-border'>Relation</td>
                <td className='lf-border'>Percentage (%)</td>
                <td className='lf-border'>Nominee Type</td>
                <td className='lf-border'>Nominee PAN Number</td>
                <td className='lf-border'>Date of Birth</td>
                <td className='lf-border'>Guardian Name</td>
                <td className='lf-border rh-border'>Guardian PAN Number</td>
                <td></td>
            </tr>
          </thead>
            {product}
          </table>
        </div>

        {this.props.nomination===0?(
          <div className='agree-checkbox'><input type='checkbox' onChange={this.enableNext}/>  Skip this section</div>):(<span></span>)}

      {this.props.fatca===1?(<div></div>):(<div className='previous-msg'>Please fill the previous form first.</div>)}

      {this.props.fatca===1 && this.props.nomination===0?(
      <div className='submit-Btn'>
        <button onClick={this.props.step4}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
        {this.state.skip?(
            <button type='button' onClick={this.onSubmitRecord}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
          ):(
            <button type="submit">Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
          )}
      </div>
      ):(<div></div>)} 
      
      {this.props.fatca===1 && this.props.nomination===1?(
      <>
      {this.state.disabled?(
        <div className='submit-Btn'><button onClick={this.props.step4}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button onClick={this.props.finance}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>
      ):(
        <div className='submit-Btn'><span><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</span><button type='button' onClick={this.pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>)}
      
      </>):(<div></div>)}
      </form>
      </div>
      );
    }
  }
  
  class ProductRow extends React.Component {
    onDelEvent() {
      this.props.onDelEvent(this.props.product);
    }
    render() {
      let panRq = true
      let gurPan = false
      if(this.props.product.nominee_type==='Minor'){
        panRq = false
        gurPan = true
      }
      return ( 
        <tr className='tb_body'>
        <EditableCellText onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
          "type": "nominee_name",
          value: this.props.product.nominee_name,
          id: this.props.product.id,
          perm_disable:this.props.perm_disable
        }}/>

        <EditableCellSelectRelation onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "relationship",
            value: this.props.product.relationship,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
      
        <EditableCellNum onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "nominee_percentage",
            value: this.props.product.nominee_percentage,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>

        <EditableCellSelect onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "nominee_type",
            value: this.props.product.nominee_type,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
        <EditableCellPan onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "pan_number",
            value: this.props.product.pan_number,
            id: this.props.product.id,
            disabled:this.props.disabled,
            panRq : panRq
          }}/>
          <EditableCellDate onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            type: "nominee_dob",
            value: this.props.product.nominee_dob,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
        <EditableCellTextGr onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "guardian_name",
            value: this.props.product.guardian_name,
            id: this.props.product.id,
            disabled:this.props.disabled,
            gurPan:gurPan
          }}/>
        <EditableCellPanGrd onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            type: "gurdian_pan_number",
            value: this.props.product.gurdian_pan_number,
            id: this.props.product.id,
            disabled:this.props.disabled,
            gurPan:gurPan
          }}/>
           <td>
            <div className='delBtn' onClick={this.onDelEvent.bind(this)}>X</div>
          </td>
        </tr>
      );
  
    }
  }

class EditableCellPan extends React.Component {
    render() {
      return (
        <td className='lf-border'>
          <input type='text' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.disabled} required={this.props.cellData.panRq}/>
        </td>
      );
    }
  }
  class EditableCellPanGrd extends React.Component {
    render() {
      return (
        <td className='lf-border rh-border'>
          <input type='text' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.disabled} required={this.props.cellData.gurPan}/>
        </td>
      );
    }
  }
class EditableCellNum extends React.Component {
    render() {
      return (
        <td className='lf-border'>
          <input type='number' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.disabled} required/>
        </td>
      );
    }
  }
  class EditableCellSelectRelation extends React.Component {
    render() {
      return (
        <td className='lf-border'>
         <select type='select' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.disabled} required>
            <option value="">Select</option>
            <option>Father</option>
            <option>Mother</option>
            <option>Son</option>
            <option>Daughter</option>
            <option>Husband</option>
            <option>Wife</option>
            <option>Brother</option>
            <option>Sister</option>
            <option>Grandfather</option>
            <option>Grandmother</option>
            <option>Grandson</option>
            <option>Granddaughter</option>
            <option>Uncle</option>
            <option>Aunt</option>
            <option>Nephew</option>
            <option>Niece</option>
            <option>Cousin(Male)</option>
            <option>Cousin(Female)</option>
            <option>Sibling</option>
            <option>spouse</option>
            <option>sibling</option>
            <option>sibling's child</option>
            <option>aunt's/uncle's child</option>
          </select>
        </td>
      );
    }
  }
  class EditableCellSelect extends React.Component {
    render() {
      return (
        <td className='lf-border'>
         <select type='select' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.disabled} required>
                <option value="">Select</option>
                <option>Minor</option>
                <option>Major</option>
                <option>Non Individual</option>
            </select>
        </td>
      );
    }
  }
  class EditableCellDate extends React.Component {
    render() {
      return (
        <td className='lf-border'>
          <input type='date' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.disabled} required min="1900-01-01"/>
        </td>
      );
    }
  }
  class EditableCellText extends React.Component {
    render() {
      return (
        <td className='lf-border'>
          <input type='text' maxLength={80} name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.perm_disable} required/>
        </td>
      );
    }
  }
  class EditableCellTextGr extends React.Component {
    render() {
      return (
        <td className='lf-border'>
          <input type='text' maxLength={80} name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.perm_disable} required={this.props.cellData.gurPan}/>
        </td>
      );
    }
  }

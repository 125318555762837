import React from 'react'
import './Login.scss'
import './LoginMedia.scss'
import {login} from '../Api';
import {useNavigate} from 'react-router-dom'
import { Col,Row,Image, Button, Form, Input } from 'antd';
import { MDBIcon } from 'mdbreact';
import LoginImage from '../../../Images/loginImage.jpg'
import Logo from '../../../Images/logoBlack.png'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {

  const navigate = useNavigate();
  const toastMsg =(id,msg,type)=>{
    toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
  } 
  const onSubmitLogin = async (values)=>{
    const id = toast.loading("Please wait...") 
    const input = {
      email_address: values.emailAddress,
      password: values.password
    }
    const res = await login(input)
    .catch((err) => {toastMsg(id,res.message,'error')});
    console.log('login',res);
    try{
      if (res.status==='successfull') {
        localStorage.setItem('token',res.result.access_token)
        localStorage.setItem('email',values.emailAddress)
        localStorage.setItem('mobile_number',res.result.mobile_number) 
        localStorage.setItem('role_id',res.result.role_id)
        if(res.result.verification==='N'){navigate('/verification')}
        if(res.result.role_id===1){navigate('/super_admin')}
        if(res.result.role_id===2){navigate('/admin')}
        if(res.result.role_id===3){navigate('/admin/operation')}
        if(res.result.role_id===4){navigate('/admin/sales')}
        if(res.result.role_id===5){navigate('/dashboard')}
        if(res.result.role_id===6){navigate('/admin/marketting')}      
      }else{
        toastMsg(id,res.message,'error')
      }
    }catch{
      toastMsg(id,'Something went wrong','error')
    }
  }
    const FormLogin = ()=>{
      return(
          <>
            <Form onFinish={onSubmitLogin}>
            <Form.Item
              name="emailAddress"
              rules={[{ required: true, message: 'Please input your Email Address!' }]}>
              <Input 
              pattern="\S(.*\S)?" title="Remove spaces"
              onInput={e => e.target.value = e.target.value.toLowerCase()}
              prefix={<MDBIcon icon="at" className='loginFormIcon'/>}
              placeholder="Email" className='LoginFormInput'/>
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
              hasFeedback
              >
              <Input.Password
              prefix={<MDBIcon icon="lock" className='loginFormIcon'/>}
              placeholder="Password"
              className='LoginFormInput'/>
            </Form.Item>
            <div className="loginFormForgot">
              <a href="/forgotpassword">Forgot Password?</a>
            </div>
            <Form.Item>
              <Button htmlType="submit" className="loginFormButton">
                Log in
              </Button>
            </Form.Item>
        </Form>
         <p className='LoginOr'>Or</p>
         <p className='LoginOr'>Don't have an account?<a href='/signup'>SignUp</a></p>
         </>
      )
    }
 
  return (
      <div className='fixPageHeight'>
        <ToastContainer theme='colored'/>
        <div className='desktop'>
          <Row >
            <Col span={13}>
              <div className='loginFormDiv'>
                <MDBIcon icon="home" onClick={()=>navigate('/home')} className='loginHomeIcon' />
                <Image src={Logo} rootClassName='loginLogoImage' preview={false}/>
                <div className='LoginHeading'>Login To Wealth 360 Customer Portal</div>
                <FormLogin/>
              </div>
            </Col>
            <Col span={11}>
              <Image src={LoginImage} rootClassName='loginImage' preview={false}/>
            </Col>
          </Row>
        </div>
        <div className='mobile'>
          <div className='mobileLoginBg'>
            <MDBIcon icon="home" onClick={()=>navigate('/home')} className='loginHomeIcon' />
            <Image src={Logo} rootClassName='loginLogoImage' preview={false}/>
            <div className='mobileLoginBox'>
              <div className='LoginHeading'>Login To Wealth 360 Customer Portal</div>
              <FormLogin/>
            </div>
          </div>
        </div>
      </div>
        )
      }
export default Login

import React from 'react'
import {Modal,} from 'antd';
import './Style.scss'

const Loader = () => {
    return (
        <div>
            <Modal visible={true} footer={null} closable={false} keyboard={false} width={250}>
                <div class="cubes">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                    <div class="sk-cube sk-cube3"></div>
                    <div class="sk-cube sk-cube4"></div>
                    <div class="sk-cube sk-cube5"></div>
                    <div class="sk-cube sk-cube6"></div>
                    <div class="sk-cube sk-cube7"></div>
                    <div class="sk-cube sk-cube8"></div>
                    <div class="sk-cube sk-cube9"></div>
                </div>
                <p className='loaderWait'>Loading...</p>
            </Modal>
           
        </div>
    )
}

export default Loader

import React,{useState,useEffect} from 'react';
import {Table, Form, Input, Drawer,Typography,Popconfirm,Row,Col,message,Select,Button } from 'antd';
import {DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined} from '@ant-design/icons';
import {zerodha_account_open_crm,zerodha_account_open_crm_post,respo_team_member_names} from '../Api'
import '../../UserManagement.scss'
import Loader from '../../../../../shared/Loader';
import {family_members} from '../../../../FinancialProfile/Api'
import { logDOM } from '@testing-library/react';

const EditableCell=({editing,dataIndex,title,record,index,children,...restProps})=>{
  return (
    <td {...restProps}>
      {editing?
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}>
            <Input/>
        </Form.Item>
      :children}
    </td>
  );
};

const AccountOpening = (props) => {
  const{cusName,customer_id,cusEmail, close, tabName} = props
  const [data1,setData1] = useState([]) 
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [familyDropDown, setFamilyDropDown] = useState('');
  const [teamDropDown, setTeamDropDown] = useState('');

  const { Option } = Select;
  
  const getZerodhaDetail = async () =>{
    setLoading(true)
    const input = {
      access_token:localStorage.token,
      customer_id:585
    }
    let newdata = []
    zerodha_account_open_crm(input).then(res => {
      console.log("crm",res)
      try{
        if (res.status==='successfull'){
          Object.keys(res.result).map((val)=>{
            newdata.push({
              key: 585,
              created_date:res.result[val].created_date,
              account_holder_name:res.result[val].account_holder_name,
              resp_team_member:res.result[val].resp_team_member_name,
              family_member:res.result[val].family_member_id,
              status:res.result[val].status,
              remark:res.result[val].remarks,
              updated_date:res.result[val].updated_date,
              attachment:res.result[val].attachment
            })
          })
          setData1(newdata)
           setLoading(false)
        }else{ setLoading(false)}
      }catch{ setLoading(false)}
    }) 
  }

  const getFamilyMember=()=>{
    const input = {
      access_token:localStorage.token,
      id:customer_id
    }
    family_members(input).then(res => {
      console.log('rrelation',res)
      try{
      if (res.status==='successfull'){setFamilyDropDown(res.result)}
      else{console.log(res)}
      }catch{}
      })
  }
  const getTeamMember=()=>{
    const input = {
      access_token:localStorage.token,
      id:customer_id
    }
    respo_team_member_names(input).then(res => {
      console.log('team',res)
      try{
      if (res.status==='successfull'){setTeamDropDown(res.result)}
      else{console.log(res)}
      }catch{}
      })
  }

  useEffect(()=>{
    getZerodhaDetail()
    getFamilyMember()
    getTeamMember()
  },[])

  const onFinish = (values) => {
    setLoading(true)
    const input = {
        access_token:localStorage.token,
        customer_id:585,
        family_member:values.family_member,
        account_holder_name:values.account_holder_name,
        resp_team_member:values.resp_team_member,
        status:values.status,
        remark:values.remark,
        reminder:values.reminder,
        attachment:values.attachment
      }
      console.log(input);
      zerodha_account_open_crm_post(input).then(res => {
        console.log(res);
        try{
          if (res.status==='successfull') {
            getZerodhaDetail()
            setLoading(false)
            message.success(res.message);
          }
          else{ 
            setLoading(false)
            message.error(res.message);
          }
            
        }catch{
          setLoading(false)
          message.error('Something went Wrong');
        }
        
        })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({name: '',age: '',address: '',...record});
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const openDrw=()=>{
    setVisible(true)
  }
  const onClose = () => {
      setVisible(false);
    };
  const save = async (key) => {
    setLoading(true)
    try {
      const row = await form.validateFields();
      const newData = [...data1];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData1(newData);
        setEditingKey('');
        const input = {
          access_token:localStorage.token,
          row:newData[index],
        }
        console.log(input)
        zerodha_account_open_crm(input).then(res => {
          console.log(res);
          try{
            if (res && res.status==='successfull'){
              getZerodhaDetail()
              getFamilyMember()
              setLoading(false);message.success(res.message);
            }
            else{ 
              setLoading(false);message.error(res.message);}
          }catch{setLoading(false);message.error('Something went Wrong');}
        })
      }else {
        newData.push(row);
        setData1(newData);
        setEditingKey('');

      }
    } catch (errInfo) {
      setLoading(false)
      console.log('Validate Failed:', errInfo);
    }
  };

  const onDelete = async(zerodha_id)=>{
    setLoading(true)
    const input = {
      access_token:localStorage.token,
      zerodha_id:zerodha_id,
      id:customer_id

    }
    console.log(input)
    zerodha_account_open_crm(input).then(res => {
    console.log(res);
      try{
        if (res && res.status==='successfull'){
          getZerodhaDetail()
          getFamilyMember();
          setLoading(false);message.success(res.message)}
        else{setLoading(false); message.error(res.message)}
      }catch{setLoading(false); message.error('Something went Wrong')}
    }) 
}
  
const formItemLayout = {
  labelCol: {span: 24},
  wrapperCol: {span: 24}
};  
const columns = [
    {title: 'Created date',dataIndex: 'created_date',width: 100},
    {title: 'Family Member ',dataIndex: 'family_member',width: 100},
    {title: 'Account holder name',dataIndex: 'account_holder_name',width: 100,editable: true},
    {title: 'Resp team member',dataIndex: 'resp_team_member',width: 100,editable: true},
    {title: ' Status',dataIndex: 'status',width: 80,editable: true},
    {title: ' Updated date',dataIndex: 'updated_date',width: 80,editable: true},
    {title: ' Remarks',dataIndex: 'remarks',width: 80,editable: true},
    {title: ' Reminder',dataIndex: 'reminder',width: 80,editable: true},
    {title: ' Attachment',dataIndex: 'attachment',width: 80,editable: true},
    
    
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: 120,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() =>save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <span className='outlineSaveButton'>
                <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
              </span>
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
            </Popconfirm>
          </span>
        ) : (
          <span>
          <Typography.Link disabled={editingKey !== ''}
            onClick={() => edit(record)}>
            <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
          </Typography.Link>
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record.zerodha_id)}>
           <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
          </Popconfirm>
          </span>
        );
      },
    },
  ];


  const mergedColumns = columns.map((col) => {
    if (!col.editable) {return col;}
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 14,
    },
  };
  return (
    <>
      {loading?<Loader/>:null}
        <div className='modalBodyBar'>
          <Form form={form} component={false}>
            <Table
              components={{body: {cell: EditableCell}}}
              dataSource={data1}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={false}
              size='small'
              />
          </Form>
          <div className='addBtnDrw'><button onClick={openDrw}>Add Details + </button></div>
          <Drawer placement="bottom" width={'30%'} onClose={onClose} visible={visible}>
          <div className='formDrawrDiv'>
          <Form
              {...formItemLayout}
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              >
              <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item
                          name="family_member"
                          label="Family Member"
                          rules={[{required: true}]}>
                          <Select placeholder='select family member'>
                            {familyDropDown?
                              <> 
                                {Object.keys(familyDropDown).map((member)=>{
                                      return <Option value={member}>{familyDropDown[member]}</Option>
                                      })}
                              </>:null}
                          </Select>
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                    <Form.Item
                          name="account_holder_name"
                          label="Account Holder Name"
                          rules={[{required: true}]}>
                                <Input placeholder="type here.."/>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                          name="resp_team_member"
                          label="Responsible Team Member"
                          rules={[{required: true}]}>
                           <Select placeholder='select team member'>
                            {teamDropDown?
                              <> 
                                {Object.keys(teamDropDown).map((member)=>{
                                      return <Option value={member}>{teamDropDown[member]}</Option>
                                      })}
                              </>:null}
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                          name="status"
                          label="Status"
                          rules={[{required: true}]}>
                           <Select placeholder='select team member'>
                            <Option value='Open'>Open</Option>
                            <Option value='Awaiting client response'>Awaiting client response</Option>
                            <Option value='WIP'>WIP,</Option>
                            <Option value='Awaiting Zerodha response'>Awaiting Zerodha response</Option>
                            <Option value='Active'>Active</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                          name="remark"
                          label="Remark">
                            <Input placeholder="type here.."/>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                          name="reminder"
                          label="Reminder">
                             <Input placeholder="type here.." />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                          name="attachment"
                          label="Attachment">
                             <Input placeholder="type here.." />
                      </Form.Item>
                    </Col>
              </Row>
              <Form.Item style={{textAlign:'right'}}>
                  <Button className='submitButtonsmall' htmlType="submit" >Submit</Button>
              </Form.Item>
          </Form>
          </div>
          </Drawer>        
        </div>
    </>
  )
};

export default AccountOpening;


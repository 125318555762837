import React,{useState,useEffect} from 'react'
import '../Update/Update.scss'
import {Input,Button,Form,Modal, Row,Divider, Col,Table,Popconfirm,DatePicker, Space,Dropdown,Option, Select} from 'antd';
import {EditFilled,DeleteFilled,ClockCircleFilled } from '@ant-design/icons';
import {add_admin_notes,getNotes,notes_data_delete,notes_data_update,email_admin_notes,get_email_admin_notes,delete_email_admin_notes} from '../Api'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../shared/Loader'


const LeadNotes = (props) => {
    
    const [data, setData] = useState([]);
    const [mailData, setmailData]= useState([])
    const [editKey, setEditKey] = useState('')
    const [editNote, setEditNote] = useState('')
    const [remDate, setRemDate] = useState('')
    const [frequency, setFrequency] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const[loading,setLoading] = useState(false)
    const{cusName,customer_id,cusEmail, close, tabName} = props
    const [form] = Form.useForm();
    // const[dates,setDates]= useState(moment(new Date()))
    const [cDate, setDate] = useState(new Date().toISOString().split('T')[0]);
    const { TextArea } = Input;

  const toastMsg =(id,msg,type)=>{
    toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
  }

  const fetchComment = async ()=>{
    setLoading(true)
    let newdata = []
    const input = {
      access_token:localStorage.token,
      id:props.customer_id,
      notes_reference:props.notes_reference
    }
    const res = await getNotes(input)
    console.log(res);
    try{
        if (res.status==='successfull') {
          Object.keys(res.result).map((val)=>{
            newdata.push({ 
            key: res.result[val].key,
            note: res.result[val].note,
            post_datetime: res.result[val].post_datetime,
            })
        })
        setLoading(false)
        setData(newdata)
        }

        else{
          // alert('snc')
          setLoading(false)
          setData(newdata)
        }
    }catch{
      setLoading(false)
      toast.error('Something went wrong')
    }
  }
  useEffect (()=>{
    fetchComment()
  },[])
  
  const handleModal = (key,note) => {
    setEditKey(key)
    setEditNote(note)
    setIsModalVisible(!isModalVisible);};

    const onChange = (date, dateString) => {
      setDate(dateString);
    };

    const onChangeSelect = (value) => {
      // console.log(`selected ${value}`);
      setFrequency(value)
    };

    const closeReminder = ()=>{
      setIsModal(!isModal)
    }

  const handleReminder= async (key,note,post_datetime) =>{
    // console.log(key);
    setRemDate(post_datetime)
    setEditKey(key)
    setEditNote(note)
    setIsModal(!isModal)
    setLoading(true)
      let newdata2 = []
      const input = {
        access_token:localStorage.token,
        notes_id:key
        
      }
      console.log(input);
      const res = await get_email_admin_notes(input)
      console.log(res);
      try{
          if (res.status==='successfull') {
            Object.keys(res.result).map((val)=>{
              newdata2.push({ 
              key: res.result[val].key,
              send_to: res.result[val].send_to,
              responding_date: res.result[val].responding_date,
              })
          })
          setLoading(false)
          setmailData(newdata2)
          }
  
          else{
            setmailData(newdata2)
            setLoading(false)
            // setmailData(mailData)
          }
      }catch{
        setLoading(false)
        toast.error('Something went wrong')
      }
  }

  const onSubmitComment = async (values) =>{
    setLoading(true)
    const input = {
      access_token:localStorage.token,
      comment: values.comment,
      id:props.customer_id,
      email:localStorage.email,
      notes_reference:props.notes_reference

    }
    // console.log(input);
     const res = await add_admin_notes(input)
    try{
        if (res.status==='successfull') {
          toast.success(res.message)
          form.resetFields();
          fetchComment()
          setLoading(false)
        }
        else{
          toast.error(res.message)
          setLoading(false)
        }
    }catch{
      toast.error('Something went wrong')
      setLoading(false)
    }
  }

  const onSave = async (value) =>{
    // setLoading(true)
    console.log(data);
    const input = {
      access_token:localStorage.token,
      comment:value.note,
      id:props.customer_id,
      key:editKey,

    }
    console.log(input);
     const res = await notes_data_update(input)
    try{
        if (res.status==='successfull') {
            console.log(res);
          toast.success(res.message)
          fetchComment()
          setIsModalVisible(!isModalVisible);
          
        }
        else{
          toast.error(res.message)
        //   setLoading(false)
        }
    }catch{
      toast.error('Something went wrong')
    //   setLoading(false)
    }
  }

  const onDelete = async(key)=>{
    setLoading(true)
  const input = {
    access_token:localStorage.token,
    key:key
  }
  console.log(input)
  notes_data_delete(input).then(res => {
  console.log(res);
  try{
    if (res && res.status==='successfull'){
      // setLoading(false)
      fetchComment()
      toast.success(res.message)
    }
    else{
      setLoading(false)
      toast.error(res.message)
    }
  }catch{
    setLoading(false)
    toast.error('Something went Wrong')
  }
}) 
}




const onReminder = async (value) =>{
  // setLoading(true)
  console.log(data);
  const input = {
    access_token:localStorage.token,
    email:value.email,
    key:editKey,
    id:props.customer_id,
    comment:editNote,
    // frequency:frequency,
    responding_datetime:cDate,
    // intervals:value.intervals
  }
  console.log(input);
   const res = await email_admin_notes(input)
  try{
      if (res.status==='successfull') {
          console.log(res);
        toast.success(res.message)
        fetchComment()
        setIsModal(!isModal)
        
      }
      else{
        toast.error(res.message)
      //   setLoading(false)
      }
  }catch{
    toast.error('Something went wrong')
  //   setLoading(false)
  }
}


    const onDeletemail = async(key)=>{
      console.log(key);
      setLoading(true)
      const input = {
        access_token:localStorage.token,
        key:key
      }
      console.log(input)
      delete_email_admin_notes(input).then(res => {
      console.log(res);
      try{
        if (res && res.status==='successfull'){
          // setLoading(false)
          handleReminder()
          toast.success(res.message)
        }
        else{
          setLoading(false)
          toast.error(res.message)
        }
      }catch{
        setLoading(false)
        toast.error('Something went Wrong')
      }
      }) 
    }

const columns = [
  {title: 'Email',dataIndex: 'send_to',width: 200},
  {title: 'Responding Date',dataIndex: 'responding_date',width: 150},
  {title: 'Action',dataIndex: 'action',width: 50,
  render:(_,record)=>{
    console.log(record);
    return(
        <>
        <Popconfirm title="Sure to delete?" onConfirm={()=>onDeletemail(record.key)}>
          <DeleteFilled style={{ fontSize: '13px', color: '#FF0000', marginRight:'10px' }} />
        </Popconfirm>
        </>
      )
  }}
]

  return (
    <>
    {loading?<Loader/>:null}
    <div className='modalHeadBar'>
        <Row align='middle'>
          <Col span={6}>
            <div className='modalHeadBarTitle'>{tabName}</div>
          </Col>
          <Col span={12}>
            <div className='modalHeadBarTab'>
              
            </div>
          </Col>
          <Col span={5}>
            <div className='modalHeadBarTitle'>{cusName}</div>
            <p className='modalHeadBarP' style={{fontSize:'14px'}}>{cusEmail}</p>
          </Col>
          <Col span={1}>
            <div className='TableActionButtonClose' onClick={close}>Close</div>
          </Col>
        </Row>
      </div>
       <ToastContainer theme='colored' limit={1}/>
       <div className='modalBodyBar'>

       <Row gutter={16}>
       <Col ></Col>
        <Col span={13}>
        {Object.keys(data).length>0?
        <div className='commentListBox scrollB getNotesDiv' >
          {Object.keys(data).map((val)=>{
            return(
              <>
                <div className='commentBoxTagLine'>
                  {data[val].post_datetime} - Created By {localStorage.email}
                  {/* <span className='sendemailspan'>Send to <b>sohilraieen@gmail.com</b></span> */}
                  <span className='notesIcon'>
                  {/* <span className='sendemailspan'><Button onClick={() =>handleAction(data[val].key)} className="ActionButton">Action</Button></span> */}
                    <span> <EditFilled onClick={()=>handleModal(data[val].key,data[val].note)} style={{ fontSize: '13px', color: '#5DADE2', marginRight:'10px' }} /></span>
                    <span> 
                    <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(data[val].key)}>
                    <DeleteFilled style={{ fontSize: '13px', color: '#FF0000', marginRight:'10px' }} />
                    </Popconfirm></span>
                    <span> <ClockCircleFilled onClick={() =>handleReminder(data[val].key,data[val].note,data[val].post_datetime)} style={{ fontSize: '13px', color: '#008E13', marginRight:'10px' }} /></span>
                  </span>
                </div>
                 
                <div className='commentListText'>{data[val].note}</div>
                <Divider/>
              </>
            )
          })}
        </div>:<div className='noDataHead'>No Data Here</div>}
        </Col>
        <Col span={10}>
          <Form onFinish={onSubmitComment} form={form}>
            <Form.Item
              name="comment"
              rules={[{ required: true, message: 'Please input comment!' }]}>
              <TextArea rows={7} maxLength={1000} allowClear={true} showCount={true}/>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" className="submitButton" >Submit</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      </div>
      {isModalVisible? <>

      <Modal  footer={null} width={800} visible={true} onCancel={handleModal} >
      <Form onFinish={onSave}>
          <Form.Item
          
            name="note"
            rules={[{ required: true, message: 'Please input comment!' }]}>
            <TextArea className='divEdit' defaultValue={editNote} rows={7} maxLength={1000} allowClear={true} showCount={true}/>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="submitButton">Submit</Button>
          </Form.Item>
        </Form>
        
         
      </Modal>
      </>:null}
      {isModal?<>
      <Modal footer={null} visible={true} className='modelReminder' width={700} onCancel={closeReminder} >
        <Form onFinish={onReminder}>
          <Form.Item  name="email">
            <Input className='divReminder' allowClear  placeholder={localStorage.email} size='large'/>
          </Form.Item>
          <Form.Item >
          <Row>
          <Col span={6}>
            <DatePicker onChange={onChange} showToday={false} placeholder={cDate} disabledDate={(current) => {
            return current && current.valueOf() < Date.now();
            }}/>
            </Col>
            <Col span={8}>
                {/* <Select
                    showSearch
                    placeholder="Select a frequency"
                    optionFilterProp="children"
                    style={{ width: 200 }}
                    onChange={onChangeSelect}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: '3',
                        label: '3 Times',
                      },
                      {
                        value: '5',
                        label: '5 Times',
                      },
                      {
                        value: '7',
                        label: '7 Times',
                      },
                      {
                        value: '15',
                        label: '15 Times',
                      },
                    ]}
                  /> */}
                </Col>
                <Col span={6}>
                  {/* <Form.Item  name="intervals">
                    <Input classNames=''  allowClear  placeholder='Intervals' size='medium'/>
                  </Form.Item> */}
                  </Col>
                  <Col span={4}>
            <span className='notesIconButton'><Button htmlType="submit" className="submitButton" >Submit</Button></span>
            </Col>
             </Row></Form.Item>
        </Form>
        <Table
              rowClassName="editable-row"
              columns={columns}
              dataSource={mailData}
              size='medium'
              scroll={{y: 500 }}
              // pagination={{
              //   pageSize: 10,
              //   position:['bottomCenter']
              // }}
            pagination={false}
              sticky={true}
              // exportableProps={{ fileName: `MFHoldings_${props.email_address}_${date}`}}
          />
        
      </Modal></>:null}
    </>
    
  )
}

export default LeadNotes
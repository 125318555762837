import axios from 'axios'

export const logout = () => {
    return axios
        .post('/alpha_user_setup_journey/logout')
        .then(response => {
            return response.data
        })
        .catch(err => {})
}

export const user_profile_status = input => {
    return axios
        .post('/alpha_user_setup_journey/user_profile_status', {
            access_token:input.access_token,
            id:input.id
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          }
          )
        .then(response => {
            return response.data
        })

        .catch(err => {})
}
export const profile_picture = input => {
    return axios
        .post('/alpha_user_setup_journey/profile_pic', {
            access_token:input.access_token,
            profile_pic:input.profile_pic
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          }
          )
        .then(response => {
            return response.data
        })

        .catch(err => {})
}
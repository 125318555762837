import React,{useState,useEffect} from 'react'
import {customer_review} from '../../../Admin/Api'
import { review_sheet } from '../Api';
import { Select,DatePicker,Table} from 'antd';
import Loader from '../../../../shared/Loader';
// import Momequityreporttable from './Momequityreporttable';
import moment from 'moment'

const Review_download = () => {

    const [cdata,setcData] =useState([])
    const [loading,setLoading] = useState(false)
    const [clientId,setClientId] = useState('')
    const [dateSend, setdateSend] = useState([])
    const [pickerValue, setPickerValue] = useState(moment(new Date()));
    const [cDate, setDate] = useState(new Date().toISOString().split('T')[0]);
    

    const { Option } = Select;
    let pdf1=null

    const fetchClient = ()=>{
        const input = {
            access_token:localStorage.token
        }
        let newdata = [...cdata]
        customer_review(input).then(res => {
            console.log(res,'ressCLI')
        try{
            if (res.status==='successfull'){
                Object.keys(res.result).map((val)=>{
                    newdata.push({
                    key: res.result[val].customer_id,
                    customer_fullname:res.result[val].customer_fullname,
                    id: res.result[val].customer_id,
                    email_address:res.result[val].email_address,
                    zerodha:res.result[val].zerodha
                })
                })
                setcData(newdata)
            }else{}
        }catch{}
        })
    }
    

    
    useEffect(()=>{
        fetchClient()
      },[])    
      

      const updateclientID=(value)=>{     
        setClientId(value)
    }
    const onChange = (date, dateString) => {
        setdateSend(dateString)
      };


      const download=()=>{
        const input = {
            access_token:localStorage.token,
            id:clientId,
            start_date:dateSend
        }
        console.log(input);
       review_sheet(input).then(res => {
            console.log(input);
            console.log("resss",res)
            try {
              if (res.status==='successfull') {
                pdf1=res.result
                const linkSource = `data:application/vnd.ms-excel;base64,${pdf1}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = res.message;
                downloadLink.click();
            }
                else{}
            } catch (error) {}
            })
    }


  return (
    <div>
     {loading?<Loader/>:null}
      <Select
        style={{width:'40%'}}
        showSearch
        bordered={true}
        title='Client Mail ID'
        className='selectborder'
        placeholder="Select Client Mail ID"
        optionFilterProp="children"
        onChange={updateclientID}
        filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        // size='large'
        >
        {Object.keys(cdata).map((val)=>{
            return <Option value={cdata[val].id}>{cdata[val].email_address}, <b>{cdata[val].customer_fullname}</b></Option>
            })}
      </Select>
      <span style={{marginLeft:'10px'}}>
            <DatePicker onChange={onChange} placeholder={['Start Date']}
             disabledDate={(current) => {
             return current && current.valueOf() > Date.now();
             }} />
          <button className='reportBtn' onClick={download}>Download</button>
          <div className='PagesCommentIconDiv'>
            <span className='valueInRs'>value in (₹)</span>
          </div>
      </span>
    </div>
  )
}

export default Review_download

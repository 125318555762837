import axios from 'axios'

export const getComments = input => {
  return axios
      .get('/alpha_user_db/comments',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}

export const handleComments = input => {
    return axios
        .post('/alpha_user_db/comments', {
            comment:input.comment
        },
        {
          headers: {
            'Authorization': input.access_token
          }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const comments_chat = input => {
    return axios
        .get('/alpha_user_db/comments_chat',
        {
            headers: {'Authorization': input.access_token},
            params: {
              comment_id:input.comment_id,
              customer_id:input.customer_id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const handleReply = input => {
    return axios
        .post('/alpha_user_db/comments_chat', {
            comment:input.comment,
            comment_id:input.comment_id,
            customer_id:input.customer_id
        },
        {
          headers: {
            'Authorization': input.access_token
          }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  

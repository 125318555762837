import React from 'react'
import './App.scss'
import ScrollTop from './ScrollTop';

import Webinar from './Component/Website/Webinar/Webinar';
import WebinarAdmin from './Component/Website/Webinar/WebAdmin';
import ThankYou from './Component/Website/Webinar/ThankYou';
import Home from './Component/Website/Home/Home';
import WhatWeDo from './Component/Website/whatwedo/WhatWeDo'
import WhyUs from './Component/Website/whyUs/WhyUs'
import Blog from './Component/Website/Blog/Blog';
import SingleBlog from './Component/Website/Blog/SingleBlog'
import Faq from './Component/Website/faq/Faq'
import ContactUs from './Component/Website/ContactUs/ContactUs'
import PrivacyPolicy from './Component/Website/Universal/PrivacyPolicy';
import Disclosures from './Component/Website/Universal/Disclosures'
import Login from './Component/Website/login/Login';
import Signup from './Component/Website/login/Signup';
import ForgotPassword from './Component/Website/login/ForgotPassword';
import PasswordResetValidator from './Component/Website/login/PasswordResetValidator';
import Verification from './Component/Website/login/Verification';
import SetupJourney from './Component/Setup-journey/SS'
import Dashboard from './Component/Dashboard/Dashboard'
import EmailRecommendation from './Component/RedirectPages/Email_recommendation'
import Authentication from './Component/RedirectPages/Authentication';
import Admin from './Component/Admin/newMainPage/NewMainPage'
import Notfound from './Component/Website/Universal/NotFound'
import Calculator from './Component/Website/Calculator/Calculator';
import Cheatsheet from './Component/Website/CheatSheet/Cheatsheet';
import Thanks from './Component/Website/CheatSheet/ThankYou';

import { BrowserRouter as Router, Route, Routes,Navigate} from 'react-router-dom'

const App = () => {

  return (
    <Router>
      <ScrollTop />
        <Routes>
          <Route exact path='/webinar' element={< Webinar />}></Route>
          <Route exact path='/webinar_admin' element={< WebinarAdmin/>}></Route>
          <Route exact path='/thank_you' element={< ThankYou />}></Route>
          <Route exact path='/home' element={< Home />}></Route>
          <Route exact path='/retirement_calculator' element={< Calculator />}></Route>
          <Route exact path='/what_we_do' element={< WhatWeDo />}></Route>
          <Route  exact path="/why_us" element={<WhyUs/>}></Route>
          <Route  exact path="/faq" element={<Faq/>}></Route>
          <Route  exact path="/blog" element={<Blog/>}></Route>
          <Route  exact path="/blog/:id" element={<SingleBlog/>}></Route>
          <Route  exact path="/contact_us" element={<ContactUs/>}></Route>
          <Route  exact path="/privacy_policy" element={<PrivacyPolicy/>}></Route>
          <Route  exact path="/disclosures" element={<Disclosures/>}></Route>
          <Route exact path='/verification' element={< Verification />}></Route>
          <Route  exact path="/login" element={<Login/>}></Route>
          <Route  exact path="/signup" element={<Signup/>}></Route>
          <Route  exact path="/forgotpassword" element={<ForgotPassword/>}></Route>
          <Route  exact path="/password_reset/:token" element={<PasswordResetValidator/>}></Route>
          <Route  exact path="/setup_journey" element={<SetupJourney/>}></Route>
          <Route  exact path="/dashboard" element={<Dashboard/>}></Route>
          <Route  exact path="/recommendation/:token" element={<EmailRecommendation/>}></Route>
          <Route  exact path="/zerodha_authentication" element={<Authentication/>}></Route>
          <Route  exact path="/super_admin" element={<Admin/>}></Route>
          <Route  exact path="/admin" element={<Admin/>}></Route>
          <Route  exact path="/admin/:role" element={<Admin/>}></Route>
          <Route  exact path="/not_found" element={<Notfound/>}></Route>
          <Route  exact path="/powerofpassiveincome" element={<Cheatsheet/>}></Route>
          <Route  exact path="/powerofpassiveincome/thank_uou" element={<Thanks/>}></Route>
          
          <Route path="*" element={<Navigate to="/home" replace />}></Route>          
        </Routes>
      </Router>

 

   
  )
}

export default App
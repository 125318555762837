import React,{useState,useEffect} from 'react';
import {asset_irr_get,asset_irr_update} from '../Api'
import { Row,Col,Table,Popconfirm, Typography,Form,Input,InputNumber,Select,Option} from 'antd';
import { EditFilled,CloseCircleFilled,SaveOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import Loader from '../../../shared/Loader';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AssetIrr = (props) => {

  const { Option } = Select;

    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [loading,setLoading] = useState(false)

    const toastMsg =(id,msg,type)=>{
        toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
      }




      const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        disable,
        ...restProps
      }) => {


        const inputNode = title === 'Realisbale' ? <Select> 
                                                <Option value="Liquidable">Liquidable</Option>
                                                <Option value="Non Liquidable">Non Liquidable</Option>
                                              </Select>:
                                              title ==='Interest Rate'?<Input/>:null

        return (
          <td {...restProps}>
      {editing? (
        
        <Form.Item
          name={dataIndex}
          rules={[{required: true,message: `Please Input ${title}!`}]}
        >{inputNode}
         {/* <Input /> */}
        </Form.Item>
        
      ) : (
        children
      )}
    </td>
  );
};


const isEditing = (record) => record.key === editingKey;
const edit = (record) => {
    form.setFieldsValue({
        
    ...record,
    });
    console.log(record);
    setEditingKey(record.key);
};
const cancel = () => {
    setEditingKey('');
};


    const getAssetList= async () =>{
        let newdata = []
          const input = {access_token:localStorage.token}
          const res = await asset_irr_get(input)
          console.log('assetirr',res);
          try{
            if (res && res.status==='successfull'){ 
                Object.keys(res.result).map((val)=>{
                    newdata.push({
                        key: val,
                        asset_class:res.result[val].asset_class,
                        asset_type:res.result[val].asset_type,
                        realisable:res.result[val].realisable,
                        interest_rate:res.result[val].interest_rate
                    })
                })
              setData(newdata)
              setLoading(false)
            }
            else{setLoading(false); toast.error(res.message)}
          }catch{setLoading(false); toast.error('Something went wrong')}
        }
        useEffect(()=>{
            getAssetList()
        },[])

        const save = async (key) => {
            
            const id = toast.loading("Please wait...",{toastId:'load'})
                try {
            const row = await form.validateFields();
            console.log(row);
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
                if (index > -1) {
                  const item = newData[index];
                  newData.splice(index, 1, { ...item, ...row });
                    setData(newData);
                    setEditingKey('');
                  const input = {
                    access_token:localStorage.token,
                    row:newData[index],
                  }
                  console.log(input);
                  asset_irr_update(input).then(res => {
                      console.log(res);
                      try{
                        if (res && res.status==='successfull'){
                            getAssetList();
                            toastMsg(id,res.message,'success')
                          }
                        else{toastMsg(id,res.message,'error');}
                      }catch{toastMsg(id,'Something went wrong','error')}
                    })
                  } else {
                    newData.push(row);
                    setData(newData);
                    setEditingKey('');
                    setLoading(false)
                  }
                } catch (errInfo) {
                  console.log('Validate Failed:', errInfo);
                  setLoading(false)
                }
              };


         

        const columns = [
            {
              title: 'SR. No.',
              dataIndex: 'key',
              width: 40,
            },
            {
                title: 'Asset Class',
                dataIndex: 'asset_class',
                width: 140,
            },
            {
                title: 'Asset Type',
                dataIndex: 'asset_type',
                width: 100,
              },
              {
                title: 'Realisbale',
                dataIndex: 'realisable',
                width: 80,
                editable: true,
              },
              {
                title: 'Interest Rate',
                dataIndex: 'interest_rate',
                width: 80,
                editable: true,
              },             
              {
                title: 'Operation',
                dataIndex: 'operation',
                fixed: 'right',
                width: 80,
                render: (_, record) => {
                    const editable = isEditing(record);
               
      return editable ? (
        <span>
        <Typography.Link
          onClick={() => save(record.key)}
          style={{
            marginRight: 8,
          }}
        >
          <span className='outlineSaveButton'>
            <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
          </span>
        </Typography.Link>
        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
        </Popconfirm>
      </span>
    ) : (
      <span>
      <Typography.Link disabled={editingKey !== ''}
        onClick={() => edit(record)}>
        <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
      </Typography.Link>
      {/* <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record.key)}>
       <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
      </Popconfirm> */}
      </span>
            )
                 },
              }, 
            ];

        
            const mergedColumns = columns.map((col) => {
                if (!col.editable) {
                  return col;
                }
                return {
                    
                  ...col,
                  onCell: (record) => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                  }),
                };
              });
            
            
  return (
    <div>
        <ToastContainer theme='colored'/>
        {loading?<Loader/>:null}
        <Row>
            <Form form={form}  component={false}>
                <Col span={24}>
                    <Table
                        components={{body: {cell: EditableCell}}}
                        rowClassName="editable-row"
                        columns={mergedColumns}
                        dataSource={data}
                        size='small'
                        scroll={{y: 500 }}
                        sticky={true}
                        pagination={false}
                    />
                </Col>
            </Form>
        </Row>
    </div>
  )
}

export default AssetIrr
import React,{useState,useEffect} from 'react'
import './Loans.scss'
import Tables from './Tables';

import { Row, Col} from 'antd';
import Outstanding from '../../../Images/outstanding.svg'
import Emi from '../../../Images/emi.svg'
import LoanEnd from '../../../Images/loanEnd.svg'
import {loan_breakup} from './Api'

const Loans = (props) => {
    const [cardData, setCardData] = useState([]);
    const [tableData, setTableData] = useState({});

    const numberFormat=(value)=>{
        var val = Math.abs(value)
        if (val >= 10000000) {
          val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
          val = (val / 100000).toFixed(2) + ' L';
        }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
        return val;
      }

    const fetchLoan = async () =>{
        const input = {access_token:localStorage.token,id:props.id}
        const res = await loan_breakup(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('loan',res);
        try{
          if (res.status==='successfull'){
            setCardData(res.result.total)
            setTableData(res.result.breakup)
          }else{}
        }catch{}
      }
      useEffect(()=>{
        fetchLoan()
      },[])

    const StatsCard = (props)=>{
        const {name,amount} = props
        return(
        <>
            <div className='statisticCard'>
                <Row>
                    <Col span={10}>
                      {/* <Outstanding className='statisticCardImg'/> */}
                        <img src={name==='OUTSTANDING'?Outstanding:name==='TOTAL EMI'?Emi:name==='LOAN ENDS'?LoanEnd:Outstanding} alt="" className='statisticCardImg'/>
                    </Col>
                    <Col span={14}>
                        <div className='statisticCardValue'>
                            <small>{name}</small>
                            <div>{name==='LOAN ENDS'?<b>{amount}</b>:<b>{numberFormat(amount)}</b>}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
        )
    }
  return (
    <>
    {Object.keys(cardData).length>0?
      <>
        <Row gutter={[20, 16]}>
            {Object.keys(cardData).map((val)=>{
                return(
                    <Col span={5}>
                        <StatsCard name={val} amount={cardData[val]}/>
                    </Col>
                )
            })}
        </Row>
        {Object.keys(tableData).length>0?<Tables tableData={tableData}/>:null}
      </>:
      <div className='noDataHead'>No Loans availed</div>}
    </>
  )
}

export default React.memo(Loans)
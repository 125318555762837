import React, { Component } from 'react'
import Moment from 'moment';
import {admin_goal_complete,admin_goal_complete_post} from '../../Api'
import {MDBIcon} from 'mdbreact'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../../shared/Loader'

export default class Goal_complete extends Component {
    constructor(props){
        super(props);
        this.state={
            goal_dict:{},
            loading:false,
            success:false,
            error:false,
            errormsg:'',
            successmsg:'',
        }
    }
componentDidMount(){
    this.setState({loading:true})
    const input = {
        access_token:localStorage.token,
        customer_id:this.props.customer_id,
        date:Moment(this.props.today).format('YYYY-MM-DD')
    }
    admin_goal_complete(input).then(res => {
    try{
    if (res.status==='successfull') {
    this.setState(() => ({
        goal_dict:res.result,
        loading:false
    }))
    }
    else{
        this.setState(() => ({
            loading:false
        }))
    }
    }catch{
        this.setState(() => ({
            loading:false
        }))
    }
    })
}
onchange_predict_date=(e)=>{
    let aa = this.state.goal_dict
    aa[e.target.id]["predicted_date"] = e.target.value
    this.setState({goal_dict:aa})
}
onchange_percent=(e)=>{
    let aa = this.state.goal_dict
    aa[e.target.id]["percentage_complete"] = e.target.value
    this.setState({goal_dict:aa})
}
onchange_amount=(e)=>{
    let aa = this.state.goal_dict
    aa[e.target.id]["amount_save"] = e.target.value
    this.setState({goal_dict:aa})
  }
submit_goal_complete=()=>{
    this.setState({loading:true})
const input = {
    access_token:localStorage.token,
    customer_id:this.props.customer_id,
    goal_dict_post:this.state.goal_dict,
    date:Moment(this.props.today).format('YYYY-MM-DD')
}
    admin_goal_complete_post(input).then(res => {
    try{
    if (res.status==='successfull') {
        this.setState({
            success:true,
            loading:false,
            successmsg:res.message
        })
    }
    else{
        this.setState({
            error:true,
            loading:false,
            errormsg:res.message
        })
    }
    }catch{
        this.setState({
            error:true,
            loading:false,
            errormsg:'Something went wrong'
        })
    }
    })
}
cancel=()=>{
    this.setState({
        success:false,
        error:false
    })
    }
componentDidUpdate(prevProps, prevState) {
    if (prevProps.today !== this.props.today) {
        const input = {
            access_token:localStorage.token,
            customer_id:this.props.customer_id,
            date:Moment(this.props.today).format('YYYY-MM-DD')
        }
        admin_goal_complete(input).then(res => {

        try{
        if (res.status==='successfull') {
        this.setState(() => ({
            goal_dict:res.result,
        }))
        }
        else{}
        }catch{}
        })
    }
    }
    render() {
        return (
            <div>
                   {this.state.loading?(
                <div className='loader-main-div'>
                    <div className='loader-div'>
                        <Loader/>
                    </div>
                </div>
            ):(null)}

            {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancel}>
                {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

            {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
                {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

                <div className='update-table-div'>
                    <table class='table table-condensed'>
                        <thead className='mf-p-table-tr'>
                            <th>Goal</th>
                            <th>Goal target date</th>
                            <th>Predicted Achieve date</th>
                            <th>Percentage Completion</th>
                            <th>Amount saved till now</th>
                            <th>date</th>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.goal_dict).map((val)=>{
                                return(
                                    <tr className='mf-p-table-tr-bg'>
                                        <td>{this.state.goal_dict[val].goal}</td>
                                        <td>{this.state.goal_dict[val].target_date}</td>

                                        <td><input type='date' value={this.state.goal_dict[val].predicted_date} id={val} onChange={this.onchange_predict_date}/></td>

                                        <td><input  value={this.state.goal_dict[val].percentage_complete} id={val} onChange={this.onchange_percent}/></td>

                                        <td><input id={val} value={this.state.goal_dict[val].amount_save} onChange={this.onchange_amount}/></td>

                                        <td>{Moment(this.props.today).format('YYYY-MM-DD')}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='update-sub-btn'><button onClick={this.submit_goal_complete}><MDBIcon icon="save" className='btn-i'/>Save</button></div>

            </div>
        )
    }
}

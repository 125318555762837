// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formContainer {
  background: white;
  width: 100%;
  height: 87vh;
  padding: 20px 20px;
  border: 1px solid #E5E7E9;
}`, "",{"version":3,"sources":["webpack://./src/Component/Admin/userCreation/UserCreation.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;AACJ","sourcesContent":[".formContainer{\n    background: white;\n    width: 100%;\n    height: 87vh;\n    padding: 20px 20px;\n    border: 1px solid #E5E7E9;    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {MDBIcon} from 'mdbreact'

export default class CropRotateImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: "",
      review:false
    };
    this.fileInput = React.createRef();
    this.fileReader = new FileReader();
    this.cropper = React.createRef();
  }
  handleChange(event) {
    if(event.target.files[0].size > 2000000){
      alert("File size should not be more than 2MB");
    }
    else{
    const file = this.fileInput.current.files[0];
    const imageSrc = URL.createObjectURL(event.target.files[0]);

    this.setState({
      imageSrc,
      croppedImgSrc: null
    });
    this.fileReader.onloadend = this.handleFileRead;
    this.fileReader.readAsBinaryString(file);
  }
  }

  handleCropChange() {
    const croppedImgData = this.cropper.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    this.setState({ croppedImgSrc: croppedImgData });

  }

  handleRotate() {
    this.cropper.current.cropper.rotate(90);
    this.handleCropChange();
  }
  savePreview=()=>{
    const croppedImgData = this.cropper.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    this.setState({ croppedImgSrc: croppedImgData });
    this.props.sign(croppedImgData)
      this.setState({review:true})
  }
  reset=()=>{
    this.setState({imageSrc:"",review:false})
    this.props.clear_sign()
  }
  render() {
    const {imageSrc, croppedImgSrc } = this.state;

    return (
      <div className="App">
        {!this.state.review?(
           <input type="file" accept="image/*" class="uploadFile" id="upload" ref={this.fileInput} onChange={e => this.handleChange(e)} required/>
        ):null}
       

        <div>
            {imageSrc && !this.state.review?(
              <>
              <div className='croper-div'>
                  <Cropper
                    style={{ maxWidth: "200px", height: "90px" }}
                    ref={this.cropper}
                    src={imageSrc}
                    aspectRatio={12 / 6}
                    cropend={() => this.handleCropChange()}
                  />
                </div>
              <span onClick={() => this.handleRotate()} className='cropeer-btn'>Rotate</span>
              <span className='cropeer-btn' onClick={this.savePreview}>Crop And Review</span>
              </>):null}

            {!imageSrc && !this.state.review?(<div className='preview-text'>Preview</div>):null}
            {this.state.review?(
              <>
                  <div>
                    <img src={croppedImgSrc} alt='' className='crop-img'/>
                    <span style={{cursor:'pointer'}}><MDBIcon icon="times-circle" onClick={this.reset}/></span>
                  </div>
              </>
            ):null}
        </div>
      </div>
    );
  }
}

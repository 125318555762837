import React,{useState,useEffect} from 'react';
import { Table,Input,InputNumber,Popconfirm,Form,Typography,Button,Space,message,Select} from 'antd';
import {admin_review,admin_review_put,admin_review_delete} from './Api'
import Highlighter from 'react-highlight-words';
import { SearchOutlined,DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined} from '@ant-design/icons';
// import './Style.scss'
import Loader from '../../../shared/Loader';


const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  
  const inputNode = inputType === 'number' ? <InputNumber/>: <Input/>
                                              
  return (
    <td {...restProps}>
      {editing? (
        <>
        {inputType==='email'?
        <Form.Item
        name={dataIndex}
        rules={[
        {
            type: 'email',
            message: 'The input is not valid E-mail!',
        },
        {
            required: true,
            message: 'Please input E-mail!',
        },
        ]}
        >
        {inputNode}
        </Form.Item>
        :
        title==='Middle Name'?
         <Form.Item
         name={dataIndex}
        >
              {inputNode}
       </Form.Item>
       :
       <Form.Item
       name={dataIndex}
       rules={[{required: true,message: `Please Input ${title}!`}]}>
            {inputNode}
     </Form.Item>
       }
        </>
      ) : (
        children
      )}
    </td>
  );
};

const OthersUserReview = () => {
  const [searchText,setSearchText] = useState('')
  const [searchedColumn,setSearchedColumn] = useState('')
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [loading,setLoading] = useState(false)

  let searchInput = ''
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

const getAllUsers = async () => {
  setLoading(true)
  let newdata = []
  const input = {access_token:localStorage.token}
  const res = await admin_review(input)
  console.log('res',res);
  try{
    if (res && res.status==='successfull'){ 
        console.log(res);
        Object.keys(res.result).map((val)=>{
            newdata.push({ 
                key: res.result[val].id,
                id : res.result[val].id,
                firstname : res.result[val].firstname,
                middlename : res.result[val].middlename,
                lastname : res.result[val].lastname,
                email_address : res.result[val].email_address,
                mobile_number : res.result[val].mobile_number,
                role : res.result[val].role,
                onboarding_date : res.result[val].onboarding_date,
        })
      })
      setData(newdata)
      setLoading(false)
    }else{setLoading(false)}
  }catch{setLoading(false)}
}
useEffect(()=>{
  getAllUsers()
},[])

let getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => {
                handleReset(clearFilters)
                confirm({ closeDropdown: false });
                setSearchedColumn(dataIndex)
                }} size="small" style={{ width: 90 }}>
                Reset
              </Button>
        
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
  render: text =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0])
  setSearchedColumn(dataIndex)
};

const  handleReset = clearFilters => {
  clearFilters();
  setSearchText('')
};

  const save = async (key) => {
    setLoading(true)
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
        const input = {
          access_token:localStorage.token,
          editadmin:newData[index],
        }
        console.log(input)
        admin_review_put(input).then(res => {
          console.log(res);
          try{
            if (res && res.status==='successfull'){
              setLoading(false)
              message.success(res.message)
              getAllUsers();
            }
            else{
              setLoading(false)
              message.error(res.message)
            }
          }catch{
            setLoading(false)
            message.error('Something went Wrong')
          }
        })
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
        setLoading(false)
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      setLoading(false)
    }
  };

  const onDelete = async(key)=>{
    setLoading(true)
    const input = {
        access_token:localStorage.token,
        id:key
    }
    console.log(input)
    admin_review_delete(input).then(res => {
    console.log(res);
    try{
        if (res && res.status==='successfull'){
        setLoading(false)
        message.success(res.message)
        getAllUsers();
        }
        else{
        setLoading(false)
        message.error(res.message)
        }
    }catch{
        setLoading(false)
        message.error('Something went Wrong')
    }
    }) 
}

  const columns = [
   
    {
      title: 'ID',
      dataIndex: 'id',
      width: 50,
      ...getColumnSearchProps('id'),
    },
    {
      title: 'First Name',
      dataIndex: 'firstname',
      width: 60,
      ...getColumnSearchProps('firstname'),
      editable: true,
    },
    {
        title: 'Middle Name',
        dataIndex: 'middlename',
        width: 60,
        ...getColumnSearchProps('middlename'),
        editable: true,
      },
      {
        title: 'Last Name',
        dataIndex: 'lastname',
        width: 60,
        ...getColumnSearchProps('lastname'),
        editable: true,
      },
    {
      title: 'Email',
      dataIndex: 'email_address',
      width: 120,
      ...getColumnSearchProps('email_address'),
      editable: true,
    },
    {
        title: 'Mobile Number',
        dataIndex: 'mobile_number',
        width: 100,
        ...getColumnSearchProps('mobile_number'),
        editable: true,
    },
    {
        title: 'User Role',
        dataIndex: 'role',
        width: 70,
        ...getColumnSearchProps('role'),
      },
    {
      title: 'Onboarding Date',
      dataIndex: 'onboarding_date',
      width: 90,
      ...getColumnSearchProps('onboarding_date'),
    },
 
    {
      title: 'Operation',
      dataIndex: 'operation',
      fixed: 'right',
      width: 90,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <span className='outlineSaveButton'>
                <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
              </span>
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
            </Popconfirm>
          </span>
        ) : (
          <span>
          <Typography.Link disabled={editingKey !== ''}
            onClick={() =>edit(record)}>
            <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
          </Typography.Link>
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record.key)}>
           <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
          </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'mobile_number' ? 'number' : col.dataIndex==='email_address'? 'email':'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div style={{fontSize:'10px'}}>
      {loading?<Loader/>:null}
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          // bordered={true}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            pageSize: 10,
            onChange: cancel,
            position:['bottomCenter']
          }}
          size='small'
          scroll={{y: 420 }}
          sticky={true}
        />
      </Form>
    </div>
  );
};

export default OthersUserReview
import React, { useState,useEffect} from 'react'
// import './SRFO.scss'
import {Table,Input,Space,Button,message} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { MDBIcon } from 'mdbreact';
import { srfo } from './Api';

const SRFO = (props) => {
    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')
    const [data, setData] = useState([]);

    const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits:0,
        maximumFractionDigits: 2,
        notation: 'compact',
        compactDisplay: 'long',
    }).format(value);

    let searchInput = ''

    // useEffect(()=>{
    //   let arr= []
    //     Object.entries(props.tableData).map((val)=>arr.push(val[1]))
    //     setData(arr)
    // },[])

    const getSrfo = async()=>{
      // setLoading(true)
      let newdata = []
      const input = {access_token:localStorage.token,id:props.id}
      const res = await srfo(input)
      console.log('resrfo',res);
      try{
        if (res && res.status==='successfull'){ 
          Object.entries(res.result).map((val)=>newdata.push(val[1]))
          // setLoading(false)
          setData(newdata)
        }
        else{
          // setLoading(false); message.error(res.message)
        }
      }catch{
        // setLoading(false); 
        message.error('Something went wrong')}
    }
  
    useEffect(() =>{
      getSrfo();
    },[])


    let getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => {
                handleReset(clearFilters)
                confirm({ closeDropdown: false });
                // setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                }} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#e87c1b' : '#000' }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
      };
      const  handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };

      const columns = [
        {title: 'OWNERSHIP',dataIndex: 'ownership',width: 90, ...getColumnSearchProps('ownership')},
        {title: 'INVESTMENT DETAILS',dataIndex: 'investments_details',width: 120,...getColumnSearchProps('investments_details')},
        {title: 'ADDRESS',dataIndex: 'address',width: 120,...getColumnSearchProps('address')},
        {title: 'PHONE',dataIndex: 'contact_no',width: 90,...getColumnSearchProps('contact_no')},
        {title: 'EMAIL',dataIndex: 'email',width: 130,...getColumnSearchProps('email')},
        {title: 'JOINT HOLDER IF ANY',dataIndex: 'joint_holder_if_any',width: 120,...getColumnSearchProps('joint_holder_if_any')},
        {title: 'NOMINEE',dataIndex: 'nominee',width: 120,...getColumnSearchProps('nominee')}
      ];

     
  return (
    <div className='tableContainer'>
        <Table rowClassName="antTable" columns={columns} dataSource={data} size='small'
              pagination={{pageSize: 6,position:['bottomCenter']}} sticky={true}/>
              
    </div>
  )
}

export default SRFO
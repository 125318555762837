import React, { useState,useEffect } from 'react';
import {Form,Input,Select,Row,Col,Button,message} from 'antd';
import './UserCreation.scss'
import {user_create,admin_roles} from '../Api'
import Loader from '../../../shared/Loader';

const UserCreation = (props) => {

  const [form] = Form.useForm();
  const[roles,setRoles] = useState({});
  const [loading,setLoading] = useState(false)

  const { Option } = Select;

  const fetchRoles = async () =>{
    const input = {access_token:localStorage.token}
    const res = await admin_roles(input)
    if(res.status==='successfull'){
        setRoles(res.result)
    }
  }

  useEffect(() =>{
    fetchRoles()
  },[])

  const onFinish = async (values) => {
    setLoading(true)
    const input = {access_token:localStorage.token,values:values}
    const res = await user_create(input)
    .catch((err) => {console.log("Err: ", err)});
    console.log('status',res);
    try{
        if (res.status==='successfull') {
          message.success(res.message)
          setLoading(false)
          form.resetFields()
        }
        else{ 
            message.error(res.message)
            setLoading(false)}
    }catch{ 
        message.error('Something went wrong')
        setLoading(false)
    }
  };

  const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24}
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{width: 70}}>
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );
  return (
    <>
      <div className='formContainer'>
      {loading?<Loader/>:null}
        <Form
        {...formItemLayout}
        initialValues={{prefix: '91'}}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        >
        <Row gutter={24}>
              <Col span={6}>
              <Form.Item
                    name="roleId"
                    label="User Type"
                    rules={[{required: true}]}>
                        <Select
                        placeholder="Select a option"
                        >
                        {roles?
                            Object.keys(roles).map((val)=>{
                                // if(localStorage.role_id===2 && roles[val].role!=='admin'){
                                return(
                                    <Option value={roles[val].role_id}>{roles[val].role}</Option>
                                )
                                // }
                            })
                        :null}  
                        </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                    name="firstName"
                    label="First Name"
                    // tooltip="What do you want others to call you?"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your First name!',
                        whitespace: true,
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                    name="middleName"
                    label="Middle Name"
                    rules={[{whitespace: true}]}
                >
                    <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your last name!',
                        whitespace: true,
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
              </Col>
        </Row>

        <Row gutter={24}>
            <Col span={6}>
                <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>

            <Col span={6}>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
            </Col>

            <Col span={6}>
                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }

                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    name="phone"
                    label="Phone Number"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your phone number!',
                    },
                    ]}
                >
                    <Input
                    addonBefore={prefixSelector}
                    style={{
                        width: '100%',
                    }}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Form.Item
            // wrapperCol={{offset: 8,span: 16}}
            >
            <Button className='SolidButton' htmlType="submit">Submit</Button>
        </Form.Item>
    </Form>
    </div>
    </>
  );
};

export default UserCreation;
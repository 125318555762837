import React, {PureComponent } from 'react'
import parse from 'html-react-parser';

import {save_risk_profiling,get_risk_profiling,submit_risk_profiling} from './Api'
import './Compliance.scss'
import {MDBIcon} from 'mdbreact'

import Loader from '../../shared/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';

export default class Risk_profiling extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data:{},
            startDate: new Date(),
            ans1:'',
            ans2:'',
            ans3:'',
            ans4:'',
            ans5:'',
            ans6:'',
            ans7:'',
            ans8:'',
            ans11:'',
            loading:false,
            clientsign:this.props.clientsign,
            result:'',
            var:'',
            error:false,
            errormsg:''
        }
    }
    componentDidMount(){
        const input = {
            access_token:localStorage.token
          }
          if(this.props.risk_form===0){
                this.setState({
            loading:true
        })
          get_risk_profiling(input).then(res => {
              console.log("res question",res)
          try{
          if (res.status==='successfull') {
            this.setState(() => ({
               data:res.result,
               loading:false
            }))
        }
        else{}
        }catch{}})
    }
    }
    ans1=(e)=>{
        this.setState({
            ans1:e.target.value
        })
    }
    ans2=(e)=>{
        this.setState({
            ans2:e.target.value
        })
    }
    ans3=(e)=>{
        this.setState({
            ans3:e.target.value
        })
    }
    ans4=(e)=>{
        this.setState({
            ans4:e.target.value
        })
    }
    ans5=(e)=>{
        this.setState({
            ans5:e.target.value
        })
    }
    ans6=(e)=>{
        this.setState({
            ans6:e.target.value
        })
    }
    ans7=(e)=>{
        this.setState({
            ans7:e.target.value
        })
    }
    ans8=(e)=>{
        this.setState({
            ans8:e.target.value
        })
    }

    finalstep2=()=>{
        this.setState({
            loading:true
        })
        let ans_arr={}
        ans_arr={1:this.state.ans1,2:this.state.ans2,3:this.state.ans3,4:this.state.ans4,
                    5:this.state.ans5,6:this.state.ans6,7:this.state.ans7,8:this.state.ans8}
        const input = {
            access_token:localStorage.token,
            ans_arr:ans_arr,
            date:this.state.startDate,
            risk_profiling:this.state.var
        }
        console.log("input",input)
        if(ans_arr){
            submit_risk_profiling(input).then(res => {
                console.log("res",res)
            try {
                if (res.status==='successfull') {
                    this.props.update();
                    this.props.step5()
                    this.setState({
                       loading:false
                    })
                }
                else{
                    this.setState({
                        error:true,
                        errormsg:res.message,
                    })
                }
            } catch (error) {
                this.setState({
                    error:true,
                    errormsg:'Something went wrong',
                })
            }
        })
    }
    }

    save=()=>{
        let ans_arr={}
        ans_arr={1:this.state.ans1,2:this.state.ans2,3:this.state.ans3,4:this.state.ans4,
                    5:this.state.ans5,6:this.state.ans6,7:this.state.ans7,8:this.state.ans8}
        const input = {
            access_token:localStorage.token,
            ans_arr:ans_arr,
            date:this.state.startDate,
        }
        console.log("input",input)
        if(ans_arr){
            save_risk_profiling(input).then(res => {
                console.log("res",res)
            try {
                if (res.status==='successfull') {
                    this.setState({
                     var:res.result
                    })
                }
                else{
                    this.setState({
                        error:true,
                        errormsg:res.message,
                    })
                }
            } catch (error) {
                this.setState({
                    error:true,
                    errormsg:'Something went wrong'
                })
            }
        })
    }
    }
    alert=()=>{
        this.setState({
            error:true,
            errormsg:'Please answer the questions first'
        })
    }
    alert1=()=>{
        this.setState({
            error:true,
            errormsg:'Please save the answers first'
        })
    }
    cancel=()=>{
        this.setState({
            error:false,
        })
      }
    render() {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();


        if (this.state.loading){
            return(
              <div style={{textAlign:'center',marginTop:100}}>
              <Loader/>
              </div>
              );
          }

        return (
            <div>
                 {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}
           {/* -----------------------------------------------document 2-------------------------------- */}
           <div>
            {this.state.data.question_dict?(
            <div>
            <div className='form-heading'>Risk Profiling Questionnaire</div>
            <div className='data-inner-div'>
        <div className='question-card'>
        <div className='questionRisk'>1. {this.state.data.question_dict[1].question}
         <div><div className='option'>
            <input type="radio" name="one" value={this.state.data.question_dict[1].option_id} onChange={this.ans1}/>{this.state.data.question_dict[1].options}
        </div></div>
         <div><div className='option'>
            <input type="radio" name="one" value={this.state.data.question_dict[2].option_id} onChange={this.ans1}/>{this.state.data.question_dict[2].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="one" value={this.state.data.question_dict[3].option_id} onChange={this.ans1}/>{this.state.data.question_dict[3].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="one" value={this.state.data.question_dict[4].option_id} onChange={this.ans1}/>{this.state.data.question_dict[4].options}
        </div></div>
        </div>
    </div>

    <div className='question-card'>
        <div className='questionRisk'>2. {this.state.data.question_dict[5].question}

        <div><div className='option'>
            <input type="radio" name="two" value={this.state.data.question_dict[5].option_id} onChange={this.ans2}/>{this.state.data.question_dict[5].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="two" value={this.state.data.question_dict[6].option_id} onChange={this.ans2}/>{this.state.data.question_dict[6].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="two" value={this.state.data.question_dict[7].option_id} onChange={this.ans2}/>{this.state.data.question_dict[7].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="two" value={this.state.data.question_dict[8].option_id} onChange={this.ans2}/>{this.state.data.question_dict[8].options}
        </div></div>
        </div>
        </div>

  <div className='question-card'>
       <div className='questionRisk'>3. {this.state.data.question_dict[9].question}

       <div><div className='option'>
            <input type="radio" name="three" value={this.state.data.question_dict[9].option_id} onChange={this.ans3}/>{this.state.data.question_dict[9].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="three"value={this.state.data.question_dict[10].option_id} onChange={this.ans3}/>{this.state.data.question_dict[10].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="three" value={this.state.data.question_dict[11].option_id} onChange={this.ans3}/>{this.state.data.question_dict[11].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="three" value={this.state.data.question_dict[12].option_id} onChange={this.ans3}/>{this.state.data.question_dict[12].options}
        </div></div>
        </div>
       </div>

       <div className='question-card'>
    <div className='questionRisk'>4. {this.state.data.question_dict[13].question}

    <div><div className='option'>
            <input type="radio" name="four" value={this.state.data.question_dict[13].option_id} onChange={this.ans4}/>{this.state.data.question_dict[13].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="four" value={this.state.data.question_dict[14].option_id} onChange={this.ans4}/>{this.state.data.question_dict[14].options}
        </div></div>
        <div> <div className='option'>
            <input type="radio" name="four" value={this.state.data.question_dict[15].option_id} onChange={this.ans4}/>{this.state.data.question_dict[15].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="four" value={this.state.data.question_dict[16].option_id} onChange={this.ans4}/>{this.state.data.question_dict[16].options}
        </div></div>
        </div>
        </div>

        <div className='question-card'>
    <div className='questionRisk'>5. {this.state.data.question_dict[17].question}

    <div><div className='option'>
            <input type="radio" name="five" value={this.state.data.question_dict[17].option_id} onChange={this.ans5}/>{this.state.data.question_dict[17].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="five" value={this.state.data.question_dict[18].option_id} onChange={this.ans5}/>{this.state.data.question_dict[18].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="five" value={this.state.data.question_dict[19].option_id} onChange={this.ans5}/>{this.state.data.question_dict[19].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="five" value={this.state.data.question_dict[20].option_id} onChange={this.ans5}/>{this.state.data.question_dict[20].options}
        </div></div> 
        </div>
        </div>

        <div className='question-card'>
    <div className='questionRisk'>6. {this.state.data.question_dict[21].question}

    <div><div className='option'>
            <input type="radio" name="six" value={this.state.data.question_dict[21].option_id} onChange={this.ans6}/>{this.state.data.question_dict[21].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="six" value={this.state.data.question_dict[22].option_id} onChange={this.ans6}/>{this.state.data.question_dict[22].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="six" value={this.state.data.question_dict[23].option_id} onChange={this.ans6}/>{this.state.data.question_dict[23].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="six" value={this.state.data.question_dict[24].option_id} onChange={this.ans6}/>{this.state.data.question_dict[24].options}
        </div></div>
        </div>
        </div>

        <div className='question-card'>
    <div className='questionRisk'>7. {parse(this.state.data.question_dict[25].question)}

    <div><div className='option'>
            <input type="radio" name="seven" value={this.state.data.question_dict[25].option_id} onChange={this.ans7}/>{this.state.data.question_dict[25].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="seven" value={this.state.data.question_dict[26].option_id} onChange={this.ans7}/>{this.state.data.question_dict[26].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="seven" value={this.state.data.question_dict[27].option_id} onChange={this.ans7}/>{this.state.data.question_dict[27].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="seven" value={this.state.data.question_dict[28].option_id} onChange={this.ans7}/>{this.state.data.question_dict[28].options}
        </div></div>
        </div>
      </div>

      <div className='question-card'>
    <div className='questionRisk'>8. {this.state.data.question_dict[29].question}

    <div> <div className='option'>
            <input type="radio" name="eight" value={this.state.data.question_dict[29].option_id} onChange={this.ans8}/>{this.state.data.question_dict[29].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="eight" value={this.state.data.question_dict[30].option_id} onChange={this.ans8}/>{this.state.data.question_dict[30].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="eight" value={this.state.data.question_dict[31].option_id} onChange={this.ans8}/>{this.state.data.question_dict[31].options}
        </div></div>
        <div><div className='option'>
            <input type="radio" name="eight" value={this.state.data.question_dict[32].option_id} onChange={this.ans8}/>{this.state.data.question_dict[32].options}
        </div></div>
        </div>
        </div>
         <div className='submit-Btn'>
    {this.state.ans1!=='' && this.state.ans2!=='' && this.state.ans3!=='' && this.state.ans4!=='' && this.state.ans5!=='' && this.state.ans6!=='' && this.state.ans7!=='' && this.state.ans8!==''?(
     <button onClick={this.save}>Save</button>):(<button onClick={this.alert}>Save</button>)}
     </div>

{this.state.var===''?(<span></span>):(
<div className='declare-text'>
Based on the KYC and the inputs given by you, Adviser feels that you are - <span style={{fontWeight:'bold'}}>{this.state.var}</span> (Aggressive/Conservative/ Moderate/) investor.</div>)}

        <hr/>
        <div className='declare'>Declaration</div>
        <div className='declare-text'>I hereby declared that the details furnished are true and correct to the best of my knowledge and belief
and I undertake to inform you of any changes therein, immediately. In case any of the above information
is found to be false or untrue or misleading or misrepresenting. I agreed to indemnify and hold harmless
Adviser and respective employees, representatives, officers and persons affiliated with them against all
claims, damages, losses, liabilities, costs and expenses incurred by them in the performance of their
obligations on the basis of information provided by me above. Further, in accordance requirements of
the regulations, I further agree to furnish all the above information on the Annual basis.
</div>
    <div className='row'>
        <div className='col-md-4'>
            <div className='user-sign-image'>
                <img src={this.props.clientsign} alt=""/>
                <div>{this.state.data.full_name}</div>
                <div>Date : {date+"/"+month+"/"+year}</div>
            </div>
        </div>
    </div>
       
    </div>

    {this.props.sign===1?(
    <div className='submit-Btn'>
    <button type='button' onClick={this.props.step3}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
    {this.state.var!==''?(
     <button onClick={this.finalstep2}>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>):(<button onClick={this.alert1}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>)}
    </div>):(<div className='previous-msg'>Please fill the previous form first.</div>)}

    </div>):(<div></div>)}
        </div>
        </div>

        )
    }
}

import React, { useState, useEffect } from 'react';
import "./Navbar.scss";
import Logob from "../../../Images/logoBlack.png"
import { Link} from "react-router-dom";
import { Col, Row,Drawer } from 'antd';
import {MenuFoldOutlined} from '@ant-design/icons';

const Navbar = (props) => {
   
  const [nav, setNav] = useState(false);
  const [color, setColor] = useState('fixed left-0 top-0 w-full z-10 ease-in duration-300 bg-transparent');
  const [textColor, setTextColor] = useState('white');
  const [logoW, setLogoW] = useState('w-20 ease-in duration-300');
  const [path, setPath] = useState('/home');
  const [visible,setVisible] = useState(false)

  const handleNav = () => {
    setNav(!nav);
  };
  const showDrawer = () => {setVisible(true)};
    
  const onClose = () => {setVisible(false)};

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 50) {
        setColor('fixed left-0 top-0 w-full z-10 ease-in duration-300 bg-white shadow-lg');
        setTextColor('#000000');
        setLogoW('w-16 ease-in duration-300')
      } else {
        setColor('fixed left-0 top-0 w-full z-10 ease-in duration-300 bg-transparent ');
        setTextColor('#ffffff');
        setLogoW('w-20 ease-in duration-300')
      }
    };
    window.addEventListener('scroll', changeColor);
    setPath(window.location.pathname)
  }, []);
    
  return (
    <>
    <div className='mobile'>
      <Row>
        <Col span={20}>
        <Link to='/home'>
          <div className='rounded-lg -mt-2'><img src={Logob} alt="" className={'logoMob'}/></div>
        </Link>
        </Col>
        <Col span={4} align="middle">
          <div style={{marginTop:'7px'}} onClick={showDrawer}><MenuFoldOutlined/></div>
        </Col>
      </Row>
      <Drawer
          title=" "
          width={200}
          onClose={onClose}
          visible={visible}>
         
          <div className='navMob'>
            <Link to='/home' className={path==='/home'?'activeCss':'navCss'}>HOME</Link>
          </div>
          <div className='navMob'>
            <Link to='/what_we_do' className={path==='/what_we_do'?'activeCss':'navCss'}>WHAT WE DO</Link>
          </div>
          <div className='navMob '>
            <Link to='/why_us' className={path==='/why_us'?'activeCss':'navCss'}>WHY US</Link>
          </div>
          <div className='navMob '>
            <Link to='/blog' className={path==='/blog'?'activeCss':'navCss'}>OUR RESOURCES</Link>
          </div>
          {/* <div className='navMob '>
            <Link to='/faqs' className={path==='/faqs'?'activeCss':'navCss'}>FAQs</Link>
          </div> */}
          <div className='navMob '>
            <Link to='/contact_us' className={path==='/contact_us'?'activeCss':'navCss'}>CONTACT US</Link>
          </div>
          <div className='navMob '><Link to="/login" exact className="linkButton">LOGIN / SIGNUP</Link></div>
  
      </Drawer>
    </div>
    {/* className={router.pathname == "/home" ? `${activeCss}` : ""} */}
      <div className={`${color} desktop`}>
      <div className='max-w-full lg:h-[11vh]  m-auto flex justify-between items-center px-8 py-3 '>
        <Link to='/home'>
          <div className='rounded-lg -mt-2'><img src={Logob} alt="" className={logoW}/></div>
        </Link>

        <ul className='hidden sm:flex primaryColor mt-3'>
          <li className='text-xs'>
            <Link to='/home' className={path==='/home'?'activeCss':'navCss'}>HOME</Link>
          </li>
          <li className='text-xs'>
            <Link to='/what_we_do' className={path==='/what_we_do'?'activeCss':'navCss'}>WHAT WE DO</Link>
          </li>
          <li className='text-xs font-semibold'>
            <Link to='/why_us' className={path==='/why_us'?'activeCss':'navCss'}>WHY US</Link>
          </li>
          <li className='text-xs font-semibold'>
            <Link to='/blog' className={path==='/blog'?'activeCss':'navCss'}>OUR RESOURCES</Link>
          </li>
          {/* <li className='text-xs font-semibold'>
            <Link to='/faqs' className={path==='/faqs'?'activeCss':'navCss'}>FAQs</Link>
          </li> */}
          <li className='text-xs font-semibold'>
            <Link to='/contact_us' className={path==='/contact_us'?'activeCss':'navCss'}>CONTACT US</Link>
          </li>
          <Link to="/login" exact className="linkButton">LOGIN / SIGNUP</Link>
        </ul>

      </div>
      </div>
    </>
    );
};

export default Navbar;
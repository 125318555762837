import React, { useState,useEffect} from 'react'
import {MDBIcon} from 'mdbreact'
import Schemewise from './Schemewise'
import MarketCapwise from './Market_capwise'
import Classwise from './Classwise'
import ClientWise from './ClientWise'
import Allclientdata from './Allclientwise'



const Allocation = (props) => {
    const [tab,setTab] = useState({scheme:true,market_cap:false,asset_class:false,client_wise:false,all_client_wise:false})
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleTab=(flag)=>{
        let dict = {...tab}
        Object.keys(dict).map(item => {
            if(item===flag){dict[item] = true
            }else{dict[item] = false}
            });
            setTab(dict)
    }
    const handleModal = () => {setIsModalVisible(!isModalVisible);};
  return (
    <div className='tableContainer'>
    <div className='tableTabContainer'>
       <div className={tab.scheme?'tableTabActive':'tableTab'} 
       onClick={()=>handleTab('scheme')}>Scheme wise</div>
       <div className={tab.asset_class?'tableTabActive':'tableTab'} 
       onClick={()=>handleTab('asset_class')}>Asset class wise</div>
       <div className={tab.client_wise?'tableTabActive':'tableTab'} 
       onClick={()=>handleTab('client_wise')}>Client wise</div>
       <div className={tab.all_client_wise?'tableTabActive':'tableTab'} 
       onClick={()=>handleTab('all_client_wise')}>All Client Data</div>
       <div className='PagesCommentIconDiv'>
       <span className='valueInRs'>value in (₹)</span>
       </div>
   </div>

    {tab.scheme?<Schemewise id={props.id}/>:null}
   {tab.market_cap?<MarketCapwise id={props.id}/>:null}
   {tab.asset_class?<Classwise id={props.id}/>:null}
   {tab.client_wise?<ClientWise id={props.id} />:null}
   {tab.all_client_wise?<Allclientdata id={props.id}/>:null}
</div>
  )
}

export default Allocation
import React,{useEffect,useState} from 'react'
import './RedirectPages.scss'
import { Modal} from 'antd';
import {useParams} from 'react-router-dom'
import { mf_recommendation_list,zerodha_request_token_get} from './Api'
import logo from "../../Images/logoBlack.png"
// import ScriptTag from 'react-script-tag';

// var KiteConnect = require("kiteconnect").KiteConnect;
const Email_recommendation = (props) => {
    const [mfList,setMfList] = useState({})
    const [doAuthe,setDoAuth] = useState(true)
    const [redirectUrl,setRedirectUrl]  = useState('')
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
   
    let { token } = useParams();

    const getList = (z_id)=>{
        const input = {
            token:token,
            zerodha_id:z_id
        }
        mf_recommendation_list(input).then(res => {
            console.log("res", res)
            try{
                if (res.status==='successfull'){
                    setMfList(res.result)
                    setLoading(false)
                }
                else{}
            }catch{console.log('something went wrong')}
        })
    }
    const checkToken = async() =>{
        setLoading(true)
        const input = {token:token}
        zerodha_request_token_get(input).then(res => {
            console.log("restoken", res)
        try{
          if(res.status==='successfull' && res.message==='Authentication link') {
            setLoading(false)
            setDoAuth(true)
            setRedirectUrl(res.result)             
          }
          else{
            setLoading(false)
          }
        }catch{
            console.log('something went wrong');
        }
        })
    } 
    // const recall=()=>{
    //     setInterval(() => {
    //         getList()
    //     }, 60 * 1000);
    // }
    useEffect(()=>{
        checkToken()
        if(Object.keys(mfList).length>0){
            console.log(mfList);
            var kite = new window.KiteConnect(mfList[0].zerodha_key);
            Object.keys(mfList).map((val)=>{
                kite.add({
                    "exchange": mfList[val].exchange,
                    "tradingsymbol": mfList[val].trading_symbol,
                    "quantity": mfList[val].quantity,
                    "transaction_type": mfList[val].buy_or_sell,
                    "order_type": mfList[val].order_type,
                    "price":mfList[val].limit_price
                });
            })
            kite.renderButton("#default-button");
            kite.link("#custom-button");
        }
    },[mfList])

    const showModal = (z_id) => {
        setIsModalVisible(true);
        getList(z_id)
        setTimeout(() => {
            setIsModalVisible(false);
        }, 20 * 1000);
      };
    const handleCancel=()=>{
        setIsModalVisible(false);
    }
    return (
        <div className='redirectPageDiv'>
            <div className='redirectPageBox'>
                <div className='rediectPagelogo'><img src={logo} alt=""/></div>
                {doAuthe && redirectUrl ?(
                    <div className='AuthMSg'>
                        <h3>Zerodha Authentication</h3>
                        <p>Authorize House of Alpha to connect to your zerodha account</p>
                        <p>Please Login with your zerodha credential</p> <br/>
                        {redirectUrl?
                        <table className="table table-condensed table-sm table-bordered">
                            <thead>
                                <tr className='tb_header'>
                                    <td>Name</td>
                                    <td>Zerodha ID</td>
                                    <td style={{textAlign:'right'}}>Zerodha Authentication</td>
                                    <td style={{textAlign:'right'}}>Recommendation</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(redirectUrl).map((val)=>{
                                    return(
                                        <tr className='tb_body'>
                                            <td>{redirectUrl[val].name}</td>
                                            <td>{val}</td>
                                            <td className='authDoneMsg'>
                                                {redirectUrl[val].link==='Authentication Done For Today'?
                                                <>{redirectUrl[val].link}</>:
                                                <button className='authBtn' onClick={()=> window.location.href = redirectUrl[val].link}>Authorise</button>}
                                            </td>
                                            <td className='authDoneMsg'>
                                            {redirectUrl[val].link==='Authentication Done For Today'?
                                                <button className='execBtn' onClick={()=>showModal(val)}>View & Execute</button>:
                                                <button className='execBtnDisable' disabled>View & Execute</button>}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>:null}
                    </div>
                ):null}
            </div>
            <Modal footer={null} visible={isModalVisible} onCancel={handleCancel} width='80%'>      
                {Object.keys(mfList).length>0?
                <>
                <div className='emailRecomList'>
                    <table className="table table-condensed table-sm table-hovered table striped">
                        <thead>
                            <tr className='tb_header'>
                                <td>Instrument Type</td>
                                <td>Exchange</td>
                                <td>Trading Symbol</td>
                                <td>Name</td>
                                <td>Lot Size</td>
                                <td>Order Type</td>
                                <td>Limit Price</td> 
                                <td>Quantity</td>
                                <td>Buy / Sell</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(mfList).map((val)=>{
                                return(
                                    <tr className='tb_body'>
                                        <td className='tab-td td-lf-border'>{mfList[val].instrument_type}</td>
                                        <td className='tab-td td-lf-border'>{mfList[val].exchange}</td>
                                        <td className='tab-td td-lf-border'>{mfList[val].trading_symbol}</td>
                                        <td className='tab-td td-lf-border'>{mfList[val].name}</td>
                                        <td className='tab-td td-lf-border'>{mfList[val].lot_size}</td>
                                        <td className='tab-td td-lf-border'>{mfList[val].order_type}</td>
                                        {mfList[val].order_type==="LIMIT"?(
                                            <td className='tab-td td-lf-border'>{mfList[val].limit_price}</td>
                                        ):(<td className='tab-td td-lf-border'>Not Applicable</td>)}
                                        <td className='tab-td td-lf-border'>{mfList[val].quantity}</td>
                                        <td className='tab-td td-lf-border'>{mfList[val].buy_or_sell}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='executeBtn'>
                    <button id="custom-button">Confirm & Execute</button>
                </div>
                </>:null}
            </Modal>
        </div>
        )
    }

export default Email_recommendation

import React,{useState,useEffect} from 'react';
import { Table } from "ant-table-extensions";
import {customer_margin_details} from '../../Api'
import { DatePicker, Space } from 'antd';
import moment from 'moment'

const Order = (props) => {
  const [pickerValue, setPickerValue] = useState(moment(new Date()));
  const [data, setData] = useState([]);
  const date = new Date().toISOString().split('T')[0]
  const [dateSend, setdateSend] = useState([])

  const { RangePicker } = DatePicker;

  const fetchData = ()=>{
    let newdata = []
    const input = {
        access_token:localStorage.token,
        type:'orders',
        id:props.customer_id,
        start_date:dateSend[0],
        end_date:dateSend[1]
    }
    console.log(input);
    customer_margin_details(input).then(res => {
        console.log('order',res);
          Object.keys(res.result).map((val)=>{
            newdata.push({ 
            key: val,
            date:res.result[val].date,
            average_price:res.result[val].average_price,
            cancelled_quantity: res.result[val].cancelled_quantity,
            disclosed_quantity: res.result[val].disclosed_quantity,
            exchange: res.result[val].exchange,
            exchange_order_id: res.result[val].exchange_order_id,
            exchange_timestamp: res.result[val].exchange_timestamp,
            exchange_update_timestamp: res.result[val].exchange_update_timestamp,
            filled_quantity: res.result[val].filled_quantity,
            guid: res.result[val].guid,
            instrument_token: res.result[val].instrument_token,
            market_protection: res.result[val].market_protection,
            modified: res.result[val].modified,
            order_id: res.result[val].order_id,
            order_timestamp: res.result[val].order_timestamp,
            order_type: res.result[val].order_type,
            parent_order_id: res.result[val].parent_order_id,
            pending_quantity: res.result[val].pending_quantity,
            placed_by: res.result[val].placed_by,
            price: res.result[val].price,
            product: res.result[val].product,
            quantity: res.result[val].quantity,
            status: res.result[val].status,
            status_message: res.result[val].status_message,
            status_message_raw: res.result[val].status_message_raw,
            tag: res.result[val].tag,
            tradingsymbol: res.result[val].tradingsymbol,
            transaction_type: res.result[val].transaction_type,
            trigger_price: res.result[val].trigger_price,
            validity: res.result[val].validity,
            validity_ttl: res.result[val].validity_ttl,
            variety: res.result[val].variety,
            account_type: res.result[val].account_type,
            name: res.result[val].name,
            })
        })
        setData(newdata)
      })
  }

  useEffect(()=>{
    fetchData()
  },[])
  const submitData =()=>{
    fetchData()
  }

  const onChangeDate = (date, dateString) => {
    setdateSend(dateString)
  };

  const columns = [
    {title: 'Updated Date',dataIndex: 'date',width: 70},
    {title: 'Account Type',dataIndex: 'account_type',width: 70},
    {title: 'Name',dataIndex: 'name',width: 70},
    {title: 'Order Id',dataIndex: 'order_id',width: 75},
    {title: 'Order Type',dataIndex: 'order_type',width: 75},
    {title: 'Average Price',dataIndex: 'average_price',width: 75},
    {title: 'Cancelled Quantity',dataIndex: 'cancelled_quantity',width: 75},
    {title: 'Disclosed Quantity',dataIndex: 'disclosed_quantity', width: 75},
    {title: 'Placed By',dataIndex: 'placed_by',width: 75},
    {title: 'Filled Quantity',dataIndex: 'filled_quantity ',width: 75},
    {title: 'GUID',dataIndex: 'guid ',width: 75},
    {title: 'Instrument Token',dataIndex: 'instrument_token ',width: 75},
    {title: 'Market Protection',dataIndex: 'market_protection ',width: 75},
    {title: 'Order Timestamp',dataIndex: 'order_timestamp',width: 75},
    {title: 'Parent Order Id',dataIndex: 'parent_order_id',width: 75},
    {title: 'Modified',dataIndex: 'modified',width: 75},
    {title: 'Price',dataIndex: 'price',width: 75},
    {title: 'Product',dataIndex: 'product',width: 75},
    {title: 'Quantity',dataIndex: 'quantity',width: 75},
    {title: 'Exchange',dataIndex: 'exchange',width: 75},
    {title: 'exchange Order Id',dataIndex: 'exchange_order_id',width: 75},
    {title: 'exchange Timestamp',dataIndex: 'exchange_timestamp',width: 75},
    {title: 'exchange Update Timestamp',dataIndex: 'exchange_update_timestamp',width: 110},
    {title: 'Pending Quantity',dataIndex: 'pending_quantity',width: 75},
    {title: 'Status',dataIndex: 'status',width: 75},
    {title: 'Status Message',dataIndex: 'status_message',width: 75},
    {title: 'Status Message Raw',dataIndex: 'status_message_raw',width: 90},
    {title: 'Tag',dataIndex: 'tag',width: 65},
    {title: 'Trading Symbol',dataIndex: 'tradingsymbol',width: 75},
    {title: 'Transaction Type',dataIndex: 'transaction_type',width: 75},
    {title: 'Trigger Price',dataIndex: 'trigger_price',width: 75},
    {title: 'Validity',dataIndex: 'validity',width: 70},
    {title: 'Validity Ttl',dataIndex: 'validity_ttl',width: 75},
    {title: 'Variety',dataIndex: 'variety',width: 75},
  ]

  return (
      <>
    <div className='modalDateBox'>
        <RangePicker 
          onChange={onChangeDate} allowClear={true}
          defaultValue={[pickerValue, pickerValue]} />
        <button className='dateSubmitButton' onClick={submitData}>Submit</button>
    </div>
        <Table
          rowClassName="editable-row"
          columns={columns}
          dataSource={data}
          size='small'
          scroll={{ x: 1150, y: 400 }}
          pagination={{
            pageSize: 10,
            position:['bottomCenter']
          }}
          sticky={true}
          exportableProps={{ fileName: `Orders_${props.cusEmail}_${date}`}}
        />
      </>
  );
};

export default Order;

import React,{useState,useEffect} from 'react'
import './Assets.scss'
import { Row, Col} from 'antd';
import Donut from './Donut'
import ColumnLine from './ColumnLine'
import Tables from './Tables';
import {dashboard_assets,user_details,current_market_value} from './Api'
import Loader from '../../../shared/LoaderTransparent'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Assets = (props) => { 
    const [donutData, setDonutData] = useState([]);
    const [tableData, setTableData] = useState({});
    const [columnData, setColumnData] = useState([]);
    const [familyMem,setFamilyMem] = useState([])
    const [data,setData] = useState({})
    const [loading,setLoading] = useState(false)

   

    const fetchAsset = async () =>{
      setLoading(true)
        const arr = []
        const input = {access_token:localStorage.token,id:props.id}
        const res = await dashboard_assets(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('asset',res);
        try{
          if (res.status==='successfull'){
            setLoading(false)
            Object.keys(res.result.asset_wheel).map((val)=>{
              arr.push({type: val,value: res.result.asset_wheel[val].value,color:res.result.asset_wheel[val].color })
            })
              setTableData(res.result.asset_table)
          }else{
            setLoading(false)
            
          }
        }catch{
         console.log("Something went wrong");
          setLoading(false)
        }
        setDonutData(arr)
      }
      
    const fetchFamily = async()=>{
        const input = {access_token:localStorage.token,id:props.id}
        const res = await user_details(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('assetTable',res);
        try{
          if (res.status==='successfull'){
            setFamilyMem(res.result)
          }else{}
        }catch{}
      }
      const fetchColumnChart = async()=>{
        let arrColumn = []
        const input = {access_token:localStorage.token,id:props.id}
        const res = await current_market_value(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('assetColumnChart',res);
        try{
          if (res.status==='successfull'){
            Object.keys(res.result).map((val)=>{
              Object.keys(res.result[val]).map((val2)=>{
                if(res.result[val][val2].current_value!==0){
                  arrColumn.push({year: val, value: res.result[val][val2].current_value,type: val2})
                }
              })
            })
          }else{}
        }catch{}
        setData(res.result)
        setColumnData(arrColumn) 
      }

    useEffect(()=>{
        fetchAsset()
        fetchFamily()
        fetchColumnChart()
      },[])
  return (
    <>
    <ToastContainer theme='colored' limit={1}/>
        <Row gutter={[16, 16]}>
            <Col span={11}>
            
                <div className='dashboardCard animate'>
                    <div className='PageComponentHead'>ASSET OVERVIEW</div>
                    {loading?<Loader/>:
                    <div className='hrScroll'>
                      {donutData.length>0?<Donut loading={loading} donutData={donutData} data={data}/>:
                      <p className='noDataTxt'>No data Availble</p>}
                      
                    </div>}
                    
                </div>
            </Col>
            <Col span={13}>
                <div className='dashboardCardAsset animate'>
                    <div className='PageComponentHead'>ASSET GROWTH</div>
                    {Object.keys(columnData).length>0?
                    <div className='chartArea'> <ColumnLine columnData={columnData} data={data}/></div>:<p className='noDataTxt'>No data Availble</p>}
                </div>
            </Col>
        </Row>
        <>
            {Object.keys(tableData).length>0 && familyMem.length>0?
            <Tables tableData={tableData} familyMem={familyMem} id={props.id}/>:null}
        </>
    </>
  )
}

export default React.memo(Assets)


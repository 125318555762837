import React, { Component } from 'react'
import Moment from 'moment';
import {risk_assessment,risk_assessment_post} from '../../Api'
import {MDBIcon} from 'mdbreact'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../../shared/Loader';

export default class RiskAssesment extends Component {
    constructor(props){
        super(props);
        this.state={
            risk_dict:{},
            loading:false,
            success:false,
            error:false,
            errormsg:'',
            successmsg:'',
        }
    }
componentDidMount(){
    this.setState({loading:true})
    const input = {
        access_token:localStorage.token,
        customer_id:this.props.customer_id,
        date:Moment(this.props.today).format('YYYY-MM-DD')
    }
    risk_assessment(input).then(res => {
        console.log(res)
    try{
    if (res.status==='successfull') {
    this.setState(() => ({
        risk_dict:res.result,
        loading:false
    }))
    }
    else{
        this.setState(() => ({
            loading:false
        }))
    }
    }catch{
        this.setState(() => ({
            loading:false
        }))
    }
    })
}

onchange_score=(e)=>{
    let aa = this.state.risk_dict
    aa["value"] = e.target.value
    this.setState({risk_dict:aa})
}
submit_goal_complete=()=>{
    this.setState({loading:true})
const input = {
    access_token:localStorage.token,
    customer_id:this.props.customer_id,
    risk_dict:this.state.risk_dict,
    date:Moment(this.props.today).format('YYYY-MM-DD')
}
risk_assessment_post(input).then(res => {
    try{
    if (res.status==='successfull') {
        this.setState({
            success:true,
            loading:false,
            successmsg:res.message
        })
    }
    else{
        this.setState({
            error:true,
            loading:false,
            errormsg:res.message
        })
    }
    }catch{
        this.setState({
            error:true,
            loading:false,
            errormsg:'Something went wrong'
        })
    }
    })
}
cancel=()=>{
    this.setState({
        success:false,
        error:false
    })
    }
componentDidUpdate(prevProps, prevState) {
    if (prevProps.today !== this.props.today) {
        const input = {
            access_token:localStorage.token,
            customer_id:this.props.customer_id,
            date:Moment(this.props.today).format('YYYY-MM-DD')
        }
        risk_assessment(input).then(res => {
            console.log(res)
        try{
        if (res.status==='successfull') {
        this.setState(() => ({
            risk_dict:res.result,
        }))
        }
        else{}
        }catch{}
        })
    }
    }
    render() {
        return (
            <div>
            {this.state.loading?(
                <div className='loader-main-div'>
                    <div className='loader-div'>
                        <Loader/>
                    </div>
                </div>
            ):(null)}

            {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancel}>
                {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

            {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
                {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

               <div className='update-table-div'>
                    <table class='table table-condensed'>
                        <thead className='mf-p-table-tr'>
                            <th>Risk Assessment</th>
                            <th>date</th>
                        </thead>
                        {this.state.risk_dict?(
                        <tbody>
                            <tr className='mf-p-table-tr-bg'>
                                <td><select  value={this.state.risk_dict.value}  onChange={this.onchange_score}>
                                    <option>Select</option>
                                    <option value="Very Bad">Very Bad</option>
                                    <option value="Bad">Bad</option>
                                    <option value="OK">OK</option>
                                    <option value="Good">Good</option>
                                    <option value="Very Good">Very Good</option>
                                </select>
                                </td>
                                <td>{Moment(this.props.today).format('YYYY-MM-DD')}</td>
                            </tr>
                        </tbody>):(<span></span>)}
                    </table>
                </div>
                <div className='update-sub-btn'><button onClick={this.submit_goal_complete}><MDBIcon icon="save" className='btn-i'/>Save</button></div>
            </div>
        )
    }
}

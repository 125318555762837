import React, { useState,useEffect} from 'react'
import {MDBIcon} from 'mdbreact'
import Momequityreport from './Momequityreport'
import Mommfreport from './Mommfreport'




const Monthonmonthreport = (props) => {
    const [tab,setTab] = useState({equityreport:true,mf_report:false})
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleTab=(flag)=>{
        let dict = {...tab}
        Object.keys(dict).map(item => {
            if(item===flag){dict[item] = true
            }else{dict[item] = false}
            });
            setTab(dict)
    }
    const handleModal = () => {setIsModalVisible(!isModalVisible);};
  return (
    <div className='tableContainer'>
    <div className='tableTabContainer'>
       <div className={tab.equityreport?'tableTabActive':'tableTab'} 
       onClick={()=>handleTab('equityreport')}>Equity Report</div>
       <div className={tab.mf_report?'tableTabActive':'tableTab'} 
       onClick={()=>handleTab('mf_report')}>MF Report</div>
       <div className='PagesCommentIconDiv'>
       <span className='valueInRs'>value in (₹)</span>
       </div>
   </div>

    {tab.equityreport?<Momequityreport id={props.id}/>:null}
   {tab.mf_report?<Mommfreport id={props.id}/>:null}
</div>
  )
}

export default Monthonmonthreport
import React, { PureComponent } from 'react'
import './EditScreens.scss'

import UserInfo from '../Compliance/UserInfo'
import Address from '../Compliance/AddressDetails'
import Signature from '../Compliance/Signature'
import Otp from '../Compliance/Otp'
import RIA from '../Compliance/RIA_formFun'

import Family from '../Profile-setup/FamilyExel'
import Bank from '../Profile-setup/BankDetails'
import Fatca from '../Profile-setup/FatcaDetails'
import Nominee from '../Profile-setup/NomineeExcel'
import KycDetails from '../Profile-setup/KycDetails'

import IncomeExpence from '../FinancialProfile/Income'
import Expense from '../FinancialProfile/Expense3'
import InsurancePolicy from '../FinancialProfile/InsuranceExel'
import Asset from '../FinancialProfile/Asset2'
import Liabilities from '../FinancialProfile/LiabilityExel'
import Goals from '../FinancialProfile/GoalExel'

import {user_profile_status} from '../Setup-journey/Api'
import {Row,Col,Tabs} from 'antd';
const { TabPane } = Tabs;
export default class SetupJourney extends PureComponent {
    constructor(props){
        super();
        this.state={
            profile:false,
            finance_profile:false,
            compliace:true,
            step1:true,
            step2:false,
            step3:false,
            step4:false,
            step5:false,
            step6:false,
            status:{},
        }
    }
    
compliance=()=>{
    this.setState({
        compliace:true,
        profile:false,
        finance_profile:false,
        step1:true,
        step2:false,
        step3:false,
        step4:false,
        step5:false,
        step6:false,
    })
    }
profile=()=>{
    this.setState({
        compliace:false,
        profile:true,
        finance_profile:false,
        step1:true,
        step2:false,
        step3:false,
        step4:false,
        step5:false,
        step6:false,
        })
    }
finance=()=>{
    this.setState({
        compliace:false,
        profile:false,
        finance_profile:true,
        step1:true,
        step2:false,
        step3:false,
        step4:false,
        step5:false,
        step6:false,
        })
    }
step1=()=>{
   this.setState({
    step1:true,
    step2:false,
    step3:false,
    step4:false,
    step5:false,
    step6:false,
   })
}
step2=()=>{
    this.setState({
        step1:false,
        step2:true,
        step3:false,
        step4:false,
        step5:false,
        step6:false,
       })
}
step3=()=>{
    this.setState({
        step1:false,
        step2:false,
        step3:true,
        step4:false,
        step5:false,
        step6:false,
       })
}
step4=()=>{
    this.setState({
        step1:false,
        step2:false,
        step3:false,
        step4:true,
        step5:false,
        step6:false,
       })
}
step5=()=>{
    this.setState({
        step1:false,
        step2:false,
        step3:false,
        step4:false,
        step5:true,
        step6:false,
       })
}
step6=()=>{
    this.setState({
        step1:false,
        step2:false,
        step3:false,
        step4:false,
        step5:false,
        step6:true,
        index:5
       })
}
income_previous=()=>{
    this.setState({
        profile:true,
        finance_profile:false,
        step1:false,
        step2:false,
        step3:false,
        step4:false,
        step5:true,
       })
}
compliancePrev=()=>{
    this.setState({
        compliace:true,
        profile:false,
        step1:false,
        step5:true,
        compliace_drop:true,
        profile_drop:false
    })
}
componentDidMount(){
    if(this.props.showRia===true){
        this.setState({
            compliace:true,
            profile:false,
            step1:false,
            step5:true,
        }) 
    }
    const input = {
        access_token:localStorage.token,
        id:this.props.id,
    }
    user_profile_status(input).then(res => {
        console.log('editStatus',res)
        try{
        if (res.status==='successfull') {
          this.setState(() => ({
            status:res.result,
          }))
     
      }
      else{}
      }catch{
        this.setState({error:true,errormsg:"Something went wrong",loading:false})
      }
      })

}
update=()=>{
    const input = {
        access_token:localStorage.token,
        id:this.props.id
      }
      user_profile_status(input).then(res => {
          console.log("resupdate",res)
      try{
      if (res.status==='successfull') {
        this.setState(() => ({
           status:res.result,
        }))
    }
    else{}
    }catch{}
    })
  }
    render() {
        console.log('thiis',this.props.id);
        let userInfo=0
        if(this.state.status.userInfo==='Y'){
            userInfo=1
        }
        let address=0
        if(this.state.status.address==='Y'){
            address=1
        }
        let sign=0
        if(this.state.status.sign==='Y'){
            sign=1
        }
        let risk_form=0
        if(this.state.status.risk_profiling_form==='Y'){
            risk_form=1
        }
        let ria_form=0
        if(this.state.status.ria_agreement==='Y'){
          ria_form=1
        }
        if(this.props.showRia===true){
            ria_form=1
        }
        let spouse_sign=0
        if(this.state.status.spouse_sign==='Y'){spouse_sign=1}
        let kyc=0
        if(this.state.status.kyc==='Y'){
            kyc=1
        }
        let family_details=0
        if(this.state.status.family_details==='Y'){
            family_details=1
        }
        let bank=0
        if(this.state.status.bank==='Y'){
            bank=1
        }
        let fatca=0
        if(this.state.status.fatca==='Y'){
            fatca=1
        }
        let nomination=0
        if(this.state.status.nomination==='Y'){
            nomination=1
        }
        let income_expense=0
        if(this.state.status.income==='Y'){
            income_expense=1
        }
        let expense=0
        if(this.state.status.expense==='Y'){
            expense=1
        }
        let insurance=0
        if(this.state.status.insurance_policy==='Y'){
            insurance=1
        }
        let asset=0
        if(this.state.status.asset==='Y'){
            asset=1
        }
        let liability=0
        if(this.state.status.liabilities==='Y'){
            liability=1
        }
        let goals=0
        if(this.state.status.goals==='Y'){
            goals=1
        }
        return (
            <div>
                {this.props.admin===1?(
                <div className='modalHeadBar'>
        <Row align='middle'>
          <Col span={6}>
            <div className='modalHeadBarTitle'>{this.props.tabName}</div>
          </Col>
          <Col span={12}>
            <div className='modalHeadBarTab'>
              {/* <Tabs defaultActiveKey={defaultTab} onChange={onChange} size='small' centered={true}>
                <TabPane tab='Margins' key='Margins'></TabPane>
                <TabPane tab='Orders' key='Orders'></TabPane>
                <TabPane tab='Positions' key='Positions'></TabPane>
                <TabPane tab='Holdings' key='Holdings'></TabPane>
              </Tabs> */}
            </div>
          </Col>
          <Col span={5}>
            <div className='modalHeadBarTitle'>{this.props.cusName}</div>
            <p className='modalHeadBarP'>{this.props.cusEmail}</p>
          </Col>
          <Col span={1}>
            <div className='TableActionButtonClose' onClick={this.props.close}>Close</div>
          </Col>
        </Row>
      </div>):null}
                <div className='row'>
                    <div className='col-lg-3 col-md-3'>
                        {this.state.compliace?(<div className='admin-edit-tab-active' onClick={this.compliance}>Compliance</div>):( <div className='admin-edit-tab' onClick={this.compliance}>Compliance</div>)}
                    </div>
                    <div className='col-lg-3 col-md-3'>
                        {this.state.profile?( <div className='admin-edit-tab-active'>Profile Setup</div>):( <div className='admin-edit-tab' onClick={this.profile}>Profile Setup</div>)}
                    </div>
                    <div className='col-lg-3 col-md-3'>
                        {this.state.finance_profile?(<div className='admin-edit-tab-active'>Financial Profile setup</div>):(<div className='admin-edit-tab' onClick={this.finance}>Financial Profile setup</div>)}
                    </div>
                </div>
{/* -------------------------------compliance screen tabs-------------------------------- */}
{this.state.compliace ?(
       <div className='edit-compnent-div'>
            <div className='row'>
                <div class='col-md-2 pr-0'>
                    {this.state.step1?(
                        <div className='upld-compnent-head'>User Info 
                        {userInfo===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step1}>User Info
                          {userInfo===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
                <div class='col-md-2 pr-0 pl-0'>
                    {this.state.step2?(
                        <div className='upld-compnent-head'>Address
                          {address===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step2}>Address
                          {address===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
                <div class='col-md-2 pr-0 pl-0'>
                    {this.state.step3?(
                        <div className='upld-compnent-head'>Signature
                          {sign===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step3}>Signature
                          {sign===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
                <div class='col-md-3 pr-0 pl-0'>
                    {this.state.step4?(
                        <div className='upld-compnent-head'>Risk Profiling Form
                        {risk_form===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step4}>Risk Profiling Form{risk_form===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
                <div class='col-md-3 pl-0'>
                    {this.state.step5?(
                        <div className='upld-compnent-head'>RIA Agreement
                          {ria_form===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step5}>RIA Agreement
                          {ria_form===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
            </div>
{/* ---------------------------compliance screens-------------------------------- */}
{this.state.step1 && userInfo===1 ?(<div className='substep-component-form-edit'><UserInfo update={this.update} step2={this.step2} userInfo={userInfo} ria_form={ria_form} admin={this.props.admin} spouse_sign={spouse_sign} id={this.props.id}/></div>):(<div></div>)}

{this.state.step2?(<div className='substep-component-form-edit'><Address update={this.update} step1={this.step1} step3={this.step3}  address={address} userInfo={userInfo} id={this.props.id}/></div>):(<div></div>)}

{this.state.step3?(<div className='substep-component-form-edit'><Signature update={this.update} step2={this.step2} step4={this.step4} sign={sign} address={address} id={this.props.id} /></div>):(<div></div>)}

{this.state.step4?(<div className='substep-component-form-edit'><Otp update={this.update} step3={this.step3} step5={this.step5}  sign={sign} risk_form={risk_form} id={this.props.id}/></div>):(<div></div>)}

{this.state.step5?(<div className='substep-component-form-edit'><RIA update={this.update}  step4={this.step4} profile={this.profile} risk_form={risk_form} ria_form={ria_form} id={this.props.id} admin={this.props.admin} cusEmail={this.props.cusEmail}/></div>):(<div></div>)}


</div>):(<div></div>)}


{/* -------------------------------profile setup screen tabs-------------------------------- */}
{this.state.profile ?(
       <div className='edit-compnent-div'>
           <div className='row'>
                <div class='col-md-2 pr-0'>
                    {this.state.step1?(
                        <div className='upld-compnent-head'>KYC
                          {kyc===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step1}>KYC
                          {kyc===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
                <div class='col-md-2 pr-0 pl-0'>
                    {this.state.step2?(
                        <div className='upld-compnent-head'>Family
                          {family_details===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step2}>Family
                          {family_details===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
                <div class='col-md-2 pr-0 pl-0'>
                    {this.state.step3?(
                        <div className='upld-compnent-head'>Bank Details
                          {bank===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step3}>Bank Details
                          {bank===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
                <div class='col-md-2 pr-0 pl-0'>
                    {this.state.step4?(
                        <div className='upld-compnent-head'>Fatca Details
                          {fatca===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step4}>Fatca Details
                          {fatca===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>

                <div class='col-md-2 pr-0 pl-0'>
                    {this.state.step5?(
                        <div className='upld-compnent-head'>Nomination
                          {nomination===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step5}>Nomination
                          {nomination===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
            </div>
{/* ---------------------------profile setup screens-------------------------------- */}
{this.state.step1?(<div className='substep-component-form-edit'><KycDetails update={this.update} kyc={kyc} step2={this.step2} ria_form={ria_form} compliancePrev={this.compliancePrev} id={this.props.id}/></div>):(<div></div>)}

{this.state.step2?(<div className='substep-component-form-edit'><Family update={this.update} family_details={family_details} step1={this.step1} kyc={kyc} step3={this.step3} id={this.props.id}/></div>):(<div></div>)}

{this.state.step3?(<div className='substep-component-form-edit'><Bank update={this.update} bank={bank} step2={this.step2} step4={this.step4} family_details={family_details} id={this.props.id}/></div>):(<div></div>)}

{this.state.step4?(<div className='substep-component-form-edit'><Fatca update={this.update} fatca={fatca} step3={this.step3} step5={this.step5}bank={bank} id={this.props.id}/></div>):(<div></div>)}

{this.state.step5?(<div className='substep-component-form-edit'><Nominee update={this.update} nomination={nomination} step4={this.step4} finance={this.finance} fatca={fatca} id={this.props.id}/></div>):(<div></div>)}

{/* {this.state.step5?(<div className='substep-component-form-edit'><KycDetails update={this.update} kyc={kyc} step4={this.step4} finance={this.finance} nomination={nomination}/></div>):(<div></div>)} */}

</div>):(<div></div>)}
{/* ---------------------------------finance screen tabs----------------------------------- */}
{this.state.finance_profile?(
       <div className='edit-compnent-div'>
            <div className='row'>
                <div class='col-md-2 pr-0'>
                    {this.state.step1?(
                        <div className='upld-compnent-head'>Income
                          {income_expense===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step1}>Income
                          {income_expense===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
                <div class='col-md-2 pr-0 pl-0'>
                    {this.state.step2?(
                        <div className='upld-compnent-head'>Expense
                          {expense===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step2}>Expense
                          {expense===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
                <div class='col-md-2 pr-0 pl-0'>
                    {this.state.step3?(
                        <div className='upld-compnent-head'>Insurance Policy
                          {insurance===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step3}>Insurance Policy  {insurance===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>

                <div class='col-md-2 pr-0 pl-0'>
                    {this.state.step4?(
                        <div className='upld-compnent-head'>Asset
                          {asset===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step4}>Asset
                          {asset===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>

                <div class='col-md-2 pr-0 pl-0'>
                    {this.state.step5?(
                        <div className='upld-compnent-head'>Liability
                          {liability===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step5}>Liability
                          {liability===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>

                <div class='col-md-2 pl-0'>
                    {this.state.step6?(
                        <div className='upld-compnent-head'>Goals
                          {goals===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    ):(
                        <div className='upld-compnent-head-inactive'onClick={this.step6}>Goals
                          {goals===1?( <span className='upload-act'><i class="fas fa-check-circle"></i></span>):null}</div>
                    )}
                </div>
            </div>
{/* ---------------------------Finance profile screens-------------------------------- */}
{this.state.step1?(<div className='substep-component-form-edit'><IncomeExpence update={this.update} income_expense={income_expense} step2={this.step2} income_previous={this.income_previous} nomination={nomination} id={this.props.id}userInfo={userInfo}/></div>):(<div></div>)}

{this.state.step2?(<div className='substep-component-form-edit'><Expense update={this.update} expense={expense} step1={this.step1} step3={this.step3} income_expense={income_expense} id={this.props.id} /></div>):(<div></div>)}

{this.state.step3?(<div className='substep-component-form-edit'><InsurancePolicy update={this.update} insurance={insurance} step2={this.step2} step4={this.step4} expense={expense} id={this.props.id}/></div>):(<div></div>)}

{this.state.step4?(<div className='substep-component-form-edit'><Asset update={this.update} asset={asset} step3={this.step3} step5={this.step5} insurance={insurance}  id={this.props.id}/></div>):(<div></div>)}

{this.state.step5?(<div className='substep-component-form-edit'><Liabilities update={this.update} asset={asset} step4={this.step4} step6={this.step6} liability={liability} id={this.props.id}/></div>):(<div></div>)}

{this.state.step6?(<div className='substep-component-form-edit'><Goals update={this.update} goals={goals} step5={this.step5} liability={liability} compliance={this.compliance} id={this.props.id}/></div>):(<div></div>)}

</div>):(<div></div>)}

       </div>
        )
    }
}

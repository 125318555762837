import React, { Component } from 'react'
import {Link} from 'react-router-dom'
// import {ReactComponent as Logob} from "../Images/logo_black.svg"
import Logob from "../../../Images/logoBlack.png"
import Navbar from './Navbar'
import notFound from '../../../Images/not_found.png'
export default class NotFound extends Component {
    render() {
      // console.log = console.warn = console.error = () => {};
      // console.clear()
        return (
          <>
          <div><Navbar /></div>  
          <div className='bodyDiv'> 
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='notFoundImage'>
                    <img src={notFound} alt=''></img>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div>
                    <p className='notFoundMsg'>Server is upgrading, please visit after some time.</p>

                    <div className='sectionButtonDiv'>
                      <a href='home' style={{textDecoration:'none'}}>
                          <div className='sectionButton' style={{width:'70px'}}>
                              <span className='whatText'>Ok</span>
                              <div className='sectionButtonDesign'></div>
                          </div>
                      </a>
                    </div>
                    {/* <div className="error-actions">
                      <Link to='/'> <button className='notfoundbtn'>Ok</button></Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          </>
        )
    }
}

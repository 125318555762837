import React, { useState,useEffect} from 'react'
import './Reporting.scss'
import {DatePicker} from 'antd';
import {holding_report} from './ReportsApi'
import HoldingTable from './HoldingTable';
import Loader from '../../../shared/LoaderTransparent';


const HoldingReport = (props) => {
    const [data, setData] = useState([]);
    const [cDate, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [dd, setDD] = useState(new Date().toISOString().split('T')[0]);
    const [loading,setLoading] = useState(false)
    
    let pdf1=null
    const onChange = (date, dateString) => {
      fetchHoldings(dateString)
      setDD(dateString)
    };
   
    const fetchHoldings=(date)=>{
      setLoading(true)
      const input = {
          access_token:localStorage.token,
          id:props.id,
          date:date,
          clientwise:'N'
      }
      console.log(input);
      holding_report(input).then(res => {
        console.log('holding',res);
        try{
          if (res.status==='successfull'){
            setLoading(false)
            setData(res.result)
          }else{
            setLoading(false)
          }
        }catch{
          setLoading(false)
        }
        })
    }
         
    useEffect(()=>{
      fetchHoldings(cDate)
    },[])


    const exportData=(name)=>{
      const input = {
          access_token:localStorage.token,
          id:props.id,
          date:dd,
          flag:'Y'
      }
      console.log(input);
      holding_report(input).then(res => {
        console.log('ho_expo',res);
        try{
          if (res.status==='successfull'){
            pdf1=res.result
            const linkSource = `data:application/vnd.ms-excel;base64,${pdf1}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = `Holdings_${name}_${cDate}.xlsx`;
            downloadLink.click();
          }else{}
        }catch{}
        })
    }

    console.log(cDate);
  return (
    <div> 
    {loading?<Loader/>:null}
      {Object.keys(data).length>0?(
        <>
        <div className='reportRangebox'>
            <DatePicker onChange={onChange} placeholder={cDate}
            disabledDate={(current) => {
            return current && current.valueOf() > Date.now();
            }} />
        </div>
          {Object.keys(data).map((val)=>{
            if(data[val]===null){
              return(<div style={{textAlign:'center', padding:'10px'}}>No Data</div>)
            }else{
              return(
                <div className='holFamCard'>
                  <div className='famName'>{val}</div>
                  <div className='csvDwn'><button onClick={(name)=>exportData(val)}>Export</button></div>
                  <HoldingTable id={props.id} xirrstatus='N' dataMain={data[val]}/>
                </div>
              )
            }
          })}
        </>
      ):null}
    </div>
  )
}

export default HoldingReport
import React,{useState,useEffect} from 'react'
import {Form,Input,Select,Row,Col,Button,message} from 'antd';
import {customer_review,admin_billing,billing_get} from '../Api'
import BillingDetailsTable from './BillingDetailsTable';
import Loader from '../../../shared/Loader';

const Billing = (props) => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [clientEmail,setClientEmail] = useState('')
    const [loading,setLoading] = useState(false)

    const fetchUser = async () =>{
        const input = {access_token:localStorage.token,id:props.id}
        const res = await customer_review(input)
        console.log(input,"resss",res)
        if(res.status==='successfull'){
            setClientEmail(res.result)
        }
      }

      const getBillingDetails = async()=>{
        setLoading(true)
        let newdata = []
        const input = {access_token:localStorage.token}
        const res = await billing_get(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.keys(res.result).map((val)=>{
              newdata.push({ 
              key: res.result[val].key,
              date:res.result[val].date,
              email:res.result[val].email,
              agreement_date:res.result[val].agreement_date,
              investment_amount:res.result[val].investment_amount,
              // paid_so_far:res.result[val].paid_so_far,
              // balance_due:res.result[val].balance_due,
              est_fees:res.result[val].est_fees
              })
            })
            setData(newdata)
            setLoading(false)
          }
          else{setLoading(false); message.error(res.message)}
        }catch{setLoading(false); message.error('Something went wrong')}
      }

      useEffect(() =>{
        fetchUser();
        getBillingDetails();
      },[])
      
      const onFinish = async (values) => {
        setLoading(true)
        const input = {access_token:localStorage.token,values:values}
        const res = await admin_billing(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('status',res,input);
        try{
            if (res.status==='successfull') {
              getBillingDetails();
              message.success(res.message);
              setLoading(false);
              form.resetFields();
            }
            else{ 
                message.error(res.message)
                setLoading(false)}
        }catch{ 
            message.error('Something went wrong')
            setLoading(false)
        }
      };

    const { Option } = Select;

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24}
      };

  return (
      <>
      {loading?<Loader/>:null}
      <div className='formContainer'>
        <Form
        {...formItemLayout}
        form={form}
        name="billing"
        onFinish={onFinish}
        scrollToFirstError
        >
        <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                    name="id"
                    label="User Email"
                    rules={[{required: true}]}>
                        <Select
                            placeholder="Select Email">
                              {clientEmail?   
                                Object.keys(clientEmail).map((val)=>{
                                    return(<Option value={clientEmail[val].customer_id}>{clientEmail[val].email_address}</Option>)
                                }):null}
                        </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                    name="agreement_date"
                    label="Agreement Date"
                    rules={[{required: true, message: 'select Date', whitespace: true,}]}
                >
                <input type='date'/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                    name="investment_amount"
                    label="Investment Amount"
                    rules={[{required: true, message: 'Please fill!', whitespace: true,}]}
                >
                    <Input prefix={<b>₹</b>}/>
                </Form.Item>
              </Col>
              {/* <Col span={6}>
                <Form.Item
                    name="paid_so_far"
                    label="Paid so far for the current year"
                    rules={[{required: true, message: 'Please fill!', whitespace: true,}]}
                >
                    <Input />
                </Form.Item>
              </Col> */}
        </Row>

        <Row gutter={24}>
            {/* <Col span={6}>
                <Form.Item
                    name="balance_due"
                    label="Balance Due"
                    rules={[{required: true, message: 'Please fill!', whitespace: true,}]}
                >
                    <Input prefix={<b>₹</b>}/>
                </Form.Item>
            </Col> */}

            <Col span={6}>
                <Form.Item
                    name="est_fees"
                    label="Estd.Fees for the contract"
                    rules={[{required: true, message: 'Please fill!', whitespace: true,}]}
                >
                    <Input prefix={<b>₹</b>}/>
                </Form.Item>
            </Col>
        </Row>
        <Form.Item>
            <Button className='SolidButton' htmlType="submit" >Submit</Button>
        </Form.Item>
    </Form>
    
    <BillingDetailsTable/>
    </div>
    </>
  )
}

export default Billing
import React, { useState,useEffect} from 'react'
import './SetupJourney.scss'
import './SS.scss'
import './SetupJourneyMediaQuery.scss'
import {user_profile_status} from './Api'

import UserInfo from '../Compliance/UserInfo'
import Address from '../Compliance/AddressDetails'
import Signature from '../Compliance/Signature'
import Otp from '../Compliance/Otp'
import RIA from '../Compliance/RIA_formFun'

import Family from '../Profile-setup/FamilyExel'
import Bank from '../Profile-setup/BankDetails'
import Fatca from '../Profile-setup//FatcaDetails'
import Nominee from '../Profile-setup/NomineeExcel'
import KycDetails from '../Profile-setup/KycDetails'

import IncomeExpence from '../FinancialProfile/Income'
import Expense from '../FinancialProfile/Expense3'
import InsurancePolicy from '../FinancialProfile/InsuranceExel'
import Asset from '../FinancialProfile/Asset2'
import Liabilities from '../FinancialProfile/LiabilityExel'
import Goals from '../FinancialProfile/GoalExel'

import  Main from "../../Images/logoBlack.png"
import profile_pic from '../../Images/user_i.png'
import User from "../../Images/user.png"
import logo from "../../Images/logoBlack.png"
import logoSingle from "../../Images/hoa_logo_single.png"
import NoInternet from '../../shared/NoInternet'

import ChangeEmail from '../Setting/ChangeEmail'
import ChangePassword from '../Setting/ChangePassword'

import ReactTooltip from 'react-tooltip';
import {MDBIcon} from 'mdbreact'
import {useNavigate} from 'react-router-dom'

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import SweetAlert from 'react-bootstrap-sweetalert';
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import { Menu,Row, Col,Avatar, Dropdown,Tooltip,Modal,Progress } from 'antd';

const SetupJourney = (props) => {
    const[sideTab, setSideTab] = useState({compliance:false,profile:false,financeProfile:false})
    const[steps,setSteps] = useState({step1:false,step2:false,step3:false,step4:false,step5:false,step6:false})
    const[status,setStatus] = useState({})
    const[sideDrawer,setSideDrawer] = useState(false)
    const[modal,setModal] = useState({emailModal:false,passwordModal:false})
    const[index,setIndex] = useState(0)
    const[online,setOnline] = useState(true)

    const navigate = useNavigate()

    const fetchStatus = async () =>{
        const input = {access_token:localStorage.token}
        const res = await user_profile_status(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('status',res);
        return res
    }
    const checkOnline=()=>{
        const on = navigator.onLine
        if(!on){setOnline(false)}
        else{setOnline(true)}
      }

    useEffect(()=>{
        setInterval(checkOnline,10000)
        if(!localStorage.token ){navigate('/')}
        if(status.progress>=100){return  (navigate('/dashboard'))}
        fetchStatus().then(res => {
            try{
                if (res.status==='successfull'){
                    setStatus(res.result)
                const current=res.result.userInfo==='N'?(handleTab('compliance'),handleStep('step1')):
                    res.result.address==='N'?(handleTab('compliance'),handleStep('step2')):
                    res.result.sign==='N'?(handleTab('compliance'),handleStep('step3')):
                    res.result.risk_profiling_form==='N'?(handleTab('compliance'),handleStep('step4')):
                    res.result.ria_agreement==='N'?(handleTab('compliance'),handleStep('step5')):
                    res.result.kyc==='N'?(handleTab('profile'),handleStep('step1')):
                    res.result.family_details==='N'?(handleTab('profile'),handleStep('step2')):
                    res.result.bank==='N'?(handleTab('profile'),handleStep('step3')):
                    res.result.fatca==='N'?(handleTab('profile'),handleStep('step4')):
                    res.result.nomination==='N'?(handleTab('profile'),handleStep('step5')):
                    res.result.income==='N'?(handleTab('financeProfile'),handleStep('step1')):
                    res.result.expense==='N'?(handleTab('financeProfile'),handleStep('step2')):
                    res.result.insurance_policy==='N'?(handleTab('financeProfile'),handleStep('step3')):
                    res.result.asset==='N'?(handleTab('financeProfile'),handleStep('step4')):
                    res.result.liabilities==='N'?(handleTab('financeProfile'),handleStep('step5')):
                    res.result.goals==='N'?(handleTab('financeProfile'),handleStep('step6')):null
                }else{}
            }catch{}
        })
    },[])

    const handleUpdate=()=>{
        console.log("update");
        fetchStatus().then(res => {
            try{
                if (res.status==='successfull'){setStatus(res.result)}
                else{}
            }catch{}
        })
    }

    const handleTab=(name)=>{
        let dict = {...sideTab}
       Object.keys(dict).map(item => {
        if(item===name){dict[item] = true
        }else{dict[item] = false}
        });
        setSideTab(dict)
        handleStep('step1')
    }
    const handleStep=(name)=>{
        let dict = {...steps}
        Object.keys(dict).map(item => {
            if(item===name){dict[item] = true
            }else{dict[item] = false}
            });
        setSteps(dict)
    }
    const handleModal=(name)=>{
        let dict = {...modal}
        Object.keys(dict).map(item => {
            if(item===name){dict[item] = true
            }else{dict[item] = false}
            });
        setModal(dict)
    }
    const handleCompliance = () =>{
        handleTab('compliance');
        handleStep('step1');
        setIndex(0)
    }
    const handleProfile = () =>{
        handleTab('profile');
        handleStep('step1');
        setIndex(0)
    }
    const handleFinanceProfile = () =>{
        handleTab('financeProfile');
        handleStep('step1');
        setIndex(0)
    }
    const compliancePrev=()=>{
        handleTab('compliance');
        handleStep('step5');
    }
    const incomePrevious=()=>{
        handleTab('profile');
        handleStep('step5');
    }
    const handleSideDrawer=(action)=>{setSideDrawer(action)}
    const left_arrow=()=>{setIndex(index-1)}
    const right_arrow=()=>{setIndex(index+1)}

    const logOut=(e)=>{
        e.preventDefault()
        localStorage.removeItem('token')
        localStorage.removeItem('email')
        navigate('/')
    }
 

    const setting = (
      <Menu>
        <Menu.Item onClick={()=>handleModal('emailModal')}>Change Email</Menu.Item>
        <Menu.Item onClick={()=>handleModal('passwordModal')}>Change Password</Menu.Item>
      </Menu>
    );
  

    let [userInfo,address,sign,spouse_sign,risk_form,ria_form,kyc,family_details,bank,fatca,nomination,income_expense,expense,insurance,asset,liability,goals] = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

    if(status.userInfo==='Y'){userInfo=1}
    if(status.address==='Y'){address=1} 
    if(status.sign==='Y'){sign=1}
    if(status.spouse_sign==='Y'){spouse_sign=1}
    if(status.risk_profiling_form==='Y'){risk_form=1} 
    if(status.ria_agreement==='Y'){ria_form=1}
    if(status.kyc==='Y'){kyc=1}
    if(status.family_details==='Y'){family_details=1}
    if(status.bank==='Y'){bank=1}
    if(status.fatca==='Y'){fatca=1}
    if(status.nomination==='Y'){nomination=1}
    if(status.income==='Y'){income_expense=1}
    if(status.expense==='Y'){expense=1}
    if(status.insurance_policy==='Y'){insurance=1}
    if(status.asset==='Y'){asset=1}
    if(status.liabilities==='Y'){liability=1}
    if(status.goals==='Y'){goals=1}

    if(status.progress>=100){navigate('/dashboard')}
 
    const ComplianceCards=[
                <li className={steps.step1?"activee sjStepcard":status.userInfo==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step1')}><MDBIcon fas icon="user-alt" className='sjStepcardI'/>User Info</li>,

                <li className={steps.step2?"activee sjStepcard":status.address==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step2')}><MDBIcon fas icon="map-marked-alt" className='sjStepcardI' />Address</li>,
    
                <li className={steps.step3?"activee sjStepcard":status.sign==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step3')}>
                <MDBIcon fas icon="signature"  className='sjStepcardI'/>Signature</li>,

                <li className={steps.step4?"activee sjStepcard":status.sign==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step4')} style={{width:'190px'}}><MDBIcon fas icon="file-contract" className='sjStepcardI' />
                Risk Profiling Form</li>,

                <li className={steps.step5?"activee sjStepcard":status.sign==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step5')}><MDBIcon fas icon="file-contract" className='sjStepcardI' />
                RIA Agreement</li>
            ]
    const ComplianceComponent=()=>{
        return(<>
         {steps.step1?<div className='component-form scrollB'><UserInfo update={handleUpdate} step2={()=>handleStep('step2')} userInfo={userInfo} ria_form={ria_form} spouse_sign={spouse_sign}/></div>:null}

        {steps.step2?<div className='component-form scrollB'><Address update={handleUpdate} step1={()=>handleStep('step1')} step3={()=>handleStep('step3')}  address={address} userInfo={userInfo} ria_form={ria_form} /></div>:null}

        {steps.step3?<div className='component-form scrollB'><Signature update={handleUpdate} step2={()=>handleStep('step2')} step4={()=>handleStep('step4')} sign={sign} address={address} ria_form={ria_form} /></div>:null}

        {steps.step4?<div className='component-form scrollB'><Otp update={handleUpdate} step3={()=>handleStep('step3')} step5={()=>handleStep('step5')}  sign={sign} risk_form={risk_form}/></div>:null}

        {steps.step5?<div className='component-form scrollB'><RIA update={handleUpdate}  step4={()=>handleStep('step4')} profile={handleProfile} risk_form={risk_form} ria_form={ria_form}/></div>:null}
        </>)
    }
    const ProfileCards=[
                <li class={steps.step1?"activee sjStepcard":status.kyc==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step1')}>
                <MDBIcon fas icon="user-check" className='sjStepcardI'/>KYC
                </li>,
          
               <li class={steps.step2?"activee sjStepcard":status.family_details==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step2')}>
                <MDBIcon fas icon="users" className='sjStepcardI'/>Family
               </li>,
          
               <li class={steps.step3?"activee sjStepcard":status.bank==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step3')}>
                <MDBIcon fas icon="university" className='sjStepcardI'/>Bank
               </li>,
           
               <li class={steps.step4?"activee sjStepcard":status.fatca==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step4')}>
                <MDBIcon far icon="file-alt" className='sjStepcardI'/>Fatca
               </li>,
           
               <li class={steps.step5?"activee sjStepcard":status.nomination==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step5')}>
                <MDBIcon fas icon="user-friends" className='sjStepcardI'/>Nominee
               </li>]
               
    const ProfileComponent=()=>{
        return(<>
            {steps.step1?(<div className='component-form scrollB'><KycDetails update={handleUpdate} kyc={kyc} step2={()=>handleStep('step2')} ria_form={ria_form} compliancePrev={compliancePrev}/></div>):null}

            {steps.step2?(<div className='component-form scrollB'><Family update={handleUpdate} family_details={family_details} step1={()=>handleStep('step1')} kyc={kyc} step3={()=>handleStep('step3')}/></div>):null}

            {steps.step3?(<div className='component-form scrollB'><Bank update={handleUpdate} bank={bank} step2={()=>handleStep('step2')} step4={()=>handleStep('step4')} family_details={family_details}/></div>):null}

            {steps.step4?(<div className='component-form scrollB'><Fatca update={handleUpdate} fatca={fatca} step3={()=>handleStep('step3')} step5={()=>handleStep('step5')}bank={bank}/></div>):null}

            {steps.step5?<div className='component-form scrollB'><Nominee update={handleUpdate} nomination={nomination} step4={()=>handleStep('step4')} finance={handleFinanceProfile} fatca={fatca} /></div>:null}
        </>)
    }
    const FinanceProfileCard=[
                <li className={steps.step1?"activee sjStepcard":status.income==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step1')}>
                    <MDBIcon fas icon="money-bill-alt" className='sjStepcardI'/>Income
                </li>,

                <li className={steps.step2?"activee sjStepcard":status.expense==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step2')}>
                    <MDBIcon fas icon="money-bill-wave" className='sjStepcardI'/>Expense
                </li>,

                <li className={steps.step3?"activee sjStepcard":status.insurance_policy==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step3')}>
                    <MDBIcon fas icon="user-shield" className='sjStepcardI'/>Insurance
                </li>,

                <li className={steps.step4?"activee sjStepcard":status.asset==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step4')}>
                    <MDBIcon fas icon="hand-holding-usd" className='sjStepcardI'/>Asset
                </li>,
                
                <li className={steps.step5?"activee sjStepcard":status.liabilities==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step5')}>
                    <MDBIcon fas icon="money-check-alt" className='sjStepcardI'/>Liability
                </li>,

                <li className={steps.step6?"activee sjStepcard":status.goals==='Y'?'sjStepcard':"sjStepcardWait"} onClick={()=>handleStep('step6')}>
                    <MDBIcon far icon="dot-circle" className='sjStepcardI'/>Goals
                </li>
            ]
    const FinanceProfileComponent=()=>{
        return(<>
            {steps.step1?(<div className='component-form scrollB'><IncomeExpence update={handleUpdate} income_expense={income_expense} step2={()=>handleStep('step2')} income_previous={incomePrevious} nomination={nomination}  userInfo={userInfo}/></div>):null}

            {steps.step2?(<div className='component-form scrollB'><Expense update={handleUpdate} expense={expense} step1={()=>handleStep('step1')} step3={()=>handleStep('step3')} income_expense={income_expense}  /></div>):null}

            {steps.step3?(<div className='component-form scrollB'><InsurancePolicy update={handleUpdate} insurance={insurance} step2={()=>handleStep('step2')} step4={()=>handleStep('step4')} expense={expense} /></div>):null}

            {steps.step4?(<div className='component-form scrollB'><Asset update={handleUpdate} asset={asset} step3={()=>handleStep('step3')} step5={()=>handleStep('step5')} insurance={insurance}/></div>):null}

            {steps.step5?(<div className='component-form scrollB'><Liabilities update={handleUpdate} asset={asset} step4={()=>handleStep('step4')} step6={()=>handleStep('step6')} liability={liability}/></div>):null}

            {steps.step6?(<div className='component-form scrollB'><Goals update={handleUpdate} goals={goals} step5={()=>handleStep('step5')} liability={liability} compliance={handleCompliance}/></div>):null}
        </>)
    }
    const responsive = {
        0: { items: 2 },
        601: { items: 3 },
        1023: { items:5 },
    };
    const SideMenu=()=>{
        return( 
        <div className='sideBarOpen'>
            <span className='mobileInner'><MDBIcon icon="times" onClick={()=>handleSideDrawer(false)} className='drawer-icon-cls'/></span>
            <div className='sidebarLogo'>
                <img src={logo} alt=""/>
            </div>
            <div>
                <div className='NewSJSalutation'>
                    <Avatar src={User} />
                    <div className='NewSJSalName'>{localStorage.email.substring(0,17)}...</div>
                    <Dropdown overlay={setting} placement="bottom" trigger={['click']}>
                        <MDBIcon fas icon="cog" className='NewSJSalI'/>
                    </Dropdown>
                    <Tooltip title="Log Out">
                        <MDBIcon fas icon="sign-out-alt" onClick={logOut} className='NewSJSalI' />
                    </Tooltip>
                </div>
            </div>

            <div class={sideTab.compliance?"sjSide sjSideActive":"sjSide"} onClick={()=>handleTab('compliance')}>
                <MDBIcon fas icon="user-edit" className='newSidebarIconSJ'/><span className='sidebarMenuTitle'>Compliance</span> 
            </div>
            <div class={sideTab.profile?"sjSide sjSideActive":"sjSide"} onClick={()=>handleTab('profile')}>
                <MDBIcon far icon="id-card" className='newSidebarIconSJ'/><span className='sidebarMenuTitle'>Profile Setup</span>
            </div>
            <div class={sideTab.financeProfile?"sjSide sjSideActive":"sjSide"} onClick={()=>handleTab('financeProfile')}>
                <MDBIcon fas icon="money-check-alt" className='newSidebarIconSJ'/><span className='sidebarMenuTitle'> 
                <Tooltip title="Financial Profile Setup">Financial Profile..</Tooltip></span>
            </div>
            <div className='SjDesp'>
            {sideTab.compliance?
                <>
                    1. This RIA agreement is needed to adhere to SEBI regulations applicable to Investment Advisers <br/><br/>
                    2. This Risk Profiling data helps us to understand your risk tolerance & we will create a suitable investment plan for you
                </>:null}
            {sideTab.profile?
                <>
                   Your bank details are needed to set up your account to process your mutual fund transactions through this platform. Please be rest assured that no transaction will happen without your specific approval.
                </>:null}
            {sideTab.financeProfile?
                <>
                    The financial data that you provide are confidential and we do not share them with anyone outside our financial planning team.<br/><br/>
                    achieving your financial goals.
                </>:null}
            </div>
         

            <div className='sidebarToggleButton' style={{marginLeft:'8px'}}>
                <Progress 
                    trailColor='#f8d8bb' 
                    strokeColor='#E87C1B'  
                    percent={status.progress} 
                    status="active"
                    type='dashboard'
                />
            </div>
        </div>
        )}
    
    return (
      <div className='parentDivBg'>
        {!online?<NoInternet/>:null}
        <div className='desktopInner'><SideMenu/></div>
        <div className='mobileInner'>
            {sideDrawer?<SideMenu/>:
            <MDBIcon icon="bars" className='drawer-icon' onClick={()=>handleSideDrawer(true)}/>}
        </div>
        <div className='dashboardContentAreaOpen'>
            <div className='desktopInner'>
                <div> 
                    {sideTab.compliance?(<ul className='sjStepcard-ul'>{ComplianceCards}</ul>):null}
                </div>
                <div> 
                    {sideTab.profile?<ul className='sjStepcard-ul'>{ProfileCards}</ul>:null}
                </div>
                <div>
                    {sideTab.financeProfile?<ul className='sjStepcard-ul'>{FinanceProfileCard}</ul>:null}
                </div>
                {sideTab.compliance?(<ComplianceComponent/>):null}
                {sideTab.profile?<ProfileComponent/>:null}
                {sideTab.financeProfile?<FinanceProfileComponent/>:null}
            </div>
            <div className='mobileInner'>
            {sideTab.compliance?
            <>
                <div className='row'>
                    <div className='col-1'></div>
                    <div className='col-1'>
                        {index===0?(<span></span>):(<MDBIcon icon="angle-left" onClick={left_arrow} className='cr-icon'/>)}
                    </div>
                    <div className='col-8'>
                        <AliceCarousel
                            mouseTracking
                            items={ComplianceCards}
                            responsive={responsive}
                            activeIndex={index}
                            disableDotsControls={true}
                            disableButtonsControls={true}/>
                    </div>
                    <div className='col-1'>
                        {index===4?null:(<MDBIcon icon="angle-right"onClick={right_arrow} className='cr-icon'/>)}
                    </div>
                </div>
                <ComplianceComponent/>
            </>:null}

            {sideTab.profile?(
                <>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-1'>
                            {index===0?(<span></span>):(<MDBIcon icon="angle-left" onClick={left_arrow} className='cr-icon'/>)}
                        </div>
                        <div className='col-8'>
                            <div className='steps-crousal'>
                            <AliceCarousel
                                mouseTracking
                                items={ProfileCards}
                                responsive={responsive}
                                activeIndex={index}
                                disableDotsControls={true}
                                disableButtonsControls={true}/>
                            </div>
                        </div>
                        <div className='col-1'>
                            {index===4?(<span></span>):(<MDBIcon icon="angle-right"onClick={right_arrow} className='cr-icon'/>)}
                        </div>
                    </div>
                    <ProfileComponent/>
                </>):null}

                {sideTab.financeProfile?(
                <>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-1'>
                            {index===0?(<span></span>):(<MDBIcon icon="angle-left" onClick={left_arrow} className='cr-icon'/>)}
                        </div>
                        <div className='col-8'>
                            <div className='steps-crousal'>
                            <AliceCarousel
                                mouseTracking
                                items={FinanceProfileCard}
                                responsive={responsive}
                                activeIndex={index}
                                disableDotsControls={true}
                                disableButtonsControls={true}/>
                            </div>
                        </div>
                        <div className='col-1'>
                            {index===5?(<span></span>):(<MDBIcon icon="angle-right"onClick={right_arrow} className='cr-icon'/>)}
                        </div>
                    </div>
                    <FinanceProfileComponent/>
                </>):null}
            </div>
        </div> 

        <Modal  footer={null} visible={modal.emailModal || modal.passwordModal} onCancel={()=>handleModal('close')}>
         {modal.emailModal? <ChangeEmail/>:null}
         {modal.passwordModal? <ChangePassword/>:null}
    </Modal>

    </div>
    )
}
export default SetupJourney
import React, { useState,useEffect } from 'react'
import {subsciptions_get,subsciptions_put,subscriptions_delete} from '../Api'
import { Table,Form,Row,Col,Typography,Popconfirm,message,Input,Select } from 'antd';
import { EditFilled,CloseCircleFilled,SaveOutlined,DeleteFilled} from '@ant-design/icons';
import 'antd/dist/antd.css';
import Loader from '../../../shared/Loader';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewsletterSubscription = () => {
    const [data, setData] = useState([]);
    const [loading,setLoading] = useState(false)
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const {Option} = Select;

    const toastMsg =(id,msg,type)=>{
      toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    }
    
    const EditableCell = ({
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      disable,
      ...restProps
    }) => {


    const inputNode = title === 'Status'?
                                    <Select>
                                      <Option value="Y">Y</Option>
                                      <Option value="N">N</Option>
                                    </Select>:
                                    inputType === 'remarks' ? <Input/>:<Input/>
                return (
                    <td {...restProps}>
                        {editing? (
                            <Form.Item
                            name={dataIndex}
                            rules={[{required: true,message: `Please Input ${title}!`}]}>
                            {inputNode}
                            </Form.Item>
                        ) : (
                            children
                        )}
                    </td>
                );
            };

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      status: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

    const getSubscriptions = async()=>{
        setLoading(true)
        let newdata = []
        const input = {access_token:localStorage.token}
        const res = await subsciptions_get(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.keys(res.result).map((val)=>{
              newdata.push({ 
              key: res.result[val].key,
              date:res.result[val].date,
              email:res.result[val].email,
              status:res.result[val].status,
              })
            })
            setData(newdata)
            setLoading(false)
          }
          else{setLoading(false); toast.error(res.message)}
        }catch{setLoading(false); toast.error('Something went wrong')}
      }

    useEffect(()=>{
        getSubscriptions();
    },[])

    const save = async (key) => {
      const id = toast.loading("Please wait...",{toastId:'load'})
          try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
              setData(newData);
              setEditingKey('');
            const input = {
              access_token:localStorage.token,
              row:newData[index],
            }
              subsciptions_put(input).then(res => {
                console.log(res,'abc');
                try{
                  if (res && res.status==='successfull'){
                    toastMsg(id,res.message,'success')
                    getSubscriptions();
                    }
                  else{toastMsg(id,res.message,'error')}
                }catch{toastMsg(id,'Something went wrong','error')}
              })
            } else {
              newData.push(row);
              setData(newData);
              setEditingKey('');
              setLoading(false)
            }
          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
            setLoading(false)
          }
        };

        const handleDelete=(record)=>{
            const id = toast.loading("Please wait...",{toastId:'load'})
            const input={
              access_token:localStorage.token,
              row:record.email,
          }
            subscriptions_delete(input).then(res => {
            try{
            if (res.status==='successfull') {
              getSubscriptions();
              toastMsg(id,res.message,'success')
            }
            else{toastMsg(id,res.message,'error')}
            }
            catch{toastMsg(id,'Subscription deleted Error','error')}
          })  
          }
        //   console.log(row,'aaa')
    const columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          width:120
        },
        {
          title: 'Email',
          dataIndex: 'email',
          width: 180,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          width: 30,
          editable: true,
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            fixed: 'right',
            width: 120,
            render: (_, record) => {
                const editable = isEditing(record);
           
  return editable ? (
                <span>
                    <Typography.Link onClick={() => save(record.key)} style={{marginRight: 8,}}>
                        <span className='outlineSaveButton'>
                        <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
                        </span>
                    </Typography.Link>
                    <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                        <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
                    </Popconfirm>
                </span>
                ) : (
                <span>
                    <Typography.Link disabled={editingKey !== ''}
                        onClick={() => edit(record)}>
                        <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
                    </Typography.Link>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                        <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
                    </Popconfirm>
                </span>
            )
             },
          },
      ];

      const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            }),
          };
        });

  return (
    <div>
        <ToastContainer theme='colored'/>
        {loading?<Loader/>:null}
        <Row>
          <Form form={form} component={false}>
            <Col span={24}>
                  <Table
                    components={{
                    body: {
                        cell: EditableCell,
                      },
                    }}
                    rowClassName="editable-row"
                    columns={mergedColumns}
                    dataSource={data}
                    scroll={{y: 400 }}
                    sticky={true}
                  />
            </Col>
          </Form>
          </Row>
    </div>
  )
}

export default NewsletterSubscription
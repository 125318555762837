import React, { useState,useEffect } from 'react'
import parse from 'html-react-parser';
import Loader from '../../shared/Loader';
import {MDBIcon} from 'mdbreact'
import {get_ria_agreement,save_ria_agreement,get_client_sign,view_compliants_docs,ria_agreement_renew} from './Api'
import adviser from '../../Images/advisor.png'
import { Button, Modal, Tooltip } from 'antd';
import './Compliance.scss'
import $ from 'jquery'

const RIA_formFun = (props) => {
    const{id,ria_form,step4,profile,risk_form,update} = props
    const[data,setData] = useState({})
    const[clientSign,setClientSign] = useState('')
    const[spouseName,setSpouseName] = useState('')
    const[spouseSign,setSpouseSign] = useState('')
    const[riaForm,setRiaForm] = useState(false)
    const[agree,setAgree] = useState(false)
    const[loading,setLoading] = useState(false)
    const[viewModal,setViewModal] = useState(false)
    const[view,setView] = useState(null)
    const[tableData,setTableData] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);

    let pdf1=null

    const getRiaForm = async() =>{
        const input = {
            access_token:localStorage.token,
            id:id
        }
        const res = await get_ria_agreement(input)
        console.log(input);
        console.log('form',res);
        try{
            if (res.status==='successfull') {
              setLoading(false)
              if(props.admin===1){
                setData(res.result[props.cusEmail])
                setSpouseName(res.result[props.cusEmail].spouse_name)
            }
              else{
                setData(res.result[localStorage.email])
                setSpouseName(res.result[localStorage.email].spouse_name)
            }
             
          }
          else{}
        }catch{}
    } 

    const getRiaFormRenew = async() =>{
        const input = {
            access_token:localStorage.token,
            id:id
        }
        const res = await ria_agreement_renew(input)
        console.log(input);
        console.log('renew',res);
        try{
            if (res.status==='successfull') {
                setLoading(false)
                setTableData(res.result)
            }
          else{}
        }catch{}
    } 
    const getClientSign= async() =>{
        const input = {
            access_token:localStorage.token,
            id:id
        }
        const res = await get_client_sign(input)
        try{
            if (res.status==='successfull') {
                console.log('ress',res)
                setRiaForm(true)
                setLoading(false)
                setClientSign('data:image/png;base64,'+ res.result.client)
            if(res.result.spouse){
                setSpouseSign('data:image/png;base64,'+ res.result.spouse)
                }
            }
        else{}
        }catch{}
    } 

    useEffect(()=>{
       if(ria_form===0){ 
        setLoading(true)
        getClientSign()
        getRiaForm()
        }
        else{
            getClientSign()
            getRiaForm()
            getRiaFormRenew()
        }
    },[])

    const viewDocs=(name)=>{
        setIsModalOpen(false)
        setViewModal(true)
        const input = {
            access_token:localStorage.token,
            flag:name,
            id:id,
            dir: 'agreement'
      }
      view_compliants_docs(input).then(res => {
          console.log("viewRes",res)
          try {
              if (res.status==='successfull') {
                    setView(`data:application/pdf;base64,${res.result}`)
              }
              else{}
          } catch (error) {}
          })
      }

      const handlemodal = () => {
        setIsModalOpen(!isModalOpen);
      };

    const submit=(event)=>{
        setLoading(true)
        event.preventDefault();
        const input = {access_token:localStorage.token}
        console.log("input",input)
        save_ria_agreement(input).then(res => {
            console.log("resagreement",res)
            try {
                if (res.status==='successfull') {
                    update();
                    profile();
                    setLoading(false)
                }
                else{}
            } catch (error) {}
        })
    }

    const closeModaldocument=()=>{
        setViewModal(false)
        setView(null)
      }

    const download=(name)=>{
        setIsModalOpen(false)
        const input = {
            access_token:localStorage.token,
            flag:name,
            id:id,
            dir: 'agreement'
        }
        console.log(input);
        view_compliants_docs(input).then(res => {
            console.log(input);
            console.log("resss",res)
            try {
                if (res.status==='successfull') {
                    pdf1=res.result
                    const linkSource = `data:application/pdf;base64,${pdf1}`;
                    const downloadLink = document.createElement("a");
                    const fileName = name;
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
                else{}
            } catch (error) {}
            })
    }

    const agreeCheck=()=>{
        setAgree(!agree)
      }

      $(document).ready(function() {
        $("#terms").scroll(function() {
          var scrollFromTop = $(this).scrollTop();
          var scrollHeight = $(this)[0].scrollHeight - $(this).height();
          var checkboxDivHeight = document.getElementById("checkbox-div")
            .clientHeight;
      
          var scrolled = scrollFromTop > scrollHeight - checkboxDivHeight;
          if (scrolled) {
            $("#payment").removeAttr("disabled")
              $("#checkSpan").removeClass("display");
          }
        });
      });

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
      if (loading){
        return(
          <div style={{textAlign:'center',marginTop:100}}>
          <Loader/>
          </div>
          );}

  return (
        <>
            <div className='form-heading'>Investment Advisory Agreement
            {tableData.flag==='Y'?<>
                <span className='oldAgmButton' onClick={handlemodal}>Old Agreements
                </span></>:null}
            </div>

            <div>
                {ria_form===1?(
                <>
                    <div className='data-inner-div' align='center'>
                    {/* <div className='download-text'>Your RIA Agreement is submitted. You can download it here.
                    </div> */}
                    {tableData.flag==='N'?<>
                        {Object.keys(tableData).length>0?
                        <>
                        <table class="table table-condensed table-sm" border="0">
                        <thead>
                            <tr className='tb_header'>
                                <td>Date</td>
                                <td className='lf-border'>Agreement</td>
                                <td className='lf-border'></td>
                            </tr>
                        </thead>
                        {Object.keys(tableData.agreements).map((val)=>{
                            return(
                              <tbody>
                                  <tr className='tb_body'>
                                    <td>{val}</td>
                                    <td className='l-r-br'>{tableData.agreements[val]}</td>
                                    <td>
                                        <MDBIcon icon="eye" className='mdbicon' onClick={()=>viewDocs(tableData.agreements[val])}/>     
                                        <MDBIcon icon="download" className='mdbicon' onClick={()=>download(tableData.agreements[val])}/>
                                    </td>
                                  </tr>
                              </tbody>
                            )
                        })}
                        </table>
                        </>:null}
                        </>:<>
                        {tableData.flag==='N'?
                        <div className='renewDat'>(Agreement Renewal date is {tableData.renewal_date})</div>
                        :
                        <>
                        <div className='renewAgree scrollB'>
                            {data.para1?<>
                                <div className='para'>{parse(data.para1)}
                                    <span className='bold'>  {date+"/"+month+"/"+year}</span>
                                    <span> {parse(data.para2)}</span>
                                </div>
                                <div className='and'>AND</div>
                                <div className='para'><span style={{fontWeight:'bold'}}>{data.name} {data.spouse_name?(<span>and {data.spouse_name} </span>):null}</span> {parse(data.para3)}
                                {data.address?(<span style={{fontWeight:'bold'}}> {data.address}</span>):(<div></div>)}
                                {parse(data.para4)}</div>
                                <div className='para'>{parse(data.para5)} {data.percentage}% {parse(data.para6)}</div>

                                <div className='row ria_agreement_gap'>
                                    <div className='col-1 desktop'></div>
                                    <div className='col-4'>
                                        <div className='ria_sign_head'>Client Signature</div>
                                        <div className='ria_user-sign-image'>
                                        <img src={clientSign} alt=""/>
                                        </div>
                                        <div style={{fontWeight:600}} className='naam'>{data.name}</div>
                                        <div className='naam'>Date : {date+"/"+month+"/"+year}</div>
                                    </div>
                                    <div className='col-3'>
                                        {spouseSign?(
                                        <>
                                            <div className='ria_sign_head'>Spouse Signature</div>
                                            <div className='ria_user-sign-image'>
                                                <img src={spouseSign} alt=""/>
                                            </div>
                                            <div style={{fontWeight:600}} className='naam'>{spouseName}</div>
                                            <div className='naam'>Date : {date+"/"+month+"/"+year}</div>
                                        </>):null}
                                    </div>
                                    <div className='col-4'>
                                        <div  className='ria_sign_head'>Adviser Signature</div>
                                        <div className='ria_user-sign-image-adv'>
                                            <img src={adviser} alt=""/>
                                        </div>
                                        <div style={{fontWeight:600}} className='naam'>Harish Menon</div>
                                        <div className='naam'>Date : {date+"/"+month+"/"+year}</div>
                                    </div>
                                </div></>:null}
                        </div>
                        </>}
                        </>}
                        
                        
                            {/* <div className='col-md-4'>
                                <div className='docs-card'>
                                    <div className='card-name'>RIA Agreement
                                        <span className='upload-act'><i class="fas fa-check-circle"></i></span> 
                                        <MDBIcon icon="eye" className='mdbicon' onClick={viewDocs}/>     
                                        <MDBIcon icon="download" className='mdbicon' onClick={download}/>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                    
                    
                    {props.admin===1?null:(<>
                    <div className='submit-Btn'>
                        <button type='button' onClick={step4}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
                        {tableData.flag==='Y'?(
                            <button onClick={submit}>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight'/></button>):( <button style={{background:'#E5E7E9',border:'none'}}>Next<MDBIcon icon="angle-right" className='buttonArrowRight'/></button>)}
                        {/* <button onClick={profile}>Next<MDBIcon icon="angle-right" className='buttonArrowRight'/></button> */}
                    </div>
                    </>)}
                    
                </>):(
                <>
                <div id="terms">
                    {data.para1?<>
                    <div className='para'>{parse(data.para1)}
                        <span className='bold'>  {date+"/"+month+"/"+year}</span>
                        <span> {parse(data.para2)}</span>
                    </div>
                    <div className='and'>AND</div>
                    <div className='para'><span style={{fontWeight:'bold'}}>{data.name} {data.spouse_name?(<span>and {data.spouse_name} </span>):null}</span> {parse(data.para3)}
                    {data.address?(<span style={{fontWeight:'bold'}}> {data.address}</span>):(<div></div>)}
                    {parse(data.para4)}</div>
                    <div className='para'>{parse(data.para5)} {data.percentage}% {parse(data.para6)}</div>

                    <div className='row ria_agreement_gap'>
                        <div className='col-1 desktop'></div>
                        <div className='col-4'>
                            <div className='ria_sign_head'>Client Signature</div>
                            <div className='ria_user-sign-image'>
                            <img src={clientSign} alt=""/>
                            </div>
                            <div style={{fontWeight:600}} className='naam'>{data.name}</div>
                            <div className='naam'>Date : {date+"/"+month+"/"+year}</div>
                        </div>
                        <div className='col-3'>
                            {spouseSign?(
                            <>
                                <div className='ria_sign_head'>Spouse Signature</div>
                                <div className='ria_user-sign-image'>
                                    <img src={spouseSign} alt=""/>
                                </div>
                                <div style={{fontWeight:600}} className='naam'>{spouseName}</div>
                                <div className='naam'>Date : {date+"/"+month+"/"+year}</div>
                            </>):null}
                        </div>
                        <div className='col-4'>
                            <div  className='ria_sign_head'>Adviser Signature</div>
                            <div className='ria_user-sign-image-adv'>
                                <img src={adviser} alt=""/>
                            </div>
                            <div style={{fontWeight:600}} className='naam'>Harish Menon</div>
                            <div className='naam'>Date : {date+"/"+month+"/"+year}</div>
                        </div>
                    </div></>:null}
                </div>
                </>)}
            </div>

            <div id="checkbox-div">
                {ria_form===1?null:
                <div>
                    <div className='mobile'><input  type="checkbox"  onChange={agreeCheck}/>
                        <span class="redSmall">I Agree with the agreement</span>
                    </div>
                    <p  id="checkSpan" class='display'>
                        <span><input id="payment" type="checkbox" disabled name="terms" onChange={agreeCheck}/></span>
                        <span class="redSmall">I Agree with the agreement</span>
                    </p>
                    
                    {risk_form===1?
                    <div className='submit-Btn'>
                        <button type='button' onClick={step4}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
                        {agree===true?(
                            <button onClick={submit}>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight'/></button>):( <button style={{background:'#E5E7E9',border:'none'}}>Next<MDBIcon icon="angle-right" className='buttonArrowRight'/></button>)}
                    </div>:
                    <div className='previous-msg'>Please fill the previous form first.</div>}
                </div>}
            </div>
            
                
                
            {viewModal?
                <div className='upload-modal-sec'>
                    <div className='row'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'>
                            <div className='viewDocs-modal-sec-card'>
                                <div className='modal-close-icon' onClick={closeModaldocument}><MDBIcon icon="times" />
                                </div> 
                                {view?
                                <iframe src={`${view}#view=fitH`} title="testPdf" height="80%" width="100%"/>
                                :null}
                            </div>
                        </div>
                    <div className='col-md-4'></div>
                </div>
            </div>:null}
            <Modal title="Old Agreement" visible={isModalOpen} onCancel={handlemodal} >
                <div className='oldAgmModal'>
                    <table class="table table-condensed table-sm" border="0">
                        <thead>
                            <tr className='tb_header'>
                                <td>Date</td>
                                <td className='lf-border'>Agreement</td>
                                <td className='lf-border'></td>
                            </tr>
                        </thead>
                        {Object.keys(tableData).length>0?
                        <>
                        {Object.keys(tableData.agreements).map((val)=>{
                            return(
                            <tbody>
                                <tr className='tb_body'>
                                    <td>{val}</td>
                                    <td className='l-r-br'>{tableData.agreements[val]}</td>
                                    <td>
                                        <MDBIcon icon="eye" className='mdbicon' onClick={()=>viewDocs(tableData.agreements[val])}/>     
                                        <MDBIcon icon="download" className='mdbicon' onClick={()=>download(tableData.agreements[val])}/>
                                    </td>
                                </tr>
                            </tbody>
                            )
                        })}
                        </>:null}
                    </table>
                </div>
                </Modal>
            
        </>
    )
}

export default RIA_formFun
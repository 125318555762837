import React,{useState,useEffect,useRef} from 'react';
import {admin_stocks_list,admin_stocks_list_post,admin_stocks_list_delete} from '../Api'
import {Table, Form,Modal, Input, Button,Typography,Popconfirm,Row,Col,message,Select,Upload ,Tooltip,Space,Divider} from 'antd';
import {DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined,UploadOutlined,DownloadOutlined,PlusOutlined} from '@ant-design/icons';
import { Collapse } from 'antd';
import {admin_ria_percentage_put,admin_last_index} from '../../Api'
import {directEqFile} from '../Api'

let index = 0;
const EditableCell=({editing,dataIndex,title,record,index,children,...restProps})=>{
    return (
      <td {...restProps}>
        {editing?
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}>
              <Input/>
          </Form.Item>
        :children}
      </td>
    );
  };

const Stock_list = () => {
    const [dataSource, setDataSource] = useState([])
    const [fileList,setFileList] = useState([])
    const [editingKey, setEditingKey] = useState('');
    const [holdingUploading,setHoldingUploading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false)
    const { Panel } = Collapse;
    const [items, setItems] = useState(['Debt Mutual Funds','Equity Mutual Funds','Hybrid Mutual Funds','Foreign Equity Mutual Funds','Equity Shares','REITs','INVITs','SGBs'])
    const [types, setTypes] = useState(['Debt Mutual Funds','Equity Mutual Funds','Hybrid Mutual Funds','Foreign Equity Mutual Funds','Equity Shares','REITs','INVITs','SGBs'])
    const [assetCategory,setAssetCategory] = useState('')
    const [assetType,setAssetType] = useState('')
    const [name, setName] = useState('');
    const [typeName, setTypeName] = useState('');
    const inputRef = useRef(null);


    let pdf1=null

    const getRiaPercentage = async()=>{
        setLoading(true)
        let newdata = []
        const input = {access_token:localStorage.token}
        const res = await admin_stocks_list(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.keys(res.result).map((val)=>{
              newdata.push({ 
              key: res.result[val].key,
              Asset_category:res.result[val].Asset_category,
              Asset_type:res.result[val].Asset_type,
              Name:res.result[val].Name,
              exchange:res.result[val].exchange,
              instrumentsymbol:res.result[val].instrumentsymbol,
              })
            })
            setDataSource(newdata)
            setLoading(false)
          }
          else{setLoading(false)}
        }catch{setLoading(false)}
      }
      useEffect(() => {
        getRiaPercentage();
      },[]);

      const onFinish = (values) => {
        setLoading(true)
        console.log(assetCategory);
        const input = {
            access_token:localStorage.token,
            // id:values.id,
            Asset_category:assetCategory,
            Asset_type:assetType,
            Name:values.Name,
            exchange:values.exchange,
            instrumentsymbol:values.instrumentsymbol,
          }
          console.log(input);
          admin_stocks_list_post(input).then(res => {
            console.log(res);
            try{
              if (res.status==='successfull') {
                getRiaPercentage()
                setLoading(false)
                message.success(res.message);
              }
              else{ 
                setLoading(false)
                message.error(res.message);
              }
                
            }catch{
              setLoading(false)
              message.error('Something went Wrong');
            }
            
            })
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

      const addItem = (e) => {
        e.preventDefault();
        setItems([...items, name || `New item ${index++}`]);
        setName('');
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      };

      const addItems = (e) => {
        e.preventDefault();
        setTypes([...types, typeName || `New item ${index++}`]);
        setTypeName('')
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      };

      const handleChange = (value) => {
        setAssetCategory(value)
        console.log(value);
      };

      const handleChangeAsset = (value) => {
        setAssetType(value)

      }

      const onNameChange = (event) => {
        setName(event.target.value);
      };
      const onTypeChange = (event) => {
        setTypeName(event.target.value);
      };
      const save = async (key) => {
        setLoading(true)
          try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
              setDataSource(newData);
              setEditingKey('');
            const input = {
              access_token:localStorage.token,
              row:newData[index],
            }
            admin_ria_percentage_put(input).then(res => {
                console.log(res,'abc');
                
                try{
                  if (res && res.status==='successfull'){
                    getRiaPercentage();
                    setLoading(false);
                    message.success("Saved");
                    }
                  else{
                    setLoading(false)
                    message.error(res.message);
                  }
                }catch{
                  setLoading(false);
                }
              })
            } else {
              newData.push(row);
              setDataSource(newData);
              setEditingKey('');
              setLoading(false)
            }
          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
            setLoading(false)
          }
        };

      const onDelete = async(record)=>{
        setLoading(true)
        const input = {
          access_token:localStorage.token,
          row:record.key,
          instrumentsymbol:record.instrumentsymbol
        }
        console.log(input)
        admin_stocks_list_delete(input).then(res => {
        console.log(res);
          try{
            if (res && res.status==='successfull'){
              getRiaPercentage();
              setLoading(false);message.success(res.message)}
            else{setLoading(false); message.error(res.message)}
          }catch{setLoading(false); message.error('Something went Wrong')}
        }) 
    }

      const isEditing = (record) => record.key === editingKey;
        const edit = (record) => {
        form.setFieldsValue({email: '',percentage: '',...record});
        setEditingKey(record.key);
        };

        const cancel = () => {
        setEditingKey('');
        };


        const handleUploadHoldings = () => {
          const formData = new FormData();
          fileList.forEach(file => {formData.append('csv_file', file)});
          // formData.append('flag', 'holdings')
          setHoldingUploading(true)
          fetch('/alpha_admin/directEqFile', {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': localStorage.token
            },
          })
            .then(res => res.json())
            .then((data) => {
              console.log(data);
              if(data.status==='successfull'){
                
                message.success(data.message)}
              if(data.status==='failed'){
              message.error(data.message)}
              setHoldingUploading(false);
            })
            .catch(() => {
              message.error('upload failed.')
              setHoldingUploading(false);
            })
            .finally(() => {});
        };

        const getDocLink = (url) => {
          const str = url.substring(url.indexOf(";") + 1);
          return `data:text/csv;base64,${str}`;
        };


        const download=()=>{
          // setIsModalOpen(false)
          const input = {
              access_token:localStorage.token,
              // flag:name,
              // id:id,
              // filename: 'filename'
          }
          console.log(input);
          directEqFile(input).then(res => {
              console.log(input);
              console.log("resss",res)
              try {
                if (res.status==='successfull') {
                  pdf1=res.result
                  const linkSource = `data:application/vnd.ms-excel;base64,${pdf1}`;
                  const downloadLink = document.createElement("a");
                  downloadLink.href = linkSource;
                  downloadLink.download = "direct EQ.xlsx";
                  downloadLink.click();
              }
                  else{}
              } catch (error) {}
              })
      }

        const props = {
          maxCount:1,
          accept:'.csv, .xlsx',
          beforeUpload: file => {
            setFileList( [...fileList, file])
            return false;
          },
          fileList,
        };

        const columns = [
            {title: 'Asset Category',dataIndex: 'Asset_category',width: 120,editable: true},
            {title: 'Asset Type',dataIndex: 'Asset_type',width: 120,editable: true},
            {title: 'Name',dataIndex: 'Name',width: 120,editable: true},
            {title: 'Exchange',dataIndex: 'exchange',width: 120,editable: true},
            {title: 'Instrument Symbol',dataIndex: 'instrumentsymbol',width: 120,editable: true},
            
            {
              title: 'Operation',
              dataIndex: 'operation',
              width: 120,
              render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                  <span>
                    <Typography.Link
                      onClick={() =>save(record.key)}
                      style={{
                        marginRight: 8,
                      }}
                    >
                      <span className='outlineSaveButton'>
                        <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
                      </span>
                    </Typography.Link>
                    <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                        <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
                    </Popconfirm>
                  </span>
                ) : (
                  <span>
                  {/* <Typography.Link disabled={editingKey !== ''}
                    onClick={() => edit(record)}>
                    <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
                  </Typography.Link> */}
                  <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
                   <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
                  </Popconfirm>
                  </span>
                );
              },
            },
          ];

      const mergedColumns = columns.map((col) => {
        if (!col.editable) {return col;}
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

      const handleModal = () => {
        setIsModalVisible(!isModalVisible);};
  return (
    <div>

    <Col span={16}></Col>
      <Collapse >
        
        <Panel header="Add Row" key="1">
          <Form name="basic" layout='inline' onFinish={onFinish} onFinishFailed={onFinishFailed}
            style={{marginTop:'15px'}}>
            <Col span={8} >

                <Select
                  style={{
                    width: 320,
                  }}
                  
                  onChange={handleChange}
                  placeholder="Asset Category Type"
                  dropdownRender={(menu) => (
                <>
                {menu}
                <Divider
                  style={{
                    margin: '8px 0',
                  }}
                />
                  <Space
                    style={{
                      padding: '0 8px 4px',
                    }}
                  >
                  <Input
                    placeholder="Please enter item"
                    ref={inputRef}
                    value={name}
                    onChange={onNameChange}
                  />
                    <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                      Add item
                    </Button>
                  </Space>
                    </>
                  )}
                  options={items.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              {/* <Form.Item
                label="Asset Category"
                name="Asset_category"
                rules={[{required: true,message: 'Please input Asset Category'}]}>
                <Input />
              </Form.Item> */}
              </Col>

              <Col span={8}>
              <Select
                  style={{
                    width: 320,
                  }}
                  
                  onChange={handleChangeAsset}
                  placeholder="Asset Type"
                  dropdownRender={(menu) => (
                <>
                {menu}
                <Divider
                  style={{
                    margin: '8px 0',
                  }}
                />
                  <Space
                    style={{
                      padding: '0 8px 4px',
                    }}
                  >
                  <Input
                    placeholder="Please enter item"
                    ref={inputRef}
                    value={typeName}
                    onChange={onTypeChange}
                  />
                    <Button type="text" icon={<PlusOutlined />} onClick={addItems}>
                      Add item
                    </Button>
                  </Space>
                    </>
                  )}
                  options={types.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              {/* <Form.Item
                label="Asset Type"
                name="Asset_type"
                rules={[{required: true, message: 'Please input Asset Type'}]}>
                <Input/>
              </Form.Item> */}
              </Col>

              <Col span={8}>
              <Form.Item
                label="Name"
                name="Name"
                rules={[{required: true, message: 'Please input Name'}]}>
                <Input/>
              </Form.Item>
              </Col>
              
              <Col span={8}>
              <Form.Item
                label="Exchange"
                name="exchange"
                rules={[{required: true, message: 'Please input Exchange'}]}>
                <Input/>
              </Form.Item>
              </Col>

              <Col span={8}>
              <Form.Item
                label="Instrument Symbol"
                name="instrumentsymbol"
                rules={[{required: true, message: 'Please input Instrument Symbol'}]}>
                <Input/>
              </Form.Item>
              </Col>

              <Col span={5}>
              <Form.Item
                wrapperCol={{offset: 8,span: 16}}>
                <Button type="primary" htmlType="submit">Submit</Button>
              </Form.Item>
              </Col>
          </Form>
        </Panel>
      </Collapse>
        <Form form={form} component={false}>
        <Table
          components={{body: {cell: EditableCell}}}
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={true}
          size='small'
          />
      </Form>


      {isModalVisible? <>
          <Modal  footer={null} width={600} visible={true} onCancel={handleModal} >
            <div className=''>
            <Upload
            {...props}
            ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
            <Button
              type="primary"
              onClick={handleUploadHoldings}
              disabled={fileList.length === 0}
              loading={holdingUploading}
              className='startUploading'
              onRemove={true}
            >
              {holdingUploading ? 'Uploading' : 'Start Upload'}
            </Button>
          </div>
          </Modal>
      </>:null}
      
    </div>
  )
}
// 

export default Stock_list
import React,{useState,useEffect} from 'react'
import {customer_review} from '../Api'
import './DocsUpload.scss'
import { Upload, Button, message, Row, Col,Table,Space,Tooltip,Input,Select,Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import { UploadOutlined,SearchOutlined,DownloadOutlined} from '@ant-design/icons';
import EquitySample from '../../../Files/direct EQ.xlsx'
import EquityOrders from '../../../Files/tradebook-RSK729-EQ (1).xlsx'
import MfHoldings from '../../../Files/holdings-RSK729.xlsx'
import MFOrder from '../../../Files/tradebook-RSK729-MF.xlsx'

const DocsUpload = () => {

  const [data,setData] = useState([])
  const [holdingfileList,setHoldingFileList] = useState([])
  const [orderfileList,setOrderFileList] = useState([])
  const [positionfileList,setPositionFileList] = useState([])
  const [marginfileList,setMarginFileList] = useState([])
  const [holdingUploading,setHoldingUploading] = useState(false)
  const [ordersUploading,setOrdersUploading] = useState(false)
  const [positionUploading,setPositionUploading] = useState(false)
  const [marginUploading,setMarginUploading] = useState(false)
  const [mfHoldingfileList,setMfHoldingFileList] = useState([])
  const [mfOrderfileList,setMfOrderFileList] = useState([])
  const [sipfileList,setSipFileList] = useState([])
  const [mfHoldingUploading,setMfHoldingUploading] = useState(false)
  const [mfOrdersUploading,setMfOrdersUploading] = useState(false)
  const [sipUploading,setSipUploading] = useState(false)
  const[clientId,setClientId] = useState('')
  

  let searchInput = ''

  useEffect(()=>{
      const input = {
          access_token:localStorage.token
      }
      let newdata = [...data]
      customer_review(input).then(res => {
          console.log(res,'ress')
      try{
          if (res.status==='successfull'){
              Object.keys(res.result).map((val)=>{
                  newdata.push({ 
                  key: res.result[val].customer_id,
                  id: res.result[val].customer_id,
                  name:res.result[val].customer_fullname,
                  email_address:res.result[val].email_address,
                  zerodha:res.result[val].zerodha
              })
              })
              setData(newdata)
          }else{}
      }catch{}
      })
  },[])
   const handleUploadHoldings = () => {
        const formData = new FormData();
        holdingfileList.forEach(file => {formData.append('csv_file', file)});
        formData.append('flag', 'holdings')
        formData.append('id', clientId)
        setHoldingUploading(true)
        fetch('/alpha_admin/zerodha_csv', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': localStorage.token
          },
        })
          .then(res => res.json())
          .then((data) => {
            if(data.status==='successfull'){
              message.success(data.message)}
            if(data.status==='failed'){
            message.error(data.message)}
            setHoldingUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setHoldingUploading(false);
          })
          .finally(() => {});
      };

      const handleUploadOrders = () => {
        const formData = new FormData();
        orderfileList.forEach(file => {formData.append('csv_file', file)});
        formData.append('flag', 'orders')
        formData.append('id', clientId)
        setOrdersUploading(true)
        fetch('/alpha_admin/zerodha_csv', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': localStorage.token
          },
        })
          .then(res => res.json())
          .then((data) => {
            console.log(data);
            if(data.status==='successfull'){
              message.success(data.message)}
            if(data.status==='failed'){
            message.error(data.message)}
            setOrdersUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setOrdersUploading(false);
          })
          .finally(() => {});
      };

      const handleUploadPositions = () => {
        const formData = new FormData();
        positionfileList.forEach(file => {formData.append('csv_file', file)});
        formData.append('flag', 'positions')
        formData.append('id', clientId)
        setPositionUploading(true)
        fetch('/alpha_admin/zerodha_csv', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': localStorage.token
          },
        })
          .then(res => res.json())
          .then((data) => {
            if(data.status==='successfull'){
              message.success(data.message)}
            if(data.status==='failed'){
            message.error(data.message)}
            setPositionUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setPositionUploading(false);
          })
          .finally(() => {});
      };

      const handleUploadMargin = () => {
        const formData = new FormData();
        marginfileList.forEach(file => {formData.append('csv_file', file)});
        formData.append('flag', 'margins')
        formData.append('id', clientId)
        setMarginUploading(true)
        fetch('/alpha_admin/zerodha_csv', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': localStorage.token
          },
        })
          .then(res => res.json())
          .then((data) => {
            console.log(data);
            if(data.status==='successfull'){
              message.success(data.message)}
            if(data.status==='failed'){
            message.error(data.message)}
            setMarginUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setMarginUploading(false);
          })
          .finally(() => {});
      };

      const handleUploadMFHoldings = () => {
        const formData = new FormData();
        mfHoldingfileList.forEach(file => {formData.append('csv_file', file)});
        formData.append('flag', 'holdings')
        formData.append('id', clientId)
        setMfHoldingUploading(true)
        fetch('/alpha_admin/zerodha_csv', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': localStorage.token
          },
        })
          .then(res => res.json())
          .then((data) => {
            console.log(data);
            if(data.status==='successfull'){
              message.success(data.message)}
            if(data.status==='failed'){
            message.error(data.message)}
            setMfHoldingUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setMfHoldingUploading(false);
          })
          .finally(() => {});
      };

      const handleUploadMFOrders = () => {
        const formData = new FormData();
        mfOrderfileList.forEach(file => {formData.append('csv_file', file)});
        formData.append('flag', 'mf_orders')
        formData.append('id', clientId)
        setMfOrdersUploading(true)
        fetch('/alpha_admin/zerodha_csv', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': localStorage.token
          },
        })
          .then(res => res.json())
          .then((data) => {
            console.log(data);
            if(data.status==='successfull'){
              message.success(data.message)}
            if(data.status==='failed'){
            message.error(data.message)}
            setMfOrdersUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setMfOrdersUploading(false);
          })
          .finally(() => {});
      };

      const handleUploadSip = () => {
        const formData = new FormData();
        sipfileList.forEach(file => {formData.append('csv_file', file)});
        formData.append('flag', 'sip')
        formData.append('id', clientId)
        setSipUploading(true)
        fetch('/alpha_admin/zerodha_csv', {
          method: 'POST',
          body: formData,
        })
          .then(res => res.json())
          .then((data) => {
            console.log(data);
            if(data.status==='successfull'){
              message.success(data.message)}
            if(data.status==='failed'){
            message.error(data.message)}
            setSipUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setSipUploading(false);
          })
          .finally(() => {});
      };

      

      const props = {
        maxCount:1,
        accept:'.csv, .xlsx',
        beforeUpload: file => {
          setHoldingFileList( [...holdingfileList, file])
          return false;
        },
        holdingfileList,
      };

      const propsorder = {
        maxCount:1,
        accept:'.csv, .xlsx',
        beforeUpload: file => {
          setOrderFileList( [...orderfileList, file])
          return false;
        },
        orderfileList,
      };

      const propspositions = {
        maxCount:1,
        accept:'.csv, .xlsx',
        beforeUpload: file => {
          setPositionFileList( [...positionfileList, file])
          return false;
        },
        positionfileList,
      };

      const propsmargin = {
        maxCount:1,
        accept:'.csv, .xlsx',
        beforeUpload: file => {
          setMarginFileList( [...marginfileList, file])
          return false;
        },
        marginfileList,
      };

      const propsmfholdings = {
        maxCount:1,
        accept:'.csv, .xlsx',
        beforeUpload: file => {
          setMfHoldingFileList( [...mfHoldingfileList, file])
          return false;
        },
        mfHoldingfileList,
      };

      const propsmforders = {
        maxCount:1,
        accept:'.csv, .xlsx',
        beforeUpload: file => {
          setMfOrderFileList( [...mfOrderfileList, file])
          return false;
        },
        mfOrderfileList,
      };

      const propssip = {
        maxCount:1,
        accept:'.csv, .xlsx',
        beforeUpload: file => {
          setSipFileList( [...sipfileList, file])
          return false;
        },
        sipfileList,
      };

      const updateclientID=(value)=>{
        setClientId(value)
    }

      const { Option } = Select;
  return (  
    <>
    <Select
        style={{ width: 600 }}
        showSearch
        bordered={true}
        title='Client Mail ID'
        className='selectborder'
        placeholder="Select Client Mail ID"
        optionFilterProp="children"
        onChange={updateclientID}
        filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        // size='large'
    >
    {Object.keys(data).map((val)=>{
        return <Option value={data[val].id}>{data[val].email_address}, <b>{data[val].name}</b></Option>
        })}
    </Select>
    <div className='partitionBox'>
    <Divider orientation="left">Equity</Divider>
    <Row>
      <Col span={6}>
        <div className='card'>
          <div className='fileName'>Holdings
          <span className='fileuploadButton'><a  href={MfHoldings} download='Holding Sample'> <Tooltip title='Download Sample File'> <DownloadOutlined   style={{ fontSize: '14px', color: '#6d6d6d', marginRight:'10px' }} /></Tooltip></a></span>
          </div>
          <Upload
          {...props}
          disabled={!clientId}
          ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
          <Button
            type="primary"
            onClick={handleUploadHoldings}
            disabled={holdingfileList.length === 0}
            loading={holdingUploading}
            className='startUploading'
          >
            {holdingUploading ? 'Uploading' : 'Start Upload'}
          </Button>
        </div>
      </Col>
      <Col span={6}>
        <div className='card'>
          <div className='fileName'>Orders
          <span className='fileuploadButton'><a  href={EquityOrders} download='Equity order Sample'> <Tooltip title='Download Sample File'> <DownloadOutlined   style={{ fontSize: '14px', color: '#6d6d6d', marginRight:'10px' }} /></Tooltip></a></span></div>
          <Upload
          {...propsorder}
          disabled={!clientId}
          ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
          <Button
            type="primary"
            onClick={handleUploadOrders}
            disabled={orderfileList.length === 0}
            loading={ordersUploading}
            className='startUploading'
          >
            {ordersUploading ? 'Uploading' : 'Start Upload'}
          </Button>
        </div> 
      </Col>

       <Col span={6}>
        <div className='card'>
          <div className='fileName'>Positions</div>
          <Upload
          {...propspositions}
          disabled={!clientId}
          ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
          <Button
            type="primary"
            onClick={handleUploadPositions}
            disabled={positionfileList.length === 0}
            loading={positionUploading}
            className='startUploading'
          >
            {positionUploading ? 'Uploading' : 'Start Upload'}
          </Button>
        </div>
      </Col>

      <Col span={6}>
        <div className='card'>
          <div className='fileName'>Margins</div>
          <Upload
          {...propsmargin}
          disabled={!clientId}
          ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
          <Button
            type="primary"
            onClick={handleUploadMargin}
            disabled={marginfileList.length === 0}
            loading={marginUploading}
            className='startUploading'
          >
            {marginUploading ? 'Uploading' : 'Start Upload'}
          </Button>
        </div>
      </Col>
      </Row>


      <Divider orientation="left">Mutual Fund</Divider>
      <Row>
      <Col span={6}>
        <div className='card'>
          <div className='fileName'>MF Holdings
          <span className='fileuploadButton'><a  href={MfHoldings} download='MF Holding Sample'> <Tooltip title='Download Sample File'> <DownloadOutlined   style={{ fontSize: '14px', color: '#6d6d6d', marginRight:'10px' }} /></Tooltip></a></span></div>
          <Upload
          {...propsmfholdings}
          disabled={!clientId}
          ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
          <Button
            type="primary"
            onClick={handleUploadMFHoldings}
            disabled={mfHoldingfileList.length === 0}
            loading={mfHoldingUploading}
            className='startUploading'
          >
            {mfHoldingUploading ? 'Uploading' : 'Start Upload'}
          </Button>
        </div>
      </Col>

      <Col span={6}>
        <div className='card'>
          <div className='fileName'>MF Orders
          <span className='fileuploadButton'><a  href={MFOrder} download='MF order Sample'> <Tooltip title='Download Sample File'> <DownloadOutlined   style={{ fontSize: '14px', color: '#6d6d6d', marginRight:'10px' }} /></Tooltip></a></span></div>
          <Upload
          {...propsmforders}
          disabled={!clientId}
          ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
          <Button
            type="primary"
            onClick={handleUploadMFOrders}
            disabled={mfOrderfileList.length === 0}
            loading={mfOrdersUploading}
            className='startUploading' 
          >
            {mfOrdersUploading ? 'Uploading' : 'Start Upload'}
          </Button>
        </div>
      </Col>      
    </Row>
    </div>
       
    </>
  )
}

export default DocsUpload

import React,{useState,useEffect} from 'react';
import { Table } from "ant-table-extensions";
import {month_end_zerodha} from '../userManagement/Api'

const MonthEndReport1 = (props) => {

    const [data, setData] = useState([]);

    useEffect(()=>{
        let newdata = [...data]
        const input = {
            access_token:localStorage.token,
            type:'view_client_data',
            // id:props.customer_id
        }

        const ExpandedRowRender = (props) => {
          const columns = [
            { title: 'Scheme Name', dataIndex: 'scheme_name',width:'40%' },
            { title: 'Quantity', dataIndex: 'quantity',width:'25%' },
            { title: 'Invested Value', dataIndex: 'invested_value',width:'30%' },
            { title: 'Market Value', dataIndex: 'market_value',width:'30%' },
          ];
        
          const data = [];
        
          if(props.schemes){
            Object.keys(props.schemes).map((val)=>{
              console.log("vhbhbj",val)
              data.push({
                key: val,
                scheme_name: props.schemes[val].name,
                invested_value:props.schemes[val].invested_value,
                market_value: props.schemes[val].market_value,
                quantity:props.schemes[val].quantity
              })
            })
            }
         
          return <Table
            columns={columns} dataSource={data}
            pagination={false}   size='small'   
            rowClassName="editable-row"
            bordered/>;
        };

        
        month_end_zerodha(input).then(res => {
          console.log('monthen',res);
          Object.keys(res.result).map((val)=>
            Object.keys(res.result[val].shares).map((val2)=>
                Object.keys(res.result[val].shares[val2].schemes).map((val3)=>{
                    console.log(val3);
                newdata.push({ 
                    key: Math.floor(1000 + Math.random()*9000),
                    date:res.result[val].date,
                    Customer_name:res.result[val].customer_name,
                    Customer_email:res.result[val].customer_email,
                    type:res.result[val].shares[val2].type,
                    invested_value: res.result[val].shares[val2].schemes[val3].invested_value,
                    market_value:res.result[val].shares[val2].schemes[val3].market_value,
                    scheme_name: res.result[val].shares[val2].schemes[val3].name,
                    quantity: res.result[val].shares[val2].schemes[val3].quantity
                    // description: <ExpandedRowRender schemes={res.result[val].shares[val2].schemes}/>
                    })
                })
            )
          )
          setData(newdata)
        })

      },[])
      const columns = [
        {title: 'Customer Names',dataIndex: 'Customer_name',width: 60},
        {title: 'Customer Email',dataIndex: 'Customer_email',width: 70},
        {title: 'Type',dataIndex: 'type',width: 50},
        { title: 'Scheme Name', dataIndex: 'scheme_name',width: 90 },
        { title: 'Quantity', dataIndex: 'quantity',width: 50 },
        {title: 'Updated Date',dataIndex: 'date',width: 50},
        {title: 'Invested Value',dataIndex: 'invested_value',width: 50},
        {title: 'Market Value',dataIndex: 'market_value',width: 50},
        
        
        // { title: 'Invested Value', dataIndex: 'invested_value',width:'30%' },
        // { title: 'Market Value', dataIndex: 'market_value',width:'30%' },
      ]
  return(
      <>
      {/* <div className='mfCustomerEmail'>{props.email_address}</div> */}
        <Table
              rowClassName="editable-row"
            //   expandable={{ expandedRowRender: record =><>{record.description}</> }}
              columns={columns}
              dataSource={data}
              size='small'
              scroll={{x: 1200, y: 420 }}
              pagination={{
                pageSize: 10,
                position:['bottomCenter']
              }}
              sticky={true}
              exportable
              searchableProps
          />

      </>
  );
};

export default MonthEndReport1;

import React,{useState,useEffect} from 'react'
// import Image from 'next/image'
import './Universal.scss'
import {complaints,contact_hoa} from '../Api'
// import { newsletter_get, view_newsletter } from '../../Admin/Api'
import footerLogo from '../../../Images/navabar_footer/footer_logo.png'
import Countdown from "react-countdown";
import { Col, Row, Modal} from 'antd';


const Footer = (props) => {

    const [complaint,setComplaint] = useState(false)
    const [complaintsData,setComplaintsData] = useState({})
    const [email,setEmail] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newsletterList,setNewsletterList] = useState({})
    const [loading,setLoading] = useState(false)

 

    const getNewsletterList=()=>{
        // newsletter_get().then(res => {
        //     try{
        //     if (res.status==='successfull') {setNewsletterList(res.result)}
        //     else{}
        //     }catch{}
        //     })
    }

    useEffect(() => {
        complaints().then(res => {
            console.log(res);
            try{
                if (res.status==='successfull') {
                    setComplaintsData(res.result)
                    setTimeout(() => {
                        setComplaint(false)
                    }, 15000);
                }
                else{
                    setTimeout(() => {
                        setComplaint(false)
                    }, 15000);
                }
            }catch{}
        })
        getNewsletterList();
    },[])

    const Complaint=()=>{
        setComplaint(!complaint)
        setTimeout(() => {
            setComplaint(false)
        }, 15000);
    }

    const contact_us_send=(e)=>{
        // e.preventDefault()
        // setLoading(true)
        // const input = {email: email}
        // contact_hoa(input).then(res => {
        //   try{
        //     if (res.status==='successfull') {
        //         setLoading(false)
        //         setIsModalVisible(true)
        //         e.target.reset();
        //     }
        //     else{setLoading(false); toast.error(res.message)}
        //   }catch{setLoading(false); toast.error('Something went wrong')}
        //   })
      }

      const download=(val)=>{
        // const input = {
        //     access_token:localStorage.token,
        // }
        // view_newsletter(input).then(res => {
        //     try {
        //         if (res.status==='successfull') {
        //             const linkSource = `data:application/pdf;base64,${newsletterList[val].data}`;
        //             const downloadLink = document.createElement("a");
        //             const fileName = newsletterList[val].newsletter ;
        //             downloadLink.href = linkSource;
        //             downloadLink.download = fileName;
        //             downloadLink.click();
        //         }
        //         else{}
        //     } catch (error) {}
        //     })
    }

    
      const handleOk = () => {
        setIsModalVisible(false);
      };
      const handleCancel = () => {
        setIsModalVisible(false);
      };
    const handlechangeemail=(e)=>{
        const validEmailRegex =RegExp(/^(([^<>()[\].,;:#*\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);
        let validate= validEmailRegex.test(e.target.value)
        if(validate===true){
                setEmail(e.target.value)}
        else{}
      }
    const cancel=()=>{
        setComplaint(!complaint)
        }

        const RegulatoryModal=()=>{
            return(
            <div className='comp-modal'>
                {/* {loading?<Loader/>:null}     */}
                {/* <ToastContainer theme='colored'/> */}
                <div className='comp-modal-card'>
                    <div className='sec8-head'>Regulatory Disclosure</div>
                    <div className='sec8-text'>Harish Menon is a SEBI Registered Investment Adviser (INA000012838). SEBI requires RIAs to disclose complaint status on their homepage. According to the same, please note:
                    </div>
                    <div className='modl-tbl'>
                        <table class='table table-condensed table-bordered'>
                            <thead>
                                <tr>
                            <th className='comp-th'>Complaint Status as on
                            {complaintsData?<span> {complaintsData.date}</span>:null}</th>
                            <th className='comp-th'>Number of complaints</th>
                            </tr>
                            </thead>
                            <tbody>
                            {complaintsData?
                            <>
                            <tr>
                                <td className='comp-td'>At the beginning of the month</td>
                                <td className='comp-td'>{complaintsData.beginning_of_the_month}</td>
                            </tr>
    
                            <tr>
                                <td className='comp-td'>Received during the month</td>
                                <td className='comp-td'>{complaintsData.Received_during_the_month}</td>
                            </tr>
                            
                            <tr>
                                <td className='comp-td'>Resolved during the month</td>
                                <td className='comp-td'>{complaintsData.Resolved_during_the_month}</td>
                            </tr>
    
                            <tr>
                                <td className='comp-td'>Pending at the end of the month</td>
                                <td className='comp-td'>{complaintsData.end_of_the_month}</td>
                            </tr>
                            
                            <tr>
                                <td className='comp-td'>Reasons for pendency</td>
                                <td className='comp-td'>{complaintsData.Reasons_for_pendency}</td>
                            </tr>
                            </>:null}
                            </tbody>
                        </table>
                        <div className='sebiDisclosureBtn'>For detailed SEBI disclosure, please <a href='disclosures'>Click here</a></div>
                        <div className='comp-close'><button onClick={cancel}>Close</button></div>
                        {complaintsData?
                        <div><Countdown date={Date.now() + 15000} renderer={renderer}/></div>:null}
                    </div>
                </div>
            </div>
            )
        }
    let renderer = ({seconds}) => {
        return (
        <div className='compTimer'>This window will close in<span> {seconds}</span> seconds</div>);
    };
    return (
        <div className='bg-darkBrown paddingHome'>
            <div>
                <Row gutter={20}>
                    <Col xs={24} md={8}>
                        <div className='w-1/3 relative'>
                            <a href="#home"><img src={footerLogo} alt=''/></a>
                        </div>
                        <div className='text-cream textFont text-sm mt-4'>SEBI Registered Client focussed Fee-Only Advisers specializing in Lifestyle Financial Planning & Investment Advisory.</div>
                    </Col>
                    <Col xs={0} md={2}></Col>
                    <Col xs={10} md={6}>
                        <div className='text-cream headFont text-base'>About Us</div><br/>
                        <div className='textFont text-sm'><a className='text-white' href='what_we_do'>What We Do</a></div>
                        <div className='textFont text-sm mt-3'><a className='text-white' href='why_us'>Why Us</a></div>
                        <div className='textFont text-sm mt-3'><a className='text-white' href='blog'>Our Resources</a></div>
                        <div className='textFont text-sm mt-3'><a className='text-white' href='faq'>FAQs</a></div>
                        <div className='textFont text-sm mt-3'><a className='text-white' href='contact_us'>Contact</a></div>
                    </Col>
                    <Col xs={14} md={8}>
                        <div className='text-cream headFont text-base'>Address</div><br/>
                        <div className='text-cream textFont text-sm'>438, Avior Corporate Park, Opp. Johnson & Johnson Ltd., LBS Road, Mulund West, Mumbai 400080</div>
                        <div className='lastSectionContact'>
                            <div className='text-cream textFont text-sm mt-4'>Contact No : +91 9819130637</div>
                            <div className='text-cream textFont text-sm mt-3'>Email : info@houseofalpha.in</div>
                        </div>
                    </Col>
                    {/* <Col span={7}>
                        <div className='text-cream headFont text-base'>Newsletter</div><br/>
                        <div className='text-cream textFont text-sm mt-1'>Give your best email to stay updated.</div>
                            
                        <div className='mt-6'>
                            <form  onSubmit={contact_us_send}>
                                <div className={style.lastSecBtn}>
                                    <input placeholder="Email ID" name='email' onChange={handlechangeemail} required/>
                                <button type='submit'>&#10140;</button>
                                </div>
                            </form>
                        </div>
                    </Col> */}
                </Row>
                
                <Modal header={null} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                    <div className='newletterboxHead'>Thank You for subscribing <span               className='newsHouseofalpha'>HouseOfAlpha</span> Newsletters.
                    </div>
                    <div className='toDownloadNewsletter'>To download the Newsletters, please click below</div>
                        {newsletterList?<>          
                            {Object.keys(newsletterList).map((val)=>{
                                if(newsletterList[val].status==='Y'){
                                return(
                                    <div className='newsletterClass' key={val}>    
                                        <div className='newsletterName' onClick={()=>download(val)}>
                                        </div>
                                    </div>
                                )}
                            })}</>:null}
                    </Modal>

                <hr className='bg-magenta h-0.5 my-2'/>
                <Row>
                    <Col xs={24} md={24}>
                        <div className='footer-bt-link text-center'>
                            <span className='footer-btn' ><a href='privacy_policy'>Privacy Policy</a></span>
                            <span className='footer-vl'></span>
                            <span className='footer-btn' onClick={Complaint}>Regulatory Disclosure</span>
                            <span className='footer-vl'></span>
                            <span className='footer-btn '>Copyright © 2021</span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                <button className='new-regularity-Button' onClick={Complaint}>Regulatory Disclosure</button>
                <div >
                    <Modal visible={complaint} footer={null} closable={false} keyboard={false} width='85%' centered={true}>
                    <RegulatoryModal/>
                    </Modal>
                </div>
            </div>
            </div>
     
    )
}

export default Footer


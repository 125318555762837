import React, { PureComponent } from 'react'
import {save_kyc_details,get_kyc_details,update_kyc_details,get_uploaded_docs} from './Api'
import {view_compliants_docs} from '../Compliance/Api'
import './ProfileSetup.scss'
import Loader from '../../shared/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import {MDBIcon} from 'mdbreact'
import AddDocument from './AddDocument'
import Moment from 'moment';
import pdf from '../../Images/sign-converted.pdf'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastMsg =(ids,msg,type)=>{
  toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
} 

let docs=null
export default class KycDetails extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            dob:new Date(),
            tax_status:'',
            pan_number:'',
            gender:'',
            aadhar_number:'',
            father_name:'',
            mother_name:'',
            loading:false,
            disabled:false,
            error:false,
            success:false,
            pan_vaild:true,
            country_list:{},
            warning:false,
            aadhar_number_validate:true,
            father_vaild:true,
            mother_vaild:true,
            document_modal:false,
            joint_flag:null,
            Ncheck:false,
            Ycheck:false,
            document_type:'',
            uploaded_docs:{},
            addressDocs:false,
            view:null,
            viewModal:false
        }
    }
    componentDidMount(){
      const input = {
        access_token:localStorage.token,
        id:this.props.id,
        dir:'kyc'
    }
    // console.log('docs-res-input',input)
    get_kyc_details(input).then(res => {
      try{
      if (res.status==='successfull') {
        this.setState(() => ({
          dob:res.result.dob,
          tax_status:res.result.tax_status,
          pan_number:res.result.pan_number,
          father_name:res.result.father_name,
          mother_name:res.result.mother_name,
          gender:res.result.gender,
          aadhar_number:res.result.aadhar_number,
          disabled:true
        }))
      }
    else{}
    }catch{toast.error('Something went wrong')}
    })
    get_uploaded_docs(input).then(res => {
      // console.log('docs-res',res)
      try{
      if (res.status==='successfull') {
        this.setState(() => ({
           uploaded_docs:res.result,
        }))
      if (res.result.joint_holder_adhaar_card || res.result.joint_holder_pan_card){
        this.setState({Ycheck:true,joint_flag:'Y'})
      }
      else {this.setState({Ncheck:true,joint_flag:'N'})}

      // Object.keys(res.result).map((val)=>{
      //   if (val==='adhaar_card'){
      //     aadhar=1 
      //     adhar_name=res.result[val].document_extension
      //   }
      //   if (val==='pan_card'){
      //     pan=1
      //     pan_name=res.result[val].document_extension
      //   }
      //   if (val==='cancelled_check'){
      //     cheque=1
      //     cheque_name = res.result[val].document_extension
      //   }
      //   if (val==='bank_statement'){statement=1
      //     statement_name = res.result[val].document_extension}
      //   if (val==='rent_agreement' || val==='utility_bill' || val==='passport' || val==='voter_id'){address=1
      //     address_name = res.result[val].document_extension}
      //   if (val==='joint_holder_adhaar_card'){j_aadhar=1
      //     j_aadhar_name = res.result[val].document_extension}
      //   if (val==='joint_holder_pan_card'){j_pan=1
      //     j_pan_name = res.result[val].document_extension}
      // })
        
    }
    else{this.setState({Ncheck:true,joint_flag:'N'})}
    }catch{
      this.setState({loading:false})
      toast.error('Something went wrong')
    }
    })
    }

onChange=(e)=>{
    this.setState({ [e.target.name]: e.target.value })
  }
  onChangeAdhar=(e)=>{
    if(e.target.value.length===12){
      this.setState({
        aadhar_number: e.target.value,
        aadhar_number_validate:true})
    }
  else{
    this.setState({
        aadhar_number: e.target.value,
        aadhar_number_validate:false})
  }
}
adddocument=(e,name)=>{
 if(name==='address'){
   this.setState({addressDocs:true})
 }
  this.setState({
    document_modal:true,
    document_type:e.target.id
  })
}
closeModaldocument=()=>{
  this.setState({
      document_modal:false,
      document_type:'',
      addressDocs:false,
      viewModal:false,
      view:null
  });
}

onAfterUpload=()=>{
  this.setState({document_type:''})
  const input = {
    access_token:localStorage.token,
    id:this.props.id,
    dir:'kyc'
}

  get_uploaded_docs(input).then(res => {
    console.log('docs-resafter',res)
    try{
    if (res.status==='successfull') {
      this.setState(() => ({
         uploaded_docs:res.result,
      }))
    if (res.result.joint_holder_adhaar_card || res.result.joint_holder_pan_card){
      this.setState({  Ycheck:true,joint_flag:'Y'})
    }
    else {}   
  }
  else{this.setState({Ncheck:true,joint_flag:'N'})}
  }catch{
    toast.error('Something went wrong')
    this.setState({loading:false})
  }
  })
  this.closeModaldocument()
}

onSubmitaddress=(e)=>{
  e.preventDefault()
  const ids = toast.loading("Please wait...",{toastId:'load'})
  const input = {
    access_token:localStorage.token,
    dob:Moment(this.state.dob).format('YYYY-MM-DD'),
    tax_status:this.state.tax_status,
    pan_number:this.state.pan_number,
    gender:this.state.gender,
    aadhar_number:this.state.aadhar_number,
    father_name:this.state.father_name,
    mother_name:this.state.mother_name,
    id:this.props.id,
  }
if(this.props.kyc===0){
  save_kyc_details(input).then(res => {
    console.log("res",res)
  try{
  if (res.status==='successfull') {
    this.props.update();
    this.props.step2();
}
else{
  toastMsg(ids,res.message,'error')
}
}catch{toastMsg(ids,'Something went wrong','error')}
})

}
else{
  update_kyc_details(input).then(res => {
try{
if (res.status==='successfull'){
  toastMsg(ids,res.message,'success')
  this.setState(() => ({disabled:true}))
}
else{toastMsg(ids,res.message,'error')}
}catch{toastMsg(ids,'Something went wrong','error')}
})
}
}
edit=(e)=>{
  e.preventDefault()
  this.setState({
      disabled:false
  })
}

onChangepan=(e)=>{
  let upper= (e.target.value).toUpperCase();
  const validate=RegExp(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/)
  let name= validate.test(upper)
  if(name===true){
      this.setState({
          [e.target.name]: upper,
          pan_vaild:true
      })
  }
  else{
      this.setState({
        [e.target.name]: upper,
        pan_vaild:false
      })
  }
}
onChangefather=(e)=>{
  const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s][a-zA-Z. ]{2,30}$/)
  let name= validate.test(e.target.value)
  if(name===true){
      this.setState({
          [e.target.name]: e.target.value,
          father_vaild:true
      })
  }
  else{
      this.setState({
        [e.target.name]: e.target.value,
        father_vaild:false
      })
  }
}
onChangemother=(e)=>{
  const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s][a-zA-Z. ]{2,30}$/)
  let name= validate.test(e.target.value)
  if(name===true){
      this.setState({
          [e.target.name]: e.target.value,
          mother_vaild:true
      })
  }
  else{
      this.setState({
        [e.target.name]: e.target.value,
        mother_vaild:false
      })
  }
}


cancel=()=>{
  this.setState({
      error:false,
      success:false
  })
}
pleaseSave=(e)=>{
  e.preventDefault()
  this.setState({
      warning:true,
  })
}
handlesave=()=>{
  this.props.step2();
}
onleave=()=>{
  this.setState({
    warning:false,
})
}

onchangedateofbirth=(date)=>{
  this.setState({
    dob:date
  })
}
joint_flag=(e)=>{
  this.setState({
      joint_flag:e.target.value,
      Ycheck:!this.state.Ycheck,
      Ncheck:!this.state.Ncheck
  })
}
download=(name)=>{
  const input = {
      access_token:localStorage.token,
      flag:name,
      id:this.props.id,
      dir: 'kyc'
  }
  let splitType = name.split('.').pop();
  view_compliants_docs(input).then(res => {
      console.log("resss",res)
      try {
          if (res.status==='successfull') {
              docs=res.result
              let linkSource = ''
              if(splitType==='pdf'){
                linkSource = `data:application/pdf;base64,${docs}`;
              }
              else{
                linkSource = `data:image/png;base64,${docs}`;
              }
              const downloadLink = document.createElement("a");
              const fileName = name;
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
          }
          else{}
      } catch (error) {}
      })
}
viewDocs=(name)=>{
  this.setState({viewModal:true})
  const input = {
    access_token:localStorage.token,
    flag:name,
    id:this.props.id,
    dir: 'kyc'
}
let splitType = name.split('.').pop();
view_compliants_docs(input).then(res => {
    console.log("viewRes",res)
    try {
        if (res.status==='successfull') {
            if(splitType==='pdf'){
              this.setState({
                view : `data:application/pdf;base64,${res.result}`
              })
            }
            else{
              this.setState({
                view : `data:image/png;base64,${res.result}`
              })
            }
        }
        else{}
    } catch (error) {}
    })
}

render() {
  let aadhar = 0 
  let adhar_name = ''
  let pan = 0
  let pan_name = ''
  let cheque = 0
  let cheque_name = ''
  let statement = 0
  let statement_name = ''
  let address = 0
  let address_name = ''
  let j_aadhar = 0
  let j_aadhar_name = ''
  let j_pan = 0
  let j_pan_name= ''
  // console.log(this.props.id);
  const {disabled} = this.state
  if (this.state.uploaded_docs){
    Object.keys(this.state.uploaded_docs).map((val)=>{
      if (val==='adhaar_card'){
        aadhar=1 
        adhar_name=this.state.uploaded_docs[val].document_extension
      }
      if (val==='pan_card'){
        pan=1
        pan_name=this.state.uploaded_docs[val].document_extension
      }
      if (val==='cancelled_check'){
        cheque=1
        cheque_name = this.state.uploaded_docs[val].document_extension
      }
      if (val==='bank_statement'){statement=1
        statement_name = this.state.uploaded_docs[val].document_extension}
      if (val==='rent_agreement' || val==='utility_bill' || val==='passport' || val==='voter_id'){address=1
        address_name = this.state.uploaded_docs[val].document_extension}
      if (val==='joint_holder_adhaar_card'){j_aadhar=1
        j_aadhar_name = this.state.uploaded_docs[val].document_extension}
      if (val==='joint_holder_pan_card'){j_pan=1
        j_pan_name = this.state.uploaded_docs[val].document_extension}
    })
  }
      let today=Moment(new Date()).format('YYYY-MM-DD')
        if (this.state.loading){
            return(
              <div style={{textAlign:'center',marginTop:100}}>
              <Loader/>
              </div>
              );
          }
        return (
           <div>
           <ToastContainer theme='colored' limit={1} enableMultiContainer={false}/>
             {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

            {this.state.warning?(
            <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  confirmBtnBsStyle="success"
                  cancelBtnText="No"
                  cancelBtnBsStyle="danger"
                  onConfirm={this.handlesave}
                  onCancel={this.onleave}
                  focusCancelBtn>Do you want to proceed without saving your changes</SweetAlert>):(<span></span>)}

        {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancel}>
              {this.state.successmsg}
            </SweetAlert>):(<span></span>)}
                <div className='row'>
            <div className='col-md-12'>
              <form onSubmit={this.onSubmitaddress}>
                <div className='form-heading'>KYC Details
                  {this.props.kyc===1?(
                  <span className='formButton'>
                    {disabled?
                    <button type="button" onClick={this.edit}><MDBIcon far icon="edit" className='form-edt-icon'/>Edit</button>:
                    <>
                    {this.state.pan_vaild && this.state.father_vaild && this.state.mother_vaild?
                    <button type='submit'><MDBIcon far icon="save" className='form-edt-icon'/>Save</button>:
                    <button disabled><MDBIcon far icon="save" className='form-edt-icon'/>Save</button>}
                    </>}
                  </span>):null}
                </div>
            
              <div className='formHeight'>
              <div className='row'>
                <div className='col-md-3 inner-form-gap'>
                <div class="input-field-u-date">
                    <input id="dob-labl" type="date" placeholder="dd-mm-yyyy" name="dob" value={this.state.dob} onChange={this.onChange} disabled={this.state.disabled} required min="1900-01-01" max={today}/>
                    <label class='dob-labl'>Date of Birth* (dd/mm/yyyy)</label>
                    
                  </div>
                </div>
                <div className='col-md-3'>
                <div class="input-field-u">
                  <div className='select-label'>Tax Status</div>
                  <div className='profile-form-input'>
                    <select name="tax_status" value={this.state.tax_status} disabled={this.state.disabled} onChange={this.onChange}>
                    <option value="">Select</option>
                    <option value="Individual">Individual</option>
                      <option value="On behalf of Minor">On behalf of Minor</option>
                      <option value="HUF">HUF</option>
                      <option value="Company">Company</option>
                      <option value="Partnership Ferm">Partnership Firm</option>
                      <option value="Trust">Trust</option>
                      <option value="NRE">NRE</option>
                      <option value="NRO">NRO</option>
                      <option value="LLP">LLP</option>
                    </select>
                  </div>
                  </div>
                </div>
  
              <div className='col-md-3 inner-form-gap'>
                 <div class="input-field-u">
                  <input id="aadhar_number" type="number" name="aadhar_number" value={this.state.aadhar_number} disabled={this.state.disabled} onChange={this.onChangeAdhar} />
                  <label for="aadhar_number">Aadhar Number</label>
                  {this.state.aadhar_number_validate?(<span></span>):(
                      <div className='invalid1'>Invalid Aadhar Number</div>
                  )}
               </div>
               </div>

              <div className='col-md-3 inner-form-gap'>
                 <div class="input-field-u">
                  <input id="input2" type="text" name="pan_number" value={this.state.pan_number} disabled={this.state.disabled} maxLength={20} onChange={this.onChangepan} style={{textTransform:'uppercase'}} required/>
                  <label for="input2">PAN Number*</label>
               </div>
               {this.state.pan_vaild?(<span></span>):(<div className='invalid1'>Invalid Pan Number</div>)}
               </div>



               <div className='col-md-3'>
               <div class="input-field-u">
                  <div className='select-label'>Gender*</div>
                   <div className='profile-form-input'>
                  <select name="gender" value={this.state.gender} disabled={this.state.disabled} onChange={this.onChange} required>
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                  </select>
                  </div>
                  </div>
                </div>

                <div className='col-md-3 inner-form-gap'>
                 <div class="input-field-u">
                  <input id="input3" type="text" name="father_name" value={this.state.father_name} disabled={this.state.disabled} maxLength={20} onChange={this.onChangefather}/>
                  <label for="input3">Father Name</label>
               </div>
               {this.state.father_vaild?(<span></span>):(<div className='invalid1'>Invalid Father Name</div>)}
               </div>

               <div className='col-md-3 inner-form-gap'>
                 <div class="input-field-u">
                  <input id="input4" type="text" name="mother_name" value={this.state.mother_name} disabled={this.state.disabled} maxLength={20} onChange={this.onChangemother}/>
                  <label for="input4">Mother Name</label>
               </div>
               {this.state.mother_vaild?(<span></span>):(<div className='invalid1'>Invalid Mother Name</div>)}
               </div>
               <div className='col-md-3'></div>

                <div className='col-md-3 inner-form-gap'>
                  <div className='docs-card'>
                    <div className='card-name'>PAN Card
                    {pan===1?(<>
                      <span className='upload-act'><i class="fas fa-check-circle"></i></span>
                      <MDBIcon icon="eye" className='mdbicon' onClick={(name)=>this.viewDocs(pan_name)}/>
                      <MDBIcon icon="download" className='mdbicon' onClick={(name)=>this.download(pan_name)}/>
                      {this.state.disabled?(
                        <MDBIcon icon="edit" className='mdbicon-disabled' />
                      ):(
                        <MDBIcon icon="edit" className='mdbicon'  id="pan_card" onClick={this.adddocument}/>
                      )}
                    </>):(
                    <>
                    {this.state.disabled?(
                        <MDBIcon icon="upload" className='mdbicon-disabled' />
                      ):(
                        <MDBIcon icon="upload" className='mdbicon'  id="pan_card" onClick={this.adddocument}/>
                      )}
                    </>
                    )}
                    </div>
                  </div>
                </div>

                <div className='col-md-3 inner-form-gap'>
                  <div className='docs-card'>
                    <div className='card-name'>Aadhar Card

                    {aadhar===1?(<>
                    <span className='upload-act'><i class="fas fa-check-circle"></i></span>
                    
                    <MDBIcon icon="eye" className='mdbicon' onClick={(name)=>this.viewDocs(adhar_name)}/>
                    <MDBIcon icon="download" className='mdbicon' onClick={(name)=>this.download(adhar_name)}/>
                    {this.state.disabled?(
                      <MDBIcon icon="edit" className='mdbicon-disabled' />
                    ):(
                      <MDBIcon icon="edit" className='mdbicon' id="adhaar_card" onClick={this.adddocument}/>
                    )}
                    
                    </>):(
                    <>
                    {this.state.disabled?(
                      <MDBIcon icon="upload" className='mdbicon-disabled' />
                    ):(
                      <MDBIcon icon="upload" className='mdbicon' id="adhaar_card" onClick={this.adddocument}/>
                    )}
                    </>
                    )}
                    </div>
                  </div>
                </div>

                <div className='col-md-3 inner-form-gap'>
                  <div className='docs-card'>
                    <div className='card-name'>Cancelled Cheque
                    {cheque===1?(<>
                    <span className='upload-act'><i class="fas fa-check-circle"></i></span>
                    {/* <div className='upld-filename'>{cheque_name}</div> */}
                    <MDBIcon icon="eye" className='mdbicon' onClick={(name)=>this.viewDocs(cheque_name)}/>
                    <MDBIcon icon="download" className='mdbicon' onClick={(name)=>this.download(cheque_name)}/>
                    {this.state.disabled?(
                    <MDBIcon icon="edit" className='mdbicon-disabled'/>
                    ):(
                    <MDBIcon icon="edit" className='mdbicon' id="cancelled_check" onClick={this.adddocument}/>)}
                    
                    </>):(
                    <>
                    {this.state.disabled?(
                    <MDBIcon icon="upload" className='mdbicon-disabled'/>
                    ):(
                    <MDBIcon icon="upload" className='mdbicon' id="cancelled_check" onClick={this.adddocument}/>)}
                    
                    
                    </>
                     )}
                     </div>
                  </div>
                </div>

                <div className='col-md-3 inner-form-gap'>
                  <div className='docs-card'>
                    <div className='card-name'>Bank Statement
                    {statement===1?(<>
                    <span className='upload-act'><i class="fas fa-check-circle"></i></span>
                    <MDBIcon icon="eye" className='mdbicon' onClick={(name)=>this.viewDocs(statement_name)}/>  
                    <MDBIcon icon="download" className='mdbicon' onClick={(name)=>this.download(statement_name)}/>
                    {this.state.disabled?(
                    <MDBIcon icon="edit" className='mdbicon-disabled'/>
                    ):(
                    <MDBIcon icon="edit" className='mdbicon' id="bank_statement" onClick={this.adddocument}/>
                    )}
                    
                    </>):(
                    <>
                    {this.state.disabled?(
                    <MDBIcon icon="upload" className='mdbicon-disabled'/>
                    ):(
                    <MDBIcon icon="upload" className='mdbicon' id="bank_statement" onClick={this.adddocument}/>
                    )}
                    </>
                    )}
                    </div>
                  </div>
                </div>

                <div className='col-md-3 inner-form-gap'>
                  <div className='docs-card'>
                    <div className='card-name'>Address Proof
                    {address===1?(<>
                    <span className='upload-act'><i class="fas fa-check-circle"></i></span> 
                    <MDBIcon icon="eye" className='mdbicon' onClick={(name)=>this.viewDocs(address_name)}/>     
                    <MDBIcon icon="download" className='mdbicon' onClick={(name)=>this.download(address_name)}/>
                    {this.state.disabled?(
                    <MDBIcon icon="edit" className='mdbicon-disabled'/>
                    ):(
                    <MDBIcon icon="edit" className='mdbicon' id={this.state.document_type} onClick={(e,name)=>this.adddocument(e,'address')}/>)}
                    </>):(
                    <>
                    {this.state.disabled?(
                    <MDBIcon icon="upload" className='mdbicon-disabled'/>
                    ):(
                    <MDBIcon icon="upload" className='mdbicon' id={this.state.document_type} onClick={(e,name)=>this.adddocument(e,'address')}/>)}
                    </>
                     )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='perm-add-box'>
              {this.props.kyc===1?(
                <div>
                    {this.state.disabled?(
                        <div className='row'>
                            <div className='col-8 same-address-head'>Do you want to open the Joint Holding Account?</div>
                            <div className='col-4 radio-button'>
                            <span><input type='radio' name='one' value='Y' disabled checked={this.state.Ycheck}/>Yes</span>
                            <span><input type='radio' name='one' value='N' disabled checked={this.state.Ncheck}/>No</span>
                            </div>
                        </div>
                    ):(
                        <div className='row'>
                        <div className='col-8 same-address-head'>Do you want to open the Joint Holding Account?</div>
                        <div className='col-4 radio-button'>
                            <span><input type='radio' name='one' value='Y' onChange={this.joint_flag} checked={this.state.Ycheck}/>Yes</span>
                            <span><input type='radio' name='one' value='N' onChange={this.joint_flag} checked={this.state.Ncheck}/>No</span>
                        </div>
                       
                        </div>)}
                    </div>):(
                    <div className='row'>
                        <div className='col-8 same-address-head'>Do you want to open the Joint Holding Account?</div>
                        <div className='col-4 radio-button'>
                            <span><input type='radio' name='one' value='Y'checked={this.state.Ycheck}  onChange={this.joint_flag}/>Yes</span>
                            <span><input type='radio' name='one' value='N' checked={this.state.Ncheck} onChange={this.joint_flag}/>No</span>
                        </div>
                    </div>)}

                    {this.state.joint_flag==='Y'?(
                      <div className='row'>
                          <div className='col-md-6 inner-form-gap'>
                            <div className='docs-card'>
                              <div className='card-name'>Joint Holder's Aadhar Card
                              {j_aadhar===1?(<>
                              <span className='upload-act'><i class="fas fa-check-circle"></i></span>
                            
                              <MDBIcon icon="eye" className='mdbicon' onClick={(name)=>this.viewDocs(j_aadhar_name)}/>  
                              <MDBIcon icon="download" className='mdbicon' onClick={(name)=>this.download(j_aadhar_name)}/>
                              {this.state.disabled?(
                              <MDBIcon icon="edit" className='mdbicon-disabled'/>
                              ):(
                              <MDBIcon icon="edit" className='mdbicon' id="joint_holder_adhaar_card" onClick={this.adddocument}/>)}
                              </>):(
                              <>
                              {this.state.disabled?(
                                <MDBIcon icon="upload" className='mdbicon-disabled' />
                               ):(
                                <MDBIcon icon="upload" className='mdbicon'  id="joint_holder_adhaar_card" onClick={this.adddocument}/>
                               )}
                              </>
                              )}
                              </div>
                            </div>
                          </div>

                          <div className='col-md-6 inner-form-gap'>
                            <div className='docs-card'>
                              <div className='card-name'>Joint Holder's PAN Card
                              {j_pan===1?(<>
                                <span className='upload-act'><i class="fas fa-check-circle"></i></span>
                               
                                <MDBIcon icon="eye" className='mdbicon' onClick={(name)=>this.viewDocs(j_pan_name)}/>
                                <MDBIcon icon="download" className='mdbicon' onClick={(name)=>this.download(j_pan_name)}/>
                                {this.state.disabled?(
                                <MDBIcon icon="edit" className='mdbicon-disabled'/>
                                ):(
                                <MDBIcon icon="edit" className='mdbicon' id="joint_holder_pan_card" onClick={this.adddocument}/>)}
                                
                                </>):(
                              <>
                              {this.state.disabled?(
                                <MDBIcon icon="upload" className='mdbicon-disabled'/>
                                ):(
                                <MDBIcon icon="upload" className='mdbicon' id="joint_holder_pan_card" onClick={this.adddocument}/>)}
                                
                              </>
                              )}
                              </div>
                            </div>
                          </div>
                      </div>
                    ):null}
              </div>
            </div>
        {this.props.ria_form===1 && this.props.kyc===0?(
        <div className='submit-Btn'><button onClick={this.props.compliancePrev}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
         {this.state.pan_vaild && this.state.father_vaild && this.state.mother_vaild && this.state.joint_flag?(
        <button type='submit'>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>):(
        <button disabled={true} style={{background:'#E5E7E9'}}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
        )}
        </div>):(<div></div>)}
        {this.props.ria_form===0?(<div className='previous-msg'>Please fill the previous form first.</div>):(<div></div>)}

        {this.props.ria_form===1 && this.props.kyc===1?(<div className='submit-Btn'><button onClick={this.props.compliancePrev}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
        {this.state.disabled?(
      <button onClick={this.props.step2}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
        ):(
        <button type='button' onClick={this.pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
        )}
        </div>):(<div></div>)}
    </form>

    
    {this.state.document_modal?(
  <div className='upload-modal-sec'>
    <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
            <div className='upload-modal-sec-card'>
              <div className='modal-close-icon' onClick={this.closeModaldocument}><MDBIcon icon="times" /></div>
              {this.state.addressDocs?(
              <div className='profile-form-input-kyc'>
                    <select onChange={this.onChange} name="document_type">
                      <option value="">Select</option>
                      <option value="rent_agreement">Rent Agreement</option>
                      <option value="utility_bill">Utility Bill</option>
                      <option value="passport">Passport</option>
                      <option value="voter_id">Voter Id</option>
                    </select>
                </div>
              ):null}
              <div className='upload-component'>
                <AddDocument document_type={this.state.document_type} dir='kyc' onAfterUpload={this.onAfterUpload} refrence='' id={this.props.id}/>
              </div>
            </div>
        </div>
        <div className='col-md-3'></div>
    </div>
  </div>):(<span></span>)}

  {this.state.viewModal?(
  <div className='upload-modal-sec'>
    <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
        <div className='viewDocs-modal-sec-card'>
            <div className='modal-close-icon' onClick={this.closeModaldocument}><MDBIcon icon="times" />
            </div>
                
            {this.state.view?(
                <iframe src={`${this.state.view}#view=fitH`} title="testPdf" height="80%" width="100%" />
           ):null}
          </div>
        </div>
        <div className='col-md-4'></div>
    </div>
  </div>):(<span></span>)}


    </div>
    <div className='col-md-4'></div>
    
    </div>
    </div>

        )
    }
}

import React,{useEffect,useState} from 'react'
import { Row, Col,Table,Tag,Space,Drawer,Popconfirm, Typography, Button,message,Modal,Image} from 'antd';
import {testimonial_get,testimonial_put,testimonial_delete} from '../../Website/Api'
import { SearchOutlined,DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined,EyeOutlined} from '@ant-design/icons';
import Loader from '../../../shared/Loader';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [detailData,setDetailData] = useState({})
    const [modalType,setModalType] = useState('')
    const [testimonialName, setTestimonialName] = useState('')
    const [position, setPosition] = useState('')
    const [status, setStatus] = useState('')
    const [body, setBody] = useState('')
    const [testimoniallist, setTestimoniallist] = useState({})
    const [image,setImage] = useState('')
    const [loading,setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toastMsg =(id,msg,type)=>{
      toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    }

    const getTestimonialList= async () =>{
    setLoading(true)
    let newdata = []
        const input = {access_token:localStorage.token}
        const res = await testimonial_get(input)
        try{
        if (res && res.status==='successfull'){ 
            setLoading(false)
            Object.keys(res.result).map((val)=>{
            newdata.push({ 
                key: res.result[val].name,
                image:res.result[val].image,
                name:res.result[val].name,
                position:res.result[val].position,
                date:res.result[val].date,
                statement:res.result[val].statement,
                status:res.result[val].status,
            })
            })
            setData(newdata)
            setLoading(false)
        }
        else{setLoading(false); toast.error(res.message);}
        }catch{setLoading(false); toast.error('Something went wrong');}
    
    }
    useEffect(()=>{
        getTestimonialList()
    },[])

    const handleTestimonialName = (e) => {
        setTestimonialName(e.target.value)
    }
    const handlePosition = (e) => {
        setPosition(e.target.value)
    }
    const handleStatus = (e) => {
        setStatus(e.target.value)
    }
    const handleBody = (e) => {
        setBody(e.target.value)
    }
    const handleImage = (e) => {
        setImage(e.target.files[0])
    }
 
  const handleBlogChange = (e) =>{
      let aa = {...detailData}
      aa[e.target.name] = e.target.value
      setDetailData(aa)
  }
  const handleSave=()=>{
      const id = toast.loading("Please wait...",{toastId:'load'})
      const input={
          access_token:localStorage.token,
          row:detailData
      }
    testimonial_put(input).then(res => {
    try{
    if (res.status==='successfull') {
        toastMsg(id,res.message,'success')
        setVisible(false)
        getTestimonialList();
        setLoading(false)
        message.success(res.message);
  }
    else{toastMsg(id,res.message,'error')}
    }catch{toastMsg(id,'Something went wrong','error');}
  })
  }

  const showDrawerAction=(type,record)=>{
    setVisible(true);
    setModalType(type)
    setDetailData(record)
  }   
  const onClose = () => {
      setVisible(false);
      setModalType('')
      setDetailData({})
    };
    
    const submitData = (e) =>{
        const id = toast.loading("Please wait...",{toastId:'load'})
        e.preventDefault();
        let data = new FormData();
        data.append('image', image);
        data.append('name', testimonialName);
        data.append('position', position);
        data.append('statement', body);
        data.append('status', status);
       
        fetch('/alpha_user_website/testimonials', {
            method: 'POST',
            headers: {
                'Authorization': localStorage.token
              },
            body: data
        }).then(response => response.json())
        .then(data => {
          if(data.status==='successfull'){
                toastMsg(id,data.message,'success')
                setVisible(false)
                setIsModalVisible(false)
                getTestimonialList();
                setTestimonialName('')
                setPosition('')
                setStatus('')
                setBody('')
                setImage('')
          }
          else{toastMsg(id,data.message,'error')}
        }).catch(err => {toastMsg(id,'Something went wrong','error')});
    }
      const handleDelete=(record)=>{
        const id = toast.loading("Please wait...",{toastId:'load'})
        const input={
            access_token:localStorage.token,
            row:record.name
        }
        testimonial_delete(input).then(res => {
      try{
      if (res.status==='successfull') {
        toastMsg(id,res.message,'success')
        getTestimonialList();
    }
    else{toastMsg(id,res.message,'error')}
    }catch{toastMsg(id,'Something went wrong','error')}
    })  
    }

    const showModal = () => {
        setIsModalVisible(true);
      };
      const handleCancel = () => {
        setIsModalVisible(false);
      };

    const columns = [
        {
          title: 'Image',
          dataIndex: 'image',
          width: 50,
          render: (image) =>(
            <Image width={50} src={'data:image/png;base64,'+ image}/>
          )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            position:'top',
            width: 200,
        },
        {
            title: 'Position',
            dataIndex: 'position',
            width: 130,
          },
          {
            title: 'Status',
            dataIndex: 'status',
            width: 130,
          },
          {
            title: 'Date',
            dataIndex: 'date',
            width: 80,
          },
          {
            title: 'Description',
            dataIndex: 'statement',
            width: 320,
            
          },
          {
            title: 'Operation',
            dataIndex: 'operation',
            fixed: 'right',
            width: 200,
            render: (_, record) => {
              return(
                <>
                <span style={{marginRight:'10px'}}>
                <Typography.Link
                    onClick={() => showDrawerAction('Testimonial Details',record)}>
                    <span className='outlineEditButton'> <EyeOutlined style={{ fontSize: '13px', color: '#F7DC6F', marginRight:'3px' }} />View</span>
                </Typography.Link>
                </span>
                <Typography.Link
                    onClick={() => showDrawerAction('Edit Testimonial',record)}>
                    <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
                </Typography.Link>
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                  <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'10px' }} />
                </Popconfirm>
                 </>
                
              )
            },
          }, 
      ];
   
    return (
        <div>
        <ToastContainer theme='colored'/>
        {loading?<Loader/>:null}
            <Row>
                <Col span={24}>
                    <Table
                        rowClassName="editable-row"
                        columns={columns}
                        dataSource={data}
                        size='small'
                        scroll={{y: 400 }}
                        sticky={true}
                    />
                </Col>
            </Row>

            <div className='addBtn'><button onClick={()=>showDrawerAction('Add Testimonial')}>Add Testimonial</button></div>

            <Drawer title={modalType} placement="top" height={'600'} onClose={onClose} visible={visible}>
                {modalType==='Add Testimonial'?
                <div>
                <div>
                    <p className='testimonialModalHead'>Name</p>
                    <textarea className='testimonialModalInput' rows="2" cols="50" onChange={handleTestimonialName} value={testimonialName}></textarea>
                </div>
                <div>
                    <p className='testimonialModalHead'>Position</p>
                    <textarea className='testimonialModalInput' rows="2" cols="50" onChange={handlePosition} value={position}></textarea>
                </div>
                <div>
                    <p className='testimonialModalHead'>Status</p>
                    <select onChange={handleStatus} value={status}>
                        <option className='testimonialModalInput'>Select</option>
                        <option className='testimonialModalInput'>Show</option>
                        <option className='testimonialModalInput'>Hidden</option>
                    </select>
                </div>
                <div>
                    <p className='testimonialModalHead'>Body</p>
                    <textarea className='newLin testimonialModalInput' rows="4" cols="50" onChange={handleBody} value={body}></textarea>
                </div>
                <div>
                    <p className='testimonialModalHead'>Image</p>
                    <input type="file" accept="image/jpeg,image/png"  onChange={handleImage} />
                </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary" onClick={showModal}
                    data-dismiss="modal"
                  >Save Testimonial</button>
              </div>
                </div>:null}

                <Modal visible={isModalVisible} onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                      Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={submitData}>
                      Save & Publish
                    </Button>,
                    ]}
                >
                  <h5>you want to save the testimonial.</h5>
                </Modal>
                {modalType==='Edit Testimonial'?
                <>
                  {detailData?<>
                  <div className='BlogModalCode'>
                        <div className='blogModal'><input value={detailData.name} name="name" disabled /></div>
                        <div className='blogModal'><input value={detailData.position} name="position" onChange={handleBlogChange}/></div>
                        <div className='blogModal'>
                            <select value={detailData.status} name="status"  onChange={handleBlogChange}>
                                <option className='testimonialModalInput'>Select</option>
                                <option className='testimonialModalInput'>Show</option>
                                <option className='testimonialModalInput'>Hidden</option>
                            </select>
                        </div>
                        <div className='blogModal'><textarea className='newLin' value={detailData.statement} rows='10' name="statement" onChange={handleBlogChange}></textarea></div>
                      <div className='blogSubmitBtn'><button  className='' onClick={handleSave}>Save</button></div>
                  </div>
                  </>:null}
                </>:null}
                {modalType==='Testimonial Details'?<>
                {detailData?
                <>  
                    <div className='row'>
                        <div className='col-1'>
                            <p className='testimonialDetailHead'><b>Image :</b> <img src={'data:image/png;base64,'+ detailData.image} alt=''></img></p>
                        </div>
                        <div className='col-10'>
                            <p className='RecomDetailHead'><b>Name :</b> {detailData.name}</p>
                            <p className='RecomDetailHead'><b>Position :</b> {detailData.position}</p>
                            <p className='RecomDetailHead newLin'><b>Description :</b> {detailData.statement}</p>
                            
                        </div>
                    </div>
                    
                   
                    
                </>
                :null}
                </>:null}
                
            </Drawer>


            {/* <Drawer title="Blog Details" placement="top" height={'600'} onClose={onClose} visible={visible}>
                {detailData?
                <>  
                    <div className='row'>
                        <div className='col-9'>
                            <p className='RecomDetailHead'><b>Title :</b> {detailData.title}</p>
                            <p className='RecomDetailHead newLin'><b>Description :</b> {detailData.description}</p>
                            <p className='RecomDetailHead'><b>Author :</b> {detailData.author}</p>
                        </div>
                        <div className='col-3'>
                            <p className='RecomDetailHead'><b>Image :</b> <img src={'data:image/png;base64,'+ detailData.img} alt=''></img></p>
                        </div>
                    </div>
                    
                   
                    
                </>
                :null}
            </Drawer> */}
        </div>
    )
}

export default Home



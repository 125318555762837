import React, { useState, useEffect } from 'react';
import AreaGraph from './AreaGraph';
import { Row, Col,Modal,Form, Input,Button} from 'antd';
// import Loader from '../../../../shared/Loader';

import {Catastrophe_Planning} from '../Api'
const Hlv = (props) => {

  const [graphData, setGraphData] = useState({});
  const [loading,setLoading] = useState(false)


const fetchHlv = async () =>{
        // setLoading(true)
        const input = {
          access_token:localStorage.token,
          id:props.id
        }
        const res = await Catastrophe_Planning(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('hlv',res);
        try{
          if (res.status==='successfull'){
            setGraphData(res.result)
            // setLoading(false)
          }else{
            // setLoading(false)
          }
        }catch{
          // setLoading(false)
        }
    }
    useEffect(()=>{
      fetchHlv()
  },[])

  // console.log(graphData.graph);
// const numberFormat=(value)=>{
//   var val = Math.abs(value)
//   if (val >= 10000000) {
//     val = (val / 10000000).toFixed(2) + ' Cr';
//   } else if (val >= 100000) {
//     val = (val / 100000).toFixed(2) + ' L';
//   }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
//   return val;
// }
const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits:0
    }).format(value);
  return (
    <div>
    {/* {loading?<Loader/>:null} */}
      <div className='WGContainer'>
        <div className='coverGraphHead'>Catastrophic planning</div>
        <div className='coverBtmCard'>
        
        {graphData.hlv_value?<>
                <Row>
                    <Col span={8}>
                        <div className='coverCardDiv'>
                            <div>Exisiting Coverage
                              <span className='coverCardDivValue'>
                              {numberFormat(graphData.hlv_value.exisiting_coverage)}</span>
                            </div>
                        </div>
                        <div className='coverCardDiv'>
                            <div>Net Assets to liquidate
                            <span className='coverCardDivValue'>{numberFormat(graphData.hlv_value.net_assets_to_liquidate)}</span>
                            </div>
                        </div>
                        <div className='coverCardDiv'>
                            <div>O/S Liabilities
                            <span className='coverCardDivValue'>{numberFormat(graphData.hlv_value.liabilities)}</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={8} style={{borderLeft:'1px solid #FF862C'}}>
                        <div className='coverCardDiv'>
                            <div>% of the expenses required<span className='coverCardDivValue'>80%</span></div>
                        </div>
                        <div className='coverCardDiv'>
                            <div>Inflation<span className='coverCardDivValue'>8%</span></div>
                        </div>
                        <div className='coverCardDiv'>
                            <div>Returns<span className='coverCardDivValue'>9%</span></div>
                        </div>
                    </Col>
                    <Col span={8} style={{borderLeft:'1px solid #FF862C'}}>
                        <div className='coverCardDivMain'>Additional Insurance Required</div>
                        <div className='coverCardDivMainValue'>{numberFormat(graphData.hlv_value.additional_insurance_required)}</div>                        
                    </Col>
                </Row> 
                </>:null}
                </div>
        <div className='WGchartArea'>
            {Object.keys(graphData).length>0?
            <AreaGraph color='#53d961' graphData={graphData.graph.networth_growth_chart}/>:null}
        </div>
      </div>
    </div>
  )
}

export default Hlv
import React,{useEffect, useState} from 'react'
import {validatePasswordResetToken, sendPasswordResetData} from '../Api';
import {useNavigate,useParams} from 'react-router-dom'
import { Col,Row,Image, Button, Form, Input } from 'antd';
import { MDBIcon } from 'mdbreact';
import LoginImage from '../../../Images/loginImage.jpg'
import Logo from '../../../Images/logoBlack.png'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordResetValidator = (props) => {
  let { token } = useParams();
  const navigate = useNavigate();
  const toastMsg =(id,msg,type)=>{
    toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
  } 

  useEffect(()=>{
    validatePasswordResetToken(token).then(res => { 
      console.log("resvalida",res)
      if (res.status==='successfull') {}
      else{}
    })
  },[])
  const onSubmitLogin = async (values)=>{
    const id = toast.loading("Please wait...")
    const input = {
      token: token,
      password: values.password
    }
    const res = await sendPasswordResetData(input)
    .catch((err) => {toastMsg(id,'Something went wrong','error')});
    try{
      if (res.status==='successfull') {
        navigate('/login')
      }else{
        toastMsg(id,res.message,'error')
      }
    }catch{
      toastMsg(id,'Something went wrong','error')
    }
  }

  const ResetForm = ()=>{
    return(
      <Form onFinish={onSubmitLogin}>
      <Form.Item
        name="password"
        rules={[
          {required: true, message: 'Please input your Password!' },
          {message: 'Password must contain at least 1 lowercase 1 uppercase 1 numeric character 1 special character and minimum 8 characters long',
          validator: (_, value) => {
            if (RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})").test(value)) {
              return Promise.resolve();
            } else {
              return Promise.reject('Some message here');
            }
            }
          }
        ]}
        hasFeedback
        >
        <Input.Password
        prefix={<MDBIcon icon="lock" className='loginFormIcon'/>}
        placeholder="Password"
        className='LoginFormInput'/>
      </Form.Item>
   
      <Form.Item
        name="confirm_password"
        dependencies={['password']}
        rules={[
          { required: true, message: 'Please confirm your password!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
        hasFeedback
        >
        <Input.Password
        prefix={<MDBIcon icon="lock" className='loginFormIcon'/>}
        placeholder="Confirm Password"
        className='LoginFormInput'/>
      </Form.Item>
   
      <Form.Item>
        <Button htmlType="submit" className="loginFormButton">
          Reset Password
        </Button>
      </Form.Item>
    </Form>
    )
  }

  return (
      <div className='fixPageHeight'>
          <ToastContainer theme='colored'/>
        <div className='desktop'>
          <Row>
            <Col span={13}>
              <div className='loginFormDiv'>
                <Image src={Logo} rootClassName='loginLogoImage' preview={false}/>
                <h6 className='LoginHeading'>Create a new password</h6>
                <ResetForm/>
              </div>
            </Col>
            <Col span={11}>
              <Image src={LoginImage} rootClassName='loginImage' preview={false}/>
            </Col>
          </Row>
          </div>
          <div className='mobile'>
            <div className='mobileLoginBg'>
              <Image src={Logo} rootClassName='loginLogoImage' preview={false}/>
              <div className='mobileLoginBox'>
                <h6 className='LoginHeading'>Create a new password</h6>
                <ResetForm/>
              </div>
          </div>
        </div>
      </div>
        )
      }
export default PasswordResetValidator
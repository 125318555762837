import React,{useState,useEffect} from 'react';
import './Update.scss'
import {Button,Row,Col,message,Select,Upload} from 'antd';
import {DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined,UploadOutlined,DownloadOutlined} from '@ant-design/icons';
import {ngenFile} from '../Api'
import Ngensample from '../../../../Files/NGEN.csv'
import Dividendsample from '../../../../Files/DIVIDEND.xlsx'

const Ngen = () => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList,setFileList] = useState([])
    const [holdingUploading,setHoldingUploading] = useState(false)
    const [fileName,setFileName] = useState('')
    const [fileType, setFileType] = useState('')
 
    let pdf1=null



    const download=()=>{
        // setIsModalOpen(false)
        const input = {
            access_token:localStorage.token,
            flag:fileName,
            extension:fileType.values
        }
        console.log(input);
        ngenFile(input).then(res => {
            console.log(input);
            console.log("resss",res)
            try {
              if (res.status==='successfull') {
                pdf1=res.result.fileContent
                const linkSource = `data:application/vnd.ms-excel;base64,${pdf1}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = res.result.fileName;
                downloadLink.click();
            }
            
                else{}
            } catch (error) {}
            })
    }
    const handleUploadHoldings = () => {
        const formData = new FormData();
        fileList.forEach(file => {formData.append('csv_file', file)});
        formData.append('flag', fileName)
        formData.append('extension', fileType.values)
        setHoldingUploading(true)
        fetch('/alpha_admin/ngenFile', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': localStorage.token
          },
        })
          .then(res => res.json())
          .then((data) => {
            console.log(data);
            if(data.status==='successfull'){
              
              message.success(data.message)}
              setFileList([])
            if(data.status==='failed'){
            message.error(data.message)}
            setHoldingUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setHoldingUploading(false);
          })
          .finally(() => {});
      };

      const props = {
        maxCount:1,
        accept:'.csv, .xlsx',
        beforeUpload: file => {
          setFileList( [...fileList, file])
          return false;
        },
        fileList,
      };

      const updatefileName=(value,values)=>{
        setFileName(value)
        setFileType(values)
      }
      

      const { Option } = Select;

  return (
    <div>
    <Select
        style={{ width: 350 }}
        showSearch
        bordered={true}
        title='Client Mail ID'
        className='selectborder'
        placeholder="Select Document"
        optionFilterProp="children"
        onChange={updatefileName}
        filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        >
      <Option value='NGEN' values='.csv'>Ngen</Option>
      <Option value='DIVIDEND' values='.xlsx'>Dividend</Option>
    </Select>
    {fileName==='NGEN'?
    <div>
      <div className='equityDocsMainDiv'>
          <span className='equityDocshead'>NGEN</span>
          <span className='equityDocsSampletext'><span className='equityDocsSampletext2'>Download a sample design to upload an NGEN Excel.</span><Button><a  href={Ngensample} download='NGEN Sample'> Download</a></Button></span>
      </div>
          <Row>
          <Col span={12}>
          <div className='equityTitleDiv'>
          <div className='equityTitleheadUpload'>Upload Here</div>
          <div className='equityDocsMainDiv'>
            <Upload
              {...props}
              ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
              <Button
                type="primary"
                onClick={handleUploadHoldings}
                disabled={fileList.length === 0}
                loading={holdingUploading}
                className='startUploading'
                onRemove={true}
              >
                {holdingUploading ? 'Uploading' : 'Start Upload'}
              </Button>
            </div>
            </div>

          </Col>
          <Col span={12}>
          <div className='equityTitleDiv'>
            <div className='equityTitlehead'>Download Here</div>
            <Button onClick={()=>download()}>Download</Button>
          </div>
          </Col>
        </Row>
      </div>
      :null}
      {fileName==='DIVIDEND'?
    <div>
      <div className='equityDocsMainDiv'>
          <span className='equityDocshead'>Dividend</span>
          <span className='equityDocsSampletext'><span className='equityDocsSampletext2'>Download a sample design to upload an Dividend Excel.</span><Button><a  href={Dividendsample} download='Dividend Sample'> Download</a></Button></span>
      </div>
          <Row>
          <Col span={12}>
          <div className='equityTitleDiv'>
          <div className='equityTitleheadUpload'>Upload Here</div>
          <div className='equityDocsMainDiv'>
            <Upload
              {...props}
              ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
              <Button
                type="primary"
                onClick={handleUploadHoldings}
                disabled={fileList.length === 0}
                loading={holdingUploading}
                className='startUploading'
                onRemove={true}
              >
                {holdingUploading ? 'Uploading' : 'Start Upload'}
              </Button>
            </div>
            </div>

          </Col>
          <Col span={12}>
          <div className='equityTitleDiv'>
            <div className='equityTitlehead'>Download Here</div>
            <Button   onClick={()=>download()}>Download</Button>
          </div>
          </Col>
        </Row>
      </div>
      :null}
      
    </div>
  )
}

export default Ngen
import React,{useState,useEffect} from 'react';
import StockList from './Stock_list';
import EquityDoc from './Equity_doc';
import IndexFundDoc from './Index_fund_doc';
import Isinequity from './Isin_equity';
import Ngen from './Ngen';


const Asset_class_list = (props) => {
  
  return (
    <>
      {props.tavs==='stock_list'?<StockList/>:null}
      {props.tavs==='equity'?<EquityDoc/>:null}
      {props.tavs==='index_fund'?<IndexFundDoc/>:null}
      {props.tavs==='isin_equity'?<Isinequity/>:null}
      {props.tavs==='ngen'?<Ngen/>:null}
    </>
  )
}

export default Asset_class_list
import React,{useState,useEffect} from 'react'
import './Contactus.scss'
import './ContactMedia.scss'
import Footer from '../Universal/Footer'
import FooterContact from '../Universal/FooterContact'
import {MDBIcon} from 'mdbreact'
import Navbar from '../Universal/Navbar'
// import Calculator from '../Calculator/Calculator'

const Address = (props) => {
    return (
        <>
        <div><Navbar /></div>
        <div className='bodyDiv'>
            <div className='contactBannerImage'>
                <div className='row'>
                    <div className='col-md-8'></div>
                    <div className='col-md-4'>
                        <div className='contactBannerText'>
                        WE'D LOVE TO HEAR<br/>FROM YOU
                        </div>
                    </div>
                </div>
            </div>
            <div className='mapBg'>   
                <div className='row'>
                    <div className='col-md-6'>
                        <div classname='mapBox'>        
                           <iframe width="100%" height="350" id="gmap_canvas" src="https://maps.google.com/maps?q=LBS%20Mulund%20West,%20Mumbai%20400080&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='addressBox'>
                            <div className='hoaName'>House of Alpha</div>
                            <div className='hoaAddress'>438, Avior Corporate Park, Opp. Johnson & Johnson Ltd., LBS Road, Mulund West, Mumbai 400080</div>
                            <div>
                                <span className='mobileIcon'><MDBIcon icon="mobile-alt" /></span><span className='hoaMobile'>+91 9819130637</span>
                                <div>
                                    <span className='mobileIcon'><MDBIcon far icon="envelope" /></span><span className='hoaMobile'>info@houseofalpha.in</span>
                                </div>
                             </div>
                        </div>
                    </div>
                    {/* <button><Calculator/></button> */}
                </div>
            </div>
            <div><FooterContact /></div>
            <div className='footrcontcBG'></div>
            <div><Footer /></div>
        </div>
        </>
    )
}

export default Address

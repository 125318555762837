import axios from 'axios'

export const relation = input => {
  return axios
      .get('/alpha_user_profile_setup/relation',
      {
          headers: {
            'Authorization': input.access_token
          },
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const save_family_details = input => {
  return axios
      .post('/alpha_user_profile_setup/family_details', {
          access_token:input.access_token,
          family_data:input.family_data,
          id:input.id,
          skip:input.skip
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const update_family_details = input => {
return axios
    .put('/alpha_user_profile_setup/family_details', {
        access_token:input.access_token,
        id:input.id,
        cellName:input.cellName,
        cellValue:input.cellValue,
        row_id:input.row_id,
    },
    {
        headers: {
          'Authorization': input.access_token
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const delete_family_details = input => {
return axios
    .delete('/alpha_user_profile_setup/family_details',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          id:input.id,
          delete_row:input.delete_row,
        }
      },)
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const get_family_details = input => {
  return axios
      .get('/alpha_user_profile_setup/family_details',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const save_bank_details = input => {
    return axios
        .post('/alpha_user_profile_setup/bank', {
                access_token:input.access_token,
                bank_name:input.bank_name,
                branch:input.branch,
                account_holder_name:input.account_holder_name,
                account_number:input.account_number,
                account_type:input.account_type,
                ifsc_code:input.ifsc_code,
                micr_code:input.micr_code,
                id:input.id
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}


export const update_bank_details = input => {
  return axios
      .put('/alpha_user_profile_setup/bank', {
        access_token:input.access_token,
        bank_name:input.bank_name,
        branch:input.branch,
        account_holder_name:input.account_holder_name,
        account_number:input.account_number,
        account_type:input.account_type,
        ifsc_code:input.ifsc_code,
        micr_code:input.micr_code,
        id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}

export const save_fatca_details = input => {
    return axios
        .post('/alpha_user_profile_setup/fatca', {
            access_token:input.access_token,
            country_of_birth_ansi_code:input.country_of_birth_ansi_code,
            no_other_tax_residences:input.no_other_tax_residences,
            source_of_wealth:input.source_of_wealth,
            gross_annual_income:input.gross_annual_income,
            city_of_birth:input.city_of_birth,
            net_worth:input.net_worth,
            occupation:input.occupation,
            political_exposed_person:input.political_exposed_person,
            any_other_info:input.any_other_info,
            retirement_age:input.retirement_age,
            id:input.id
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const update_fatca_details = input => {
  return axios
      .put('/alpha_user_profile_setup/fatca', {
        access_token:input.access_token,
        country_of_birth_ansi_code:input.country_of_birth_ansi_code,
        no_other_tax_residences:input.no_other_tax_residences,
        source_of_wealth:input.source_of_wealth,
        gross_annual_income:input.gross_annual_income,
        city_of_birth:input.city_of_birth,
        net_worth:input.net_worth,
        occupation:input.occupation,
        political_exposed_person:input.political_exposed_person,
        any_other_info:input.any_other_info,
        retirement_age:input.retirement_age,
        id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}

export const save_nominee_details = input => {
    return axios
        .post('/alpha_user_profile_setup/nominee', {
          access_token:input.access_token,
          nominee_data:input.nominee_data,
          id:input.id,
          skip:input.skip
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}

export const update_nominee_details = input => {
  return axios
      .put('/alpha_user_profile_setup/nominee', {
        access_token:input.access_token,
        nominee_name:input.nominee_name,
        relationship:input.relationship,
        nominee_type:input.nominee_type,
        nominee_dob:input.nominee_dob,
        guardian_name:input.guardian_name,
        pan_number:input.pan_number,
        nominee_percentage:input.nominee_percentage,
        id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}


export const save_kyc_details = input => {
    return axios
        .post('/alpha_user_profile_setup/kyc', {
            access_token:input.access_token,
            dob:input.dob,
            tax_status:input.tax_status,
            pan_number:input.pan_number,
            gender:input.gender,
            aadhar_number:input.aadhar_number,
            father_name:input.father_name,
            mother_name:input.mother_name,
            id:input.id
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}

export const update_kyc_details = input => {
  return axios
      .put('/alpha_user_profile_setup/kyc', {
          access_token:input.access_token,
          dob:input.dob,
          tax_status:input.tax_status,
          pan_number:input.pan_number,
          gender:input.gender,
          aadhar_number:input.aadhar_number,
          father_name:input.father_name,
          mother_name:input.mother_name,
          id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_bank_details = input => {
  return axios
      .get('/alpha_user_profile_setup/bank',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_fatca_details = input => {
  return axios
      .get('/alpha_user_profile_setup/fatca',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_kyc_details = input => {
  return axios
      .get('/alpha_user_profile_setup/kyc',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_nominee_details = input => {
  return axios
      .get('/alpha_user_profile_setup/nominee',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const delete_nominee_details = input => {
  return axios
      .delete('/alpha_user_profile_setup/nominee',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
            delete_row:input.delete_row,
          }
        },)
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const country = input => {
  return axios
      .get('/alpha_user_profile_setup/country',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_last_index = input => {
  return axios
      .get('/alpha_user_profile_setup/last_index',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            flag:input.flag,
             id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const next_click = input => {
  return axios
      .put('/alpha_user_profile_setup/next', {
          access_token:input.access_token,
          flag:input.flag,
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const save_cheque_img = input => {
  return axios
      .post('/alpha_user_profile_setup/save_cheque_img', {
          access_token:input.access_token,
          base64: input.base64,
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_cheque_img = input => {
  return axios
  .get('/alpha_user_profile_setup/save_cheque_img',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
         id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const save_pan_img = input => {
  return axios
      .post('/alpha_user_profile_setup/save_pan_img', {
          access_token:input.access_token,
          base64: input.base64,
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_pan_img = input => {
  return axios
  .get('/alpha_user_profile_setup/get_pan_img',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
         id:input.id
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
}
export const get_uploaded_docs = input => {
  return axios
      .get('/alpha_user_profile_setup/docs_upload',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
            dir:input.dir
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
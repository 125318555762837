import React, { useState,useEffect} from 'react'
import './Reporting.scss'
import HoldingReport from './HoldingReport'
import SipReport from './SipReport'
import TransationReport from './TransationReport'
import YearWiseReport from './YearWiseReport'
import AddComment from '../comment/AddComment'
import {MDBIcon} from 'mdbreact'

const ReportHome = (props) => {

    const [tab,setTab] = useState({holding:true,sip:false,transaction:false,
        yearWise:false})
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleTab=(flag)=>{
        let dict = {...tab}
        Object.keys(dict).map(item => {
            if(item===flag){dict[item] = true
            }else{dict[item] = false}
            });
            setTab(dict)
    }
    const handleModal = () => {setIsModalVisible(!isModalVisible);};
  return (
    <div className='tableContainer'>
    <div className='tableTabContainer'>
       <div className={tab.holding?'tableTabActive':'tableTab'} 
       onClick={()=>handleTab('holding')}>Holdings</div>
       <div className={tab.transaction?'tableTabActive':'tableTab'} 
       onClick={()=>handleTab('transaction')}>Transactions</div>
       <div className={tab.sip?'tableTabActive':'tableTab'} 
       onClick={()=>handleTab('sip')}>SIPs</div>
       <div className={tab.yearWise?'tableTabActive':'tableTab'} 
       onClick={()=>handleTab('yearWise')}>Yearly Summary</div>
       <div className='PagesCommentIconDiv'>
       <span className='valueInRs'>value in (₹)</span>
         <MDBIcon className='PagesCommentIcon' icon="comment-dots" onClick={handleModal}/>
       </div>
   </div>

    <div className='reportBody'>
   {tab.holding?<HoldingReport id={props.id}/>:null}
   {tab.transaction?<TransationReport id={props.id}/>:null}
   {tab.sip?<SipReport id={props.id}/>:null}
   {tab.yearWise?<YearWiseReport  id={props.id}/>:null}
   {isModalVisible? <AddComment handleModal={handleModal}/>:null}
   </div>
</div>
  )
}

export default ReportHome
import React, { Component } from 'react'
import {email_setting} from '../MainPage/Api'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../shared/Loader'
import {MDBIcon} from 'mdbreact'
import {send_verification_code,email_verification} from '../Website/Api';
import OtpInput from 'react-otp-input';
import './Setting.scss'

export default class ChangeEmail extends Component {
    constructor(props){
        super(props);
        this.state={
            email:'',
            password:'',
            error:false,
            success:false,
            errormsg:'',
            loading:false,
            email_validate:true,
            showPassword:false,
            showOtpbox:false,
            otp:''
        }
    }
onChange=(e)=>{
    this.setState({ [e.target.name]: e.target.value })
    }
onsubmitNewmail=(e)=>{
    e.preventDefault()
    this.setState({loading:true})
    const input={email:this.state.email}
    console.log(input);
      send_verification_code(input).then(res => {
        console.log("sres",res)
        try{
        if (res.status==='successfull') {
            this.setState({
                showOtpbox:true,
                loading:false
            })
        }
        else{
            this.setState({loading:false})
          alert(res.message)
        }
        }catch{
            this.setState({loading:false})
          alert("something went wrong")
        }
        })
}
submitOtp=(e)=>{
    this.setState({loading:true})
    const input={
        email:this.state.email,
        code:this.state.otp
    }
    const input1 = {
        access_token:localStorage.token,
        email:this.state.email,
        password:this.state.password,
    }
    console.log(input)
    email_verification(input).then(res => {
        console.log("sres",res)
        try{
        if (res.status==='successfull') {
            email_setting(input1).then(res => {
                console.log("res",res)
            try{
            if (res.status==='successfull') {
                localStorage.removeItem('email')
                localStorage.setItem('email',this.state.email)
                localStorage.removeItem('token')
                localStorage.setItem('token',res.result)
                this.setState(() => ({
                    success:true,
                    loading:false,
                    errormsg:res.message,
                    email:'',
                    password:'',
                }))
            }
            else{
                this.setState({
                    error:true,
                    errormsg:res.message,
                    loading:false
                })
            }
            }catch{
                this.setState({
                    error:true,
                    errormsg:"something went wrong",
                    loading:false
                })
            }
            })
        }
        else{
            this.setState({loading:false})
            alert(res.message)
        }
        }catch{
            this.setState({loading:false})
            alert("something went wrong")
        }
        })
}
cancel=()=>{
    this.setState({
        error:false,
    })
 }
 cancelsuccess=()=>{
    this.setState({
        success:false,
    })
    this.props.afterchangefunction();
 }
 onChangeEmail=(e)=>{
    const validEmailRegex =RegExp(/^(([^<>()\[\]\.,;:#*\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
      let validate= validEmailRegex.test(e.target.value)
    if(validate===true){
        this.setState({
        email: e.target.value,
        email_validate:true})
      }
    else{
      this.setState({
      email_validate:false})
    }
  }
  showPassword=()=>{
    this.setState({
      showPassword:!this.state.showPassword
    })
  }
  handleChange = (otp) =>{
    this.setState({ otp });
  }
  resend=()=>{
    const input1={
      access_token:localStorage.token
    }
    console.log("input11",input1)
    send_verification_code(input1).then(res => {
      try{
      if (res.status==='successfull') {
        alert("New Otp sent successfully")
      }
      else{
        alert("something went wrong")
      }
      }catch{
        alert("something went wrong..")
      }
      })
  }
    render() {
        return (
            <div>
            {this.state.loading?(
                <div className='loader-main-div'>
                    <div className='loader-div'>
                        <Loader/>
                    </div>
                </div>
            ):(null)}
                 {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

            {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancelsuccess}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}
                    <div className='change-card'>
                       <div className='change-head'>Change Email Address</div>
                       {this.state.showOtpbox?(
                           <div style={{textAlign:'center'}}>
                            <div className='verif-text'>
                            A four digit code is sent to your Email-address. Please verify your email by filling the code.
                            </div>
                                <div className='otp-input-shift' style={{textAlign:'center'}}>
                                    <OtpInput
                                        value={this.state.otp}
                                        onChange={this.handleChange}
                                        numInputs={4}
                                        separator={<span>-</span>}
                                        containerStyle='containerStyle'
                                        inputStyle='inputStyle'
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                    />
                                </div>
                                <div className='submit-Btn' style={{textAlign:'center'}}><button onClick={this.submitOtp}class="custom-btn-sm btn-15">Submit</button></div>
                                <div className='resend-text'>Didn't get OTP <span onClick={this.resend}className='resend-text-b'>Resend OTP</span></div>
                        </div>
                       ):(
                        <form onSubmit={this.onsubmitNewmail}>
                        <div className='change-form'>
                            <div class="input-field-u">
                                <input  type='text' id="reset_email11" name="email" onChange={this.onChangeEmail} maxLength={40} required />
                                <label for="reset_email11"><i class="fa fa-lock form-icon-S"></i>New Email ID*</label>
                                {this.state.email_validate?(<span></span>):(<div className='invalid-password'>Invalid Email</div>)}
                            </div>

                            <div class="input-field-u">
                                <input  type={this.state.showPassword? 'text':'password'} id="password_e" name="password" value={this.state.password}  onChange={this.onChange} required />
                                {this.state.showPassword?
                                <MDBIcon far icon="eye" className='eye-icon-active' onClick={this.showPassword}/>:
                                <MDBIcon far icon="eye" className='eye-icon' onClick={this.showPassword}/>}
                                <label for="password_e"><i class="fa fa-lock form-icon-S"></i>Password*</label>
                            </div>
                            <div className='submit-Btn' style={{textAlign:'left'}}>
                                {this.state.email_validate?(<button type='submit' >Submit</button>):(<button disabled={true} >Submit</button>)}
                            </div>
                        </div>
                    </form>)}
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import Moment from 'moment';
import {admin_goal_risk_level,admin_goal_risk_level_post} from '../../Api'
import {MDBIcon} from 'mdbreact'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../../shared/Loader';

export default class Goal_risk extends Component {
    constructor(props){
        super(props);
        this.state={
            goal_dict:{},
            loading:false,
            success:false,
            error:false,
            errormsg:'',
            successmsg:'',
        }
    }
componentDidMount(){
    this.setState({loading:true})
    const input = {
        access_token:localStorage.token,
        customer_id:this.props.customer_id,
        date:Moment(this.props.today).format('YYYY-MM-DD')
    }
    admin_goal_risk_level(input).then(res => {
        console.log(res)
    try{
    if (res.status==='successfull') {
    this.setState(() => ({
        goal_dict:res.result,
        loading:false
    }))
    }
    else{
        this.setState(() => ({
            loading:false
        }))
    }
    }catch{
        this.setState(() => ({
            loading:false
        }))
    }
    })
}

onchange_score=(e)=>{
    let aa = this.state.goal_dict
    aa["goal_risk_score"] = e.target.value
    this.setState({goal_dict:aa})
}
onchange_score_dec=(e)=>{
    let aa = this.state.goal_dict
    aa["goal_risk_score_discription"] = e.target.value
    this.setState({goal_dict:aa})
    }
submit_goal_complete=()=>{
    this.setState({loading:true})
const input = {
    access_token:localStorage.token,
    customer_id:this.props.customer_id,
    goal_dict_post:this.state.goal_dict,
    date:Moment(this.props.today).format('YYYY-MM-DD')
}
    admin_goal_risk_level_post(input).then(res => {
    try{
    if (res.status==='successfull') {
        this.setState({
            success:true,
            loading:false,
            successmsg:res.message
        })
    }
    else{
        this.setState({
            error:true,
            loading:false,
            errormsg:res.message
        })
    }
    }catch{
        this.setState({
            error:true,
            loading:false,
            errormsg:'Something went wrong'
        })
    }
    })
}
cancel=()=>{
    this.setState({
        success:false,
        error:false
    })
    }
componentDidUpdate(prevProps, prevState) {
    if (prevProps.today !== this.props.today) {
        const input = {
            access_token:localStorage.token,
            customer_id:this.props.customer_id,
            date:Moment(this.props.today).format('YYYY-MM-DD')
        }
        admin_goal_risk_level(input).then(res => {
            console.log(res)
        try{
        if (res.status==='successfull') {
        this.setState(() => ({
            goal_dict:res.result,
        }))
        }
        else{}
        }catch{}
        })
    }
    }
    render() {
        if(this.state.goal_dict.goal_risk_score==='1'){
            this.state.goal_dict.goal_risk_score_discription = 'Very Bad'
        }
        if(this.state.goal_dict.goal_risk_score==='2'){
            this.state.goal_dict.goal_risk_score_discription = 'Bad'
        }
        if(this.state.goal_dict.goal_risk_score==='3'){
            this.state.goal_dict.goal_risk_score_discription = 'OK'
        }
        if(this.state.goal_dict.goal_risk_score==='4'){
            this.state.goal_dict.goal_risk_score_discription = 'Good'
        }
        if(this.state.goal_dict.goal_risk_score==='5'){
            this.state.goal_dict.goal_risk_score_discription = 'Very Good'
        }
        return (
            <div>
            {this.state.loading?(
                <div className='loader-main-div'>
                    <div className='loader-div'>
                        <Loader/>
                    </div>
                </div>
            ):(null)}

            {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancel}>
                {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

            {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
                {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

               <div className='update-table-div'>
                    <table class='table table-condensed'>
                        <thead className='mf-p-table-tr'>
                            <th>Goal Risk Score</th>
                            <th>Goal Risk Discription</th>
                            <th>date</th>
                        </thead>
                        {this.state.goal_dict?(
                        <tbody>
                            <tr className='mf-p-table-tr-bg'>
                                <td><select  value={this.state.goal_dict.goal_risk_score}  onChange={this.onchange_score}>
                                    <option>Select</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                                </td>

                                <td><input value={this.state.goal_dict.goal_risk_score_discription} onChange={this.onchange_score_dec} disabled/></td>

                                <td>{Moment(this.props.today).format('YYYY-MM-DD')}</td>
                            </tr>
                        </tbody>):(<span></span>)}
                    </table>
                </div>
                <div className='update-sub-btn'><button onClick={this.submit_goal_complete}><MDBIcon icon="save" className='btn-i'/>Save</button></div>
            </div>
        )
    }
}

import React,{useState,useEffect} from 'react'
import './Universal.scss'

import { disclosure } from '../Api'
import Navbar from './Navbar'
import Footer from './Footer'
import SebiPdf from '../../../Images/sebi_disclosures.pdf'

const Disclosures = (props) => {
    const [disclosureData,setDisclosureData] = useState({})
    useEffect(() => {
        disclosure().then(res => {
            console.log(res)
            try{
                if (res.status==='successfull') {
                    setDisclosureData(res.result) 
                }
                else{}
            }catch{}
        })
    },[])
  let PendingTotal = 0
  let ReceiveTotal = 0
  let ResolveTotal = 0
  let TotalPendingTotal = 0
  let LastThreePendingTotal = 0
  let AvgReolutionTimeTotal = 0
  let YearlyPendingTotal = 0

    return (
        <>
            <div><Navbar/></div>
            <div className='bodyDiv'>
                <section>
                    <div className='container'>
                        <div className='disclosureHeading'>SEBI DISCLOSURES</div>
                        <p className='disclosurePara'>This page contains all the regulatory disclosures required to be displayed on the website. To learn more about this, you can view the SEBI Circular <a href='https://www.sebi.gov.in/legal/circulars/dec-2021/publishing-of-investor-charter-and-disclosure-of-investor-complaints-by-investment-advisers-on-their-websites-mobile-applications_54585.html' target="_blank">here</a></p>
                        <hr/>
                        <p className='disclosureHeading'>SEBI Registration Details</p>
                        <p className='disclosurePara'>Adviser Name: Harish Chandran Menon | RIA No: INA000012838 | Reg. Type: Individual | Validity: Perpetual | Reg. address: 803, Dahlia, Vasant Valley, Kalyan West, Thane 421301 | Phone: 9819130637</p>
                        <p className='disclosurePara'>Associated SEBI regional office:  Plot No. C 4-A , G Block, SEBI Bhavan, Bandra Kurla Complex, Bandra East,. Mumbai – 400051</p>
                        <hr/>
                        <p className='disclosureHeading'>Regulatory Complaints Disclosure</p>
                        <p className='disclosurePara'>SEBI mandates all RIAs to display the complaints status on the website as shown below. Investors can first contact the intermediary in case of complaints, in our case through the <a href='/contact_us'>contact us</a> page. The SEBI ‘SCORES’ website that facilitates investors in logging any complaints against listed companies or intermediaries can be accessed <a href='https://scores.gov.in/scores/Welcome.html' target="_blank">here</a>.</p>
                        <p className='disclosurePara'>Data as of last month ending :
                        {disclosureData.last_month_data?<> {disclosureData.last_month_data[0].date}
                        </>:null}
                        </p>
                        <div className='dsTableView'>
                            <table className='disclosureTable'>
                                <tr>
                                    <td className='dsTableColoumn'>Received from</td>
                                    <td className='dsTableColoumn'>Pending as of last month</td>
                                    <td className='dsTableColoumn'>Received</td>
                                    <td className='dsTableColoumn'>Resolved*</td>
                                    <td className='dsTableColoumn'>Total pending#</td>
                                    <td className='dsTableColoumn'>Pending &#x3e; 3 months</td>
                                    <td className='dsTableColoumn'>Avg. resolution time^</td>
                                </tr>
                                

                                {disclosureData.last_month_data?
                                <>
                                    {Object.keys(disclosureData.last_month_data).map((val)=>{
                                        PendingTotal = PendingTotal+disclosureData.last_month_data[val].Pending_as_of_last_month
                                        ReceiveTotal = ReceiveTotal+disclosureData.last_month_data[val].Received
                                        ResolveTotal = ResolveTotal+disclosureData.last_month_data[val].Resolved
                                        TotalPendingTotal = TotalPendingTotal+disclosureData.last_month_data[val].Total_pending
                                        LastThreePendingTotal = LastThreePendingTotal+disclosureData.last_month_data[val].Pending_last_three_months
                                        AvgReolutionTimeTotal = AvgReolutionTimeTotal+disclosureData.last_month_data[val].Avg_resolution_time
                                    return(
                                        <tr>
                                            <td className='dsTableColoumn'>{disclosureData.last_month_data[val].Received_from}</td>
                                            <td className='dsTableColoumn'>{disclosureData.last_month_data[val].Pending_as_of_last_month}</td>
                                            <td className='dsTableColoumn'>{disclosureData.last_month_data[val].Received}</td>
                                            <td className='dsTableColoumn'>{disclosureData.last_month_data[val].Resolved}</td>
                                            <td className='dsTableColoumn'>{disclosureData.last_month_data[val].Total_pending}</td>
                                            <td className='dsTableColoumn'>{disclosureData.last_month_data[val].Pending_last_three_months}</td>
                                            <td className='dsTableColoumn'>{disclosureData.last_month_data[val].Avg_resolution_time}</td>
                                        </tr>
                                    )
                                    })}
                                </>:null}
                            
                                <tr>
                                    <td className='dsTableColoumn'>Total</td>
                                    <td className='dsTableColoumn'>{PendingTotal}</td>
                                    <td className='dsTableColoumn'>{ReceiveTotal}</td>
                                    <td className='dsTableColoumn'>{ResolveTotal}</td>
                                    <td className='dsTableColoumn'>{TotalPendingTotal}</td>
                                    <td className='dsTableColoumn'>{LastThreePendingTotal}</td>
                                    <td className='dsTableColoumn'>{AvgReolutionTimeTotal}</td>
                                </tr>
                            </table>
                        </div>
                        <hr/>
                        <p className='disclosurePara'>Trend of monthly disposal of complaints</p>
                        <div className='dsTableView'>
                            <table className='disclosureTable'> 
                                <tr>
                                    {/* <td className='dsTableColoumn'>S.No.</td> */}
                                    <td className='dsTableColoumn'>Month</td>
                                    <td className='dsTableColoumn'>Carried fwd. from prev. month</td>
                                    <td className='dsTableColoumn'>Received</td>
                                    <td className='dsTableColoumn'>Resolved*</td>
                                    <td className='dsTableColoumn'>pending#</td>
                                </tr>

                                {disclosureData.monthly_data?
                                <>
                                    {Object.keys(disclosureData.monthly_data).map((val)=>{
                                        // pendingTotal = pendingTotal+disclosureData.monthly_data[val].Pending_as_of_last_month
                                    return(
                                        <tr>
                                        <td className='dsTableColoumn'>{val}</td>
                                        {Object.keys(disclosureData.monthly_data[val]).length>0?
                                            <>
                                            <td className='dsTableColoumn'>{disclosureData.monthly_data[val].Pending_as_of_last_month}</td>
                                            <td className='dsTableColoumn'>{disclosureData.monthly_data[val].Received}</td>
                                            <td className='dsTableColoumn'>{disclosureData.monthly_data[val].Resolved}</td>
                                            <td className='dsTableColoumn'>{disclosureData.monthly_data[val].Total_pending}</td>
                                            </>
                                            :
                                            <>
                                                <td className='dsTableColoumn'>-</td>
                                                <td className='dsTableColoumn'>-</td>
                                                <td className='dsTableColoumn'>-</td>
                                                <td className='dsTableColoumn'>-</td>
                                            </>}
                                        </tr>
                                    )
                                    })}
                                </>:null}
                                <tr>
                                <td className='dsTableColoumn'>Total</td>
                                <td className='dsTableColoumn'>{PendingTotal}</td>
                                <td className='dsTableColoumn'>{ReceiveTotal}</td>
                                <td className='dsTableColoumn'>{ResolveTotal}</td>
                                <td className='dsTableColoumn'>{YearlyPendingTotal}</td>
                            </tr>
                            </table>
                        </div>
                        <hr/>
                        <p className='disclosurePara'>Trend of annual disposal of complaints</p>
                        <div className='dsTableView'>
                            <table className='disclosureTable'>
                                <tr>
                                    {/* <td className='dsTableColoumn'>S.No.</td> */}
                                    <td className='dsTableColoumn'>Year</td>
                                    <td className='dsTableColoumn'>Carried fwd. from prev. year</td>
                                    <td className='dsTableColoumn'>Received</td>
                                    <td className='dsTableColoumn'>Resolved*</td>
                                    <td className='dsTableColoumn'>pending#</td>
                                </tr>

                                {disclosureData.yearly_data?
                                <>
                                    {Object.keys(disclosureData.yearly_data).map((val)=>{
                                        PendingTotal = PendingTotal+disclosureData.yearly_data[val].Carried_fwd
                                        ReceiveTotal = ReceiveTotal+disclosureData.yearly_data[val].Received
                                        ResolveTotal = ResolveTotal+disclosureData.yearly_data[val].Resolved
                                        YearlyPendingTotal = YearlyPendingTotal+disclosureData.yearly_data[val].Pending
                                    return(
                                        <tr>
                                            <td className='dsTableColoumn'>{disclosureData.yearly_data[val].year}</td>
                                            <td className='dsTableColoumn'>{disclosureData.yearly_data[val].Carried_fwd}</td>
                                            <td className='dsTableColoumn'>{disclosureData.yearly_data[val].Received}</td>
                                            <td className='dsTableColoumn'>{disclosureData.yearly_data[val].Resolved}</td>
                                            <td className='dsTableColoumn'>{disclosureData.yearly_data[val].Pending}</td>
                                        </tr>
                                    )
                                    })}
                                </>:null}

                                <tr>
                                <td className='dsTableColoumn'>Total</td>
                                <td className='dsTableColoumn'>{PendingTotal}</td>
                                <td className='dsTableColoumn'>{ReceiveTotal}</td>
                                <td className='dsTableColoumn'>{ResolveTotal}</td>
                                <td className='dsTableColoumn'>{YearlyPendingTotal}</td>
                            </tr>
                            </table>
                            <hr/>
                        </div>
                        <p  className='disclosurePara'>*Inclusive of complaints of previous months/years resolved in the current month/year | ^Time in days | # Inclusive of complaints pending as on the last day of the month/year</p>

                        <p className='disclosurePara'>Disclosure with respect to compliance</p>
                        <p className='disclosurePara'>"Disclosure with respect to compliance with Annual
                        compliance audit requirement under Regulation 19(3) of SECURITIES AND EXCHANGE BOARD OF INDIA (INVESTMENT ADVISERS) REGULATIONS, 2013 for last and current financial year are as under.</p>
                        <div className='dsTableView'>
                            <table className='disclosureTable'>
                                <tr>
                                    <td className='dsTableColoumn'>SR. No</td>
                                    <td className='dsTableColoumn'>Financial Year</td>
                                    <td className='dsTableColoumn'>Compliance Audit Status</td>
                                    <td className='dsTableColoumn'>Remarks, If any</td>
                                </tr>

                                <tr>
                                    <td className='dsTableColoumn'>1</td>
                                    <td className='dsTableColoumn'>FY 2020-21</td>
                                    <td className='dsTableColoumn'>Conducted</td>
                                    <td className='dsTableColoumn'>No adverse remarks from auditor</td>
                                </tr>
                                <tr>
                                    <td className='dsTableColoumn'>2</td>
                                    <td className='dsTableColoumn'>FY 2021-22</td>
                                    <td className='dsTableColoumn'>Conducted</td>
                                    <td className='dsTableColoumn'>No adverse remarks from auditor</td>
                                </tr>
                                <tr>
                                    <td className='dsTableColoumn'>3</td>
                                    <td className='dsTableColoumn'>FY 2022-23</td>
                                    <td className='dsTableColoumn'>Conducted</td>
                                    <td className='dsTableColoumn'>No adverse remarks from auditor</td>
                                </tr>
                            </table>
                        </div>
                        <hr/>
                        <br/>
                        <p className='disclosureHeading'>SEBI Investor Charter</p>
                        <p className='disclosurePara'>To facilitate investor awareness, SEBI has created an ‘Investor Charter’ pertaining to Investment Advisers, displayed below for your reference.</p>
                        <div className='pdfBox'>
                            {/* <embed src={SebiPdf} type="application/pdf" width="100%" height="600px"></embed> */}
                            <object data={SebiPdf} type="application/pdf" width="100%" height="600px"><a href={SebiPdf}>click here</a></object>
                        </div>
                    </div>
                </section>
            </div>
            <div><Footer /></div>
        </>
    )
}

export default Disclosures

import React,{useEffect,useState} from 'react'
import {getComments} from './Api'
import {Table,Input,Space,Button,Tag} from 'antd';
import Highlighter from 'react-highlight-words';
import {MDBIcon} from 'mdbreact'
import './Comment.scss'
import AddComment from './AddComment';
import ReplyModal from './ReplyModal'

const Comment = (props) => {
  const [data, setData] = useState([]);
  const [searchText,setSearchText] = useState('')
  const [searchedColumn,setSearchedColumn] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReplyModalVisible, setIsReplyModalVisible] = useState(false);
  const [commentId, setCommentId] = useState(0);
  const [customerId,setCustomerId] = useState(0);
  const [status,setStatus] = useState(0);

  let searchInput = ''

  const fetchComment = async ()=>{
    let newdata = []
    const input = {access_token:localStorage.token,customer_id:props.customer_id,id:props.id}
    const res = await getComments(input)
    console.log(input,res);
    try{
        if (res.status==='successfull') {
          Object.keys(res.result).map((val)=>{
            newdata.push({ 
            s_no: res.result[val].s_no,
            key: res.result[val].comment_id,
            comment_id:res.result[val].comment_id,
            comment: res.result[val].comment.substring(0,50)+'...',
            date: res.result[val].date,
            status: res.result[val].status
            })
        })
        setData(newdata)
        }
        else{
         
        }
    }catch{
      // toast.error('Something went wrong')
    }
  }
  useEffect (()=>{
    fetchComment()
  },[])
  let getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<MDBIcon className='tableSearchI' icon="search"/>}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => {
            handleReset(clearFilters)
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
            }} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <MDBIcon className='tableSearchI' icon="search" style={{ color: filtered ? '#e87c1b' : '#000' }}/>,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };
  const  handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };
  // const handleClickRow=(record, rowIndex)=>{
  //   console.log(record, rowIndex);
  // }
  const handleModal = () => {setIsModalVisible(!isModalVisible);};
  const handleReplyModal = (record) => {
    setIsReplyModalVisible(!isReplyModalVisible)
    setCommentId(record.comment_id)
    setCustomerId(record.customer_id)
    setStatus(record.status)
  };

  const columns = [
    {title: 'SR. NO',dataIndex: 's_no',width: 60, ...getColumnSearchProps('s_no')},
    {title: 'COMMENT ID',dataIndex: 'comment_id',width: 60, ...getColumnSearchProps('comment_id')},
    {title: 'DATE',dataIndex: 'date',width: 100,...getColumnSearchProps('date')},
    {title: 'COMMENT',dataIndex: 'comment',width: 200,...getColumnSearchProps('comment')},
    {title: 'STATUS',dataIndex: 'status',width: 80,...getColumnSearchProps('status'),
    render: status => (
      <Tag color={status==='OPEN'?'green':'red'} key={status}>
          {status}
      </Tag>
    ),},
    {title: 'Action',key: 'action',width: 60,
        render: (record) => (<MDBIcon icon="eye" onClick={()=>handleReplyModal(record)} className='chartI' />),
      },
  ];
  console.log(data);
  return (
    <div className='tableContainer'>
      <div className='tableTabContainer'>
        <div className='commentIconDiv'>
          <MDBIcon className='PagesCommentIcon'icon="comment-dots" onClick={handleModal}/>
        </div>
        <div className='tableHead'>Comments Index</div>
      </div>

      <Table rowClassName="antTable" columns={columns} dataSource={data} size='small'
        pagination={{pageSize: 8,position:['bottomCenter']}} sticky={true} 
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: event => {handleClickRow(record,rowIndex)}
        //   };
        // }}
        />
        {isModalVisible? <AddComment handleModal={handleModal}/>:null}
        {isReplyModalVisible && commentId?<ReplyModal commentId={commentId} customerId={customerId} handleReplyModal={handleReplyModal} status={status}/>:null}
    </div>
  )
}

export default Comment
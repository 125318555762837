import React,{useState,useEffect} from 'react'
import './Universal.scss'
import Navbar from './Navbar'
import Footer from './Footer'
import FooterContact from './FooterContact'
 
const PrivacyPolicy = (props) => {

    return (
        <div>
            <div><Navbar /></div>
            <section>
                <div className='Privacybg'>
                    {/* <div className='privacyMenuComponent'><Menu /></div> */}
                    <div className='container'>
                        <div className='privacyBannerHead'>Our privacy policy</div>
                        <div className='policySection'>
                            <p className='privacyPara'>HOA Investments Private Limited will use your personal information to manage your account(s) whilst providing our services and to develop and improve upon our services to you. We will not disclose your personal information to any external person or entity other than to:</p>

                            <p className='privacyPara'>- As required by law or regulation.</p>
                            <p className='privacyPara'>- Persons acting as our service providers or agents under a strict code of confidentiality</p>
                            <p className='privacyPara'>- Anyone to whom we transfer or may transfer our rights and duties under your Customer Agreement</p>
                            <p className='privacyPara'>- In the event your information is shared with a service provider or agent in another country, we will make sure that the service provider or agent agrees to apply the same levels of protection as we are required to apply to information held in India, and to use your information only for the purpose of providing the agreed service.</p>
                            <p className='privacyPara'>Security safeguards are in place to protect your personal information against loss or theft, as well as unauthorized access, disclosure, copying, use, or modification regardless of the format in which it is held.</p>
                      </div>
                    </div>
                </div>
            </section>
            
            

            <div><FooterContact /></div>
            <div><Footer /></div>
        </div>
    )
}

export default PrivacyPolicy

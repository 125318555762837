import axios from 'axios'

export const customer_mf_details = input => {
    return axios
        .get('/alpha_admin/customer_mf_details',
        {
            headers: {'Authorization': input.access_token},
            params: {
              id:input.id,
              type: input.type,
              start_date:input.start_date,
              end_date:input.end_date
            }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const year_wise_summary = input => {
    return axios
        .get('/alpha_user_db/year_wise_summary',
        {
            headers: {'Authorization': input.access_token},
            params: {
              id:input.id,
              start_date:input.start_date,
              end_date:input.end_date
            }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const customer_margin_details = input => {
    return axios
        .get('/alpha_admin/customer_margin_details',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
                id:input.id,
                type: input.type,
                start_date:input.start_date,
                end_date:input.end_date
              }

          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const holding_report = input => {
    return axios
        .get('/alpha_user_db/holding_report',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
                id:input.id,
                date:input.date,
                flag:input.flag,
                clientwise:input.clientwise
              }

          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  // export const holding_report_put = input => {
  //   return axios
  //       .put('/alpha_user_db/holding_report', {
  //           access_token:input.access_token,
  //           id:input.id,
  //           tradingsymbol:input.tradingsymbol,
  //           family_member_id:input.family_member_id
  //       },
  //       {
  //           headers: {
  //             'Authorization': input.access_token
  //           }
  //         })
  //       .then(response => {
  //           return response.data
  //       })
  //       .catch(err => {})
  // }
  // export const holding_report_put = input => {
  //   return axios
  //       .put('/alpha_user_db/holding_report',
  //       {
  //           headers: {
  //             'Authorization': input.access_token
  //           },
  //           params: {
  //           // access_token:input.access_token,
  //           id:input.id,
  //           tradingsymbol:input.tradingsymbol,
  //           family_member_id:input.family_member_id
  //             }

  //         })
  //       .then(response => {
  //           return response.data
  //       })
  //       .catch(err => {})
  // }
  // export const holding_report_put = input => {
  //   return axios
  //       .put('/alpha_user_db/holding_report',
  //          {
  //           // access_token:input.access_token,
  //           id:input.id,
  //           // tradingsymbol:input.tradingsymbol,
  //           // family_member_id:input.family_member_id
  //       },
  //       {
  //           headers: {
  //             'Authorization': input.access_token
  //           }
  //         })
  //       .then(response => {
  //           return response.data
  //       })
        
  //       .catch(err => {})
  // }
  export const holding_report_put = input => {
    return axios
        .put('/alpha_user_db/holding_report',
           {
            id: input.id,
          tradingsymbol:input.tradingsymbol,
          family_member_id:input.family_member_id,
          flag:input.flag

        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            console.log('Response', input.family_member_id); // Print the response data
            return response.data;
        })
        .catch(err => {
            console.error('Error:', err); // Print the error if there's any
        });
}
import React,{useState} from 'react'
import {signup, send_verification_code,email_verification} from '../Api';
import {useNavigate} from 'react-router-dom'
import { Col,Row,Image, Button, Form, Input} from 'antd';
import { MDBIcon } from 'mdbreact';
import OtpInput from 'react-otp-input';
import LoginImage from '../../../Images/loginImage.jpg'
import Logo from '../../../Images/logoBlack.png'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signup = () => {
  const [takeEmail,setTakeEmail] = useState(true)
  const [takeOpt,setTakeOtp] = useState(false)
  const [takePass,setTakePass] = useState(false)
  const [otp,setOtp] = useState(false)
  const [emailAddress,setEmailAddress]= useState('')

  const navigate = useNavigate();

  const toastMsg =(id,msg,type)=>{
    toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
  } 

  const emailVerification=(values)=>{
    const id = toast.loading("Please wait...") 
    setEmailAddress(values.emailAddress)
    const input={email:values.emailAddress}
    console.log(input);
    send_verification_code(input).then(res => {
      console.log(res);
      try{
        if (res.status==='successfull') {
          toastMsg(id,res.message,'success')
          setTakeOtp(true)
          setTakeEmail(false)
        }
        else{
          toastMsg(id,res.message,'error')
        }
        }catch{
          toastMsg(id,'Something went wrong','error')
        }
      })
  }
  const handleChange = (otp) =>{setOtp(otp)}
  const submitOtp=()=>{
    const id = toast.loading("Please wait...") 
      const input={
          email:emailAddress,
          code:otp
      }
      email_verification(input).then(res => {
          try{
          if (res.status==='successfull') {
            setTakePass(true)
            setTakeOtp(false)
            toastMsg(id,res.message,'success')
          }
          else{
            toastMsg(id,res.message,'error')
          }
          }catch{
            toastMsg(id,'Something went wrong','error')
          }
          })
  }
  const resend=()=>{
    const id = toast.loading("Please wait...") 
    const input={email:emailAddress}
    send_verification_code(input).then(res => {
      try{
      if (res.status==='successfull') {
        toastMsg(id,res.message,'success')
      }
      else{
        toastMsg(id,res.message,'error')
      }
      }catch{
        toastMsg(id,'Something went wrong','error')
      }
      })
  }
  const onSubmitSignup = async (values)=>{
    const id = toast.loading("Please wait...") 
    const input = {
      email_address : emailAddress,
      password : values.password
    }
  signup(input).then(res => {
    try{
      if (res.status==='successfull') {
        localStorage.setItem('token',res.result.access_token)
        localStorage.setItem('email',emailAddress)
        navigate('/setup_journey');
      }
      else{
        toastMsg(id,res.message,'error')
      }
    }catch{
      toastMsg(id,'Something went wrong','error')
    }
  })
  }
  const EmailForm = ()=>{
    return(
      <Form
      name="normal_login"
      className="login-form"
      onFinish={emailVerification}
    >
    <Form.Item
      name="emailAddress"
      rules={[
        {required: true, message: 'Please input your Email Address!'},
        {message: 'Invalid Email',
        validator: (_, value) => {
          if (/^(([^<>()[\].,;:#*\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(value)) {
            return Promise.resolve();
          } else {
            return Promise.reject('Some message here');
          }
          }
        }]}
        hasFeedback>
      <Input 
      pattern="\S(.*\S)?" title="Remove spaces"
      onInput={e => e.target.value = e.target.value.toLowerCase()}
      prefix={<MDBIcon icon="at" className='loginFormIcon' />}
        placeholder="Email" className='LoginFormInput' />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" className="loginFormButton">
        Submit
      </Button>
    </Form.Item>
  </Form>
    )
  }

  const PasswordForm=()=>{
    return(
        <Form
          name="normal_login"
          className="login-form"
          onFinish={onSubmitSignup}>
          <Form.Item
            name="password"
            rules={[
              {required: true, message: 'Please input your Password!' },
              {message: 'Password must contain at least 1 lowercase 1 uppercase 1 numeric character 1 special character and minimum 8 characters long',
              validator: (_, value) => {
                if (RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})").test(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Some message here');
                }
                }
              }
            ]}
            hasFeedback
            >
            <Input.Password
            prefix={<MDBIcon icon="lock" className='loginFormIcon'/>}
            placeholder="Password"
            className='LoginFormInput'/>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="loginFormButton">
              Submit
            </Button>
          </Form.Item>
        </Form>
    )
  }
  
  return (
      <div className='fixPageHeight'>
        <ToastContainer theme='colored'/>
        <div className='desktop'>
          <Row>
            <Col span={13}>
              <div className='loginFormDiv'>
              <MDBIcon icon="home" onClick={()=>navigate('/home')} className='loginHomeIcon' />
                <Image src={Logo} rootClassName='loginLogoImage' preview={false}/>
                <div className='LoginHeading'>Sign In To Wealth 360 Customer Portal</div>
                {takeEmail?<EmailForm/>:null}
                {takeOpt?<>
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    separator={<span>-</span>}
                    inputStyle='inputStyle'
                    shouldAutoFocus={true}
                    isInputNum={true}
                    />   
                  <p className='resendOtp'>Didn't receive otp<b onClick={resend}>Resend Otp</b></p> 
                  <Button className="loginFormButton" onClick={submitOtp}>
                    Submit
                  </Button>
                  </>:null}
                {takePass?<PasswordForm/>:null}
                <p className='LoginOr'>Or</p>
                <p className='LoginOr'>Already have an account?<a href='/login'>Login</a></p>
              </div>
            </Col>
            <Col span={11}>
              <Image src={LoginImage} rootClassName='loginImage' preview={false}/>
            </Col>
          </Row>
        </div>
        <div className='mobile'>
          <div className='mobileLoginBg'>
          <MDBIcon icon="home" onClick={()=>navigate('/home')} className='loginHomeIcon' />
            <Image src={Logo} rootClassName='loginLogoImage' preview={false}/>
              <div className='mobileLoginBox'>
                <div className='LoginHeading'>Sign In To Wealth 360 Customer Portal</div>
                {takeEmail?<EmailForm/>:null}
                {takeOpt?<>
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    separator={<span>-</span>}
                    inputStyle='inputStyle'
                    shouldAutoFocus={true}
                    isInputNum={true}
                    />   
                  <p className='resendOtp'>Didn't receive otp<b onClick={resend}>Resend Otp</b></p> 
                  <Button className="loginFormButton" onClick={submitOtp}>
                    Submit
                  </Button>
                  </>:null}
                {takePass?<PasswordForm/>:null}
                <p className='LoginOr'>Or</p>
                <p className='LoginOr'>Already have an account?<a href='/login'>Login</a></p>
              </div>
          </div>
        </div>
      </div>
        )
      }
export default Signup
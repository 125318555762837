import React,{useState,useEffect} from 'react'
import {class_and_scheme_assets} from './Api'
import { Select,DatePicker} from 'antd';
import {Table} from 'ant-table-extensions'
import Loader from '../../../shared/Loader';
import { toast,ToastContainer } from 'react-toastify';

const Allclientwise = () => {
    const [data, setData] = useState([]);
    const [adata,setaData] = useState([])
    const [loading,setLoading] = useState(false)
    const [selectedClient,setSelectedClient] = useState({})
    const [cDate, setDate] = useState(new Date().toISOString().split('T')[0]);
    

    const toastMsg =(id,msg,type)=>{
      toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    }

    let pdf1=null

    const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        // style: 'currency',
        // currency: 'INR',
        minimumFractionDigits:0
    }).format(value);

    console.log(cDate);

    const submitbutton = (date)=>{
        setLoading(true)
        let newdata = [...adata]
        const input = {
          access_token:localStorage.token,
          type:'values_by_client_name',
          date:date,
          download:'N'
      }
      console.log(input);
      class_and_scheme_assets(input).then(res => {
        console.log('year',res);
        try{
          if (res.status==='successful'){
        setLoading(false)
        Object.keys(res.result).map((val)=>{
            newdata.push({  
              key:val,
              name: val,
              total_invested_value:numberFormat(res.result[val].total_invested_value),
              total_current_value:numberFormat(res.result[val].total_current_value),
              description: <ExpandedRowRender asset_list= {res.result[val]}/>
              })
           
        })
        setaData(newdata)
      }
      else{toastMsg(res.message,'error')}
        }catch{toastMsg('Something went wrong','error')}
      }) 
      }
      
    const ExpandedRowRender = (props) => {
      const columns2 = [
        { title: 'Asset Class', dataIndex: 'asset_class',},
        { title: 'Asset Name', dataIndex: 'asset_name', },
        { title: 'Invested Value', dataIndex: 'invested_value',align:'right',width:'20%' },
        { title: 'Current Value', dataIndex: 'current_value',align:'right',width:'20%' },
      ];
      
      const data2 = [];
      if(props.asset_list){
        Object.keys(props.asset_list).map((val)=>{
          if(val!=='total_invested_value' && val!=='total_current_value'){
          data2.push({
            key: val,
            asset_class: props.asset_list[val].asset_class,
            asset_name:props.asset_list[val].asset_name,
            invested_value: numberFormat(props.asset_list[val].invested_value),
            current_value: numberFormat(props.asset_list[val].current_value),
          })
        } 
        })
      }
      
      return <Table
      columns={columns2} dataSource={data2}
      pagination={false}   size='small'  
      rowClassName="editable-row"
      bordered/>;
    };

    const exportData=()=>{
      const input = {
          access_token:localStorage.token,
          type:'values_by_client_name',
          download:'Y'
      }
      console.log(input);
      class_and_scheme_assets(input).then(res => {
        console.log('ho_expo',res);
        try{
          if (res.status==='successfull'){
            pdf1=res.result
            const linkSource = `data:application/vnd.ms-excel;base64,${pdf1}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = `all_clients_data_${cDate}.xlsx`;
            downloadLink.click();
          }else{}
        }catch{}
        })
    }

    
      useEffect(()=>{
        submitbutton(cDate)
      },[])

    const column = [   
        {title: 'Asset Class',dataIndex: 'name'},
        {title: 'Total Invested Value',dataIndex: 'total_invested_value',align:'right',width:'20%'},
        {title: 'Total Current Value',dataIndex: 'total_current_value',align:'right',width:'20%'},
      ]
  return (
    <div>
    {loading?<Loader/>:null}
        <div ><button className='csvDwn2' onClick={exportData}>Export to CSV</button></div>
        <Table
          expandable={{ expandRowByClick:true,
          expandedRowRender: record =><>{record.description}</> }}
          dataSource={adata}
          columns={column}
          rowClassName="editable-row"
          pagination={{pageSize: 9,}}
          size='small'
          scroll={{y:'55vh' }}
          // scroll={{x: '100%', y: '55vh' }}
          sticky={true}
        />
    </div>
  )
}

export default Allclientwise
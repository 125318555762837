import React from 'react'
import ColumnChart from './ColumnChart'

const CashFlowProjection = (props) => {
    const color1 = ['#37bdff', '#fb963b']
    const color2 = ['#37bdff', '#fb963b']
  return (
    <>
        <div className='cashflowCard'>
            <div className='cashflowGraphHead'>Cashflow Projection Without Inflation</div>
            <div className='chartArea'>
              {Object.keys(props.cashFlowData.without_inflation).length>0?
              <ColumnChart color={color1} cashFlowData={props.cashFlowData.without_inflation}/>:null}
            </div>
        </div>
       
        <div className='cashflowCard'>
            <div className='cashflowGraphHead'>Cashflow Projection With Inflation</div>
            <div className='chartArea'>
            {Object.keys(props.cashFlowData.with_inflation).length>0?
              <ColumnChart color={color2} cashFlowData={props.cashFlowData.with_inflation}/>:null}
            </div>
        </div>
    </>
  )
}

export default React.memo(CashFlowProjection)
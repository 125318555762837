// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Images/faqbanner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Playfair+Display);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Red+Hat+Display);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faqBannerImage {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 45vh;
  width: fixed;
  background-size: contain;
  background-position: 0% 10%;
  background-repeat: no-repeat;
  overflow: hidden;
  background-attachment: fixed;
}

.mapBg {
  background-color: #FFF2EC;
  padding: 40px 150px 40px 150px;
}

.questionBox {
  width: 100%;
  height: auto;
  border: 1px solid #DC571F;
  border-radius: 4px;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
}

.questionarrow {
  font-size: 20px;
  font-weight: 600;
  color: #DC571F;
  cursor: pointer;
}

.questionText {
  margin-left: 10px;
  font-size: 16px;
  color: #5f2510;
  font-weight: 400;
  font-family: "Playfair Display";
  cursor: pointer;
}

.answerText {
  font-size: 14px;
  color: #5f2510;
  font-weight: 400;
  margin-top: 10px;
  margin-left: 25px;
  font-family: "Red Hat Display";
}`, "",{"version":3,"sources":["webpack://./src/Component/Website/faq/Faq.scss"],"names":[],"mappings":"AAIA;EACI,yDAAA;EACA,YAAA;EACA,YAAA;EACA,wBAAA;EACA,2BAAA;EACA,4BAAA;EACA,gBAAA;EACA,4BAAA;AADJ;;AAGA;EACI,yBAAA;EACA,8BAAA;AAAJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AACA;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AAEJ;;AAAA;EACI,iBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,+BAAA;EACA,eAAA;AAGJ;;AADA;EACI,eAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;AAIJ","sourcesContent":["\n@use '../../../Variable.scss';\n@import url('https://fonts.googleapis.com/css?family=Playfair+Display');\n@import url('https://fonts.googleapis.com/css?family=Red+Hat+Display');\n.faqBannerImage{\n    background-image: url(\"../../../Images/faqbanner.jpg\");\n    height: 45vh;\n    width: fixed;\n    background-size: contain;\n    background-position: 0% 10%;\n    background-repeat: no-repeat;\n    overflow: hidden;\n    background-attachment: fixed;\n}\n.mapBg{\n    background-color: #FFF2EC;\n    padding:40px 150px 40px 150px;\n}\n.questionBox{\n    width: 100%;\n    height: auto;\n    border: 1px solid #DC571F;\n    border-radius: 4px;\n    background-color: #fff;\n    padding: 15px;\n    margin-bottom: 20px;\n}\n.questionarrow{\n    font-size: 20px;\n    font-weight: 600;\n    color: #DC571F;\n    cursor: pointer;\n}\n.questionText{\n    margin-left: 10px;\n    font-size: 16px;\n    color: #5f2510;\n    font-weight: 400;\n    font-family: 'Playfair Display';\n    cursor: pointer;\n}\n.answerText{\n    font-size: 14px;\n    color: #5f2510;\n    font-weight: 400;\n    margin-top: 10px;\n    margin-left: 25px;\n    font-family: 'Red Hat Display';\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react'
import {password_setting} from './Api'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../shared/Loader'
import {MDBIcon} from 'mdbreact'
import './Setting.scss'

export default class ChangePassword extends Component {
    constructor(props){
        super(props);
        this.state={
            new_password:'',
            password:'',
            confirm_password:'',
            error:false,
            success:false,
            errormsg:'',
            loading:false,
            password_validate:true,
            showPassword:false,
            showPassword1:false
        }
    }
onChange=(e)=>{
    this.setState({ [e.target.name]: e.target.value })
    }
onSubmit=(e)=>{
    this.setState({
        loading:true
    })
const input = {
    access_token:localStorage.token,
    newPassword:this.state.new_password,
    password:this.state.password,
}
console.log('input',input)
password_setting(input).then(res => {
    console.log("res",res)
try{
if (res.status==='successfull') {
    this.setState(() => ({
        success:true,
        loading:false,
        errormsg:res.message,
        new_password:'',
        password:'',
        confirm_password:'',
        
    }))
    window.location.reload(false)
}
else{
    this.setState({
        error:true,
        errormsg:res.message,
        loading:false
    })
}
}catch{
    this.setState({
        error:true,
        errormsg:"something went wrong",
        loading:false
    })
}
})
}

cancel=()=>{
    this.setState({
        error:false,
    })
 }
 cancelsuccess=()=>{
    this.setState({
        success:false,
    })
    this.props.afterchangefunction();
 }
 onChangepassword=(e)=>{
    const validpassword=RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let validate= validpassword.test(e.target.value)
    if(validate===true){
      this.setState({
        new_password: e.target.value,
        password_validate:true})
    }
  else{
    this.setState({
      password_validate:false})
  }
  }
  showPassword=()=>{
    this.setState({
      showPassword:!this.state.showPassword
    })
  }
  showPassword1=()=>{
    this.setState({
      showPassword1:!this.state.showPassword1
    })
  }
    render() {
        console.log(this.state.new_password , this.state.confirm_password)
        if (this.state.loading){
            return(
              <div style={{textAlign:'center',marginTop:100}}>
              <Loader/>
              </div>
              );
          }
        return (
            <div>
                 {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

            {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancelsuccess}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}
                    <div className='change-carform-icon-Sd'>
                       <div className='change-head'>Change Password</div>

                       <form onSubmit={this.onSubmit}>
                       <div className='change-form'>
                            <div class="input-field-u">
                                <input  type={this.state.showPassword? 'text':'password'} id="reset_password" name="password" value={this.state.password}  onChange={this.onChange} required />
                                {this.state.showPassword?
                                <MDBIcon far icon="eye" className='eye-icon-active' onClick={this.showPassword}/>:
                                <MDBIcon far icon="eye" className='eye-icon' onClick={this.showPassword}/>}
                                <label for="reset_password"><i class="fa fa-lock form-icon-S"></i>Old Password*</label>
                            </div>

                            <div class="input-field-u">
                                <input  type={this.state.showPassword1? 'text':'password'} id="new_password" name="new_password"  onChange={this.onChangepassword} required />
                                {this.state.showPassword1?
                                <MDBIcon far icon="eye" className='eye-icon-active' onClick={this.showPassword1}/>:
                                <MDBIcon far icon="eye" className='eye-icon' onClick={this.showPassword1}/>}
                                <label for="new_password"><i class="fa fa-lock form-icon-S"></i>New Password*</label>
                                {this.state.password_validate?(<span></span>):(
                                <div className='invalid-password'>Password must contain at least 1 lowercase 1 uppercase 1 numeric character 1 special character and minimum 8 characters long</div>)}
                            </div>

                            <div class="input-field-u">
                                <input  type="password" id="confirm_password" name="confirm_password" value={this.state.confirm_password}  onChange={this.onChange} required  />
                                <label for="confirm_password"><i class="fa fa-lock form-icon-S"></i>Confirm New Password*</label>
                                {this.state.new_password===this.state.confirm_password?(<span></span>):(
                                <div className='invalid-password'>New password and confirmed password are not same</div>)}
                            </div>
                        </div>

                        <div className='submit-Btn' style={{textAlign:'left'}}>
                        {this.state.password_validate && this.state.new_password===this.state.confirm_password?(<button type='submit' >Submit</button>):(<button disabled={true} >Submit</button>)}
                        </div>
                        </form>
                    </div>
                </div>
        )
    }
}

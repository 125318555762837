import React, { useState,useEffect } from 'react'
import './BlogStyle.scss'
import './BlogMediaQuery.scss'
import Navbar from '../Universal/Navbar'
import Footer from '../Universal/Footer'
import FooterContact from '../Universal/FooterContact'
import user from '../../../Images/user_icon.png'
import logosmall from '../../../Images/logoSmall.jfif'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';
import { video_links_get } from '../../Admin/Api'
import {hoa_blogs_get_new} from '../Api'
import { Pagination,Image } from 'antd';
import Loader from '../../../shared/Loader'
import { MDBIcon } from 'mdbreact';

const Blog = (props) => {
    const [bloglist, setBloglist] = useState({})
    const [blogVideo,setBlogVideo] = useState({})
    const [minIndex, setMinIndex] = useState(0)
    const [maxIndex, setMaxIndex] = useState(0)
    const [current,setCurrent] = useState(1)
    const [totalPage,setTotalPage] = useState(0)
    const [minIndexVideo, setMinIndexVideo] = useState(0)
    const [maxIndexVideo, setMaxIndexVideo] = useState(0)
    const [currentVideo,setCurrentVideo] = useState(1)
    const [loading,setLoading] = useState(false)
    const [like,setLike] = useState(false)
    const [dislike,setDislike] = useState(false)
    const pageSize = 3
    const pageVideo = 2

    const getBlogList=()=>{
        setLoading(true)
        hoa_blogs_get_new().then(res => {
            console.log("bloglist",res)
            try{
            if (res.status==='successfull') {
                setBloglist(res.result)
                setTotalPage(Object.keys(bloglist).length/pageSize)
                setMinIndex(0)
                setMaxIndex(pageSize)
                setLoading(false)
            }
            else{ setLoading(false)}
            }catch{ setLoading(false)}
            })
    }
    const getBlogVideo=()=>{
        video_links_get().then(res => {
            console.log("blogVideo",res)
            try{
            if (res.status==='successfull') {setBlogVideo(res.result)
                setTotalPage(Object.keys(blogVideo).length/pageVideo)
                setMinIndexVideo(0)
                setMaxIndexVideo(pageVideo)
                setLoading(false)}
            else{}
            }catch{}
            })
    }

    useEffect(()=>{
        getBlogList();
        getBlogVideo();
    },[])

    const handleChange = (page) => {
        setCurrent(page)
        setMinIndex((page-1)*pageSize)
        setMaxIndex(page * pageSize)
      };

      const handleChangePage = (page) => {
        setCurrentVideo(page)
        setMinIndexVideo((page-1)*pageVideo)
        setMaxIndexVideo(page * pageVideo)
      };

      const handleLike=()=>{
            setLike(true)
            setDislike(false)
      }
      const handleDislike=()=>{
            setDislike(true)
            setLike(false)
    }
    const RecomendationCard = (props)=>{
        const{tImage,image,text2,text3,text4,val} =props
        return(
            <div class="recomendCard" >
                <div className='thumbImage'><Image src={'data:image/png;base64,'+ tImage} alt='' preview={false}/></div>
                <div class="cardSubtitle">{text2}</div>
                <p class="recomendCardPara newLin">
                    <Link to={`/blog/${val}`} className='clickHere' target="_blank">Click here to read full blog</Link>
                </p>
                <span className="blogLogo"><img src={image} alt=''/></span>  
                <span className="cardName">{text3}</span>
                <span className="recomendCardDate">{text4}</span>
            </div>
        )
    }

    return (
        <>
            {loading?<Loader/>:null}
            <div><Navbar /></div>
            <div className='bodyDiv'>
                <div className='blogBannerImage'>
                    <div className='blogBannerText'>HOUSE OF ALPHA BLOGS</div>
                </div>

                <div className='expertSec'>
                    <div className='blogMainHead'>Our Blogs</div>
                        <div className='row'>
                            <div className='col-md-7'>
                                {Object.keys(bloglist).map((index)=>{
                                    if(index==='0'){ 
                                    return(
                                        <>
                                            <div className='blogSectionImage'><img src={'data:image/png;base64,' + bloglist[0].image} alt=''/></div>
                                            <div>
                                            <span className='userIcon'><img src={user} alt=''></img></span>
                                            <span className='blogWriterName'>By: House of Alpha</span>
                                            <span className='blogDate'>{bloglist[0].date}</span>
                                            </div>
                                            <div className='blogHeading'>{bloglist[0].title}</div>
                                            <div className='blogPara'>{parse(bloglist[0].value)}</div>
                                        </>
                                    )
                                }
                            })}
                                <div className='articleDiv'>Did you find this article helpful? 
                                <span className='articleSpan'>
                                    <MDBIcon fas icon="thumbs-up" className={like?'thumbIA':'thumbI'} onClick={handleLike}/>
                                    <MDBIcon fas icon="thumbs-down" className={dislike?'thumbIA':'thumbI'} onClick={handleDislike}/>
                                </span>
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <div className='recomendHead'>Other Recommendations</div>    
                                {Object.keys(bloglist).length>0?
                                    <>          
                                    {Object.keys(bloglist).map((val,index)=>{
                                        if(val>0){
                                            if( index < maxIndex && index >= minIndex){
                                                return(
                                                    <RecomendationCard
                                                    val={bloglist[val].url}
                                                    tImage={bloglist[val].image}
                                                    text2={bloglist[val].title}
                                                    text3='House of Alpha' image={logosmall}
                                                    text4={bloglist[val].date}/>
                                                ) 
                                            }
                                        }
                                    })}
                                        
                                    </>
                                :null}
                                <Pagination 
                                            pageSize={pageSize}
                                            current={current}
                                            total={Object.keys(bloglist).length}
                                            onChange={handleChange}
                                        />
                            </div>
                        </div>
                    </div>
                    <div className='expertSec'>
                        <div className='blogMainHead'>Our Videos</div>
                        <div className='videoDiv'>
                            <div className='row'>
                            {Object.keys(blogVideo).length>0?<>          
                            {Object.keys(blogVideo).map((val,index)=>{
                                if( index < maxIndexVideo && index >= minIndexVideo){
                                return(
                                    <div className='col-md-6'>
                                    <div className='linkedinVideo'>
                                        <iframe src={blogVideo[val].link} 
                                            title="YouTube video player" 
                                            frameborder="0" 
                                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" controls allowfullscreen='true'></iframe>
                                        <div className='videoHead'>{blogVideo[val].title}</div>
                                    </div>
                                    </div>
                                )}
                            })}</>:null}
                               
                            </div>
                        </div>
                        <Pagination 
                            pageSize={pageVideo}
                            current={currentVideo}
                            total={Object.keys(blogVideo).length}
                            onChange={handleChangePage}
                            style={{float:'right'}}
                        />
                    </div>
                <div><FooterContact /></div>
                <div className='footrcontcBG'></div>
                <div><Footer /></div>
            </div>
        </>
    )
}

export default Blog

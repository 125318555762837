import React,{useState,useEffect} from 'react'
import './Cover.scss'
import { Row, Col} from 'antd';
import InsuranceIcon from '../../../Images/insuranceicon.png'
import Tables from './Tables';
import {insurance_information} from './Api'
import Hlv from './hlv/Hlv';

const Cover = (props) => {
    const [tab,setTab] = useState({family:true,hlv:false})
    const [cardData, setCardData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const numberFormat=(value)=>{
        var val = Math.abs(value)
        if (val >= 10000000) {
          val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
          val = (val / 100000).toFixed(2) + ' L';
        }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
        return val;
      }
    
    const fetchInsurance = async () =>{
        const input = {access_token:localStorage.token,id:props.id}
        const res = await insurance_information(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('cover',res);
        try{
          if (res.status==='successfull'){
            setCardData(res.result.cover_details)
            setTableData(res.result.insurance_details)
          }else{}
        }catch{}
      }
      useEffect(()=>{
        fetchInsurance()
      },[])

    const StatsCard = (props)=>{
        const {name,life,health} = props
        return(
        <>
            <div className='statisticCard'>
                <span className='statisticCardName'>{name}</span>
                <Row style={{marginTop:'10px'}}>
                    <Col span={12}>
                        <div className='statisticCardValue'>
                            <small>Life</small>
                            <div>Rs. <b>{numberFormat(life)}</b></div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='statisticCardValueRight'>
                            <small>Health</small>
                            <div>Rs. <b>{numberFormat(health)}</b></div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
        )
    }

    const handleTab=(flag)=>{
      console.log(flag);
      let dict = {...tab}
        Object.keys(dict).map(item => {
          if(item===flag){dict[item] = true
          }else{dict[item] = false}
          });
          setTab(dict)
  }
  return (
    <>
    <div className='tableTabContainer'>
      <div className={tab.family?'tableTabActive':'tableTab'} onClick={()=>handleTab('family')}>Family Cover</div>
      <div className={tab.hlv?'tableTabActive':'tableTab'} onClick={()=>handleTab('hlv')}>Catastrophic Planning</div>
    </div>

    {tab.family?<>
    {Object.keys(tableData).length>0 || Object.keys(cardData).length>0?
      <div className='animate'>      
        <Row gutter={[20, 16]} style={{marginTop:'20px'}}>
          {Object.keys(cardData).map((val)=>{
            return(
                <Col span={6}>
                    <StatsCard name={val} life={cardData[val].LIFE} health={cardData[val].HEALTH}/>
                </Col>
            )
          })}
        </Row>
        <>
          {Object.keys(tableData).length>0?<Tables tableData={tableData}/>:null}
        </>
      </div>:<div className='noDataHead'>No Active Insurance</div>}</>:null}

    {tab.hlv?
    <>
    <div className='animate'>      
        <Row gutter={[20, 16]} style={{marginTop:'20px'}}>
            {Object.keys(cardData).map((val)=>{
                return(
                    <Col span={6}>
                        <StatsCard name={val} life={cardData[val].LIFE} health={cardData[val].HEALTH}/>
                    </Col>
                )
            })}
        </Row>
        <Hlv id={props.id}/>
      
    </div></>:null}

    </>
  )
}

export default React.memo(Cover)
// import * as React from 'react';
// import {BootstrapTable, TableHeaderColumn,InsertButton,DeleteButton,InsertModalHeader } from 'react-bootstrap-table';
// import {get_mf_details,save_mf_details,get_last_index,update_mf_details,delete_mf_details} from '../../Api'

// let index=1
// export default class Mf_details extends React.Component {
// constructor(props){
//     super(props);
//     this.state={
//         resData:{},
//     }
// }
// componentDidMount(){
//     const input = {
//         access_token:localStorage.token,
//         id:this.props.customer_id,
//     }
//     get_mf_details(input).then(res => {
//     console.log("res",res)
//     try{
//     if (res.status==='successfull') {
//     this.setState(() => ({
//         resData:res.result,
//     }))
//     if(res.result[0]){
//       this.setState({
//         have:true
//       })
//     }
// }
// else{console.log(res)}
// }catch{
//   this.setState({
//     error:true,
//     errormsg:"Something went wrong",
//     loading:false
// })
// }
// })
// }
// createCustomModalHeader = (closeModal, save) => {
//     return (
//       <InsertModalHeader
//         className='table-modal-header'
//         title='Add Expense'
//         hideClose={ true } to hide the close button/>
//     );
// }
// policyValidator=(value, row)=>{
//   const response = { isValid: true, notification: { type: 'success', msg: '', title: '' } };
//   const nan = isNaN(parseInt(value, 10));
//   if (!nan) {
//     response.isValid = false;
//     response.notification.type = 'error';
//     response.notification.msg = 'Type must be character'
//     response.notification.title = 'Requested Value';
//   }
//   if (!value) {
//     response.isValid = false;
//     response.notification.type = 'error';
//     response.notification.msg = 'Please fill out this feilds'
//     response.notification.title = 'Requested Value';
//   }
//   if (value.length>40) {
//     response.isValid = false;
//     response.notification.type = 'error';
//     response.notification.msg = 'Only 40 characters'
//     response.notification.title = 'Requested Value';
//   }
//   return response;
// }
// amountValidator=(value, row)=>{
//   const response = { isValid: true, notification: { type: 'success', msg: '', title: '' } };
//   if (!value) {
//     response.isValid = false;
//     response.notification.type = 'error';
//     response.notification.msg = 'Please fill out this feilds'
//     response.notification.title = 'Requested Value';
//   }
//   if (value<=0) {
//     response.isValid = false;
//     response.notification.type = 'error';
//     response.notification.msg = '0 and negative value not allowed'
//     response.notification.title = 'Requested Value';
//   }
//   if (value.length>15) {
//     response.isValid = false;
//     response.notification.type = 'error';
//     response.notification.msg = 'Only 15 digits'
//     response.notification.title = 'Requested Value';
//   }
//   return response;
// }
// incomeValidator=(value, row)=>{
//   const response = { isValid: true, notification: { type: 'success', msg: '', title: '' } };

//   if(value==='Select'){
//     response.isValid = false;
//     response.notification.type = 'error';
//     response.notification.msg = 'Please select any value'
//     response.notification.title = 'Requested Value';
//   }
//   return response;
// }
// frequencyValidator=(value, row)=>{
// const response = { isValid: true, notification: { type: 'success', msg: '', title: '' } };

// if(value==='Select'){
//   response.isValid = false;
//   response.notification.type = 'error';
//   response.notification.msg = 'Please select any value'
//   response.notification.title = 'Requested Value';
// }
// return response;
// }
// onAddRow=(row) =>{
//   const input = {
//     access_token:localStorage.token,
//     mf_data:row,
//     id:this.props.customer_id,
//   }
//   console.log("input",input)
//   save_mf_details(input).then(res => {
//       console.log("res",res)
//   try{
//   if (res.status==='successfull') {
//     this.setState({
//       enableNext:true,
//       have:true
//     })
//     get_mf_details(input).then(res => {
//     console.log("res",res)
//     try{
//     if (res.status==='successfull') {
//     this.setState(() => ({
//       resData:res.result
//     }))
//     }
//     else{console.log(res)}
//   }catch{
//     this.setState({
//       error:true,
//       errormsg:"Something went wrong",
//       loading:false
//   })
//   }
//     })
// }
// else{
//   this.setState({
//     error:true,
//     errormsg:res.message,
// })
// }
// }catch{
//   this.setState({
//     error:true,
//     errormsg:"something went wrong",
// })
// }
// })
// }

// onAfterDeleteRow=(rowKeys)=>{
//      const input = {
//     access_token:localStorage.token,
//     row_id:{rowKeys},
//     id:this.props.customer_id,
//   }
//   console.log("input",input)
//   delete_mf_details(input).then(res => {
//       console.log("res",res)
//   try{
//   if (res.status==='successfull') {
// }
// else{
//   this.setState({
//     error:true,
//     errormsg:res.message,
// })
// }
// }catch{
//   this.setState({
//     error:true,
//     errormsg:'something went wrong',
// })
// }
// })
// }

// onAfterSaveCell=(row, cellName, cellValue)=>{
//     const input = {
//         access_token:localStorage.token,
//         row_id:row.id,
//         cellName:cellName,
//         cellValue:cellValue,
//         id:this.props.customer_id,
//       }
//       console.log("input",input)
//       update_mf_details(input).then(res => {
//           console.log("res",res)
//       try{
//       if (res.status==='successfull') {
//         this.setState(() => ({
//           success:true,
//           successmsg:res.message
//         }))
//         get_mf_details(input).then(res => {
//             console.log("res",res)
//             try{
//             if (res.status==='successfull') {
//             this.setState(() => ({
//               resData:res.result
//             }))
//             }
//             else{console.log(res)}
//           }catch{
//             this.setState({
//               error:true,
//               errormsg:"Something went wrong",
//               loading:false
//           })
//           }
//             })
//     }
//     else{
//       this.setState({
//         error:true,
//         errormsg:res.message,
//     })
//     }
//     }catch{
//       this.setState({
//         error:true,
//         errormsg:'something went wrong',
//     })
//     }
//     })
//     const input1 = {
//       access_token:localStorage.token,
//       id:this.props.customer_id,
//     }
//     get_mf_details(input1).then(res => {
//     console.log("res",res)
//     try{
//     if (res.status==='successfull') {
//     this.setState(() => ({
//       resData:res.result
//     }))
//     }
//     else{console.log(res)}
//   }catch{
//     this.setState({
//       error:true,
//       errormsg:"Something went wrong",
//       loading:false
//   })
//   }
//     })
//     }

// handleInsertButtonClick = (onClick) => {
//     const input = {
//         access_token:localStorage.token,
//         flag:'customer_mf_sip',
//         id:this.props.customer_id,
//       }
//       get_last_index(input).then(res => {
//           console.log("res",res)
//       try{
//       if (res.status==='successfull') {
//             index=1+res.result
//             console.log("index",index)
//     }
//     else{
//       this.setState({
//         error:true,
//         errormsg:res.message,
//     })
//     }
//     }catch{
//       this.setState({
//         error:true,
//         errormsg:'something went wrong',
//     })
//     }
//     })
//     onClick();
// }
// createCustomInsertButton = (onClick) => {
// return (
//     <InsertButton
//     btnText='Insert Row'
//     className='table-button'
//     onClick={ () => this.handleInsertButtonClick(onClick) }/>
// );
// }
// handleDeleteButtonClick = (onClick) => {
//     onClick();
//   }
//   createCustomDeleteButton = (onClick) => {
//     return (
//       <DeleteButton
//         btnText='Delete'
//         className='table-button'
//         onClick={ () => this.handleDeleteButtonClick(onClick) }/>
//     );
//   }
// autoValue=()=>{
//   return index;
// }
//  render() {
//     const schemeTypes = ['Select','SIP','Lumpsum' ];
//     const assetTypes = ['Select','Shares','Equity Mutual Funds','Debt','Savings','Debt Mutual Funds','Debentures','Fixed Deposits','Provident Fund','Gold' ];
//     const selectRowProp = {
//         mode: 'checkbox',
//         };
//     const cellEditProp = {
//     mode: 'click',
//     blurToSave: true,
//     afterSaveCell: this.onAfterSaveCell
//     };
//     let products =[]
//     if(this.state.resData){
//         Object.keys(this.state.resData).map((val) => {
//         products.push({"id":this.state.resData[val].id,"scheme_name":this.state.resData[val].scheme_name,"scheme_code":this.state.resData[val].scheme_code,"amount":this.state.resData[val].amount,"start_date":this.state.resData[val].start_date,"end_date":this.state.resData[val].end_date,"scheme_type":this.state.resData[val].scheme_type,"asset_type":this.state.resData[val].asset_type})
//         return null


//               })

//        }
//        const options = {
//         onAddRow: this.onAddRow,
//         afterDeleteRow: this.onAfterDeleteRow,
//         insertBtn: this.createCustomInsertButton,
//         deleteBtn: this.createCustomDeleteButton,
//         noDataText: 'Add your Expense by clicking +insert row button.',
//         insertModalHeader: this.createCustomModalHeader
//     };
//     return (
//         <div>
//         <div className='row'>
//         <div className='col-md-12'>
//          <div className='inner-table-form-height'>
//          <div className='form-heading-table'>Your Mf details</div>
//          <div className='bootstrape-table-div'>
//         <BootstrapTable
//         data={ products }
//         cellEdit={ cellEditProp }
//         insertRow={ true }
//         options={ options }
//         deleteRow={ true }
//         selectRow={ selectRowProp }
//         striped hover condensed
//         height='40vh' scrollTop={ 'Top' }
//         bordered={ false }
//         trClassName='bootstrape-tr'>
//             <TableHeaderColumn dataField='id' isKey={ true }  autoValue={this.autoValue}hiddenOnInsert={true} hidden>ID</TableHeaderColumn>

//             <TableHeaderColumn dataField='scheme_name' editable={ { type: 'text'} }thStyle={{'fontWeight': 'bold','fontSize':'14px'}} tdStyle={{'fontWeight': '400'}}width='120px'>Scheme Name</TableHeaderColumn>

//             <TableHeaderColumn dataField='scheme_code' editable={ { type: 'text'} }thStyle={{'fontWeight': 'bold','fontSize':'14px'}} tdStyle={{'fontWeight': '400'}}width='120px'>Scheme Code</TableHeaderColumn>

//             <TableHeaderColumn dataField='amount' editable={ { type: 'number',validator: this.amountValidator} }thStyle={{'fontWeight': 'bold','fontSize':'14px'}} tdStyle={{'fontWeight': '400'}}width='120px'>Amount</TableHeaderColumn>

//             <TableHeaderColumn dataField='start_date' editable={ { type: 'date'} }thStyle={{'fontWeight': 'bold','fontSize':'14px'}} tdStyle={{'fontWeight': '400'}}width='120px'>Start Date</TableHeaderColumn>

//             <TableHeaderColumn dataField='end_date' editable={ { type: 'date'} }thStyle={{'fontWeight': 'bold','fontSize':'14px'}} tdStyle={{'fontWeight': '400'}}width='120px'>End Date</TableHeaderColumn>

//             <TableHeaderColumn dataField='scheme_type' editable={ {type: 'select', options: { values: schemeTypes },validator: this.frequencyValidator} }thStyle={{'fontWeight': 'bold','fontSize':'14px'}} tdStyle={{'fontWeight': '400'}}width='120px'>Scheme Type</TableHeaderColumn>

//             <TableHeaderColumn dataField='asset_type' editable={ {type: 'select', options: { values: assetTypes },validator: this.frequencyValidator} }thStyle={{'fontWeight': 'bold','fontSize':'14px'}} tdStyle={{'fontWeight': '400'}}width='120px'>Asset Type</TableHeaderColumn>

//         </BootstrapTable>
//         </div>
//         </div>
//         </div>
//       </div>
//       </div>
//       );
//     }
//   }
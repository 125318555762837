import React,{useEffect,useState} from 'react'
import {zerodha_request_token_post} from './Api'
import {useNavigate} from 'react-router-dom'
import logo from "../../Images/logoBlack.png"
import './RedirectPages.scss'

const Authentication = (props) => {
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [errormsg,setErrorMsg] = useState(false)
    const [token,setToken] = useState('') 
    const navigate = useNavigate();

    let num =1
    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let token = params.get('request_token')
        let cId = params.get('client_id')
        console.log(params);
        const input={
            id:cId,
            request_token:token
        }
        console.log(input) 
        zerodha_request_token_post(input).then(res => {
            console.log("restoken", res)
        try{
          if(res.status==='successfull') {
                setToken(res.result.request_token)
                setSuccess(true)  
                setErrorMsg(res.message)
          }
          else{
              setError(true)
              setErrorMsg(res.message)
          }
        }catch{
            console.log('something went wrong');
        }
        })
    }, [num])

    const recom = ()=>{
        navigate(`/recommendation/${token}`)
    }

    return (
        <div className='redirectPageDiv'>
            <div className='redirectPageBox'>
                <div className='rediectPagelogo'><img src={logo} alt=""/></div>
                {success?
                <>
                <div className='AuthMSg'>
                    <h3>{errormsg}</h3>
                </div>
                <div className='executeBtn'>
                    <button id="custom-button" onClick={recom}>Go To Recommendation</button>
                </div>
                </>:null}

                {error?
                    <div  className='AuthMSg'>
                        <h3>{errormsg}</h3>
                    </div>
                :null}
            </div>
        </div>
    )
}
export default React.memo(Authentication)

import React, { useState, useEffect } from 'react';
import './Wg.scss'
import { Row, Col,DatePicker } from 'antd';
import {MDBIcon} from 'mdbreact'
import AreaGraph from './AreaGraph';
import {networth_compare} from './Api'
import Loader from '../../../shared/Loader';

const numberFormat=(value)=>{
    var val = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' L';
    }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
    return val;
  }

const Compare = (props) => {
    const [graphData, setGraphData] = useState({});
    const [tableData, setTableData] = useState({});
    const [graphData2, setGraphData2] = useState({});
    const [tableData2, setTableData2] = useState({});
    const [loading,setLoading] = useState(false)
 
    const onChange = async(date, dateString) => {
        setLoading(true)
        setGraphData('')
        setTableData('')
        const input = {
            access_token:localStorage.token,
            from_date:dateString,
            id:props.id
        }
        console.log(input);
        const res = await networth_compare(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log(res);
        try{
          if (res.status==='successfull'){
                setLoading(false)
                setGraphData(res.result.networth_growth_chart)
                setTableData(res.result.networth_as_it_is)
          }else{setLoading(false) }
        }catch{ setLoading(false) }
      };

      const onChange2 = async(date, dateString) => {
        setLoading(true)
        setGraphData2('')
        setTableData2('')
        const input = {
            access_token:localStorage.token,
            from_date:dateString,
            id:props.id
        }
        console.log(input);
        const res = await networth_compare(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log(res);
        try{
          if (res.status==='successfull'){
                setLoading(false)
                setGraphData2(res.result.networth_growth_chart)
                setTableData2(res.result.networth_as_it_is)
          }else{setLoading(false)}
        }catch{setLoading(false)}
      };

    return(
        <>
        {loading?<Loader/>:null}
            <div className='compareModalHead'>Compare Networth</div>
            <div className='compareRow'>
                <Row gutter={20}>
                    <Col span={5}>
                        <div className='compareSideCard'>
                            <div style={{marginBottom:'10px'}}>
                                <span><DatePicker onChange={onChange} style={{width:'100%'}}/></span>
                                {/* <span><MDBIcon className='compI' fas icon="long-arrow-alt-right" /></span> */}
                            </div>
                            <Row>
                                <Col span={12}>
                                    <div className='wgCardDiv'>
                                        <div>Wealth</div>
                                        <div>
                                        {tableData.wealth?<b>{numberFormat(tableData.wealth)}</b>
                                        :<b>--</b>}  
                                    </div>
                                    </div>
                                    <div className='wgCardDiv'>
                                        <div>Income</div>
                                        <div>
                                        {tableData.income?<b>{numberFormat(tableData.income)}</b>
                                        :<b>--</b>}  
                                    </div>
                                    </div>
                                    <div className='wgCardDiv'>
                                        <div>Expense</div>
                                        <div>
                                        {tableData.expense?<b>{numberFormat(tableData.expense)}</b>
                                        :<b>--</b>}  
                                    </div>
                                    </div>
                                </Col>
                                <Col span={12} style={{borderLeft:'1px solid #FF862C'}}>
                                    <div className='wgCardDiv'>
                                        <div>Wealth Growth Rate</div>
                                        <div>
                                            {tableData.growth_rate_of_wealth?<b>{tableData.growth_rate_of_wealth} %</b>
                                        :<b>--</b>}  
                                        </div>
                                    </div>
                                    <div className='wgCardDiv'>
                                        <div>Income Growth Rate</div>
                                        <div>
                                            {tableData.income_increase_rate?<b>{tableData.income_increase_rate} %</b>
                                        :<b>--</b>}  
                                        </div>
                                    </div>
                                    <div className='wgCardDiv'>
                                        <div>Expense Growth Rate</div>
                                        <div>
                                            {tableData.expense_increase_rate?<b>{tableData.expense_increase_rate} %</b>
                                        :<b>--</b>}  
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                      
                        </div>
                    </Col>
                    <Col span={19}>
                        <div className='WGchartAreaCompare'>
                            {Object.keys(graphData).length>0?
                            <AreaGraph color='#53d961'graphData={graphData}/>:null}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='compareRow'>
                <Row gutter={20}>
                    <Col span={5}>
                        <div className='compareSideCard'>
                        <div style={{marginBottom:'10px'}}>
                            <span><DatePicker onChange={onChange2} style={{width:'100%'}}/></span>
                            {/* <span><MDBIcon className='compI' fas icon="long-arrow-alt-right" /></span> */}
                        </div>
                        <Row>
                            <Col span={12}>
                                <div className='wgCardDiv'>
                                    <div>Wealth</div>
                                    <div>
                                        {tableData2.wealth?<b>{numberFormat(tableData2.wealth)}</b>
                                        :<b>--</b>}  
                                    </div>
                                </div>
                                <div className='wgCardDiv'>
                                    <div>Income</div>
                                    <div>
                                        {tableData2.income?<b>{numberFormat(tableData2.income)}</b>
                                        :<b>--</b>}  
                                    </div>
                                </div>
                                <div className='wgCardDiv'>
                                    <div>Expense</div>
                                    <div>
                                        {tableData2.expense?<b>{numberFormat(tableData2.expense)}</b>
                                        :<b>--</b>}  
                                    </div>
                                </div>
                            </Col>
                            <Col span={12} style={{borderLeft:'1px solid #FF862C'}}>
                                <div className='wgCardDiv'>
                                    <div>Wealth Growth Rate</div>
                                    <div>
                                        {tableData2.growth_rate_of_wealth?<b>{tableData2.growth_rate_of_wealth} %</b>
                                        :<b>--</b>}  
                                    </div>
                                </div>
                                <div className='wgCardDiv'>
                                    <div>Income Growth Rate</div>
                                    <div>
                                        {tableData2.income_increase_rate?<b>{tableData2.income_increase_rate} %</b>
                                        :<b>--</b>}  
                                    </div>
                                </div>
                                <div className='wgCardDiv'>
                                    <div>Expense Growth Rate</div>
                                    <div>
                                        {tableData2.expense_increase_rate?<b>{tableData2.expense_increase_rate} %</b>
                                        :<b>--</b>}  
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        </div>
                    </Col>
                    <Col span={19}>
                        <div className='WGchartAreaCompare'>
                            {Object.keys(graphData2).length>0?
                            <AreaGraph color='#53d961'graphData={graphData2}/>:null}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default Compare;
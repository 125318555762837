// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-head {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.change-form {
  padding: 5px 30px;
}

.form-icon-S {
  margin-right: 8px;
}

.eye-icon-active {
  color: lightgreen;
}`, "",{"version":3,"sources":["webpack://./src/Component/Setting/Setting.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AACA;EACI,iBAAA;AAEJ;;AAAA;EACI,iBAAA;AAGJ;;AADA;EACI,iBAAA;AAIJ","sourcesContent":[".change-head{\n    color: #000;\n    font-size: 24px;\n    font-weight: 600;\n    text-align: center;\n  }\n.change-form{\n    padding: 5px 30px;\n}\n.form-icon-S{\n    margin-right: 8px;\n}\n.eye-icon-active{\n    color: lightgreen;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState,useEffect} from 'react'
import './Reporting.scss'
// import { Table } from "ant-table-extensions";
import {DatePicker,Table} from 'antd';
import moment from 'moment'
import {customer_mf_details,customer_margin_details} from './ReportsApi'
import { CSVLink} from "react-csv";

const TrasactionReport = (props) => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [pickerValue, setPickerValue] = useState(moment(new Date()));
  const [dateSend, setdateSend] = useState([])

  const { RangePicker } = DatePicker;

  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        minimumFractionDigits:0
    }).format(value);

  const fetchmfData = ()=>{
    let newdata = []
    const input = {
        access_token:localStorage.token,
        type:'mf_orders',
        id:props.id,
        start_date:dateSend[0],
        end_date:dateSend[1],
    }
    console.log(input);
    customer_mf_details(input).then(res => {
        console.log('mforder',res);
        Object.keys(res.result).map((val)=>{<>
          {res.result[val].status==="COMPLETED" || res.result[val].status==="COMPLETE"?
            newdata.push({ 
            key: val+'mf',
            fund: res.result[val].fund,
            folio:'-',
            date: res.result[val].order_timestamp,
            transaction_type:res.result[val].transaction_type,
            quantity:res.result[val].quantity,
            price:res.result[val].average_price,
            amount:(res.result[val].average_price*res.result[val].quantity).toFixed(2)           
            })
            :null}
            </>})
        setData(newdata)
      })
  }

  const fetchMarginData = ()=>{
      let newdata = []
      const input1 = {
        access_token:localStorage.token,
        type:'orders',
        id:props.id,
        start_date:dateSend[0],
        end_date:dateSend[1]
    }
    console.log(input1);
    customer_margin_details(input1).then(res => {
        console.log('order',res);
          Object.keys(res.result).map((val)=>{<>
            {res.result[val].status==="COMPLETED"  || res.result[val].status==="COMPLETE"?
            newdata.push({ 
            key: val+'order',
            fund: res.result[val].tradingsymbol,
            date: res.result[val].order_timestamp,
            folio:'-',
            transaction_type: res.result[val].transaction_type,
            price: res.result[val].average_price,
            quantity: res.result[val].quantity,
            amount:(res.result[val].average_price*res.result[val].quantity).toFixed(2)
            })
            :null}
        </>})
        setData2(newdata)
      })
    
  }

  useEffect(()=>{
    fetchmfData()
    fetchMarginData()
  },[])

  const submitData =()=>{
    fetchmfData()
    fetchMarginData()
  }

  const onChangeDate = (date, dateString) => {
    setdateSend(dateString)
  };
  
  let alldata = []
    
    alldata = data.concat(data2)

  const column = [   
    {title: 'Fund',dataIndex: 'fund',ellipsis:true},
    {title: 'Folio No.',dataIndex: 'folio'},
    {title: 'Transaction Date',dataIndex: 'date'},
    {title: 'Transaction Type',dataIndex: 'transaction_type'},
    {title: 'Unit Price',align:'right',dataIndex: 'price',render: total => { return total?(total.toFixed(2)):0}},
    {title: 'Quantity',align:'right',dataIndex: 'quantity',render: total => { return total?(total.toFixed(2)):0}},
    {title: 'Amount',align:'right',dataIndex: 'amount',render: total => { return total}},
    // {title: 'Balance Units',dataIndex: 'balance_units'},
  ]

  const headers = [
    { label: "Fund", key: "fund" },
    { label: "Folio No.", key: "folio" },
    { label: "Transaction Date", key: "date" },
    { label: "Transaction Type", key: "transaction_type" },
    { label: "Unit Price", key: "price" },
    { label: "Quantity", key: "quantity" },
    { label: "Quantity", key: "amount" },

  ];

  const csvReport = {
    data: alldata,
    headers: headers,
    filename: `Transactions_${localStorage.email}_${pickerValue}.csv`
  };


  return (
    <>
    <div className='reportRangebox2'>
    <RangePicker 
          onChange={onChangeDate} allowClear={true}
          defaultValue={[pickerValue, pickerValue]} 
    />
        <button className='reportBtn' onClick={submitData}>Submit</button>
    </div>

    <CSVLink {...csvReport} className='csvDwn'>Export CSV</CSVLink>
   
      <Table rowClassName="antTable" columns={column} dataSource={alldata} size='small'
          // pagination={{pageSize: 5,position:['bottomCenter']}} 
          scroll={{y: 310}}
          pagination={false} 
          sticky={true}
          // bordered
          // exportableProps={{ fileName: `Transactions_${localStorage.email}_${pickerValue}`}}

          // summary={(pageData) => {
          //   let Total = 0;
          //   pageData.forEach(({amount}) => {
          //     Total = Total+parseInt(amount);
          //   });

          //   return (
          //     <>
          //       <Table.Summary.Row className='totalRow'> 
          //         <Table.Summary.Cell className='tableTotal'>Total</Table.Summary.Cell>
          //         <Table.Summary.Cell ></Table.Summary.Cell>
          //         <Table.Summary.Cell ></Table.Summary.Cell>
          //         <Table.Summary.Cell ></Table.Summary.Cell>
          //         <Table.Summary.Cell ></Table.Summary.Cell>
          //         <Table.Summary.Cell ></Table.Summary.Cell>
                 
          //            <Table.Summary.Cell className='tableTotal'><div style={{textAlign:'right'}}>{numberFormat(Total)}</div></Table.Summary.Cell>
          //       </Table.Summary.Row>
          //     </>
          //   );
          // }}
      />
</>
  )
}

export default TrasactionReport
import React, { useEffect, useState } from 'react';
import { Select, message,Row,Col,Input,Form} from 'antd';
import {covered_call_options,customer_review,instrument_list_for_cco} from '../Api'

const Setup = () => {
    const [data,setData] = useState([])
    const [symbol,setSymbol] = useState([])
    const [ccoList,setCcoList] = useState([{id:1}])
    const [loading,setLoading] = useState(false)
    const[clientId,setClientId] = useState('')
    const[investedValue,setInvestedValue] = useState('')

    const { Option } = Select;
    const addRow2 = () =>{
        let aa = [...ccoList]
        var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
        var product = {id: id,}
        aa.push(product);
        setCcoList(aa)
    }
    const handleRowDel2=(val)=>{
        let aa = [...ccoList]
        aa.splice(val,1);
        setCcoList(aa)
    };
    const updateDrvt=(e)=>{
        let aa  = [...ccoList]
        aa[e.target.id][e.target.name] = e.target.value
        setCcoList(aa)
    }
    const updateDrvtSerch=(value,val,symbolName)=>{
        let aa  = [...ccoList]
        aa[val][symbolName] = value
    }
    const updateclientID=(value)=>{
        setClientId(value)
    }
    const updateTotalValue=(e)=>{
        setInvestedValue(e.target.value)
    }

    const getClient = async()=>{
        setLoading(true)
        let newdata = [...data]
        const input = {access_token:localStorage.token}
        const res = await customer_review(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.keys(res.result).map((val)=>{
                newdata.push({ 
                key: res.result[val].customer_id,
                id: res.result[val].customer_id,
                name:res.result[val].customer_fullname,
                email_address:res.result[val].email_address
            })
            })
            setData(newdata)
            setLoading(false)
          }
          else{setLoading(false)}
        }catch{setLoading(false)}
      }
      const getSymbol = async()=>{
        const input = {access_token:localStorage.token}
        const res = await instrument_list_for_cco(input)
        console.log('resSymbol',res);
        try{
          if (res && res.status==='successfull'){
            setSymbol(res.result)
           }
          else{setLoading(false)}
        }catch{setLoading(false)}
      }
useEffect(()=>{
    getClient();
    getSymbol();
},[])

const submitCco=()=>{
  setLoading(true)
  const input = {
      access_token:localStorage.token,
      clientId:clientId,
      ccoList:ccoList,
      TotalInvestedValue:investedValue
  }
  console.log(input);
  covered_call_options(input).then(res => {
      console.log('ressubmit',res)
  try{
  if (res.status==='successfull') {
      if(Object.keys(res.result).length>0){
          setLoading(false)
          setCcoList([{id:1}])
      }else{
          setLoading(false)
          setCcoList([{id:1}])
          message.success(res.message)
      } 
  }
  else{
      setLoading(false)
      message.error(res.message)
  }
  }catch{
      setLoading(false)
      message.error('Something went wrong')
  }
  })
}
  return (
    <div>
        <Row>
            <Col span={8}>
            <Select
                style={{ width: 600 }}
                showSearch
                bordered={true}
                className='selectborder'
                placeholder="Select Client"
                optionFilterProp="children"
                onChange={updateclientID}
                filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase())>= 0
                }
            >
            {Object.keys(data).map((val)=>{
                return <Option value={data[val].id}>{data[val].email_address +','+ data[val].name}</Option>
                })}
            </Select>
            </Col>
            <Col span={8}></Col>
            <Col span={8}>
            <Form.Item
                label="Total Invested Value"
                name="username"
                rules={[{ required: true, message: 'Please input total invested value' }]}
            >
                <Input  onChange={updateTotalValue}/>
            </Form.Item>
            </Col>
        </Row>
       <div>
        <div className='tab-scroll'>
            <table class="table table-condensed table-sm">
                <thead className='tab-head'>
                    <th className='tab-th td-lf-border'>EQ Trading Symbol</th>
                    <th className='tab-th td-lf-border'>EQ Quantity</th>
                    <th className='tab-th td-lf-border'>CE Symbol</th>
                    <th className='tab-th td-lf-border'>CE Quantity</th>
                    <th className='tab-th td-lf-border td-rh-border'></th>
                </thead>
                <tbody>
                {Object.keys(ccoList).map((val) =>{
                    return(
                        <tr className='tab-tr'> 
                            <td className='tab-td td-lf-border'style={{minWidth:'100px',maxWidth:'100px'}}>
                                <Select
                                    showSearch
                                    bordered={true}
                                    className='selectborder'
                                    placeholder="Select Symbol"
                                    optionFilterProp="children"
                                    onChange={(value) => updateDrvtSerch(value,val,'eqSymbol')}
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase())>= 0
                                    }
                                >
                                {symbol.EQ?<>
                                    {Object.keys(symbol.EQ).map((val1)=>{
                                        return <Option value={val1}>{symbol.EQ[val1]}</Option>
                                        })}
                                </>:null}
                                </Select>
                            </td>

                            <td className='tab-td td-lf-border' style={{minWidth:'80px',maxWidth:'80px'}}><input name='eqQuantity' type='number' placeholder='0' id = {val} onChange={updateDrvt} className='select-width'/></td>

                            <td className='tab-td td-lf-border' style={{minWidth:'110px',maxWidth:'110px'}}>
                            <Select
                                showSearch
                                bordered={true}
                                className='selectborder'
                                placeholder="Select Symbol"
                                optionFilterProp="children"
                                onChange={(value) => updateDrvtSerch(value,val,'ceSymbol')}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase())>= 0
                                }
                            >
                                {symbol.CE?<>
                                    {Object.keys(symbol.CE).map((val1)=>{
                                        return <Option value={val1}>{symbol.CE[val1]}</Option>
                                        })}
                                </>:null}
                                </Select>
                            </td>

                            <td className='tab-td td-lf-border' style={{minWidth:'80px',maxWidth:'80px'}}><input name='ceQuantity' type='number' placeholder='0' id = {val} onChange={updateDrvt} className='select-width'/></td>
                          
                            <td className='cross-btn td-lf-border td-rh-border'><button onClick={()=>handleRowDel2(val)}><i class="fas fa-trash-alt"></i></button></td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
            <div className='addBtn'><button onClick={addRow2}>Add Row</button></div>
        </div>
        <div className='addBtn' style={{textAlign:'right',marginTop:'-20px'}}><button onClick={submitCco}>Submit</button></div>
    </div>
    </div>
  )
}

export default Setup
import React from 'react'
import './WhyUs.scss'
import './WhyUsMedia.scss'
import Navbar from '../Universal/Navbar'
import Footer from '../Universal/Footer'
import FooterContact from '../Universal/FooterContact'
import harishpic from '../../../Images/harish.png'
import saumilPic from '../../../Images/saumil.png'
import malvikaPic from '../../../Images/malvika.png'
import bhuvanaapic from '../../../Images/bhuvanaa.png'
import kajalPic from '../../../Images/kajal.png'
import kanicaPic from '../../../Images/kanica.png'
import whyicon1 from '../../../Images/whyicon1.png'
import whyicon2 from '../../../Images/whyicon2.png'
import whyicon3 from '../../../Images/whyicon3.png'
import {MDBIcon} from 'mdbreact'

const Welcome = (props) => {
    
    const SectionCard = (props) => {
        const {icon,icon2,text,text2,text3,text4}=props
        return (
                <div className='welcomeCardWhite'>
                    <div className='whySectionCardIcon'><img src={icon} alt=''></img></div>
                    <div className='welcomeCardHead'>{text}</div>
                    <div className='welcomeCardLower'>
                        <div className='welcomeCardIcon'><MDBIcon far icon="check-circle" className='whyTickIcon' />
                        <span className='welcomeCardText'>{text2}</span></div>
                        <div className='welcomeCardIcon'>{icon2}<span className='welcomeCardText'>{text3}</span><span className='welcomeCardText4'>{text4}</span></div>
                        
                    </div>
                </div>
        )
    }
    
    const ExpertCard =(props)=>{
        const{image,text,text2,discription}=props
        return(
            <div className='expertImageCard'>
                <div class="image-boxnew">
                    <img src={image} alt=''/>
                    <div className='whatwedotextdivnew'>
                        <div className='whatwedotextshow'>
                                <div className='expertName'>{text}</div>
                                <div className='expertPost'>{text2}</div>
                        </div>
                        <div className='whatwedotextnew'>{discription}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div><Navbar /></div>
            <div className='bodyDiv'>
                <div className='welcomeBannerImage'> 
                    <div className='row'>
                        <div className='col-md-6'></div>
                        <div className='col-md-6'>
                            <div className='welcomeBannerText'>
                            <span>Delivering Performance<br/>in the world of promises</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='alphaGenerator'>
                    <div className='sectionHeadYellow'>We are Alpha Generators</div>
                    <div className='sectionParaYellow'>We believe that the simpler the solution, the more efficient it is. <br/>Our Alpha comes from </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <SectionCard icon={whyicon1} icon2={<MDBIcon far icon="check-circle" className='whyTickIcon'/>} text='Choosing Lowest cost products' text2='Transparent Pricing' text3='Higher Returns'/>
                        </div>
                        <div className='col-md-4'>
                            <SectionCard icon={whyicon2} icon2={<MDBIcon far icon="check-circle" className='whyTickIcon'/>} text='Better Risk Management' text2='Lesser Drawdowns' text3='More Peace of Mind'/>
                        </div>
                        <div className='col-md-4'>
                            <SectionCard icon={whyicon3} text='Customized Smart Strategy Products' text2='That work in almost all market' text4='conditions'/>
                        </div>
                    </div>
                    <div className='sectionFooterYellow'>We don't sell or distribute any financial product to earn commissions.<br/> You grow, you happily pay us, we grow with you. This is how it will always be.</div>
                </div>  
          
                <div className='expertSec'>
                    <div className='sectionAskHead'>Meet Your Experts</div>
                    <div className='expertCardSection'>
                        <div className='grid-container'>
                            <ExpertCard image={bhuvanaapic} text='Bhuvanaa Shreeram' text2='Head - Financial Planning' discription='Bhuvanaa is a Certified Financial Planner (CFP CM) and holds an MBA degree in Finance. She has over 17 years experience in financial services having worked with the TATA Group, Sundaram Finance and Wealth Advisors India. She heads the Financial Planning Division and ensures we keep the focus on our clients and their lives. Her team helps our clients make informed choices that help them live a great life, without the fear of running out of money or dying with too much.'/>
                        
                            <ExpertCard image={harishpic} text='Harish Menon' text2='Head - Investment Strategy ' discription='Harish is a Chartered Accountant with over 17 years experience in financial markets. He heads our Investment Advisory division. He worked with ING Vysya Bank Ltd., ICICI Securities Ltd. and IDFC Mutual Fund before his entrepreneurial stints. His last corporate role was as Chief Economist – India for ING Vysya Bank. Harish has also been a trainer in the financial markets domain & has facilitated over 1500 days of trainings for various mutual funds, wealth management firms, banks etc. His expertise in fixed income, macro-economics, and currency related areas help build robust investment portfolios for our clients.'/>
                        
                        
                            <ExpertCard image={kajalPic} text='Kajal singh' text2='Head - Operations and Client Success' discription="Kajal has a Master degree from Melbourne Business School and has over 10 years experience in the financial service industry & consulting. She has worked with Citi Financial, Barclays and Ernst and Young in process optimisation and operations roles. With an eye to detail and commitment to see things through, at House of Alpha she heads the Client Success Team and ensures seamless implementation of plan recommendations taking our clients towards their goals one step at a time."/>
                        
                            {/* <ExpertCard image={kanicaPic} text='Kanica Sharma' text2='Head - Technology' discription='Kanica holds an MBA. She worked with ABC consulting, Vodafone, etc before starting her venture DeepLayers which is a software development company. She has great exposure and experience in building very efficient technology platforms. Technology plays a critical part in client experience and success. At House Of Alpha, Kanica and her team are creating our own cloud-based digital platform that would seamlessly integrate financial planning, scenario building, plan execution, investing, reporting, and compliance.'/>
                        
                            <ExpertCard image={malvikaPic} text='Malvika Parikh' text2='Head - Finance & Compliance ' discription="Malvika Parikh is a BMS graduate & holds a Masters degree in Commerce with a specialization in Business Management. She has worked with TATA Business Service Solutions & Transitions HR in operations and compliance roles. At House of Alpha, she ensures smooth operational flows for all legal and compliance related matters."/> */}
                        
                            <ExpertCard image={saumilPic} text='Saumil Parikh' text2='Head - Derivatives Strategy' discription='Saumil Parikh is a Chartered Accountant with over 10 years of experience in Capital Markets. He worked with KPMG before founding Scale Up Financial Advisors in 2011 which is a specialist Options trading firm. He is also a visiting faculty with S P Jain Institute of Global Management, SIES Management Studies, Rizvi Management Studies etc. At House of Alpha, Saumil helps clients better manage risks in their portfolios and optimize returns using smart derivative strategies.'/>
                        
                        </div>
                    </div>
                </div>
            <div><FooterContact /></div>
            <div className='footrcontcBG'></div>
            <div><Footer /></div>
        </div>
        </>
    )
}

export default Welcome

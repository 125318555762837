import React, { useState,useEffect} from 'react'
import { Table } from "ant-table-extensions";
import Loader from '../../../shared/Loader';
import { year_wise_summary } from '../../Dashboard/reporting/ReportsApi';

const Market_capwise = (props) => {
    const [data, setData] = useState([]);
  const [loading,setLoading] = useState(false)

  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
      // style: 'currency',
      // currency: 'INR',
      minimumFractionDigits:0
  }).format(value);

  useEffect(()=>{
    setLoading(true)
    let newdata = []
    const input = {
        access_token:localStorage.token,
        type:'market_cap',
        id:props.id
    }
    year_wise_summary(input).then(res => {
        console.log('year',res);
        setLoading(false)
        Object.keys(res.result).map((val)=>{
          Object.keys(res.result[val]).map((val2)=>{
            newdata.push({ 
              key: val,
              year:val2,
              })

          })
        })
        setData(newdata)
      })
  },[])

  console.log(data);
  const column = [   
    {title: 'Market Cap',dataIndex: 'year'},
    {title: 'Total Invested Value',dataIndex: 'Opening_balance',render: total => { return total?(numberFormat(total.toFixed(0))):0}},
    {title: 'Total Current Value',dataIndex: 'purchase',render: total => { return total?(numberFormat(total.toFixed(0))):0}},
  ]

  return (
    <>
    {loading?<Loader/>:null}
   
      <Table rowClassName="antTable" columns={column} dataSource={data} size='small'
          scroll={{y: 300}}
          pagination={false} 
          sticky={true}
          exportableProps={{ fileName: `yearly_summary_${localStorage.email}`}}
      />
</>
  )
}

export default Market_capwise
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableHead {
  font-size: 18px;
  font-weight: 700;
}

.chartI {
  background: #E87C1B;
  color: white;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
}

.ModalchartArea {
  height: 50vh;
  padding: 5px;
}

.loanNameTitle {
  font-size: 10px;
}

.loanName {
  font-size: 12px;
  font-weight: 600;
}

.loanNameHead {
  font-size: 16px;
  font-weight: 700;
  margin-top: -10px;
  margin-bottom: 5px;
}

.statisticCardImg {
  background: white;
  border-radius: 100%;
  padding: 5px;
  width: 45px;
  height: 45px;
}

.noDataHead {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-top: 15%;
  color: #BDC3C7;
}`, "",{"version":3,"sources":["webpack://./src/Component/Dashboard/loan/Loans.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,gBAAA;AAAJ;;AAEA;EACI,mBANQ;EAOR,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AACA;EACI,YAAA;EACA,YAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;;AADA;EACI,eAAA;EACA,gBAAA;AAIJ;;AAFA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAKJ;;AAHA;EACI,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AAMJ;;AAJA;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AAOJ","sourcesContent":["$mainColor: #E87C1B;\n.tableHead{\n    font-size: 18px;\n    font-weight: 700;\n}\n.chartI{\n    background: $mainColor;\n    color: white;\n    padding: 3px;\n    border-radius: 5px;\n    cursor: pointer;\n}\n.ModalchartArea{\n    height: 50vh;\n    padding: 5px;\n}\n.loanNameTitle{\n    font-size: 10px;\n}\n.loanName{\n    font-size: 12px;\n    font-weight: 600;\n}\n.loanNameHead{\n    font-size: 16px;\n    font-weight: 700;\n    margin-top: -10px;\n    margin-bottom: 5px;   \n}\n.statisticCardImg{\n    background: white;\n    border-radius: 100%;\n    padding: 5px;\n    width: 45px;\n    height: 45px;\n}\n.noDataHead{\n    font-size: 26px;\n    font-weight: 700;  \n    text-align: center;\n    margin-top: 15%;\n    color: #BDC3C7; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

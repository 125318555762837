import React, { PureComponent } from 'react'
import {save_fatca_details,get_fatca_details,update_fatca_details,country} from './Api'

import Loader from '../../shared/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import {MDBIcon} from 'mdbreact'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ProfileSetup.scss'

const toastMsg =(ids,msg,type)=>{
  toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
} 

export default class FatcaDetails extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            country_of_birth_ansi_code:'',
            no_other_tax_residences:'',
            source_of_wealth:'',
            gross_annual_income:'',
            city_of_birth:'',
            net_worth:'',
            occupation:'',
            political_exposed_person:'',
            any_other_info:'',
            retirement_age:'',
            loading:false,
            disabled:false,
            error:false,
            success:false,
            warning:false,
            country_list:{},
            city_valid:true,
            worth_valid:true,
            retirement_valid:true,
        }
    }

componentDidMount(){
  const input = {
    access_token:localStorage.token,
    id:this.props.id
}
  get_fatca_details(input).then(res => {
    console.log("res",res)
  try{
  if (res.status==='successfull') {
    this.setState(() => ({
      country_of_birth_ansi_code:res.result.country_of_birth_ansi_code,
      no_other_tax_residences:res.result.no_other_tax_residences,
      source_of_wealth:res.result.source_of_wealth,
      gross_annual_income:res.result.gross_annual_income,
      city_of_birth:res.result.city_of_birth,
      net_worth:res.result.net_worth,
      occupation:res.result.occupation,
      political_exposed_person:res.result.political_exposed_person,
      any_other_info:res.result.any_other_info,
      retirement_age:res.result.retirement_age,
      disabled:true
    }))
  }
  else{console.log(res)}
}catch{
  toast.error('Something went wrong')
  this.setState({loading:false})
  
}
})
country(input).then(res => {
  console.log("get",res)
  try{
  if (res.status==='successfull') {
    this.setState(() => ({
       country_list:res.result
    }))
}
else{console.log(res)}
}catch{
  toast.error('Something went wrong')
  this.setState({loading:false})
}
})
}

onChange=(e)=>{
    this.setState({ [e.target.name]: e.target.value })
  }
onChangecity=(e)=>{
  const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s][a-zA-Z. ]{2,30}$/)
  let name= validate.test(e.target.value)
  if(name===true){
      this.setState({
          [e.target.name]: e.target.value,
          city_vaild:true
      })
  }
  else{
      this.setState({
        [e.target.name]: e.target.value,
        city_vaild:false
      })
  }
}
onChangeworth=(e)=>{
  const validate=RegExp(/^([0-9]*)$/)
  let name= validate.test(e.target.value)
  if(name===true){
      this.setState({
          [e.target.name]: e.target.value,
          worth_valid:true
      })
  }
  else{
      this.setState({
        [e.target.name]: e.target.value,
        worth_valid:false
      })
  }
}

numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits:0
    }).format(value);

onChangeretirement=(e)=>{
  const validate=RegExp(/^([0-9]*)$/)
  let name= validate.test(e.target.value)
  if(name===true){
      this.setState({
          [e.target.name]: e.target.value,
          retirement_valid:true
      })
  }
  else{
      this.setState({
        [e.target.name]: e.target.value,
        retirement_valid:false
      })
  }
}

onSubmitaddress=(e)=>{
  e.preventDefault();
  const ids = toast.loading("Please wait...",{toastId:'load'})
  const input = {
        access_token:localStorage.token,
        country_of_birth_ansi_code:this.state.country_of_birth_ansi_code,
        no_other_tax_residences:this.state.no_other_tax_residences,
        source_of_wealth:this.state.source_of_wealth,
        gross_annual_income:this.state.gross_annual_income,
        city_of_birth:this.state.city_of_birth,
        net_worth:this.state.net_worth,
        occupation:this.state.occupation,
        political_exposed_person:this.state.political_exposed_person,
        any_other_info:this.state.any_other_info,
        retirement_age:this.state.retirement_age,
        id:this.props.id
  }
  console.log("innppuutt",input)
if(this.props.fatca===0){
  save_fatca_details(input).then(res => {
  try{
  if (res.status==='successfull') {
      this.props.update();
      this.props.step5();
    }
    else{toastMsg(ids,res.message,'error')
    }
    }catch{toastMsg(ids,'Something went wrong','error')}
    })
  }
  else{
    update_fatca_details(input).then(res => {
      console.log("res",res)
  try{
  if (res.status==='successfull') {
    toastMsg(ids,res.message,'success')
    this.setState(() => ({disabled:true}))
}
else{toastMsg(ids,res.message,'error')}
}catch{toastMsg(ids,'Something went wrong','error')}
})
}
}
edit=(e)=>{
  e.preventDefault()
  this.setState({
      disabled:false
  })
}
cancel=()=>{
  this.setState({
      error:false,
      success:false,
      warning:false
  })
}
pleaseSave=(e)=>{
  e.preventDefault()
  this.setState({
      warning:true,
  })
}
handlesave=()=>{
  this.props.step5();
}
onleave=()=>{
  this.setState({
    warning:false,
})
}
    render() {
      const {disabled} = this.state
        if (this.state.loading){
            return(
              <div style={{textAlign:'center',marginTop:100}}>
              <Loader/>
              </div>
              );
          }
        return (
           <div>
           <ToastContainer theme='colored' limit={1} enableMultiContainer={false}/>
              {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

            {this.state.warning?(
            <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  confirmBtnBsStyle="success"
                  cancelBtnText="No"
                  cancelBtnBsStyle="danger"
                  onConfirm={this.handlesave}
                  onCancel={this.onleave}
                  focusCancelBtn>Do you want to proceed without save your changes</SweetAlert>):(<span></span>)}

        {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancel}>
              {this.state.successmsg}
            </SweetAlert>):(<span></span>)}
            <div className='row'>
            <div className='col-md-12'>
            <form onSubmit={this.onSubmitaddress}>
            <div className='form-heading'>Fatca Details
              {this.props.fatca===1?(
                <span className='formButton'>
                  {disabled?
                  <button type="button" onClick={this.edit}><MDBIcon far icon="edit" className='form-edt-icon'/>Edit</button>:
                  <>
                    {this.state.city_valid && this.state.worth_valid && this.state.retirement_valid?
                  <button type='submit'><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>:
                  <button disabled><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>}
                  </>}
                </span>):null}
              </div>
           
              <div className='formHeight'>
              <div className='row'>
                 <div className='col-md-3'>
                  <div class="input-field-u">
                    <div className='select-label'>Country of birth</div>
                          <div className='profile-form-input'>
                          <select name="country_of_birth_ansi_code" value={this.state.country_of_birth_ansi_code} disabled={this.state.disabled} onChange={this.onChange}>
                          <option value="">Select</option>
                          {Object.keys(this.state.country_list).map((val)=>{
                              return<option value={val}>{val}</option>
                          })}
                          </select>
                    </div>
                    </div>
                  </div>
                  <div className='col-md-3 inner-form-gap'>
                  <div class="input-field-u">
                      <input id="birthcity" type="text" name="city_of_birth" value={this.state.city_of_birth} disabled={this.state.disabled} onChange={this.onChangecity} />
                      <label for="birthcity">City of birth</label>
                  </div>
                  {this.state.city_valid?(<span></span>):(<div className='invalid-msg'>Only text</div>)}
                  </div>
                  <div className='col-md-3'>
                    <div class="input-field-u">
                    <div className='select-label'>Gross Annual Income (₹)*</div>
                          <div className='profile-form-input'>
                          <select value={this.state.gross_annual_income} name="gross_annual_income" disabled={this.state.disabled} onChange={this.onChange} required>
                            <option value="">Select</option>
                            <option value="Below 1 lac">Below 1 lac</option>
                            <option value="Between 1 lac to 5 lac">Between 1 lac to 5 lac</option>
                            <option value="Between 5 lac to 10 lac">Between 5 lac to 10 lac</option>
                            <option value="Between 10 lac to 25 lac">Between 10 lac to 25 lac</option>
                            <option value="Between 25 lac to 1 Crore">Between 25 lac to 1 Crore</option>
                            <option value="Above 1 Crore">Above 1 Crore</option>
                          </select>
                         </div>
                    </div>
                </div>
                <div className='col-md-3 inner-form-gap'>
                  <div class="input-field-u">
                      <input id="worth" type="number" maxLength={18} value={this.state.net_worth} name="net_worth" disabled={this.state.disabled} onChange={this.onChangeworth} />
                      <label for="worth">Net Worth</label>
                  </div>
                  {this.state.worth_valid ?(<span></span>):(<div className='invalid1'>Only numeric value</div>)}
                </div>

                <div className='col-md-3'>
                <div class="input-field-u">
                  <div className='select-label'>Occupation*</div>
                    <div className='profile-form-input'>
                      <select name="occupation" value={this.state.occupation} maxLength={40} onChange={this.onChange}disabled={this.state.disabled} required>
                        <option value="">Select</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Bussiness">Business</option>
                        <option value="Forex dealer">Forex dealer</option>
                        <option value="Government Service">Government Service</option>
                        <option value="Housewife">Housewife</option>
                        <option value="Others">Others</option>
                        <option value="Private Sector Service">Private Sector Service</option>
                        <option value="Professional">Professional</option>
                        <option value="Public Sector Service">Public Sector Service</option>
                        <option value="Retired">Retired</option>
                        <option value="Student">Student</option>
                      </select>
                    </div>
                  </div>
                  </div>

                  <div className='col-md-3'>
                  <div class="input-field-u">
                  <div className='select-label'>Source of Wealth*</div>
                    <div className='profile-form-input'>
                      <select name="source_of_wealth" value={this.state.source_of_wealth}  onChange={this.onChange}disabled={this.state.disabled} required>
                        <option value="">Select</option>
                        <option value="Salary">Salary</option>
                        <option value="Bussiness">Business</option>
                        <option value="Gift">Gift</option>
                        <option value="Ancestrol Property">Ancestral Property</option>
                        <option value="Rental Income">Rental Income</option>
                        <option value="Prize Money">Prize Money</option>
                        <option value="Royalty">Royalty</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                  </div>

                  <div className='col-md-3'>
                  <div class="input-field-u">
                  <div className='select-label'>Politically Exposed Person(PEP)*</div>
                    <div className='profile-form-input'>
                      <select name="political_exposed_person" value={this.state.political_exposed_person} onChange={this.onChange}disabled={this.state.disabled} required>
                        <option value="">Select</option>
                        <option value="I am PEP">I am PEP</option>
                        <option value="I am related to PEP">I am related to PEP</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                    </div>
                  </div>
                  </div>

                  <div className='col-md-3 inner-form-gap'>
                  <div class="input-field-u">
                      <input id="retirement" type="text" maxLength={18} value={this.state.retirement_age} name="retirement_age" disabled={this.state.disabled} onChange={this.onChangeretirement} required/>
                      <label for="retirement">Retirement age*</label>
                  </div>
                  {this.state.retirement_valid ?(<span></span>):(<div className='invalid1'>Only numeric value</div>)}
                </div>

                  

                  <div className='col-md-6'>
                  <div class="input-field-u">
                  <div className='select-label'>Is Country of Tax Residency other than India*</div>
                        <div className='profile-form-input'>
                        <select name="no_other_tax_residences" value={this.state.no_other_tax_residences} disabled={this.state.disabled} onChange={this.onChange} required>
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        </select>
                  </div>
                  </div>
                 </div>

                 <div className='col-md-6 inner-form-gap'>
                  <div class="input-field-nr-u">
                      <input id="input3" type="text" name="any_other_info" value={this.state.any_other_info} maxLength={80} onChange={this.onChange}disabled={this.state.disabled} placeholder=' '/>
                      <label for="input3">Any Other Information</label>
                  </div>
                  </div>
                 {this.state.no_other_tax_residences==='Yes'?(
                        <div className='fatca-disclaimer'>If your tax residency/nationality/citizenship is other than India then please provide declaration/self certification under FATCA/CRS.( FATCA Form )
                        </div>
                        ):(<span></span>)}
              </div>
         </div>
         {this.props.bank===1 ?(
         <div></div>):(<div className='previous-msg'>Please fill the previous form first.</div>)}

        {this.props.bank===1 && this.props.fatca===0?(
         <div className='submit-Btn'><button onClick={this.props.step3}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
         {this.state.city_valid && this.state.worth_valid && this.state.retirement_valid?(
         <button type='submit'>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>):(
         <button disabled={true} style={{background:'#E5E7E9'}}>Next<MDBIcon icon="angle-right" className='buttonArrowRight'/></button>
         )}
         </div>):(<div></div>)}

         {this.props.bank===1 && this.props.fatca===1?(
         <div className='submit-Btn'><button onClick={this.props.step3}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
          {this.state.disabled?(
        <button onClick={this.props.step5}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
          ):(
          <button type='button' onClick={this.pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
          )}
         </div>):(<div></div>)}
    </form>
    </div>
    </div>
    </div>

        )
    }
}

import axios from 'axios'
export const user_info = (input) => {
  return axios
      .put('/alpha_user_compliance/user_info', {
          customer_firstname:input.customer_firstname,
          customer_middlename:input.customer_middlename,
          customer_lastname:input.customer_lastname,
          mobile_number:input.mobile_number,
          home_number:input.home_number,
          current_job:input.current_job,
          current_employer_name:input.current_employer_name,
          year_of_experience:input.year_of_experience,
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_user_info = input => {
  return axios
      .get('/alpha_user_compliance/user_info',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const save_spouse_details = (input) => {
  return axios
      .post('/alpha_user_compliance/spouse_details', {
          access_token:input.access_token,
          first_name:input.first_name,
          middle_name:input.middle_name,
          last_name:input.last_name,
          mobile_number:input.mobile_number,
          home_number:input.home_number,
          current_job:input.current_job,
          current_employer_name:input.current_employer_name,
          year_of_experience:input.year_of_experience,
          date_of_birth:input.date_of_birth,
          pan_number:input.pan_number,
          aadhar_number:input.aadhar_number,
          main_source_income :input.main_source_income,
          residence_address:input.residence_address,
          spouse_flag:input.spouse_flag,
          id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_spouse_details = input => {
  return axios
      .get('/alpha_user_compliance/spouse_details',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const update_spouse_details = (input) => {
  return axios
      .put('/alpha_user_compliance/spouse_details', {
          access_token:input.access_token,
          first_name:input.first_name,
          middle_name:input.middle_name,
          last_name:input.last_name,
          mobile_number:input.mobile_number,
          home_number:input.home_number,
          current_job:input.current_job,
          current_employer_name:input.current_employer_name,
          year_of_experience:input.year_of_experience,
          date_of_birth:input.date_of_birth,
          pan_number:input.pan_number,
          aadhar_number:input.aadhar_number,
          main_source_income :input.main_source_income,
          residence_address:input.residence_address,
          spouse_flag:input.spouse_flag,
          id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const delete_spouse_detail = input => {
  return axios
      .delete('/alpha_user_compliance/spouse_details',
      {
          headers: {
            'Authorization': input.access_token
          }},)
      .then(response => {
          return response.data
      })
      .catch(err => {})
  }
export const save_address = input => {
  return axios
      .post('/alpha_user_compliance/address', {
          access_token:input.access_token,
          house_no:input.house_no,
          apartment:input.apartment,
          area_locality:input.area_locality,
          current_city:input.current_city,
          current_state:input.current_state,
          current_country:input.current_country,
          current_pincode:input.current_pincode,
          permanent_house_no:input.permanent_house_no,
          permanent_apartment:input.permanent_apartment,
          permanent_area_locality:input.permanent_area_locality,
          permanent_city:input.permanent_city,
          permanent_state:input.permanent_state,
          permanent_country:input.permanent_country,
          permanent_pincode:input.permanent_pincode,
          Permanent_add_flag:input.Permanent_add_flag,
          id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const update_address = input => {
return axios
    .put('/alpha_user_compliance/address', {
      access_token:input.access_token,
      house_no:input.house_no,
      apartment:input.apartment,
      area_locality:input.area_locality,
      current_city:input.current_city,
      current_state:input.current_state,
      current_country:input.current_country,
      current_pincode:input.current_pincode,
      permanent_house_no:input.permanent_house_no,
      permanent_apartment:input.permanent_apartment,
      permanent_area_locality:input.permanent_area_locality,
      permanent_city:input.permanent_city,
      permanent_state:input.permanent_state,
      permanent_country:input.permanent_country,
      permanent_pincode:input.permanent_pincode,
      Permanent_add_flag:input.Permanent_add_flag,
      id:input.id
    },
    {
        headers: {
          'Authorization': input.access_token
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
export const get_address_details = input => {
  return axios
      .get('/alpha_user_compliance/address',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const country = input => {
  return axios
      .get('/alpha_user_compliance/country',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_ria_agreement = input => {
    return axios
        .get('/alpha_user_compliance/ria_agreement',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          }
          )
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const save_ria_agreement = (input) => {
    return axios
        .post('/alpha_user_compliance/ria_agreement', {
            access_token:input.access_token,
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const get_risk_profiling = (input) => {
    return axios
        .get('/alpha_user_compliance/risk_profiling',
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const save_risk_profiling = input => {
    return axios
        .post('/alpha_user_compliance/risk_profiling', {
            access_token:input.access_token,
            ans_arr: input.ans_arr,
            date:input.date,
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const submit_risk_profiling = input => {
  return axios
      .put('/alpha_user_compliance/risk_profiling', {
          access_token:input.access_token,
          ans_arr: input.ans_arr,
          date:input.date,
          risk_profiling:input.risk_profiling
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const save_client_sign = input => {
  return axios
      .post('/alpha_user_compliance/customer_sign', {
          access_token:input.access_token,
          signature: input.signature,
          signature2:input.signature2,
          id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_client_sign = input => {
  return axios
      .get('/alpha_user_compliance/customer_sign',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id
          }
        })
      .then(response => {
        // console.log('rrddff',response)
          return response.data
      })
      .catch(err => {})
}
export const view_compliants_docs = input => {
  return axios
  .get('/alpha_user_compliance/view_compliants_docs',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        flag:input.flag,
        id : input.id,
        dir:input.dir
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
  }
  export const phone_number = input => {
    return axios
    .get('/alpha_user_compliance/phone_number',
    {
        headers: {
          'Authorization': input.access_token
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
    }
    export const otp_verification_get = input => {
      return axios
      .get('/alpha_user_compliance/otp_verification',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            mobile_no:input.mobile_no
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
      }
      export const otp_verification_post = input => {
        return axios
            .post('/alpha_user_compliance/otp_verification', {
              given_otp: input.given_otp,
            },
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      
  export const ria_agreement_renew = input => {
        return axios
            .get('/alpha_user_compliance/ria_agreement_renew',
            {
                headers: {
                  'Authorization': input.access_token
                },
                params: {
                  id:input.id
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
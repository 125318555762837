import React,{useEffect,useState,useRef} from 'react'
// import Link from 'next/link';
// import Image from 'next/image'
import home2 from '../../../Images/homePage/home2.jpg'
import process from '../../../Images/homePage/Process.jpg'
import logosmall from '../../../Images/homePage/logoSmall.jpg'
import kajal  from '../../../Images/homePage/kajal.png'
import Bhuvanaa from '../../../Images/homePage/bhu.png'
import Harish from '../../../Images/homePage/ha.png'
import Navbar from '../Universal/Navbar'
import FooterContact from '../Universal/FooterContact';
import Footer from '../Universal/Footer';
import HomeBannerText from '../Universal/TextAnimation/HomeBannerText'
import {testimonial_get,hoa_blogs_get_new,get_hoa_videos} from '../Api'
import { Col, Row,} from 'antd';
import {YoutubeOutlined} from '@ant-design/icons';
import './Home.scss';
import './HomeMediaQuery.scss'
import arrow from '../../../Images/homePage/arrow.png'
import { NavLink} from "react-router-dom";

// const Cards = ({ image, title, brandLogo, brandName, date }) => {
//     return (
      
//     );
//   };


const Home = () => {
    const myRef = useRef(null)
    const [loading,setLoading] = useState(false)
    const [testimonialList, setTestimonialList] = useState({})
    const [bloglist, setBloglist] = useState({})
    const [videolist, setVideolist] = useState({})
    const [vedio,setVedio] = useState(false)
    const itemsPerPage = 3; // Number of items per page
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageVideo, setCurrentPageVideo] = useState(0);
    const [currentTest, setCurrentTest] = useState(0);
    const [quizPgHead, setQuizPgHead] = useState('');

    const getTestimonialList=()=>{
        testimonial_get().then(res => {
            console.log('testimonials',res);
            try{
                if (res.status==='successfull'){setTestimonialList(res.result)}
                else{}
            }catch{}
            })
    }

// ........Blog API...................//
    const getBlogList=()=>{
        hoa_blogs_get_new().then(res => {
            try{
                if (res.status==='successfull') {setBloglist(res.result)}
                else{}
            }catch{}
         })
    }

// .........Blog pagination code........ //

const startIndex = currentPage * itemsPerPage;
const endIndex = startIndex + itemsPerPage;
const currentBlog = Object.values(bloglist).slice(startIndex, endIndex);

const handlePrevPage = () => {
    setCurrentPage(Math.max(0, currentPage - 1));
};

const handleNextPage = () => {
    setCurrentPage(Math.min(Math.ceil(Object.keys(bloglist).length / itemsPerPage) - 1, currentPage + 1));
};

// ........video API...................//
    const getVideoList=()=>{
        get_hoa_videos().then(res => {
            try{
                if (res.status==='successfull') {setVideolist(res.result)}
                else{}
            }catch{}
         })
    }

// .........Video pagination code........ //

const startVideoIndex = currentPage * itemsPerPage;
const endVideoIndex = startIndex + itemsPerPage;
const currentVideo = Object.values(videolist).slice(startVideoIndex, endVideoIndex);

const handlePrevVideo = () => {
    setCurrentPageVideo(Math.max(0, currentVideo - 1));
};

const handleNextVideo = () => {
    setCurrentPageVideo(Math.min(Math.ceil(Object.keys(videolist).length / itemsPerPage) - 1, currentVideo + 1));
};

    const handleVedio=()=>{
        setVedio(true)
    }

//............Testimonial Pagination Function........//
    const itemsPerTestimonial = 1;
    const startIndexTest = currentTest * itemsPerTestimonial;
    const endIndexTest = startIndexTest + itemsPerTestimonial;
    const currentTestimonial = Object.values(testimonialList).slice(startIndexTest, endIndexTest);

    const handlePrevTest = () => {
        setCurrentTest(Math.max(0, currentTest - 1));
    };
    
    const handleNextTest = () => {
        setCurrentTest(Math.min(Math.ceil(Object.keys(testimonialList).length / itemsPerTestimonial) - 1, currentTest + 1));
    };

    const quizPg = (val)=>{setQuizPgHead(val)}


    useEffect(() => {
        if(window.location.hash==='#testimonials'){myRef.current.scrollIntoView()}
        getTestimonialList();
        getBlogList();
        getVideoList();
        setLoading(true)
        setTimeout(() =>{
                setLoading(false)
            }, 1000);

        
        
    },[])

    return (
    <>
        <Navbar/>
        <a href='https://calendly.com/bhuvanaa/exploratory-call-hoa?month=2023-09' target='blank' >
            <button className='fixedBtn'>Book your exploratory call now </button>
        </a>
       
        <div className="h-[100vh] overflow-hidden">
              {/* <div className="bg-fixed homeBanner bg-cover bg-no-repeat bg-center h-[100vh]"> */}
            <div className="homeBanner bg-cover bg-no-repeat bg-center h-[100vh] brightness-50 animate-[zoom-in-zoom-out_25s_ease-in-out_infinite]"></div>
            <div className="headFont italic font-semibold absolute left-14 p-4 bannerTxt "> 
            Your Journey Towards <br/>
            <HomeBannerText/><br/>
            Starts Here ...
            </div>
        </div>
        {/* <div className="bg-themeColor2 py-5">
            <div className='banBox'>
                <span className='headFont text-3xl '>Are You ? </span>
                <Link href={`/financial_freedom_score_quiz/salaried_professional`} target='blank' >
                    <button className="bannnerButtonAct">
                    Salaried Professional
                </button>
                </Link>
                <Link href={`/financial_freedom_score_quiz/business_owner`} target='blank' >
                    <button className="bannnerButtonAct">
                    Business Owner
                </button>
                </Link>
            </div>
            <div className='banrBtnTxt'>
                    Take this Financial Freedom Score Quiz
            </div>
        </div> */}
        {/* ----------------------------------blog---------------------------------- */}
        <section className='paddingHome border border-customColor'>
            {/* <div className='p-4 shadow shadowHover' > */}
            <div className="capitalize text-themeColor headFont lg:text-3xl leading-relaxed font-medium text-center mt-2 Hhead">Latest News, Updates, Videos and Blogs</div>
            <div>
                <div>
                    <div className="flex justify-between items-center" >
                        {/* <div className='p-2 text-center'>
                            <button className="bg-themeColor text-white border text-3xl rounded-full px-4 py-2" onClick={handlePrevPage} disabled={currentPage === 0}>&lt;</button>
                        </div> */}
                    <div className="gap grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 " data-aos="fade-up">
    
                    {currentBlog.map((item, index) => (
                        <a key={index} href={`/blog/${item.url}`} target="_blank" rel='noreferrer' style={{ textDecoration: 'none' }}>
                        <div className="pb-2 relative shadow-lg shadow-transparent shadowHover border-black rounded-lg overflow-hidden bg-customColor flex flex-col justify-between h-full text-black">
                            <div className="">
                            <img src={'data:image/png;base64,' + item.image} alt="one" className="w-full h-48 rounded-t-lg" />
                            </div>
                            <div className="flex justify-between items-center p-2">
                            <div className="font-normal flex items-center max-h-15 overflow-hidden ">{item.title}</div>
                            </div>
                            <div className="flex justify-between items-center p-2 border-t">
                            <div className="flex items-center">
                                <img src={logosmall} alt="Logo" className="w-8 h-8 mr-2 rounded-full" />
                                <span className='text-sm'>House of Alpha</span>
                            </div>
                            <div className="text-gray-200 text-sm">{item.date}</div>
                            </div>
                        </div>
                        </a>
                    ))
                    }

                    </div>
                    {/* <div className='p-2 text-center'>
                        <button className="bg-themeColor text-white border text-3xl rounded-full px-4 py-2" onClick={handleNextPage} disabled={endIndex >= Object.keys(bloglist).length}>&gt;</button>
                    </div> */}
                </div>
                
            </div>

            <div>
                    <div className="mt-8 flex justify-between items-center" >
                        {/* <div className='p-2 text-center'>
                            <button className="bg-themeColor text-white border text-3xl rounded-full px-4 py-2 disabled" onClick={handlePrevPage} disabled={currentPage === 0}>&lt;</button>
                        </div> */}
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 " data-aos="fade-up">
    
                    {
                    currentVideo.map((item, index) => (
                        <a key={index} href={`/video/${item.url}`} target="_blank" rel='noreferrer'style={{ textDecoration: 'none' }}>
                        <div className="pb-2 relative shadow-lg shadow-transparent shadowHover border-black rounded-lg overflow-hidden bg-customColor flex flex-col justify-between h-full text-black">
                            <div className="">
                            <iframe src={item.link} 
                                            title="YouTube video player" 
                                            frameborder="0" 
                                            width="100%"  // Set the width to 100% to make it responsive
                                            height="300"
                                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" controls allowfullscreen='true' className="rounded-t-lg" ></iframe>
                            </div>
                            <div className="flex justify-between items-center p-2">
                            <div className="font-normal flex items-center max-h-15 overflow-hidden">{item.title}</div>
                            </div>
                            <div className="flex justify-between items-center p-2 border-t">
                            <div className="flex items-center">
                                <img src={logosmall} alt="Logo" className="w-8 h-8 mr-2 rounded-full" />
                                <span className='text-sm'>House of Alpha</span>
                            </div>
                            <div className="text-gray-200 text-sm">{item.date}</div>
                            </div>
                        </div>
                        </a>
                    ))
                    }

                    </div>
                    {/* <div className='p-2 text-center'>
                        <button className="bg-themeColor text-white border text-3xl rounded-full px-4 py-2" onClick={handleNextPage} disabled={endIndex >= Object.keys(bloglist).length}>&gt;</button>
                    </div> */}
                </div>
                
            </div>
            </div>
            <div className="py-4 mt-7 flex justify-center">
                <a href='/blog' className='no-underline'> 
                    <div className="bg-yellow text-center cursor-pointer shadow-lg shadowHover ">
                        <span className="text-themeColor text-base headFont leading-10 capitalize font-medium ml-4  ">Our Resources</span>
                        <div className="WebBtnBrdr"></div>
                    </div>
                </a>
            </div>
            {/* </div> */}
        </section>

        
        {/* ------------------------------what we do sec------------------------- */}
        <Row className='bg-themeColor2 paddingHome'>
            <div className='mobile'>
            <Col xs={24} md={12} >
            
                    {vedio?
                    <iframe src='https://www.youtube.com/embed/AFyHf65xEwE?autoplay=1'frameborder='0' autoplay={1} allow='autoplay' allowfullscreen title='video'className="w-full aspect-video"/>
                    :<> 
                    <div>
                    <img src={home2} alt="" fill
                    // sizes="(max-width: 768px) 100vw,
                    // (max-width: 1200px) 50vw,
                    // 33vw"
                    data-aos="fade-left"
                    className='zoomEff'
                     /> 
                     </div>
                    <YoutubeOutlined  className="text-3xl absolute bottom-8 left-2 text-white" onClick={handleVedio}/></> }
            </Col>
            </div>
            <Col xs={24} md={12} data-aos="fade-right" data-aos-easing="ease-in-sine">
                <div className="capitalize text-themeColor headFont text-3xl font-bold leading-relaxed mb-2 gap2">to us, you are the client. <br/>your money is incidental
                </div>
                <div className="text-themeColor textFont text-base pr-4">
                    <p className='md:leading-7 min-[320px]:leading-3'>We believe that there are no rehearsals in life.</p> 
                    <p className='md:leading-7 min-[320px]:leading-3'>You live once and you deserve to live it well.</p>
                    <p className='md:leading-7 min-[320px]:leading-3'>Our job is to help you live the best life you can with the money you have.</p>
                </div>
                <div className="my-7 flex">
                    <a href='what_we_do' className='no-underline'>
                    <div className="bg-yellow text-center cursor-pointer shadow-lg shadowHover ">
                        <span className="text-themeColor text-base headFont leading-10 capitalize font-medium ml-4  ">what we do</span>
                        <div className="WebBtnBrdr"></div>
                    </div>
                    </a>
                </div>
            </Col>
            <Col xs={24} md={12} >
                <div className='desktop'>
                    {vedio?
                    <iframe src='https://www.youtube.com/embed/AFyHf65xEwE?autoplay=1'frameborder='0' autoplay={1} allow='autoplay' allowfullscreen title='video'className="w-full aspect-video"/>
                    :<> 
                    <div>
                    <img src={home2} alt="" fill
                    // sizes="(max-width: 768px) 100vw,
                    // (max-width: 1200px) 50vw,
                    // 33vw"
                    data-aos="fade-left"
                    className='zoomEff'
                     /> 
                     </div>
                    <YoutubeOutlined  className="text-3xl absolute bottom-8 left-2 text-white" onClick={handleVedio}/></> }
                </div>
            </Col>
        </Row>
{/* ----------------------------------How we are different Web----------------------- */}
    <div className="paddingHome mobile" >
        <div className="capitalize text-themeColor headFont text-3xl leading-relaxed font-medium text-center Hhead">How we are different</div>
        <div className='processImg'><img src={process} alt='process'/></div>
    </div>
{/* ----------------------------------How we are different Web----------------------- */}
    <div className="paddingHome desktop" >
        <div className="capitalize text-themeColor headFont text-3xl leading-relaxed font-medium text-center mb-5 ">How we are different</div>
        <Row justify="center" align="middle">
            <Col span={3}>
                <div className='diffLft'>Executive Plan Tracking Process</div>
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
                <div className='diffBox zoomEff'>
                    <p className='diffBoxNum'>7</p>
                    <p className='diffBoxName'>Implement Plan</p>
                </div>
            </Col>
            <Col span={2}>
                <div className='flex justify-center'><img src={arrow} alt="Arrow" className="diffArw"/></div>
            </Col>
            <Col span={4}>
                <div className='diffBoxDrk zoomEff'>
                    <p className='diffBoxNum'>8</p>
                    <p className='diffBoxName'>Track & course correct</p></div>
            </Col>
            <Col span={2}>
                <div className='flex justify-center'><img src={arrow} alt="Arrow" className="diffArw"/></div>
            </Col>
            <Col span={4}>
                <div className='diffBox zoomEff'>
                <p className='diffBoxNum'>9</p>
                <p className='diffBoxName'>Hit milestones & celebrate</p></div>
            </Col>
            <Col span={1}></Col>
            <Col span={3}>
                <div className='diffRgt'>Is the money doing what is it supposed to do?</div>
            </Col>
        </Row>
        <Row>
            <Col span={5}></Col>
            <Col span={2}>
            <div className='flex justify-center'><img src={arrow} alt="Arrow" className="diffArwV"/></div>
            </Col>
            <Col span={16}></Col>
        </Row>
        <Row justify="center" align="middle">
            <Col span={3}>
                <div className='diffLft'>Investment Planning</div>
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
                <div className='diffBoxDrk zoomEff'>
                    <p className='diffBoxNum'>6</p>
                    <p className='diffBoxName'>Timing of entry & exit</p></div>
            </Col>
            <Col span={2}>
                <div className='flex justify-center'><img src={arrow} alt="Arrow" className="diffArwR"/></div>
            </Col>
            <Col span={4}>
                <div className='diffBox zoomEff'>
                    <p className='diffBoxNum'>5</p>
                    <p className='diffBoxName'>Product selection</p></div>
            </Col>
            <Col span={2}>
                <div className='flex justify-center'><img src={arrow} alt="Arrow" className="diffArwR"/></div>
            </Col>
            <Col span={4}>
                <div className='diffBoxDrk zoomEff'>
                    <p className='diffBoxNum'>4</p>
                    <p className='diffBoxName'>Asset choices</p></div>
            </Col>
            <Col span={1}></Col>
            <Col span={3}>
                <div className='diffRgt'>What to do with the money?</div>
            </Col>
        </Row>
        <Row>
            <Col span={17}></Col>
            <Col span={2}>
            <div className='flex justify-center'><img src={arrow} alt="Arrow" className="diffArwV"/></div>
            </Col>
        </Row>
        <Row justify="center" align="middle">
            <Col span={3}>
                <div className='diffLft'>Lifestyle Financial Planning</div>
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
                <div className='diffBox zoomEff'>
                    <p className='diffBoxNum'>1</p>
                    <p className='diffBoxName'>Journey so far</p></div>
            </Col>
            <Col span={2}>
                <div className='flex justify-center'><img src={arrow} alt="Arrow" className="diffArw"/></div>
            </Col>
            <Col span={4}>
                <div className='diffBoxDrk zoomEff'>
                    <p className='diffBoxNum'>2</p>
                    <p className='diffBoxName'>Where are you now?</p></div>
            </Col>
            <Col span={2}>
                <div className='flex justify-center'><img src={arrow} alt="Arrow" className="diffArw"/></div>
            </Col>
            <Col span={4}>
                <div className='diffBox zoomEff'>
                    <p className='diffBoxNum'>3</p>
                    <p className='diffBoxName'>Where do you want to go?</p></div>
            </Col>
            <Col span={1}></Col>
            <Col span={3}>
                <div className='diffRgt'>what you want your money to do?</div>
            </Col>
        </Row>
        
    </div>
{/* ...................................Footer Contact Section.................................. */}
        <section >
            <FooterContact />
        </section>
{/* ...................................Testimonial Section.................................. */}

        <section className=' bg-themeColor2 paddingHome'>
        <div className="capitalize text-themeColor headFont text-2xl text-center mobile Hhead">What Our Clients Say</div>
            <div className='flex flex-row justify-center items-center w-full h-full'>
                <p className='desktop'><div className='flex w-1/2 text-themeColor headFont text-3xl font-bold leading-relaxed text-center'>What Our Clients Say </div></p>
                <div className='flex w-full bg-white border shadow-lg rounded-lg'>
                <div className="flex justify-between items-center mx-4 ">

                        <div className=''>
                            <button className="" onClick={handlePrevTest} disabled={currentTest === 0} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                </svg>
                            </button>
                        </div>
                
                {currentTestimonial.map((item, index) => (
                    <div key={index} className="flex flex-col p-2 text-center w-full h-auto md:h-96">
                    <div className="p-2 flex flex-col items-center">
                        <div className="bg-gray-300 w-16 md:w-20 h-16 md:h-20 rounded-full border">
                            {/* Add your display picture here */}
                            <img
                                src={'data:image/png;base64,' + item.image}
                                alt="Profile"
                                className="w-20 h-20 rounded-full object-cover"
                            />
                        </div>
                        <div className="text-md font-semibold text-gray-800 mt-2">{item.name}</div>
                        <div className="text-sm txt text-gray-500">{item.position}</div>
                        <div className="text-xs txt text-gray-700 my-3">{item.statement}</div>
                    </div>
                </div>
                
                
                ))}

                <div className=''>
                    <button className="" onClick={handleNextTest} disabled={endIndex >= Object.keys(testimonialList).length} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                        </svg>
                    </button>
                </div>
                </div>   
                </div>

            </div>

        </section>
        

            <section>
                <div className='teamPad'>
                    <div className='text-themeColor headFont text-3xl leading-relaxed font-medium text-center mb-5 Hhead'> Meet Our Team</div>
                    <div>
                        <Row gutter={[5, 5]} className='text-center'>
                            <Col xs={24} md={7} >
                                <div className='shadowHover mobile flex justify-center'>
                                <img
                                src={Bhuvanaa}
                                className='TeamP mobile'
                                alt="author"
                                />
                                </div>
                            <div className='text-center md:text-left'> 
                                <span className='text-orange text-2xl font-bold headFont'>Bhuvanaa</span>
                                <span className='text-2xl text-orange headFont'> Shreeram</span>
                                <div className='textPosition textFont text-grey'>Co-Founder & Head - Financial Planning</div>
                                <div className='text-sm textFont text-justify mt-2'>MBA in Finance, Certified Financial Planner (CFP CM), Cotified Life Coach, Experience in broking, research, client servicing in financial services since 2004.</div>
                            </div>
                            </Col>
                            <Col span={1}></Col>
                            <Col xs={24} md={7}>
                          
                                <div className='shadowHover mobile flex justify-center'>
                                <img
                                src={Harish}
                                className='TeamP mobile'
                                alt="author"
                                />
                                </div>
                           
                            <div className='text-center md:text-left'> 
                                <span className='text-orange text-2xl font-bold headFont'>Harish</span>
                                <span className='text-2xl text-orange headFont'> Menon</span>
                                <div className='textPosition textFont text-grey'>Co-Founder & Head of Investment Advisory</div>
                                <div className='text-sm textFont text-justify mt-2'>Chartered Accountant (CA) and qualified Chartered Financial Analyst Extensive Experience in research & trading roles with ICICI Securities, Standard Chartered Mutual Fund and ING Vysya Bank since 2004.</div>
                            </div>
                            </Col>
                            <Col span={1}></Col>
                            <Col  xs={24} md={7}>
                           
                                <div className='shadowHover mobile flex justify-center'>
                                <img
                                src={kajal}
                                className='TeamP mobile'
                                alt="author"
                                />
                                </div>
                          
                            <div className='text-center md:text-left'> 
                                <span className='text-orange text-2xl font-bold headFont'>Kajal</span>
                                <span className='text-2xl text-orange headFont'> Singh</span>
                                <div className='textPosition textFont text-grey'>Head of Operations</div>
                                <div className='text-sm textFont text-justify mt-2'>B.Com, Dip in Business Finance and MBA from University of Melbourne Operations experience for 15 years in Indiabulls, Barclays Banks, E&Y.</div>
                            </div>
                            </Col>
                        </Row>
                        <div className='desktop'>
                        <Row gutter={[5, 5]} className='text-center pt-3'>
                        <Col span={6}>
                        <div>
                                {/* <div className='bhSquare  bg-yellowishcream rotate-45 w-21 pb-1/2 h-40  '>
                                </div> */}
                                <div className='shadowHover'>
                                <img
                                src={Bhuvanaa}
                                className=''
                                alt="author"
                                />
                                </div>
                            </div>
                        </Col>
                        <Col span={3}></Col>
                        <Col span={6}>
                        <div>
                               
                                <div className='shadowHover'>
                                <img
                                src={Harish}
                                className=''
                                alt="author"
                                />
                                </div>
                            </div>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={6}>
                            <div>
                                <div className='shadowHover'>
                                <img
                                src={kajal}
                                className=''
                                alt="author"
                                />
                                </div>
                            </div>
                        </Col>
                        </Row>
                        </div>
                    </div>
                </div>
                <div>
                <Row className=' pb-5'>
                    <Col span={14}></Col>
                    <Col span={8}>
                        <div className='flex justify-end'>
                            <a href='/contact_us'>
                                <div className="w-40 bg-yellow text-center cursor-pointer mt-2">
                                    <span className="text-themeColor text-base headFont leading-10 capitalize font-medium ml-4  ">Join Our Team</span>
                                    <div className="WebBtnBrdr"></div>
                                </div>
                            </a>
                        </div>

                       
                    </Col>
                    </Row>
                </div>
                
            </section>
            

            {/* <section>
            <div className='paddingHome'>
                <div className='text-themeColor headFont text-3xl leading-relaxed font-medium text-center mb-5'> Get our Monthly Newsletter</div>
            </div>

            </section> */}

{/* ...................................Footer Section.................................. */}
            
            <div><Footer /></div>
        </>
    )
}
export default Home
import axios from 'axios'


export const respo_team_member_names = input => {
    return axios
        .get('/alpha_crm/respo_team_member_names',
        {
            headers: {
              'Authorization': input.access_token
            },
            params:{
              id:input.customer_id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
export const zerodha_account_open_crm = input => {
    return axios
        .get('/alpha_crm/zerodha_account_open_crm',
        {
            headers: {
              'Authorization': input.access_token
            },
            params:{
              id:input.customer_id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const zerodha_account_open_crm_post = input => {
    return axios
    .post('/alpha_crm/zerodha_account_open_crm', {
        id:585,
        family_member_id:input.family_member,
        account_holder_name:input.account_holder_name,
        respo_team_member_name:input.resp_team_member,
        status:input.status,
        remarks:input.remark,
        reminder:input.reminder,
        attachment:input.attachment
      },
      {
        headers: {
          'Authorization': input.access_token
        }
      })
      .then(response => {
          return response.data
      })
      .catch(err => {})
  }
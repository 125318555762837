import React,{useState,useEffect} from 'react';
import '../../UserManagement.scss'
import {Row,Col,Tabs} from 'antd';
import Orders from './MfOrders';
import Holding from './MfHoldings'
import Sips from './Sips';


const MutualFunds = (props) => {
  const { TabPane } = Tabs;
  const{cusName,customer_id,cusEmail, close, tabName} = props
  const [tabs, setTabs] = useState('Holdings');
  const defaultTab = 'tab1';

  const onChange = (key) => {setTabs(key)};

  return (
    <div>
      <div className='modalHeadBar'>
        <Row align='middle'>
          <Col span={6}>
            <div className='modalHeadBarTitle'>{tabName}</div>
          </Col>
          <Col span={12}>
            <div className='modalHeadBarTab'>
              <Tabs defaultActiveKey={defaultTab} onChange={onChange} size='small' centered={true}>
                <TabPane tab='Holdings' key='Holdings'></TabPane>
                <TabPane tab='Orders' key='Orders'></TabPane>
                <TabPane tab='Sips' key='Sips'></TabPane>
              </Tabs>
            </div>
          </Col>
          <Col span={5}>
            <div className='modalHeadBarTitle'>{cusName}</div>
            <p className='modalHeadBarP'>{cusEmail}</p>
          </Col>
          <Col span={1}>
            <div className='TableActionButtonClose' onClick={close}>Close</div>
          </Col>
        </Row>
      </div>

      <div className='modalBodyBar'>
        {tabs==='Sips'?<Sips customer_id={customer_id} cusName={cusName} cusEmail={cusEmail}/>:null}
        {tabs==='Orders'?<Orders customer_id={customer_id} cusName={cusName} cusEmail={cusEmail}/>:null}
        {tabs==='Holdings'?<Holding customer_id={customer_id} cusName={cusName} cusEmail={cusEmail}/>:null}
      </div>
    </div>
  )
}

export default MutualFunds
import React, { useState, useEffect } from 'react';
import '../Tabs.scss'
import ReactApexChart from "react-apexcharts";

const ColumnChart = (props) => {

    const numberFormat=(value)=>{
      var val = Math.abs(value)
      if (val >= 10000000) {
        val = (val / 10000000).toFixed(2) + ' Cr';
      } else if (val >= 100000) {
        val = (val / 100000).toFixed(2) + ' L';
      }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
      return val;
    }

const series = [{
  name: 'Income',
  data: props.cashFlowData.income
}, {
  name: 'Expense',
  data: props.cashFlowData.expenses
}]
const options = {
  chart: {
    type: 'bar',
    toolbar: {show: false,}
  },
  legend:{
    position: 'top',
    horizontalAlign: 'right',
  },
  colors:props.color,
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {show: false},
  xaxis: {
    categories: props.cashFlowData.age,
    tickAmount:(props.cashFlowData.age).length/2
  },
  yaxis: {
    labels:{
      formatter: function(val, index) {
        return numberFormat(val);
      }
    }
  },
  fill: {
    opacity: 1,
    
  },
  tooltip: {
    shared:true,
    intersect:false
  }
}
  return (
    <ReactApexChart options={options} series={series} type="bar" height={'100%'}/>
  )
}

export default React.memo(ColumnChart)
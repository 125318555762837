// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Images/faqbannerMobile.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .faqBannerImage {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: 35vh;
  }
  .mapBg {
    padding: 20px 30px 20px 30px;
  }
  .questionarrow {
    font-size: 16px;
  }
  .questionText {
    font-size: 13px;
  }
  .answerText {
    font-size: 12px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 991px) {
  .faqBannerImage {
    height: 37vh;
  }
  .mapBg {
    padding: 20px 30px 20px 30px;
  }
  .questionarrow {
    font-size: 16px;
  }
  .questionText {
    font-size: 13px;
  }
  .answerText {
    font-size: 12px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1180px) {
  .faqBannerImage {
    height: 37vh;
  }
  .mapBg {
    padding: 20px 80px 20px 80px;
  }
  .questionarrow {
    font-size: 20px;
  }
  .questionText {
    font-size: 18px;
  }
  .answerText {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1700px) {
  .faqBannerImage {
    height: 40vh;
  }
  .mapBg {
    padding: 50px 250px 50px 250px;
  }
  .questionarrow {
    font-size: 38px;
  }
  .questionText {
    font-size: 26px;
  }
  .answerText {
    font-size: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Component/Website/faq/FaqMedia.scss"],"names":[],"mappings":"AAAA;EACA;IACI,yDAAA;IACA,YAAA;EACF;EACF;IAAO,4BAAA;EAEL;EADF;IAAe,eAAA;EAIb;EAHF;IAAc,eAAA;EAMZ;EALF;IAAY,eAAA;EAQV;AACF;AAPA;EACI;IAAgB,YAAA;EAUlB;EATE;IAAO,4BAAA;EAYT;EAXE;IAAe,eAAA;EAcjB;EAbE;IAAc,eAAA;EAgBhB;EAfE;IAAY,eAAA;EAkBd;AACF;AAjBA;EACI;IAAgB,YAAA;EAoBlB;EAnBE;IAAO,4BAAA;EAsBT;EArBE;IAAe,eAAA;EAwBjB;EAvBE;IAAc,eAAA;EA0BhB;EAzBE;IAAY,eAAA;EA4Bd;AACF;AA3BA;EACI;IAAgB,YAAA;EA8BlB;EA7BE;IAAO,8BAAA;EAgCT;EA/BE;IAAe,eAAA;EAkCjB;EAjCE;IAAc,eAAA;EAoChB;EAnCE;IAAY,eAAA;EAsCd;AACF","sourcesContent":["@media only screen and (max-width: 600px) {\n.faqBannerImage{\n    background-image: url(\"../../../Images/faqbannerMobile.jpg\");\n    height: 35vh;\n}\n.mapBg{padding:20px 30px 20px 30px;}\n.questionarrow{font-size: 16px;}\n.questionText{font-size: 13px;}\n.answerText{font-size: 12px;}\n}\n@media only screen and (min-width: 601px) and (max-width: 991px) {\n    .faqBannerImage{height: 37vh;}\n    .mapBg{padding:20px 30px 20px 30px;}\n    .questionarrow{font-size: 16px;}\n    .questionText{font-size: 13px;}\n    .answerText{font-size: 12px;} \n}\n@media only screen and (min-width: 992px) and (max-width: 1180px) { \n    .faqBannerImage{height: 37vh;}\n    .mapBg{padding:20px 80px 20px 80px;}\n    .questionarrow{font-size: 20px;}\n    .questionText{font-size: 18px;}\n    .answerText{font-size: 15px;} \n}\n@media only screen and (min-width: 1700px) { \n    .faqBannerImage{height: 40vh}\n    .mapBg{padding:50px 250px 50px 250px;}\n    .questionarrow{font-size: 38px;}\n    .questionText{font-size: 26px;}\n    .answerText{font-size: 22px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React,{useState,useEffect} from 'react';
import {master_transcation} from '../userManagement/Api'
import {customer_review} from '../Api'
import { Table } from "ant-table-extensions";
import { DatePicker,Select} from 'antd';
import {MDBIcon} from 'mdbreact'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../shared/Loader';

import moment from 'moment'

const Master_tran = (props) => {
  const [data, setData] = useState([]);
  const [cdata,setcData] = useState([])
  const [pickerValue, setPickerValue] = useState(moment(new Date()));
  const [dateSend, setdateSend] = useState([])
  const[clientId,setClientId] = useState('')
  const[check,setCheck] = useState(false)
  const [loading,setLoading] = useState(false)

  const toastMsg =(id,msg,type)=>{
    toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
  }

  const { RangePicker } = DatePicker;
  
  const { Option } = Select;

  const numberFormat=(value)=>
    new Intl.NumberFormat('en-IN', {
      // style: 'currency',
      // currency: 'INR',
      minimumFractionDigits:0
  }).format(value);
 
  const updateclientID=(value)=>{
    setClientId(value)
}

  const fetchData=(flag)=>{
    let newdata = []
    const input = {
        access_token:localStorage.token,
        customer_id:clientId,
        start_date:dateSend[0],
        end_date:dateSend[1],
        flag:flag
    }
    
    // console.log(input);
    master_transcation(input).then(res => {
        // console.log('master',res);
        
        try{
        if(res.status==='successfull'){
          if (res.message==='Transactions Email sent successfully'){
            setLoading(false)
            setCheck(true)
            toast.success(res.message)
          }
          
          if(res.message==='Failed while sending Email'){
            
            toast.error(res.message)
            setCheck(false)
          }
          if (res.message==='data sent successfully'){
            setLoading(false)
            // toast.success('Data sent successfully')
            console.log(res);
            Object.keys(res.result).map((val)=>
            Object.keys(res.result[val]).map((val2)=>
              Object.keys(res.result[val][val2]).map((val3)=>{
                // console.log(val2); 
                newdata.push({ 
                  key: val2,
                  asset_type:res.result[val][val2][val3].asset_type,
                  fund:val2,
                  client_name:val,
                  transaction_date:res.result[val][val2][val3].date,
                  isin:res.result[val][val2][val3].isin,
                  transaction_type:res.result[val][val2][val3].transaction_type,
                  unit_price:res.result[val][val2][val3].average_price,
                  qty:res.result[val][val2][val3].quantity,
                  amount:res.result[val][val2][val3].asset_type,
                  balance_unit:res.result[val][val2][val3].balance_unit,
                  current_value:res.result[val][val2][val3].current_value,
                  invested_total:res.result[val][val2][val3].invested_value,
                })
              })
            )  
          ) 
          }
        }else{
          toast.error(res.message)
          console.log('something went wromg');
        }

        
        
        setData(newdata)
      }catch{toast.error('Something went wrong')}
      })
  }
  console.log(data);

  const fetchClient = ()=>{
      const input = {
          access_token:localStorage.token
      }
      let newdata = [...cdata]
      customer_review(input).then(res => {
          console.log(res,'ressCLI')
      try{
          if (res.status==='successfull'){
              Object.keys(res.result).map((val)=>{
                  newdata.push({ 
                  key: res.result[val].customer_id,
                  id: res.result[val].customer_id,
                  name:res.result[val].customer_fullname,
                  email_address:res.result[val].email_address,
                  zerodha:res.result[val].zerodha
              })
              })
              setcData(newdata)
          }else{}
      }catch{}
      })
  }
  useEffect(()=>{
    fetchClient()
  },[])
  const submitData =()=>{
    fetchData('N')
    setLoading(true)
  }
  const onChangeDate = (date, dateString) => {
    setdateSend(dateString)
  };
  const sendEmail = ( ) => {
    fetchData('Y')
    toast.success('Data will be sent to email within 30 minutes.')
  };

  

  const columns = [
    {title: 'Client Name/Id',
    dataIndex: 'client_name',
    // filters: [
    //   {
    //     text: 'London',
    //     value: 'London',
    //   },
    //   {
    //     text: 'New York',
    //     value: 'New York',
    //   },
    // ],
    // onFilter: (value, record) => record.client_name.indexOf(value) === 0,
  },

    {title: 'Date',dataIndex: 'transaction_date'},
    {title: 'Fund',dataIndex: 'fund'},
    {title: 'ISIN',dataIndex: 'isin'},
    {title: 'Asset Type',dataIndex: 'asset_type',render: val => { return val? val:'-'}},
    {title: 'Action',dataIndex: 'transaction_type'},
    {title: 'Qty',dataIndex: 'qty'},
    {title: 'Unit Price',dataIndex: 'unit_price'},
    {title: 'Invested Value',dataIndex: 'invested_total',render: total => { return numberFormat(total)}},
    {title: 'Current Value',dataIndex: 'current_value',render: total => { return numberFormat(total)}},
    {title: 'Balance Unit',dataIndex: 'balance_unit',render: total => { return numberFormat(total)}},
  ];

  return(
    <>
    <ToastContainer theme='colored' limit={1}/>
    {loading?<Loader/>:null}
    <div className='row'style={{marginBottom:'15px'}}>
      <div className='col-10 dateCrd'>
        <Select
          style={{width:'35%'}}
          showSearch
          bordered={true}
          title='Client Mail ID'
          className='selectborder'
          placeholder="Select Client Mail ID"
          optionFilterProp="children"
          onChange={updateclientID}
          filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
          // size='large'
          >
          {Object.keys(cdata).map((val)=>{
              return <Option value={cdata[val].id}>{cdata[val].email_address}, <b>{cdata[val].name}</b></Option>
              })}
          </Select>

          <span style={{marginLeft:'10px'}}>
          <RangePicker 
              onChange={onChangeDate} allowClear={true}
              defaultValue={[pickerValue, pickerValue]} 
          />
            <button className='reportBtn' onClick={submitData}>View On Screen</button>
        </span>
      </div>
      <div className='col-2 dateCrd' >
        <span><button className='reportBtnEm' onClick={sendEmail}>Email All Data</button></span>
        <span>{check?<MDBIcon fas icon="check" style={{marginLeft:'6px', color:'green'}}/>:null}</span>
      </div>
    </div>
       
      <Table
        columns={columns}
        pagination={false}
        rowClassName="antTable"
        dataSource={data}
        sticky={true}
        size='small'
        scroll={{y: 350}} 
        exportableProps={{ fileName: `MFHoldings_${pickerValue}`}}
    />

    </>
  )
}
// 

export default Master_tran
import React,{useState} from 'react'
import {contact_hoa} from '../Api'
import './Universal.scss'
import { Col, Row, Modal} from 'antd';

const FooterContact = (props) => {

    const [name,setName] = useState('')
    const [surname,setSurName] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [contactMessage,setContactMessage] = useState('')
    const [loading,setLoading] = useState(false)

    const contact_us_send = async (e) =>{
        e.preventDefault()
        setLoading(true)
        const input = {
          name: name,
          surname: surname,
          email: email,
          phone_number: phone,
          message: contactMessage,
        }
        console.log(input)
        const res = await contact_hoa(input)
        console.log('footer',res);
        try{
          if (res.status==='successfull') {
            setLoading(false)
            e.target.reset();
          }
          else{setLoading(false)}
        }catch{setLoading(false)}

      }

    const onChangeName=(e)=>{setName(e.target.value)}
    const onChangeSurName=(e)=>{setSurName(e.target.value)}
    const onChangePhone=(e)=>{setPhone(e.target.value)}
    const onChangeMessage=(e)=>{setContactMessage(e.target.value)}
    const handlechangeemail=(e)=>{
        const validEmailRegex =RegExp(/^(([^<>()[\].,;:#*\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);
        let validate= validEmailRegex.test(e.target.value)
        if(validate===true){
                setEmail(e.target.value)}
        else{}
    }
    
    return (
        <div className='bg-themeColor paddingHome'>
            <Row gutter={20}>
                <Col span={4}></Col> 
                <Col xs={24} md={16}> 
                    <div className=' capitalize headFont text-3xl leading-relaxed font-medium text-center text-darkYellow marg'>Get our Monthly Newsletter</div>
                    <form onSubmit={contact_us_send} className='footerForm'>
                        <Row gutter={20}>
                            <Col span={12}>
                                <input type='text' className='capitalize' placeholder=' Your Name' name='name' onChange={onChangeName} required/>
                            </Col>
                            <Col span={12}>
                                <input type='text' className='capitalize' placeholder=' Your Surname' name='surname' onChange={onChangeSurName} required/>
                            </Col>
                            <Col span={12}>
                                <input type='number' placeholder='  Phone Number' name='phone' onChange={onChangePhone} required/>
                            </Col>
                            <Col span={12}>
                                <input type='text' placeholder='  Email' name='email' onChange={handlechangeemail} required/>
                            </Col>
                            {/* <Col span={24}>
                                <textarea rows="3" maxLength={500} name='contactMessage'  placeholder='  Your message' onChange={onChangeMessage} required></textarea>
                            </Col> */}
                        </Row>
                        <div className="flex justify-end">
                            <div className="bg-yellow h-10 w-32 relative text-center cursor-pointer mt-4">
                                <span className="text-themeColor text-base headFont leading-10 capitalize font-medium ml-4"><button type='submit'>Subscribe</button></span>
                                <div className="WebBtnBrdr"></div>
                            </div>
                        </div>
                    </form>
                </Col>
            </Row>   
        </div>
    )
}
export default FooterContact
import React, {PureComponent } from 'react'
import {MDBIcon} from 'mdbreact'
import Moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './Compliance.scss'
import './ComplianceMediaQuery.scss'
import Loader from '../../shared/Loader';
// import {Form, Input,Select,Option} from 'antd';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import{user_info,get_user_info,get_spouse_details, save_spouse_details,update_spouse_details,delete_spouse_detail} from './Api'

const toastMsg =(ids,msg,type)=>{
    toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
}  

export default class UserInfo extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            customer_firstname:'',
            customer_middlename:'',
            customer_lastname:'',
            mobile_number:'',
            home_number:'',
            current_job:'',
            current_employer_name:'',
            year_of_experience:'',
            mobile_validate:true,
            contact_no_validate:true,
            first_valid:true,
            middel_valid:true,
            last_valid:true,
            loading:false,
            disabled:false,
            non_editable:false,
            error:false,
            success:false,
            warning:false,
            spouse_flag:null,
            Ncheck:false,
            Ycheck:false,
            helpModal:false,

            firstname:'',
            middlename:'',
            lastname:'',
            spouse_mobile_number:null,
            spouse_home_number:'',
            s_contact_no_validate:true,
            s_current_job:'',
            s_current_employer_name:'',
            s_year_of_experience:'',
            s_pan_number:'',
            s_aadhar_number:null,
            s_main_source_income :'',
            s_mobile_validate:true,
            s_first_valid:true,
            s_middel_valid:true,
            s_last_valid:true,
            s_pan_vaild:true,
            s_aadhar_number_validate:true,
            s_date_of_birth: new Date(),
            confirm:false,
            ria_edit:false,
            spouse_edit:false,
            spouse_name_edit:false
        }
    }
   
    componentDidMount(){
        if(this.props.ria_form===1){
            this.setState({ria_edit:true})
        }
        if(this.props.ria_form===1 && this.props.spouse_sign===1){
            this.setState({spouse_edit:true})
        }
        
        if (this.props.userInfo===1){
        const input = {
            access_token:localStorage.token,
            id:this.props.id
        }
        get_user_info(input).then(res => {
            console.log('res11',res)
            try{
            if (res.status==='successfull') {
              this.setState(() => ({
                customer_firstname:res.result.customer_firstname,
                customer_middlename:res.result.customer_middlename,
                customer_lastname:res.result.customer_lastname,
                home_number:res.result.home_no,
                current_job:res.result.current_job_title,
                current_employer_name:res.result.current_employer_name,
                year_of_experience:res.result.years_of_experience,
                disabled:true,
                non_editable:true,
                ria_edit:true,
              }))
              if(res.result.mobile_number){
                this.setState({ mobile_number:(res.result.mobile_number).toString(),})
            }
          }
          else{toast.error(res.message)}
          }
          catch{
            this.setState({loading:false})
            toast.error('Something went wrong')
          }
        })
        // const PrefixSelector = (
        //     <Form.Item name="prefix" noStyle>
        //       <Select style={{ width: 70 }}>
        //         <Option value="86">+86</Option>
        //         <Option value="87">+87</Option>
        //       </Select>
        //     </Form.Item>
        //   );
    get_spouse_details(input).then(res => {
        console.log('resspo',res)
        try{
        if (res.status==='successfull') {
          this.setState(() => ({
            firstname:res.result.first_name,
            middlename:res.result.middle_name,
            lastname:res.result.last_name,
            spouse_home_number:res.result.home_number,
            s_current_job:res.result.current_job,
            s_current_employer_name:res.result.current_employer_name,
            s_year_of_experience:res.result.years_of_experience,
            s_date_of_birth:res.result.date_of_birth,
            s_pan_number:res.result.pan_number,
            s_aadhar_number:res.result.aadhar_number,
            s_main_source_income :res.result.main_source_income,
            Ycheck:true,
            spouse_name_edit:true,
            spouse_flag:'Y'
          }))
          if(res.result.mobile_number){
              this.setState({ spouse_mobile_number:(res.result.mobile_number).toString(),})
          }
      }
      else{ this.setState({Ncheck:true,spouse_flag:'N'})}
      }catch{
        this.setState({
            error:true,
            errormsg:"Something went wrong2",
            loading:false
        })
      }
    })
}

    }
    onChangefirstname=(e)=>{
        var words = e.target.value.toLowerCase().split();
        var newWords = words.map((element)=>{
          return element !== "" ?  element[0].toUpperCase() + element.substr(1, element.length) : "";
        })
        const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s][a-zA-Z. ]{2,30}$/)
        let name= validate.test(newWords)
        if(name===true){
            this.setState({
                [e.target.name]: newWords[0],
                first_valid:true
            })
        }
        else{
            this.setState({
              [e.target.name]: newWords[0],
              first_valid:false
            })
        }
      }
    onChangemiddelname=(e)=>{
        var words = e.target.value.toLowerCase().split();
        var newWords = words.map((element)=>{
          return element !== "" ?  element[0].toUpperCase() + element.substr(1, element.length) : "";
        })
            this.setState({
                [e.target.name]:newWords[0],
                middel_valid:true
            })
      }
    onChangelastname=(e)=>{
        var words = e.target.value.toLowerCase().split();
        var newWords = words.map((element)=>{
          return element !== "" ?  element[0].toUpperCase() + element.substr(1, element.length) : "";
        })
        const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s][a-zA-Z. ]{2,30}$/)
        let name= validate.test(newWords)
        if(name===true){
            this.setState({
                [e.target.name]: newWords[0],
                last_valid:true
            })
        }
        else{
            this.setState({
              [e.target.name]: newWords[0],
              last_valid:false
            })
        }
      }
    onChangeContact=(e)=>{
        if(e.target.value.length<=15){
          this.setState({
            home_number: e.target.value,
            contact_no_validate:true})
        }
      else{
        this.setState({
            home_number: e.target.value,
            contact_no_validate:false})
      }
    }
    onChangeNumber=(value)=>{
            this.setState({
                mobile_number: value,
            })
            }
           
    //     const validNumber=RegExp(/^[1-9]\d{11}$/g);
    //     let validate= validNumber.test(value)
    //     if(validate===true){
    //       this.setState({
    //         mobile_number: value,
    //         mobile_validate:true})
    //     }
    //   else{
    //     this.setState({
    //       mobile_validate:false})
    //   }
    //   }
    // onChangeNumber=(value)=>{
    //       this.setState({
    //         mobile_number: value})
    //   }
    onChange=(e)=>{
        this.setState({ [e.target.name]: e.target.value })
    }
    submitUserInfo=(e)=>{
        const ids = toast.loading("Please wait...",{toastId:'load'})
        e.preventDefault();
        const input = {
            access_token:localStorage.token,
            id:this.props.id,
            customer_firstname:this.state.customer_firstname,
            customer_middlename:this.state.customer_middlename,
            customer_lastname:this.state.customer_lastname,
            mobile_number:this.state.mobile_number,
            home_number:this.state.home_number,
            current_job:this.state.current_job,
            current_employer_name:this.state.current_employer_name,
            year_of_experience:this.state.year_of_experience,
        }
        const input1 = {
            access_token:localStorage.token,
            id:this.props.id,
            first_name:this.state.firstname,
            middle_name:this.state.middlename,
            last_name:this.state.lastname,
            mobile_number:this.state.spouse_mobile_number,
            home_number:this.state.spouse_home_number,
            current_job:this.state.s_current_job,
            current_employer_name:this.state.s_current_employer_name,
            year_of_experience:this.state.s_year_of_experience,
            date_of_birth: Moment(this.state.s_date_of_birth).format('YYYY-MM-DD') ,
            pan_number:this.state.s_pan_number,
            aadhar_number:this.state.s_aadhar_number,
            main_source_income :this.state.s_main_source_income,
            spouse_flag:this.state.spouse_flag,
        }
       console.log('inputnn',input)
          if(this.props.userInfo===1){
            update_spouse_details(input1).then(res => {
                console.log('resInfoupdate',res)
                try{
                if (res.status==='successfull') {
                        toastMsg(ids,res.message,'success')
                        this.setState({disabled:true})
                }
                else{toastMsg(ids,res.message,'error')}
                }catch{toastMsg(ids,'Something went wrong','error')}
              })

              user_info(input).then(res => {
                console.log('resInfo',res)
                try{
                if (res.status==='successfull') {
                    this.setState({ loading:false})}
                else{
                    this.setState({loading:false})
                    toastMsg(ids,res.message,'error')
                }
                }catch{
                this.setState({loading:false})
                toastMsg(ids,'Something went wrong','error')
              }
            })
        }
        else{
            user_info(input).then(res => {
                console.log('resInfo',res)
                try{
                if (res.status==='successfull') {
                    this.setState({ loading:false})
                    this.props.update();
                    this.props.step2();
                }
                else{
                    this.setState({loading:false})
                    toastMsg(ids,res.message,'error')
                }
                }catch{
                this.setState({loading:false})
                toastMsg(ids,'Something went wrong','error')
              }
            })
    
        save_spouse_details(input1).then(res => {
            console.log('resInfo',res)
            try{
            if (res.status==='successfull') {
                    this.setState({ loading:false})
                    this.props.update();
            }
            else{
                this.setState({loading:false})
                toastMsg(ids,res.message,'error')
            }
            }catch{
            this.setState({loading:false})
            toastMsg(ids,'Something went wrong','error')
          }
          })
        }
    }
    
    
    edit=(e)=>{
        e.preventDefault()
        this.setState({
            disabled:false,
            spouse_name_edit:false
        })
        if( this.props.spouse_sign===1 || this.props.admin===1 && this.props.ria_form===1){
            this.setState({spouse_name_edit:true})
        }
        if(this.props.spouse_sign===0){
            this.setState({ria_edit:false})
        }
        this.help()
    }
    pleaseSave=(e)=>{
        e.preventDefault()
        this.setState({
            warning:true,
        })
    }
    handlesave=()=>{
        this.props.step2();
    }
    onleave=()=>{
        this.setState({
            warning:false,
            error:false,
            success:false,
            confirm:false
        })
      }
spouse_flag=(e)=>{
    this.setState({
        spouse_flag:e.target.value,
        Ycheck:!this.state.Ycheck,
        Ncheck:!this.state.Ncheck
    })
    if(this.state.spouse_flag==='Y'){
        this.setState({})
    }
}
s_onChangefirstname=(e)=>{
    var words = e.target.value.toLowerCase().split();
    var newWords = words.map((element)=>{
      return element !== "" ?  element[0].toUpperCase() + element.substr(1, element.length) : "";
    })
    const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s][a-zA-Z. ]{2,30}$/)
    let name= validate.test(newWords)
    if(name===true){
        this.setState({
            [e.target.name]: newWords[0],
            s_first_valid:true
        })
    }
    else{
        this.setState({
          [e.target.name]: newWords[0],
          s_first_valid:false
        })
    }
  }
s_onChangemiddelname=(e)=>{
    var words = e.target.value.toLowerCase().split();
    var newWords = words.map((element)=>{
      return element !== "" ?  element[0].toUpperCase() + element.substr(1, element.length) : "";
    })
        this.setState({
            [e.target.name]:newWords[0],
            s_middel_valid:true
        })
  }
s_onChangelastname=(e)=>{
    var words = e.target.value.toLowerCase().split();
    var newWords = words.map((element)=>{
      return element !== "" ?  element[0].toUpperCase() + element.substr(1, element.length) : "";
    })
    const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s][a-zA-Z. ]{2,30}$/)
    let name= validate.test(newWords)
    if(name===true){
        this.setState({
            [e.target.name]: newWords[0],
            s_last_valid:true
        })
    }
    else{
        this.setState({
          [e.target.name]: newWords[0],
          s_last_valid:false
        })
    }
  }
// s_onChangeNumber=(value)=>{
//     const validNumber=RegExp(/^[1-9]\d{11}$/g);
//     let validate= validNumber.test(value)
//     if(validate===true){
//       this.setState({
//         spouse_mobile_number: value,
//         s_mobile_validate:true})
//     }
//   else{
//     this.setState({
//       s_mobile_validate:false})
//   }
//   }
s_onChangeNumber=(value)=>{
      this.setState({
        spouse_mobile_number: value,
       })
  }

s_onChangeHomeNumber=(e)=>{
    if(e.target.value.length<=15){
      this.setState({
        spouse_home_number: e.target.value,
        s_contact_no_validate:true})
    }
  else{
    this.setState({
        spouse_home_number: e.target.value,
        s_contact_no_validate:false})
  }
}
s_onChangePanNumber=(e)=>{
    let upper= (e.target.value).toUpperCase();
    const validate=RegExp(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/)
    let name= validate.test(upper)
    if(name===true){
        this.setState({
            [e.target.name]: upper,
            s_pan_vaild:true
        })
    }
    else{
        this.setState({
          [e.target.name]: upper,
          s_pan_vaild:false
        })
    }
  }
s_onChangedate=(date)=>{
    this.setState({
        s_date_of_birth:date
    })
  }
s_onChangeAdhar=(e)=>{
    if(e.target.value.length===12){
      this.setState({
        s_aadhar_number: e.target.value,
        s_aadhar_number_validate:true})
    }
  else{
    this.setState({
        s_aadhar_number: e.target.value,
        s_aadhar_number_validate:false})
  }
}
delete_spouse=()=>{
  this.setState({
      confirm:true
  })
}

help=()=>{
    this.setState({
        helpModal:true
    })
}
closeModaldocument=()=>{
    this.setState({
        helpModal:false
    })
}
onConfirmDelete=()=>{
    const input = {
        access_token:localStorage.token,
        id:this.props.id
    }
    delete_spouse_detail(input).then(res => {
        console.log('resInfo',res)
        try{
        if (res.status==='successfull'){
            this.setState({confirm:false,success:true,successmsg:res.message})
            get_spouse_details(input).then(res => {
                console.log('resspo',res)
                try{
                if (res.status==='successfull') {
                  this.setState(() => ({
                    firstname:res.result.first_name,
                    middlename:res.result.middle_name,
                    lastname:res.result.last_name,
                    spouse_home_number:res.result.home_number,
                    s_current_job:res.result.current_job,
                    s_current_employer_name:res.result.current_employer_name,
                    s_year_of_experience:res.result.years_of_experience,
                    s_date_of_birth:res.result.date_of_birth,
                    s_pan_number:res.result.pan_number,
                    s_aadhar_number:res.result.aadhar_number,
                    s_main_source_income :res.result.main_source_income,
                    Ycheck:true,
                    spouse_flag:'Y'
                  }))
                  if(res.result.mobile_number){
                      this.setState({ spouse_mobile_number:(res.result.mobile_number).toString(),})
                  }
              }
              else{ this.setState({Ncheck:true,spouse_flag:'N'})}
              }catch{
                this.setState({loading:false})
                toast.error('Something went wrong')
              }
            })
        }
        else{
            this.setState({confirm:false})
            toast.error(res.message)
        }
        }catch{
            this.setState({confirm:false})
            toast.error('Something went wrong')
        }
      })
}
  
    render() {
        const {disabled} = this.state
        console.log(this.state.mobile_number);
        // let selected = new Date(this.state.s_date_of_birth)
        let today=Moment(new Date()).format('YYYY-MM-DD')
        if (this.state.loading){
            return(
              <div style={{textAlign:'center',marginTop:100}}>
              <Loader/>
              </div>
              );
          }
        return (
            <>
                <ToastContainer theme='colored' limit={1}/>
              
        {this.state.confirm?(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={this.onConfirmDelete}
                onCancel={this.onleave}
                focusCancelBtn></SweetAlert>
            ):(<span></span>)}

                {this.state.warning?(
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnText="No"
                        cancelBtnBsStyle="danger"
                        onConfirm={this.handlesave}
                        onCancel={this.onleave}
                        focusCancelBtn>Do you want to proceed without save your changes</SweetAlert>):(<span></span>)}
                    {this.state.success?(
                        <SweetAlert
                            success
                            onConfirm={this.onleave}>
                        {this.state.successmsg}
                        </SweetAlert>):(<span></span>)}

                    <form onSubmit={this.submitUserInfo}>
                    <div className='form-heading'>User Info
                        {this.props.userInfo===1?(
                        <span className='formButton'>
                            {disabled?
                            <span><MDBIcon icon="info-circle" onClick={this.help} className='help-modal-icon'></MDBIcon>
                            <button type="button" onClick={this.edit}><MDBIcon far icon="edit" className='form-edt-icon'/>Edit</button></span>:
                            <>
                            {this.state.first_valid && this.state.middel_valid && this.state.last_valid  && this.state.contact_no_validate?
                            <span><MDBIcon icon="info-circle" onClick={this.help} className='help-modal-icon'></MDBIcon>
                            <button type='submit'><MDBIcon far icon="save" className='form-edt-icon'/>Save</button></span>:
                            <button disabled><MDBIcon far icon="save" className='form-edt-icon'/>Save</button>}
                            </>}
                        </span>):null}
                    </div>
                    <div className='formHeight'>
                        <div class="row">
                            <div class="col-md-3 inner-form-gap">
                                <div class="input-field-u">
                                    <input type="text" id="s_first_name" name="customer_firstname" value={this.state.customer_firstname} disabled={this.state.non_editable} onChange={this.onChangefirstname} maxLength={40} required/>
                                    <label for="s_first_name">First Name*</label>
                                    {this.state.first_valid?(<span></span>):(<div className='invalid-msg'>Only letters are allowed</div>)}
                                </div>
                            </div>
                            <div class="col-md-3 inner-form-gap">
                                <div class="input-field-nr-u">
                                    <input type="text" id="customer_middlename" name="customer_middlename" value={this.state.customer_middlename} disabled={this.state.non_editable} onChange={this.onChangemiddelname} maxLength={40} placeholder=' '/>
                                    <label for="customer_middlename">Middle Name</label>
                                </div>
                            </div>
                            <div class="col-md-3 inner-form-gap">
                                <div class="input-field-u">
                                    <input id="customer_lastname" type="text" name="customer_lastname" value={this.state.customer_lastname} disabled={this.state.non_editable} onChange={this.onChangelastname} maxLength={40} required/>
                                    <label for="customer_lastname">Last Name*</label>
                                    {this.state.last_valid?(<span></span>):(<div className='invalid-msg'>Only letters are allowed</div>)}
                                </div>
                            </div>
                            <div class="col-md-3 inner-form-gap">
                                <div className='mobile-no-f'>Mobile Number*</div>
                                <PhoneInput
                                    inputProps={{name:'phone',required: true}}
                                    country={'in'}
                                    countryCodeEditable={false}
                                    value={this.state.mobile_number}
                                    containerStyle={{marginTop:'15px'}}
                                    inputStyle={{background:'transparent',color:'#000',border:'none',outline:'none',borderBottom: '1px solid #000',width:'80%',borderRadius:0}}
                                    buttonStyle={{background:'transparent',border: 'none'}}
                                    dropdownStyle={{background:'#D7DBDD',width:'250px'}}
                                    onChange={this.onChangeNumber}
                                    required
                                />
                                {/* {this.state.mobile_validate?(<span></span>):(
                                <div className='invalid-password'>Mobile number should be 10 digits</div>)} */}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 inner-form-gap">
                                <div class="input-field-nr-u">
                                    <input id="home_number" type="number" name="home_number" value={this.state.home_number} onChange={this.onChangeContact}disabled={this.state.disabled} placeholder=' '/>
                                    <label for="home_number">Home Contact Number</label>
                                    {this.state.contact_no_validate?(<span></span>):(
                                <div className='invalid-password'>Invalid Number</div>)}
                                </div>
                            </div>
                            <div class="col-md-3 inner-form-gap">
                                <div class="input-field-nr-u">
                                    <input id="current_job" type="text" name="current_job" value={this.state.current_job} onChange={this.onChange}maxLength={40} disabled={this.state.disabled} placeholder=' '/>
                                    <label for="current_job">Current Job Title</label>
                                </div>
                            </div>
                            <div class="col-md-3 inner-form-gap">
                                <div class="input-field-nr-u">
                                    <input id="current_employer_name" type="text" name="current_employer_name" value={this.state.current_employer_name} onChange={this.onChange}maxLength={40} disabled={this.state.disabled} placeholder=' '/>
                                    <label for="current_employer_name">Current Employer Name</label>
                                </div>
                            </div>
                            <div class="col-md-3 inner-form-gap">
                                <div class="input-field-nr-u">
                                    <input id="year_of_experience" type="text" name="year_of_experience" value={this.state.year_of_experience} onChange={this.onChange}maxLength={40} disabled={this.state.disabled} placeholder=' '/>
                                    <label for="year_of_experience">Year of Experience</label>
                                </div>
                            </div>
                        </div>

                        <div className='perm-add-box'>
                            <div className='form-heading'>
                                <div className='row'>
                                    <div className='col-md-6 perm-address'>Spouse Details</div>
                                    <div className='col-md-6'>
                                        {this.props.userInfo===1?(
                                        <div>
                                            {this.state.disabled?(
                                                <div className='row'>
                                                    <div className='col-6 radio-button'>
                                                    <span><input type='radio' name='one' value='Y' disabled checked={this.state.Ycheck}/>Yes</span>
                                                    <span><input type='radio' name='one' value='N' disabled checked={this.state.Ncheck}/>No</span>
                                                    </div>
                                                    <div className='col-1'></div>
                                                </div>
                                            ):(
                                                <div className='row'>
                                                <div className='col-6 radio-button'>
                                                    <span><input type='radio' name='one' value='Y' disabled={this.state.spouse_edit}  onChange={this.spouse_flag} checked={this.state.Ycheck}/>Yes</span>
                                                    <span><input type='radio' name='one' value='N' disabled={this.state.spouse_edit} onChange={this.spouse_flag} checked={this.state.Ncheck}/>No</span>
                                                </div>
                                                </div>)}
                                            </div>):(
                                            <div className='row'>
                                                <div className='col-6 radio-button'>
                                                    <span><input type='radio' name='one' value='Y' onChange={this.spouse_flag}/>Yes</span>
                                                    <span><input type='radio' name='one' value='N' onChange={this.spouse_flag}/>No</span>
                                                </div>
                                                <div className='col-1'></div>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                                {this.state.spouse_flag==='Y'?(<>
                                    <div class="row">
                                    <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-u">
                                            <input type="text" id="first_name" name="firstname" value={this.state.firstname} onChange={this.s_onChangefirstname} maxLength={40} disabled={this.state.spouse_name_edit} required/>
                                            <label for="first_name">First Name*</label>
                                            {this.state.s_first_valid?(<span></span>):(<div className='invalid-msg'>Only letters are allowed</div>)}
                                        </div>
                                    </div>
                                    <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-nr-u">
                                            <input type="text" id="middlename" name="middlename" value={this.state.middlename} onChange={this.s_onChangemiddelname}maxLength={40} disabled={this.state.spouse_name_edit} placeholder=' '/>
                                            <label for="middlename">Middle Name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-u">
                                            <input id="lastname" type="text" name="lastname" value={this.state.lastname} onChange={this.s_onChangelastname}maxLength={40} disabled={this.state.spouse_name_edit} required/>
                                            <label for="lastname">Last Name*</label>
                                            {this.state.s_last_valid?(<span></span>):(<div className='invalid-msg'>Only letters are allowed</div>)}
                                        </div>
                                    </div>

                                    <div class="col-md-3 inner-form-gap">
                                    <div className='mobile-no-f'>Mobile Number</div>
                                        <PhoneInput
                                        inputProps={{name: 'phone',required: true,placeholder:'+91 1234567899'}}
                                        country={'in'}
                                        countryCodeEditable={false}
                                        value={this.state.spouse_mobile_number}
                                        containerStyle={{marginTop:'15px'}}
                                        inputStyle={{background:'transparent',color:'#000',border:'none',outline:'none',borderBottom: '1px solid #000',width:'85%',borderRadius:0}}
                                        buttonStyle={{background:'transparent',border: 'none'}}
                                        dropdownStyle={{background:'#D7DBDD',width:'250px'}}
                                        onChange={this.s_onChangeNumber}
                                        />
                                    {this.state.s_mobile_validate?(<span></span>):(
                                            <div className='invalid-password'>Mobile number should be 10 digits</div>)}
                                    </div>

                                    

                                </div>
                                <div class="row">
                                    <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-u-date">
                                        <input id="dob-labl" type="date" placeholder="dd-mm-yyyy" name="s_date_of_birth" value={this.state.s_date_of_birth} onChange={this.onChange} disabled={this.state.disabled} required max={today} min="1900-01-01"/>
                                        <label class='dob-labl'>Date of Birth* (mm/dd/yyyy)</label>
                                        </div>
                                    </div>

                                    <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-nr-u">
                                            <input id="spouse_home_number" type="number" name="spouse_home_number" value={this.state.spouse_home_number} onChange={this.s_onChangeHomeNumber}maxLength={40} disabled={this.state.disabled} placeholder=' '/>
                                            <label for="spouse_home_number">Home Contact Number</label>
                                            {this.state.s_contact_no_validate?(<span></span>):(
                                            <div className='invalid-password'>Invalid Number</div>)}
                                        </div>
                                    </div>
                                    <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-nr-u">
                                            <input id="s_pan_number" type="text" name="s_pan_number" value={this.state.s_pan_number} onChange={this.s_onChangePanNumber}maxLength={40} disabled={this.state.disabled} placeholder=' '/>
                                            <label for="s_pan_number">PAN Number</label>
                                            {this.state.s_pan_vaild?(<span></span>):(<div className='invalid1'>Invalid Pan Number</div>)}
                                        </div>
                                    </div>
                                    <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-nr-u">
                                            <input id="s_aadhar_number" type="number" name="s_aadhar_number" value={this.state.s_aadhar_number} onChange={this.s_onChangeAdhar} disabled={this.state.disabled} placeholder=' '/>
                                            <label for="s_aadhar_number">Aadhar Number</label>
                                            {this.state.s_aadhar_number_validate?(<span></span>):(
                                                <div className='invalid1'>Invalid Adhar Number</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-nr-u">
                                            <input id="s_current_job" type="text" name="s_current_job" value={this.state.s_current_job} onChange={this.onChange}maxLength={40} disabled={this.state.disabled} placeholder=' '/>
                                            <label for="s_current_job">Current Job Title</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-nr-u">
                                            <input id="s_current_employer_name" type="text" name="s_current_employer_name" value={this.state.s_current_employer_name} onChange={this.onChange}maxLength={40} disabled={this.state.disabled} placeholder=' '/>
                                            <label for="s_current_employer_name">Current Employer Name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-nr-u">
                                            <input id="s_year_of_experience" type="text" name="s_year_of_experience" value={this.state.s_year_of_experience} onChange={this.onChange}maxLength={40} disabled={this.state.disabled} placeholder=' '/>
                                            <label for="s_year_of_experience">Year of Experience</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3 inner-form-gap">
                                        <div class="input-field-nr-u">
                                            <input id="s_main_source_income" type="text" name="s_main_source_income" value={this.state.s_main_source_income} onChange={this.onChange}maxLength={40} disabled={this.state.disabled} placeholder=' '/>
                                            <label for="s_main_source_income">Main Source of Income</label>
                                        </div>
                                    </div>

                                </div>
                                </>):null}
                            </div>
                        </div>
                    {this.props.userInfo===1 ?(
                        <div className='submit-Btn'>
                            {this.state.disabled?(
                            <button onClick={this.props.step2}>Next<MDBIcon icon="angle-right" className='buttonArrowRight'/></button>
                            ):(
                            <button type='button' onClick={this.pleaseSave} >Next<MDBIcon icon="angle-right" className='buttonArrowRight'/></button>
                            )}
                        </div>
                    ):(
                    <div className='submit-Btn'>
                        {this.state.first_valid && this.state.middel_valid && this.state.last_valid && this.state.mobile_validate && this.state.contact_no_validate && this.state.spouse_flag && this.state.s_first_valid && this.state.s_last_valid && this.state.s_mobile_validate && this.state.mobile_number?
                            (<button type='submit'>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>)
                            :(<button disabled={true} style={{background:'#E5E7E9'}}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
                        )}
                    </div>)}

    {this.state.helpModal?(
    <div className='help-modal-sec'>
    <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
        <div className='help-modal-sec-card'>
            <div className='help-msg-head'>Support</div>
            <div className='help-msg'>You can edit the details that are not used in the RIA agreement. If you want to edit the details, that have been used in the RIA agreement. So, please contact the team.
            <br/>Contact No : <b>+91 9819130637</b>
            <br/>Email : <b>info@houseofalpha.in</b>
            <span className='ok-btn' onClick={this.closeModaldocument}>
                <button>Ok</button>
            </span>
            </div>
            
          </div>
        </div>
        <div className='col-md-4'></div>
    </div>
  </div>):(<span></span>)}

                </form>
            </>
        )
    }
}

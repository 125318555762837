import axios from "axios";

export const srfo = input => {
    return axios
        .get('/alpha_user_db/srfo',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
import React,{useEffect,useState} from 'react'
import { Row, Col,Table,Drawer,Popconfirm, Typography,message, Modal,Button,Image} from 'antd';


const DocsDownload = () => {


    const columns = [
        {title: 'Client ID'},
        {title: 'Name'},
        {title: 'Email'},
        {title: 'Updated Date'},
        {title: 'Equity',
        children:[
            {title:'Holdings'},
            {title:'Orders'},
            {title:'positions'},
            {title:'Margins'},
        ]},
        {title: 'Mutual Fund',
        children:[
            {title:'Holdings'},
            {title:'Orders'},
            {title:'SIPs'},
        ]},
      ];
  return (
    <>
        <Table
            rowClassName="editable-row"
            columns={columns}
            bordered
            // dataSource={data}
            size='small'
            // scroll={{y: 450 }}
            pagination={{
            pageSize: 10,
            position:['bottomCenter']
            }}
            sticky={true}
            />
    </>
  )
}

export default DocsDownload
import React,{useState,useEffect} from 'react';
import { Table,Input,InputNumber,Popconfirm,Form,Typography,Button,Space,message,Select} from 'antd';
import {recommendations_data,recommendations_data_put,recommendations_data_delete} from './Api'
import Highlighter from 'react-highlight-words';
import { SearchOutlined,DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined} from '@ant-design/icons';
import './Recommandation.scss'
import Loader from '../../../shared/Loader';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  disable,
  ...restProps
}) => {
  

  const inputNode = title === 'Buy or Sell' ? <Select> 
                                                <Option value="Buy">Buy</Option>
                                                <Option value="Sell">Sell</Option>
                                              </Select>:
                                              title === 'Order Type' ?
                                              <Select>
                                                <Option value="MARKET">MARKET</Option>
                                                <Option value="LIMIT">LIMIT</Option>
                                              </Select>:
                                              inputType === 'text' ? <Input/>
                                              : 
                                              disable && dataIndex==='limit_price'?<InputNumber disabled={disable}/>:<InputNumber/>
                                              
  return (
    <td {...restProps}>
      {editing? (
        <>
        {dataIndex==='limit_price'?
        <Form.Item name={dataIndex}>{inputNode}</Form.Item>:

        <Form.Item
          name={dataIndex}
          rules={[{required: true,message: `Please Input ${title}!`}]}
        >
            {inputNode}
        </Form.Item>}
        </>
      ) : (
        children
      )}
    </td>
  );
};

const Review = () => {
  const[searchText,setSearchText] = useState('')
  const[searchedColumn,setSearchedColumn] = useState('')
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [editRecommendation,setEditRecommendation] = useState({}) 
  const [selectedClient,setSelectedClient] = useState({})
  const [loading,setLoading] = useState(false)

  let searchInput = ''
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  
const clientSelection = {
    onChange: (selectedRowKeys,selectedRows) => {setSelectedClient(selectedRows)},
    getCheckboxProps: (record) => ({disabled: record.execute === 'Y'}),
  };
const ExpandedRowRender = (props) => {
  const columns = [
    { title: 'Client Name', dataIndex: 'customer_name',width:'15%' },
    { title: 'Execute', dataIndex: 'execute',width:'5%' },
    { title: 'Execution Date', dataIndex: 'date_of_execution',width:'15%' },
    { title: 'Status', dataIndex: 'status',width:'50%' },
  ];

  const data = [];

  if(props.customer_list){
    Object.keys(props.customer_list).map((val)=>{
      data.push({
        key: val,
        customer_name: props.customer_list[val].customer_name,
        execute:props.customer_list[val].execute,
        date_of_execution: props.customer_list[val].date_of_execution,
        status: props.customer_list[val].status,
      })
    })
  }
 
  return <Table
    columns={columns} dataSource={data}
    pagination={false}   size='small'  
    rowSelection={{    type: 'checkbox',...clientSelection}} 
    rowClassName="editable-row"
    bordered/>;
};
const getAllRecoomendation = async()=>{
  setLoading(true)
  let newdata = []
  const input = {access_token:localStorage.token}
  const res = await recommendations_data(input)
  console.log('res',res);
  try{
    if (res && res.status==='successfull'){ 
      Object.keys(res.result).map((val)=>{
        newdata.push({ 
        key: res.result[val].recommendation_id,
        ' ':' ',
        activity_date: res.result[val].activity_date,
        instruments_type: res.result[val].instruments_type,
        exchange: res.result[val].exchange,
        trading_symbol:res.result[val].trading_symbol,
        name:res.result[val].name,
        lot_size:res.result[val].lot_size,
        order_type:res.result[val].order_type,
        limit_price:res.result[val].limit_price,
        quantity:res.result[val].quantity,
        buy_or_sell:res.result[val].buy_or_sell,
        expiry:res.result[val].expiry,
        strike:res.result[val].strike,
        description: <ExpandedRowRender customer_list={res.result[val].customer_list}/>
      }) 
      })
      setData(newdata)
      setLoading(false)
    }
    else{setLoading(false)}
  }catch{setLoading(false)}
}
useEffect(()=>{
  getAllRecoomendation()
},[])

let getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
          }}
        >
          Filter
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
  render: text =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0])
  setSearchedColumn(dataIndex)
};

const  handleReset = clearFilters => {
  clearFilters();
  setSearchText('')
};

  const save = async (key) => {
    setLoading(true)
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        setEditRecommendation(item)
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
        const input = {
          access_token:localStorage.token,
          editRecommendation:newData[index],
          selectedClient:selectedClient
        }
        console.log(input)
        recommendations_data_put(input).then(res => {
          console.log(res);
          try{
            if (res && res.status==='successfull'){
              setLoading(false)
              message.success(res.message)
              getAllRecoomendation();
            }
            else{
              setLoading(false)
              message.error(res.message)
            }
          }catch{
            setLoading(false)
            message.error('Something went Wrong')
          }
        })
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
        setLoading(false)
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      setLoading(false)
    }
  };


  const onDelete = async(key)=>{
    setLoading(true)
  const input = {
    access_token:localStorage.token,
    recommendation_id:key
  }
  console.log(input)
recommendations_data_delete(input).then(res => {
  console.log(res);
  try{
    if (res && res.status==='successfull'){
      setLoading(false)
      message.success(res.message)
      getAllRecoomendation();
    }
    else{
      setLoading(false)
      message.error(res.message)
    }
  }catch{
    setLoading(false)
    message.error('Something went Wrong')
  }

}) 
}
const showAlert=()=>{
  message.error('Please select atleast one client',3);
}


  const columns = [
    {
      title: ' ',
      dataIndex: ' ',
      fixed:'left'
    },
    {
      title: 'Activity Time',
      dataIndex: 'activity_date',
      width: 130,
      ...getColumnSearchProps('activity_date'),
    },
    {
      title: 'Instruments Type',
      dataIndex: 'instruments_type',
      width: 150,
      ...getColumnSearchProps('instruments_type'),
    },
    {
      title: 'Trading Symbol',
      dataIndex: 'trading_symbol',
      width: 200,
      ...getColumnSearchProps('trading_symbol'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: 120,
      editable: true,
      ...getColumnSearchProps('quantity'),
    },
 
    {
      title: 'Buy or Sell',
      dataIndex: 'buy_or_sell',
      width: 150,
      editable: true,
    
      ...getColumnSearchProps('buy_or_sell'),
      sorter: (a, b) => a.buy_or_sell.length - b.buy_or_sell.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Exchange',
      dataIndex: 'exchange',
      width: 120,
      ...getColumnSearchProps('exchange'),
    },
    {
      title: 'Lot Size',
      dataIndex: 'lot_size',
      width: 120,
      ...getColumnSearchProps('lot_size'),
    },
    {
      title: 'Order Type',
      dataIndex: 'order_type',
      width: 140,
      ...getColumnSearchProps('order_type'),
      sorter: (a, b) => a.order_type.length - b.order_type.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Limit Price',
      dataIndex: 'limit_price',
      width: 120,
      editable: true,
      ...getColumnSearchProps('limit_price'),
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      fixed: 'right',
      width: 120,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() =>Object.keys(selectedClient).length>0? save(record.key):showAlert()}
              style={{
                marginRight: 8,
              }}
            >
              <span className='outlineSaveButton'>
                <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
              </span>
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
            </Popconfirm>
          </span>
        ) : (
          <span>
          <Typography.Link disabled={editingKey !== ''}
            onClick={() =>Object.keys(selectedClient).length>0? edit(record):showAlert()}>
            <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
          </Typography.Link>
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record.key)}>
           <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
          </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'quantity' || col.dataIndex === 'limit_price' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        disable: record.order_type === 'MARKET'? true:false
      }),
    };
  });

  return (
    <div className='adminBg'>
      {loading?<Loader/>:null}
      <Form form={form} component={false}>
        <Table
          expandable={{ expandedRowRender: record =><>{record.description}</> }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          // bordered={true}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            pageSize: 10,
            onChange: cancel
          }}
          size='small'
          scroll={{x: '100%', y: '65vh' }}
          sticky={true}
        />
      </Form>
    </div>
  );
};

export default Review
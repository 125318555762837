import React ,{useState} from 'react'
import './WhatWeDo.scss'
import './WhatWeDoMedia.scss'

import Navbar from '../Universal/Navbar'
import {MDBIcon} from 'mdbreact'
import Footer from '../Universal/Footer'
import FooterContact from '../Universal/FooterContact'
import icon1 from '../../../Images/Icon_1.png'
import icon2 from '../../../Images/Icon_2.png'
import icon3 from '../../../Images/Icon_3.png'
import icon4 from '../../../Images/Icon_4.png'
import icon5 from '../../../Images/Icon_5.png'
import icon6 from '../../../Images/Icon_6.png'
import adviseIcon from '../../../Images/advise.png'
import uniqueIcon from '../../../Images/unique.png'
import comprehensiveIcon from '../../../Images/comprehensive.png'
import {YoutubeOutlined} from '@ant-design/icons';
import ModalVideo from 'react-modal-video'

const WhatWeDo = (props) => {
    const [comprehensive,setComprehensive] = useState(true)
    const [advise,setAdvise] = useState(false)
    const [unique,setUnique] = useState(false)
    const [vedio,setVedio] = useState(false)

    const comprehensives=()=>{
        setComprehensive(true)
        setAdvise(false)
        setUnique(false)
    }

    const advices=()=>{
        setComprehensive(false)
        setAdvise(true)
        setUnique(false)
    }

    const uniques=()=>{
        setComprehensive(false)
        setAdvise(false)
        setUnique(true)
    }
    const handleVedio= ()=>{
        setVedio(!vedio)
    }


    const Card = (props) => {
        const {icon,text,text1,discription,play}=props
        return (
                <article class="aboutCardWhite">
                    <div className='playPeriodic'>{play}</div>
                  <div class="cardThumb">
                    <img src={icon} alt = ""/>
                  </div>
                  <div class="cardBody">
                    <div class="aboutCardHead">{text}</div>
                    <div class="aboutCardHead">{text1}</div>
                 
                    <p class="modalHeadPara">{discription}</p>
                  </div>
                </article>
        )
    }
    const Mainmsg = (props)=>{
        const {icon,text,text2,text3}=props
        return (
            <div>
                <div className='main-box-icons'><img src={icon} alt=''></img></div> 
                <div className='main-box-msg' > {text} </div>
                <div className='main-box-msg' > {text2} </div>
                <div className='main-box-msg' > {text3} </div>
            </div>
        )
    }


    return (
        <>
            <div><Navbar/></div>
            <div className='bodyDiv'>
                <div className='whatBannerImage'>
                    <div className='row'>
                        <div className='col-md-7'></div>
                        <div className='col-md-5'>
                            <div className='thoughts'>A SYSTEMATIC APPROACH<br/>TO CREATING A WEALTHY LIFE</div>
                        </div>
                    </div>
                </div>
           
                <div className='approachBg'>
                    <div className='SectionApproachParag'>It is immoral to disguise a sales pitch as well - intentioned advice.</div>
                    <div className='SectionApproachPara'>The 'Life Centred Financial Planning and Wealth Management Process' focusses only on you achieving your life goals.</div>
                </div>
          
                <div className='cardBg'>
                    <div className='sectionHeadYellow'>The 6 Step Process</div>
                    <div className='cardSection'>
                        <div className='gridContainerCard'>
                            <div className='grid-item'>
                                <Card icon={icon1} text='Exploratory Meeting' 
                                discription="In this 'no-cost, no-obligation' meeting we will explore if our services are best suited for your requirements. You will get to know our financial planning approach, investment philosophy, the preferred choice of products, and fee structure in this meeting."/>
                            </div>
                            <div className='grid-item'>
                                <Card icon={icon2} text='Documentation and Data' text1='Collection'
                                    discription="If we like each other we sign the necessary agreements and non-disclosures. We will gather important information about your financial life."/>
                            </div>
                            <div className='grid-item'>
                                <Card icon={icon3} text='Planning and Scenario' text1='Building'
                                    discription="Based on the data you had shared, we will show you what will happen to your 'bucket'. In this meeting, you can wear your 'thinking hat' and come up with various scenarios to get the best possible outcome. We can run every scenario and help you see for yourself what can happen in the future. You can then pick the best scenario and identify specific actions you have to take now."/>
                            </div>
                            <div className='grid-item'>
                                <Card icon={icon4} text='Investment Policy' text1='Statement Preparation'
                                    discription="An important aspect of the journey is identifying the right investment options for you. We will help you write your own 'Investment Policy Statement' which acts as a framework for us to manage your assets. Asset Allocation, Product Selection, Timing decisions, Risk Management decisions, and response to extreme market conditions are all planned in this stage."/>
                            </div>
                            <div className='grid-item'>
                                <Card icon={icon5} text='Implementation of the Plan'
                                    discription="Your Support team will ensure that your plan is implemented seamlessly for investments, risk management, succession planning, and loan management."/>
                            </div>
                            <div className='grid-item'>
                                <Card icon={icon6} text='Periodic Review'
                                    discription="Once a year - Your Plan will be reviewed and forward planning for the next year will be done. Quarterly check-in with your adviser to see if everything is on track and if you have any specific questions. A monthly update will be sent to you by email and if you need to discuss any part of it you can fix an appointment and chat up with your adviser. Needless to say, you will have access to view your progress at any time through our in-house build technology platform."/>
                            </div>
                        </div>
                    </div>
                    <div className='sectionVedioYellow'>To know more about this process
                    <span className='vedioLink' onClick={handleVedio}>click here<YoutubeOutlined className='YouIcnYello'/></span></div>
                   
                    <ModalVideo channel='youtube'youtube={{autoplay: 1,mute: 1}} isOpen={vedio} videoId='AFyHf65xEwE' onClose={handleVedio} />
                </div>
        
                <div className='AddressingSec'>
                    <div className='sectionAskHead'> Addressing the 'What ifs'</div>    
                        <div className='sectionKeyPoint'>
                        <div className='suggetions'>
                            {comprehensive?(
                            <div className="boxes-active" onClick={comprehensives}>
                                <div className='icons'><img src={comprehensiveIcon} alt=''></img></div> 
                                <div className='box-msg'> COMPREHENSIVE<br/>  FINANCIAL  PLANNING</div>
                            </div>
                            ):(
                            <div className="boxes" onClick={comprehensives}>
                                <div className='icons'><img src={comprehensiveIcon} alt=''></img></div> 
                                <div>
                                    <span className='iconCircle'><span className='middle-icon'>
                                        <MDBIcon icon="angle-right" size='1x'/></span></span>
                                </div>
                                <div className='box-msg'> COMPREHENSIVE<br/>  FINANCIAL  PLANNING</div>
                            </div>
                            )}
                            {advise?(
                            <div className="boxes-active" onClick={advices}>
                                <div className='icons'><img src={adviseIcon} alt=''></img></div>
                                <div className='box-msg'> ADVISE ON ALL <br/> MARKET  PRODUCTS</div>
                            </div>
                            ):(
                            <div className="boxes" onClick={advices}>
                                <div className='icons'><img src={adviseIcon} alt=''></img></div>
                                    <span className='iconCircle'><span className='middle-icon'><MDBIcon icon="angle-right" size='1x'/></span></span>
                                <div className='box-msg'> ADVISE ON ALL <br/> MARKET  PRODUCTS</div>
                            </div>
                            )}
                            {unique?(
                            <div className="boxes-active" onClick={uniques}>
                                <div className='icons'><img src={uniqueIcon} alt=''></img></div> 
                                <div className='box-msg' >UNIQUE PROPRIETARY <br/> PRODUCT </div>
                            </div>
                            ):(
                            <div className="boxes" onClick={uniques}>
                                <div className='icons'><img src={uniqueIcon} alt=''></img></div> 
                                    <span className='iconCircle'><span className='middle-icon'><MDBIcon icon="angle-right" size='1x'/></span></span>
                                <div className='box-msg' >UNIQUE PROPRIETARY <br/> PRODUCT </div>
                            </div>
                            )}
                        </div>
                        <div className='text-box'>
                            {comprehensive?(
                                <Mainmsg text='Comprehensive Lifestyle focussed financial planning includes Life Goal identification and Planning, Contingency Planning and Insurances, Investment choices, Risk management and Wealth Preservation, Succession Planning and Tax Planning.'
                                    icon = {comprehensiveIcon}
                                />
                            ):null}
                            {advise?(
                                <Mainmsg text='We have the expertise to analyse and assess all financial products in the market. Customized portfolios may consist of investment products including :' text2='In Equities - Index Funds/ETFs, Actively managed mutual funds, International ETFs or Index funds and Model portfolio of Stocks.' text3='In Debt - corporate deposits, bonds, mutual funds, REITs and INViTs.'
                                    icon = {adviseIcon}
                                />
                            ):null}
                            {unique?(
                                <Mainmsg text='We create customized, efficient, lower cost products with better risk reward pay off in-house.' text2= 'Exclusive proprietary products are built on both Equity and Fixed Income side such as Debt + NIFTY Options, Stocks + Covered Call Options, Currency Derivatives.'
                                    icon = {uniqueIcon}
                                />
                            ):null}
                        </div>
                    </div>
                </div>

                <div className='fee-section'>
                    <div className='title'>Our Fees</div>
                    <div className='feesBox'>
                        <div className='head'>
                            {/* <div className='fee'>Our minimum fee is ₹ 75,000 + applicable taxes   per   annum</div> */}
                            {/* <div className='msg'>Ongoing  management </div>  */}
                            <div className='discription'>Fees 1% of the assets managed</div>
                            <div className='discription'>Our minimum fee is Rs. 75,000 + applicable taxes per annum</div>
                            
                            <div className='meeting-link'>
                                <span className='link'><a href='#contact'>Click here</a></span>  for a "No Cost No Obligation" meeting to see if this is a good fit for you.
                            </div>
                        </div>
                    </div>
                </div>

            <div id='contact'><FooterContact/></div>
            <div className='footrcontcBG'></div>
            <div><Footer/></div>
        </div>
      </>
    )
}

export default WhatWeDo

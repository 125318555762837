import React, { useState, useEffect } from 'react';
import './Calculator.scss'

import { Row, Col,Modal,Form, Input,Button,Image } from 'antd';
import {hoa_webinar_calculator,hoa_webinar_details} from '../Api'
import CurrencyInput from 'react-currency-input-field';
import CalcImage from '../../../Images/CalcImage.jpg'
import Logo from '../../../Images/logoBlack.png'


const Calculator = (props) => {

    const [data, setData] = useState([]);
    const[formField,setFormField] = useState({name:'',email:'',current_age:'',contact_no:0,city:'',monthly_income:'',calculator_user:'Y'})

    const[field,setField] = useState({Desired_Age_of_Retirement:'',Expected_Inflation:'8',Todays_Annual_Living_Expenses:'',Interest_rate_at_the_time_of_retiremen:'5',current_age:''})

    const [details, setDetails] = useState(true);

    const onChangeVal=(e)=>{
        let dict = {...field}
        dict[e.target.name] = e.target.value
        setField(dict)
      }
    const onSubmitaddress = async (e)=>{
        e.preventDefault()
        const input = {
          field:field
      }
        const res = await hoa_webinar_calculator(input)
        console.log('ress',res);
        try{
          if (res && res.status==='successfull'){
              setData(res.result)
          }else{
          }
        }catch{
        }
      }

      const onSubmitDetails = async (e)=>{
        e.preventDefault()
        const input = {
          field:formField,
      }
      console.log(input);
        const res = await hoa_webinar_details(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ setDetails(false)}
          else{}
        }catch{}
      }

      const onChangeValDetails=(e)=>{
        let dict = {...formField}
        dict[e.target.name] = e.target.value
        setFormField(dict)
      }
      const handleChangeAmount=(value,name,type,row)=>{
        const aa = {...field};
        aa[value][type][row][name] = parseInt(value);
      }
      const numberFormat = (value) =>
      new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
          minimumFractionDigits:0
      }).format(value);
  return (
    <div className='calBannerImageThank'>
      <div className='fiPageHeight'>
        <div className='desktop0'>
          <Row>
            <Col span={24}>
              <div className='calcFormDiv'>
              <Row>
                <Col span={12}>
                <div className='logoCalc'><img src={Logo} alt=''/></div>
                </Col>
                <Col lg={12} md={12}  xs={24}>
                <div className='calctextdiv'>
                  <p className='inverted'>"</p>
                  <p className='calcText'>Enough is a decision not an amount.</p>
                  <p className='calcTextWriter'>ALISON FAULKNER</p>
                </div>   
                </Col>
              </Row>

                {details?
            <>
             <form onSubmit={onSubmitDetails} id='book_seat_now'>
             <div className='CalCard2' align='center'>
          <div className='webform'>
            <input type="text" name="name" placeholder='Name' onChange={onChangeValDetails} maxLength={40} required/>
          </div>
          <div className='webform'>
            <input type="text" name="email" placeholder='Email' onChange={onChangeValDetails} maxLength={40} required/>
          </div>
          <div className='webform'>
            <input type="number" name="current_age" placeholder='Current Age' onChange={onChangeValDetails} maxLength={2} required/>
          </div>
         
          <div className='bnrBtn1'><button type='submit'>Submit</button></div>
          </div>
        </form>
    </>:
    <>
     <form onSubmit={onSubmitaddress}>
        <div className='CalCard'>
          
            <Row>
                <Col lg={12} md={12}  xs={24} className='calCaedDivder'>
                    <Row>
                        <Col lg={12} md={12}  xs={24}>
                            <div className='calFormLabel'>Current Age</div>
                            <input  type="number" name="current_age" onChange={onChangeVal} className='calFormInput' required/>
                        </Col>
                        <Col lg={12} md={12}  xs={24}>
                            <div className='calFormLabel'>Desired Age of Retirement</div>
                            <input  type="number" name="Desired_Age_of_Retirement" onChange={onChangeVal} className='calFormInput' required/>
                        </Col>
                        <Col lg={12} md={12}  xs={24}>
                            <div className='calFormLabel'>Today’s Annual Living Expenses</div>
                            <input type="number" name="Todays_Annual_Living_Expenses" onChange={onChangeVal} className='calFormInput' required/>
                        </Col>
                        <Col lg={12} md={12}  xs={24}>
                            <div className='calFormLabel'>Expected Inflation (%)</div>    
                            <input type="number" name="Expected_Inflation" onChange={onChangeVal} value={field.Expected_Inflation} className='calFormInput' required/>
                        </Col>
                        <Col lg={15} md={12}  xs={24}>
                            <div className='calFormLabel'>Interest rate at the time of retirement (%)</div>
                            <input type="number" name="Interest_rate_at_the_time_of_retiremen" value={field.Interest_rate_at_the_time_of_retiremen} onChange={onChangeVal} className='calFormInput' required/>
                        </Col>
                    </Row>
                    <Button htmlType="submit" className="calFormButton">Submit</Button>
                </Col>
                <Col lg={12} md={12}  xs={24}>
                    <div className='calFormLabel1'>Expected Expenses on year of Retirement: &nbsp;  {data.Expected_Expenses_on_year_of?(<p className='calFormLabel1Total'>{numberFormat(data.Expected_Expenses_on_year_of)}</p>):0}</div>
                    <div className='calFormLabelTotalHead'>How much should your retirement corpus be?</div>
                    <div className='calFormLabelTotal'>{data.How_much_should_your_retirement?(<>{numberFormat(data.How_much_should_your_retirement)}</>):0}</div>
                </Col>
            </Row>
            </div>
        </form>
    </>}  
              </div>
            </Col>
          </Row>
        </div>
        {/* <div className='mobile'>
          <div className='mobileLoginBg'>

            <Image src={Logo} rootClassName='loginLogoImage' preview={false}/>
            <div className='mobileLoginBox'>
              <div className='LoginHeading'>Login To Wealth 360 Customer Portal</div>
            </div>
          </div>
        </div> */}
      </div>


      {/* <div className='webBody'>
        <div className='calcultorHead'>Retirement Needs Calculator</div>
        {details?
            <>
             <form onSubmit={onSubmitDetails} id='book_seat_now'>
             <div className='CalCard' align='center'>
          <div className='webform'>
            <input type="text" name="name" placeholder='Name' onChange={onChangeValDetails} maxLength={40} required/>
          </div>
          <div className='webform'>
            <input type="text" name="email" placeholder='Email' onChange={onChangeValDetails} maxLength={40} required/>
          </div>
          <div className='webform'>
            <input type="number" name="current_age" placeholder='Current Age' onChange={onChangeValDetails} maxLength={2} required/>
          </div>
         
          <div className='bnrBtn1'><button type='submit'>Submit</button></div>
          </div>
        </form>
    </>:<>
        <form onSubmit={onSubmitaddress}>
        <div className='CalCard'>
          
            <Row>
                <Col lg={12} md={12}  xs={24} className='calCaedDivder'>
                    <Row>
                        <Col lg={12} md={12}  xs={24}>
                            <div className='calFormLabel'>Current Age</div>
                            <input  type="number" name="current_age" onChange={onChangeVal} className='calFormInput' required/>
                        </Col>
                        <Col lg={12} md={12}  xs={24}>
                            <div className='calFormLabel'>Desired Age of Retirement</div>
                            <input  type="number" name="Desired_Age_of_Retirement" onChange={onChangeVal} className='calFormInput' required/>
                        </Col>
                        <Col lg={12} md={12}  xs={24}>
                            <div className='calFormLabel'>Today’s Annual Living Expenses</div>
                            <input type="number" name="Todays_Annual_Living_Expenses" onChange={onChangeVal} className='calFormInput' required/>
                        </Col>
                        <Col lg={12} md={12}  xs={24}>
                            <div className='calFormLabel'>Interest rate at the time of retirement (%)</div>
                            <input type="number" name="Interest_rate_at_the_time_of_retiremen" value={field.Interest_rate_at_the_time_of_retiremen} onChange={onChangeVal} className='calFormInput' required/>
                        </Col>
                        <Col lg={12} md={12}  xs={24}>
                            <div className='calFormLabel'>Expected Inflation (%)</div>    
                            <input type="number" name="Expected_Inflation" onChange={onChangeVal} value={field.Expected_Inflation} className='calFormInput' required/>
                        </Col>
                    </Row>
                    <Button htmlType="submit" className="calFormButton">Submit</Button>
                </Col>
                <Col lg={12} md={12}  xs={24}>
                    <div className='calFormLabel1'>Expected Expenses on year of Retirement: &nbsp;  {data.Expected_Expenses_on_year_of?(<>{numberFormat(data.Expected_Expenses_on_year_of)}</>):0}</div>
                    <div className='calFormLabelTotalHead'>How much should your retirement corpus be?</div>
                    <div className='calFormLabelTotal'>{data.How_much_should_your_retirement?(<>{numberFormat(data.How_much_should_your_retirement)}</>):0}</div>
                </Col>
            </Row>
            </div>
        </form>
        <div className='calculatornewdiv'>
        <div className='calcultorpara'>Do you want to know how to build this retirement corpus?<br/>Book a 1 on 1 No Obligation call to discuss with our team</div>
          <a className='calculatorlink' target="_blank" href='https://calendly.com/bhuvanaa/wealth-management-exploratory-call'><u>BOOK NOW</u></a>
        </div>
        </>}         
        </div> */}
    </div>
  )
}

export default Calculator 
import React from 'react'
import { Row,Col,Table,Drawer,Popconfirm, Typography,Select,Form,Input,Avatar,Button} from 'antd';
import {contact_hoa} from '../../../Website/Api'
import { toast,ToastContainer } from 'react-toastify';

const AddLeads = (props) => {

  const toastMsg =(id,msg,type)=>{
    toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
  }
    const [form] = Form.useForm();
    const{close} = props

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24}
      };  

      const onFinish =(values)=>{
        const id = toast.loading("Please wait...")
        const input = {
            access_token:localStorage.token,
            name: values.name,
            surname: values.surname,
            email: values.email,
            phone_number: values.phone,
            reference:'Admin',
          }
          contact_hoa(input).then(res => {
            console.log(res);
            try{
            if (res.status==='successfull') {
              toastMsg(id,res.message,'success')
            }
            else{toastMsg(id,res.message,'error')}
            }catch{toastMsg(id,'Something went wrong','error')}
            })
      }
  return (
    <div>
    <Row align='middle'>
          <Col span={23}>
          <div className='modalHeadBarTitle'>Add New Leads</div>
          </Col>
          <Col span={1}>
            <div className='TableActionButtonClose' onClick={close}>Close</div>
          </Col>
        </Row>
         <Form
              {...formItemLayout}
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              >
              <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item
                          name="name"
                          label="First Name"
                          rules={[{required: true}]}>
                            <Input placeholder="type here.." className='LoginFormInput'/>
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                    <Form.Item
                          name="surname"
                          label="Last Name"
                          rules={[{required: true}]}>
                                <Input placeholder="type here.." className='LoginFormInput'/>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                          name="phone"
                          label="Mobile Number"
                          rules={[{required: true}]}>
                            <Input placeholder="type here.." className='LoginFormInput'/>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                          name="email"
                          label="Email"
                          rules={[{required: true}]}>
                             <Input placeholder="type here.." className='LoginFormInput'/>
                      </Form.Item>
                    </Col>
              </Row>
              <Form.Item style={{textAlign:'right'}}>
                  <Button className='submitButtonsmall' htmlType="submit" >Submit</Button>
              </Form.Item>
          </Form>
    </div>
  )
}

export default AddLeads
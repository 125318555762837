import React, { useState,useEffect } from 'react'
import CropRotateImage from '../../shared/CropRotateImage'
import {MDBIcon} from 'mdbreact'
import {save_client_sign,get_client_sign,get_spouse_details} from './Api'
import  Sign from "../../Images/signature.png"
import SweetAlert from 'react-bootstrap-sweetalert';
import {useSelector,useDispatch} from 'react-redux'
import {setDisabled} from '../../redux/actions/index'
import Loader from '../../shared/Loader';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signature = (props) => {
    const {id,sign,update,address,step2,step4,ria_form} = props

    const disabled = useSelector((state)=> state.handleDisabled.disabled)
  
    const [loading,setLoading] = useState(false)
    const [showSpouse,setShowSpouse] = useState(true)
    const [signature,setSignature] = useState('')
    const [spouseSignature,setSpouseSignature] = useState('')
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [warning,setWarning] = useState(false)
    const [successmsg,setSuccessmsg] = useState('')
    const [errormsg,setErrormsg] = useState('')
    const [signFlag,setSignFlag] = useState('N')
    const [Ycheck,setYcheck] = useState(false)
    const [Ncheck,setNcheck] = useState(true)
    
    const dispatch = useDispatch();

    const toastMsg =(ids,msg,type)=>{
        toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    } 

    useEffect(()=>{
        console.log("useeffect")
        const input = {access_token:localStorage.token,id:id}

        if(sign===1){
            setLoading(true)
            get_client_sign(input).then(res => {
                console.log("ress",res)
                try {
                    if (res.status==='successfull') {
                        setLoading(false)
                        dispatch(setDisabled(true));
                        setSignature('data:image/png;base64,'+ res.result.client)
                        if(res.result.spouse_sign==='Y'){
                            setSignFlag('Y')
                            setYcheck(true)
                            setNcheck(false)
                            setSpouseSignature('data:image/png;base64,'+ res.result.spouse)
                        }else{
                            setSignFlag('N')
                            setNcheck(true)
                            setYcheck(false)
                        }
                    }else{
                        setLoading(false)
                        toast.error(res.message)
                  }
              }
              catch (error) {
                setLoading(false);toast.error('Something went wrong')
              }
            })
        }

        get_spouse_details(input).then(res => {
                    console.log('resspo',res)
                    try{
                        if (res.status==='successfull'){setShowSpouse(true) }
                        else{setShowSpouse(false)}
                    }catch{setShowSpouse(false)}
                })
    },[sign])
    
    const setSign=(signature)=>{setSignature(signature)}

    const setSign2=(signature)=>{setSpouseSignature(signature)}

    const clear_sign=()=>{setSignature('')}

    const clear_sign2=()=>{setSpouseSignature('')}

    const onleave=()=>{
        setWarning(false)
        setError(false)
        setSuccess(false)
      }
    const saveSign=()=>{
        const ids = toast.loading("Please wait...",{toastId:'load'})
        const input = {
            access_token:localStorage.token,
            signature:signature,
            signature2:spouseSignature,
            id:id
          }
        save_client_sign(input).then(res => {
            console.log("res",res)
            try {
                if (res.status==='successfull') {
                    toastMsg(ids,res.message,'success')
                    if(sign===1){
                        
                        dispatch(setDisabled(true));
                    }else{
                        update();
                        step4();
                    }
                }else{toastMsg(ids,res.message,'error')}
            }catch (error) {toastMsg(ids,'Something went wrong','error')}
        })
    }
    const onChangeSignFlag=(e)=>{
        setSignFlag(e.target.value)
        setYcheck(!Ycheck)
        setNcheck(!Ncheck)
      }
    const pleaseSave=()=>{
        setWarning(true)
    }
    const edit=()=>{
        dispatch(setDisabled(false));
        setSignature('')
        setSpouseSignature('')
    }
    
    if (loading){
        return(
          <div style={{textAlign:'center',marginTop:100}}>
          <Loader/>
          </div>
          );
    }
   
    return (
        <>
        <ToastContainer theme='colored' limit={1}/>
        {error?(<SweetAlert
                    error onConfirm={onleave}>
                    {errormsg}
                </SweetAlert>):null}
        {warning?(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="success"
                cancelBtnText="No"
                cancelBtnBsStyle="danger"
                onConfirm={()=>step4()}
                onCancel={onleave}
                focusCancelBtn>Do you want to proceed without save your changes</SweetAlert>):null}
        {success?(
            <SweetAlert
                success onConfirm={onleave}>
                {successmsg}
            </SweetAlert>):null}

        <div className='form-heading'>Signature
        {sign===1?(
            <span className='formButton'>
                {disabled?
                <>
                {ria_form===0?<><button type="button" onClick={edit}><MDBIcon far icon="edit" className='form-edt-icon'/>Edit</button></>:null}
                </>:
                <>
                {signature && ((signFlag==='Y' && spouseSignature)||signFlag==='N')?
                <button onClick={saveSign}><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>:
                <button disabled><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>}
                </>}
            </span>):null}
        </div>
        
        <div className='formHeight'>
            <div className='row'>
                <div className='col-md-6 br-right'><div className='sign-imag'><img src={Sign} alt=""/></div>
                    <div className='sign-note'>Note: This signature will be used only for the following purposes :
                    </div>
                    <ul className='sign-ul'>
                        <li>Signing of the Investment Advisory Agreement</li>
                        <li>Risk Profiling document sign off</li>
                        <li>Account Opening  with BSE Star / Stock broker</li>
                    </ul>
                </div>

                <div className='col-md-6'>
                    {disabled && sign===1?(
                    <>
                       <div className='sign-name'>User Signature</div>
                       {signature?<div className='cr-d'><img src={signature} alt='' className='crop-img'/></div>:null}
                       {showSpouse && signFlag==='Y' && spouseSignature?
                        <> 
                            <div className='sign-name'>Spouse Signature</div>
                            <div className='perm-add-box'>
                            <div className='cr-d'><img src={spouseSignature}alt='' className='crop-img'/></div>
                            </div>
                        </>:null}          
                    </>
                    ):(
                    <>
                    <div className='sign-msg'>
                        Please do a signature on plain white paper and upload a clear image.
                    </div>
                    <div className='sign-upld-msg'>
                      (The file must be in a JPG or PNG format.Maximum upload file size 2MB.)
                    </div>
                    <CropRotateImage sign={setSign} clear_sign={clear_sign}/>
                    {showSpouse?
                    <div className='perm-add-box'>
                        <div className='row'>
                            <div className='col-8 same-address-head'>Do you want to sign the agreement along with your spouse?</div>
                            <div className='col-4 radio-button'>
                                <span><input className='checkbox' type='checkbox' name='one' value='Y' onChange={onChangeSignFlag} checked={Ycheck} />Yes</span>
                                <span><input className='checkbox' type='checkbox' name='one' value='N' onChange={onChangeSignFlag} checked={Ncheck}/>No</span>
                            </div>
                        </div>
                        {signFlag==='Y'?(
                                <CropRotateImage sign={setSign2} clear_sign={clear_sign2}/>
                            ):null}
                    </div>:null}
                </>
                    )}
                </div>
            </div>
        </div>

        {address===1 && sign===0?(
            <div className='submit-Btn'>
                <span onClick={step2}><MDBIcon icon="angle-left" className='buttonArrowLeft'/>Previous</span>
            {showSpouse?
            <>
            {signature && ((signFlag==='Y' && spouseSignature)||signFlag==='N')?
                <button onClick={saveSign}>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>:
                <button disabled={true} style={{background:'#E5E7E9'}}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>}
            </>:
            <>
            {signature?
                <button onClick={saveSign}>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>:
                <button disabled={true} style={{background:'#E5E7E9'}}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>}
            </>}
            </div>):null}

        {address===0?<div className='previous-msg'>Please fill the previous form first.</div>:null}

        {address===1 && sign===1?<div className='submit-Btn'>
            <button type='button' onClick={step2}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
        {disabled?
            <button onClick={step4}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
            :
            <button type='button' onClick={pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>}
        </div>:null}
    </>
    )
}
export default Signature

import axios from 'axios'
export const customer_review = input => {
    return axios
        .get('/alpha_admin/customer_review',
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const customer_admin_delete = input => {
    return axios
        .delete('/alpha_admin/customer_admin_delete',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          },)
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const scheme_name = input => {
    return axios
        .get('/alpha_admin/scheme_name',
        {
            headers: {
              'Authorization': input.access_token
            },
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const recommendation = input => {
    return axios
        .post('/alpha_admin/recommendation', {
          client_list:input.client_list,
          derivatives_list:input.derivatives_list
        },
        {
          headers: {
            'Authorization': input.access_token
          }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const revenue_data = input => {
    return axios
        .get('/alpha_admin/revenue',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
             req        : input.req,
             start_date : input.start_date,
             last_date  : input.last_date
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const communication = input => {
    return axios
        .get('/alpha_admin/communication',
        {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            start_date : input.start_date,
            last_date  : input.last_date
           }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const user_fullinfo = input => {
    return axios
        .get('/alpha_admin/user_fullinfo',
        {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            start_date : input.start_date,
            last_date  : input.last_date
           }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const mf_performance = input => {
    return axios
        .get('/alpha_admin/mf_performance',
        {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            start_date : input.start_date,
            last_date  : input.last_date
           }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const view_compliants_docs = input => {
    return axios
    .get('/alpha_admin/view_compliants_docs',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          flag:input.flag,
          id : input.id,
          dir: input.dir
          
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
    }

    export const get_payment = input => {
      return axios
      .get('/alpha_admin/payment',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id : input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
      }

      export const save_payment = input => {
        return axios
            .post('/alpha_admin/payment', {
              id : input.id,
              amount : input.amount,
              date : input.date
            },
            {
              headers: {
                'Authorization': input.access_token
              }
            })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const mf_scheme_list = input => {
        return axios
        .get('/alpha_admin/mf_scheme_list',
        {
            headers: {
              'Authorization': input.access_token
            },
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
        }
      export const admin_asset = input => {
        return axios
        .get('/alpha_admin/admin_asset',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              customer_id : input.customer_id,
              date:input.date
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
        }
    export const admin_asset_post = input => {
      return axios
          .post('/alpha_admin/admin_asset', {
            customer_id : input.customer_id,
            asset_dict_post : input.asset_dict_post,
            date:input.date
          },
          {
            headers: {
              'Authorization': input.access_token
            }
          })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const admin_mf_value = input => {
      return axios
      .get('/alpha_admin/admin_mf_value',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            customer_id : input.customer_id,
            date:input.date
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
      }
  export const admin_mf_value_post = input => {
    return axios
        .post('/alpha_admin/admin_mf_value', {
          customer_id : input.customer_id,
          mf_dict : input.mf_dict,
          date:input.date
        },
        {
          headers: {
            'Authorization': input.access_token
          }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
    export const admin_asset_goals = input => {
      return axios
      .get('/alpha_admin/admin_asset_goals',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            customer_id : input.customer_id,
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
      }
      export const admin_asset_goals_post = input => {
        return axios
            .post('/alpha_admin/admin_asset_goals', {
              customer_id : input.customer_id,
              data_dict : input.data_dict,
            },
            {
              headers: {
                'Authorization': input.access_token
              }
            })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const admin_goal_complete = input => {
        return axios
        .get('/alpha_admin/admin_goal_complete',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              customer_id : input.customer_id,
              date:input.date
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
        }
        export const admin_goal_complete_post = input => {
          return axios
              .post('/alpha_admin/admin_goal_complete', {
                customer_id : input.customer_id,
                goal_dict_post : input.goal_dict_post,
                date:input.date
              },
              {
                headers: {
                  'Authorization': input.access_token
                }
              })
              .then(response => {
                  return response.data
              })
              .catch(err => {})
        }
    export const admin_goal_risk_level = input => {
      return axios
      .get('/alpha_admin/admin_goal_risk_level',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            customer_id : input.customer_id,
            date:input.date
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
      }
  export const admin_goal_risk_level_post = input => {
    return axios
        .post('/alpha_admin/admin_goal_risk_level', {
          customer_id : input.customer_id,
          goal_dict_post : input.goal_dict_post,
          date:input.date
        },
        {
          headers: {
            'Authorization': input.access_token
          }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_last_index = input => {
    return axios
        .get('/alpha_admin/admin_last_index',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              flag:input.flag,
               id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_statistics_get = input => {
    return axios
        .get('/alpha_admin/admin_statistics',
        {
            headers: {
              'Authorization': input.access_token
            },
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_statistics_post = input => {
    return axios
        .post('/alpha_admin/admin_statistics', {
            access_token:input.access_token,
            statistic_data:input.statistic_data,
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_statistics_put = input => {
    return axios
        .put('/alpha_admin/admin_statistics', {
            access_token:input.access_token,
            row_id:input.row_id,
            cellName:input.cellName,
            cellValue:input.cellValue,
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_statistics_delete = input => {
    return axios
        .delete('/alpha_admin/admin_statistics',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              row_id:input.row_id,
            }

          },)
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const admin_sip_get = input => {
    return axios
        .get('/alpha_admin/admin_sip',
        {
            headers: {
              'Authorization': input.access_token
            },
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_sip_post = input => {
    return axios
        .post('/alpha_admin/admin_sip', {
            access_token:input.access_token,
            sip_data:input.sip_data,
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_sip_put = input => {
    return axios
        .put('/alpha_admin/admin_sip', {
            access_token:input.access_token,
            row_id:input.row_id,
            cellName:input.cellName,
            cellValue:input.cellValue,
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_sip_delete = input => {
    return axios
        .delete('/alpha_admin/admin_sip',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              row_id:input.row_id,
            }

          },)
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const admin_alpha_plans_get = input => {
    return axios
    .get('/alpha_admin/admin_alpha_plans',
    {
        headers: {
          'Authorization': input.access_token
        },
        params: {
          customer_id : input.customer_id,
          date:input.date
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
    }
export const admin_alpha_plans_post = input => {
  return axios
      .post('/alpha_admin/admin_alpha_plans', {
        customer_id : input.customer_id,
        alpha_dict : input.alpha_dict,
        date:input.date
      },
      {
        headers: {
          'Authorization': input.access_token
        }
      })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
// -----------------------------------------------------------
export const get_last_index = input => {
  return axios
      .get('/alpha_admin/last_index',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            flag:input.flag,
            id:input.id
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const get_mf_details = input => {
  return axios
      .get('/alpha_admin/mf_details',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            id:input.id,
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const save_mf_details = input => {
  return axios
      .post('/alpha_admin/mf_details', {
          access_token:input.access_token,
          mf_data:input.mf_data,
          id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const update_mf_details = input => {
  return axios
      .put('/alpha_admin/mf_details', {
          access_token:input.access_token,
          row_id:input.row_id,
          cellName:input.cellName,
          cellValue:input.cellValue,
          id:input.id
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const delete_mf_details = input => {
  return axios
      .delete('/alpha_admin/mf_details',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            row_id:input.row_id,
            id:input.id
          }

        },)
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const admin_buckets_growth = input => {
  return axios
      .get('/alpha_admin/admin_buckets_growth',
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const admin_buckets_growth_put = input => {
  return axios
      .put('/alpha_admin/admin_buckets_growth', {
        access_token:input.access_token,
        buckets_data:input.buckets_data
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const complaints = input => {
  return axios
      .post('/alpha_admin/complaints', {
        date                        :   input.date,
        beginning_of_the_month      :   input.beginning_of_the_month,
        Received_during_the_month   :   input.Received_during_the_month,
        Resolved_during_the_month   :   input.Resolved_during_the_month,
        end_of_the_month            :   input.end_of_the_month,
        Reasons_for_pendency        :   input.Reasons_for_pendency
      },
      {
          headers: {
            'Authorization': input.access_token
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
}
export const risk_assessment = input => {
  return axios
  .get('/alpha_admin/risk_assessment',
  {
      headers: {
        'Authorization': input.access_token
      },
      params: {
        customer_id : input.customer_id,
        date:input.date
      }
    })
  .then(response => {
      return response.data
  })
  .catch(err => {})
  }
export const risk_assessment_post = input => {
return axios
    .post('/alpha_admin/risk_assessment', {
      customer_id : input.customer_id,
      risk_dict : input.risk_dict,
      date:input.date
    },
    {
      headers: {
        'Authorization': input.access_token
      }
    })
    .then(response => {
        return response.data
    })
    .catch(err => {})
}
// export const ria_percentage = input => {
//   return axios
//   .get('/alpha_admin/ria_percentage',
//   {
//       headers: {
//         'Authorization': input.access_token
//       },
//       params: {
//         customer_id : input.customer_id,
//         email_address:input.email_address
//       }
//     })
//   .then(response => {
//       return response.data
//   })
//   .catch(err => {})
//   }
//   export const ria_percentage_post = input => {
//     return axios
//         .post('/alpha_admin/ria_percentage', {
//           customer_id : input.customer_id,
//           percentage : input.percentage,
//           email_address:input.email_address
//         },
//         {
//           headers: {
//             'Authorization': input.access_token
//           }
//         })
//         .then(response => {
//             return response.data
//         })
//         .catch(err => {})
//     }
    export const admin_ria_percentage_get = input => {
      return axios
          .get('/alpha_admin/ria_percentage',
          {
              headers: {
                'Authorization': input.access_token
              },
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const admin_ria_percentage_post = input => {
      return axios
          .post('/alpha_admin/ria_percentage', {
              access_token:input.access_token,
              id:input.id,
              email:input.email,
              percentage:input.percentage,
            },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    
    export const admin_ria_percentage_put = input => {
      return axios
          .put('/alpha_admin/ria_percentage', {
              access_token:input.access_token,
              row:input.row,
          },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    
    export const admin_ria_percentage_delete = input => {
      return axios
          .delete('/alpha_admin/ria_percentage',
          {
              headers: {
                'Authorization': input.access_token
              },
              params: {
                key:input.row,
              }
  
            },)
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const get_uploaded_docs_admin = input => {
      return axios
          .get('/alpha_admin/get_uploaded_docs_admin',
          {
              headers: {
                'Authorization': input.access_token
              },
              params: {
                id:input.id,
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }

    export const ria_status = input => {
      return axios
          .get('/alpha_admin/ria_status',
          {
              headers: {
                'Authorization': input.access_token
              },
              params: {
                id:input.id,
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const ria_status_put = input => {
      return axios
          .put('/alpha_admin/ria_status', {
              access_token:input.access_token,
              id : input.id,
              update_flag:input.update_flag
          },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const instrument_list = input => {
      return axios
          .get('/alpha_admin/instrument_list',
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const instrument_list_post = input => {
      return axios
          .post('/alpha_admin/instrument_list', {
              access_token:input.access_token,
              instrument_token:input.instrument_token
          },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const hoa_blogs_post = input => {
      return axios
          .post('/alpha_admin/hoa_blogs', {
              access_token:input.access_token,
              title:input.title,
              description:input.description,
              author:input.author,
              image:input.image
          },
          {
              headers: {
                'Authorization': input.access_token
              },
              body: input.image
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const hoa_blogs_get = input => {
      return axios
          .get('/alpha_admin/hoa_blogs',
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const hoa_blogs_put = input => {
      return axios
          .put('/alpha_admin/hoa_blogs', {
              access_token:input.access_token,
              row:input.row
          },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const hoa_blogs_delete = input => {
      return axios
          .delete('/alpha_admin/new_hoa_blogs',
          {
              headers: {
                'Authorization': input.access_token
              },
              params:{
                row:input.row
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const recommendations_data_get = input => {
      return axios
          .get('/alpha_admin/recommendations_data',
          )
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const video_links_post = input => {
      return axios
          .post('/alpha_user_website/video_links', {
              access_token:input.access_token,
              title:input.title,
              link:input.link,
              
          },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const video_links_get = input => {
      return axios
          .get('/alpha_user_website/video_links')
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const video_links_put = input => {
      return axios
          .put('/alpha_user_website/video_links', {
              access_token:input.access_token,
              row:input.row
          },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const video_links_delete = input => {
      return axios
          .delete('/alpha_user_website/video_links',
          {
              headers: {
                'Authorization': input.access_token
              },
              params:{
                row:input.row
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }


    export const hoa_blogs_post_new = input => {
      return axios
          .post('/alpha_admin/new_hoa_blogs', {
              access_token:input.access_token,
              title:input.title,
              value:input.value,
              image:input.image
              },
          {
              headers: {
                'Authorization': input.access_token
              },
          })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }

    export const hoa_blogs_get_new = input => {
      return axios
          .get('/alpha_admin/new_hoa_blogs',
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const hoa_blogs_put_new = input => {
      return axios
          .put('/alpha_admin/new_hoa_blogs', {
              access_token:input.access_token,
              detailData:input.detailData,
              image:input.image
          },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const hoa_contact_get= input => {
      return axios
          .get('/alpha_admin/requests_for_contact',
          {
              headers: {
                'Authorization': input.access_token,
              },
              params:{
                flag:input.flag
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }

    export const hoa_contact_put = input => {
      return axios
          .put('/alpha_admin/requests_for_contact', {
              access_token:input.access_token,
              row:input.row 
          },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }

    export const hoa_contact_dropdown_get= input => {
      return axios
          .get('/alpha_admin/contact_us_dropdown',
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }


    export const user_create = input => {
      return axios
          .post('/alpha_admin/user_create', {
              access_token:input.access_token,
              values:input.values
              },
          {
              headers: {
                'Authorization': input.access_token
              },
          })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const admin_roles= input => {
      return axios
          .get('/alpha_admin/admin_roles',
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const screen_management_data= input => {
      return axios
          .get('/alpha_admin/screen_management_status',
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    } 
    export const screen_management_data_put = input => {
      return axios
          .put('/alpha_admin/screen_management_status', {
              access_token:input.access_token,
              row:input.row
          },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    } 

    export const screen_management_user= input => {
      return axios
          .get('/alpha_admin/screen_management_user',
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const covered_call_options = input => {
      return axios
          .post('/alpha_admin/covered_call_options', {
              access_token:input.access_token,
              clientId:input.clientId,
              ccoList:input.ccoList,
              TotalInvestedValue:input.TotalInvestedValue
              },
          {
              headers: {
                'Authorization': input.access_token
              },
          })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const covered_call_options_get= input => {
      return axios
          .get('/alpha_admin/covered_call_options',
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const covered_call_options_put = input => {
      return axios
          .put('/alpha_admin/covered_call_options', {
              access_token:input.access_token,
              editCco:input.editCco
          },
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    } 
    export const covered_call_options_delete = input => {
      return axios
          .delete('/alpha_admin/covered_call_options',
          {
              headers: {
                'Authorization': input.access_token
              },
              params:{
                ccoRow:input.ccoRow
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const instrument_list_for_cco= input => {
      return axios
          .get('/alpha_admin/instrument_list_for_cco',
          {
              headers: {
                'Authorization': input.access_token
              }
            })
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const newsletter_get = input => {
      return axios
          .get('/alpha_admin/newsletter_upload')
          .then(response => {
              return response.data
          })
          .catch(err => {})
    }
    export const view_newsletter = input => {
      return axios
      .get('/alpha_admin/newsletter_upload',
      {
          headers: {
            'Authorization': input.access_token
          },
          params: {
            dir: input.dir
            
          }
        })
      .then(response => {
          return response.data
      })
      .catch(err => {})
      }
      
      export const newsletter_delete = input => {
        return axios
            .delete('/alpha_admin/newsletter_upload',
            {
                headers: {
                  'Authorization': input.access_token
                },
                params:{
                  date:input.row
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const newsletter_put = input => {
        return axios
            .put('/alpha_admin/newsletter_upload', {
                access_token:input.access_token,
                row:input.row,
                filename:input.newsletter
            },
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }

      export const subsciptions_get= input => {
        return axios
            .get('/alpha_admin/newsletter_subscriptions',
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const subsciptions_put = input => {
        return axios
            .put('/alpha_admin/newsletter_subscriptions', {
                access_token:input.access_token,
                row:input.row
            },
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const subscriptions_delete = input => {
        return axios
            .delete('/alpha_admin/newsletter_subscriptions',
            {
                headers: {
                  'Authorization': input.access_token
                },
                params:{
                  email:input.row
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const admin_billing = input => {
        return axios
            .post('/alpha_user_db/admin_billing', {
                access_token:input.access_token,
                values:input.values
                },
            {
                headers: {
                  'Authorization': input.access_token
                },
            })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const billing_get= input => {
        return axios
            .get('/alpha_user_db/admin_billing',
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const billing_put = input => {
        return axios
            .put('/alpha_user_db/admin_billing', {
                access_token:input.access_token,
                row:input.row
            },
            {
                headers: {
                  'Authorization': input.access_token
                },
                
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const getAdminComments = input => {
        return axios
            .get('/alpha_user_db/comments_admin',
            {
                headers: {'Authorization': input.access_token},
                params: {
                  customer_id:input.customer_id
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const admin_comment_put = input => {
        return axios
            .put('/alpha_user_db/comments_admin', {
                access_token:input.access_token,
                row:input.row
            },
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const admin_succession = input => {
        return axios
            .post('/alpha_user_db/succession_readiness', {
                access_token:input.access_token,
                values:input.values
                },
            {
                headers: {
                  'Authorization': input.access_token
                },
            })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const admin_billing_history = input => {
        return axios
            .post('/alpha_user_db/dashboard_billing', {
                access_token:input.access_token,
                values:input.values
                },
            {
                headers: {
                  'Authorization': input.access_token
                },
            })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const billing_history_put = input => {
        return axios
            .put('/alpha_user_db/dashboard_billing_history', {
                access_token:input.access_token,
                row:input.row
            },
            {
                headers: {
                  'Authorization': input.access_token
                },
                
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const dashboard_billing_history= input => {
        return axios
            .get('/alpha_user_db/dashboard_billing_history',
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const asset_irr_get = input => {
        return axios
            .get('/alpha_admin/asset_irr',
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const asset_irr_update = input => {
        return axios
            .put('/alpha_admin/asset_irr', {
                access_token:input.access_token,
                row:input.row
            },
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const income_irr_get = input => {
        return axios
            .get('/alpha_admin/income_irr',
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const income_irr_update = input => {
        return axios
            .put('/alpha_admin/income_irr', {
                access_token:input.access_token,
                row:input.row
            },
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const documents_dropdown = input => {
        return axios
            .get('/alpha_admin/documents_dropdown',
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const customer_documents_upload_and_download = input => {
        return axios
            .get('/alpha_admin/customer_documents_upload_and_download',
            {
                headers: {
                  'Authorization': input.access_token
                },
                params: {
                    id:input.id,
                    doc_id:input.doc_id,
                  }
    
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const view_and_download_docs = input => {
        return axios
            .get('/alpha_admin/view_and_download_docs',
            {
                headers: {
                  'Authorization': input.access_token
                },
                params: {
                    id:input.id,
                    key:input.key,
                  }
    
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const documents_list_dropdown = input => {
        return axios
            .get('/alpha_admin/documents_list_dropdown',
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
import React,{useState,useEffect} from 'react'
import './Dashboard.scss'
import './MediaQuery.scss'
import {user_profile_status} from '../Setup-journey/Api'
import {useNavigate} from 'react-router-dom'
import { Menu,Row, Col,Breadcrumb,Avatar, Dropdown,Tooltip,Drawer,Modal } from 'antd';
import {DownOutlined , FullscreenOutlined } from '@ant-design/icons';
import { MDBIcon } from 'mdbreact';
import logo from "../../Images/logoBlack.png"
import logoSingle from "../../Images/hoa_logo_single.png"

import wealthI from "../../Images/wealthI.png"
import wealthIB from "../../Images/wealthIB.png"
import assetI from "../../Images/assetI.png"
import assetIB from "../../Images/assetIB.png"
import riskI from "../../Images/riskI.png"
import riskIB from "../../Images/riskIB.png"
import loanI from "../../Images/loanI.png"
import loanIB from "../../Images/loanIB.png"
import cashflowI from "../../Images/cashflowI.png"
import cashflowIB from "../../Images/cashflowIB.png"
import wgI from "../../Images/wgI.png"
import wgIB from "../../Images/wgIB.png"
import srfoI from "../../Images/srfoI.png"
import srfoIB from "../../Images/srfoIB.png"
import billingI from "../../Images/billingI.png"
import billingIB from "../../Images/billingIB.png"
import commentI from "../../Images/commentI.png"
import commentIB from "../../Images/commentIB.png"
import User from "../../Images/user.png"

import Wealth360 from './wealth360/Wealth360';
import Assets from './assets/Assets';
import Cover from './cover/Cover';
import Loans from './loan/Loans';
import Cashflow from './cashflow/Cashflow';
import Wg from './wg/Wg';
import SRFO from './srfo/SRFO'
import Billing from './billing/Billing';
import Comment from './comment/Comment'
import ReportHome from './reporting/ReportHome'
import Edit from '../EditScreens/EditScreens'
import ChangeEmail from '../Setting/ChangeEmail'
import ChangePassword from '../Setting/ChangePassword'
import ReactFullscreen from 'react-easyfullscreen';
import {ria_agreement_renew} from '../Compliance/Api'

// import {holding_report} from './reporting/ReportsApi'
// import {useSelector,useDispatch} from 'react-redux'
// import {setHoldings} from '../../redux/actions/index'

const Dashboard = (props) => {
  const{id} = props
  const[sideTab,setSideTab] =useState({wealth360:false,assets:true,cover:false,loans:false,
                                       cashflow:false,wg:false,srfo:false,billing:false,comment:false,reports:false})
  const [menuKey,setMenuKey] = useState('Assets')
  const[collapsed,setCollapsed] =  useState(false)
  const[modal,setModal] = useState({emailModal:false,passwordModal:false})
  const [visible, setVisible] = useState(false);
  const[modalType,setModalType] = useState('')
  const[renew,setRenew] = useState(false)
  const[showRia,setShowRia] = useState(false)
  // const [cDate, setDate] = useState(new Date().toISOString().split('T')[0]);

  const navigate = useNavigate()
  // const dispatch = useDispatch();

  const fetchStatus = async () =>{
    const input = {access_token:localStorage.token}
    const res = await user_profile_status(input)
    .catch((err) => {console.log("Err: ", err)});
    try{
      if (res.status==='successfull' && res.result.progress<=100){
         navigate('/setup_journey')
      }else{}
    }catch{}
  }

  const getRiaFormRenew = async() =>{
    const input = {
        access_token:localStorage.token,
        id:id
    }
    const res = await ria_agreement_renew(input)
    console.log(input);
    console.log('renew',res);
    try{
        if (res.status==='successfull') 
            if(res.result.flag==='Y'){
              setRenew(true)
            }
      else{}
    }catch{}
} 

  const showDrawer = (type) => {
    setVisible(true);
    setModalType(type)
}
  const onClose = ()=>{
    setVisible(false);
  }

  const handleTab=(name)=>{
    Object.keys(sideTab).map(item => {
        if(item===name){setSideTab(prevState => ({...prevState,[item]: true}))
        }else{setSideTab(prevState => ({...prevState,[item]: false}))}
   });
  }
  const handleModal=(name)=>{
    Object.keys(modal).map(item => {
      if(item===name){setModal(prevState => ({...prevState,[item]: true}))
      }else{setModal(prevState => ({...prevState,[item]: false}))}
    }); 
  }
  const  toggleCollapsed = () => {
    setCollapsed(!collapsed)
  };
  const menuSelect =(item)=>{
    setMenuKey(item.key)
  }
  const logOut=()=>{
    localStorage.clear();
    navigate('/')
  }
  
  // const fetchHoldings = async(date)=>{
  //   const input = {
  //       access_token:localStorage.token,
  //       id:props.customer_id,
  //       date:date
  //   }
  //   console.log(input);
  //   const res = await holding_report(input)
  //   .catch((err) => {console.log("Err: ", err)});
  //   try{
  //     if (res.status==='successfull'){
  //       dispatch(setHoldings(res.result))
  //     }else{
  //       dispatch(setHoldings({}))
  //     }
  //   }catch{
  //     dispatch(setHoldings({}))
  //   }
  // }

  const renewClose=()=>{
    setRenew(false)
  }
  useEffect(()=>{
    fetchStatus()
    getRiaFormRenew()
    // fetchHoldings(cDate)
  },[])
  const setting = (
    <Menu>
      {/* <Menu.Item onClick={()=>handleModal('emailModal')}>
      <span className='dropDItem'><MDBIcon fas icon="at" className='dropDItemI'/>Change Email</span></Menu.Item>  */}
      <Menu.Item onClick={()=>handleModal('passwordModal')}>
      <span className='dropDItem'><MDBIcon fas icon="key" className='dropDItemI'/>Change Password</span></Menu.Item>
      <Menu.Item key='Edit' onClick={()=>showDrawer('Edit Client Data')}>
      <span className='dropDItem'><MDBIcon fas icon="edit" className='dropDItemI'/>Edit</span>
      </Menu.Item>

      <Menu.Item key='FullScreenMode'>
        <ReactFullscreen>
          {({ ref, onRequest, onExit }) => (
            <div className='dropDItem' onClick={onRequest}><FullscreenOutlined /> Screen Mode</div>
          )}
        </ReactFullscreen>
      </Menu.Item>

      <Menu.Item onClick={logOut}>
        <span className='dropDItem'><MDBIcon fas icon="sign-out-alt" className='dropDItemI'/>Log Out</span>
      </Menu.Item>
    </Menu>
  );

  const TopBar = ()=>{
    return(
      <Row gutter={[16, 16]} style={{paddingLeft:'5px'}}>
          <Col span={8}>
            <h5 className='dashboardPageTitle'>{menuKey}</h5>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>{menuKey}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={8}>
            <>
            {props.admin===1?<>
            <p className='dashboardPageTitle_2'>Purpose / Goal</p>
            <Tooltip placement='top' title='To spend on key life events in the best way possible, maintain the desired lifestyle without compromises, and never have to worry about not having enough or running out of money, whatever happens.'>
              <p className='dashboardPageSubTitle'>To spend on key life events in the best way possible...</p>
            </Tooltip></>:null}
            </>
          </Col>
          <Col span={8}>
            {props.admin===1?
              <div>
              <Avatar src={User} />
              <span className='salutationName'>{props.email_address}</span>
              <span className='TableActionButton' onClick={props.close} style={{marginLeft:'15px'}}>Close</span>
            </div>:
            <Dropdown overlay={setting} placement="bottomCenter" trigger={['click']}>
              <div style={{cursor:'pointer'}}>
                <Avatar src={User} />
                <span className='salutationName'>{localStorage.email}</span>
                <DownOutlined className='salutationIcon'/>
              </div>
            </Dropdown>}
          </Col>
      </Row>
    )
  }

  const showDrawerRia = (type,Ri) => {
    setVisible(true);
    setModalType(type)
    setShowRia(true)
}
  return (
    <div className='parentDivBg'>
      {props.admin===1?<div style={{marginTop:'10px'}}></div>:null}
      {renew?(
      <div>
        <Modal  footer={null} visible={renew} onCancel={()=>renewClose()}>
          <p className='p-4'>Your Investment Advisory Agreement is up for renewal. Kindly <button className='renewLink' onClick={()=>showDrawerRia('Edit Client Data','Y')}>click here</button> to renew your agreement .  If you have any questions or need further clarification, please feel free to call or message us.<br/><br/>
          We appreciate your trust in our services, and we look forward to continuing this relation.</p>
       
        </Modal>
      </div>):null}

      {/* {props.admin===1?(
        <div className='modalHeadBar'>
        <Row align='middle'>
          <Col span={6}>
            <div className='modalHeadBarTitle'>{props.tabName}</div>
          </Col>
          <Col span={12}></Col>
          <Col span={5}>
            <div className='modalHeadBarTitle'>{props.cusName}</div>
            <p className='modalHeadBarP'>{props.cusEmail}</p>
          </Col>
          <Col span={1}>
            <div className='TableActionButton' onClick={props.close}>close</div>
          </Col>
        </Row>
      </div>
      ):null} */}

      <div className={collapsed?'sideBarClose':'sideBarOpen'}>
        <div className='sidebarLogo'>
          {collapsed?<img src={logoSingle} alt=""/>:<img src={logo} alt=""/>}
        </div>
        
        <Menu
            defaultSelectedKeys={['Assets']}
            mode='inline'
            inlineCollapsed={collapsed}
            style={{backgroundColor:'#fff',height:'100%',color:'#000'}}
            onSelect={menuSelect}
          >
  
          <Menu.Item key="Assets" onClick={()=>handleTab('assets')}
            icon={menuKey==='Assets'?<img src={assetI} alt='' className='newSidebarIcon'/>:<img src={assetIB} alt='' className='newSidebarIcon'/>}><span className='sidebarMenuTitle'>Assets</span>
          </Menu.Item> 

          {props.admin===1?<>
          <Menu.Item key="Wealth 360"  icon={menuKey==='Wealth 360'?<img src={wealthI} alt='' className='newSidebarIcon'/>:<img src={wealthIB} alt='' className='newSidebarIcon'/>} onClick={()=>handleTab('wealth360')}><span className='sidebarMenuTitle'>Wealth 360</span>
          </Menu.Item>

          <Menu.Item key="Risk Cover"  onClick={()=>handleTab('cover')}
          icon={menuKey==='Risk Cover'?<img src={riskI} alt='' className='newSidebarIcon'/>:<img src={riskIB} alt='' className='newSidebarIcon'/>}>
            <span className='sidebarMenuTitle'>Risk Cover</span> 
          </Menu.Item>

           <Menu.Item key="Loans"  onClick={()=>handleTab('loans')}
          icon={menuKey==='Loans'?<img src={loanI} alt='' className='newSidebarIcon'/>:<img src={loanIB} alt='' className='newSidebarIcon'/>}>
            <span className='sidebarMenuTitle'>Loans</span> 
          </Menu.Item> 

          <Menu.Item key="Cashflow" icon={menuKey==='Cashflow'?<img src={cashflowI} alt='' className='newSidebarIcon'/>:<img src={cashflowIB} alt='' className='newSidebarIcon'/>} onClick={()=>handleTab('cashflow')}><span className='sidebarMenuTitle'>Cashflow</span>
          </Menu.Item> 

          <Menu.Item key="Wealth Growth" icon={menuKey==='Wealth Growth'?<img src={wgI} alt='' className='newSidebarIcon'/>:<img src={wgIB} alt='' className='newSidebarIcon'/>} onClick={()=>handleTab('wg')}><span className='sidebarMenuTitle'>Wealth Growth</span>
          </Menu.Item> 

          <Menu.Item key="Succession Details" icon={menuKey==='Succession Details'?<img src={srfoI} alt='' className='newSidebarIcon'/>:<img src={srfoIB} alt='' className='newSidebarIcon'/>} onClick={()=>handleTab('srfo')}><span className='sidebarMenuTitle'>Succession Details</span>
          </Menu.Item> 

          <Menu.Item key="Billing" icon={menuKey==='Billing'?<img src={billingI} alt='' className='newSidebarIcon'/>:<img src={billingIB} alt='' className='newSidebarIcon'/>} onClick={()=>handleTab('billing')}><span className='sidebarMenuTitle'>Billing</span>
          </Menu.Item> 
          
          <Menu.Item key="Reports" icon={menuKey==='Reports'?<img src={commentI} alt='' className='newSidebarIcon'/>:<img src={commentIB} alt='' className='newSidebarIcon'/>} onClick={()=>handleTab('reports')}><span className='sidebarMenuTitle'>Reports</span>
          </Menu.Item> 

          <Menu.Item key="Comments" icon={menuKey==='Comments'?<img src={commentI} alt='' className='newSidebarIcon'/>:<img src={commentIB} alt='' className='newSidebarIcon'/>} onClick={()=>handleTab('comment')}><span className='sidebarMenuTitle'>Comments</span>
          </Menu.Item> 
          </>:null}
        

          <div className='sidebarToggleButton'>
            {collapsed?
          <MDBIcon icon="chevron-circle-right" className='sideToggBtnI'  onClick={toggleCollapsed}/>:
            <><MDBIcon icon="chevron-circle-left" className='sideToggBtnI' onClick={toggleCollapsed}/> 
            <span>Collapse</span></>}
          </div>
        </Menu>
      </div>
    
      <div class={collapsed?'dashboardContentAreaClose':'dashboardContentAreaOpen'}>
        <TopBar/>
        <div className='PageArea scrollB'>
          {sideTab.wealth360?  <Wealth360 id={props.id}/>:null}
          {sideTab.assets?<Assets id={props.id}/>:null}
          {sideTab.cover?<Cover id={props.id}/>:null} 
          {sideTab.loans?<Loans id={props.id}/>:null}
          {sideTab.cashflow?<Cashflow id={props.id}/>:null}  
          {sideTab.wg?<Wg id={props.id}/>:null}
          {sideTab.srfo?<SRFO id={props.id}/>:null}  
          {sideTab.billing?<Billing id={props.id}/>:null}
          {sideTab.comment?<Comment id={props.id}/>:null}
          {sideTab.reports?<ReportHome id={props.id}/>:null}
        </div>
    </div> 
    <Drawer title={modalType}  placement="top" height={'100%'} onClose={onClose} visible={visible} headerStyle={{backgroundImage:'linear-gradient(to bottom,#FDEBD0,#FDEBD0)'}}>
    {modalType==='Edit Client Data'?<Edit showRia={showRia}/>:null}
    </Drawer>

    <Modal  footer={null} visible={modal.emailModal || modal.passwordModal} onCancel={()=>handleModal('close')}>
      {modal.passwordModal? <ChangePassword/>:null}
    </Modal>


</div>
  )
}

export default React.memo(Dashboard)
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-input {
  display: inline-block;
  text-align: left;
  background: #fff;
  padding: 16px;
  width: 100%;
  position: relative;
  border-radius: 10px;
}

.file-input > [type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 10;
  cursor: pointer;
}

.UplCard {
  border: 1px solid #dcdede;
  background-color: #F8F9F9;
  padding: 10px;
}

.upld-btn {
  margin: 10px 0px;
  padding: 8px 20px;
  border: 2px solid #db9d00;
  color: #db9d00;
}

.textAreaBox {
  margin: 0px 7px 0px 15px;
  padding: 0px 7px 0px 7px;
  height: 75vh;
  overflow: hidden;
  border-right: 1px dotted lightgrey;
}

.buttonC {
  text-align: center;
  margin-top: 5px;
}

.buttonC button {
  background-color: #db9d00;
  padding: 5px 20px;
  color: white;
  font-weight: 600;
  outline: none;
  border-radius: 3px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Admin/Reports/Report.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAGE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;EACA,eAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,yBAAA;EACA,aAAA;AAAJ;;AAEA;EACY,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,cAAA;AACZ;;AAEA;EAEE,wBAAA;EACA,wBAAA;EACA,YAAA;EACA,gBAAA;EACA,kCAAA;AAAF;;AAEA;EACE,kBAAA;EACA,eAAA;AACF;;AACA;EACE,yBAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;AAEF","sourcesContent":[".file-input {\n    display: inline-block;\n    text-align: left;\n    background: #fff;\n    padding: 16px;\n    width: 100%;\n    position: relative;\n    border-radius: 10px;\n   \n  }\n  \n  .file-input > [type='file'] {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    opacity: 1;\n    z-index: 10;\n    cursor: pointer;\n  }\n  \n.UplCard{\n    border: 1px solid #dcdede;\n    background-color: #F8F9F9;\n    padding: 10px;\n  }\n.upld-btn{\n            margin: 10px 0px;\n            padding: 8px 20px;\n            border: 2px solid #db9d00;\n            color: #db9d00;\n\n}\n.textAreaBox{\n  // border: 1px solid grey;\n  margin: 0px 7px 0px 15px;\n  padding: 0px 7px 0px 7px;\n  height: 75vh;\n  overflow: hidden;\n  border-right: 1px dotted lightgrey;\n}\n.buttonC{\n  text-align: center;\n  margin-top: 5px;\n}\n.buttonC button{\n  background-color: #db9d00;\n  padding: 5px 20px;\n  color: white;\n  font-weight: 600;\n  outline: none;\n  border-radius: 3px;\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

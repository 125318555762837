import React,{useState,useEffect} from 'react'
import '../Tabs.scss'
import {Table,Input,Space,Button} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const IncomeBreakup = (props) => {
    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')
    const [data, setData] = useState([]);

    const numberFormat=(value)=>{
      var val = Math.abs(value)
      if (val >= 10000000) {
        val = (val / 10000000).toFixed(2) + ' Cr';
      } else if (val >= 100000) {
        val = (val / 100000).toFixed(2) + ' L';
      }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
      return val;
    }

    let searchInput = ''
    useEffect(()=>{
      let arr= []
        Object.entries(props.tableData).map((val)=>arr.push(val[1]))
        setData(arr)
    },[])

    let getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => {
                handleReset(clearFilters)
                confirm({ closeDropdown: false });
                // setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                }} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#e87c1b' : '#000' }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
      };
    const  handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };

    const summaryColumns = [
        {title: 'SR. NO',dataIndex: 's_no',width: 60, ...getColumnSearchProps('s_no')},
        {title: 'INCOME HEAD',dataIndex: 'income_type',width: 150,...getColumnSearchProps('income_type')},
        {title: 'AMOUNT',dataIndex: 'amount',width: 120,...getColumnSearchProps('amount'),
        render: total => { return numberFormat(total)}},
        {title: 'GROWTH RATE',dataIndex: 'growth_rate',width: 120,...getColumnSearchProps('growth_rate'),
        render: growth_rate => { return growth_rate + ' %'}},
        {title: 'CONTINUES TILL',dataIndex: 'continues_till',width: 120,...getColumnSearchProps('continues_till')}
      ];

  
  return (
    <>
        <p className='tableTitle'>Income Breakup Summary</p>
       
          <Table rowClassName="antTable" columns={summaryColumns} dataSource={data} size='small'
              // pagination={{pageSize: 5,position:['bottomCenter']}} 
              scroll={{y: 200}}
              pagination={false} 
              sticky={true}

              summary={(pageData) => {
                let Total = 0;
                pageData.forEach(({ amount}) => {
                  Total = Total+amount;
                });
  
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell ></Table.Summary.Cell>
                      <Table.Summary.Cell className='tableTotal'>Total</Table.Summary.Cell>
                         <Table.Summary.Cell className='tableTotal'>{numberFormat(Total)}</Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
          />
    </>
  )
}

export default React.memo(IncomeBreakup)
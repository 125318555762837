import React,{useState,useEffect} from 'react'
import './FinancialProfile.scss'
import Loader from '../../shared/LoaderTransparent';
import { post_srfo,family_members,get_srfo_details,delete_srfo_details} from './Api';
import { Col,Row,Button, Form, Input,Select } from 'antd';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MDBIcon} from 'mdbreact'

const AssetSrfo = (props) => {
    const [data,setdata] = useState({})
    const [tableData,setTableData] = useState([{id:1}])
    const [familyDropDown,setFamilyDropDown] = useState({})
    const[loading,setLoading] = useState(false)

    const { Option } = Select;
    const toastMsg =(id,msg,type)=>{
        toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    } 

    const updateTableDataSel=(value,val,name)=>{
        let aa  = [...tableData]
        aa[val][name] = value
        setTableData(aa)
    }
    const updateTableData=(e)=>{
        let aa  = [...tableData]
        aa[e.target.id][e.target.name] = e.target.value
        setTableData(aa)
    }
    const addRow = () =>{
        let aa = [...tableData]
        let len = aa.length
        let rIn = aa[len-1].row_index+1
        var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
        var product = {id: id,row_index:rIn}
        aa.push(product);
        setTableData(aa)
    }
    const onSubmitLogin = async (values)=>{
        const id = toast.loading("Please wait...") 
        const input = {
            access_token:localStorage.token,
            values:values,
            srfoValues:props.srfoValues,
            fields:tableData,
            id:props.id
        }
        console.log(input);
          const res = await post_srfo(input)
          .catch((err) => {toastMsg(id,res.message,'error')});
          console.log('post',res);
          try{
            if (res.status==='successfull') {
                toastMsg(id,res.message,'success')
            }else{
              toastMsg(id,res.message,'error')
            }
          }catch{
            toastMsg(id,'Something went wrong','error')
          }
    }

    const fetchFamilyMember = async () =>{
        const input = {access_token:localStorage.token,id:props.id}
        console.log(input);
        const res = await family_members(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('family',res);
        try{
          if (res.status==='successfull'){
            setFamilyDropDown(res.result)
          }else{}
        }catch{}
    }
    const fetchSrfo = async () =>{
        setTableData([{id:1}])
        setLoading(true)
        let td = []
        const input = {
            access_token:localStorage.token,
            asset_row_id:props.asset_row_id,
            asset_type:props.srfoValues.asset_type,
            id:props.id
        }
        const res = await get_srfo_details(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log(input,res);
        try{
          if (res.status==='successfull'){
            Object.keys(res.result.srfo_values).map((val)=>{
                td.push({'nominee':res.result.srfo_values[val].nominee,
                'nominee_percetage':res.result.srfo_values[val].nominee_percetage,
                'joint_holder_if_any':res.result.srfo_values[val].joint_holder_if_any,
                'joint_holder_percentage':res.result.srfo_values[val].joint_holder_percentage,
                'row_index':res.result.srfo_values[val].row_index
            })
            })
            setdata(res.result)
            setLoading(false)
          }else{ setLoading(false)}
        }catch{ setLoading(false)}
        setTableData(td)
    }

    const deleteSingle=(val)=>{
        const aa = [...tableData];
        // console.log(aa);
        if(aa[val].nominee_percetage && aa[val].nominee){
        const input = {
            access_token:localStorage.token,
            id:props.id,
            row:aa[val], 
        }
        // console.log(aa);
        
        delete_srfo_details(input).then(res => {
            console.log("delete",res)
            try{
                if (res.status==='successfull') {
                    toast.success(res.message)
                    fetchSrfo()
                }
                else{toast.error(res.message)}
            }catch{
                toast.error("Something went wrong")
                setLoading(false)
            }
        })
        }
        else{ 
            aa.splice(val,1);
            setTableData(aa)}
    }


    useEffect(()=>{
        fetchFamilyMember()
        fetchSrfo()
    },[props.asset_row_id])

  return (
    <>
        <ToastContainer theme='colored'/>
        <div className='form-heading'>Add Investment Details</div>
        {loading?<Loader/>:
        <Form onFinish={onSubmitLogin}>
            <Row gutter={[10, 0]}>
                <Col span={10}>
                    <div className='wgFormLabel'>Address</div>
                    <Form.Item
                        name="address"
                        rules={[{ required: true, message: 'Please input your Address!' }]}
                        initialValue={data.address}>
                        <Input 
                        prefix={<MDBIcon fas icon="map-marker-alt" className='loginFormIcon'/>}
                        placeholder="Address" className='wgFormInput'/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <div className='wgFormLabel'>Email</div>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your Email Address!' }]}
                        initialValue={data.email}>
                        <Input 
                        prefix={<MDBIcon icon="at" className='loginFormIcon' />}
                        placeholder="Email" className='wgFormInput'
                         />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <div className='wgFormLabel'>Contact No</div>
                    <Form.Item
                        name="contact_no"
                        rules={[{ required: true, message: 'Please input your Contact No!' }]}
                        initialValue={data.contact_no}>
                        <Input 
                        prefix={ <MDBIcon fas icon="phone-alt" className='loginFormIcon'/>}
                        placeholder="Contact No" className='wgFormInput' />
                    </Form.Item>
                </Col>
            </Row>
            <div className="formButtonDisable" style={{marginBottom:'5px'}}>
                <button type="button" onClick={addRow}>Add +
                        {/* <MDBIcon icon="plus" className='form-edt-icon' />Add Row  */}
                </button>
            </div>
            <table className="table table-condensed table-sm table-bordered">
                <tr className='tb_header'>
                    <td>Nominee</td>
                    <td>Nominee Percentage</td>
                    <td>Joint Holder</td>
                    <td>Joint Holder Percent</td>
                    <td></td>
                </tr>
                <tbody>
                {Object.keys(tableData).map((val) =>{
                    return(
                        <tr className='tab-tr'> 
                            <td className='tab-td td-lf-border' style={{minWidth:'120px',maxWidth:'120px'}}>
                                <Select
                                    bordered={false}
                                    className='selectborder'
                                    placeholder="Select Nominee"
                                    defaultValue={tableData[val].nominee}
                                    size='medium'
                                    style={{minWidth:'100px',maxWidth:'120px'}}
                                    onChange={(value) => updateTableDataSel(value,val,'nominee')}
                                >
                                {familyDropDown?<>
                                    {Object.entries(familyDropDown).map((member)=>{
                                        return <Option value={member[0]}>{member[1]}</Option>
                                        })}
                                </>:null}
                                </Select>
                            </td>

                            <td className='tab-td td-lf-border' style={{minWidth:'60px',maxWidth:'60px'}}>
                                <input name='nominee_percetage' type='number' placeholder='0' id = {val}  onChange={updateTableData} className='select-width' value={tableData[val].nominee_percetage}/>
                            </td>

                            <td className='tab-td td-lf-border' style={{minWidth:'120px',maxWidth:'120px'}}>
                                <Select
                                style={{minWidth:'100px',maxWidth:'120px'}}
                                    bordered={false}
                                    className='selectborder'
                                    placeholder="Select joint Holder"
                                    defaultValue={tableData[val].joint_holder_if_any}
                                    onChange={(value) => updateTableDataSel(value,val,'joint_holder_if_any')}
                                >
                                    {familyDropDown?<>
                                        {Object.entries(familyDropDown).map((member)=>{
                                            return <Option value={member[0]}>{member[1]}</Option>
                                            })}
                                    </>:null}
                                </Select>
                            </td>

                            <td className='tab-td td-lf-border' style={{minWidth:'60px',maxWidth:'60px'}}><input name='joint_holder_percentage' type='number' placeholder='0' id = {val} onChange={updateTableData} className='select-width' value={tableData[val].joint_holder_percentage}/></td>

                            <td>
                            <MDBIcon far icon="trash-alt" className="assetDelIcon" onClick={()=>deleteSingle(val)}/>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
        <hr/>
            <Form.Item style={{textAlign:'right'}}>
              <Button htmlType="submit" className="modalSubmitButton">Submit</Button>
            </Form.Item>
        </Form> }
    </>
  )
}

export default AssetSrfo
import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import {documents_dropdown,customer_review} from '../Api'
import CropRotateImage from '../../../shared/CropRotateImage'
import './DocsUpload.scss'
import { Upload, Button, message, Row, Col,Table,Space,Tooltip,Input,Select,Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import { UploadOutlined,SearchOutlined,DownloadOutlined,EyeOutlined} from '@ant-design/icons';
import {MDBIcon} from 'mdbreact'

const FileUploads = () => {

  const [data,setData] = useState([])
  const [clientData, setClientData] = useState([])
  const [holdingUploading,setHoldingUploading] = useState(false)
  const [clientId,setClientId] = useState('')
  const [fileName,setFileName] = useState('')
  const [showInput, setShowInput] = useState(false);
  const [showInput2, setShowInput2] = useState(false);
  const [file, setFile] = useState(null);
  
  let searchInput = ''

  const fileInputRef = useRef();

  useEffect(()=>{
      const input = {
          access_token:localStorage.token
      }
      let newdata = [...data]
      documents_dropdown(input).then(res => {
          console.log(res,'ress')
      try{
          if (res.status==='successfull'){
              Object.keys(res.result).map((val)=>{
                  newdata.push({ 
                  key: res.result[val].customer_id,
                  dropdown:res.result[val],
                  fileid:val
              })
              })
              setData(newdata)
          }else{}
      }catch{}
      })
      const input2 = {
        access_token:localStorage.token
    }
    let newdata2 = [...clientData]
    customer_review(input2).then(res => {
        console.log(res,'ress')
    try{
        if (res.status==='successfull'){
            Object.keys(res.result).map((val)=>{
                newdata2.push({ 
                key: res.result[val].customer_id,
                id: res.result[val].customer_id,
                name:res.result[val].customer_fullname,
                email_address:res.result[val].email_address,
                zerodha:res.result[val].zerodha
            })
            })
            setClientData(newdata2)
        }else{}
    }catch{}
    })
  },[]
  )


   const handleFileUpload = () => {
        const formData = new FormData();
        formData.append('doc',file);
        formData.append('id', clientId)
        formData.append('doc_id',fileName)
        setHoldingUploading(true)
        console.log(fileName);
        fetch('/alpha_admin/customer_documents_upload_and_download', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': localStorage.token
          },
        })
          .then(res => res.json())
          .then((data) => {
            if(data.status==='successfull')
           
            { 
              console.log(data)
              message.success(data.message)
              fileInputRef.current.value = ''
              setFileName(null)
            
              }
            if(data.status==='failed'){
            message.error(data.message)}
            setHoldingUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setHoldingUploading(false);
          })
          .finally(() => {});
      };

     
   
      
      const updateclientID=(value)=>{
        setClientId(value)
        setShowInput2(true);
    }
    const updatefileName=(value)=>{
      setFileName(value)
      setShowInput(true);
  }
  

 


      const { Option } = Select;
  return (  
    <>
    <Row>
    <Select
        style={{ width: 500 }}
        showSearch
        bordered={true}
        title='Client Mail ID'
        className='selectborder'
        placeholder="Select Client Mail ID"
        optionFilterProp="children"
        onChange={updateclientID}
        filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        // size='large'
    >
    {Object.keys(clientData).map((val)=>{
        return <Option value={clientData[val].id}>{clientData[val].email_address}, <b>{clientData[val].name}</b></Option>
        })}
    </Select>
    <span style={{width:10}}></span>
    <Select
        style={{ width: 350 }}
        showSearch
        bordered={true}
        title='Client Mail ID'
        className='selectborder'
        placeholder="Select Document"
        optionFilterProp="children"
        onChange={updatefileName}
        filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        // size='large'
    >
    {Object.keys(data).map((val)=>{
        return <Option value={data[val].fileid}>{data[val].dropdown}</Option>
        })}
    </Select>
    </Row>
    {showInput && showInput2?<>
    <Row>
      <Col span={6}>
      <div className='equityTitleDiv'>
        <div className='equityTitleheadUpload'>Upload Here</div>
        <div className='equityDocsMainDiv'>
           <input className='uploadField' icon={<UploadOutlined />} ref={fileInputRef} type="file" onChange={(e) =>setFile(e.target.files[0])} />
      <button className='reportBtn' onClick={handleFileUpload}>Upload</button>
          </div>
          </div>
      </Col>
      </Row> 
      </>:null}

         
    </>
  )
}

export default FileUploads

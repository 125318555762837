import React, { useState,useEffect } from 'react'
import {hoa_blog} from '../Api'
import Navbar from '../Universal/Navbar'
import Footer from '../Universal/Footer'
import parse from 'html-react-parser';
import {useParams} from 'react-router-dom'
import Loader from '../../../shared/Loader'
import { MDBIcon } from 'mdbreact';

const SingleBlog = (props) => {
    const [bloglist, setBloglist] = useState({})
    const [loading,setLoading] = useState(false)
    const [like,setLike] = useState(false)
    const [dislike,setDislike] = useState(false)

    let { id } = useParams();

    const getBlogList=()=>{
        setLoading(true)
        const input = {title:id}
        hoa_blog(input).then(res => {
            console.log("bloglistsingle",res)
            try{
            if (res.status==='successfull') {
                setBloglist(res.result)
                setLoading(false)
            }
            else{ setLoading(false)}
            }catch{ setLoading(false)}
            })
    }
    useEffect(()=>{
        getBlogList();
    },[])

    const handleLike=()=>{
        setLike(true)
        setDislike(false)
  }
  const handleDislike=()=>{
        setDislike(true)
        setLike(false)
}
    
    return (
        <>
        {loading?<Loader/>:null}
        <div><Navbar /></div>
            {Object.keys(bloglist).length>0?         
                <div className='expertSec'>
                    <div className='row'>
                        <div className='col-md-1'></div>
                        <div className='col-md-12'>
                            <div className='singleBlogPage'>
                                <div className='singleBlogTitle'>{bloglist.title}</div>
                                <div className='singleBlogDesc'>{parse(bloglist.value)}</div>
                            </div>
                        </div>
                        <div className='col-md-1'></div>
                    </div>
                    <div className='articleDiv'>Did you find this article helpful? 
                        <span className='articleSpan'>
                            <MDBIcon fas icon="thumbs-up" className={like?'thumbIA':'thumbI'} onClick={handleLike}/>
                            <MDBIcon fas icon="thumbs-down" className={dislike?'thumbIA':'thumbI'} onClick={handleDislike}/>
                        </span>
                    </div>
                </div>:<div style={{height:'60vh',width:'100%'}}></div>}
        <div><Footer /></div>
        </>
    )
}

export default SingleBlog

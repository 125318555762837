import React,{useState,useEffect} from 'react'
import {MDBIcon} from 'mdbreact'
import {save_goals_details,get_goals_details} from './Api'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../shared/Loader';
import CurrencyInput from 'react-currency-input-field';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GoalExel = (props) => {
    const {id,goals,liability,step5,update} = props

    const [dataDict,setDataDict] = useState({})
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [successmsg,setSuccessmsg] = useState('')
    const [errormsg,setErrormsg] = useState('')
    const [loading,setLoading] = useState(false)
    const [disabled,setDisabled] = useState(false)
    const [userLifeEvent,setUserLifeEvent] = useState('')

    const toastMsg =(ids,msg,type)=>{
        toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
      } 

    const fetchGoal = async () =>{
        const input = {
            access_token:localStorage.token,
            id:id
        }
        const res = await get_goals_details(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log(res);
        try{
            if (res.status==='successfull') {
                setDataDict(res.result.goaL_details)
                setUserLifeEvent(res.result.life_event)
            }else{}
        }catch{toast.error('Something went wrong')}
    }

    useEffect(()=>{
        if(goals===1){setDisabled(true)}
        fetchGoal();

    },[])

    const addFunc=(type)=>{
        let newRow = 1
        const newRowItem={amount: 0,goal_type:"",start_date: "NULL", target_date:"NULL",remarks:"",hlv_calculation:"Y",}

        let aa = {...dataDict}
        Object.values(dataDict[type]).map(item => {
            newRow = newRow + 1
            if(!aa[type][newRow]){
                aa[type][newRow] = newRowItem
                setDataDict(aa);
            }
       });
    }
    const removeFunc=(type)=>{
        let aa = {...dataDict}
        let length = Object.keys(aa[type]).length
        if(length>1){
        delete aa[type][length];
        setDataDict(aa);
        }
    }

    const handleChangeCheckbox=(e,type,row)=>{
        const aa = {...dataDict};
        if(aa[type][row].hlv_calculation === 'Y'){
            aa[type][row].hlv_calculation = 'N'
        }else{
            aa[type][row].hlv_calculation = 'Y'
        }
        setDataDict(aa);
    }

    const handleChangeValue=(e,type,row)=>{
        const aa = {...dataDict};
        aa[type][row][e.target.name] = e.target.value;
        setDataDict(aa);
    }
    const handleChangeAmount=(value,name,type,row)=>{
        const aa = {...dataDict};
        aa[type][row][name] = parseInt(value);
        setDataDict(aa);
    }
    const onChangeLifeEvent=(e)=>{
        setUserLifeEvent(e.target.value)
    }
    const edit=()=>{setDisabled(false)}

    const cancel=()=>{
        setError(false)
        setSuccess(false)
        setDisabled(true)
    }

    const submitIncome=(e)=>{
        const ids = toast.loading("Please wait...",{toastId:'load'})
        e.preventDefault();
        const input = {
            access_token:localStorage.token,
            id:id,
            goals_data:dataDict,
            userLifeEvent:userLifeEvent
        }
        console.log(input);
        save_goals_details(input).then(res => {
            console.log("update",res)
            try{
                if (res.status==='successfull') {
                    if(goals===0){
                        update();
                        toastMsg(ids,res.message,'success')
                        setDisabled(true)
                    }
                    else{
                        toastMsg(ids,res.message,'success')
                        setDisabled(true)
                    }
                }
                else{toastMsg(ids,res.message,'error')}
            }catch{toastMsg(ids,'Something went wrong','error')}
        })
    }

    const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits:0
    }).format(value);

    let self_total = 0
    if(dataDict){
        Object.keys(dataDict).map((type)=>{
            Object.keys(dataDict[type]).map((row)=>{
                self_total = self_total + parseInt(dataDict[type][row].amount)
            })
            return null
        })
    }
    if (loading){
        return(
          <div style={{textAlign:'center',marginTop:100}}>
          <Loader/>
          </div>
          );
      }
    return (
        <div>
        <ToastContainer theme='colored' limit={1}/>
              {error?
                  <SweetAlert
                      error
                      onConfirm={cancel}>
                  {errormsg}
                  </SweetAlert>:null}

              {success?
                  <SweetAlert
                      success
                      onConfirm={cancel}>
                  {successmsg}
                  </SweetAlert>:null}

            <div className='row'>
                <div className='col-md-9'>
                <div className='form-heading'>Goals Details
                    {goals===1?(
                    <span className='formButton'>
                        {disabled?
                        <button type="button" onClick={edit}><MDBIcon far icon="edit" className='form-edt-icon'/>Edit</button>:
                        <>
                        <button onClick={submitIncome}><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>
                        </>}
                    </span>):null}
                </div>

                <div className='formHeight'>
                    <table class="table table-condensed table-sm" border="0">
                        <thead>
                            <tr className='tb_header'>
                                <td>Goal</td>
                                <td className='lf-border'>HLV</td>
                                <td className='lf-border'>Goal Type</td>
                                <td className='lf-border'>Amount</td>
                                <td className='lf-border'>Start Date(if recurring)</td>
                                <td className='lf-border'>Target date*</td>
                                <td className='lf-border'>Remarks</td>
                            </tr>
                        </thead>
                        {Object.keys(dataDict).map((type)=>{
                        return(
                        <tbody>
                            {Object.keys(dataDict[type]).map((row,index1)=>{
                            return(
                            <tr className='tb_body'>
                                {index1===0?(
                                <>
                                <td rowSpan={index1} style={{minWidth:'120px'}}>{type}
                                <MDBIcon far icon="plus-square" className="assetAddIcon" onClick={()=>addFunc(type)} />

                                <MDBIcon far icon="minus-square" className="assetAddIcon" onClick={()=>removeFunc(type)} />
                                </td>
                                </>):null}

                                <td className='l-r-br'>
                                    <input type='checkbox' onChange={(e)=>handleChangeCheckbox(e,type,row)} name="hlv_calculation" checked={dataDict[type][row].hlv_calculation==='Y'} disabled={disabled}/>
                                </td>

                                <td  style={{minWidth:'80px'}} className='l-r-br'>
                                    <select value={dataDict[type][row].goal_type} onChange={(e)=>handleChangeValue(e,type,row)} name="goal_type" disabled={disabled}>
                                        <option value="">Select</option>
                                        <option value="One-Time">One-Time</option>
                                        <option value="Recurring">Recurring</option>
                                    </select>
                                </td>
                                <td > 
                                    <CurrencyInput
                                        name="amount"
                                        placeholder={numberFormat(dataDict[type][row].amount)}
                                        disabled={disabled}
                                        onValueChange={(value,name)=>handleChangeAmount(value,name,type,row)}
                                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                        prefix="₹"
                                    />
                                </td>
                                
                                <td className='l-r-br'>
                                    <input type="date" value={dataDict[type][row].start_date} 
                                    onChange={(e)=>handleChangeValue(e,type,row)} name="start_date" disabled={disabled}/>
                                </td>
                                <td>
                                    <input type="date" value={dataDict[type][row].target_date} 
                                    onChange={(e)=>handleChangeValue(e,type,row)} name="target_date" required disabled={disabled}/>
                                </td>
                                <td className='l-r-br'>
                                    <input type="text" placeholder={dataDict[type][row].remarks} 
                                    onChange={(e)=>handleChangeValue(e,type,row)} name="remarks" disabled={disabled}/>
                                </td>
                            </tr>
                            )
                            })}      
                        </tbody>
                        )
                        })}
                            <tr className='tb_header'>
                                <td>Total</td>
                                <td className='lf-border'></td>
                                <td className='lf-border'></td>
                                {self_total?(
                                <td className='l-r-br'>{numberFormat(self_total)}</td>
                                ):<td className='l-r-br'>0</td>}
                                <td className='l-r-br'></td>
                                <td className='l-r-br'></td>
                                <td></td>
                            </tr>
                    </table>
                </div>
                </div>
                <div className='col-md-3'>
                    <div className='form-heading'>Summarize Your Key life Event</div>
                    <div class="input-field-nr-u">
                    <textarea id="input9" style={{width:'100%', border:'1px solid black'}} type="text" rows="8" className='event-text' name="userLifeEvent" value={userLifeEvent} maxLength={50000} onChange={onChangeLifeEvent} placeholder=' '/>
                    </div> 
                </div>
            </div>

              {goals===0?
                  <div className='agree-checkbox'><input type='checkbox'/> Skip this section</div>:null}
  
              {liability===1?(<div></div>):(<div className='previous-msg'>Please fill the previous form first.</div>)}

              {liability===1 && goals===1?(
              <>
              {disabled?(
                  <div className='submit-Btn'><button onClick={step5}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button></div>
              ):(
                  <div className='submit-Btn'><button onClick={step5}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button></div>
              )}
              </>):null}
                  
              {liability===1 && goals===0?
              <div className='submit-Btn'><button onClick={step5}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button  onClick={submitIncome}>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>:null}
        </div>
        
    )
}

export default GoalExel

import React, { useState,useEffect} from 'react'
import {Input,Space,Button} from 'antd';
import { Table } from "ant-table-extensions";
import {MDBIcon} from 'mdbreact'
import Highlighter from 'react-highlight-words';
import {hoa_webinar_details_get} from '../Api'
import OtpInput from 'react-otp-input';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../shared/Loader'

const WebAdmin = () => { 
    const[data,setData] = useState([])
    const[pin,setPin] = useState('')
    const[pinSuccess,setPinSuccess] = useState(false)
    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')
    const[loading,setLoading] = useState(false)

    let searchInput = ''

    const handleChange = (otp) =>{setPin(otp)}

    const submitPin = async ()=>{
      const input={
        pin:pin
      }
      let arr= []
        setLoading(true)
        const res = await hoa_webinar_details_get(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            setPinSuccess(true)
            Object.entries(res.result).map((val)=>arr.push(val[1]))
            setData(arr)
            setLoading(false)
        
          }else{
            setLoading(false)
            toast.error(res.message)
          
          }
        }catch{
          setLoading(false)
          toast.error('Something went wrong')
       
        }
    }

      let getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<MDBIcon icon="search" className='tableSearchI'/>}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => {
                handleReset(clearFilters)
                confirm({ closeDropdown: false });
                // setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                }} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <MDBIcon className='tableSearchI' icon="search" style={{ color: filtered ? '#e87c1b' : '#000' }}/>,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
      };
      const  handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };

    let columns = [
        {title: 'NAME',dataIndex: 'name',width: 120, ...getColumnSearchProps('name')},
        {title: 'EMAIL',dataIndex: 'email',width: 120,...getColumnSearchProps('email')},
        {title: 'PHONE NUMBER',dataIndex: 'contact_no',width: 90,...getColumnSearchProps('contact_no')},
        {title: 'Current Age',dataIndex: 'current_age',width: 120, ...getColumnSearchProps('current_age')},
        {title: 'CITY',dataIndex: 'city',width: 100,...getColumnSearchProps('city')},
        {title: 'MONTHLY INCOME',dataIndex: 'monthly_income',width: 100,...getColumnSearchProps('monthly_income')},
        {title: 'CALCULATOR_USER',dataIndex: 'calculator_user',width: 100,...getColumnSearchProps('calculator_user')},
        {title: 'DATE',dataIndex: 'Time and Date',width: 80,...getColumnSearchProps('date')},
    ];
    
  return (
    <div className='webBannerImageAdmin'>
          <ToastContainer theme='colored' limit={1}/>
          {loading?<Loader/>:null}
        {pinSuccess?
             <div className='webBodyAdmin'>
                <Table 
                    rowClassName="antTable" 
                    columns={columns} 
                    dataSource={data} 
                    size='small'
                    scroll={{y: 350}}
                    pagination={{pageSize: 12,position:['bottomCenter']}} 
                    sticky={true}
                    exportableProps={{ fileName: 'WebinarLoginList'}}
                    />
            </div>
        :
         <div className='PinCard'>
            <div className='pin'>Enter Pin</div>
            <div className='pinDiv'>
                <OtpInput
                    value={pin}
                    onChange={handleChange}
                    numInputs={4}
                    separator={<span>-</span>}
                    containerStyle='containerStyle'
                    inputStyle='inputStyle'
                    shouldAutoFocus={true}
                    isInputNum={true}
                    hasErrored={false}
                />
            </div>
            <div className='submit-Btn' style={{textAlign:'center'}}>
                <button onClick={submitPin}>Submit</button>
            </div>
        </div>}
     
    </div>
  )
}

export default WebAdmin
import React,{useState, useEffect} from 'react'
import ToolTipChart from './ToolTipChart'
import './Assets.scss'
import {MDBIcon} from 'mdbreact'

const ToolT = (props) => {

  const numberFormat=(value)=>{
    var val = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' L';
    }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
    return val;
  }

  let cColor = '#ffe4cb' 
  let total = 0


  
  return (
    <div className='columnTooltip'>
      {props.title?
      <>
        {Object.keys(props.data[props.title]).map((val)=>{
         total = total + parseInt(props.data[props.title][val].current_value)
        })}
        <div className='ToolChartHead'>&nbsp; &nbsp;{numberFormat(total)}</div>

        {Object.keys(props.data[props.title]).map((val)=>{
          if(props.data[props.title][val].current_value!==0){

            return(
              <div className='ToolTRow'>
                  <span> <MDBIcon fas icon="stop" style={{color:`${props.data[props.title][val].color}`,marginRight:'4px'}}/>{val}</span>  
                  <div className='ToolTRowNum'>
                    {numberFormat(props.data[props.title][val].current_value)}</div>
              </div>  
            )
          }
        })}
      </>:null}
      

    </div>
  )
}

export default ToolT
import { ActionTypes } from "../constants/action-types";

const intialState = {
  disabled: false,
  allInstrument:{},
  holdings:{}
};

export const handleDisabled = (state = intialState.disabled, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_DISABLE:
      return { ...state, disabled: payload };
    default:
      return state;
  }
};
export const handleAllInstrument = (state = intialState.allInstrument, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_INSTRUMENT:
      return { ...state, allInstrument: payload };
    default:
      return state;
  }
};
export const fetchHolding = (state = intialState.holdings, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_HOLDINGS:
      return { ...state, holdings: payload };
    default:
      return state;
  }
};


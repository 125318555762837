import React, { useState, useEffect } from 'react';
import { DualAxes } from '@ant-design/plots';

const ColumnLine = (props) => {
  const [data,setData] = useState([])
  const [lineData,setLineData] = useState([])

  const numberFormat=(value)=>{
    var val = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' L';
    }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
    return val;
  }
  
  useEffect(()=>{
    let arr = []
    let arr2 = []
    Object.keys(props.ammortizationData).map((val)=>{
      Object.keys(props.ammortizationData[val]).map((val2)=>{
        if(val2!== 'principal_balance'){
        arr.push({year: val,value:props.ammortizationData[val][val2].value,type: val2})
        }
        else{
          arr2.push({year:val,'Balance Due':props.ammortizationData[val][val2].value})
        }
      })
    })
    console.log(arr);
    setData(arr)
    setLineData(arr2)
  },[props.ammortizationData])

      const config = {
        data: [data, lineData],
        xField: 'year',
        yField: ['value', 'Balance Due'],
        yAxis:{
          value: {
            label:{formatter: (text,item,index) => {return numberFormat(text)}}
          },
          'Balance Due': {
            label:{formatter: (text,item,index) => {return numberFormat(text)}}
          },
        },
        geometryOptions: [
          {
            geometry: 'column',
            isStack: true,
            seriesField: 'type',
            color:['#f8d80c','#ff872e'],
            tooltip:{
              formatter: (text) => {
              return {name: text.type ,value: numberFormat(text.value)}
              },
            },
          },
          {
            geometry: 'line',
            color:'#ef0000',
            point:{color:'#ef0000'},
            tooltip:{
              formatter: (text) => {
              return {name: 'Balance Due' ,value: numberFormat(text['Balance Due'])}
              },
            },
          },
        ],
      };
  return (<DualAxes {...config} />)
}

export default React.memo(ColumnLine)
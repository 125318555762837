import React, { Component } from 'react'
import {save_insurance_policy_details,get_insurance_details,delete_insurance_policy_details} from './Api'
import {get_uploaded_docs} from '../Profile-setup/Api'

import {MDBIcon} from 'mdbreact'
import Loader from '../../shared/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import CurrencyInput from 'react-currency-input-field';
import AddDocument from '../Profile-setup/AddDocument'
import {view_compliants_docs} from '../Compliance/Api'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastMsg =(ids,msg,type)=>{
  toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
} 


export default class InsuranceExel extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        resData:{},
        order : [],
        del_product:{},
        confirm:false,
        success:false,
        error:false,
        successmsg:'',
        errormsg:''
      };
    }
    componentDidMount(){
      let arr = []
        const input = {
            access_token:localStorage.token,
            id:this.props.id
        }
        get_insurance_details(input).then(res => {
        console.log("res",res)
        try{
        if (res.status==='successfull') {
        Object.keys(res.result).map((val)=>{
            arr.push(res.result[val])
        })
        this.setState(() => ({
            resData:res.result,
            order:arr
        }))
    }
    else{console.log(res)}
    }catch{
      toast.error('Something went wrong')
      this.setState({loading:false})
    }
    })
    }
    handleRowDel(product) {
        console.log(product)
        if(product.insurance_type && product.insurance_name && product.policy_no && product.insurance_company && product.insurance_holder && product.premium_amount){
            this.setState({del_product:product,confirm:true})
        }
        else{
            var index = this.state.order.indexOf(product);
            this.state.order.splice(index, 1);
            this.setState(this.state.order);
        }
       
    };
    handledelete=()=>{
        const input = {
            access_token:localStorage.token,
            id:this.props.id,
            delete_row:this.state.del_product
        }
        console.log("input",input)
        delete_insurance_policy_details(input).then(res => {
            console.log("res",res)
            try{
            if (res.status==='successfull') {
                this.setState({confirm:false,success:true,successmsg:res.message})
                var index = this.state.order.indexOf(this.state.del_product);
                this.state.order.splice(index, 1);
                this.setState(this.state.order);
        }
        else{
          this.setState({confirm:false})
          toast.error(res.message)
      }
        }catch{
          this.setState({confirm:false})
          toast.error('Something went wrong')
      }
        }) 
      }
      
      
    handleAddEvent(evt) {
      var id = Math.floor(1000 + Math.random() * 9000);
      var product = {
        id: id,
        insurance_type: '',
        insurance_name: '',
        policy_no:'',
        insurance_company:'',
        insurance_holder:'',
        premium_amount:'',
        sum_assured:'',
        insurance_start_date:'',
        premium_paying_term:'',
        coverage_till:'',
        remarks:''
      }
      this.state.order.push(product);
      this.setState(this.state.order);
    }
  
    handleProductTable(evt) {
      var item = {
        id: evt.target.id,
        name: evt.target.name,
        value: evt.target.value
      };
  var order = this.state.order.slice();
    var neworder = order.map(function(product) {
      for (var key in product) {
        if (key === item.name && product.id === parseInt(item.id)) {
          product[key] = item.value;
        }
      }
      return product;
    });
      this.setState({order:neworder});
    };
    currencyhandleProductTable=(value,id,name)=>{
      var item = {
        id: id,
        name: name,
        value: value
      };
  var order = this.state.order.slice();
    var neworder = order.map(function(product) {
      for (var key in product) {
        if (key === item.name && product.id === parseInt(item.id)) {
          product[key] = item.value;
        }
      }
      return product;
    });
      this.setState({order:neworder});
    }
    onCancel=()=>{
        this.setState({
          confirm:false,
          success:false,
          error:false,
        })
      }
    render() {
      return (
        <div>
        <ToastContainer theme='colored' limit={1}/>
        {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.onCancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

        {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.onCancel}>
              {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

        {this.state.confirm?(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={this.handledelete}
                onCancel={this.onCancel}
                focusCancelBtn></SweetAlert>
            ):(<span></span>)}

          <ProductTable onProductTableUpdate={this.handleProductTable.bind(this)} onRowAdd={this.handleAddEvent.bind(this)} currencyhandleProductTable={this.currencyhandleProductTable} onRowDel={this.handleRowDel.bind(this)} order={this.state.order} update={this.props.update} insurance={this.props.insurance} step2={this.props.step2} step4={this.props.step4} expense={this.props.expense} id={this.props.id}/>
        </div>
      );
  
    }
  }
  let docs = null
  class ProductTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        success:false,
        error:false,
        successmsg:'',
        errormsg:'',
        disabled:false,
        skip:false,
        uploadModal:false,
        refrence:'',
        uploaded_docs:{},
        loading:false,
        warning:false,
        viewModal:false,
        view:null,
      };
    }
    componentDidMount(){
      if(this.props.insurance===1){
        this.setState({disabled:true})
      }
      const input = {
        access_token:localStorage.token,
        id:this.props.id,
        dir:'insurance'
    }
      get_uploaded_docs(input).then(res => {
        console.log('docs-res',res)
        try{
        if (res.status==='successfull') {
          this.setState(() => ({
             uploaded_docs:res.result
          }))    
      }
      else{console.log('error')}
      }catch{
        toast.error('Something went wrong')
        this.setState({loading:false})
      }
      })
     }
    onAfterUpload=()=>{
      this.setState({uploadModal:false})
      const input = {
        access_token:localStorage.token,
        id:this.props.id,
        dir:'insurance'
    }
    console.log(input);
      get_uploaded_docs(input).then(res => {
        console.log('docs-res',res)
        try{
        if (res.status==='successfull') {
          this.setState(() => ({
             uploaded_docs:res.result,
          }))    
      }
      else{console.log('error')}
      }catch{}
      })
    }

    enableNext=()=>{
    this.setState({skip:!this.state.skip})
    }
    onSubmitRecord=(e) =>{
      e.preventDefault();
      const ids = toast.loading("Please wait...",{toastId:'load'})
        const input = {
          access_token:localStorage.token,
          policy_data:this.props.order,
          id:this.props.id,
          skip:this.state.skip
        }
        console.log("input",input)
        save_insurance_policy_details(input).then(res => {
            console.log("resgoa",res)
        try{
        if (res.status==='successfull') {
          if(this.props.insurance===1){
            toastMsg(ids,res.message,'success')
            this.setState({disabled:true})
          }
          else{
            this.props.update();
            this.props.step4();
          }
      }
      else{toastMsg(ids,res.message,'error')}
      }catch{toast.error('Something went wrong')}
      })
      }
      
      onCancel=()=>{
        this.setState({
          success:false,
          error:false,
          warning:false,
          disabled:true
        })
      }
      edit=(e)=>{
        e.preventDefault()
        this.setState({
            disabled:false
        })
    }
    openModal=(refrence)=>{
        this.setState({
          uploadModal:true,
          refrence:refrence
        })
    }
    closeModaldocument=()=>{
      this.setState({
        uploadModal:false,
        viewModal:false,
        view:null
      })
    }
    pleaseSave=(e)=>{
      e.preventDefault()
      this.setState({
          warning:true,
      })
  }
  onleave=()=>{
    this.setState({
        warning:false,
    })
  }
  handlesave=()=>{
    this.props.step4();
}
download=(name)=>{
  const input = {
      access_token:localStorage.token,
      flag:name,
      id:this.props.id,
      dir: 'insurance'
  }
  console.log(input)
  let splitType = name.split('.').pop();
  view_compliants_docs(input).then(res => {
      console.log("resss",res)
      try {
          if (res.status==='successfull') {
              docs=res.result
              let linkSource = ''
              if(splitType==='pdf'){
                linkSource = `data:application/pdf;base64,${docs}`;
              }
              else{
                linkSource = `data:image/png;base64,${docs}`;
              }
              const downloadLink = document.createElement("a");
              const fileName = name;
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
          }
          else{}
      } catch (error) {}
      })
}

viewDocs=(name)=>{
  this.setState({viewModal:true})
  const input = {
    access_token:localStorage.token,
    flag:name,
    id:this.props.id,
    dir: 'insurance'
}
let splitType = name.split('.').pop();
view_compliants_docs(input).then(res => {
    console.log("viewRes",res)
    try {
        if (res.status==='successfull') {
            if(splitType==='pdf'){
              this.setState({
                view : `data:application/pdf;base64,${res.result}`
              })
            }
            else{
              this.setState({
                view : `data:image/png;base64,${res.result}`
              })
            }
        }
        else{}
    } catch (error) {}
    })
}

    render() {
      var onProductTableUpdate = this.props.onProductTableUpdate;
      let disabled = this.state.disabled
      let perm_disable = true
      let insurance = this.props.insurance
      var rowDel = this.props.onRowDel;
      var currencyhandleProductTable = this.props.currencyhandleProductTable
      var openModal = this.openModal
      var download = this.download
      var viewDocs = this.viewDocs
      let uploaded_docs = this.state.uploaded_docs
      let view = false
      let fileName = ''
      var product = this.props.order.map(function(product) {
        view = false
        fileName = ''
        if(uploaded_docs){
          Object.keys(uploaded_docs).map((val)=>{
            if (parseInt(val)===parseInt(product.policy_no)){
              view=true
              fileName =uploaded_docs[val].document_extension
            }
          })
        }
        if(product.id>1000 || insurance===0){
          perm_disable = false          
        }
        else{
          perm_disable=true
        }
        return (<ProductRow onProductTableUpdate={onProductTableUpdate} currencyhandleProductTable = {currencyhandleProductTable} product={product} onDelEvent={rowDel.bind(this)} key={product.id} disabled={disabled} perm_disable={perm_disable} refrence={product.policy_no} openModal={openModal} view={view} fileName={fileName}download={download} viewDocs={viewDocs}/>)
      }); 
      
      if (this.state.loading){
        return(
          <div style={{textAlign:'center',marginTop:100}}>
          <Loader/>
          </div>
          );
      }
      return (
        <div> 
        <ToastContainer theme='colored' limit={1}/>
             {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.onCancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

        {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.onCancel}>
              {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

            {this.state.warning?(
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="success"
                    cancelBtnText="No"
                    cancelBtnBsStyle="danger"
                    onConfirm={this.handlesave}
                  onCancel={this.onleave}
                  focusCancelBtn>Do you want to proceed without save your changes</SweetAlert>):(<span></span>)}

      <form onSubmit={this.onSubmitRecord}>
      
        <div className='form-heading'>Insurance Details
            {this.props.insurance===1?(
            <span className='formButton'>
            {disabled?
            <button type="button" onClick={this.edit}><MDBIcon far icon="edit" className='form-edt-icon' />Edit</button>:
            <button type='submit'><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>}
            </span>):null}
            <span className={disabled?"formButtonDisable":"formButton"}>
              <button disabled={disabled} type="button" onClick={this.props.onRowAdd}>
              <MDBIcon icon="plus"className='form-edt-icon' />Add Row </button>
            </span>
        </div>
        
        <div className='formHeight'>
          <table class="table table-condensed table-sm" cellspacing="0" cellpadding="0" border="0">
            <thead>
              <tr className='tb_header' >
                <td>Policy Type*</td>
                <td className='lf-border'>Policy Name*</td>
                <td className='lf-border'>Policy Number*</td>
                <td className='lf-border'>Company Name*</td>
                <td className='lf-border'>Holder Name*</td>
                <td className='lf-border'>Annual Premium*</td>
                <td className='lf-border'>Sum Assured*</td>
                <td className='lf-border'>Start Date*</td>
                <td className='lf-border'>Premium Paying Term*</td>
                <td className='lf-border'>Coverage Till*</td>
                <td className='lf-border'>Remarks</td>
                <td className='lf-border rh-border'>Document</td>
                <td></td>
              </tr>
            </thead>
            {product}
          </table>
        </div>

{this.state.uploadModal?(
  <div className='upload-modal-sec'>
    <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
            <div className='upload-modal-sec-card'>
              <div className='modal-close-icon' onClick={this.closeModaldocument}><MDBIcon icon="times" /></div>
              <div className='upload-component'>
                <AddDocument document_type='insurance_policy' dir='insurance' refName='Policy Number' refrence={this.state.refrence} onAfterUpload={this.onAfterUpload} id={this.props.id}/>
              </div>
            </div>
        </div>
        <div className='col-md-3'></div>
    </div>
  </div>):(<span></span>)}

{this.state.viewModal?(
  <div className='upload-modal-sec'>
    <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
        <div className='viewDocs-modal-sec-card'>
            <div className='modal-close-icon' onClick={this.closeModaldocument}><MDBIcon icon="times" />
            </div>
                
            {this.state.view?(
                <iframe src={`${this.state.view}#view=fitH`} title="testPdf" height="80%" width="100%" />
          ):null}
          </div>
        </div>
        <div className='col-md-4'></div>
    </div>
  </div>):(<span></span>)}

      {this.props.insurance===0?(
          <div className='agree-checkbox'><input type='checkbox' onChange={this.enableNext}/>  Skip this section</div>):(<span></span>)}

      {this.props.expense===1?(<div></div>):(<div className='previous-msg'>Please fill the previous form first.</div>)}

      {this.props.expense===1 && this.props.insurance===0?(
        <div className='submit-Btn'>
          <button onClick={this.props.step2}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
          {this.state.skip?(
            <span onClick={this.onSubmitRecord}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></span>
          ):(
            <button type="submit">Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
          )}
        </div>
        ):(<div></div>)}

      {this.props.expense===1 && this.props.insurance===1?(
        <>
        {this.state.disabled?(
           <div className='submit-Btn'><button onClick={this.props.step2}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button onClick={this.props.step4}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>
        ):(
        <div className='submit-Btn'><span><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</span><button type='button' onClick={this.pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>)}
        </>):(<div></div>)} 

        </form>

        
        </div>
      );
    }
  }
  
  class ProductRow extends React.Component {
    onDelEvent() {
      this.props.onDelEvent(this.props.product);
    }
    render() {
      return (
        <tr className='tb_body'>
        <EditableCellSelect onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "insurance_type",
            value: this.props.product.insurance_type,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
        <EditableCellText onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "insurance_name",
            value: this.props.product.insurance_name,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
        <EditableCellPolicyNum onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "policy_no",
            value: this.props.product.policy_no,
            id: this.props.product.id,
            perm_disable:this.props.perm_disable
          }}/>
        <EditableCellText onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "insurance_company",
            value: this.props.product.insurance_company,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
        <EditableCellText onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "insurance_holder",
            value: this.props.product.insurance_holder,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
        <EditableCellCurrency onProductTableUpdate={this.props.currencyhandleProductTable} cellData={{
            "type": "premium_amount",
            value: this.props.product.premium_amount,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
        <EditableCellCurrency onProductTableUpdate={this.props.currencyhandleProductTable} cellData={{
            "type": "sum_assured",
            value: this.props.product.sum_assured,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
        <EditableCellDate onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            type: "insurance_start_date",
            value: this.props.product.insurance_start_date,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
        <EditableCellNum onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
        "type": "premium_paying_term",
        value: this.props.product.premium_paying_term,
        id: this.props.product.id,
        disabled:this.props.disabled
        }}/>
         <EditableCellDate onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            type: "coverage_till",
            value: this.props.product.coverage_till,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>    
        <EditableCellTextArea onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            type: "remarks",
            value: this.props.product.remarks,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
           <td className="lf-border rh-border">
            <div>
              {this.props.view?(
                <>
                <div>{this.props.fileName} 
                <MDBIcon icon="eye" className='mdbicon' onClick={(name)=>this.props.viewDocs(this.props.fileName)}/>
                 <MDBIcon icon="download" className='mdbicon' onClick={(name)=>this.props.download(this.props.fileName)}/>
                 {this.props.disabled?(
                 <MDBIcon icon="edit" className='mdbicon-disabled'/>
                 ):(
                  <MDBIcon icon="edit" className='mdbicon' onClick={(refrence)=>this.props.openModal(this.props.refrence)}/>
                 )}
                 
                 </div>
                </>
                
              ):(
                <MDBIcon icon="upload" onClick={(refrence)=>this.props.openModal(this.props.refrence)}/>
              )}
            </div>
          </td>
           <td>
            <div className='delBtn' onClick={this.onDelEvent.bind(this)}>X</div>
          </td>
        </tr>
      );
    }
  }

  class EditableCellCurrency extends React.Component {
    render() {
      return (
        <td className='lf-border'>
        <CurrencyInput
              value={this.props.cellData.value}
              disabled={this.props.cellData.disabled}
              onValueChange={(value) => this.props.onProductTableUpdate(value,this.props.cellData.id,this.props.cellData.type)}
              required
              intlConfig={{ locale: 'en-IN', currency: 'INR' }}
              prefix="₹"
            />
        </td>
      );
    }
  }
class EditableCellPolicyNum extends React.Component {
    render() {
      return (
        <td className='lf-border'>
          <input type='number' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} required disabled={this.props.cellData.perm_disable}/>
        </td>
      );
    }
  }
class EditableCellNum extends React.Component {
    render() {
      return (
        <td className='lf-border'>
          <input type='number' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.disabled} required/>
        </td>
      );
    }
  }
  class EditableCellSelect extends React.Component {
    render() {
      return (
        <td>
         <select type='select' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.disabled} required>
                <option value="">Select</option>
                <option>LIFE</option>
                <option>HEALTH</option>
                <option>ACCIDENTAL</option>
                <option>CRITICAL</option>
                <option>PROPERTY</option>
                <option>VEHICLE</option>
                <option>OTHER</option>
            </select>
        </td>
      );
    }
  }
  class EditableCellDate extends React.Component {
    render() {
      return (
        <td className='lf-border'>
          <input type='date' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} required disabled={this.props.cellData.disabled}/>
        </td>
      );
    }
  }
  class EditableCellText extends React.Component {
    render() {
      return (
        <td className='lf-border'>
          <input type='text' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.disabled} required/>
        </td>
      );
    }
  }
  class EditableCellTextArea extends React.Component {
    render() {
      return (
        <td className='lf-border'>
          <textarea type='text' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} disabled={this.props.cellData.disabled}/>
        </td>
      );
    }
  }
  
import React,{useState,useEffect} from 'react';
import { Table } from "ant-table-extensions";
import {customer_margin_details} from '../../Api'
import { DatePicker, Space } from 'antd';

const Holdings = (props) => {
  
  const [data, setData] = useState([]);
  const date = new Date().toISOString().split('T')[0]
  const [dateSend, setdateSend] = useState([])
  
  
    const fetchData = ()=>{
    let newdata = []
    const input = {
        access_token:localStorage.token,
        type:'holdings',
        id:props.customer_id,
        start_date:dateSend
    }
    console.log(input);
    customer_margin_details(input).then(res => {
        console.log('holding',res);
        Object.keys(res.result).map((val)=>{
          newdata.push({ 
          key: val,
          date:res.result[val].date,
          average_price:res.result[val].average_price,
          authorised_date: res.result[val].authorised_date,
          authorised_quantity: res.result[val].authorised_quantity,
          close_price: res.result[val].close_price,
          collateral_quantity: res.result[val].collateral_quantity,
          collateral_type: res.result[val].collateral_type,
          day_change: res.result[val].day_change,
          discrepancy: res.result[val].discrepancy,
          exchange: res.result[val].exchange,
          day_change_percentage: res.result[val].day_change_percentage,
          instrument_token: res.result[val].instrument_token,
          isin: res.result[val].isin,
          last_price: res.result[val].last_price,
          opening_quantity: res.result[val].opening_quantity,
          pnl: res.result[val].pnl,
          price: res.result[val].price,
          product: res.result[val].product,
          quantity: res.result[val].quantity,
          realised_quantity: res.result[val].realised_quantity,
          short_quantity: res.result[val].short_quantity,
          t1_quantity: res.result[val].t1_quantity,
          tradingsymbol: res.result[val].tradingsymbol,
          used_quantity: res.result[val].used_quantity,
          account_type: res.result[val].account_type,
          name: res.result[val].name,


          })
      })
        setData(newdata)
      })
    }

  useEffect(()=>{
    fetchData()
  },[])

  const submitData =()=>{
    fetchData()
  }

  const onChangeDate = (date, dateString) => {
    setdateSend(dateString)
  };

  const columns = [
    {title: 'Updated Date',dataIndex: 'date',width: 70},
    {title: 'Account Type',dataIndex: 'account_type',width: 70},
    {title: 'Name',dataIndex: 'name',width: 70},
    {title: 'Average Price',dataIndex: 'average_price',width: 70},
    {title: 'Authorised Date',dataIndex: 'authorised_date',width: 75},
    {title: 'Authorised Quantity',dataIndex: 'authorised_quantity',width: 75},
    {title: 'Close Price',dataIndex: 'close_price',width: 70}, 
    {title: 'Collateral Quantity',dataIndex: 'collateral_quantity',width: 75},
    {title: 'Collateral Type',dataIndex: 'collateral_type',width: 75},
    {title: 'Day Change',dataIndex: 'day_change ',width: 70},
    {title: 'Discrepancy',dataIndex: 'discrepancy',width: 80},
    {title: 'Exchange',dataIndex: 'exchange',width: 70},
    {title: 'Day Change Percentage',dataIndex: 'day_change_percentage',width: 85},
    {title: 'Instrument Token',dataIndex: 'instrument_token',width: 75},
    {title: 'IsIn',dataIndex: 'isin',width: 70},
    {title: 'Last Price',dataIndex: 'last_price',width: 70},
    {title: 'Opening Quantity',dataIndex: 'opening_quantity',width: 75},
    {title: 'PnL',dataIndex: 'pnl',width: 70},
    {title: 'Price',dataIndex: 'price',width: 70},
    {title: 'Product',dataIndex: 'product',width: 70},
    {title: 'Quantity',dataIndex: 'quantity',width: 70},
    {title: 'Realised Quantity',dataIndex: 'realised_quantity',width: 70},
    {title: 'Short Quantity',dataIndex: 'short_quantity',width: 70},
    {title: 'T1 Quantity',dataIndex: 't1_quantity',width: 70},
    {title: 'Trading Symbol',dataIndex: 'tradingsymbol',width: 70},
    {title: 'Used Quantity',dataIndex: 'used_quantity',width: 70},
  ]

  return (
      <>
    
      <div className='modalDateBox'>
      <DatePicker onChange={onChangeDate} placeholder={date}
        disabledDate={(current) => {
        return current && current.valueOf() > Date.now();
        }} />
        <button className='dateSubmitButton' onClick={submitData}>Submit</button>
      </div>
        <Table
          rowClassName="editable-row"
          columns={columns}
          dataSource={data}
          size='small'
          scroll={{ x: 1150, y: 400 }}
          pagination={{
            pageSize: 10,
            position:['bottomCenter']
          }}
          sticky={true}
          exportableProps={{ fileName: `Holdings_${props.cusEmail}_${date}`}}
          />
      </>
  );
};

export default Holdings;

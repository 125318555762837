import React, { Component } from 'react'
import {MDBIcon} from 'mdbreact'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Asset_value from './Asset_value';
import Mf_details from './Mf_details'
import Mf_value from './Mf_value'
import Asset_goal from './Asset_goal'
import Goal_complete from './Goal_complete'
import Goal_risk from './Goal_risk'
import Alpha_plans from './Alpha_plans'
import RiskAssesment from './RiskAssesment';

export default class Daily_Update extends Component {
    constructor(props){
        super(props);
        this.state={
            today: new Date(),
            asset:true,
            mf:false,
            mf_details:false,
            mf_value:true,
            goal:false,
            asset_goal:true,
            goal_complete:false,
            goal_risk:false,
            alpha:false,
            risk:false
        }
    }
    onChangefrom=(date)=>{
        this.setState({
          today:date
        })
      }
    asset=()=>{
        this.setState({
            asset:true,
            mf:false,
            goal:false,
            alpha:false,
            risk:false
        })
    }
    mf=()=>{
        this.setState({
            asset:false,
            mf:true,
            goal:false,
            alpha:false,
            risk:false
        })
    }
    goal=()=>{
        this.setState({
            asset:false,
            mf:false,
            goal:true,
            alpha:false,
            risk:false
        })
    }
    alpha=()=>{
        this.setState({
            asset:false,
            mf:false,
            goal:false,
            alpha:true,
            risk:false
        })
    }
    risk=()=>{
        this.setState({
            risk:true,
            asset:false,
            mf:false,
            goal:false,
            alpha:false,
        }) 
    }
    mf_value=()=>{
        this.setState({
            mf_value:true,
            mf_details:false
        })
    }
    mf_details=()=>{
        this.setState({
            mf_value:false,
            mf_details:true
        })
    }
    asset_goal=()=>{
        this.setState({
            asset_goal:true,
            goal_complete:false,
            goal_risk:false,
        })
    }

    goal_complete=()=>{
        this.setState({
            asset_goal:false,
            goal_complete:true,
            goal_risk:false,
        })
    }

    goal_risk=()=>{
        this.setState({
            asset_goal:false,
            goal_complete:false,
            goal_risk:true,
        })
    }

    render() {
        const ExampleCustomInput = ({ value, onClick }) => (
            <button className="datebox-upld" onClick={onClick}>
             <span><MDBIcon far icon="calendar-alt" size='xs' className='cal-icon-upld'/></span><span className='c-date-upld'> {value}</span>
            </button>
          );
        return (
            <div>
                <div className='row upld-top'>
                    <div className='col-md-9'>
                        <div className='upld-email'>
                            <MDBIcon far icon="envelope" className='upload-email-icon'/>{this.props.email_address}
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div>
                            <DatePicker
                                closeOnScroll={true}
                                selected={this.state.today}
                                onChange={this.onChangefrom}
                                showMonthDropdown
                                showYearDropdown
                                customInput={<ExampleCustomInput />}
                                withPortal
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-2'>
                        {this.state.asset?(
                            <div className='upld-compnent-tab-active'>Asset</div>
                        ):(
                            <div className='upld-compnent-tab' onClick={this.asset}>Asset</div>
                        )}
                    </div>

                    <div className='col-md-3'>
                        {this.state.mf?(
                            <div className='upld-compnent-tab-active'>Mutual Funds</div>
                        ):(
                            <div className='upld-compnent-tab' onClick={this.mf}>Mutual Funds</div>
                        )}
                    </div>
                    <div className='col-md-2'>
                        {this.state.goal?(
                            <div className='upld-compnent-tab-active'>Goal</div>
                        ):(
                            <div className='upld-compnent-tab' onClick={this.goal}>Goal</div>
                        )}
                    </div>
                    <div className='col-md-2'>
                    {this.state.alpha?(
                            <div className='upld-compnent-tab-active'>Alpha</div>
                        ):(
                            <div className='upld-compnent-tab' onClick={this.alpha}>Alpha</div>
                        )}
                    </div>

                    <div className='col-md-3'>
                    {this.state.risk?(
                            <div className='upld-compnent-tab-active'>Risk Assessment</div>
                        ):(
                            <div className='upld-compnent-tab' onClick={this.risk}>Risk Assessment</div>
                        )}
                    </div>
                </div>

                {this.state.asset?(
                    <div className='upld-compnent-div'>
                        <div className='upld-compnent-head'>Asset Value</div>
                        <Asset_value today={this.state.today} customer_id={this.props.customer_id}/>
                    </div>
                ):(<span></span>)}

                {this.state.mf?(
                    <div className='upld-compnent-div'>
                        <div className='row'>
                            <div class='col-md-6 pr-0'>
                                {this.state.mf_value?(
                                    <div className='upld-compnent-head'>Mutual Fund Value</div>
                                ):(
                                    <div className='upld-compnent-head-inactive'onClick={this.mf_value}>Mutual Fund Value</div>
                                )}
                            </div>
                            <div class='col-md-6 pl-0'>
                            {this.state.mf_details?(
                                     <div className='upld-compnent-head'>Mutual Fund Details</div>
                                ):(
                                    <div className='upld-compnent-head-inactive'onClick={this.mf_details}>Mutual Fund Details</div>
                                )}
                            </div>
                        </div>
                        {this.state.mf_value?(<div><Mf_value today={this.state.today} customer_id={this.props.customer_id}/></div>):(<span></span>)}
                        {this.state.mf_details?(<div><Mf_details today={this.state.today} customer_id={this.props.customer_id}/></div>):(<span></span>)}
                    </div>
                ):(<span></span>)}

            {this.state.goal?(
                <div className='upld-compnent-div'>
                    <div className='row'>
                        <div class='col-md-4 pr-0'>
                            {this.state.asset_goal?(
                                <div className='upld-compnent-head'>Asset Goal</div>
                            ):(
                                <div className='upld-compnent-head-inactive'onClick={this.asset_goal}>Asset Goal</div>
                            )}
                        </div>
                        <div class='col-md-4 pl-0 pr-0'>
                        {this.state.goal_complete?(
                                <div className='upld-compnent-head'>Goal Complete</div>
                            ):(
                                <div className='upld-compnent-head-inactive'onClick={this.goal_complete}>Goal Complete</div>
                            )}
                        </div>
                        <div class='col-md-4 pl-0'>
                        {this.state.goal_risk?(
                                    <div className='upld-compnent-head'>Goal Risk</div>
                            ):(
                                <div className='upld-compnent-head-inactive'onClick={this.goal_risk}>Goal Risk</div>
                            )}
                        </div>
                    </div>
                    {this.state.asset_goal?(<div><Asset_goal customer_id={this.props.customer_id} today={this.state.today}/></div>):(<span></span>)}
                    {this.state.goal_complete?(<div><Goal_complete customer_id={this.props.customer_id} today={this.state.today}/></div>):(<span></span>)}
                    {this.state.goal_risk?(<div><Goal_risk customer_id={this.props.customer_id} today={this.state.today}/></div>):(<span></span>)}
                </div>
            ):(<span></span>)}

            {this.state.alpha?(
                <div className='upld-compnent-div'>
                    <div className='upld-compnent-head'>Alpha</div>
                    <div><Alpha_plans today={this.state.today} customer_id={this.props.customer_id}/></div>
                </div>
            ):(<span></span>)}

            {this.state.risk?(
                <div className='upld-compnent-div'>
                    <div className='upld-compnent-head'>Risk Assessment</div>
                    <div><RiskAssesment today={this.state.today} customer_id={this.props.customer_id}/></div>
                </div>
            ):(<span></span>)}
            
            </div>
        )
    }
}

import React,{useState } from 'react'
import logo from "../../../Images/logoBlack.png"
import {Tooltip,Button,Col,Space,Row} from "antd";
import {LogoutOutlined} from '@ant-design/icons';
import {otpMobileVerification,otpMobileVerificationSumbit} from '../Api';
import "./Login.scss"
import {Navigate} from 'react-router-dom'
import mobile from '../../../Images/mobile.png'
// import OtpInput from 'react-otp-input';



const Verification = (props) => {
    const[otpEmail,setOtpEmail]=useState(true)
    const[takeInputEmail,setTakeInputEmail] = useState("")
    const[counter,setCounter] = useState(59)
    const[resendEmail,setResendEmail] = useState(false)
    const[code,setCode] = useState('')
    const[verifiedEmail,setVerifiedEmail] = useState(false)
    const[mobileNumber,setMobileNumber] = useState('')
    const[phoneCode,setPhoneCode] = useState('')
    const[otpMobile,setOtpMobile]=useState(true)
    const[takeInputMobile,setTakeInputMobile] = useState("")
    const[resendMobile,setResendMobile] = useState(false)
    const[verifiedMobile,setVerifiedMobile] = useState(false)
    const [verification,setVerification] = useState(false)
    const [roleId, setRoleId] = useState(0)
    const [loginFlag, setLoginFlag] = useState(false)


      const sendMobileOtp=()=>{
            const phnumber = mobileNumber
            const input={
                access_token:localStorage.token,
                mobile_no:"+"+localStorage.mobile_number
            }
            otpMobileVerification(input).then(res => {
                console.log("res",res)
            try{
            if (res.status==='successfull') {
                setTakeInputMobile(true)
                setOtpMobile(false)
                setResendMobile(true)
                
        }
        else{}
        }catch{}
        })
    }

    const submitMobileOtp=()=>{
        const input={
            access_token:localStorage.token,
            given_otp:phoneCode
        }
        console.log(input)
        otpMobileVerificationSumbit(input).then(res => {
            console.log("res",res)
        try{
        if (res.status==='successfull') {
           setLoginFlag(true)
            
    }
    else{
        alert("Incorrect OTP")
    }
    }catch{}
    })
    }
    
    const resendOtpMobile=()=>{
        const phnumber = mobileNumber
        let number = '+'+phnumber;
        const input={
            access_token:localStorage.token,
            mobile_no:localStorage.mobile_number
        }
        otpMobileVerification(input).then(res => {
            console.log("res",res)
        try{
        if (res.status==='successfull') {
            alert("New Otp sent successfully")
    }
    else{}
    }catch{}
    })
}
    

    const log_out=(e)=>{
        e.preventDefault()
        localStorage.clear();
        props.history.push(`/`)
    }

    // const handleEmail =(code)=>{
    //     setCode(code)
    // }
    const handleMobile =(phoneCode)=>{
        setPhoneCode(phoneCode)
    }
    
    console.log(localStorage);
    if (loginFlag===true){
        if(localStorage.role_id === '2'){return  <Navigate to='/admin'/>}
        if(localStorage.role_id === '3'){return  <Navigate to='/admin/operation'/>}
        if(localStorage.role_id === '4'){return  <Navigate to='/admin/sales'/>}
        if(localStorage.role_id === '5'){return  <Navigate to='/dashboard'/>}
        if(localStorage.role_id === '6'){return  <Navigate to='/admin/marketting'/>}
    }
        

  return (
      <div>
        <div className='parentBody'>
          <Row align='middle' className='ToolTopBar'>
                <Col span={5}>
                <img src={logo} alt="" className="collspLogoImg"/>
                </Col>
                <Col span={15}></Col>
                <Col span={3}>
                    <Space>
                        <Tooltip title="Logout">
                            <Button onClick={log_out} className='DashedButton' shape="circle" icon={<LogoutOutlined/>} />
                        </Tooltip>
                    </Space>
                </Col>
            </Row>
                {/* <div>
                <div className='emailVerification'>Email Verification</div>
                    <div className='verifyEmail'>{localStorage.email}</div>
                        {takeInputEmail?(
                            <>
                                <div className='otpEmailInput'>
                                    <OtpInput
                                        value={code}
                                        onChange={handleEmail}
                                        numInputs={4}
                                        separator={<span>-</span>}
                                        containerStyle='containerStyle'
                                        inputStyle='inputStyle'
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                        hasErrored={false}
                                    />
                                </div>
                                <div className='resendEmailText'>Didn't get OTP <span onClick={resendOtp} className='resendEmailtext-b'>Resend OTP</span></div>
                <div className='otpEmailverbutt'><Button type="primary" onClick={submitOtpValue}>Sumbit</Button></div>
                </>):null}
                

                
                {otpEmail?(
                <div className='otpEmailverbutt'><Button type="primary" onClick={sendEmailOtp} >Send OTP</Button></div>
                
             ):(<span></span>)}
             {verifiedEmail?(
             <div className='verifyEmail'><Tag icon={<CheckCircleOutlined />} color="success">
                Verified</Tag></div>):null}

            </div> */}

            {/* <div className='emailVerification'>Mobile Number Verification</div>
            <div className='verifyEmail'>{localStorage.mobile_number}</div>
            {takeInputMobile?(
                            <>
                                <div className='otpEmailInput'>
                                    
                                </div>
                                <div className='resendEmailText'>Didn't get OTP <span onClick={resendOtpMobile} className='resendEmailtext-b'>Resend OTP</span></div>
                                <div className='otpEmailverbutt'><Button type="primary" onClick={submitMobileOtp}>Sumbit</Button></div>
                                </>):null}
                
                        {otpMobile?(
                            <div className='otpEmailverbutt'><Button type="primary" onClick={sendMobileOtp} >Send OTP</Button></div>):(<span></span>)}

            {verifiedMobile?(
             <div className='verifyEmail'><Tag icon={<CheckCircleOutlined />} color="success">
                Verified</Tag></div>):null} */}
                <div>
                    <div className='form-heading'>OTP Verification</div>
                    <div className='row'>
                        <div className='col-md-7 br-right'>
                            <div className='otpimg'><img src={mobile} alt=""/></div>
                        </div>
                        <div className='col-md-5'>
                            <div className='otp-text-div'>
                                <div className='otpprevmsg'>A four digit verification code will be send to your registered mobile number.Please verify it to proceed furthur.
                                </div>
                                <div className='phnumdiv'>+{localStorage.mobile_number}</div>
                                <div id="recaptcha"></div>
                                {takeInputMobile?(
                                    <>
                                
                                
                                    <div className='otp-input-shift'>
                                    {/* <OtpInput
                                        value={phoneCode}
                                        onChange={handleMobile}
                                        numInputs={4}
                                        separator={<span>-</span>}
                                        containerStyle='containerStyle'
                                        inputStyle='inputStyle'
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                        hasErrored={false}
                                    /> */}
                                </div>
                                <div className='resendEmailText'>Didn't get OTP <span onClick={resendOtpMobile} className='resendEmailtext-b'>Resend OTP</span></div>
                                <div className='otp_submit_btn'><button onClick={submitMobileOtp}>Submit</button></div>
                                </>):null}
                              

                                 {/* {this.state.resend?( */}
                                    {/* <div className='dinnot-text'>Didn't receive OTP */}
                                        {/* <span> */}
                                            {/* <Countdown date={Date.now() + 30000} renderer={renderer}/> */}
                                        {/* </span> */}
                                    {/* </div>): */}
                                    {/* ( */}
                                        {otpMobile?(
                                        <div className='otp_submit_btn'><button onClick={sendMobileOtp}>Send OTP</button></div>
                                        ):(<span></span>)}
                                 {/* )} */}
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
    </div>
  )
}

export default Verification
import React, { useEffect } from 'react'
import './Webinar.scss'
import Logo from '../../../Images/logoBlack.png'
import Thank from '../../../Images/ThankYou.png'
import {MDBIcon} from 'mdbreact'
import {Helmet} from "react-helmet";

const ThankYou = () => {

  return (
    <>
        <div className='webBannerImageThank'>
                <Helmet>
                    <script>{
                        `!function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '605915974361956');
                        fbq('track', 'PageView');
                        fbq('track', 'CompleteRegistration');`
                        }
                    </script>
                </Helmet>
            <div className='webBody' >
                <div className='logoWebnr'>
                    <img src={Logo} alt=''/>
                </div>
                <div align='center'>
                    <div className='ThankYouImg'>
                        <img src={Thank} alt=''/>
                    </div>

                    <div className='Th-l1'>For expressing your interest in our upcoming <b>Webinar</b> on the topic:</div><br/>

                    <div className='Th-l2'>Do you have Enough money?...</div>
                    <div className='Th-l2_1'>....to live the best lifestyle and not worry about running out of money ever</div><br/>

                    <div className='Th-l3'>Make sure to attend the webinar to take your first step towards a secure financial future!</div>

                    <div className='Th-l5'>The webinar joining link : <a href='https://bit.ly/webinar-financial-management-3rd-Dec' target='blank'>https://bit.ly/webinar-financial-management-3rd-Dec</a>
</div>
                </div>
            </div>
        </div>
        <footer className='webfooterThank'>
            <MDBIcon far icon="copyright" /> 2022 Houseofalpha.in All rights reserved
        </footer>
    </>
  )
}

export default ThankYou
import React,{useEffect,useState} from 'react'

import { Row, Col,Table,Drawer,Popconfirm, Typography,message, Modal,Button,Image} from 'antd';
import {DeleteFilled,EditFilled,EyeOutlined} from '@ant-design/icons';
import parse from 'html-react-parser';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {hoa_blogs_get_new,hoa_blogs_delete} from '../Api'
import Loader from '../../../shared/Loader'
import './SocialActivity.scss'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Home = () => {
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [detailData,setDetailData] = useState({})
    const [modalType,setModalType] = useState('')
    const [title, setTitle] = useState('')
    const [value, setValue] =  useState("");
    const [body, setBody] = useState('')
    const [image,setImage] = useState('')
    const [loading,setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toastMsg =(id,msg,type)=>{
      toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    }


    const getBlogList= async () =>{
        setLoading(true)
        let newdata = []
          const input = {access_token:localStorage.token}
          const res = await hoa_blogs_get_new(input)
          console.log('bloglist',res);
          try{
            if (res && res.status==='successfull'){ 
              setLoading(false)
              Object.keys(res.result).map((val)=>{
                newdata.push({ 
                url: res.result[val].url,
                title:res.result[val].title,
                date:res.result[val].date,
                value:res.result[val].value,
                image:res.result[val].image,
              })
              })
              setData(newdata)
              setLoading(false);
            }
            else{setLoading(false); toast.error(res.message);}
          }catch{setLoading(false); toast.error('Something went wrong');}
        }
        useEffect(()=>{
            getBlogList()
        },[])

      const handleTitle = (e) => { setTitle(e.target.value)}
      const handleChange = (content) =>{setValue(content)}

      const handleImage = (e) => {setImage(e.target.files[0])}

        const submitData = (e) =>{
          const id = toast.loading("Please wait...",{toastId:'load'})
          e.preventDefault();
          let data = new FormData();
          data.append('image', image);
          data.append('title', title);
          data.append('value', value);
          
         
          console.log('bcxjbjchb',data);
          fetch('/alpha_admin/new_hoa_blogs', {
              method: 'POST',
              headers: {
                  'Authorization': localStorage.token
                },
              body: data
          }).then(response => response.json())
          .then(data => {
              console.log("bshb",data)
            if(data.status==='successfull'){
                  toastMsg(id,data.message,'success')
                  setVisible(false)
                  getBlogList();
                  setIsModalVisible(false)
                  setTitle('')
                  setBody('')
                  setImage('')
            }
            else{toastMsg(id,data.message,'error')}
          }).catch(err => {toastMsg(id,'Something went wrong','error')});
      }
   
  
      const handleBlogChange = (html) =>{
        let aa = detailData
        aa['value'] = html
        setDetailData(aa)
    }
      const handleBlogChange1 = (e) =>{
        let aa = detailData
        console.log(e.target.files[0])
        aa['image'] = e.target.files[0]
        setDetailData(aa)
    }
    const handleSave = (e) =>{
      const id = toast.loading("Please wait...",{toastId:'load'})
      e.preventDefault();
      let data = new FormData();
      data.append('image', detailData.image);
      data.append('title', detailData.title);
      data.append('value', detailData.value);
      
     
      console.log('bcxjbjchb',data);
      fetch('/alpha_admin/new_hoa_blogs', {
          method: 'PUT',
          headers: {
              'Authorization': localStorage.token
            },
          body: data
      }).then(response => response.json())
      .then(data => {
          console.log("bshb",data)
        if(data.status==='successfull'){
              toastMsg(id,data.message,'success')
              setVisible(false)
              getBlogList();
              setIsModalVisible(false)
              setDetailData('')
              // setImage('')
        }
        else{toastMsg(id,data.message,'error');}
      }).catch(err => {toastMsg(id,'Something went wrong','error')});
  }

  const showDrawerAction=(type,record)=>{
    setVisible(true);
    setModalType(type)
    setDetailData(record)
  }   
  const onClose = () => {
      setVisible(false);
      setModalType('')
      setDetailData({})
    };
    
    const handleDelete=(record)=>{
        const id = toast.loading("Please wait...",{toastId:'load'})
        const input={
            access_token:localStorage.token,
            row:record.url
        }
        console.log("record",record)
        hoa_blogs_delete(input).then(res => {
          console.log("res",res)
          try{
            if (res.status==='successfull') {
              toastMsg(id,res.message,'success')
              getBlogList();
              
            }
            else{toastMsg(id,res.message,'error')}
          }catch{toastMsg(id,'Something went wrong','error')}
        })  
    }

    const showModal = () => {
      setIsModalVisible(true);
    };
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const columns = [
      {
        title: 'Image',
        dataIndex: 'image',
        width: 50,
        render: (image) =>(
          <Image width={50} src={'data:image/png;base64,'+ image}/>
        )
      },
          {
            title: 'Title',
            dataIndex: 'title',
            width: 240,
          },
          {
            title: 'Date',
            dataIndex: 'date',
            width: 80,
          },
          {
            title: 'url',
            dataIndex: 'url',
            width: 200,
          },
          {
            title: 'Operation',
            dataIndex: 'operation',
            fixed: 'right',
            width: 140,
            render: (_, record) => {
              return(
                <>
                <span style={{marginRight:'10px'}}>
                <Typography.Link
                    onClick={() => showDrawerAction('Blog Details',record)}>
                    <span className='outlineEditButton'> <EyeOutlined style={{ fontSize: '13px', color: '#F7DC6F', marginRight:'3px' }} />View</span>
                </Typography.Link>
                </span>
                <Typography.Link
                    onClick={() => showDrawerAction('Edit Blog',record)}>
                    <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
                </Typography.Link>
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                  <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'10px' }} />
                </Popconfirm>
                 </>
              )
            },
          }, 
      ];
  
    return (
        <>
          <ToastContainer theme='colored'/>
          {loading?<Loader/>:null}
          <Row>
              <Col span={24}>
                  <Table
                      rowClassName="editable-row"
                      columns={columns}
                      dataSource={data}
                      size='small'
                      scroll={{y: 380 }}
                      sticky={true}
                  />
              </Col>
          </Row>

          <div className='addBtn'>
            <button onClick={()=>showDrawerAction('Add Blog')}>Add Blog</button>
          </div>

          <Drawer title={modalType} placement="top" height={'600'} onClose={onClose} visible={visible}>
            {modalType==='Add Blog'?
              <div>
                <div>
                      <p className='testimonialModalHead'>Title</p>
                      <textarea  rows="2" cols="100"  onChange={handleTitle} value={title} ></textarea>

                       <p className='testimonialModalHead'>Thumbnail Image</p> 
                      <input type="file" accept="image/jpeg,image/png"  onChange={handleImage} />
                      {/* <CropRotateImage sign={setSign} clear_sign={clear_sign}/>  */}

                </div>
                <div>
                  <SunEditor 
                    placeholder="Please type here..."
                    setOptions={{
                      buttonList: [['font','bold','underline', 'italic', 'strike','fontColor','fontSize','align','table','horizontalRule','lineHeight','list','textStyle','paragraphStyle','blockquote','hiliteColor',],
                      ['image','video','link','preview','fullScreen']],
                      charCounter:true
                    }}
                    autoFocus={true}
                    onChange={handleChange}
                    height={280}
                    width='auto'
                  />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" onClick={showModal} data-dismiss="modal">Save Blog</button>
                </div>
                </div>:null}

                <Modal visible={isModalVisible} onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                      Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={submitData}>
                      Save & Publish
                    </Button>,
                    ]}
                >
                  <h5>you want to save the blog.</h5>
                </Modal>
                {modalType==='Edit Blog'?
                <>
                  {detailData?<>
                  <div className='BlogModalCode'>
                      <div className='blogModal'>
                        <input value={detailData.title} name="title" disabled />

                        <p className='testimonialModalHead'>Thumbnail Image</p> 
                        <input type="file" accept="image/jpeg,image/png" onChange={handleBlogChange1} />
                      </div>
                        <div>
                          <SunEditor 
                            defaultValue={detailData.value}
                            setOptions={{
                              buttonList: [['font','bold','underline', 'italic', 'strike','fontColor','fontSize','align','table','horizontalRule','lineHeight','list','textStyle','paragraphStyle','blockquote','hiliteColor'],
                              ['image','video','link','preview','fullScreen']],
                             

                            }}
                            autoFocus={true}
                            onChange={handleBlogChange}
                            height={280}
                          />
                        </div>
                      <div className='blogSubmitBtn'><button  className='' onClick={handleSave}>Save</button></div>
                  </div>
                  </>:null}
                </>:null}

                {modalType==='Blog Details'?<>
                {detailData?
                <> 
                <div className='row'>
                  <div className='col-md-12'>
                    <p className='RecomDetailHead'><b>Title :</b> {detailData.title}</p>
                    <p className='RecomDetailHead newLin'><b>Value :</b> {parse(detailData.value)}</p>
                  </div>
                  {/* <div className='col-md-4'>
                    <p className='blogThumbnailImage'><b>Thumbnail Image :</b> <img src={'data:image/png;base64,'+ detailData.image} alt=''></img></p>  
                  </div> */}
                </div> 
                  
                  
                </>
                :null}
                </>:null}
            </Drawer>
        </>
    )
}

export default Home



import React,{useState,useEffect} from 'react'
import { Table,Typography,Popconfirm,Form,message,Select,Input,Tag } from 'antd';
import { EditFilled,CloseCircleFilled,SaveOutlined } from '@ant-design/icons';
import {screen_management_data,screen_management_data_put} from '../Api'
import Loader from '../../../shared/Loader';

const ScreenManagement = () => {

    const [loading,setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();

    const { Option } = Select;

    const getScreenManagement = async()=>{
        setLoading(true)
        let newdata = []
        const input = {access_token:localStorage.token}
        const res = await screen_management_data(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.keys(res.result).map((val)=>{
              newdata.push({   
              key: val,
              screen: val,
              super_admin: res.result[val].super_admin,
              admin: res.result[val].admin,
              operation: res.result[val].operation,
              sales: res.result[val].sales,
              marketing: res.result[val].marketing,
              
            })
            })
            setData(newdata)
            setLoading(false)
          }
          else{setLoading(false)}
        }catch{setLoading(false)}
      }
      useEffect(()=>{
        getScreenManagement()
      },[])


      const save = async (key) => {
        setLoading(true)
          try {
      const row = await form.validateFields();
      const newData = [...data];

      const index = newData.findIndex((item) => key === item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
              setData(newData);
              setEditingKey('');
              console.log(newData[index]);
            const input = {
              access_token:localStorage.token,
              row:newData[index],
            }
            screen_management_data_put(input).then(res => {
                console.log(res);
                try{
                  if (res && res.status==='successfull'){
                    getScreenManagement();
                    setLoading(false);
                    message.success("Saved");
                    }
                  else{
                    setLoading(false)
                    message.error(res.message);
                  }
                }catch{
                  setLoading(false);
                }
              })
            } else {
              newData.push(row);
              setData(newData);
              setEditingKey('');
              setLoading(false)
            }
          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
            setLoading(false)
          }
        };

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        disable,
        ...restProps
      }) => {

       
        return (
            <td {...restProps}>
        {editing? (
          
          <Form.Item
            name={dataIndex}
            rules={[{required: true,message: `Please Input ${title}!`}]}
          >
                <Select  size='small' style={{fontSize:'9px',width:'40%',padding:'1px'}}>
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                </Select> 
          </Form.Item>
          
        ) : (
          children
        )}
      </td>
    );
  };

    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
    form.setFieldsValue({
      name: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };
      

    const columns = [
        {
          title: 'Screen',
          dataIndex: 'screen',
          width: 50,
          // fixed: 'left',
        },
        {
          title: 'Super Admin',
          dataIndex: 'super_admin',
          width: 30,
          editable: true,
          render: super_admin => (
            <Tag color={super_admin==='Y'?'green':'red'} key={super_admin}>
                <div style={{fontSize:'8px'}}>{super_admin}</div>
            </Tag>
          ),
          //  render: (_, record) => {
          //     return record.super_admin==='Y'?(<Checkbox checked/>):(<Checkbox checked/>)
          //  }
        },
        {
          title: 'Admin',
          dataIndex: 'admin',
          width: 30,
          editable: true,
          render: admin => (
            <Tag color={admin==='Y'?'green':'red'} key={admin}>
                <div style={{fontSize:'8px'}}>{admin}</div>
            </Tag>
          ),
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: 30,
          editable: true,
          render: operation => (
            <Tag color={operation==='Y'?'green':'red'} key={operation}>
                <div style={{fontSize:'8px'}}>{operation}</div>
            </Tag>
          ),
        },
        {
          title: 'Sales',
          dataIndex: 'sales',
          width: 30,
          editable: true,
          render: sales => (
            <Tag color={sales==='Y'?'green':'red'} key={sales}>
                <div style={{fontSize:'8px'}}>{sales}</div>
            </Tag>
          ),
        },
        {
          title: 'Marketing',
          dataIndex: 'marketing',
          width: 30,
          editable: true,
          render: marketing => (
            <Tag color={marketing==='Y'?'green':'red'} key={marketing}>
                <div style={{fontSize:'8px'}}>{marketing}</div>
            </Tag>
          ),
        }, 
        {
          title: 'Operation',
          dataIndex: 'operation',
          // fixed: 'right',
          width: 30,
          render: (_, record) => {
              const editable = isEditing(record);
           
      return editable ? (
        <span>
        <Typography.Link onClick={() => save(record.key)} style={{marginRight: 5}}>
          <span className='outlineSaveButton'>
            <SaveOutlined style={{ fontSize: '11px', color: '#82E0AA', marginRight:'3px' }} />Save
          </span>
        </Typography.Link>
        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            <CloseCircleFilled style={{ fontSize: '13px', color: '#CB4335', marginLeft:'15px' }}/>
        </Popconfirm>
      </span>
    ) : (
      <span>
      <Typography.Link disabled={editingKey !== ''}
        onClick={() => edit(record)}>
        <span className='outlineEditButton'> <EditFilled style={{ fontSize: '11px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
      </Typography.Link>
      </span>
            )
            },
          }, 
        ];
      
      const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            
          }),
        };
      });


    
  return (
    <div className='adminBg'>
        {loading?<Loader/>:null}
        <Form form={form} component={false}>
        <Table 
            components={{body: {cell: EditableCell}}}
            pagination={false}
            rowClassName="editable-row"
            columns={mergedColumns}
           dataSource={data} 
           size='small'
           scroll={{y: 720 }}
            />
        </Form>
    </div>
  )
}

export default ScreenManagement
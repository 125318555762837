import React, { useState, useEffect } from 'react';
import './Wg.scss'
import AreaGraph from './AreaGraph';
import Compare from './Compare';
import { Row, Col,Modal,Form, Input,Button} from 'antd';
import {networth_as_it_is,networth_scenario,networth_scenario_case,networth_scenario_case_get} from './Api'
import { MDBIcon } from 'mdbreact';
import Loader from '../../../shared/LoaderTransparent'
import Expense from '../../FinancialProfile/Expense3'
import Income from '../../FinancialProfile/Income'

const Wg = (props) => {

    const [graphData, setGraphData] = useState({});
    const [tableData, setTableData] = useState({});
    const [graphDataScen, setGraphDataScen] = useState({});
    const [tableDataScen, setTableDataScen] = useState({});
    const [scnerioModal, setScnerioModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [scenarioId, setScenarioId] = useState(0);
    const [md2, setmd2] = useState(false);
    const [md3, setmd3] = useState(false);
    const [compareModal, setCompareModal] = useState(false)

    const handleModal1 = () => {
        setScnerioModal(!scnerioModal);
        setScenarioId(1)
      };
    const handleModal2 = () => {
        setScnerioModal(!scnerioModal);
        setScenarioId(2)
    };
    const handleModal3 = () => {
        setScnerioModal(!scnerioModal);
        setScenarioId(3)
    };
    const handleScenario1 = ()=>{fetchNetworthScenario(1)}
    const handleScenario2 = ()=>{fetchNetworthScenario(2)}
    const handleScenario3 = ()=>{fetchNetworthScenario(3)}
    
    const numberFormat=(value)=>{
        var val = Math.abs(value)
        if (val >= 10000000) {
          val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
          val = (val / 100000).toFixed(2) + ' L';
        }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
        return val;
      }

    const fetchNetworthAsItIs = async () =>{
        const input = {access_token:localStorage.token,id:props.id}
        const res = await networth_as_it_is(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('asirit',res);
        try{
          if (res.status==='successfull'){
        
            setGraphData(res.result.networth_growth_chart)
            setTableData(res.result.networth_as_it_is)
          }else{}
        }catch{}
    }
    const fetchNetworthScenario = async (scenarioId) =>{
        setGraphDataScen({})
        setLoading(true)
        const input = {
            access_token:localStorage.token,
            customer_id:props.customer_id,
            id:props.id,
            scenario_id:scenarioId   
        }
        console.log(input);
        const res = await networth_scenario(input)
        .catch((err) => {console.log("Err: ", err)});
        // console.log(res);
        try{
          if (res.status==='successfull'){
            setGraphDataScen(res.result.networth_growth_chart)
            setTableDataScen(res.result.networth_scenario)
            setLoading(false)
          }else{  setLoading(false)}
        }catch{  setLoading(false)}
      }

    useEffect(()=>{
        fetchNetworthAsItIs()
        fetchNetworthScenario(scenarioId)
    },[])

    const onSubmitLogin = async (values)=>{
        const input = {
            access_token:localStorage.token,
            customer_id:props.customer_id,
            id:props.id,
            scenario_id:scenarioId,
            values:values
        }
        const res = await networth_scenario_case(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log('ress',res);
        try{
          if (res.status==='successfull'){
            fetchNetworthScenario(scenarioId)
            setScnerioModal(!scnerioModal);
          }else{}
        }catch{}
    }

    const handleModal22=()=>{
        setmd2(!md2)
    }
    const handleModal33=()=>{
        setmd3(!md3)
    }
    const handleCompareModal=()=>{
        setCompareModal(!compareModal)
    }

    const BottomCard=(props)=>{
        const {data} = props
        return(
            <>
                <Row>
                    <Col span={12}>
                        <div className='wgCardTopRow wgCardDiv'>
                            <div>Retirement Age</div>
                            <b>{data.retirment_age} Years Old</b>
                        </div>
                    </Col>
                    <Col span={12} style={{borderLeft:'1px solid white'}}>
                        <div className='wgCardTopRow wgCardDiv'>
                            <div>Life Expectancy</div>
                            <b>100 Years</b>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div className='wgCardDiv'>
                            <div>Wealth</div>
                            <div><b>{numberFormat(data.wealth)}</b></div>
                        </div>
                        <div className='wgCardDiv'>
                            <div>Income</div>
                            <div><b>{numberFormat(data.income)}</b></div>
                        </div>
                        <div className='wgCardDiv'>
                            <div>Expense</div>
                            <div><b>{numberFormat(data.expense)}</b></div>
                        </div>
                    </Col>
                    <Col span={12} style={{borderLeft:'1px solid #FF862C'}}>
                        <div className='wgCardDiv'>
                            <div>Wealth Growth Rate</div>
                            <div><b>{data.growth_rate_of_wealth} %</b></div>
                        </div>
                        <div className='wgCardDiv'>
                            <div>Income Growth Rate</div>
                            <div><b>{data.income_increase_rate} %</b></div>
                        </div>
                        <div className='wgCardDiv'>
                            <div>Expense Growth Rate</div>
                            <div><b>{data.expense_increase_rate} %</b></div>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

  return (
    <>
        <div className='WGContainer'>
            <div className='wgGraphHead'>Networth Growth As it is
                <div className='wgRightButton'>
                <button className='wgScenBtnAct' onClick={handleCompareModal}>Compare
                        <MDBIcon fas icon="balance-scale" className='wgScenBtnEdit'/>
                    </button>
                </div>
            </div>
            <Row>
                <Col span={6}>
                    <div className='networthBtmCard'><BottomCard data={tableData}/></div>
                </Col>
                <Col span={18}>
                    <div className='WGchartArea'>
                        {Object.keys(graphData).length>0?
                        <AreaGraph color='#53d961'graphData={graphData}/>:null}
                    </div>
                </Col>
            </Row>
        </div>
        <div className='WGContainer'>
            <div className='wgGraphHead'>Networth Growth Scenarios
                <div className='wgRightButton'>
                    <button className={tableDataScen.scenario_id===1?'wgScenBtnAct':'wgScenBtn'} onClick={handleScenario1}>Scenario 1 
                        <MDBIcon far icon="edit" className='wgScenBtnEdit' onClick={handleModal1}/>
                    </button>
                    <button className={tableDataScen.scenario_id===2?'wgScenBtnAct':'wgScenBtn'} onClick={handleScenario2}>Scenario 2
                        <MDBIcon far icon="edit" className='wgScenBtnEdit' onClick={handleModal2}/>
                    </button>
                    <button className={tableDataScen.scenario_id===3?'wgScenBtnAct':'wgScenBtn'} onClick={handleScenario3}>Scenario 3
                        <MDBIcon far icon="edit" className='wgScenBtnEdit' onClick={handleModal3}/>
                    </button>
                </div>
            </div>

            <Row>
                <Col span={6}>
                    <div className='networthBtmCard'><BottomCard data={tableDataScen}/></div>
                </Col>
                <Col span={18}>
                    <div className='WGchartArea'>
                    {Object.keys(graphDataScen).length>0?
                        <AreaGraph color='#e9872e' graphData={graphDataScen}/>:null}
                    </div>
                </Col>
            </Row>
        </div>
        {/* <Row gutter={[20, 20]} style={{marginTop:'15px'}}>
            <Col span={12}>
                <div className='cashflowGraphHead'>Networth As it is</div>
                <div className='networthBtmCard'><BottomCard data={tableData}/></div>
            </Col>
            <Col span={12}>
                <div className='cashflowGraphHead'>Networth Growth Scenarios</div>
                <div className='networthBtmCard'><BottomCard data={tableDataScen}/></div>
            </Col>
        </Row> */}
        <Modal  footer={null} visible={scnerioModal} onCancel={handleModal1}>
            {loading?<Loader/>:
            <>
            <button className='wgScenBtnAct'>Income
                <MDBIcon far icon="edit" className='wgScenBtnEdit' onClick={handleModal33}/>
            </button>

            <button className='wgScenBtnAct'>Expense
                <MDBIcon far icon="edit" className='wgScenBtnEdit' onClick={handleModal22}/>
            </button>
         
            <Form onFinish={onSubmitLogin}>
                <div className='wgFormLabel'>Retirement Age</div>
                <Form.Item
                name="retirement_age"
                rules={[{ required: true, message: 'Please input your Retirement Age' }]}
                hasFeedback
                initialValue={tableDataScen.retirment_age}
                >
                <Input className='wgFormInput'/>
                </Form.Item>

                <div className='wgFormLabel'>Wealth Growth Rate</div>
                <Form.Item
                name="growth_rate_of_wealth"
                rules={[{ required: true, message: 'Please input your Wealth Growth Rate' }]}
                hasFeedback
                initialValue={tableDataScen.growth_rate_of_wealth}
                >
                    <Input className='wgFormInput' />
                </Form.Item>

                <div className='wgFormLabel'>Income Increase Rate</div>
                <Form.Item
                name="income_increase_rate"
                rules={[{ required: true, message: 'Please input your Income Increase Rate' }]}
                hasFeedback
                initialValue={tableDataScen.income_increase_rate}
                >
                    <Input className='wgFormInput'/>
                </Form.Item>

                <div className='wgFormLabel'>Expense Increase Rate</div>
                <Form.Item
                name="expense_increase_rate"
                rules={[{ required: true, message: 'Please input your Expense Increase Rate' }]}
                hasFeedback
                initialValue={tableDataScen.expense_increase_rate}
                >
                    <Input className='wgFormInput'/>
                </Form.Item>
                <Form.Item>
                <Button htmlType="submit" className="loginFormButton">
                    Submit
                </Button>
                </Form.Item>
            </Form>
            </>}
        </Modal>
        <Modal footer={null} visible={md2} onCancel={handleModal22} width='100%'>
            <Expense scenarioId={scenarioId} id={props.id} dashboard={1}/>
        </Modal>
        <Modal footer={null} visible={md3} onCancel={handleModal33} width='100%'>
            <Income scenarioId={scenarioId} id={props.id} dashboard={1} userInfo={1}/>
        </Modal>

        <Modal footer={null} visible={compareModal} onCancel={handleCompareModal} width='100%'
        style={{ top: 1 }}centered={true}>
            <Compare id={props.id}/>
        </Modal>
    </>
  )
}

export default React.memo(Wg)
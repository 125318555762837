import React,{useEffect} from 'react'

const HomeBannerText = () => {
  
  return (
    <div class="positivity">
        <div class="positivity__words">
        <span class="change">Spending Guilt Free </span>
        <span class="change">Pursuing Your Passions</span>
        <span class="change">Building Lasting Legacy</span>
        </div>
  </div>
  )
}

export default HomeBannerText
import React,{useState,useEffect} from 'react';
import { Table } from "ant-table-extensions";
import {customer_mf_details} from '../../Api'
import { DatePicker, Space } from 'antd';
import moment from 'moment'
const MfHoldings = (props) => {


    const [data, setData] = useState([]);
    const date = new Date().toISOString().split('T')[0]
    const [dateSend, setdateSend] = useState([])


    
      const fetchData = ()=>{
        let newdata = []
        const input = {
            access_token:localStorage.token,
            type:'mf_holdings',
            id:props.customer_id,
            start_date:dateSend,
        }
        customer_mf_details(input).then(res => {
            console.log('mfholding',res);
            Object.keys(res.result).map((val)=>{
                newdata.push({ 
                key: val,
                date:res.result[val].date,
                average_price: res.result[val].average_price,
                customer_id: res.result[val].customer_id,
                folio: res.result[val].folio,
                fund: res.result[val].fund,
                last_price: res.result[val].last_price,
                last_price_date: res.result[val].last_price_date,
                pnl: res.result[val].pnl,
                quantity: res.result[val].quantity,
                tradingsymbol: res.result[val].tradingsymbol,
                xirr: res.result[val].xirr,
                account_type: res.result[val].account_type,
                name: res.result[val].name,
                key1: res.result[val].key1,
                })
            })
            setData(newdata)
          })
        }
      useEffect(()=>{
        fetchData()
      },[])

      const submitData =()=>{
        fetchData()
      }
    
      const onChangeDate = (date, dateString) => {
        setdateSend(dateString)
      };

      const columns = [
        {title: 'Updated Date',dataIndex: 'date',width: 70},
        {title: 'Account Type',dataIndex: 'account_type',width: 70},
        {title: 'Name',dataIndex: 'name',width: 70},
        {title: 'Average Price',dataIndex: 'average_price',width: 70},
        {title: 'Customer Id',dataIndex: 'customer_id',width: 70},
        {title: 'Folio',dataIndex: 'folio',width: 70},
        {title: 'Fund',dataIndex: 'fund',width: 70},
        {title: 'Key1',dataIndex: 'key1',width: 70},
        {title: 'Last Price',dataIndex: 'last_price',width: 70},
        {title: 'Last Price Date',dataIndex: 'last_price_date',width: 70},
        {title: 'PnL',dataIndex: 'pnl',width: 70},
        {title: 'Quantity',dataIndex: 'quantity',width: 70},
        {title: 'Trading Symbol',dataIndex: 'tradingsymbol',width: 70},
        {title: 'XIRR',dataIndex: 'xirr',width: 70},
      ]
      console.log(data);
  return(
      <>
      <div className='modalDateBox'>
      <DatePicker onChange={onChangeDate} placeholder={date}
        disabledDate={(current) => {
        return current && current.valueOf() > Date.now();
        }} />
        <button className='dateSubmitButton' onClick={submitData}>Submit</button>
      </div>
        <Table
              rowClassName="editable-row"
              columns={columns}
              dataSource={data}
              size='small'
              scroll={{ x: 1150, y: 400 }}
              pagination={{
                pageSize: 10,
                position:['bottomCenter']
              }}
              sticky={true}
              exportableProps={{ fileName: `MFHoldings_${props.cusEmail}_${date}`}}
          />

      </>
  );
};

export default MfHoldings;

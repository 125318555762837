import React from 'react'
import './Assets.scss'
import { Pie} from '@ant-design/plots';

const Donut = (props) => {
  // const numberFormat=(value)=>{
  //   var val = Math.abs(value)
  //   if (val >= 10000000) {
  //     val = (val / 10000000).toFixed(2) + ' Cr';
  //   } else if (val >= 100000) {
  //     val = (val / 100000).toFixed(2) + ' L';
  //   }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
  //   return val;
  // }

  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
      // style: 'currency',
      // currency: 'INR',
      minimumFractionDigits:0
  }).format(value);

  let colArr = []
  Object.values(props.donutData).map((val)=>{
      colArr.push(val.color)
  })
     
    const data = props.donutData
      const config = {
        appendPadding: 0,
        autoFit:false,
        width:680,
        height:230,
        data,
        angleField: 'value',
        colorField: 'type',
        // color:['#FFBF00','#F5F5DC','#FFEA00','#FFFF8F','#DFFF00','#E4D00A','#FFF8DC','#FFFDD0','#FAD5A5','#EEDC82','#FFD700','#FFC000','#FCF55F','#F8DE7E','#F0E68C','#FFFAA0'],
        color:colArr,
        radius: .8,
        innerRadius: 0.6,
        meta: {
          value: {
            formatter: (v)=> {return numberFormat(Math.trunc(v))},
          },
        },
        label: false,
        tooltip:{
          domStyles: {
            'g2-tooltip-value': {fontSize:'10px'},
            'g2-tooltip-name': {fontSize:'10px'}
          },
          formatter: (text) => {
          return {name: text.type ,value: numberFormat(Math.trunc(text.value))}
          },
        },
        legend: {
            offsetX:-130,
            offsetY:0,
            marker:{symbol:'square'},
        },
        statistic: {
            title: false,
            content: {
              style: {
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                fontSize: 14,
              },    
              // content: 'Assets',
            },
          },
      };

      const config1 = {
        appendPadding: 10,
        autoFit:false,
        width:780,
        data,
        angleField: 'value',
        colorField: 'type',
        color:colArr,
        radius: .8,
        innerRadius: 0.6,
        meta: {
          value: {
            formatter: (v)=> {return numberFormat(Math.trunc(v))},
          },
        },
        label: false,
        tooltip:{
          domStyles: {
            'g2-tooltip-value': {fontSize:'10px'},
            'g2-tooltip-name': {fontSize:'10px'}
          },
          formatter: (text) => {
          return {name: text.type ,value: numberFormat(Math.trunc(text.value))}
          },
        },
        legend: {
            offsetX:-60,
            offsetY:-10,
            marker:{symbol:'square'},
        },
        statistic: {
            title: false,
            content: {
              style: {
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                fontSize: 14,
              },    
              // content: 'Assets',
            },
          },
      };

  return (
    <>
      {/* <div className='dashPie'><Pie {...config}  className='smallScr'/></div> */}
      <div className='dashPie'><Pie {...config}/></div>

      {/* <Pie {...config1}  className='BigScr'/> */}
    </>
  )
}

export default React.memo(Donut)
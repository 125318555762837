import React,{useState,useEffect} from 'react'
import './Faq.scss'
import './FaqMedia.scss'
import Navbar from '../Universal/Navbar'
import Footer from '../Universal/Footer'
import FooterContact from '../Universal/FooterContact'
import Accordian from './Accordian'

const Frequently = (props) => {
    return (
        <>
        <div><Navbar /></div>
            <div className='bodyDiv'>
                <div className='faqBannerImage'></div>
                <div className='mapBg'>
                    <div className='sectionAskHead'>Some Questions You May Have</div>
                    <div><span><Accordian/></span></div>
                </div>
            <div><FooterContact /></div>
            <div className='footrcontcBG'></div>
            <div><Footer /></div>
        </div>
        </>
    )
}

export default Frequently

import React, { Component } from 'react'
import {MDBIcon} from 'mdbreact'
import {get_expense_details,save_expense_details,expense_scenario,get_expense_scenario} from './Api'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../shared/Loader';
import './FinancialProfile.scss'
import './FinancialProfileMediaQuery.scss'
import CurrencyInput from 'react-currency-input-field';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastMsg =(ids,msg,type)=>{
    toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
  } 

export default class Expense3 extends Component {
    constructor(props){
        super(props);
        this.state={
            data_dict:{},
            skip:true,
            disabled:false,
            loading:false,
            warning:false,
        }
    }

    fetch_expense_scenerio=()=>{
        this.setState({data_dict:{}})
        const input = {
            access_token:localStorage.token,
            id:this.props.id,
            flag:'expense',
            scenario_id:this.props.scenarioId
        }

        get_expense_scenario(input).then(res => {
            console.log("resdrop",res)
            try{
                if (res.status==='successfull') {
                    this.setState(() => ({
                            data_dict:res.result,
                    }))
                }else{console.log(res)}
            }catch{
                toast.error('Something went wrong')
                this.setState({loading:false})
            }
        })
    }

    fetch_expense_details=()=>{
        const input = {
            access_token:localStorage.token,
            id:this.props.id,
            flag:'expense',
        }
        get_expense_details(input).then(res => {
            console.log("resdrop",res)
            try{
                if (res.status==='successfull') {
                    this.setState(() => ({
                            data_dict:res.result,
                    }))
                }else{console.log(res)}
            }catch{
                toast.error('Something went wrong')
                this.setState({loading:false})
            }
            })
    }

    componentDidMount(){
        if(this.props.expense===1){
            this.setState({disabled:true})
        }
       
        if(this.props.dashboard===1){
            this.fetch_expense_scenerio()
        }
        else{
           this.fetch_expense_details()
        }
    }

    componentDidUpdate(prevProps) {
        // console.log(prevProps,this.props.scenarioId);
        if (prevProps.scenarioId !== this.props.scenarioId) {
            this.fetch_expense_scenerio()
        }
      }
    onchangeAmount=(value,val,d,inx)=>{
        let aa = this.state.data_dict
        aa[val][d][inx].amount = value
        if(!value){
            aa[val][d][inx].amount = 0
        }
        this.setState({data_dict:aa})
        if(aa[val][d][inx].amount!==0){
            this.setState({skip:false})
        }else{
            this.setState({skip:true})
        }
    }
    onchangeRetAmount=(value,val,d,inx)=>{
        let aa = this.state.data_dict
        aa[val][d][inx].amount_retirement = value
        if(!value){
            aa[val][d][inx].amount_retirement = 0
        }
        this.setState({data_dict:aa})
        if(aa[val][d][inx].amount_retirement!==0){
            this.setState({skip:false})
        }else{
            this.setState({skip:true})
        }
    }
    onchangePostAge=(value,val,d,inx)=>{
        let aa = this.state.data_dict
        aa[val][d][inx].post_age = value
        if(!value){
            aa[val][d][inx].post_age = 0
        }
        this.setState({data_dict:aa})
        if(aa[val][d][inx].post_age!==0){
            this.setState({skip:false})
        }else{
            this.setState({skip:true})
        }
    }
    onchangeRemarks=(e,inx)=>{
        let aa = this.state.data_dict
        aa[e.target.id][e.target.name][inx].remarks = e.target.value
        this.setState({data_dict:aa})
        if(e.target.value){
            this.setState({skip:false})
        }else{
            this.setState({skip:true})
        }
    }
    onchangeStartDate=(e,inx)=>{
        console.log(e.target.value,inx);
        let aa = this.state.data_dict
        aa[e.target.id][e.target.name][inx].start_date = e.target.value
        this.setState({data_dict:aa})
        if(e.target.value){
            this.setState({skip:false})
        }else{
            this.setState({skip:true})
        }
    }
    onchangeEndDate=(e,inx)=>{
        let aa = this.state.data_dict
        aa[e.target.id][e.target.name][inx].end_date = e.target.value
        this.setState({data_dict:aa})
        if(e.target.value){
            this.setState({skip:false})
        }else{
            this.setState({skip:true})
        }
    }
    submitExpense=(e)=>{
        const ids = toast.loading("Please wait...",{toastId:'load'})
        e.preventDefault();
        const input = {
            access_token:localStorage.token,
            id:this.props.id,
            expense_details:this.state.data_dict
        }

    console.log('expense',input)
    if(this.props.expense===1){
        save_expense_details(input).then(res => {
            console.log("update",res)
            try{
            if (res.status==='successfull') {
                toastMsg(ids,res.message,'success')
                this.setState({
                    disabled:true
                })
              }
              else{
                this.props.update();
                this.props.step3();
                toastMsg(ids,res.message,'success')}
              }catch{toastMsg(ids,'Something went wrong','error')}
              })
    }else{
        save_expense_details(input).then(res => {
            console.log("resdrop",res)
            try{
            if (res.status==='successfull') {
                this.props.update();
                this.props.step3();
              }
              else{toastMsg(ids,res.message,'error')}
              }catch{toastMsg(ids,'Something went wrong','error')}
              })
    }
    }
    submitExpenseDB=(e)=>{
        const ids = toast.loading("Please wait...",{toastId:'load'})
        e.preventDefault();
        const input = {
            access_token:localStorage.token,
            id:this.props.id,
            expense_details:this.state.data_dict,
            scenario_id:this.props.scenarioId
        }

        expense_scenario(input).then(res => {
            console.log("scenrio",res)
            try{
            if (res.status==='successfull') {
                toastMsg(ids,res.message,'success')
              }
              else{
                toastMsg(ids,res.message,'success')}
              }catch{toastMsg(ids,'Something went wrong','error')}
              })
    }
    edit=()=>{
        this.setState({
            disabled:false
        })
      }
    cancel=()=>{
    this.setState({
        error:false,
        success:false,
        disabled:true,
        warning:false
    })
    }
    pleaseSave=(e)=>{
        e.preventDefault()
        this.setState({
            warning:true,
        })
    }
    onleave=()=>{
        this.setState({
            warning:false,
        })
      }
      handlesave=()=>{
        this.props.step3();
    }
    addFunc=(val,d,inx)=>{
        let newRow = 1
        const newRowItem={amount: 0,amount_org :0,amount_retirement:0,amount_retirement_org:0,post_age:0,post_age_org:0,remarks:"",start_date:this.state.data_dict[val][d][1].start_date,end_date: "NULL"}

        let aa = {...this.state.data_dict}
        Object.values(this.state.data_dict[val][d]).map(item => {
            newRow = newRow + 1
            if(!aa[val][d][newRow]){
                aa[val][d][newRow] = newRowItem
                this.setState({
                    data_dict:aa
                })
            }
          
       });
    }
    removeFunc=(group,asset)=>{
        let aa = {...this.state.data_dict}
        let length = Object.keys(aa[group][asset]).length
        if(length>1){
        delete aa[group][asset][length];
        this.setState({
            data_dict:aa
        })
        }
    }
    render() {
        const {disabled} = this.state
        let amount_arr = []
        let ret_amount_arr = []
        let post_amount_arr = []
        let t_amount = 0
        let ret_t_amount = 0
        let post_t_amount = 0
        let sumAmount = 0
        let sumRetAmount = 0
        let sumPostRetAmount = 0
        if (this.state.loading){
            return(
              <div style={{textAlign:'center',marginTop:100}}>
              <Loader/>
              </div>
              );
          }
          const numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits:0
        }).format(value);
          
        return (
        
            <div>
            <ToastContainer theme='colored' limit={1}/>
                 {this.state.error?(
                    <SweetAlert
                        error
                        onConfirm={this.cancel}>
                    {this.state.errormsg}
                    </SweetAlert>):(<span></span>)}

                {this.state.success?(
                    <SweetAlert
                        success
                        onConfirm={this.cancel}>
                    {this.state.successmsg}
                    </SweetAlert>):(<span></span>)}

                    {this.state.warning?(
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="success"
                    cancelBtnText="No"
                    cancelBtnBsStyle="danger"
                    onConfirm={this.handlesave}
                  onCancel={this.onleave}
                  focusCancelBtn>Do you want to proceed without save your changes</SweetAlert>):(<span></span>)}

                <div className='form-heading'>Expense Details
                    {this.props.expense===1?(
                    <span className='formButton'>
                        {disabled?
                        <button type="button" onClick={this.edit}><MDBIcon far icon="edit" className='form-edt-icon'/>Edit</button>:
                        <>
                        <button onClick={this.submitExpense}><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>
                        </>}
                    </span>):null}
                </div>
                
                <div className='formHeightSmall scrollB'>
                    <div className='row'>
                       <div className='col-md-12'>
                        {this.state.data_dict?(
                            <>
                                <div className='row tb_header-top'>
                                    <div className='col-3'>Expense Type</div>
                                    <div className='col-2 l-r-br'>Amount</div>
                                    <div className='col-2'>Amount<br/><small>(at retirement)</small></div>
                                    <div className='col-2'>Amount<br/><small>(Post Age 80Y)</small></div>
                                    <div className='col-1'>Start Date</div>
                                    <div className='col-1'>End Date</div>
                                    <div className='col-1 lf-border'>Remarks</div>
                                    {/* <div className='col-2'>Frequency</div> */}
                                </div>
                            {Object.keys(this.state.data_dict).map((val,index)=>{
                                t_amount = 0
                                ret_t_amount = 0
                                post_t_amount = 0
                                Object.keys(this.state.data_dict[val]).map((ss)=>
                                    Object.keys(this.state.data_dict[val][ss]).map((inx)=>{
                                    t_amount = t_amount + parseInt(this.state.data_dict[val][ss][inx].amount)
                                    ret_t_amount = ret_t_amount + parseInt(this.state.data_dict[val][ss][inx].amount_retirement)
                                    post_t_amount = post_t_amount + parseInt(this.state.data_dict[val][ss][inx].post_age) 
                                }))
                                amount_arr.push(t_amount)
                                ret_amount_arr.push(ret_t_amount)
                                post_amount_arr.push(post_t_amount)
                                return(
                                <div>
                                    <div className='row colapse-form-row' data-toggle="" data-target={'#'+val} aria-expanded="true" aria-controls={val}>
                                        <div className='col-3'>{val}</div>
                                        <div className='col-2 l-r-br'>{numberFormat(amount_arr[index])}</div>
                                        <div className='col-2'>{numberFormat(ret_amount_arr[index])}</div>
                                        <div className='col-4'>{numberFormat(post_amount_arr[index])}</div>
                                        <div className='col-1'><MDBIcon icon="angle-down" /></div>
                                    </div>
                                    <div id={val} class='' aria-labelledby="headingOne">
                                            {Object.keys(this.state.data_dict[val]).map((d,index1)=>
                                            Object.keys(this.state.data_dict[val][d]).map((inx,index2)=>{
                                               
                                                return(
                                                    <div className='row tb_body'>
                                                        {index2===0?
                                                        <div className='col-3'>{d}
                                                            <MDBIcon far icon="plus-square" className="assetAddIcon" onClick={()=>this.addFunc(val,d,inx)} />

                                                            <MDBIcon far icon="minus-square" className="assetAddIcon" onClick={()=>this.removeFunc(val,d,inx)} />
                                                        </div>:<div className='col-3'></div>}

                                                        <div className='col-2 l-r-br'>
                                                        <CurrencyInput
                                                                name={val}
                                                                placeholder={numberFormat(this.state.data_dict[val][d][inx].amount)}
                                                                // decimalsLimit={2}
                                                                disabled={this.state.disabled}
                                                                onValueChange={(value) => this.onchangeAmount(value,val,d,inx)}
                                                                intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                                                prefix="₹"
                                                        />
                                                        </div>

                                                        <div className='col-2'>
                                                        <CurrencyInput
                                                            name={val}
                                                            placeholder={numberFormat(this.state.data_dict[val][d][inx].amount_retirement)}
                                                            // decimalsLimit={2}
                                                            disabled={this.state.disabled}
                                                            onValueChange={(value) => this.onchangeRetAmount(value,val,d,inx)}
                                                            intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                                            prefix="₹"
                                                        />
                                                        </div>

                                                        <div className='col-2 lf-border'>
                                                        <CurrencyInput
                                                            name={val}
                                                            placeholder={numberFormat(this.state.data_dict[val][d][inx].post_age)}
                                                            // decimalsLimit={2}
                                                            disabled={this.state.disabled}
                                                            onValueChange={(value) => this.onchangePostAge(value,val,d,inx)}
                                                            intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                                            prefix="₹"
                                                        />
                                                        </div>
                                                        <div className='col-1 px-lg-1 lf-border'>
                                                            <input type="date" value={this.state.data_dict[val][d][inx].start_date} onChange= { (e)=>this.onchangeStartDate(e,inx)} id={val} name={d} disabled={this.state.disabled}/>
                                                        </div>
                                                        <div className='col-1 px-lg-1 lf-border'>
                                                            <input type="date" value={this.state.data_dict[val][d][inx].end_date} onChange= {(e)=>this.onchangeEndDate(e,inx)} id={val} name={d} disabled={this.state.disabled}/>
                                                        </div>

                                                        <div className='col-1 lf-border'>
                                                            <textarea type='text' placeholder={this.state.data_dict[val][d][inx].remarks} onChange= {(e)=>this.onchangeRemarks(e,inx)} id={val} name={d} disabled={this.state.disabled}/>
                                                        </div>

                                                        {/* <div className='col-2 lf-border'>
                                                            <input value="Annually" disabled/>
                                                        </div> */}
                                                    </div>
                                                )
                                            }))}
                                    </div>
                                </div>
                                )
                            })}
                            </>
                            ):null}
                       </div>
                    </div>
                </div>
                <div className='grandTotalArea'>
                    <div className='row tb_header-top'>
                        <div className='col-3'></div>
                        <div className='col-2 l-r-br'>
                            {amount_arr.map((val)=>{sumAmount = parseInt(sumAmount) + parseInt(val)})}
                            {numberFormat(sumAmount)}
                        </div>
                        <div className='col-2'>
                            {ret_amount_arr.map((val)=>{sumRetAmount = parseInt(sumRetAmount) + parseInt(val)})}
                            {numberFormat(sumRetAmount)}
                        </div>
                        <div className='col-2'>
                            {post_amount_arr.map((val)=>{sumPostRetAmount = parseInt(sumPostRetAmount) + parseInt(val)})}
                            {numberFormat(sumPostRetAmount)}
                        </div>
                        <div className='col-2 lf-border'></div>
                        <div className='col-2'></div>
                    </div>
                </div>
                {this.props.dashboard===1?<>
                    <button className="scenarioIncomeFormButton" onClick={this.submitExpenseDB}>Submit</button>
                </>:<>
                {this.props.expense===0 && this.state.skip?(
                    <div className='agree-checkbox'><input type='checkbox' onChange={this.enableNext}/>  Skip this section</div>):(<span></span>)}

                {this.props.income_expense===1?(<div></div>):(<div className='previous-msg'>Please fill the previous form first.</div>)}

                {this.props.income_expense===1 && this.props.expense===1?(
                <>
                {this.state.disabled?(
                    <div className='submit-Btn'><span onClick={this.props.step1}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</span><span  onClick={this.props.step3}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></span></div>
                ):(
                    <div className='submit-Btn'><button><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button type='button' onClick={this.pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>
                )}
                
                </>
                ):(<div></div>)}

            {this.props.expense===0 && this.props.income_expense===1?(
            <div className='submit-Btn'><button onClick={this.props.step1}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button onClick={this.submitExpense}>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>):(<div></div>)}
            </>}

            </div>
        )
    }
}

import React from 'react'
import NoInternetImg from '../Images/noInternet.jpg'

const NoInternet = () => {
  return (
    <div className='NoInternetDiv'>
        <img src={NoInternetImg} alt=''/>
        <div className='NoInternetHead'>Ooops!</div>
        <div className='NoInternetText'>No internet connection found. <br/>Please check your internet connection and try again</div>

    </div>
  )
}

export default NoInternet
import axios from "axios";

export const income_breakup = input => {
    return axios
        .get('/alpha_user_db/income_breakup',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const expense_breakup = input => {
    return axios
        .get('/alpha_user_db/expense_breakup',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const regular_investments = input => {
    return axios
        .get('/alpha_user_db/regular_investments',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

export const cashflow_growth_chart = input => {
    return axios
        .get('/alpha_user_db/cashflow_growth_chart',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const expense_age_meter = input => {
    return axios
        .get('/alpha_user_db/expense_age_meter',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              id:input.id
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
import React, {PureComponent } from 'react'
import RiskProfiling from './Risk_profiling'
import {get_client_sign,view_compliants_docs,phone_number,otp_verification_get,otp_verification_post} from './Api'
// import SweetAlert from 'react-bootstrap-sweetalert';
import Countdown from "react-countdown";
import mobile from '../../Images/Otp.jpg'
import './Compliance.scss'
import {MDBIcon} from 'mdbreact'
import OtpInput from 'react-otp-input';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastMsg =(ids,msg,type)=>{
    toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
} 

let pdf1=null
export default class Otp extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
          otp:true,
          mobile_number:'',
          risk_form:false,
          clientsign:'',
          error:false,
          success:false,
          download:false,
          successotp:false,
          counter:false,
          resend:false,
          takeInput:'',
          otpValue:'',
          viewModal:false,
          view:null
        }
    }
componentDidMount(e){
    const input = {
        access_token:localStorage.token,
      }
        phone_number(input).then(res => {
            console.log("resphonr",res)
            try{
            if (res.status==='successfull') {
                    this.setState({mobile_number:res.result.mobile_number})
                }
                else{toast.error(res.message)}
                }catch{toast.error('Something went wrong')}
        })

    if(this.props.risk_form===1){
        this.setState({
            otp:false,
            risk_form:false,
            download:true
        })
    }

    get_client_sign(input).then(res => {
        console.log("res",res)
    try{
    if (res.status==='successfull') {
    this.setState({
        clientsign:'data:image/png;base64,'+ res.result.client,
        })
}
else{}
}catch{}
})
}
sendotp=()=>{
    this.setState({resend:true})
    if(this.state.otp===true){
        const phnumber = this.state.mobile_number
        let number = '+'+phnumber;
        const input={
            access_token:localStorage.token,
            mobile_no:number
        }
        otp_verification_get(input).then(res => {
            console.log("res",res)
        try{
        if (res.status==='successfull') {
        toast.success('OTP sent successfully')
        this.setState({
           takeInput:true
            })
    }
    else{toast.error(res.message)}
    }catch{toast.error('Something went wrong')}
    })
    }
}
resendOtp=()=>{
    const phnumber = this.state.mobile_number
    let number = '+'+phnumber;
    const input={
        access_token:localStorage.token,
        mobile_no:number
    }
    otp_verification_get(input).then(res => {
        console.log("res",res)
    try{
    if (res.status==='successfull') {
    toast.success('OTP sent successfully')
    this.setState({
       takeInput:true
        })
}
else{toast.error(res.message)}
}catch{toast.error('Something went wrong')}
})

}
handleChange = (otp) =>{
    this.setState({ otpValue:otp })
    ;
  }
cancel=()=>{
    this.setState({
        error:false,
        success:false
    })
  }
  closeModaldocument=()=>{
    this.setState({
        viewModal:false,
        view:null
    });
  }
  download=()=>{
    const input = {
        access_token:localStorage.token,
        flag:'Risk_Profiling.pdf',
        id:this.props.id,
        dir: 'agreement'
    }
    view_compliants_docs(input).then(res => {
        console.log("resss",res)
        try {
            if (res.status==='successfull') {
                pdf1=res.result
                const linkSource = `data:application/pdf;base64,${pdf1}`;
                const downloadLink = document.createElement("a");
                const fileName = "Risk Profiling.pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
            else{toast.error(res.message)}
        } catch (error) {toast.error('Something went wrong')}
        })
}
submitOtpValue=()=>{
    const input={
        access_token:localStorage.token,
        given_otp:this.state.otpValue
    }
    console.log(input)
    otp_verification_post(input).then(res => {
        console.log("res",res)
    try{
    if (res.status==='successfull') {
        this.setState({
            otp:false,
            risk_form:true
        })
}
else{toast.error('Incorrect OTP')}
}catch{toast.error('Something went wrong')}
})
}
viewDocs=()=>{
    this.setState({viewModal:true})
    const input = {
        access_token:localStorage.token,
        flag:'Risk_Profiling.pdf',
        id:this.props.id,
        dir: 'agreement'
  }
  view_compliants_docs(input).then(res => {
      console.log("viewRes",res)
      try {
          if (res.status==='successfull') {
            this.setState({ view : `data:application/pdf;base64,${res.result}`})    
          }
          else{toast.error(res.message)}
      } catch (error) {toast.error('Something went wrong')}
      })
  }
    render() {
        let renderer= null
        if(this.state.otp===true){
            renderer = ({ hours, minutes, seconds, completed }) => {
                if (completed) {
                    return (<span className='resend-enable' onClick={this.resendOtp}> Resend OTP</span>);
                } else {
                return (
                <span className='resend-desable'> Resend OTP in <span className='otptimer'>({seconds})</span></span>);
                }
            };
        }
        return (
            <div>
            <ToastContainer theme='colored'/>
                {this.state.error?(
                    {/* <SweetAlert
                        error
                        onConfirm={this.cancel}>
                    {this.state.errormsg}
                    </SweetAlert> */}
                    ):(<span></span>)}

                {this.state.otp?(
                <div>
                    <div className='form-heading'>OTP Verification</div>
                    <div className='row'>
                        <div className='col-md-7 br-right'>
                            <div className='otpimg'><img src={mobile} alt=""/></div>
                        </div>
                        <div className='col-md-5'>
                            <div className='otp-text-div'>
                                <div className='otpprevmsg'>A four digit verification code will be send to your registered mobile number.Please verify it to proceed furthur.
                                </div>
                                <div className='phnumdiv'>+{this.state.mobile_number}</div>
                                <div id="recaptcha"></div>

                                {this.state.takeInput?(
                                    <>
                                    <div className='otp-input-shift'>
                                    <OtpInput
                                        value={this.state.otpValue}
                                        onChange={this.handleChange}
                                        numInputs={4}
                                        separator={<span>-</span>}
                                        containerStyle='containerStyle'
                                        inputStyle='inputStyle'
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                        hasErrored={false}
                                    />
                                </div>
                                <div className='otp_submit_btn'><button onClick={this.submitOtpValue}>Submit</button></div>
                                </>
                                ):null}

                                 {this.state.resend?(
                                    <div className='dinnot-text'>Didn't receive OTP
                                        <span>
                                            <Countdown date={Date.now() + 30000} renderer={renderer}/>
                                        </span>
                                    </div>):(<div className='otp_submit_btn'><button onClick={this.sendotp}>Send</button></div>
                                 )}
                            </div>
                        </div>
                    </div>
                </div>):(<span></span>)}

               {this.state.risk_form?(<div>
                   <RiskProfiling update={this.props.update} step5={this.props.step5} step3={this.props.step3} clientsign={this.state.clientsign} spousesign={this.state.spousesign} spouseName={this.state.spouseName} sign={this.props.sign} risk_form={this.props.risk_form}/>
               </div>):(<div></div>)}

               {this.state.download?(<div>
                <div className='form-heading'>Risk Profiling Questionnaire</div>
                <div className='data-inner-div' align='center'>
                <div className='download-text'>Your Risk Profiling Form is submitted. You can download it here. </div>

                <div classname='row'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                        <div className='docs-card'>
                            <div className='card-name'>Risk Profiling Form
                            <span className='upload-act'><i class="fas fa-check-circle"></i></span> 
                            <MDBIcon icon="eye" className='mdbicon' onClick={this.viewDocs}/>     
                            <MDBIcon icon="download" className='mdbicon' onClick={this.download}/>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>

                <div className='submit-Btn'>
                    <button type='button' onClick={this.props.step3}><MDBIcon icon="angle-left" className='buttonArrowLeft'/>Previous</button>
                    <button onClick={this.props.step5}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
                </div>

               </div>):(<div></div>)}

               {this.state.viewModal?(
                <div className='upload-modal-sec'>
                    <div className='row'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'>
                        <div className='viewDocs-modal-sec-card'>
                            <div className='modal-close-icon' onClick={this.closeModaldocument}><MDBIcon icon="times" />
                            </div>
                                
                            {this.state.view?(
                                <iframe src={`${this.state.view}#view=fitH`} title="testPdf" height="80%" width="100%" />
                        ):null}
                        </div>
                        </div>
                        <div className='col-md-4'></div>
                    </div>
                </div>):(<span></span>)}
            </div>
        )
    }
}

import axios from 'axios'

export const recommendations_data = input => {
    return axios
        .get('/alpha_admin/recommendations_data',
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const recommendations_data_put = input => {
    return axios
        .put('/alpha_admin/recommendations_data', {
            access_token:input.access_token,
            editRecommendation:input.editRecommendation,
            selectedClient:input.selectedClient
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const recommendations_data_delete = input => {
    return axios
        .delete('/alpha_admin/recommendations_data',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              recommendation_id:input.recommendation_id,
            }

          },)
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const recommendation_home = input => {
    return axios
        .get('/alpha_admin/recommendation_home',
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  
import React,{useState,useEffect} from 'react';
import {Table, Form, Input, Button,Typography,Popconfirm,Row,Col,message,Select } from 'antd';
import {DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined} from '@ant-design/icons';
import { Collapse } from 'antd';
import {admin_ria_percentage_post,admin_ria_percentage_get,admin_ria_percentage_delete,admin_ria_percentage_put,admin_last_index} from '../../Api'

const EditableCell=({editing,dataIndex,title,record,index,children,...restProps})=>{
    return (
      <td {...restProps}>
        {editing?
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}>
              <Input/>
          </Form.Item>
        :children}
      </td>
    );
  };

const RiaPercentage4 = () => {
    const [dataSource, setDataSource] = useState([])
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false)
    const { Panel } = Collapse;

    const getRiaPercentage = async()=>{
        setLoading(true)
        let newdata = []
        const input = {access_token:localStorage.token}
        const res = await admin_ria_percentage_get(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.keys(res.result).map((val)=>{
              newdata.push({ 
              key: res.result[val].key,
              email_address:res.result[val].email_address,
              percentage:res.result[val].percentage,
              })
            })
            setDataSource(newdata)
            setLoading(false)
          }
          else{setLoading(false)}
        }catch{setLoading(false)}
      }
      useEffect(() => {
        getRiaPercentage();
      },[]);

      const onFinish = (values) => {
        setLoading(true)
        const input = {
            access_token:localStorage.token,
            // id:values.id,
            email:values.email,
            percentage:values.percentage,
          }
          console.log(input);
          admin_ria_percentage_post(input).then(res => {
            console.log(res);
            try{
              if (res.status==='successfull') {
                getRiaPercentage()
                setLoading(false)
                message.success(res.message);
              }
              else{ 
                setLoading(false)
                message.error(res.message);
              }
                
            }catch{
              setLoading(false)
              message.error('Something went Wrong');
            }
            
            })
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

      const save = async (key) => {
        setLoading(true)
          try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
              setDataSource(newData);
              setEditingKey('');
            const input = {
              access_token:localStorage.token,
              row:newData[index],
            }
            admin_ria_percentage_put(input).then(res => {
                console.log(res,'abc');
                
                try{
                  if (res && res.status==='successfull'){
                    getRiaPercentage();
                    setLoading(false);
                    message.success("Saved");
                    }
                  else{
                    setLoading(false)
                    message.error(res.message);
                  }
                }catch{
                  setLoading(false);
                }
              })
            } else {
              newData.push(row);
              setDataSource(newData);
              setEditingKey('');
              setLoading(false)
            }
          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
            setLoading(false)
          }
        };

      const onDelete = async(record)=>{
        setLoading(true)
        const input = {
          access_token:localStorage.token,
          row:record.key
        }
        console.log(input)
        admin_ria_percentage_delete(input).then(res => {
        console.log(res);
          try{
            if (res && res.status==='successfull'){
              getRiaPercentage();
              setLoading(false);message.success(res.message)}
            else{setLoading(false); message.error(res.message)}
          }catch{setLoading(false); message.error('Something went Wrong')}
        }) 
    }

      const isEditing = (record) => record.key === editingKey;
        const edit = (record) => {
        form.setFieldsValue({email: '',percentage: '',...record});
        setEditingKey(record.key);
        };

        const cancel = () => {
        setEditingKey('');
        };

        const columns = [
            {title: 'Email',dataIndex: 'email_address',width: 120,editable: true},
            {title: 'Percentage',dataIndex: 'percentage',width: 120,editable: true},
            
            {
              title: 'Operation',
              dataIndex: 'operation',
              width: 120,
              render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                  <span>
                    <Typography.Link
                      onClick={() =>save(record.key)}
                      style={{
                        marginRight: 8,
                      }}
                    >
                      <span className='outlineSaveButton'>
                        <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
                      </span>
                    </Typography.Link>
                    <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                        <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
                    </Popconfirm>
                  </span>
                ) : (
                  <span>
                  <Typography.Link disabled={editingKey !== ''}
                    onClick={() => edit(record)}>
                    <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
                  </Typography.Link>
                  <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
                   <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
                  </Popconfirm>
                  </span>
                );
              },
            },
          ];

      const mergedColumns = columns.map((col) => {
        if (!col.editable) {return col;}
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

  return (
    <div>

      <Collapse >
        <Panel header="Add Row" key="1">
          <Form name="basic" layout='inline' onFinish={onFinish} onFinishFailed={onFinishFailed}
            style={{marginTop:'15px'}}>

              <Form.Item
                label="Email"
                name="email"
                rules={[{required: true,message: 'Please input Email!'}]}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Percentage"
                name="percentage"
                rules={[{required: true, message: 'Please input Percentage!'}]}>
                <Input/>
              </Form.Item>

              <Form.Item
                wrapperCol={{offset: 8,span: 16}}>
                <Button type="primary" htmlType="submit">Submit</Button>
              </Form.Item>
          </Form>
        </Panel>
      </Collapse>
        <Form form={form} component={false}>
        <Table
          components={{body: {cell: EditableCell}}}
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={true}
          size='small'
          />
      </Form>
      
    </div>
  )
}

export default RiaPercentage4
import React,{useEffect,useState} from 'react'
import {Table,Input,Space,Button,Row,Col,Form,message} from 'antd';
import { MDBIcon } from 'mdbreact';
import Highlighter from 'react-highlight-words';
import './Billing.scss'
import AddComment from '../comment/AddComment';
import { dashboard_billing } from './Api';

const Billing = (props) => {
  const [data, setData] = useState([]);
  const[searchText,setSearchText] = useState('')
  const[searchedColumn,setSearchedColumn] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading,setLoading] = useState(false)
  const [bill,setBill] = useState('')

  const numberFormat=(value)=>{
    var val = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' L';
    }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
    return val;
  }

  const getBillingDetails = async()=>{
    setLoading(true)
    let newdata = []
    const input = {access_token:localStorage.token,id:props.id}
    const res = await dashboard_billing(input)
    console.log('resbilling',res);
    try{
      if (res && res.status==='successfull'){
        setBill(res.result.billing_details); 
        Object.entries(res.result.billing_history).map((val)=>newdata.push(val[1]))
        setLoading(false)
        setData(newdata)
      }
      else{setLoading(false); message.error(res.message)}
    }catch{setLoading(false); message.error('Something went wrong')}
  }

  useEffect(() =>{
    getBillingDetails();
  },[])

  let searchInput = ''

  let getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<MDBIcon className='tableSearchI' icon="search"/>}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => {
            handleReset(clearFilters)
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
            }} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <MDBIcon className='tableSearchI' icon="search" style={{ color: filtered ? '#e87c1b' : '#000' }}/>,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };
  const  handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };
  // const handleClickRow=(record, rowIndex)=>{
  //   console.log(record, rowIndex);
  // }
  const handleModal = () => {setIsModalVisible(!isModalVisible);};

  const columns = [
    {title: 'SR.NO',dataIndex: 's_no',width: 60, ...getColumnSearchProps('s_no')},
    {title: 'Payment Date',dataIndex: 'payment_date',width: 100,...getColumnSearchProps('payment_date')},
    // {title: 'DUE DATE',dataIndex: 'due_date',width: 200,...getColumnSearchProps('comment')},
    {title: 'Paid Amount',dataIndex: 'paid_amount',width: 80,...getColumnSearchProps('paid_amount'),
    render: total => { return numberFormat(total)}},
    // {title: 'IN WORD',dataIndex: 'in_word',width: 80,...getColumnSearchProps('status')},
    // {title: 'Action',key: 'action',width: 60,
    //     render: (text, record) => (<MDBIcon icon="eye" onClick={handleModal} className='chartI' />),
    //   },
  ];

  return (
    <>
      <Form>
        {Object.keys(bill).length>0?
        <>
        <Row style={{marginTop:'15px'}} gutter={[40,0]}>
          <Col span={6}>
            <div className='formLbl'>Agreement Date</div>
            <Form.Item>
              <Input 
              value={bill[0].agreement_date} disabled 
              // prefix={<MDBIcon icon="calendar-day" className='loginFormIcon' />}
              className='InnerFormInput' />
            </Form.Item>
          </Col>
          <Col span={6}>
            <div className='formLbl'>Investment Amount</div>
            <Form.Item>
              <Input 
              value={numberFormat(bill[0].investment_amount)} disabled 
              className='InnerFormInput' />
            </Form.Item>
          </Col>
          <Col span={6}>
            <div className='formLbl'>Paid so far for the current year</div>
            <Form.Item>
              <Input 
              value={numberFormat(bill[0].paid_so_far)} disabled 
              className='InnerFormInput' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40,0]}>
          <Col span={6}>
            <div className='formLbl'>Balance Due</div>
            <Form.Item>
              <Input 
              value={numberFormat(bill[0].balance_due)} disabled 
              className='InnerFormInput' />
            </Form.Item>
          </Col>
          <Col span={6}>
            <div className='formLbl'>Estd. fees for the contract</div>
            <Form.Item>
              <Input 
              value={numberFormat(bill[0].est_fees)} disabled 
              className='InnerFormInput' />
            </Form.Item>
          </Col>
        </Row>
        </>:null}
      </Form>

      <div className='tableContainer'>
      <div className='tableTabContainer'>
        <div className='commentIconDiv'>
          <MDBIcon className='PagesCommentIcon'icon="comment-dots" onClick={handleModal}/>
        </div>
        <div className='tableHead'>Billing History</div>
      </div>

      <Table rowClassName="antTable" columns={columns} dataSource={data} size='small'
        pagination={{pageSize: 8,position:['bottomCenter']}} sticky={true} 
        />
        {isModalVisible? <AddComment handleModal={handleModal}/>:null}
    </div>
    </>
  )
}

export default Billing
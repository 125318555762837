import React,{useState,useEffect} from 'react'
import AssetReport from '../../Dashboard/reporting/HoldingReport'
import {customer_review} from '../Api'
import { Select,DatePicker} from 'antd';
import { holding_report } from '../../Dashboard/reporting/ReportsApi';
import HoldingTable from '../../Dashboard/reporting/HoldingTable'
import Loader from '../../../shared/Loader';
import { toast,ToastContainer } from 'react-toastify';

const ClientWise = (props) => {
    const [data, setData] = useState([]);
    const [cdata,setcData] =useState([])
    const[clientId,setClientId] = useState('')
    const [loading,setLoading] = useState(false)
    const [cDate, setDate] = useState(new Date().toISOString().split('T')[0]);

    const toastMsg =(id,msg,type)=>{
      toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    }

    const { Option } = Select;

    
    const fetchData =(value)=>{
      setLoading(true)
      setData([])
      const input = {
          access_token:localStorage.token,
          id:value,
          date: cDate,
          clientwise:'Y'
      }
      console.log(input);
      holding_report(input).then(res => {
        console.log('holding',res);
        try{
          if (res.status==='successfull'){
            setLoading(false)
            setData(res.result)
          }else{
            setLoading(false)
            toastMsg(res.message,'error')
          }
        }catch{
          setLoading(false)
          toastMsg('Something went wrong','error')
        }
        })
      
    }

    const fetchClient = ()=>{
        const input = {
            access_token:localStorage.token
        }
        let newdata = [...cdata]
        customer_review(input).then(res => {
            console.log(res,'ressCLI')
        try{
            if (res.status==='successfull'){
                Object.keys(res.result).map((val)=>{
                    newdata.push({
                    key: res.result[val].customer_id,
                    id: res.result[val].customer_id,
                    name:res.result[val].customer_fullname,
                    email_address:res.result[val].email_address,
                    zerodha:res.result[val].zerodha
                })
                })
                setcData(newdata)
                
            }else{toastMsg(res.message,'error')}
        }catch{toastMsg('Something went wrong','error')}
        })
    }

    
    useEffect(()=>{
        fetchClient()
      },[])

      const updateclientID=(value)=>{      
        fetchData(value)
        setClientId(value)
    }
      const onChange = (date, dateString) => {
        fetchData(dateString)
      };


  return (
    <div>
      {loading?<Loader/>:null}
      <Select
        style={{width:'55%'}}
        showSearch
        bordered={true}
        title='Client Mail ID'
        className='selectborder'
        placeholder="Select Client Mail ID"
        optionFilterProp="children"
        onChange={updateclientID}
        filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        // size='large'
        >
        {Object.keys(cdata).map((val)=>{
            return <Option value={cdata[val].id}>{cdata[val].email_address}, <b>{cdata[val].name}</b></Option>
            })}
      </Select>

          {Object.keys(data).length>0?(
        <>
        {/* <div className='reportRangebox'>
            <DatePicker onChange={onChange} placeholder={cDate}
            disabledDate={(current) => {
            return current && current.valueOf() > Date.now();
            }} />
        </div> */}
        
          {Object.keys(data).map((val)=>{
            if(data[val]===null){
              return(<div style={{textAlign:'center', padding:'10px'}}>No Data</div>)
            }else{
              return(
                <div className='holFamCard'>
                  <div className='famName'>{val}</div>
                  <HoldingTable id={clientId} xirrstatus='clientwise' famName={val} dataMain={data[val]}/>
                </div>
              )
            }
          })}
        </>
      ):null}


        
    </div>
  )
}

export default ClientWise

import React,{useState,useEffect} from 'react';
import './Crm.scss'
import {Row,Col,Tabs} from 'antd';
import AccountOpening from './zerodha/AccountOpening';

const Implementation = (props) => {
  const { TabPane } = Tabs;

  const{cusName,customer_id,cusEmail, close, tabName} = props
  const [tabs, setTabs] = useState('Zerodha Investment Plan');
  const [subTabs, setSubTabs] = useState('Zerodha Account Opening');

  const defaultTab = 'tab1';

  const onChange = (key) => {
    if(key==='Zerodha Investment Plan'){setSubTabs('Zerodha Account Opening')}
    if(key==='Outside Zerodha Investments'){setSubTabs('Other Demats')}
    if(key==='Insurance Plan'){setSubTabs('Life')}
    if(key==='Other Investment products'){setSubTabs('Currency Strategy')}
    setTabs(key)
  };
  const onChangeSub = (key) => {setSubTabs(key)};
  
  return (
    <>
      <div className='modalHeadBar'>
        <Row align='middle'>
          <Col span={4}>
            <div className='modalHeadBarTitle'>{tabName}</div>
          </Col>
          <Col span={15}>
            <div className='modalHeadBarTab'>
              <Tabs defaultActiveKey={defaultTab} onChange={onChange} size='small' centered={true}>
                <TabPane tab='Zerodha Investment Plan' key='Zerodha Investment Plan'></TabPane>
                <TabPane tab='Outside Zerodha Investments' key='Outside Zerodha Investments'></TabPane>
                <TabPane tab='Insurance Plan' key='Insurance Plan'></TabPane>
                <TabPane tab='Other Investment products' key='Other Investment products'></TabPane>
              </Tabs>
            </div>
          </Col>
          <Col span={4}>
            <div className='modalHeadBarTitle'>{cusName}</div>
            <p className='modalHeadBarP'>{cusEmail}</p>
          </Col>
          <Col span={1}>
            <div className='TableActionButton' onClick={close}>Close</div>
          </Col>
        </Row>
      </div>

      <div className='modalBodyBar'>
        <div className='modalHeadBarTab'>
          {tabs==='Zerodha Investment Plan'?
            <Tabs defaultActiveKey={defaultTab} onChange={onChangeSub} size='small' centered={true}>
              <TabPane tab='Zerodha Account Opening' key='Zerodha Account Opening'></TabPane>
              <TabPane tab='Lumpsum Investment' key='Lumpsum Investment'></TabPane>
              <TabPane tab='Monthly Investment SIP' key='Monthly Investment SIP'></TabPane>
            </Tabs>:null}
            {tabs==='Outside Zerodha Investments'?
            <Tabs defaultActiveKey={defaultTab} onChange={onChangeSub} size='small' centered={true}>
              <TabPane tab='Other Demats' key='Other Demats'></TabPane>
              <TabPane tab='Physical form' key='Physical form'></TabPane>
              <TabPane tab='FD' key='FD'></TabPane>
              <TabPane tab='Others' key='Others'></TabPane>
            </Tabs>:null}
            {tabs==='Insurance Plan'?
            <Tabs defaultActiveKey={defaultTab} onChange={onChangeSub} size='small' centered={true}>
              <TabPane tab='Life' key='Life'></TabPane>
              <TabPane tab='Health' key='Health'></TabPane>
              <TabPane tab='Topup' key='Topup'></TabPane>
              <TabPane tab='Critical' key='Critical'></TabPane>
            </Tabs>:null}
            {tabs==='Other Investment products'?
            <Tabs defaultActiveKey={defaultTab} onChange={onChangeSub} size='small' centered={true}>
              <TabPane tab='Currency Strategy' key='Currency Strategy'></TabPane>
              <TabPane tab='CCO strategy' key='CCO strategy'></TabPane>
              <TabPane tab='Fixed Income Agreement' key='Fixed Income Agreement'></TabPane>
            </Tabs>:null}
          </div>
      </div>

      <div>
        {subTabs==='Zerodha Account Opening'?<AccountOpening/>:null}
        {subTabs==='Lumpsum Investment'?'Lumpsum Investment':null}
        {subTabs==='Monthly Investment SIP'?'Monthly Investment SIP':null}
        {subTabs==='Other Demats'?'Other Demats':null}
        {subTabs==='Physical form'?'Physical form':null}
        {subTabs==='FD'?'FD':null}
        {subTabs==='Others'?'Others':null}
        {subTabs==='Life'?'Life':null}
        {subTabs==='Health'?'Health':null}
        {subTabs==='Topup'?'Topup':null}
        {subTabs==='Critical'?'Critical':null}
        {subTabs==='Currency Strategy'?'Currency Strategy':null}
        {subTabs==='CCO strategy'?'CCO strategy':null}
        {subTabs==='Fixed Income Agreement'?'Fixed Income Agreement':null}

      </div>
    </>
  )
}

export default Implementation
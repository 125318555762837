import React, { useState,useEffect} from 'react'
import { Table } from "ant-table-extensions";
import Loader from '../../../shared/Loader';
import {class_and_scheme_assets} from './Api'
import { DatePicker} from 'antd';
import { toast,ToastContainer } from 'react-toastify';


const Schemewise = (props) => {
    const [data, setData] = useState([]);
    const [cDate, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading,setLoading] = useState(false)

    const toastMsg =(id,msg,type)=>{
      toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
    }

  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
      // style: 'currency',
      // currency: 'INR',
      minimumFractionDigits:0
  }).format(value);

  const onChange = (date, dateString) => {
    fetchSchemeWiseData(dateString)
  };

  const fetchSchemeWiseData = (date)=>{
    setLoading(true)
    let newdata = []
    const input = {
      access_token:localStorage.token,
      type:'scheme_name',
      date:date
  }
  console.log(input);
  class_and_scheme_assets(input).then(res => {
    console.log('year',res);
    try{
      if(res.status==='successfull'){
      setLoading(false)
    Object.keys(res.result).map((val)=>{
        newdata.push({ 
          name: val,
          asset_class:res.result[val].asset_class,
          invested_value:res.result[val].invested_value,
          current_value:res.result[val].current_value,
          })
    })
    setData(newdata)
  }
  else{toastMsg(res.message,'error')}
    }
    catch{toastMsg('Something went wrong','error')}
    
  }) 
  }

  useEffect(()=>{
    fetchSchemeWiseData(cDate)
  },[])

  
  // console.log(data);
  const column = [   
    {title: 'Scheme Name',dataIndex: 'name'},
    {title: 'Asset Class',dataIndex: 'asset_class'},
    {title: 'Total Invested Value',align:'right',dataIndex: 'invested_value',render: total => { return total?(numberFormat(total.toFixed(0))):0}},
    {title: 'Total Current Value',align:'right',dataIndex: 'current_value',render: total => { return total?(numberFormat(total.toFixed(0))):0}},
  ]

  return (
    <>
    {loading?<Loader/>:null}
    <div className='reportRangebox'>
             <DatePicker onChange={onChange} placeholder={cDate}
             disabledDate={(current) => {
             return current && current.valueOf() > Date.now();
             }} />
         </div>
   
      <Table rowClassName="antTable" columns={column} dataSource={data} size='small'
          scroll={{y: 300}}
          pagination={true} 
          sticky={true}
          exportableProps={{ fileName: `schemewise_${localStorage.email}`}}
      />
</>
  )
}

export default Schemewise
import React,{useState, useEffect} from 'react'
import { Column } from '@ant-design/plots';
import ToolT from './ToolT'

const ColumnLine = (props) => {
  const data = props.columnData;
 
  // const numberFormat = (value) =>
  // new Intl.NumberFormat('en-IN', {
  //     style: 'currency',
  //     currency: 'INR',
  //     minimumFractionDigits:0,
  //     maximumFractionDigits: 2,
  //     notation: 'compact',
  //     compactDisplay: 'long',
  // }).format(value);

  const numberFormat=(value)=>{
    var val = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' L';
    }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
    return val;
  }
  let colArr = []
  Object.keys(props.data).map((val)=>{
    Object.keys(props.data[val]).map((val2)=>{
      if(props.data[val][val2].current_value!==0){
        let dup = colArr.includes(props.data[val][val2].color)
        if(dup===false){
          colArr.push(props.data[val][val2].color)
        }
      }
    })
  })
  console.log(colArr);
  const config = {
    data,
    isStack: true,
    xField: 'year',
    yField: 'value',
    color: colArr,
    maxColumnWidth:20,
    seriesField: 'type',
    label: false,
    legend:false,
    // legend: {
    //   layout: 'horizontal',
    //   position: 'bottom'
    // },
    yAxis:{
      label:{
      formatter: (text,item,index) => {return numberFormat(text)}
    }
    },
    tooltip:{
      // enterable:true,
      position:'top',
      // offsetY:80,
      // offsetX:-20,
      customContent: (title, data) => {
        return <ToolT title={title} 
        data={props.data}
        // data={props.columnData}
        
        
        />
      },
      // formatter: (text) => {
      // return {name: text.type ,value: numberFormat(text.value)}
      // },
    }
  };
  return (<Column {...config} />)
}

export default React.memo(ColumnLine)

import { ActionTypes } from "../constants/action-types";

export const setDisabled = (val) => {
  return {
    type: ActionTypes.SET_DISABLE,
    payload: val,
  };
};
export const setInstrument = (val) => {
  return {
    type: ActionTypes.SET_INSTRUMENT,
    payload: val,
  };
};

export const setHoldings = (val) => {
  console.log('val',val);
  return {
    type: ActionTypes.SET_HOLDINGS,
    payload: val,
  };
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 1200px) {
  .dashboardContentAreaOpen {
    margin-left: 5px;
    padding: 10px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Component/Dashboard/MediaQuery.scss"],"names":[],"mappings":"AAAA;EACI;IACI,gBAAA;IACA,kBAAA;EACN;AACF","sourcesContent":["@media only screen and (max-width: 1200px) {\n    .dashboardContentAreaOpen{\n        margin-left: 5px;\n        padding: 10px 20px;\n    }\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react'
import {admin_asset_goals,admin_asset_goals_post} from '../../Api'
import {MDBIcon} from 'mdbreact'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../../shared/Loader';

export default class Asset_goal extends Component {
    constructor(props){
        super(props);
        this.state={
            asset_goal_data:{},
            data_dict:{},
            show:false,
            loading:false,
            success:false,
            error:false,
            errormsg:'',
            successmsg:'',
        }
    }
componentDidMount(){
    this.setState({loading:true})
    const input = {
        access_token:localStorage.token,
        customer_id:this.props.customer_id,
    }
    admin_asset_goals(input).then(res => {
        console.log(res)
    try{
    if (res.status==='successfull') {
    this.setState(() => ({
        asset_goal_data:res.result,
        show:true,
        loading:false
    }))
    }
    else{
        this.setState(() => ({
            loading:false
        }))
    }
    }catch{
        this.setState(() => ({
            loading:false
        }))
    }
    })
}
change_asset_amount=(e)=>{
    let aa = this.state.data_dict
    aa[e.target.id].asset[e.target.name].asset_amount = e.target.value
    this.setState({data_dict:aa})
}
submit_asset_goal=()=>{
    this.setState({loading:true})
    const input={
        access_token:localStorage.token,
        customer_id:this.props.customer_id,
        data_dict:this.state.data_dict
    }
    admin_asset_goals_post(input).then(res => {
    try{
    if (res.status==='successfull') {
        this.setState({
            success:true,
            loading:false,
            successmsg:res.message
        })
    }
    else{
        this.setState({
            error:true,
            loading:false,
            errormsg:res.message
        })
    }
    }catch{
        this.setState({
            error:true,
            loading:false,
            errormsg:'Something went wrong'
        })
    }
    })
}
cancel=()=>{
    this.setState({
        success:false,
        error:false,
    })
    }
    render() {
        if (this.state.asset_goal_data && this.state.show===true){
            Object.keys(this.state.asset_goal_data).map((val)=>{
                this.state.data_dict[val]={"goal":this.state.asset_goal_data[val].goal,"goal_amount":this.state.asset_goal_data[val].goal_amount,"target_date":this.state.asset_goal_data[val].goal_target_date,"asset":[]}
               Object.keys(this.state.asset_goal_data[val].assets).map((val2)=>{
                Object.keys(this.state.asset_goal_data[val].assets[val2]).map((val3)=>{
                    this.state.data_dict[val]['asset'].push({"asset_name":val3,"asset_amount":this.state.asset_goal_data[val].assets[val2][val3]})
                })
               })
            })
            this.setState({
                show:false
            })

        }
        return (
            <div>
            {this.state.loading?(
                <div className='loader-main-div'>
                    <div className='loader-div'>
                        <Loader/>
                    </div>
                </div>
            ):(null)}

            {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancel}>
                {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

            {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
                {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

               <div className='update-table-div'>
                    <table class='table table-condensed'>
                        <thead className='mf-p-table-tr'>
                            <th>Goal</th>
                            <th>Goal Amount</th>
                            <th>Target Date</th>
                            <th>Asset Type : Asset Amount</th>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.data_dict).map((val)=>{
                                return(
                                    <tr className='mf-p-table-tr-bg'>
                                        <td>{this.state.data_dict[val].goal}</td>
                                        <td>{this.state.data_dict[val].goal_amount}</td>
                                        <td>{this.state.data_dict[val].target_date}</td>
                                        <td>
                                            {Object.keys(this.state.data_dict[val].asset).map((val2)=>{
                                                return(
                                                    <div className='row'>
                                                        <div className='col-4'>{this.state.data_dict[val].asset[val2].asset_name}</div>
                                                       <div className='col-3'>
                                                       <input value={this.state.data_dict[val].asset[val2].asset_amount} id={val} name={val2} onChange={this.change_asset_amount}/>
                                                       </div>
                                                    </div>
                                                )
                                            })}
                                        </td>
                                    </tr>
                                )

                            })}
                        </tbody>
                    </table>
                </div>
                <div className='update-sub-btn'><button onClick={this.submit_asset_goal}><MDBIcon icon="save" className='btn-i'/>Save</button></div>
            </div>
        )
    }
}

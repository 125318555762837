import React, { useEffect,useState } from 'react'
import '../Tabs.scss'
import { Pie} from '@ant-design/plots';

const DonutChart = (props) => {
  const data = props.cashFlowData;

  const numberFormat=(value)=>{
    var val = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' L';
    }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
    return val;
  }

  console.log(props.cashFlowData);

      const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        color:['#FFBF00','#F5F5DC','#FFEA00','#FFFF8F','#DFFF00','#E4D00A','#FFF8DC','#FFFDD0','#FAD5A5','#EEDC82','#FFD700','#FFC000','#FCF55F','#F8DE7E','#F0E68C','#FFFAA0'],
        radius: 1,
        innerRadius: 0.6,
        label: false,
        tooltip:{
          formatter: (text) => {
          return {name: text.type ,value: numberFormat(text.value)}
          },
        },
        legend: {
            offsetX:-20,
            marker:{
                symbol:'square',
                style:{}
            },
        },
        statistic: {
            title: false,
            content: {
              style: {
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                fontSize: 14,
              },
              content: numberFormat(props.total),
            },
          },
        interactions: [
            {
              type: 'element-selected',
            },
            {
              type: 'element-active',
            }
          ],
      };

  return (
    <Pie {...config} />
  )
}

export default React.memo(DonutChart)
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  width: 250px;
  height: 150px;
  margin: 30px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.uploadField {
  margin-bottom: 15px;
}

.fileName {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.startUploading {
  width: 120px;
}

.gapbutton {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Admin/docsUpload/DocsUpload.scss"],"names":[],"mappings":"AAWA;EACI,YAAA;EACA,aAAA;EACA,YAAA;EACA,6CAAA;EACA,gBAAA;EACA,oCAAA;EACA,uBAAA;EAEA,kBAAA;EACA,mBAAA;EACA,kCAAA;UAAA,0BAAA;AAXJ;;AAaA;EACI,mBAAA;AAVJ;;AAYA;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;AATJ;;AAWA;EACI,YAAA;AARJ;;AAUA;EAEI,gBAAA;AARJ","sourcesContent":["// .partitionBox{\n//     background: white;\n//     padding: 10px;\n// }\n// .partitionBox {\n//     background-image: url(../../../Images/upload_object.png);\n//     background-size: auto;          \n//     background-repeat:   no-repeat;\n//     background-position: center center;\n//     min-height: 100vh;  \n// }\n.card {\n    width: 250px;\n    height: 150px;\n    margin: 30px;\n    box-shadow: 20px 20px 50px rgba(0,0,0,0.2);\n    overflow: hidden;\n    background: rgba(255,255,255,0.1);\n    justify-content: center;\n    // align-items: ;\n    padding-left: 20px;\n    padding-right: 20px;\n    backdrop-filter: blur(5px);\n}\n.uploadField{\n    margin-bottom: 15px;\n}\n.fileName{\n    font-size: 16px;\n    font-weight: 600;\n    margin-bottom: 5px;\n}\n.startUploading{\n    width: 120px;\n}\n.gapbutton{\n\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React,{useEffect,useState} from 'react'
import { Row, Col,Table,Tag,Space,Drawer, Button,Input} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';

import {recommendation_home} from './Api'

const Home = () => {
    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [detailData,setDetaillData] = useState({})
    let searchInput = ''

    const getAllRecoomendation=()=>{
        const input = {access_token:localStorage.token}
        const res = recommendation_home(input)
        return res
      }
      useEffect(()=>{
          getAllRecoomendation().then(res => {
            console.log('home',res);
            let newdata = [...data]
            try{
              if (res && res.status==='successfull'){ 
                Object.keys(res.result).map((val)=>{
                  newdata.push({ 
                  key: val,
                  activity_date: res.result[val].activity_date,
                  customer_id:res.result[val].customer_id,
                  email_address: res.result[val].email_address,
                  customer_name: res.result[val].customer_name,
                  instruments_type: res.result[val].instruments_type,
                  exchange: res.result[val].exchange,
                  trading_symbol:res.result[val].trading_symbol,
                  name:res.result[val].name,
                  order_type:res.result[val].order_type,
                  strike:res.result[val].strike,
                  lot_size:res.result[val].lot_size,
                  limit_price:res.result[val].limit_price,
                  expiry:res.result[val].expiry,
                  quantity:res.result[val].quantity,
                  buy_or_sell:res.result[val].buy_or_sell,
                  status:res.result[val].status,
                  execute:res.result[val].execute,
                  recommendation_url:res.result[val].recommendation_url
                })
                })
                setData(newdata)
              }
              else{}
            }catch{}
      
          })
      },[])

      const showDrawer = (record) => {
        setVisible(true);
        setDetaillData(record)
      };
    
      const onClose = () => {
        setVisible(false);
        setDetaillData({})
      };

    let getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setSearchText(selectedKeys[0])
                  setSearchedColumn(dataIndex)
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
      
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
      };
      
      const  handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };

    const columns = [
      {
        title: 'Activity Time',
        dataIndex: 'activity_date',
        width: 150,
        ...getColumnSearchProps('activity_date')
    },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            width: 120,
            ...getColumnSearchProps('customer_name')
        },
        {
            title: 'Instruments Type',
            dataIndex: 'instruments_type',
            width: 130,
            ...getColumnSearchProps('instruments_type')
          },
          {
            title: 'Trading Symbol',
            dataIndex: 'trading_symbol',
            width: 170,
            ...getColumnSearchProps('trading_symbol')
          },
          {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 100,
            ...getColumnSearchProps('quantity')
          },
       
          {
            title: 'Buy or Sell',
            dataIndex: 'buy_or_sell',
            width: 90,
            ...getColumnSearchProps('buy_or_sell')
          },
          {
            title: 'Execute',
            dataIndex: 'execute',
            width: 80,
            ...getColumnSearchProps('execute')
          },
          {
            title: 'Status',
            dataIndex: 'status',
            width: 90,
            ...getColumnSearchProps('status'),
            render: status => (
              <Tag color={status==='OPEN'?'red':'green'} key={status}>
                  {status}
              </Tag>
            ),
          },
          {
            title: 'Order Type',
            dataIndex: 'order_type',
            width: 110,
            ...getColumnSearchProps('order_type')
          },
          {
            title: 'Action',
            key: 'action',
            width: 90,
            render: (text, record) => (
              <Button className='DashedButton' size='small' onClick={()=>showDrawer(record)}>Details</Button>
            ),
          },
      ];
   
      console.log(data,detailData);
    return (
        <div className='adminBg'>
            <Row>
                <Col span={24}>
                    <Table
                        rowClassName="editable-row"
                        columns={columns}
                        dataSource={data}
                        size='small'
                        scroll={{y: 380 }}
                        sticky={true}
                    />
                </Col>
            </Row>

          
            <Drawer title="Recommendation Details" placement="right" onClose={onClose} visible={visible}>
                {detailData?
                <>
                    <p className='RecomDetailHead'>Client ID : {detailData.customer_id}</p>
                    <p className='RecomDetailHead'>Client Name : {detailData.customer_name}</p>
                    <p className='RecomDetailHead'>Client Email : {detailData.email_address}</p>
                    <p className='RecomDetailHead'>Instruments Type : {detailData.instruments_type}</p>
                    <p className='RecomDetailHead'>Exchange        : {detailData.exchange}</p>
                    <p className='RecomDetailHead'>Trading Symbol : {detailData.trading_symbol}</p>
                    <p className='RecomDetailHead'>Name : {detailData.name}</p>
                    <p className='RecomDetailHead'>Lot Size : {detailData.lot_size}</p>
                    <p className='RecomDetailHead'>Strike : {detailData.strike}</p>
                    <p className='RecomDetailHead'>Expiry : {detailData.expiry}</p>
                    <p className='RecomDetailHead'>Order Type : {detailData.order_type}</p>
                    <p className='RecomDetailHead'>Limit Price : {detailData.limit_price}</p>
                    <p className='RecomDetailHead'>Quantity : {detailData.quantity}</p>
                    <p className='RecomDetailHead'>Buy or Sell : {detailData.buy_or_sell}</p>
                    <p className='RecomDetailHead'>Recommendation Url :<a href={detailData.recommendation_url} target='blank'>Open Link</a></p>
                    
                </>
                :null}
            </Drawer>
        </div>
    )
}

export default Home



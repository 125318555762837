import React,{useState,useEffect} from 'react'
import axios from "axios";
import {documents_dropdown,customer_review,customer_documents_upload_and_download,view_and_download_docs} from '../Api'
import CropRotateImage from '../../../shared/CropRotateImage'
import './DocsUpload.scss'
import { Upload, Button, message, Row, Col,Table,Space,Tooltip,Input,Select,Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import { UploadOutlined,SearchOutlined,DownloadOutlined,EyeOutlined} from '@ant-design/icons';
import {MDBIcon} from 'mdbreact'

const FilesDownload = () => {

  const [data,setData] = useState([])
  const [dataFile,setDataFile] = useState([])
  const [clientData, setClientData] = useState([])
  const [clientId,setClientId] = useState('')
  const [fileName,setFileName] = useState('')
  const [showInput, setShowInput] = useState(false);
  
  let searchInput = ''

  useEffect(()=>{
      const input = {
          access_token:localStorage.token
      }
      let newdata = [...data]
      documents_dropdown(input).then(res => {
          console.log(res,'ress')
      try{
          if (res.status==='successfull'){
              Object.keys(res.result).map((val)=>{
                  newdata.push({ 
                  key: res.result[val].customer_id,
                  dropdown:res.result[val],
                  fileid:val
              })
              })
              setData(newdata)
          }else{}
      }catch{}
      })
      const input2 = {
        access_token:localStorage.token
    }
    let newdata2 = [...clientData]
    customer_review(input2).then(res => {
        console.log(res,'ress')
    try{
        if (res.status==='successfull'){
            Object.keys(res.result).map((val)=>{
                newdata2.push({ 
                key: res.result[val].customer_id,
                id: res.result[val].customer_id,
                name:res.result[val].customer_fullname,
                email_address:res.result[val].email_address,
                zerodha:res.result[val].zerodha
            })
            })
            setClientData(newdata2)
        }else{}
    }catch{}
    })
  },[]
  )

  
      const fetchFiles=()=>{
        // setLoading(true)
        setShowInput(true);
        const input = {
            access_token:localStorage.token,
            id:clientId,
            doc_id:fileName
        }
        console.log(input);
        let newdata3 = []
        customer_documents_upload_and_download(input).then(res => {
          console.log('holding',res);
          try{
            if (res.status==='success'){
              Object.keys(res.result).map((val)=>{
                newdata3.push({ 
                keys: res.result[val].key,
                id: res.result[val].customer_id,
                name:res.result[val].document_name,
                date:res.result[val].date
            })
            })
              // setLoading(false)
              setDataFile(newdata3)
            }else{
              // setLoading(false)
            }
          }catch{
            // setLoading(false)
          }
          })
      }

      const downloadFiles=(value,name)=>{
        let docs = ''
        const input = {
            access_token:localStorage.token,
            flag:value.name,
            key:value.keys,
            id:value.id,
        }
        console.log(input)
        // console.log(document_name)
        let splitType = value.name.split('.').pop();
        view_and_download_docs(input).then(res => {
            console.log("resss",res)
            try {
                if (res.status==='successfull') {
                    docs=res.result.document_data                    
                    let linkSource = ''
                    if(splitType==='pdf'){
                      linkSource = `data:application/pdf;base64,${docs}`;
                    }
                    else{
                      linkSource = `data:image/png;base64,${docs}`;
                      linkSource = `data:image/jpeg;base64,${docs}`;
                    }
                    const downloadLink = document.createElement("a");
                    const fileName = value.name;
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
                else{}
            } catch (error) {}
            })
      }
      
      
      const updateclientID=(value)=>{
        setClientId(value)
    }
    const updatefileName=(value)=>{
      setFileName(value)
  }
  
  const column = [   
    {title: 'Customer ID',dataIndex: 'id',width: 100},
    {title: 'Date',dataIndex: 'date',width: 200},
    {title: 'Name',dataIndex: 'name',width: 300},
    {title: 'Action',key: 'action',width: 500,
            render: (text, record) => (<>
                <span  placement="bottomLeft" >    
        <button className='reportBtn'  onClick={()=>(downloadFiles(record))}>Download</button>
                </span>
                </>
            ),
          },
  ]
 


      const { Option } = Select;
  return (  
    <>
    <Row>
    <Select
        style={{ width: 500 }}
        showSearch
        bordered={true}
        title='Client Mail ID'
        className='selectborder'
        placeholder="Select Client Mail ID"
        optionFilterProp="children"
        onChange={updateclientID}
        filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        // size='large'
    >
    {Object.keys(clientData).map((val)=>{
        return <Option value={clientData[val].id}>{clientData[val].email_address}, <b>{clientData[val].name}</b></Option>
        })}
    </Select>
    <span style={{width:10}}></span>
    <Select
        style={{ width: 350 }}
        showSearch
        bordered={true}
        title='Client Mail ID'
        className='selectborder'
        placeholder="Select Document"
        optionFilterProp="children"
        onChange={updatefileName}
        filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        // size='large'
    >
    {Object.keys(data).map((val)=>{
        return <Option value={data[val].fileid}>{data[val].dropdown}</Option>
        })}
    </Select>
      <Col span={3}>     
      <button className='reportBtn' onClick={fetchFiles}>Submit</button>
      

      </Col>
      
      </Row>
      {showInput?<>
      <Table
        columns={column}
        pagination={false}
        rowClassName="antTable"
        dataSource={dataFile}
        size='small'
        bordered
    />
    </>:null}
      

         
    </>
  )
}

export default FilesDownload

import React,{useState,useEffect,useRef} from 'react';
import {masters_stocks_detail_get,masters_stocks_detail_post,masters_stocks_detail_delete} from '../Api'
import {Table, Form,Modal, Input, Button,Typography,Popconfirm,Row,Col,message,Select,Upload ,Tooltip,Space,Divider,Image} from 'antd';
import {DeleteFilled,EditFilled,CloseCircleFilled,SaveOutlined,UploadOutlined,DownloadOutlined,PlusOutlined,InboxOutlined} from '@ant-design/icons';
import { Collapse } from 'antd';
import {admin_ria_percentage_put,admin_last_index} from '../../Api'
import {directEqFile} from '../Api'

let index = 0;
const EditableCell=({editing,dataIndex,image,title,record,index,children,...restProps})=>{
    return (
      <td {...restProps}>
        {editing?
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}>
              <Input/>
          </Form.Item>
        :children}
        {/* {image?
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}>
              <input type="file" accept="image/jpeg,image/png"  onChange={updateImage} />
          </Form.Item>
        :children} */}
      </td>
    );
  };

  

const Master_stock_list = () => {
    const [dataSource, setDataSource] = useState([])
    const [fileList,setFileList] = useState([])
    const [editingKey, setEditingKey] = useState('');
    const [holdingUploading,setHoldingUploading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false)
    const { Panel } = Collapse;
    const [schemeName,setSchemeName] = useState('')
    const [isin,setIsin] = useState('')
    const [shortName, setShortName] = useState('');
    const [typeName, setTypeName] = useState('');
    const inputRef = useRef(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const[image,setImage] = useState('')
    const[file,setFile] = useState('')
    const[sectorName,setSectorName] = useState('')
  
    


    let pdf1=null

    const getBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const getRiaPercentage = async()=>{
        setLoading(true)
        let newdata = []
        const input = {access_token:localStorage.token}
        const res = await masters_stocks_detail_get(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.keys(res.result).map((val)=>{
              newdata.push({ 
              key: val,
              image:res.result[val].logo,
              scheme_isin:res.result[val].scheme_isin,
              scheme_name:res.result[val].scheme_name,
              scheme_sector:res.result[val].scheme_sector,
              short_name:res.result[val].short_name,
              })
            })
            setDataSource(newdata)
            setLoading(false)
          }
          else{setLoading(false)}
        }catch{setLoading(false)}
      }
      useEffect(() => {
        getRiaPercentage();
      },[]);

      const onFinish = (values) => {
        const formData = new FormData()
        console.log(sectorName);
        formData.append('image', image)
        // fileList.forEach((fileList) => {
        //   formData.append('image', fileList);
        // });
        formData.append('scheme_name', schemeName)
        formData.append('scheme_isin', isin)
        formData.append('scheme_sector', sectorName)
        formData.append('scheme_short_name', shortName)
        setHoldingUploading(true)
        fetch('/alpha_admin/masters_stocks_detail', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': localStorage.token
          },
        })
          .then(res => res.json())
          .then((data) => {
            console.log(data);
            if(data.status==='successfull'){ 
              message.success(data.message)}
              setFileList([])
              getRiaPercentage()
            if(data.status==='failed'){
            message.error(data.message)}
            setHoldingUploading(false);
          })
          .catch(() => {
            message.error('upload failed.')
            setHoldingUploading(false);
          })
          .finally(() => {});
      };

      // const onFinish = (values) => {
      //   setLoading(true)
      //   console.log(image);
      //   const formData = new FormData()
      //   formData.append('image', image)
      //   const input = {
      //       access_token:localStorage.token,
      //       // id:values.id,
      //       // image:image,
      //       scheme_name:values.scheme_name,
      //       scheme_sector:values.sector_name,
      //       scheme_isin:values.isin,
      //       scheme_short_name:values.short_name,
      //     }
      //     console.log(image);
      //     masters_stocks_detail_post(input,formData).then(res => {
      //       console.log(res);
      //       try{
      //         if (res.status==='successfull') {
      //           getRiaPercentage()
      //           setLoading(false)
      //           message.success(res.message);
      //         }
      //         else{ 
      //           setLoading(false)
      //           message.error(res.message);
      //         }
                
      //       }catch{
      //         setLoading(false)
      //         message.error('Something went Wrong');
      //       }
            
      //       })
      // };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

     


      // const handleChange = (value) => {
      //   setAssetCategory(value)
      //   console.log(value);
      // };

      // const save = async (key) => {
      //   setLoading(true)
      //     try {
      // const row = await form.validateFields();
      // const newData = [...dataSource];
      // const index = newData.findIndex((item) => key === item.key);
      //     if (index > -1) {
      //       const item = newData[index];
      //       newData.splice(index, 1, { ...item, ...row });
      //         setDataSource(newData);
      //         setEditingKey('');
      //       const input = {
      //         access_token:localStorage.token,
      //         row:newData[index],
      //       }
      //       admin_ria_percentage_put(input).then(res => {
      //           console.log(res,'abc');
                
      //           try{
      //             if (res && res.status==='successfull'){
      //               getRiaPercentage();
      //               setLoading(false);
      //               message.success("Saved");
      //               }
      //             else{
      //               setLoading(false)
      //               message.error(res.message);
      //             }
      //           }catch{
      //             setLoading(false);
      //           }
      //         })
      //       } else {
      //         newData.push(row);
      //         setDataSource(newData);
      //         setEditingKey('');
      //         setLoading(false)
      //       }
      //     } catch (errInfo) {
      //       console.log('Validate Failed:', errInfo);
      //       setLoading(false)
      //     }
      //   };

      const onDelete = async(record)=>{
        setLoading(true)
        console.log(record);
        const input = {
          access_token:localStorage.token,
          row:record.key,
          scheme_isin:record.scheme_isin
        }
        console.log(input)
        masters_stocks_detail_delete(input).then(res => {
        console.log(res);
          try{
            if (res && res.status==='successfull'){
              getRiaPercentage();
              setLoading(false);message.success(res.message)}
            else{setLoading(false); message.error(res.message)}
          }catch{setLoading(false); message.error('Something went Wrong')}
        }) 
    }

      const isEditing = (record) => record.key === editingKey;
        const edit = (record) => {
          
        form.setFieldsValue({sector_name: '',scheme_name: '',short_name: '',isin: '',...record});
        setEditingKey(record.key);
         
        };

        
        const cancel = () => {
        setEditingKey('');
        };

        const editImage = (image)=>{
          alert('ji',image)
          console.log(image);
          return(
          
            <input type="file" accept="image/jpeg,image/png"  onChange={updateImage} />
          )
        }


        const handleUploadHoldings = () => {
          const formData = new FormData();
          fileList.forEach(file => {formData.append('csv_file', file)});
          // formData.append('flag', 'holdings')
          setHoldingUploading(true)
          fetch('/alpha_admin/directEqFile', {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': localStorage.token
            },
          })
            .then(res => res.json())
            .then((data) => {
              console.log(data);
              if(data.status==='successfull'){
                
                message.success(data.message)}
              if(data.status==='failed'){
              message.error(data.message)}
              setHoldingUploading(false);
            })
            .catch(() => {
              message.error('upload failed.')
              setHoldingUploading(false);
            })
            .finally(() => {});
        };

        const getDocLink = (url) => {
          const str = url.substring(url.indexOf(";") + 1);
          return `data:text/csv;base64,${str}`;
        };


        const download=()=>{
          // setIsModalOpen(false)
          const input = {
              access_token:localStorage.token,
              // flag:name,
              // id:id,
              // filename: 'filename'
          }
          console.log(input);
          directEqFile(input).then(res => {
              console.log(input);
              console.log("resss",res)
              try {
                if (res.status==='successfull') {
                  pdf1=res.result
                  const linkSource = `data:application/vnd.ms-excel;base64,${pdf1}`;
                  const downloadLink = document.createElement("a");
                  downloadLink.href = linkSource;
                  downloadLink.download = "direct EQ.xlsx";
                  downloadLink.click();
              }
                  else{}
              } catch (error) {}
              })
      }

        const props = {
          maxCount:1,
          accept:'.csv, .xlsx',
          beforeUpload: file => {
            setFileList( [...fileList, file])
            return false;
          },
          fileList,
        };

        const columns = [
          // {title: 'Stock Logo',
          //   dataIndex: 'logo',key: 'logo',
          //   render: (dataIndexValue, record) => <img style={{width:30, borderRadius:50}} src={photo} alt="" />,width: 60
          // },
          {
            title: 'Scheme Logo',
            dataIndex: 'image',
            width:50,
            render: (image) =>(
              <Image style={{width:50, borderRadius:50}} src={'data:image/png;base64,'+ image}/>
        )
          }
          ,
            {title: 'Scheme Name',dataIndex: 'scheme_name',width: 170,editable: true},
            {title: 'Sector Name',dataIndex: 'scheme_sector',width: 100,editable: true},
            {title: 'ISIN',dataIndex: 'scheme_isin',width: 120,editable: true},
            {title: 'Short Name',dataIndex: 'short_name',width: 80,editable: true},
            
            {
              title: 'Operation',
              dataIndex: 'operation',
              width: 120,
              render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                  <span>
                    <Typography.Link
                      // onClick={() =>save(record.key)}
                      style={{
                        marginRight: 8,
                      }}
                    >
                      <span className='outlineSaveButton'>
                        <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
                      </span>
                    </Typography.Link>
                    <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                        <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
                    </Popconfirm>
                  </span>
                ) : (
                  <span>
                  <Typography.Link disabled={editingKey !== ''}
                    onClick={() => edit(record) || editImage(record)}>
                    <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
                  </Typography.Link>
                  <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
                   <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
                  </Popconfirm>
                  </span>
                );
              },
            },
          ];

      const mergedColumns = columns.map((col) => {
        if (!col.editable) {return col;}
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

      const handleModal = () => {
        setIsModalVisible(!isModalVisible);};

        const handlePreview = async (file) => {
          if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
          }
          setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleImage = (e) => {setImage(e.target.files[0])}
  const updateImage = (e) => {setImage(e.target.files[0])}
  const handleFile = (e) => {setFile(e.target.files[0])}
  
  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
  };
 console.log(fileList);
  // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  
  // console.log('ikmm',image);
  return (
    <div>

      <Collapse > 
        <Panel header="Add Row" key="1">
          <Form name="basic" layout='inline' onFinish={onFinish} onFinishFailed={onFinishFailed}
            style={{marginTop:'15px'}}  >
            <Col >
            <Form.Item
              wrapperCol={{ sm: 24 }}
              style={{ width: "35%", marginRight: 10 }}
                label="Select Image"
                >
                <input type="file" accept="image/jpeg,image/png"  onChange={handleImage} />
              </Form.Item>
                
            </Col>
            <Col >
              {/* <Form.Item
              wrapperCol={{ sm: 24 }}
              style={{ width: "35%", marginRight: 10 }}
                label="Select File"
                >
                <input type="file" accept="xsml/csv"  onChange={handleFile} />
              </Form.Item> */}
                
            </Col>
            <Col span={20}>
            <Row>
             
            <Form.Item
              wrapperCol={{ sm: 24 }}
              style={{ width: "35%", marginRight: 10 }}
                label="Scheme Name"
                name="scheme_name"
                rules={[{required:true,message: 'Please input Scheme name'}]}>
                <Input  onChange={(e) => setSchemeName(e.target.value)} />
              </Form.Item>
              <Form.Item
              wrapperCol={{ sm: 24 }}
              style={{ width: "35%", marginRight: 10 }}
                label="Sector name"
                name="sector_name"
                rules={[{required:true, message: 'Please input sector name'}]}>
                <Input  onChange={(e)=>setSectorName(e.target.value)}/>
              </Form.Item>

              </Row>
              <Row>
              
           
              <Form.Item
              wrapperCol={{ sm: 24 }}
              style={{ width: "35%", marginRight: 10 }}
                label="ISIN"
                name="isin"
                rules={[{required:true,message: 'Please input isin'}]}>
                <Input  onChange={(e) => setIsin(e.target.value)}/>
              </Form.Item>
               
              <Form.Item
              wrapperCol={{ sm: 24 }}
              style={{ width: "35%", marginRight: 10 }}
                label="Short Name"
                name="short_name"
                rules={[{required:true,message: 'Please input short name'}]}>
                <Input  onChange={(e) => setShortName(e.target.value)} />
              </Form.Item>

              <Form.Item
                wrapperCol={{offset: 8,span: 16}}>
                <button className='reportBtn' htmlType="submit">Submit</button>
              </Form.Item>
             
              </Row>
              </Col>
              
          </Form>
        </Panel>
      </Collapse>
        <Form form={form} component={false}>
        <Table
          components={{body: {cell: EditableCell}}}
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={true}
          size='small'
          />
      </Form>


      {isModalVisible? <>
          <Modal  footer={null} width={600} visible={true} onCancel={handleModal} >
            <div className=''>
            <Upload
            {...props}
            ><Button className='uploadField' icon={<UploadOutlined />}>Select File</Button></Upload>
            <Button
              type="primary"
              onClick={handleUploadHoldings}
              disabled={fileList.length === 0}
              loading={holdingUploading}
              className='startUploading'
              onRemove={true}
            >
              {holdingUploading ? 'Uploading' : 'Start Upload'}
            </Button>
          </div>
          </Modal>
      </>:null}
      
    </div>
  )
}
// 

export default Master_stock_list
import React, { Component } from 'react'
import {complaints} from '../../Api'
import {MDBIcon} from 'mdbreact'
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

export default class Complain extends Component {
    constructor(props){
        super(props);
        this.state={
            date:new Date(),
            beginning_of_the_month:0,
            Received_during_the_month:0,
            Resolved_during_the_month:0,
            end_of_the_month:0,
            Reasons_for_pendency:''
        }
    }

onChangedate=(date)=>{
      this.setState({
        date:date
      })
    }
onchangeval=(e)=>{
    this.setState({
        [e.target.name]:e.target.value
    })
}
submit_complaint=()=>{
    const input={
        access_token:localStorage.token,
        date                        :   Moment(this.state.date).format('YYYY-MM-DD'),
        beginning_of_the_month      :   this.state.beginning_of_the_month,
        Received_during_the_month   :   this.state.Received_during_the_month,
        Resolved_during_the_month   :   this.state.Resolved_during_the_month,
        end_of_the_month            :   this.state.end_of_the_month,
        Reasons_for_pendency        :   this.state.Reasons_for_pendency
    }
    console.log(input)
    complaints(input).then(res => {
        console.log(res)
    try{
    if (res.status==='successfull') {
        this.setState({
            success:true
        })
    }
    else{}
    }catch{}
    })
}
cancel=()=>{
    this.setState({
        success:false,
    })
    }
    render() {
        const ExampleCustomInput = ({ value, onClick }) => (
            <div className="datebox" onClick={onClick}>
             <span><MDBIcon icon="calendar-day" size='xs' className='cal-icon'/></span><span className='c-date'> {value}</span>
            </div>
          );
        return (
            <div>
                  {this.state.success?(
                    <SweetAlert
                        success
                        onConfirm={this.cancel}>
                    Data Submitted
                    </SweetAlert>):(<span></span>)}
               <div className='update-table-div'>
                    <table class='table table-condensed'>
                        <thead className='mf-p-table-tr'>
                            <th>Date</th>
                            <th>Beginning of Month</th>
                            <th>Received</th>
                            <th>Resolved</th>
                            <th>Pending</th>
                            <th>Reasons for Pendency</th>
                        </thead>
                        <tbody>
                            <tr className='mf-p-table-tr-bg'>
                                <td>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        closeOnScroll={true}
                                        selected={this.state.date}
                                        onChange={this.onChangedate}
                                        customInput={<ExampleCustomInput />}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </td>
                                <td>
                                    <input type="number" name="beginning_of_the_month" value={this.state.beginning_of_the_month} onChange={this.onchangeval}/>
                                </td>
                                <td>
                                    <input type="number" name="Received_during_the_month" value={this.state.Received_during_the_month} onChange={this.onchangeval}/>
                                </td>
                                <td>
                                    <input type="number" name="Resolved_during_the_month" value={this.state.Resolved_during_the_month} onChange={this.onchangeval}/>
                                </td>
                                <td>
                                    <input type="number" name="end_of_the_month" value={this.state.end_of_the_month} onChange={this.onchangeval}/>
                                </td>
                                <td>
                                    <input type="text" name="Reasons_for_pendency" value={this.state.Reasons_for_pendency} onChange={this.onchangeval}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='update-sub-btn'><button onClick={this.submit_complaint}><MDBIcon icon="save" className='btn-i'/>Save</button></div>
            </div>
        )
    }
}

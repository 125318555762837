import React, { useState,useEffect} from 'react'
import './Loans.scss'
import ColumnLine from './ColumnLine';
import {Table,Input,Space,Button,Modal,Row,Col} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { MDBIcon } from 'mdbreact';
import AddComment from '../comment/AddComment';

const Tables = (props) => {
    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState([]);
    const [ammortizationData,setAmmortizationData] = useState({})
     const [isModalVisibleComment, setIsModalVisibleComment] = useState(false);

     const numberFormat=(value)=>{
      var val = Math.abs(value)
      if (val >= 10000000) {
        val = (val / 10000000).toFixed(2) + ' Cr';
      } else if (val >= 100000) {
        val = (val / 100000).toFixed(2) + ' L';
      }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
      return val;
    }

    let searchInput = ''

    useEffect(()=>{
      let arr= []
        Object.entries(props.tableData).map((val)=>arr.push(val[1]))
        setData(arr)
    },[])

    const handleModal = (record) => {
      console.log('record',record);
      setAmmortizationData(record)
      setIsModalVisible(!isModalVisible);
    };

    let getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => {
                handleReset(clearFilters)
                confirm({ closeDropdown: false });
                // setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                }} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#e87c1b' : '#000' }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
      };
      const  handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };
      const handleModalComment = () => {setIsModalVisibleComment(!isModalVisibleComment);};

      const columns = [
        {title: 'SR. NO',dataIndex: 's_no',width: 60, ...getColumnSearchProps('s_no')},
        {title: 'TYPE',dataIndex: 'type',width: 150,...getColumnSearchProps('type')},
        // {title: 'LENDER',dataIndex: 'lender',width: 120,...getColumnSearchProps('lender')},
        {title: 'LOAN AMOUNTS',dataIndex: 'loan_amount',width: 120,...getColumnSearchProps('loan_amount')},
        {title: 'Interest Rate',dataIndex: 'interest_rate',width: 120,...getColumnSearchProps('interest_rate'),
        render: total => { return numberFormat(total)}},
        {title: 'PENDING AMOUNT',dataIndex: 'pending_amount',width: 120,...getColumnSearchProps('pending_amount'), render: total => { return numberFormat(total)}},
        {title: 'EXPIRY/MATURITY',dataIndex: 'expiry_maturity',width: 120,...getColumnSearchProps('expiry_maturity')},
        {title: 'Amortization',key: 'action',width: 80,
        render: (text, record) => (<MDBIcon icon="chart-area" onClick={()=>handleModal(record)} className='chartI' />),
      },
      ];
  return (
    <div className='tableContainer'>
        <div className='tableTabContainer'>
          <div className='PagesCommentIconDiv'>
            <MDBIcon className='PagesCommentIcon' icon="comment-dots" onClick={handleModalComment}/>
          </div>
          <div className='tableHead'>Loan Index</div>
        </div>
        <Table rowClassName="antTable" columns={columns} dataSource={data} size='small'
              // pagination={{pageSize: 6,position:['bottomCenter']}}
              scroll={{y: 220}}
              pagination={false} 
              sticky={true}/>

        <Modal  footer={null} visible={isModalVisible} onCancel={handleModal}>
           <div className='loanNameHead'>Loan Repayment Overview</div>
          <Row>
            {/* <Col span={6}>
              <div className='loanNameTitle'>Lender</div>
              <div className='loanName'>{ammortizationData.lender}</div>
            </Col> */}
            <Col span={6}>
              <div className='loanNameTitle'>Loan Amount</div>
              <div className='loanName'>{numberFormat(ammortizationData.pending_amount)}</div>
            </Col>
            <Col span={6}>
              <div className='loanNameTitle'>Loan Expiry/Maturity</div>
              <div className='loanName'>{ammortizationData.expiry_maturity}</div>
            </Col>
            <Col span={6}>
              <div className='loanNameTitle'>Type</div>
              <div className='loanName'>{ammortizationData.type}</div>
            </Col>
          </Row>
          <div className='ModalchartArea'>{isModalVisible?
            <ColumnLine ammortizationData={ammortizationData.ammortization_data}/>:null}
          </div>
        </Modal>

        {isModalVisibleComment? <AddComment handleModal={handleModalComment}/>:null}
    </div>
  )
}

export default React.memo(Tables)
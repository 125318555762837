import React,{useState,useEffect} from 'react'
import {MDBIcon} from 'mdbreact'
import {get_income_expense_details,save_income_expence_details,delete_income_details,income_scenario,income_scenario_get} from './Api'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../shared/Loader';
import CurrencyInput from 'react-currency-input-field';
import {get_spouse_details} from '../Compliance/Api'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Income = (props) => {
    const {id,income_expense,nomination,userInfo,step2,income_previous,update} = props

    const [dataDict,setDataDict] = useState({})
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [warning,setWarning] = useState(false)
    const [successmsg,setSuccessmsg] = useState('')
    const [errormsg,setErrormsg] = useState('')
    const [loading,setLoading] = useState(false)
    const [disabled,setDisabled] = useState(false)
    const [showSpouse,setShowSpouse] = useState(true)
    const [futureIncome,setFutureIncome] = useState('')
    const [retireYear, setRetireYear] = useState('')

    const toastMsg =(ids,msg,type)=>{
        toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
      } 


    const fetchIncome = async () =>{
        const input = {
            access_token:localStorage.token,
            id:id
        }
        const res = await get_income_expense_details(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log(res);
        try{
            if (res.status==='successfull') {
                setDataDict(res.result.table_data)
                setRetireYear(res.result.table_data.Salary[1].retirment_year)
            }else{}
        }catch{toast.error('Something went wrong')}
    }
    const fetchIncomeDB = async () =>{
        setDataDict({})
        const input = {
            access_token:localStorage.token,
            id:id,
            scenario_id:props.scenarioId
        }
        console.log(input);
        const res = await income_scenario_get(input)
        .catch((err) => {console.log("Err: ", err)});
        console.log(res);
        try{
            if (res.status==='successfull') {
                setDataDict(res.result.table_data)
            }else{}
        }catch{toast.error('Something went wrong')}
    }

    const spouseCheck=()=>{
        const input = {
            access_token:localStorage.token,
            id:id
        }
        get_spouse_details(input).then(res => {
            console.log('resspo',res)
            try{
                if (res.status==='successfull'){setShowSpouse(true) }
                else{setShowSpouse(false)}
            }catch{setShowSpouse(false)}
        })
    }

    useEffect(()=>{
        if(income_expense===1){setDisabled(true)}
        if(props.dashboard===1){
            fetchIncomeDB()
        }else{
            fetchIncome();
            spouseCheck()
        }
    },[props.scenarioId])

    const addFunc=(type)=>{
        let newRow = 1
        console.log(type);
        const newRowItem={amount: 0,annual_growth_rate:dataDict[type][1].annual_growth_rate, description:"",expected_year:"",frequency:"Annually",relation_id:"",remarks:"",start_date: dataDict[type][1].start_date,custom_retirement:"",retirment_year:"",hlv_calculation:"Y"}

        let aa = {...dataDict}
        Object.values(dataDict[type]).map(item => {
            console.log(dataDict[type]);
            newRow = newRow + 1
            if(!aa[type][newRow]){
                aa[type][newRow] = newRowItem
                setDataDict(aa);
            }
       });
    }
    
    const removeFunc=(type)=>{
        let aa = {...dataDict}
        let length = Object.keys(aa[type]).length
        if(length>1){
        delete aa[type][length];
        setDataDict(aa);
        }
    }

    const changeCustom=(e)=>{
        setFutureIncome(e.target.value);
    }

    const getDropList = () => {
        const year = 'YYYY';
      return (
          Array.from( new Array(75), (v,i) =>
            <option key={i} value={new Date().getFullYear()+i}>{new Date().getFullYear()+i}</option>
        )
      );
    };

    const handleChangeValue=(e,type,row)=>{
        const aa = {...dataDict};
        aa[type][row][e.target.name] = e.target.value;
        setDataDict(aa);
    }

    const handleChangeValueYear=(e,type,row)=>{
        const aa = {...dataDict};
        aa[type][row][e.target.name] = e.target.value;
        setDataDict(aa);
    }
    const handleChangeCheckbox=(e,type,row)=>{
        const aa = {...dataDict};
        if(aa[type][row].hlv_calculation === 'Y'){
            aa[type][row].hlv_calculation = 'N'
        }else{
            aa[type][row].hlv_calculation = 'Y'
        }
        setDataDict(aa);
    }
    const handleChangeAmount=(value,name,type,row)=>{
        const aa = {...dataDict};
        aa[type][row][name] = parseInt(value);
        setDataDict(aa);
    }
    const edit=()=>{setDisabled(false)}

    const cancel=()=>{
        setError(false)
        setSuccess(false)
        setDisabled(true)
        setWarning(false)
    }

    const pleaseSave=(e)=>{
        e.preventDefault()
        setWarning(true)
    }
    const onleave=()=>{
        setWarning(false)
      }
    const handlesave=()=>{
        step2();
    }
    const submitIncome=(e)=>{
        const ids = toast.loading("Please wait...",{toastId:'load'})
        e.preventDefault();
        const input = {
            access_token:localStorage.token,
            id:id,
            income_data:dataDict
        }
        console.log(input);
        save_income_expence_details(input).then(res => {
            console.log("update",res)
            try{
                if (res.status==='successfull') {
                    if(income_expense===0){
                        update();
                        step2();
                    }
                    else{
                    toastMsg(ids,res.message,'success')
                    setDisabled(true)}
                }
                else{toastMsg(ids,res.message,'error')}
            }catch{toastMsg(ids,'Something went wrong','error')}
        })
    }
    const submitIncomeDB=(e)=>{
        const ids = toast.loading("Please wait...",{toastId:'load'})
        e.preventDefault();
        const input = {
            access_token:localStorage.token,
            id:id,
            income_data:dataDict,
            scenario_id:props.scenarioId
        }
        console.log(input);
        income_scenario(input).then(res => {
            console.log("update",res)
            try{
                if (res.status==='successfull') {
                    toastMsg(ids,res.message,'success')
                }
                else{toastMsg(ids,res.message,'error')}
            }catch{toastMsg(ids,'Something went wrong','error')}
        })
    }

    const deleteSingle=(type,row)=>{
        const aa = {...dataDict};
        console.log(type,row);
        const input = {
            access_token:localStorage.token,
            delete_row:aa[type][row],
            row_index:row,
            type:type,
            id:id,
        }
        console.log(input);
        delete_income_details(input).then(res => {
            console.log("delete",res)
            try{
                if (res.status==='successfull') { 
                }
                else{toast.error(res.message)}
            }catch{
                toast.error("Something went wrong")
                setLoading(false)
            }
        })
    }

    const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits:0
    }).format(value);

    let self_total = 0
    if(dataDict){
        Object.keys(dataDict).map((type)=>{
            Object.keys(dataDict[type]).map((row)=>{
                self_total = self_total + parseInt(dataDict[type][row].amount)
            })
            return null
        })
    }
    if (loading){
        return(
          <div style={{textAlign:'center',marginTop:100}}>
          <Loader/>
          </div>
          );
      }
    //   console.log(retireYear);

    return (
        <div>
        <ToastContainer theme='colored' limit={1}/>
            {userInfo===1?(
            <>
                {error?
                    <SweetAlert
                        error
                        onConfirm={cancel}>
                    {errormsg}
                    </SweetAlert>:null}

                {success?
                    <SweetAlert
                        success
                        onConfirm={cancel}>
                    {successmsg}
                    </SweetAlert>:null}

                {warning?
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnText="No"
                        cancelBtnBsStyle="danger"
                        onConfirm={handlesave}
                        onCancel={onleave}
                        focusCancelBtn>Do you want to proceed without save your changes
                    </SweetAlert>:null}

                
                <div className='form-heading'>Income Details
                    {income_expense===1?(
                    <span className='formButton'>
                        {disabled?
                        <button type="button" onClick={edit}><MDBIcon far icon="edit" className='form-edt-icon'/>Edit</button>:
                        <>
                        <button onClick={submitIncome}><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>
                        </>}
                    </span>):null}
                </div>

                <div className='formHeight'>
                    <table class="table table-condensed table-sm" border="0">
                        <thead>
                            <tr className='tb_header'>
                                <td>Type</td>
                                <td className='lf-border'>HLV</td>
                                <td className='lf-border'>Description</td>
                                <td className='lf-border'>Owner*</td>
                                <td className='lf-border'>Amount</td>
                                <td className='lf-border' style={{width:'7%'}}>From Year</td>
                                <td className='lf-border' style={{width:'12%'}}>Till Year/Retirement</td>
                                <td className='lf-border'>Specific Year</td>
                                <td className='lf-border'>Annual Growth Rate*</td>
                                <td className='lf-border'>Remarks</td>
                                {/* <td className='lf-border'></td> */}
                                {/* <td className='lf-border'>Frequency</td> */}
                            </tr>
                        </thead>
                        {Object.keys(dataDict).map((type)=>{
                        return(
                        <tbody>
                            {Object.keys(dataDict[type]).map((row,index1)=>{
                                {/* console.log(dataDict[type][row].amount); */}
                            return(
                            <tr className='tb_body'>
                                {index1===0?(
                                <>
                                <td rowSpan={index1} style={{minWidth:'120px'}}>{type}
                                <MDBIcon far icon="plus-square" className="assetAddIcon" onClick={()=>addFunc(type)} />

                                <MDBIcon far icon="minus-square" className="assetAddIcon" onClick={()=>removeFunc(type)} />
                                </td>
                                </>):null}

                                <td className='l-r-br'>
                                    <input type='checkbox' onChange={(e)=>handleChangeCheckbox(e,type,row)} name="hlv_calculation" checked={dataDict[type][row].hlv_calculation==='Y'} disabled={disabled}/>
                                </td>

                                <td className='l-r-br'>
                                    <input type="text" placeholder={dataDict[type][row].description} 
                                    onChange={(e)=>handleChangeValue(e,type,row)} name="description" disabled={disabled}/>
                                </td>

                                <td  style={{minWidth:'80px'}}>
                                    <select value={dataDict[type][row].relation_id} onChange={(e)=>handleChangeValue(e,type,row)} name="relation_id" disabled={disabled}>
                                        <option value="">Select</option>
                                        <option value={1}>Self</option>
                                        {showSpouse?
                                        <option value={2}>Spouse</option>
                                        :null}
                                    </select>
                                </td>
                                <td className='l-r-br'> 
                                    <CurrencyInput
                                        name="amount"
                                        placeholder={numberFormat(dataDict[type][row].amount)}
                                        disabled={disabled}
                                        onValueChange={(value,name)=>handleChangeAmount(value,name,type,row)}
                                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                        prefix="₹"
                                    />
                                </td>
                               
                                <td>
                                    {/* <input type="text" value={dataDict[type][row].start_date} 
                                    onChange={(e)=>handleChangeValue(e,type,row)} name="start_date" disabled={disabled}/> */}

                                    <select onChange={(e)=>handleChangeValue(e,type,row)} value={dataDict[type][row].start_date} name="start_date" disabled={disabled}>
                                        <option value=''>YYYY</option>
                                        {getDropList()}
                                    </select>
                                </td>
                                <td className='l-r-br'>
                                    {/* <input type="date" value={dataDict[type][row].expected_year} 
                                    onChange={(e)=>handleChangeValue(e,type,row)} name="expected_year" disabled={disabled}/> */}
                                    <select value={dataDict[type][row].custom_retirement} onChange= {(e)=>handleChangeValueYear(e,type,row)} disabled={disabled} name="custom_retirement" >
                                        <option value=''>select</option>
                                        <option value='custom'>Custom</option>
                                        <option value='retirement'>Retirement</option>
                                    </select>
                                </td>
                               
                                <td className='l-r-br' style={{width:'8%'}}>
                                 {dataDict[type][row].custom_retirement==='custom'?(
                                    <select value={dataDict[type][row].expected_year} 
                                    onChange={(e)=>handleChangeValue(e,type,row)} name="expected_year" disabled={disabled}>
                                        <option value=''>YYYY</option>
                                        {getDropList()}
                                    </select>
                                 ):(<>{dataDict[type][row].custom_retirement==='retirement'?(
                                    <input value={retireYear} disabled={disabled}/>
                                    ):<span></span>}
                                    </>)}
                                 {/* {dataDict[type][row].custom_retirement==='retirement'&& dataDict[type][row].relation_id==="1"?
                                     (<>{dataDict.expected_year}</>):null} */}
                                </td>
                               
                        
                                <td>
                                    <input type="text" placeholder={dataDict[type][row].annual_growth_rate} onChange={(e)=>handleChangeValue(e,type,row)} name="annual_growth_rate" disabled={disabled}/>
                                </td>
                                <td className='l-r-br'>
                                    <input type="text" placeholder={dataDict[type][row].remarks} 
                                    onChange={(e)=>handleChangeValue(e,type,row)} name="remarks" disabled={disabled}/>
                                </td>
                                {/* <td className='lf-border'>
                                    <MDBIcon far icon="trash-alt" className="assetDelIcon" onClick={()=>deleteSingle(type,row)} />
                                </td> */}
                                {/* <td className='lf-border'>
                                    <input type='text' value="Annually" disabled/>
                                </td> */}
                            </tr>
                            )
                            })}      
                        </tbody>
                      
                        )
                        })}
                            <tr className='tb_header'>
                                <td>Total</td>
                                <td className='lf-border'></td>
                                <td className='lf-border'></td>
                                <td className='lf-border'></td>
                                {self_total?(
                                <td className='l-r-br'>{numberFormat(self_total)}</td>
                                ):<td className='l-r-br'>0</td>}
                                <td className='l-r-br'></td>
                                <td className='l-r-br'></td>
                                <td></td>
                                <td className='lf-border'></td>
                                <td className='lf-border'></td>
                                {/* <td className='lf-border'></td> */}
                                {/* <td className='lf-border'></td> */}
                            </tr>
                    </table>
                </div>
                {props.dashboard===1?<>
                    <button  className="scenarioIncomeFormButton" onClick={submitIncomeDB}>Submit</button>
                </>:<>
                {income_expense===0?
                    <div className='agree-checkbox'><input type='checkbox'/> Skip this section</div>:null}
    
                {nomination===1?(<div></div>):(<div className='previous-msg'>Please fill the previous form first.</div>)}

                {nomination===1 && income_expense===1?(
                <>
                {disabled?(
                    <div className='submit-Btn'><button onClick={income_previous}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button onClick={step2}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>
                ):(
                    <div className='submit-Btn'><button onClick={income_previous}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button type='button' onClick={pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>
                )}
                </>):null}
                    
                {nomination===1 && income_expense===0?
                <div className='submit-Btn'>
                    <button onClick={income_previous}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
                    <button  onClick={submitIncome}>Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
                </div>:null}
                </>}
                
            </>):<p style={{textAlign:'center',fontWeight:600,marginTop:'10px'}}>Please fill user details first</p>}
            
        </div>
    )
}

export default Income

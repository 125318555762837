import React, { PureComponent } from 'react'
import {save_family_details,get_family_details,delete_family_details,relation} from './Api'
import {MDBIcon} from 'mdbreact'
import Loader from '../../shared/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import Moment from 'moment';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';



export default class FamilyExel extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        resData:{},
        order : [],
        del_product:{},
        confirm:false,
        success:false,
        error:false,
        successmsg:'',
        errormsg:''
      };
    }
    componentDidMount(){
        let arr = []
        const input = {
            access_token:localStorage.token,
            id:this.props.id
        }
        get_family_details(input).then(res => {
        console.log("resfamily",res)
        try{
        if (res.status==='successfull') {
        Object.keys(res.result).map((val)=>{
            arr.push(res.result[val])
        })
        this.setState(() => ({
            resData:res.result,
            order:arr
        }))
    }
    else{console.log(res)}
    }catch{
      this.setState({loading:false})
      toast.error('Something went wrong')
    }
    })
    }
    handleRowDel(product) {
        if(product.relation_id && product.name && product.date_of_birth && product.mobile_number){
            this.setState({del_product:product,confirm:true})
        }
        else{
            var index = this.state.order.indexOf(product);
            this.state.order.splice(index, 1);
            this.setState(this.state.order);
        }
    };
    handledelete=()=>{
        const input = {
            access_token:localStorage.token,
            id:this.props.id,
            delete_row:this.state.del_product
        }
        console.log("input",input)
        delete_family_details(input).then(res => {
            console.log("res",res)
            try{
            if (res.status==='successfull') {
                this.setState({confirm:false})
                toast.success(res.message)
                var index = this.state.order.indexOf(this.state.del_product);
                this.state.order.splice(index, 1);
                this.setState(this.state.order);
        }
        else{
          this.setState({confirm:false})
          toast.error(res.message)
        }
        }catch{
          this.setState({confirm:false})
          toast.error('Something went wrong')
      }
        }) 
      }
      
    handleAddEvent(evt) {
      var id = Math.floor(1000 + Math.random() * 9000);
      var product = {
        id: id,
        relation_id: '',
        name: '',
        date_of_birth:'',
        mobile_number:0
      }
      this.state.order.push(product);
      this.setState(this.state.order);
    }
  
    handleProductTable(evt) {
      var item = {
        id: evt.target.id,
        name: evt.target.name,
        value: evt.target.value
      };
      console.log(item);
  var order = this.state.order.slice();
    var neworder = order.map(function(product) {
      for (var key in product) {
        console.log(key)
        if (key === item.name && product.id === parseInt(item.id)) {
          product[key] = item.value;
        }
      }
      return product;
    });
      this.setState({order:neworder});
    };

    handleProductTablePhone = (value,name,id)=>{
      var item = {
        id: id,
        name: name,
        value: value
      };
      console.log(item);
  var order = this.state.order.slice();
    var neworder = order.map(function(product) {
  
      for (var key in product) {
        if (key === item.name && product.id === parseInt(item.id)) {
          product[key] = item.value;
        }
      }
      return product;
    });
    console.log('newOrder',neworder)
      this.setState({order:neworder});
    };
   
    
    onCancel=()=>{
        this.setState({
          confirm:false,
          success:false,
          error:false,
        })
      }
    render() {
      return (
        <div>
        <ToastContainer theme='colored' limit={1}/>
        {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.onCancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

        {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.onCancel}>
              {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

        {this.state.confirm?(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={this.handledelete}
                onCancel={this.onCancel}
                focusCancelBtn></SweetAlert>
            ):(<span></span>)}

          <ProductTable handleProductTablePhone={(value,name,id)=>this.handleProductTablePhone(value,name,id)} onProductTableUpdate={this.handleProductTable.bind(this)} onRowAdd={this.handleAddEvent.bind(this)} onRowDel={this.handleRowDel.bind(this)} order={this.state.order}
          family_details={this.props.family_details} step1={this.props.step1} kyc={this.props.kyc} step3={this.props.step3} update={this.props.update} id={this.props.id}/>
        </div>
      );
  
    }
  }

  const toastMsg =(ids,msg,type)=>{
    toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
  }
  class ProductTable extends React.Component {
    
   
    constructor(props) {
      super(props);
      this.state = {
        success:false,
        error:false,
        successmsg:'',
        errormsg:'',
        disabled:false,
        skip:false,
        warning:false,
        loading:false,
      };
    }
    
    componentDidMount(){
     if(this.props.family_details===1){
       this.setState({disabled:true})
     }
    }
    enableNext=()=>{
      this.setState({skip:!this.state.skip})
     }
    onSubmitRecord=(e) =>{
      e.preventDefault();
        const ids = toast.loading("Please wait...",{toastId:'load'})
        const input = {
          access_token:localStorage.token,
          family_data:this.props.order,
          id:this.props.id,
          skip:this.state.skip
        }
        console.log("input",input)
        save_family_details(input).then(res => {
            console.log("resgoa",res)
        try{
        if (res.status==='successfull') {
          if(this.props.family_details===1){
            toastMsg(ids,res.message,'success')
            this.setState(() => ({disabled:true})) 
          }
          else{
            this.props.update();
            this.props.step3();
            // toastMsg(ids,res.message,'success')
        }
      }
      else{toastMsg(ids,res.message,'error')}
      }catch{toastMsg(ids,'Something went wrong','error')}
      })
      }
      onCancel=()=>{
        this.setState({
          success:false,
          error:false,
          warning:false,
          disabled:true
        })
      }
      edit=(e)=>{
        e.preventDefault();
        this.setState({
            disabled:false
        })
    }
    pleaseSave=(e)=>{
      e.preventDefault()
      this.setState({
          warning:true,
      })
  }
  onleave=()=>{
    this.setState({
        warning:false,
        
    })
  }
  handlesave=()=>{
    this.props.step3();
}
    render() {
   
      var onProductTableUpdate = this.props.onProductTableUpdate;
      var handleProductTablePhone = this.props.handleProductTablePhone
      var rowDel = this.props.onRowDel;
      let disabled = this.state.disabled
      let perm_disable = true
      let family = this.props.family_details
      var product = this.props.order.map(function(product) {
        if(product.id>1000 || family===0 ||(product.id===3 && product.date_of_birth==='' || product.name==='' || product.relation_id==='' || product.mobile_number==='')){
          perm_disable = disabled          
        }
        else{
          perm_disable=true
        }
        
        return (<ProductRow onProductTableUpdate={onProductTableUpdate} handleProductTablePhone={handleProductTablePhone} product={product} onDelEvent={rowDel.bind(this)} key={product.id} disabled={disabled} perm_disable={perm_disable}/>)
      }); 
      
      if (this.state.loading){
        return(
          <div style={{textAlign:'center',marginTop:100}}>
         <Loader/>
          </div>
          );
      }
      return (
        <div> 
        <ToastContainer theme='colored' limit={1}/>
             {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.onCancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

        {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.onCancel}>
              {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

            {this.state.warning?(
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="success"
                    cancelBtnText="No"
                    cancelBtnBsStyle="danger"
                    onConfirm={this.handlesave}
                  onCancel={this.onleave}
                  focusCancelBtn>Do you want to proceed without save your changes</SweetAlert>):(<span></span>)}
        
        <form onSubmit={this.onSubmitRecord}>

          <div className='form-heading'>Family Details
            {this.props.family_details===1?(
            <span className='formButton'>
            {disabled?
            <button type="button" onClick={this.edit}><MDBIcon far icon="edit" className='form-edt-icon' />Edit</button>:
            <button type='submit'><MDBIcon far icon="save"className='form-edt-icon'/>Save</button>}
            </span>):null}
            <span className={disabled?"formButtonDisable":"formButton"}>
              <button disabled={disabled} type="button" onClick={this.props.onRowAdd}>
              <MDBIcon icon="plus" className='form-edt-icon' />Add Row </button>
            </span>
          </div>

        <div className='formHeight'>
        <table class="table table-condensed table-sm" cellspacing="0" cellpadding="0" border="0">
            <thead>
            <tr className='tb_header' >
                <td>Relation</td>
                <td className='lf-border'>Name</td>
                <td className='lf-border'>Date of Birth</td>
                <td className='lf-border rh-border'>Contact Number</td>
                <td></td>
            </tr>
            </thead>
            {product}
            </table>
        </div>

        {this.props.family_details===0?(
          <div className='agree-checkbox'><input type='checkbox' onChange={this.enableNext}/>  Skip this section</div>):(<span></span>)}

        {this.props.kyc===1?(<div></div>):(<div className='previous-msg'>Please fill the previous form first.</div>)}  

        {this.props.kyc===1 && this.props.family_details===0?(
        <div className='submit-Btn'>
          <button onClick={this.props.step1}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
          {this.state.skip?(
            <button type="button" onClick={this.onSubmitRecord}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
          ):(
            <button type="submit">Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
          )}
          
        </div>
        ):(<div></div>)} 

      {this.props.kyc===1 && this.props.family_details===1?(
        <>
        {this.state.disabled?(
          <div className='submit-Btn'><button onClick={this.props.step1}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button onClick={this.props.step3}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>
        ):(
          <div className='submit-Btn'><button type="button"><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button><button type="button" onClick={this.pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button></div>)}
        
        </>):(<div></div>)} 
      </form>
      </div>
      );
    }
  }
  
  class ProductRow extends React.Component {
    onDelEvent() {
      this.props.onDelEvent(this.props.product);
    }
    render() {
      // console.log(this.props.product.id);
      return (
        <tr className='tb_body'>
          <EditableCellSelect onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "relation_id",
            value: this.props.product.relation_id,
            id: this.props.product.id,
            perm_disable:this.props.perm_disable
          }}/>
          <EditableCellText onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            "type": "name",
            value: this.props.product.name,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
          <EditableCellDate onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
            type: "date_of_birth",
            value: this.props.product.date_of_birth,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
          <EditableCellNumber handleProductTablePhone={this.props.handleProductTablePhone} cellData={{
            type: "mobile_number",
            value: this.props.product.mobile_number,
            id: this.props.product.id,
            disabled:this.props.disabled
          }}/>
          <td>
            <div className='delBtn' onClick={this.onDelEvent.bind(this)}>X</div>
          </td>
        </tr>
      );
  
    }
  }

  class EditableCellSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown_value:{} 
        }
    }
      componentDidMount(){
        const input = {
            access_token:localStorage.token,
            id:this.props.id,
        }
        relation(input).then(res => {
            try{
            if (res.status==='successfull') {
                this.setState({
                    dropdown_value:res.result
                })
            }
            else{console.log(res)}
            }catch{}
            })
      }
    render() {
      return (
        <td>
         <select type='select' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} required disabled={this.props.cellData.perm_disable}>
                <option value="">Select</option>
                {this.state.dropdown_value?(<>
                {Object.keys(this.state.dropdown_value).map((val)=>{
                    return(
                        <option value={this.state.dropdown_value[val].relation_id}>{this.state.dropdown_value[val].relation}</option>
                    )
                })}
                </>):null}
            </select>
        </td>
      );
    }
  }
  class EditableCellDate extends React.Component {
    render() {
      let today=Moment(new Date()).format('YYYY-MM-DD')
      return (
        <td className='lf-border '>
          <input type='date' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} required disabled={this.props.cellData.disabled} min="1900-01-01" max={today}/>
        </td>
      );
    }
  }
  class EditableCellText extends React.Component {
    render() {
      return (
        <td className='lf-border'>
            <input type='text' maxLength={80} name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} required disabled={this.props.cellData.disabled}/>
        </td>
      );
    }
  }
  class EditableCellNumber extends React.Component {
    render() {   
      const { cellData } = this.props;

      console.log('cellData',cellData)
    
      return (
        <td className='lf-border rh-border'>
        <PhoneInput
        country={'in'}
        name={cellData?.type || ''}
        id={cellData?.id || ''}
        value={(this.props.cellData.value ? this.props.cellData.value.toString() : '')} 
        onChange={(value)=> this.props.handleProductTablePhone(value,cellData.type,cellData.id)}
        >
        </PhoneInput>
        </td>
      );
    }
  }
  
import React,{useState,useEffect} from 'react'
import { Radar } from '@ant-design/plots';
import './Wealth360.scss'
import {wheel_Of_wealth} from './Api'
import Loader from '../../../shared/Loader'

const Wealth360 = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);

  const fetchWealthData = async () =>{
    setLoading(true)
    const arr = []
    const input = {
      access_token:localStorage.token,
      id:props.id
    }
    console.log(input);
    const res = await wheel_Of_wealth(input)
    .catch((err) => {console.log("Err: ", err)});
    try{
      if (res.status==='successfull'){
        console.log(res);
        Object.keys(res.result).map((val)=>{
          arr.push({item: val, user: '_', score:res.result[val] })
        })
        setLoading(false)
      }else{}
    }catch{}
    setData(arr)
  }
  useEffect(()=>{
    fetchWealthData()
  },[])
  const config = {
    data,
    xField: 'item',
    yField: 'score',
    seriesField: 'user',
    color:['#e87c1b'], 
    legend: false,
  
    label: null,
    tooltip: {
      showTitle:false,
      formatter: (datum) => {
        return { name: datum.item, value: datum.score };
      },
    },
    
    meta: {
      score: {
        min: 1,
        max: 10,
      },
    },
    xAxis: {
      line: null,
      tickLine: null,
      label:{
        style:{ fill:'#292929',fontSize:11,fontWeight:600,fontFamily:'Montserrat'}
      },
      grid: {
        line: {
          style: {
            stroke:'#BDC3C7',
            lineDash: null,
          },
        },
      },
    },
    yAxis: {
      line: null,
      tickLine: null,
      tickCount:7,
      // verticalFactor:0,
      label:{
        style: {
          fill: '#797D7F',
        },
     },
      grid: {
        line: {
          type: 'line',
          style: {
            stroke:'#BDC3C7'
          },
        },
      },
    },
    area: {},
    point: {
      size: 2,
    },
  };
  return (
    <div className='wealthBg animate'>
      {/* {loading?<Loader/>:null} */}
        <div className='PageComponentHead'>WHEEL OF WEALTH</div>
      <div><Radar {...config}/></div>
    </div>
  )
}

export default React.memo(Wealth360)

import React, { useEffect,useState } from 'react'
import {MDBIcon} from 'mdbreact'
import '../UserManagement.scss'
import {Row,Col,Tabs} from 'antd';
import {ria_status,ria_status_put} from '../../Api'

const RiaStatus = (props)=>{
    const [resData, setResData] = useState('')
    const [error,setError] = useState(false)
    const [editRia,setEditRia] = useState(false)
    const{cusName,customer_id,cusEmail, close, tabName} = props
    


    useEffect(() => {
          const input = {
            access_token:localStorage.token,
            id:props.customer_id
          }
          console.log(input)
          ria_status(input).then(res => {
            console.log("res",res)
            try{
            if (res.status==='successfull') {
                setResData(res.result.flag)
            }
            else{
                setError(true)
            }
            }catch{}
          })
        },[])

    const onClickEdit=()=>{
        setEditRia(true)
    }
    const onChangeRia=(e)=>{
        setResData(e.target.value)
    }
    const submitRia=()=>{
        const input = {
            access_token:localStorage.token,
            id:props.customer_id,
            update_flag:resData
        }
        console.log(input)
        ria_status_put(input).then(res => {
            console.log('resInfo',res)
            try{
            if (res.status==='successfull') {
                alert('Data updated')}
            else{
                alert('Data not updated')
            }
            }catch{
           
          }
        })
          
}
console.log(cusEmail);
    return( 
    <div>
    <div className='modalHeadBar'>
        <Row align='middle'>
          <Col span={6}>
            <div className='modalHeadBarTitle'>{tabName}</div>
          </Col>
          <Col span={12}>
            <div className='modalHeadBarTab'>
            </div>
          </Col>
          <Col span={5}>
            <div className='modalHeadBarTitle'>{cusName}</div>
            <p className='modalHeadBarP'>{cusEmail}</p>
          </Col>
          <Col span={1}>
            <div className='TableActionButtonClose' onClick={close}>Close</div>
          </Col>
        </Row>
      </div>

      
      <div className='modalBodyBar'>
      <Row>
        <Col span={10}>
          <div className='riamodal-head'>Email</div>
        </Col>
        <Col span={4}>
          <div  className='riamodal-head'>RIA Status</div>
        </Col>
        <Col span={6}>
          <div  className='riamodal-head'>Edit</div>
        </Col>
        <Col span={3}></Col>
      </Row>
      <Row>
        <Col span={10}>
          <div>{cusEmail}</div>
        </Col>
        <Col span={4}>
          {editRia?(
            <select onChange={onChangeRia} >
              <option>Select</option>
              <option>Y</option>
              <option>N</option>
            </select>
          ):(
        <div>{resData}</div>)}
        </Col>

        <Col span={6}>
          <MDBIcon far icon="edit" className='mdbicon' onClick={onClickEdit}/>
        </Col>
        <Col span={3}>
          <div >
          {editRia?<button className='TableActionButtonClose' onClick={submitRia}>Submit</button>:null}
          </div>
        </Col>
      </Row>
        </div> 

    </div>
    )
}
export default RiaStatus



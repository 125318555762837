import React, { useState,useEffect,useRef } from 'react'
import './Webinar.scss'
import Logo from '../../../Images/logoBlack.png'
import BhuvnaWebWh from '../../../Images/bhuvanaaWebWh.png'
import BhuvnaWeb from '../../../Images/bhuvanaaWeb.png'
import HarishWeb from '../../../Images/harishWeb.png'
import PhoneInput from 'react-phone-input-2'

import tick from '../../../Images/webtick.png'
import cross from '../../../Images/webtickred.png'
import {MDBIcon} from 'mdbreact'
import Countdown from 'react-countdown';
import { Row, Col,Modal} from 'antd';
import {hoa_webinar_details} from '../Api'
import Loader from '../../../shared/LoaderTransparent'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {useNavigate} from 'react-router-dom'

const Webinar = () => {
  const myRef = useRef(null)
  const[loading,setLoading] = useState(false)
  const[isModalVisible,setIsModalVisible] = useState(false)
  const[resp,setResp] = useState(false)
  const[succ,setSucc] = useState(false)
  const[mobileNumber,setMobileNumber] = useState('')
  
  const[field,setField] = useState({name:'',email:'',contact_no:'',city:'',monthly_income:''})

  const navigate = useNavigate();


  const renderer = ({ days,hours, minutes, seconds, completed }) => {
    if (completed) {
        return (<span className='resend-enable'> Resend OTP</span>);
    } else {
    return (
      <Row gutter={[40,20]} className='banTimerRow'>
        <Col span={7}>
          <div className='banTimer'><b>{days}</b><br/>Days</div>
        </Col>
        <Col span={7}>
          <div className='banTimer'><b>{hours}</b><br/>Hours</div>
        </Col>
        <Col span={7}>
          <div className='banTimer'><b>{minutes}</b><br/>Minutes</div>
        </Col>
      </Row>
    );
    }
};

const renderer2 = ({ days,hours, minutes, seconds, completed }) => {
  if (completed) {
      return (<span className='resend-enable'> Resend OTP</span>);
  } else {
  return (
    <Row gutter={[40,20]} className='banTimerRow'>
      <Col span={4}></Col>
      <Col span={5} className='lrftMr'>
        <div className='banTimerf'><b>{days}</b><br/>Days</div>
      </Col>
      <Col span={5}>
        <div className='banTimerfB'><b>{hours}</b><br/>Hours</div>
      </Col>
      <Col span={5}>
        <div className='banTimerfB'><b>{minutes}</b><br/>Minutes</div>
      </Col>
    </Row>
  );
  }
};

const handleModal=()=>{
  setIsModalVisible(!isModalVisible)
}
const onChangeVal=(e)=>{
  let dict = {...field}
  dict[e.target.name] = e.target.value
  setField(dict)
}
// const onChangeNumber=(value)=>{
//   // const validNumber=RegExp(/^[1-9]\d{11}$/g);
//   // // let validate= validNumber.test(value)
//   // if(validate===true){
//     setField(value)
//     // dict[e.target.name] = e.target.validate
//     // setField(dict)
// //   }
// // else{
// // }
// }
const onSubmitaddress = async (e)=>{
  e.preventDefault()
  setIsModalVisible(true)
  setLoading(true)
  const input = {
    field:field,
}
  const res = await hoa_webinar_details(input)
  console.log('res',res);
  try{
    if (res && res.status==='successfull'){ 
      // setLoading(false)
      console.log('hh',res);
      window.location.href='https://house-of-alpha.stores.instamojo.com/product/3548426/3rd-dec-2022-webinar-do-you-have-enough-mone-70bb8/'
      // navigate('/thank_you')
     
    }else{
      setLoading(false)
      setResp(res.message)
      setSucc(false)
    }
  }catch{
    setLoading(false)
    setResp('Something Went Wrong')
    setSucc(false)
  }
}
console.log(field);
const ComplianceCards=[
  <div>
    <Row>
      <Col lg={12} md={12} xs={24}>
        <div className='bannerImg1'><img src={HarishWeb} alt=''/></div>
      </Col>
      <Col lg={12} md={12} xs={24}>
      <div className='sec4head'>Meet the Host</div>
      <div className='sec42text' >Harish Menon is a qualified Chartered Account and Chartered Financial Analyst. He has about 18 years experience in asset management, equity and fixed income research and derivative trading. He is a master trainer having trained the customer relationship and research teams of almost all leading wealth management firms in the country. His ability to simplify complex concepts and apply them in practice has resulted in great Alpha generation in his personal portfolio, proprietary investments and client assets.  He lives in Mulund and runs a not for profit animal shelter in Thane.
      </div>
      </Col>
    </Row>
  </div> ,

  <div>
    <Row>
      <Col lg={12} md={12} xs={24}>
        <div className='bannerImg1'><img src={BhuvnaWeb} alt=''/></div>
      </Col>
      <Col lg={12} md={12} xs={24}>
      <div className='sec4head'>Meet the Host</div>
      <div className='sec42text'>Bhuvanaa Shreeram is a Certified Financial Planner with over 18 years of experience in wealth management and financial services. She has worked with over 500 client families and has been privy to very interesting client journeys including major life transitions, inheritances, wealth building for early retirement and more. She has the knack of putting things in perspective with simple questions and tools. The proprietary financial planning tool built by her team, provides life altering insights for her clients. She lives in Thane with her husband, teenage son and parents in law.
      </div>
      </Col>
    </Row>
  </div>       
]
const responsive = {
  0: { items: 1 },
  601: { items: 1 },
  1023: { items:1 },
};


  return (
    <>
    <div className='webBannerImage'>
      <div className='webBody'>
        <div className='logoWebnr'>
          <img src={Logo} alt=''/>
        </div>
        <Row>
          <Col lg={12} md={12} xs={24}>
          <div className='freehead'></div>
            {/* <div className='freehead'>FREE WEBINAR</div> */}
            <div className='banrhd2'>Do you have Enough money?...</div>
            <div className='banrhd3'>....to live the best lifestyle and not worry about running out of money ever</div> 
            <div className='banrhd4'>
              <MDBIcon far icon="calendar-alt" className='banrI'/>Saturday , 3rd December, 2022
              &nbsp;&nbsp;&nbsp;&nbsp;<span><MDBIcon far icon="clock" className='banrI'/>11:00 am</span>
            </div>
            <Countdown date='2022-12-03T11:00:00' renderer={renderer}/>
          </Col> 
          <Col  lg={12} md={12} xs={24} align='center'>
            <div className='bannerImg12'><img src={BhuvnaWebWh} alt=''/></div>
            <div className='banrhd5'>BHUVANAA SHREERAM</div>
            <div className='banrhd6'>Co Founder - Head of Financial Planning</div>
            <div className='bnrBtn'><button><a href='#book_seat_now'>Register for just Rs.199/-</a></button></div>
          </Col>
        </Row>
      </div>
    </div>

    <div className='webBannerImage2'>
        <div className='webBody2'>
          <Row>
            <Col lg={12} md={12} xs={24}>
              <div className='websecHead'>What will you learn in this webinar?</div><br/>
              <p className='websecP'>
                • Beyond Goal Based Financial Planning - What is the one thing that guides every money decision?
              </p>

              <p className='websecP'>• Get a peek preview of the empowering two step framework to make investment decisions that you will never regret</p>

              <p className='websecP'>• Discover the  3 step process that puts your financial life on auto mode so that you can focus on work and family without any money worries</p>
            </Col>
            <Col  lg={12} md={12} xs={24} align='center'>
              <div className='websecSq'>
                <div className='bTime'>1 Hour and 30mins.</div>
                <div className='bTimeHead'>Live Webinar</div>
              </div>
              <div className='websecSq' style={{marginTop:'10px'}}>
                <div className='bTime'>30mins.</div>
                <div className='bTimeHead'>Live Q & A</div>
              </div>
              <div className='bnrBtn2'><button><a href='#book_seat_now'>Register for just Rs.199/-</a></button></div>
            </Col>
          </Row>
        </div>
    </div>
    <div className='section3'>
      <div className='webBody2'>
        <div className='sec3head'>Should You Attend this Webinar?</div><br/>
        <Row gutter={[20,15]}>
          <Col lg={11} md={12} xs={24}>
          <div className='sec3point'><img src={tick} alt=''/>If you have recently received a windfall</div>
          </Col>
          <Col lg={11} md={12} xs={24}>
          <div className='sec3point'><img src={tick} alt=''/>If bulk of your wealth is in ESOPs</div>
          </Col>

          <Col lg={11} md={12} xs={24} className='lrftMr'>
          <div className='sec3point'><img src={tick} alt=''/>If you are busy and do not have time to manage investments yourself</div>
          </Col>
          <Col lg={11} md={12} xs={24}>
          <div className='sec3point'><img src={tick} alt=''/>If you are not sure whether you are getting the right investment advice</div>
          </Col>

          <Col lg={11} md={12} xs={24}>
          <div className='sec3point'><img src={tick} alt=''/>If you would like to retire rich early in life</div>
          </Col>
          <Col lg={11} md={12} xs={24}>
          <div className='sec3point'><img src={tick} alt=''/>If you want to live and give generously without worrying</div>
          </Col>
          {/* <Col lg={6} md={6} xs={0}></Col>
          <Col lg={12} md={12} xs={24}>
          <div className='sec3point'><img src={cross} alt=''/>People who have abundant money at their disposal</div>
          </Col>
          <Col lg={6}></Col> */}
        </Row>
      </div>
    </div>

    <div className='webBannerImage2'>
        <div className='webBody2'>
          <AliceCarousel
              mouseTracking
              items={ComplianceCards}
              responsive={responsive}
              // disableDotsControls={true}
              disableButtonsControls={true}
              // autoHeight={true}
              autoPlay={true}
              infinite={true}
              autoPlayInterval={5000}
            />
        </div>
    </div>

    <div className='webBannerImageF' ref={myRef}>
      <div className='webBody2' align='center'>
        <div className='webfooterHead'>Limited Seats Left!</div>
        <Countdown date='2022-12-03T11:00:00' renderer={renderer2}/><br/><br/>

        <div className='webfooterHead'>Choose the Right Future for You</div>

        <form onSubmit={onSubmitaddress} id='book_seat_now'>
          <div className='webform'>
            <input type="text" name="name" placeholder='Name' onChange={onChangeVal} maxLength={40} required/>
          </div>
          <div className='webform'>
            <input type="text" name="email" placeholder='Email' onChange={onChangeVal} maxLength={40} required/>
          </div>
          <div className='webform'>
            <input type="text" name="contact_no" placeholder='Contact No.' onChange={onChangeVal} maxLength={12} minLength={10} required/>
            {/* <PhoneInput
              inputProps={{name:'contact_no',required: true}}
              className='webforj'
              country={'in'}
              countryCodeEditable={false}
              inputStyle={{background:'transparent',color:'#000',border:'none',outline:'none'}}
              buttonStyle={{background:'transparent',border: 'none',height:'35px',margin:'5px 0px 0px 0px'}}
              dropdownStyle={{background:'#D7DBDD',width:'250px'}}
              value={mobileNumber}
              onChange={onChangeNumber}
              required
            /> */}
          </div>
          <div className='webform'>
            <input type="text" name="city" placeholder='City' onChange={onChangeVal} maxLength={40} required/>
          </div>
          <div className='webform'>
            <select name="monthly_income" onChange={onChangeVal} required>
              <option value=''>Your Annual Income Range</option>
              <option value='Less than 25 lacs'>Less than 25 lacs</option>
              
              <option value='25-50 lacs'>25-50 lacs</option>
              <option value='50 lacs - 1 crore'>50 lacs - 1 crore</option>
              <option value='1 crore - 2 crore'>1 crore - 2 crore</option>
              <option value='Greater than 2 crore'>Greater than 2 crore</option>
             




            </select>
          </div>
          <div className='bnrBtn1'>
          {/* <a href='https://bit.ly/free-webinar-financial-management-12Nov' target='blank'>
          <button type='button'>
            Register for just Rs.199/-
          </button></a> */}
          <button type='submit'>Register for just Rs.199/-</button>
          </div>
        </form>
      </div>
    </div>
    <footer className='webfooter'>
    <MDBIcon far icon="copyright" /> 2022 Houseofalpha.in All rights reserved
    </footer>
    <Modal  footer={null} visible={isModalVisible} onCancel={handleModal}>
      {loading?
      <div><Loader/></div>
      :<div align='center' style={{padding:'30px',fontSize:'22px'}}>
        {succ?<div className='mdIcon'><img src={tick} alt=''/></div>
        :<div className='mdIcon'><img src={cross} alt=''/></div>}
        {resp}
        <div className='bnrBtn' style={{marginTop:'20px'}}><button onClick={handleModal}>Ok</button></div>
      </div>}
    </Modal>
    </>
  )
}

export default Webinar
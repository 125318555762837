import React,{useState,useEffect} from 'react'
import {Form,Input,Table,message,Typography,InputNumber,Popconfirm} from 'antd';
import { EditFilled,CloseCircleFilled,SaveOutlined,DeleteFilled} from '@ant-design/icons';
import {billing_get,billing_put} from '../Api'
import Loader from '../../../shared/Loader';


const BillingTable = (props) => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading,setLoading] = useState(false)
    const [editingKey, setEditingKey] = useState('');

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
      }) => {
        const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
        return (
          <td {...restProps}>
            {editing ? (
              <Form.Item
                name={dataIndex}
                style={{
                  margin: 0,
                }}
                rules={[
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ]}
              >
                {inputNode}
              </Form.Item>
            ) : (
              children
            )}
          </td>
        );
      };

      const getBillingDetails = async()=>{
        setLoading(true)
        let newdata = []
        const input = {access_token:localStorage.token}
        const res = await billing_get(input)
        console.log('res',res);
        try{
          if (res && res.status==='successfull'){ 
            Object.keys(res.result).map((val)=>{
              newdata.push({ 
              key: res.result[val].key,
              id: res.result[val].id,
              date:res.result[val].date,
              email:res.result[val].email,
              agreement_date:res.result[val].agreement_date,
              investment_amount:res.result[val].investment_amount,
              paid_so_far:res.result[val].paid_so_far,
              balance_due:res.result[val].balance_due,
              est_fees:res.result[val].est_fees
              })
            })
            setData(newdata)
            setLoading(false)
          }
          else{setLoading(false); message.error(res.message)}
        }catch{setLoading(false); message.error('Something went wrong')}
      }

      useEffect(() =>{
        getBillingDetails()
      },[]);

      const save = async (key) => {
        setLoading(true)
          try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
              setData(newData);
              setEditingKey('');
            const input = {
              access_token:localStorage.token,
              row:newData[index],
            }
            billing_put(input).then(res => {
                console.log(res,'abc',input);
                
                try{
                  if (res && res.status==='successfull'){
                    getBillingDetails();
                    setLoading(false);
                    message.success("Saved");
                    }
                  else{
                    setLoading(false)
                    message.error(res.message);
                  }
                }catch{
                  setLoading(false);
                }
              })
            } else {
              newData.push(row);
              setData(newData);
              setEditingKey('');
              setLoading(false)
            }
          } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
            setLoading(false)
          }
        };
        const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
    form.setFieldsValue({
      agreementDate: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Agreement Date',
      dataIndex: 'agreement_date',
      editable: true,
    },
    {
      title: 'Investment Amount',
      dataIndex: 'investment_amount',
      editable: true,
    },
    {
      title: 'Paid so far for the current year',
      dataIndex: 'paid_so_far',
      // editable: true,
    },
    {
      title: 'Balance Due',
      dataIndex: 'balance_due',
      // editable: true,
    },
    {
      title: 'Estd.Fees',
      dataIndex: 'est_fees',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 120,
      render: (_, record) => {
          const editable = isEditing(record);
     
return editable ? (
          <span>
              <Typography.Link onClick={() => save(record.key)} style={{marginRight: 8,}}>
                  <span className='outlineSaveButton'>
                  <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
                  </span>
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
              </Popconfirm>
          </span>
          ) : (
          <span>
              <Typography.Link disabled={editingKey !== ''}
                  onClick={() => edit(record)}
                  >
                  <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
              </Typography.Link>
              {/* <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                  <DeleteFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }} />
              </Popconfirm> */}
          </span>
      )
       },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        }),
      };
    });
    
  return (
    <>
    {loading?<Loader/>:null}
    
    <div>
        <Form form={form}>
        <Table components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        rowClassName="editable-row"
        dataSource={data} size='small' scroll={{y: 170 }} />
      </Form>
    </div>
    </>
  )
}

export default BillingTable
import React,{useState} from 'react'
import {forgotpassword} from '../Api';
import {useNavigate} from 'react-router-dom'
import { Col,Row,Image, Button, Form, Input} from 'antd';
import { MDBIcon } from 'mdbreact';
import LoginImage from '../../../Images/loginImage.jpg'
import Logo from '../../../Images/logoBlack.png'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const toastMsg =(id,msg,type)=>{
    toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
  } 

  const submitEmail=(values)=>{
      const id = toast.loading("Please wait...")
      const input={email_address:values.emailAddress}
      console.log(input);
      forgotpassword(input).then(res => {
        console.log(res);
          try{
          if (res.status==='successfull') {
            toastMsg(id,res.message,'success')
          }
          else{
            toastMsg(id,res.message,'error')
          }
          }catch{
            toastMsg(id,'Something went wrong','error')
          }
          })
  }
  const ForgotForm = ()=>{
    return(
    <>
      <Form onFinish={submitEmail}>
      <Form.Item
        name="emailAddress"
        rules={[
          {required: true, message: 'Please input your Email Address!'},
          {message: 'Invalid Email',
          validator: (_, value) => {
            if (/^(([^<>()[\].,;:#*\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(value)) {
              return Promise.resolve();
            } else {
              return Promise.reject('Some message here');
            }
          }
          }]}
          hasFeedback>
        <Input 
        prefix={<MDBIcon icon="at" className='loginFormIcon' />}
        placeholder="Email" className='LoginFormInput' />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" className="loginFormButton">
          Submit
        </Button>
      </Form.Item>
    </Form>
    <p className='LoginOr'><a href='/login'>Back to Login</a></p>
    </>
    )
  }
  return (
      <div className='fixPageHeight'>
          <ToastContainer theme='colored'/>
        <div className='desktop'>
          <Row>
            <Col span={13}>
              <div className='loginFormDiv'>
              <MDBIcon icon="home" onClick={()=>navigate('/home')} className='loginHomeIcon' />
                <Image src={Logo} rootClassName='loginLogoImage' preview={false}/>
                <h6 className='LoginHeading'>Forgot Password</h6>
                <ForgotForm/>
              </div>
            </Col>
            <Col span={11}>
              <Image src={LoginImage} rootClassName='loginImage' preview={false}/>
            </Col>
          </Row>
        </div>
        <div className='mobile'>
          <div className='mobileLoginBg'>
            <MDBIcon icon="home" onClick={()=>navigate('/home')} className='loginHomeIcon' />
              <Image src={Logo} rootClassName='loginLogoImage' preview={false}/>
              <div className='mobileLoginBox'>
                <div className='LoginHeading'>Forgot Password</div>
                <ForgotForm/>
              </div>
          </div>
        </div>
      </div>
        )
      }
export default ForgotPassword
import React,{useState,useEffect} from 'react';
import { Table } from "ant-table-extensions";
import {customer_mf_details} from '../../Api'
import { DatePicker, Space } from 'antd';
import moment from 'moment'
const MfOrders = (props) => {
    const [pickerValue, setPickerValue] = useState(moment(new Date()));
    const [data, setData] = useState([]);
    const date = new Date().toISOString().split('T')[0]
    const [dateSend, setdateSend] = useState([])

    const { RangePicker } = DatePicker;

    const fetchData = ()=>{
      let newdata = []
      const input = {
          access_token:localStorage.token,
          type:'mf_orders',
          id:props.customer_id,
          start_date:dateSend[0],
          end_date:dateSend[1]
      }

      console.log(input);
      customer_mf_details(input).then(res => {
        console.log('mforders',res);
        Object.keys(res.result).map((val)=>{
            newdata.push({ 
            key: val,
            amount:res.result[val].amount,
            average_price: res.result[val].average_price,
            customer_id: res.result[val].customer_id,
            date: res.result[val].date,
            exchange_order_id: res.result[val].exchange_order_id,
            exchange_timestamp: res.result[val].exchange_timestamp,
            folio: res.result[val].folio,
            fund: res.result[val].fund,
            name: res.result[val].name,
            last_price: res.result[val].last_price,
            last_price_date: res.result[val].last_price_date,
            order_id: res.result[val].order_id,
            order_timestamp: res.result[val].order_timestamp,
            placed_by: res.result[val].placed_by,
            purchase_type: res.result[val].purchase_type,
            quantity: res.result[val].quantity,
            settlement_id: res.result[val].settlement_id,
            status: res.result[val].status,
            status_message: res.result[val].status_message,
            tag: res.result[val].tag,
            tradingsymbol: res.result[val].tradingsymbol,
            transaction_type: res.result[val].transaction_type,
            variety: res.result[val].variety,     
            })
        })
          setData(newdata)
        })
    }
    
    useEffect(()=>{
      fetchData()
    },[])
      const onChangeDate = (date, dateString) => {
        setdateSend(dateString)
      };
      const submitData =()=>{
        fetchData()
      }
      const columns = [
        {title: 'Updated Date',dataIndex: 'date',width: 70},
        {title: 'Name',dataIndex: 'name',width: 70},
        {title: 'Amount',dataIndex: 'amount',width: 70},
        {title: 'Average Price',dataIndex: 'average_price',width: 70},
        {title: 'Customer Id',dataIndex: 'customer_id',width: 70},
        {title: 'Exchange_order_id',dataIndex: 'exchange_order_id',width: 70},
        {title: 'Exchange Timestamp',dataIndex: 'exchange_timestamp',width: 80},
        {title: 'Quantity',dataIndex: 'quantity',width: 80},
        {title: 'Folio',dataIndex: 'folio',width: 70},
        {title: 'Fund',dataIndex: 'fund',width: 70},
        {title: 'Last Price',dataIndex: 'last_price',width: 70},
        {title: 'Order Id',dataIndex: 'order_id',width: 80},
        {title: 'Order Timestamp',dataIndex: 'order_timestamp',width: 80},
        {title: 'Placed By',dataIndex: 'placed_by',width: 70},
        {title: 'Purchase Type',dataIndex: 'purchase_type',width: 70},
        {title: 'Settlement Id',dataIndex: 'settlement_id',width: 80},
        {title: 'Status',dataIndex: 'status',width: 70},
        {title: 'Status Message',dataIndex: 'status_message',width: 70},
        {title: 'Tag',dataIndex: 'tag',width: 70},
        {title: 'Trading Symbol',dataIndex: 'tradingsymbol',width: 70},
        {title: 'Transaction Type',dataIndex: 'transaction_type',width: 80},
        {title: 'Variety',dataIndex: 'variety',width: 70},
        
      ]
  return(
      <>
      <div className='modalDateBox'>
        <RangePicker 
          onChange={onChangeDate} allowClear={true}
          defaultValue={[pickerValue, pickerValue]} />
        <button className='dateSubmitButton' onClick={submitData}>Submit</button>
    </div>
        <Table
              rowClassName="editable-row"
              columns={columns}
              dataSource={data}
              size='small'
              scroll={{ x: 1150, y: 400 }}
              pagination={{
                pageSize: 10,
                position:['bottomCenter']
              }}
              sticky={true}
              exportableProps={{ fileName: `MFOrders_${props.cusEmail}_${date}`}}
          />

      </>
  );
};

export default MfOrders;

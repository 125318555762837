// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commentIconDiv {
  float: right;
  transform: translate(30%, 15%);
  margin-right: 15px;
}

.commentBoxHead {
  font-weight: 700;
  font-size: 16px;
}

.commentBoxTagLine {
  font-size: 12px;
  color: grey;
  margin-bottom: 15px;
}

.commentListBox {
  height: 45vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.commentListText {
  font-size: 11px;
  font-weight: 500;
  margin-top: -10px;
}

.commentThank {
  color: grey;
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Dashboard/comment/Comment.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,8BAAA;EACA,kBAAA;AACJ;;AACA;EACI,gBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,eAAA;EACA,WAAA;EACA,mBAAA;AAGJ;;AADA;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AAIJ;;AAAA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AAGJ;;AADA;EACI,WAAA;EACA,gBAAA;AAIJ","sourcesContent":[".commentIconDiv{\n    float: right;\n    transform:translate(30%,15%) ;\n    margin-right: 15px;\n}\n.commentBoxHead{\n    font-weight: 700;\n    font-size: 16px;\n}\n.commentBoxTagLine{\n    font-size: 12px;\n    color: grey;\n    margin-bottom: 15px;\n}\n.commentListBox{\n    height: 45vh;\n    width: 100%;\n    overflow-x: hidden;\n    overflow-y: auto;\n    // box-shadow: 0 0 3px #ccc;\n    // padding: 5px;\n}\n.commentListText{\n    font-size: 11px;\n    font-weight: 500;\n    margin-top: -10px;\n}\n.commentThank{\n    color: grey;\n    margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { PureComponent } from 'react'
import {save_bank_details,get_bank_details,update_bank_details,save_cheque_img} from './Api'
import CropRotateImage from '../../shared/CropRotateImage'
import Loader from '../../shared/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import {MDBIcon} from 'mdbreact'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ProfileSetup.scss'


const toastMsg =(ids,msg,type)=>{
  toast.update(ids,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
} 


export default class BankDetails extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            bank_name:'',
            account_number:'',
            confirm_account_number:'',
            branch:'',
            account_holder_name:'',
            account_type:'',
            ifsc_code:'',
            micr_code:'',
            loading:false,
            disabled:false,
            error:false,
            success:false,
            name_vaild:true,
            number_valid:true,
            ifsc_valid:true,
            warning:false,
            branch_valid:true,
            cname_valid:true,
            signature:'',
            skip:false,
            
        }
    }
    componentDidMount(){
      const input = {
        access_token:localStorage.token,
        id:this.props.id
    }
    get_bank_details(input).then(res => {
      console.log(res)
    try{
      if(this.props.bank===1){
        if (res.status==='successfull') {
          this.setState(() => ({
             bank_name:res.result.bank_name,
             branch:res.result.branch,
             account_holder_name:res.result.account_holder_name,
             account_number:res.result.account_number,
             account_type:res.result.account_type,
             ifsc_code:res.result.ifsc_code,
             micr_code:res.result.micr_code,
             disabled:true
          }))
      }
      else{console.log(res)}
      }
      else{
        if (res.status==='successfull') {
          this.setState(() => ({disabled:false}))
      }
      else{console.log(res)}
      }
    
  }catch{toast.error('Something went wrong')
  this.setState({loading:false})}
  })

}

onChange=(e)=>{
    this.setState({ [e.target.name]: e.target.value })
  }
enableNext=()=>{
  this.setState({skip:!this.state.skip})
  }
onSubmitaddress=(e)=>{
  e.preventDefault();
  const ids = toast.loading("Please wait...",{toastId:'load'})
  const input = {
        access_token:localStorage.token,
        bank_name:this.state.bank_name,
        branch:this.state.branch,
        account_holder_name:this.state.account_holder_name,
        account_number:this.state.account_number,
        account_type:this.state.account_type,
        ifsc_code:this.state.ifsc_code,
        micr_code:this.state.micr_code,
        id:this.props.id,
        skip:this.state.skip,
  }
  if(this.props.bank===0){
      save_bank_details(input).then(res => {
          console.log("res",res)
      try{
      if (res.status==='successfull') {
        this.setState(() => ({disabled:false}))
        this.props.update();
        this.props.step4();
    }
    else{toastMsg(ids,res.message,'error')
     }
      
    }catch{toastMsg(ids,'Something went wrong','error')}
    })
    
  }
  else{
    update_bank_details(input).then(res => {
  try{
  if (res.status==='successfull') {
    toastMsg(ids,res.message,'success')
    this.setState(() => ({disabled:true}))
    
}
else{toastMsg(ids,res.message,'error')}
}catch{toastMsg(ids,'Something went wrong','error')}
})
}
}

edit=(e)=>{
  e.preventDefault()
  this.setState({
      disabled:false,
  })
}
clear_sign=()=>{
 console.log('cancel')
}
onChangename=(e)=>{
  const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s][a-zA-Z. ]{2,30}$/)
  let name= validate.test(e.target.value)
  if(name===true){
      this.setState({
          [e.target.name]: e.target.value,
          name_vaild:true
      })
  }
  else{
      this.setState({
        [e.target.name]: e.target.value,
        name_vaild:false
      })
  }
}
onChangebranch=(e)=>{
  const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s][a-zA-Z. ]{2,30}$/)
  let name= validate.test(e.target.value)
  if(name===true){
      this.setState({
          [e.target.name]: e.target.value,
          branch_valid:true
      })
  }
  else{
      this.setState({
        [e.target.name]: e.target.value,
        branch_valid:false
      })
  }
}
onChangecname=(e)=>{
  const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s][a-zA-Z. ]{2,30}$/)
  let name= validate.test(e.target.value)
  if(name===true){
      this.setState({
          [e.target.name]: e.target.value,
          cname_valid:true
      })
  }
  else{
      this.setState({
        [e.target.name]: e.target.value,
        cname_valid:false
      })
  }
}
onChangenumber=(e)=>{
  const validate=RegExp(/^([0-9]*)$/)
  let code= validate.test(e.target.value)
  if(code===true){
      this.setState({
          [e.target.name]: e.target.value,
          number_valid:true
      })
  }
  else{
      this.setState({
        [e.target.name]: e.target.value,
        number_valid:false
      })
  }
}
onChangeifsc=(e)=>{
  let upper= (e.target.value).toUpperCase();
  const validate=RegExp(/^[^~`!@#$%^&()_={}[\]:;,.<>+/?-\s\d][A-Z0-9]{2,30}$/)
  let code= validate.test(upper)
  if(code===true){
      this.setState({
          [e.target.name]: upper,
          ifsc_valid:true
      })
  }
  else{
      this.setState({
        [e.target.name]: upper,
        ifsc_valid:false
      })
  }
}
cancel=()=>{
  this.setState({
      error:false,
      success:false,
      warning:false
  })
}
pleaseSave=(e)=>{
  e.preventDefault()
  this.setState({
      warning:true,
  })
}
handlesave=()=>{
  this.props.step4();
}
onleave=()=>{
  this.setState({
    warning:false,
})
}


    render() {
      const {disabled} = this.state
        if (this.state.loading){
            return(
              <div style={{textAlign:'center',marginTop:100}}>
              <Loader/>
              </div>
              );
          }
      return (
        <div>
        <ToastContainer theme='colored' limit={1} enableMultiContainer={false}/>
           {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
              {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

            {this.state.warning?(
            <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  confirmBtnBsStyle="success"
                  cancelBtnText="No"
                  cancelBtnBsStyle="danger"
                  onConfirm={this.handlesave}
                  onCancel={this.onleave}
                  focusCancelBtn>Do you want to proceed without saving your changes</SweetAlert>):(<span></span>)}


        {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancel}>
              {this.state.successmsg}
            </SweetAlert>):(<span></span>)}
        <div className='row'>
            <div className='col-md-12'>
              <form onSubmit={this.onSubmitaddress}>
                <div className='form-heading'>Bank Details
                  {this.props.bank===1?(
                  <span className='formButton'>
                    {disabled?
                      <button type="button" onClick={this.edit}><MDBIcon far icon="edit" className='form-edt-icon' />Edit</button>:
                      <button type='submit'><MDBIcon far icon="save" className='form-edt-icon'/>Save</button>}
                  </span>):null}
                  
                </div>
      
              <div className='formHeight'>
               <div className='row'>
                <div className='col-md-3 inner-form-gap'>
                 <div class="input-field-u">
                      <input id="Bname" type="text" name="bank_name" value={this.state.bank_name} onChange={this.onChangename} disabled={this.state.disabled} maxLength={3230} />
                      <label for="Bname">Bank Name</label>
                  </div>
                  {this.state.name_vaild?(<span></span>):(<div className='invalid-msg'>Only text</div>)}
                </div>
                <div className='col-md-3'>
                  <div class="input-field-u">
                  <div className='select-label'>Account Type</div>
                  <div className='profile-form-input'>
                    <select name="account_type" value={this.state.account_type} disabled={this.state.disabled} onChange={this.onChange}>
                    <option value="">Select </option>
                    <option value="Saving">Saving</option>
                    <option value="Current">Current</option>
                    <option value="NRE accounts">NRE accounts</option>
                    <option value="NRO accounts">NRO accounts</option>
                    </select>
                  </div>
                  </div>
                 </div>

                 <div className='col-md-3 inner-form-gap'>
                 <div class="input-field-u">
                      <input id="number" type="text" maxLength={18} name="account_number" value={this.state.account_number} disabled={this.state.disabled} onChange={this.onChangenumber}/>
                      <label for="number">Account Number</label>
                  </div>
                  {this.state.number_valid?(<span></span>):(<div className='invalid1'>Only numeric value</div>)}
                  </div>

                 {this.props.bank===0?(
                <div className='col-md-3 inner-form-gap'>
                 <div class="input-field-u">
                      <input id="confirm-number" type="text" maxLength={18} name="confirm_account_number" value={this.state.confirm_account_number} disabled={this.state.disabled} onChange={this.onChange}/>
                      <label for="confirm-number">Confirm Account Number</label>
                  </div>
                  {this.state.confirm_account_number===this.state.account_number?(<span></span>):(<div className='invalid1'>Account number not match</div>)}
                  </div>):(<span></span>)}

                  <div className='col-md-3 inner-form-gap'>
                 <div class="input-field-u">
                      <input id="branch" type="text" maxLength={100} name="branch" value={this.state.branch} disabled={this.state.disabled} onChange={this.onChangebranch}/>
                      <label for="branch">Branch</label>
                  </div>
                   {this.state.branch_valid?(<span></span>):(<div className='invalid-msg'>Only text</div>)}
                  </div>


                  <div className='col-md-3 inner-form-gap'>
                  <div class="input-field-u">
                      <input id="ifsc" type="text" name="ifsc_code" value={this.state.ifsc_code} onChange={this.onChangeifsc} disabled={this.state.disabled} maxLength={30} style={ {textTransform:'uppercase'}}/>
                      <label for="ifsc">IFSC Code</label>
                  </div>
                  {this.state.ifsc_valid?(<span></span>):(<div className='invalid1'>Only capital alphabets </div>)}
                </div>

                <div className='col-md-3 inner-form-gap'>
                  <div class="input-field-u">
                      <input id="micr" type="text" name="micr_code" value={this.state.micr_code} onChange={this.onChange}disabled={this.state.disabled} maxLength={30} style={ {textTransform:'uppercase'}}/>
                      <label for="micr">MICR Code</label>
                  </div>
                </div>

                 <div className='col-md-3 inner-form-gap'>
                 <div class="input-field-u">
                      <input id="name" type="text" name="account_holder_name" value={this.state.account_holder_name} onChange={this.onChangecname} disabled={this.state.disabled} maxLength={30}/>
                      <label for="name">Name(as per bank details)</label>
                  </div>
                  {this.state.cname_valid?(<span></span>):(<div className='invalid-msg'>Only text</div>)}
                  </div>
            </div>
            </div>

        {this.props.bank===0?(
          <div className='agree-checkbox'><input type='checkbox' onChange={this.enableNext}/>  Skip this section</div>):(<span></span>)}

          {this.props.family_details===1?(<div></div>):(<div className='previous-msg'>Please fill the previous form first.</div>)}  

        {this.props.family_details===1 && this.props.bank===0?(
          <div className='submit-Btn'>
          <button onClick={this.props.step2}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
          
          {this.state.skip?(
            <button type="button" onClick={this.onSubmitaddress}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
          ):(
            <button type="submit">Submit & Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
          )}
          
        </div>
        ):(<div></div>)}

      

        {this.props.bank===1 && this.props.family_details===1?(<div className='submit-Btn'><button onClick={this.props.step2}><MDBIcon icon="angle-left" className='buttonArrowLeft' />Previous</button>
        {this.state.disabled?(
        <button onClick={this.props.step4}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
        ):(
        <button type='button' onClick={this.pleaseSave}>Next<MDBIcon icon="angle-right" className='buttonArrowRight' /></button>
        )}
        </div>):(<div></div>)}
    </form>

    </div>
    </div>
    </div>

        )
    }
}


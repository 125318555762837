import React,{useState,useEffect} from 'react'
import {Table,Input,Space,Button,Typography,Popconfirm,Select,Form} from 'antd';
import { EditFilled,CloseCircleFilled,SaveOutlined,EyeOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import {getAdminComments,admin_comment_put} from '../Api'
import {MDBIcon} from 'mdbreact'
import Highlighter from 'react-highlight-words';
import ReplyModal from '../../Dashboard/comment/ReplyModal';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Comment = (props) => {
  const [data, setData] = useState([]);
  const [searchText,setSearchText] = useState('')
  const [searchedColumn,setSearchedColumn] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReplyModalVisible, setIsReplyModalVisible] = useState(false);
  const [commentId, setCommentId] = useState(0);
  const [customerId, setCustomerId] = useState(0);
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const {Option} = Select;


  const toastMsg =(id,msg,type)=>{
    toast.update(id,{render:msg,type: type,isLoading: false,autoClose:4000,closeButton:true });
  }

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    disable,
    ...restProps
  }) => {


  const inputNode = 'Status'?
                          <Select>
                            <Option value="Open">Open</Option>
                            <Option value="Pending">Pending</Option>
                            <Option value="Resolved">Resolved</Option>
                            <Option value="Closed">Closed</Option>
                          </Select>:
                            inputType === 'remarks' ? <Input/>:<Input/>
              return (
                  <td {...restProps}>
                      {editing? (
                          <Form.Item
                          name={dataIndex}
                          rules={[{required: true,message: `Please Input ${title}!`}]}>
                          {inputNode}
                          </Form.Item>
                      ) : (
                          children
                      )}
                  </td>
              );
          };

          const isEditing = (record) => record.key === editingKey;
          const edit = (record) => {
            form.setFieldsValue({
              status: '',
              ...record,
            });
            setEditingKey(record.key);
          };
        
          const cancel = () => {
            setEditingKey('');
          };

  let searchInput = ''
  const fetchAdminComment = async ()=>{
    let newdata = []
    const input = {access_token:localStorage.token,customer_id:props.customer_id}
    const res = await getAdminComments(input)
    console.log(res);
    try{
        if (res.status==='successfull') {
        
          Object.keys(res.result).map((val)=>{
            newdata.push({ 
            key: res.result[val].comment_id,
            comment_id:res.result[val].comment_id,
            customer_id:res.result[val].customer_id,
            comment: res.result[val].comment.substring(0,50)+'...',
            date: res.result[val].date,
            name:res.result[val].name,
            email:res.result[val].email,
            status: res.result[val].status
            })
           
        })
        setData(newdata)
        
        }
        else{
         
        }
    }catch{
      toast.error('Something went wrong')
    }
  }
  useEffect (()=>{
    fetchAdminComment()
  },[])

  const save = async (key) => {
    const id = toast.loading("Please wait...",{toastId:'load'})
        try {
    const row = await form.validateFields();
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
            setData(newData);
            setEditingKey('');
          const input = {
            access_token:localStorage.token,
            row:newData[index],
          }
            admin_comment_put(input).then(res => {
              console.log(res,'abc',input);
              try{
                if (res && res.status==='successfull'){
                  toastMsg(id,res.message,'success')
                  fetchAdminComment();
                  }
                else{toastMsg(id,res.message,'error')}
              }catch{toastMsg(id,'Something went wrong','error')}
            })
          } else {
            newData.push(row);
            setData(newData);
            setEditingKey('');
            // setLoading(false)
          }
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
          // setLoading(false)
        }
      };
  
  let getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<MDBIcon className='tableSearchI' icon="search"/>}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => {
            handleReset(clearFilters)
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
            }} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <MDBIcon className='tableSearchI' icon="search" style={{ color: filtered ? '#e87c1b' : '#000' }}/>,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };
  const  handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const handleModal = () => {setIsModalVisible(!isModalVisible);};
  const handleReplyModal = (record) => {
    setIsReplyModalVisible(!isReplyModalVisible)
    setCommentId(record.comment_id)
    setCustomerId(record.customer_id)
  };


  const columns = [
    {title: 'COMMENT ID',dataIndex: 'comment_id',width: 60, ...getColumnSearchProps('comment_id')},
    {title: 'DATE',dataIndex: 'date',width: 100,...getColumnSearchProps('date')},
    {title: 'NAME',dataIndex: 'name',width: 80,...getColumnSearchProps('name')},
    {title: 'EMAIL',dataIndex: 'email',width: 180,...getColumnSearchProps('email')},
    {title: 'COMMENT',dataIndex: 'comment',width: 200},
    {title: 'STATUS',dataIndex: 'status',width: 80,editable: true,...getColumnSearchProps('status')},
    {title: 'Action',dataIndex: 'action',width: 120,
            render: (_, record) => {
                const editable = isEditing(record);
         
return editable ? (
              <span>
                  <Typography.Link onClick={() => save(record.key)} style={{marginRight: 8,}}>
                      <span className='outlineSaveButton'>
                      <SaveOutlined style={{ fontSize: '13px', color: '#82E0AA', marginRight:'3px' }} />Save
                      </span>
                  </Typography.Link>
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                      <CloseCircleFilled style={{ fontSize: '15px', color: '#CB4335', marginLeft:'15px' }}/>
                  </Popconfirm>
              </span>
              ) : (
              <span>
                  <Typography.Link disabled={editingKey !== ''}
                      onClick={() => edit(record)}>
                      <span className='outlineEditButton'> <EditFilled style={{ fontSize: '13px', color: '#5DADE2', marginRight:'3px' }} />Edit</span>
                  </Typography.Link>
                      <EyeOutlined style={{ fontSize: '15px', color: 'black', marginLeft:'15px' }} icon="eye" onClick={()=>handleReplyModal(record)} className='chartI' />
                  
              </span>
          )
           },
        },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        }),
      };
    });

  return (
    <div>
      <Form form={form} component={false}>
        <Table rowClassName="antTable"
         components={{body: {
                        cell: EditableCell,
                      },
                    }} columns={mergedColumns}  size='small' dataSource={data}
                  pagination={{pageSize: 8,position:['bottomCenter']}} sticky={true} />
      </Form>
                  
          {isReplyModalVisible && commentId?<ReplyModal commentId={commentId} customerId={customerId} handleReplyModal={handleReplyModal}/>:null}
    </div>
  )
}

export default Comment
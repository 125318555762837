import axios from 'axios'
export const login = input => {
    return axios
        .post('/alpha_user_website/login', {
                email_address: input.email_address,
                password: input.password
        },)
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const signup = input => {
    return axios
        .post('/alpha_user_website/signup', {
            email_address : input.email_address,
            customer_firstname : input.customer_firstname,
            customer_middlename : input.customer_middlename,
            customer_lastname : input.customer_lastname,
            mobile_number : input.mobile_number,
            password : input.password
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const forgotpassword = user => {
    return axios
        .post('/alpha_user_website/forgot_password', {
            email_address: user.email_address
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const validatePasswordResetToken = token => {
    return axios
        .get('/alpha_user_website/password_reset/validate_password_reset_token', {
            params: {
                token:token
              }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const sendPasswordResetData = passwordDetails => {
    return axios
        .post('/alpha_user_website/password_reset/password_change_submit', {
            token: passwordDetails.token,
            password: passwordDetails.password
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const remember_me = input => {
    return axios
        .get('/alpha_user_website/remember_me',
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }

  export const complaints = input => {
    return axios
        .get('/alpha_admin/complaints')
        .then(response => {
            return response.data
        })
        .catch(err => {})
  }
  export const send_verification_code = input => {
    return axios
        .post('/alpha_user_website/send_verification_code',
        {
            email:input.email,
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}

export const email_verification = input => {
    return axios
        .post('/alpha_user_website/email_verification',
        {
            email:input.email,
            code:input.code
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}

export const contact_hoa = input => {
    return axios
        .post('/alpha_user_website/contact_hoa',
        {
            access_token:input.access_token,
            name: input.name,
            surname: input.surname,
            email: input.email,
            phone_number: input.phone_number,
            message: input.message,
            reference:input.reference
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const subscribe = input => {
    return axios
        .post('/alpha_user_website/subscribe',
        {
            access_token:input.access_token,
            phone_number: input.phone_number,
        },
        {
            headers: {
              'Authorization': input.access_token
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
export const get_faqs = input => {
    return axios
        .get('/alpha_user_website/faqs',
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const disclosure = input => {
        return axios
            .get('/alpha_admin/disclouser_data')
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const hoa_blogs_get = input => {
        return axios
            .get('/alpha_user_website/hoa_blogs')
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const hoa_blogs_get_new = input => {
        return axios
            .get('/alpha_user_website/new_hoa_blogs')
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const hoa_blog = input => {
        return axios
            .get('/alpha_user_website/hoa_blog',
            {
                params:{
                  title:input.title
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const get_hoa_videos = async input => {
        try {
            const res = await axios.get('/alpha_user_website/video_links')
            if (res){return res.data}
        } catch (error) {}        
      }

      export const testimonial_get = input => {
        return axios
            .get('/alpha_user_website/testimonials')
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const testimonial_put = input => {
        return axios
            .put('/alpha_user_website/testimonials', {
                access_token:input.access_token,
                row:input.row
            },
            {
                headers: {
                  'Authorization': input.access_token
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const testimonial_delete = input => {
        return axios
            .delete('/alpha_user_website/testimonials',
            {
                headers: {
                  'Authorization': input.access_token
                },
                params:{
                  row:input.row
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
      }
      export const  otpVerificationEmail = input => {
        return axios
        .get('/alpha_user_website/get_role_verification',
        {
            headers: {
              'Authorization': input.access_token
            },
            params: {
              email:input.email
            }
          })
        .then(response => {
            return response.data
        })
        .catch(err => {})
        }

        export const otpVerificationEmailPost = input => {
            return axios
                .post('/alpha_user_website/email_verification', {
                    email:input.email,
                    code:input.code
                },
                {
                    headers: {
                      'Authorization': input.access_token
                    }
                  })
                .then(response => {
                    return response.data
                })
                .catch(err => {})
          }
          export const otpMobileVerification = input => {
            return axios
            .get('/alpha_user_compliance/otp_verification',
            {
                headers: {
                  'Authorization': input.access_token
                },
                params: {
                  mobile_no:input.mobile_no
                }
              })
            .then(response => {
                return response.data
            })
            .catch(err => {})
            }
            export const otpMobileVerificationSumbit = input => {
                return axios
                    .post('/alpha_user_compliance/otp_verification', {
                      given_otp: input.given_otp,
                    },
                    {
                        headers: {
                          'Authorization': input.access_token
                        }
                      })
                    .then(response => {
                        return response.data
                    })
                    .catch(err => {})
              }

    export const hoa_webinar_details = input => {
      return axios
          .post('/alpha_user_website/hoa_webinar_details', {
            field : input.field,
          })
          .then(response => {
              return response.data
          })
          .catch(err => {})
  }
  export const hoa_webinar_details_get = input => {
    return axios
    .get('/alpha_user_website/hoa_webinar_details',
    {
        params: {
          pin:input.pin
        }
      })
    .then(response => {
        return response.data
    })
    .catch(err => {})
    }
    // export const hoa_webinar_calculator = input => {
    //   return axios
    //       .get('/alpha_user_website/hoa_webinar_calculator',
    //       {
    //           params: {
    //             No_Of_years_to_retirement:input.No_Of_years_to_retirement,
    //             Expected_Inflation:input.Expected_Inflation,
    //             Todays_Annual_Living_Expenses:input.Todays_Annual_Living_Expenses,
    //             Interest_rate_at_the_time_of_retiremen:input.Interest_rate_at_the_time_of_retiremen,

    //           }
    //         })
    //       .then(response => {
    //           return response.data
    //       })
    //       .catch(err => {})
    // }
    export const hoa_webinar_calculator = input => {
      return axios
          .post('/alpha_user_website/hoa_webinar_calculator', {
            field : input.field,
          })
          .then(response => {
              return response.data
          })
          .catch(err => {})
  }
  export const power_of_passive_income_form = input => {
    return axios
        .post('/alpha_user_website/power_of_passive_income_form', {
          name  : input.name,
          email  : input.email,
        })
        .then(response => {
            return response.data
        })
        .catch(err => {})
}
import React, { Component } from 'react'
import Moment from 'moment';
import {admin_mf_value,admin_mf_value_post} from '../../Api'
import {MDBIcon} from 'mdbreact'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../../shared/Loader';

export default class Mf_value extends Component {
    constructor(props){
        super(props);
        this.state={
            mf_dict:{},
            loading:false,
            success:false,
            error:false,
            errormsg:'',
            successmsg:'',
        }
    }
    componentDidMount(){
        this.setState({loading:true})
        const input = {
            access_token:localStorage.token,
            customer_id:this.props.customer_id,
            date:Moment(this.props.today).format('YYYY-MM-DD')
        }
        admin_mf_value(input).then(res => {
        try{
        if (res.status==='successfull') {
        this.setState(() => ({
            mf_dict:res.result,
            loading:false
        }))
        }
        else{
            this.setState(() => ({
                loading:false
            }))
        }
        }catch{
            this.setState(() => ({
                loading:false
            }))
        }
      })
    }
    onchange_current=(e)=>{
        let aa = this.state.mf_dict
        aa[e.target.id]["current_value"] = e.target.value
        this.setState({mf_dict:aa})
    }
    onchange_return=(e)=>{
        let aa = this.state.mf_dict
        aa[e.target.id]["return_"] = e.target.value
        this.setState({mf_dict:aa})
      }
    submit_asset=()=>{
        this.setState({loading:true})
        const input = {
            access_token:localStorage.token,
            customer_id:this.props.customer_id,
            mf_dict:this.state.mf_dict,
            date:Moment(this.props.today).format('YYYY-MM-DD')
        }
        console.log(input)
        admin_mf_value_post(input).then(res => {
                console.log("res",res)
            try{
            if (res.status==='successfull') {
                this.setState({
                    success:true,
                    loading:false,
                    successmsg:res.message
                })
            }
            else{
                this.setState({
                    error:true,
                    loading:false,
                    errormsg:res.message
                })
            }
            }catch{
                this.setState({
                    error:true,
                    loading:false,
                    errormsg:'Something went wrong'
                })
            }
          })
    }
    cancel=()=>{
        this.setState({
            success:false,
            error:false
        })
        }
componentDidUpdate(prevProps, prevState) {
    if (prevProps.today !== this.props.today) {
        const input = {
            access_token:localStorage.token,
            customer_id:this.props.customer_id,
            date:Moment(this.props.today).format('YYYY-MM-DD')
        }
        admin_mf_value(input).then(res => {
        try{
        if (res.status==='successfull') {
        this.setState(() => ({
            mf_dict:res.result,
        }))
        }
        else{}
        }catch{}
      })
    }
}
    render() {
        return (
            <div>
                {this.state.loading?(
                <div className='loader-main-div'>
                    <div className='loader-div'>
                        <Loader/>
                    </div>
                </div>
            ):(null)}

            {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancel}>
                {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

            {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
                {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

                <div className='update-table-div'>
                    <table class='table table-condensed'>
                        <thead className='mf-p-table-tr'>
                            <th>Scheme Name</th>
                            <th>Scheme Code</th>
                            <th>Current Value</th>
                            <th>Return</th>
                            <th>Date</th>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.mf_dict).map((val)=>{
                                return(
                                    <tr className='mf-p-table-tr-bg'>
                                        <td>{this.state.mf_dict[val].scheme_name}</td>
                                        <td>{this.state.mf_dict[val].scheme_code}</td>

                                        <td><input  value={this.state.mf_dict[val].current_value} id={val} onChange={this.onchange_current}/></td>

                                        <td><input id={val} value={this.state.mf_dict[val].return_} onChange={this.onchange_return}/></td>

                                        <td>{this.state.mf_dict[val].date}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='update-sub-btn'><button onClick={this.submit_asset}><MDBIcon icon="save" className='btn-i'/>Save</button></div>
            </div>
        )
    }
}

import axios from 'axios'

export const password_setting = input => {
    return axios
        .post('/alpha_user_db/password_setting', {
                newPassword:input.newPassword,
                password :input.password,
        },
        {
            headers: {
                'Authorization': input.access_token
            }
            })
        .then(response => {
            return response.data
        })
        .catch(err => {})
    }
import React,{useState, useEffect} from 'react'
import { Column } from '@ant-design/plots';

const ToolTipChart = (props) => {
    const numberFormat=(value)=>{
        var val = Math.abs(value)
        if (val >= 10000000) {
          val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
          val = (val / 100000).toFixed(2) + ' L';
        }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
        return val;
      }

    let data =[]
    Object.keys(props.cData).map((val)=>{
        data.push({Name: val, Value: props.cData[val]})
      })

      const config = {
        data,
        xField: 'Name',
        yField: 'Value',
        maxColumnWidth:20,
        color: props.cColor,
        animation: {
            appear: {
              animation: 'wave-in', 
              duration: 1000, 
            },
          },
        label: {
            position: 'middle',
            formatter: (val) =>{return numberFormat(val.Value)},
            style: {
                fill: '#000',
                opacity: 1,
             },
        },
        xAxis: {
          label: {
            autoHide:false,
            autoEllipsis:true,
            // autoRotate:true
          },
        },
        yAxis:{
            label:{
            formatter: (text,item,index) => {return numberFormat(text)}
          },
          tickCount:4
          },
      };
      return(
        <>
            {data.length>0?
                <Column {...config} />
            :null}
         </>
      )
}

export default React.memo(ToolTipChart)
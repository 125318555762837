import React, { Component } from 'react'
import Moment from 'moment';
import {MDBIcon} from 'mdbreact'
import {admin_asset,admin_asset_post} from '../../Api'
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../../shared/Loader';

export default class Asset_value extends Component {
    constructor(props){
        super(props);
        this.state={
            asset_dict_post:{},
            loading:false,
            success:false,
            error:false,
            errormsg:'',
            successmsg:'',
        }
    }
    componentDidMount(){
        this.setState({loading:true})
        const input = {
            access_token:localStorage.token,
            customer_id:this.props.customer_id,
            date:Moment(this.props.today).format('YYYY-MM-DD')
        }
        admin_asset(input).then(res => {
            console.log('ress',res)
        try{
        if (res.status==='successfull') {
        this.setState(() => ({
            asset_dict_post:res.result,
            loading:false
        }))
        }
        else{
            this.setState(() => ({
                loading:false
            }))
        }
        }catch{
            this.setState(() => ({
                loading:false,
                error:true,
                errormsg:'Something went wrong'
            }))
        }
      })
    }

    onchange_current=(e)=>{
        let aa = this.state.asset_dict_post
        aa[e.target.id]["current_value"] = e.target.value
        this.setState({asset_dict_post:aa})
    }
    onchange_return=(e)=>{
        let aa = this.state.asset_dict_post
        aa[e.target.id]["returns"] = e.target.value
        this.setState({asset_dict_post:aa})
      }
    submit_asset=()=>{
        this.setState({loading:true})
        const input = {
            access_token:localStorage.token,
            customer_id:this.props.customer_id,
            asset_dict_post:this.state.asset_dict_post,
            date:Moment(this.props.today).format('YYYY-MM-DD')
        }
        admin_asset_post(input).then(res => {
        try{
        if (res.status==='successfull') {
            this.setState({
                success:true,
                loading:false,
                successmsg:res.message
            })
        }
        else{
            this.setState({
                error:true,
                loading:false,
                errormsg:res.message
            })
        }
        }catch{
            this.setState({
                error:true,
                loading:false,
                errormsg:'Something went wrong'
            })
        }
        })
    }
    cancel=()=>{
        this.setState({
            success:false,
            error:false
        })
        }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.today !== this.props.today) {
            const input = {
                access_token:localStorage.token,
                customer_id:this.props.customer_id,
                date:Moment(this.props.today).format('YYYY-MM-DD')
            }
            admin_asset(input).then(res => {
            try{
            if (res.status==='successfull') {
            this.setState(() => ({
                asset_dict_post:res.result,
            }))
            }
            else{}
            }catch{}
          })
        }
        }
    render() {
        console.log("date",this.props.today)
        return (
            <div>
                  {this.state.loading?(
                <div className='loader-main-div'>
                    <div className='loader-div'>
                        <Loader/>
                    </div>
                </div>
            ):(null)}

            {this.state.success?(
            <SweetAlert
                success
                onConfirm={this.cancel}>
                {this.state.successmsg}
            </SweetAlert>):(<span></span>)}

            {this.state.error?(
            <SweetAlert
                error
                onConfirm={this.cancel}>
                {this.state.errormsg}
            </SweetAlert>):(<span></span>)}

                <div className='update-table-div'>
                    <table class='table table-condensed'>
                        <thead className='mf-p-table-tr'>
                            <th>Asset Type</th>
                            <th>Current Value</th>
                            <th>Return</th>
                            <th>Date</th>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.asset_dict_post).map((val)=>{
                                return(
                                    <tr className='mf-p-table-tr-bg'>
                                        <td>{this.state.asset_dict_post[val].asset_type}</td>

                                        <td><input  value={this.state.asset_dict_post[val].current_value} id={val} onChange={this.onchange_current}/></td>

                                        <td><input id={val} value={this.state.asset_dict_post[val].returns} onChange={this.onchange_return}/></td>

                                        <td>{this.state.asset_dict_post[val].date}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='update-sub-btn'><button onClick={this.submit_asset}><MDBIcon icon="save" className='btn-i'/>Save</button></div>
            </div>
        )
    }
}

import React, { useState,useEffect} from 'react'
import './Assets.scss'
import {Table,Input,Space,Button} from 'antd';
import {MDBIcon} from 'mdbreact'
import Highlighter from 'react-highlight-words';
import AddComment from '../comment/AddComment';
import AssetReport from '../reporting/HoldingReport';

const Tables = (props) => {
    const {familyMem,tableData} = props
    const [tab,setTab] = useState({asset:true,war:false,report:false})
    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')
    const [relisableData, setRelisableData] = useState([]);
    const [unRelisableData, setUnRelisableData] = useState([]);
    const [war, setWar] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    let searchInput = ''
    let famD = {}

    // const numberFormat=(value)=>{
    //   var val = Math.abs(value)
    //   if (val >= 10000000) {
    //     val = (val / 10000000).toFixed(2) + ' Cr';
    //   } else if (val >= 100000) {
    //     val = (val / 100000).toFixed(2) + ' L';
    //   }else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';
    //   return val;
    // }
    const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
      // style: 'currency',
      // currency: 'INR',
      minimumFractionDigits:0
  }).format(value);

    useEffect(()=>{
      let arr= []
      let arr1 = []
      let arr2 = []
        Object.entries(tableData.realisable_assets).map((val)=>arr.push(val[1]))
        Object.entries(tableData.non_realisable_assets).map((val)=>arr1.push(val[1]))
        Object.entries(tableData.war).map((val)=>arr2.push(val[1]))
      setRelisableData(arr)
      setUnRelisableData(arr1)
      setWar(arr2)
    },[])

    console.log(tableData);
    const handleTab=(flag)=>{
        let dict = {...tab}
        Object.keys(dict).map(item => {
            if(item===flag){dict[item] = true
            }else{dict[item] = false}
            });
            setTab(dict)
    }
    let getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<MDBIcon icon="search" className='tableSearchI'/>}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => {
                handleReset(clearFilters)
                confirm({ closeDropdown: false });
                // setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                }} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <MDBIcon className='tableSearchI' icon="search" style={{ color: filtered ? '#e87c1b' : '#000' }}/>,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
      };
      const  handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };

    const handleModal = () => {setIsModalVisible(!isModalVisible);};

    let famNum = 0
    let columns = [
      {title: 'SR. NO',dataIndex: 's_no',width: 40},
      {title: 'ASSETS',dataIndex: 'asset_type',width: 130,...getColumnSearchProps('asset_type')},
    ];
 
    if(familyMem.length>famNum){
      familyMem.map((fName)=>{
        famNum = famNum+1
        columns.push({title: fName,align:'right',dataIndex: fName,width: 80,
          render: fName => { return numberFormat(Math.trunc(fName))}})
      })
      columns.push({title: 'TOTAL',align:'right',dataIndex: 'total',width: 120,
      render: total => { return numberFormat(Math.trunc(total))}
      })
    }

    const weightedColumns = [
        {title: 'SR. NO',dataIndex: 's_no',width: 20},
        {title: 'ASSETS CLASS',dataIndex: 'asset_type',width: 130,...getColumnSearchProps('asset_type')},
        {title: 'VALUE OF INVESTMENT',align:'right',dataIndex: 'invested_value',width: 80, render: total => { return numberFormat(Math.trunc(total))}},
        {title: 'WEIGHT ON PORTFOLIO',align:'right',dataIndex: 'weight_on_portfolio',width: 80},
        {title: 'EXPECTED RETURN',align:'right',dataIndex: 'new_war',width: 80,render: total => { return `${total} %`}},
        // {title: 'EXPECTED RISK ON CAPITAL IN 3YRS',dataIndex: 'expected return in 3 yrs',width: 120,...getColumnSearchProps('expected return in 3 yr')}
      ];
      
  return (
    <div className='tableContainer animate'>
        <div className='tableTabContainer'>
            <div className={tab.asset?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('asset')}>Assets</div>

             <div className={tab.war?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('war')}>WAR</div>

            <div className={tab.report?'tableTabActive':'tableTab'} 
            onClick={()=>handleTab('report')}>Zerodha Holding Report</div>

            <div className='PagesCommentIconDiv'>
              <MDBIcon className='PagesCommentIcon' icon="comment-dots" onClick={handleModal}/>
            </div>
        </div>
        {tab.war?(<>
          <div className='tableHeadAsset'>Weighted Average Return</div>
            <Table rowClassName="antTable" 
            columns={weightedColumns} dataSource={war} 
            size='small'pagination={false} 
            scroll={{y: 220}} sticky={true}

            summary={(pageData) => {
              let Total = 0
              let weight = 0
              let retrn = 0
              pageData.forEach(({ invested_value,weight_on_portfolio,weighted_average_returns}) => {
                Total = Total+invested_value;
                weight = weight+weight_on_portfolio;
                retrn = retrn+weighted_average_returns;
              });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell ></Table.Summary.Cell>
                    <Table.Summary.Cell className='tableTotal'>Total</Table.Summary.Cell>
                       <Table.Summary.Cell className='tableTotal'><div style={{textAlign:'right'}}>{numberFormat(Math.trunc(Total))}</div></Table.Summary.Cell>
                       <Table.Summary.Cell className='tableTotal'><div style={{textAlign:'right'}}>{(Math.round(weight))}</div></Table.Summary.Cell>
                       <Table.Summary.Cell className='tableTotal'><div style={{textAlign:'right'}}>{retrn.toFixed(2) + '%'}</div></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
            />
        </>):null}
        {tab.asset?
        <div>
          <div className='tableHeadAsset'>Realisable asset</div>
            <Table rowClassName="antTable" 
                    columns={columns} dataSource={relisableData} size='small'
                    pagination={false} sticky={true}
                    summary={(pageData) => {
                      let Total = 0;
                      pageData.forEach(({ total}) => {
                        Total = Total+total;
                      });

                      const myFunction1=(value)=>{
                        famD[value] = 0
                        Object.keys(pageData).map((val)=>{
                          Object.keys(pageData[val]).map((val1)=>{
                            if(value===val1){
                            famD[value] = famD[value] + pageData[val][val1] 
                            }
                          })
                        })
                      }
                      familyMem.forEach(myFunction1)
                   
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell className='tableTotal'>Total</Table.Summary.Cell>
                            {Object.keys(famD).map((tot)=>{
  
                              return(
                                <Table.Summary.Cell  className='tableTotal tableTotalValue'>{numberFormat(Math.trunc(famD[tot]))}</Table.Summary.Cell>
                              )
                            })}
                               <Table.Summary.Cell className='tableTotal tableTotalValue'>{numberFormat(Math.trunc(Total))}</Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
              />
            <div className='tableHeadAsset'>UnRealisable asset</div>
            <Table rowClassName="antTable" 
                    columns={columns} dataSource={unRelisableData} 
                    size='small' 
                    pagination={false}
                    showHeader={false}
                    summary={(pageData) => {
                      let Total = 0;
                      pageData.forEach(({ total}) => {
                        Total = Total+total;
                      });

                      const myFunction1=(value)=>{
                        famD[value] = 0
                        Object.keys(pageData).map((val)=>{
                          Object.keys(pageData[val]).map((val1)=>{
                            if(value===val1){
                            famD[value] = famD[value] + pageData[val][val1] 
                            }
                          })
                        })
                      }
                      familyMem.forEach(myFunction1)
                   
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell ></Table.Summary.Cell>
                            <Table.Summary.Cell className='tableTotal'>Total</Table.Summary.Cell>
                            {Object.keys(famD).map((tot)=>{
  
                              return(
                                <Table.Summary.Cell className='tableTotal tableTotalValue'>{numberFormat(Math.trunc(famD[tot]))}</Table.Summary.Cell>
                              )
                            })}
                               <Table.Summary.Cell className='tableTotal tableTotalValue'>{numberFormat(Math.trunc(Total))}</Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                    />
        </div>
        :null}
        {tab.report?(<div><AssetReport id={props.id}/></div>):null}
        {isModalVisible? <AddComment handleModal={handleModal}/>:null}
    </div>
  )
}

export default React.memo(Tables)
import React, { Component } from 'react'


let splitType=null
export default class AddDocument extends Component {
    constructor(props){
        super(props);
        this.state={
            document:'',
            file_name:'',
            error:false,
            success:false,

        }
    }
    uploadDocs=(e)=>{

        if(e.target.files[0].size > 500000){
            alert("File size should not be more than 500kb");
          }
          else{
            this.setState({
                document: e.target.files[0],
                file_name:e.target.files[0].name
            });
            let type = e.target.files[0].type
            splitType = type.split('/').pop();
    }
}
    submitDocs = (e) => {
        e.preventDefault();
        let data = new FormData();
        data.append('docs', this.state.document);
        if(this.props.dir==='kyc'){
            data.append('document_type', this.props.document_type+ '.' +splitType);
        }
        else{
        data.append('document_type', this.props.document_type+'-'+ this.props.refrence+ '.' +splitType);
        }
        data.append('reference',this.props.refrence)
        data.append('dir', this.props.dir);
        data.append('document_name', this.props.document_type);
        console.log(this.props.refrence,this.props.document_type,splitType,this.props.dir)
        if(this.props.id===undefined){
            data.append('id','')
        }
        else{
        data.append('id',this.props.id)
        }
        console.log(data);

        fetch('/alpha_user_profile_setup/docs_upload', {
            method: 'POST',
            headers: {
                'Authorization': localStorage.token
              },
            body: data
        }).then(response => response.json())
        .then(data => {
            console.log("bshb",data.status)
          if(data.status==='successfull'){
              this.props.onAfterUpload();
          }
          else{
            this.setState({
              error:true,
              errormsg:'Some err',
              loading:false
          })
          }
        }).catch(err => {
        
                this.setState({
                  error:true,
                  errormsg:"Something went wrong",
                  loading:false
              })
        });
          
      
    }
    cancel=()=>{
        this.setState({
            error:false,
            success:false
        })
      }
    render() { 
        console.log(this.props.document_type)
        return (
            <>
                <div className='upld-doc'>Upload Document</div>
                {this.props.refrence?( <div>{this.props.refName} : {this.props.refrence}</div>):null}
                   
                    <div class="form-group">
                        <div class="preview-zone hidden">
                            <div class="box box-solid">
                                <div class="box-header with-border"></div>
                                <div class="box-body"></div>
                            </div>
                        </div>
                        <div class="dropzone-wrapper">
                            <div class="dropzone-desc">
                                <p>Choose an document file or drag it here.</p>
                                {this.state.file_name?(<div style={{fontSize:'11px', color:'black'}}>{this.state.file_name}</div>):null}
                            </div>
                        <input type="file" accept="image/jpeg,image/png,application/pdf" class="dropzone" onChange={this.uploadDocs}/>
                    </div>
                    
                    <div className='upload'>
                    {this.props.document_type && this.state.document?(
                        <button className='upld-btn' onClick={this.submitDocs}>Upload</button>
                    ):(<button style={{background:'#E5E7E9'}}  disabled>Upload</button>)}
                    </div>
                </div>
            </>
        )
    }
}

import React, { useState,useEffect} from 'react'
import './Reporting.scss'
import { Table } from "ant-table-extensions";
import {Input,Space,Button} from 'antd';
import {year_wise_summary} from './ReportsApi'
import Loader from '../../../shared/Loader';

const YearWiseReport = (props) => {
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState(false)

  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
      // style: 'currency',
      // currency: 'INR',
      minimumFractionDigits:0
  }).format(value);

  useEffect(()=>{
    setLoading(true)
    let newdata = []
    const input = {
        access_token:localStorage.token,
        type:'sips',
        id:props.id
    }
    year_wise_summary(input).then(res => {
        console.log('year',res);
        setLoading(false)
        Object.keys(res.result).map((val)=>{
          Object.keys(res.result[val]).map((val2)=>{
            newdata.push({ 
              key: val,
              year:val2,
              name:val,
              Opening_balance:res.result[val][val2].as_on_jan,
              purchase:res.result[val][val2].purchase,
              redemption:res.result[val][val2].redemption,
              net_investment:res.result[val][val2].net_investment,
              current_value_year_end_value:res.result[val][val2].current_value_year_end_value,
              gain:res.result[val][val2].gain,
              absreturn:res.result[val][val2].absreturn,
              as_on_jan:res.result[val][val2].as_on_jan,
              })

          })
        })
        setData(newdata)
      })
  },[])

  console.log(data);
  const column = [   
    {title: 'Year',dataIndex: 'year'},
    {title: 'Name',dataIndex: 'name'},
    {title: 'Opening Balance',width: 130,align:'right',dataIndex: 'Opening_balance',render: total => { return total?(numberFormat(total.toFixed(0))):0}},
    {title: 'Purchase',align:'right',dataIndex: 'purchase',render: total => { return total?(numberFormat(total.toFixed(0))):0}},
    {title: 'Redemption',align:'right',dataIndex: 'redemption',render: total => { return total?(numberFormat(total.toFixed(0))):0}},
    {title: 'Net Investment',align:'right',dataIndex: 'net_investment',render: total => { return total?(numberFormat(total.toFixed(0))):0}},
    {title: 'Closing Value',align:'right',dataIndex: 'current_value_year_end_value',render: total => { return total?(numberFormat(total.toFixed(0))):0}},
    {title: 'Gain',align:'right',dataIndex: 'gain',render: total => {return total?(numberFormat(total.toFixed(0))):0}},
    {title: 'Abs Return',align:'right',dataIndex: 'absreturn',render: total => { return total? (`${total.toFixed(2)} %`):0}}
  ]

  // .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  
  return (
    <>
    {loading?<Loader/>:null}
    {/* <p className='tableTitle'>Income Breakup Summary</p> */}
   
      <Table rowClassName="antTable" columns={column} dataSource={data} size='small'
          // pagination={{pageSize: 5,position:['bottomCenter']}} 
          scroll={{y: 300}}
          pagination={false} 
          sticky={true}
          exportableProps={{ fileName: `yearly_summary_${localStorage.email}`}}

          // summary={(pageData) => {
          //   let Total = 0;
          //   pageData.forEach(({ amount}) => {
          //     Total = Total+amount;
          //   });

          //   return (
          //     <>
          //       <Table.Summary.Row>
          //         <Table.Summary.Cell ></Table.Summary.Cell>
          //         <Table.Summary.Cell className='tableTotal'>Total</Table.Summary.Cell>
          //            <Table.Summary.Cell className='tableTotal'>{numberFormat(Total)}</Table.Summary.Cell>
          //       </Table.Summary.Row>
          //     </>
          //   );
          // }}
      />
</>
  )
}

export default YearWiseReport
import React,{useState,useEffect} from 'react';
import { Table, ExportTableButton } from "ant-table-extensions";
import {month_end_zerodha,date_post} from '../Api'
import {Input,DatePicker,Tooltip,Button,Space,} from 'antd';
import moment from 'moment';
import {ReloadOutlined,SearchOutlined } from '@ant-design/icons'
import Loader from '../../../../shared/Loader'
import Highlighter from 'react-highlight-words';



const ClientData = (props) => {

    const [data, setData] = useState([]);
    const[searchText,setSearchText] = useState('')
    const[searchedColumn,setSearchedColumn] = useState('')
    const [datess, setDatess] = useState([]);
    const date = new Date().toISOString().split('T')[0]   
    const [loading,setLoading] = useState(false)

    let searchInput = ''

    const ExpandedRowRender = (props) => {
      const columns = [
        { title: 'Scheme Name', dataIndex: 'scheme_name',width:'30%' },
        { title: 'Quantity', dataIndex: 'quantity',width:'20%' },
        { title: 'Invested Value', dataIndex: 'invested_value',width:'25%' },
        { title: 'Market Value', dataIndex: 'market_value',width:'25%' },
      ];
    
      const data = [];
    
      if(props.schemes){
        Object.keys(props.schemes).map((val)=>{
          // console.log("vhbhbj",val)
          data.push({
            key: val,
            scheme_name: props.schemes[val].name,
            invested_value:props.schemes[val].invested_value,
            market_value: props.schemes[val].market_value,
            quantity:props.schemes[val].quantity
          })
        })
        }
     
      return <Table
        columns={columns} dataSource={data}
        pagination={false}   size='small'   
        rowClassName="editable-row"
        bordered
        exportable
        />;
    };

    // useEffect(()=>{
    //     let newdata = []
    //     const input = {
    //         access_token:localStorage.token,
    //         type:'view_client_data',
    //         id:props.customer_id,
    //     }
    //     month_end_zerodha(input).then(res => {
    //       console.log('monthen',res);
    //       Object.keys(res.result).map((val)=>
    //         Object.keys(res.result[val].shares).map((val2)=>{
    //           newdata.push({ 
    //             key: val2,
    //             date:res.result[val].date,
    //             type:res.result[val].shares[val2].type,
    //             total_invested_value: res.result[val].shares[val2].total_invested_value,
    //             total_market_value:res.result[val].shares[val2].total_market_value,
    //             description: <ExpandedRowRender schemes={res.result[val].shares[val2].schemes}/>
    //             })
    //         })
    //         )
    //       setData(newdata)
    //     })
    //   },[])

      const getMonthEnd= async () =>{
        setLoading(true)
        let newdata = []
          const input = {
            access_token:localStorage.token,
            type:'view_client_data',
            id:props.customer_id,
          }
          const res = await month_end_zerodha(input)
          console.log('monthen',res);
          try{
            if (res && res.status==='successfull'){ 
              setLoading(false)
              Object.keys(res.result).map((val)=>
                Object.keys(res.result[val]).map((index)=>
                  Object.keys(res.result[val][index].shares).map((val2)=>{
                    newdata.push({ 
                      key: val2,
                      date:res.result[val][index].date,
                      type:res.result[val][index].shares[val2].type,
                      total_invested_value: res.result[val][index].shares[val2].total_invested_value,
                      total_market_value:res.result[val][index].shares[val2].total_market_value,
                      description: <ExpandedRowRender schemes={res.result[val][index].shares[val2].schemes}/>
                      })
                  })
                )
              )
              setData(newdata)
              setLoading(false)
            }
            else{}
          }catch{}
        
        }
        useEffect(()=>{
          getMonthEnd()
        },[])
      
      

      const handleRangePicker = (value, dateString) => {
        
        let date = datess
        date.push(dateString)
        setDatess(date)
      }

      const cancel = () => {
        getMonthEnd()
      }

       const submitData = () =>{
        let newdata = []
        const input ={
            access_token:localStorage.token,
            id:props.customer_id,
            datess:datess,
            
        }
        date_post(input).then(res => {
            console.log("savedate",res)
            try{
            if (res.status==='successfull'){
              Object.keys(res.result).map((val)=>
                Object.keys(res.result[val]).map((index)=>
                  Object.keys(res.result[val][index].shares).map((val2)=>{
                    console.log(val2)
                    newdata.push({ 
                      key: Math.floor(1000 + Math.random()*9000),
                      date:res.result[val][index].date,
                      type:res.result[val][index].shares[val2].type,
                      total_invested_value: res.result[val][index].shares[val2].total_invested_value,
                      total_market_value:res.result[val][index].shares[val2].total_market_value,
                      description: <ExpandedRowRender schemes={res.result[val][index].shares[val2].schemes}/>
                      })
                  })
                )
              )
              setData(newdata)
              // getMonthEnd()
            }
            else{}
            }catch{} 
            })
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    };
    
    const  handleReset = clearFilters => {
      clearFilters();
      setSearchText('')
    };


    let getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });


      console.log('datebh',datess)
      const columns = [
        {
          title: 'Type',
          dataIndex: 'type',width: 70,
          ...getColumnSearchProps('type')
        },
        {
          title: 'Total Invested Value',
          dataIndex: 'total_invested_value',
          width: 70,...getColumnSearchProps('total_invested_value')
        },
        {
          title: 'Total Market Value',
          dataIndex: 'total_market_value',
          width: 70,...getColumnSearchProps('total_market_value')
        },
        {
          title: 'Date',
          dataIndex: 'date',
          width: 70,...getColumnSearchProps('date')
        },  
      ]
      console.log('bvj',data);
  return(
      <>
      {loading?<Loader/>:null}
      <div style={{ float: 'right' }}>
        <Input.Group compact >
          <DatePicker.RangePicker 
           onChange={handleRangePicker}
            />
            <button className='rangeButton' onClick={submitData}>Submit</button>
            <span >
            <Tooltip title='Reload the table' color={'#c1c1a4'}>
            <ReloadOutlined onClick={cancel} style={{ fontSize: '15px', color: '#c1c1a4', marginLeft:'15px' }}/>
            </Tooltip>
            
            </span>
        </Input.Group>
        
      
      <div className='mfCustomerEmail'>
      <div>{props.cusName}</div>
      <div>{props.cusEmail}</div>
      </div>
      </div>
        <Table
              rowClassName="editable-row"
              expandable={{ expandedRowRender: record =><>{record.description}</> }}
              columns={columns}
              dataSource={data}
              size='medium'
              scroll={{x: 1150 }}
              pagination={{
                pageSize: 10,
                position:['bottomCenter']
              }}
              sticky={true}
              exportableProps={{ fileName: `Clientdata_${props.cusEmail}_${date}`}}
          />

      </>
  );
};

export default ClientData;
